import { getCurrencySymbol } from 'shared/models/Currency';
import { numberUtils } from './numberUtils';

export const FormatMonetaryValue = (value : number, currencySymbol? : string) : string => {
    const absoluteValue = Math.abs(value);
    const symbol = typeof currencySymbol === 'undefined' ? getCurrencySymbol() : currencySymbol;
    return (value < 0 ? '-' : '') + (symbol + numberUtils.ToFixed(absoluteValue, 0).replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,').toString());
};

export const FormatMonetaryValueWithCents = (value : number | null, currencySymbol? : string, decimalRoundedTo? : number) : string => {
    if (value === null) {
        return '-';
    }
    const decimal = typeof decimalRoundedTo === 'undefined' ? 2 : decimalRoundedTo;

    const absoluteValue = numberUtils.ToFixed(Math.abs(value), decimal);
    const symbol = typeof currencySymbol === 'undefined' ? getCurrencySymbol() : currencySymbol;
    return (value < 0 ? '-' : '') + (symbol + absoluteValue.replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,').toString());
};

export const FormatPrice = (value : number, currencySymbol? : string) : string => {
    const symbol = typeof currencySymbol === 'undefined' ? getCurrencySymbol() : currencySymbol;
    return value === null ? '-' : (symbol + numberUtils.ToFixed(value, 2));
};

export const FormatDollarValue = (dollarValue : number, currencySymbol? : string) : string => {
    const symbol = typeof currencySymbol === 'undefined' ? getCurrencySymbol() : currencySymbol;
    if (dollarValue < 1) {
        return symbol + numberUtils.ToFixed(dollarValue, 2);
    }

    return FormatMonetaryValue(dollarValue);
};
