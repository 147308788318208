import React, { useCallback, useState } from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { CheckBox } from 'shared/components/CheckBox';
import { Dialog } from 'shared/components/Dialog';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { RadioButton, RadioButtonTheme } from 'shared/components/RadioButton';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { LabelOrientation } from 'shared/models/InputLabel';
import { IModalButton } from 'shared/models/Modal';
import { StringUtils } from 'shared/utils/stringUtils';
import { SalesItemSaveOption } from '../reducers/reducers';
import { CreateOrEditSalesItemFormUtils } from '../utils/CreateOrEditSalesItemFormUtils';

export interface IApplyChangesDialogProps {
    readonly salesItemName : string;
    readonly salesItemId : SalesItemId;
    readonly salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    readonly isInsideItemLevelReport : boolean; // changes save options and some copy
    readonly selectedSaveOption : SalesItemSaveOption;
    readonly doNotAskMeAgainIsChecked : boolean;

    readonly onSelectSaveOption : (saveOption : SalesItemSaveOption) => void;
    readonly onSaveClick : () => Promise<void>;
    readonly onCancelClick : () => void;
    readonly onSetDoNotAskMeAgain : (isChecked : boolean) => void;
}

export const ApplyChangesDialog = (props: IApplyChangesDialogProps) => {
    const {
        onSaveClick,
        onCancelClick,
        salesItemId,
        salesItemName,
        salesItemsById,
        isInsideItemLevelReport,
        selectedSaveOption,
        doNotAskMeAgainIsChecked
    } = props;

    const [isUpdating, setIsUpdating] = useState(false);

    const handleSave = useCallback(() => {
        setIsUpdating(true);
        onSaveClick().then(() => setIsUpdating(false)).catch(() => setIsUpdating(false));
    },[onSaveClick])

    const onSelectAllReportsSaveOption = () => {
        props.onSelectSaveOption(SalesItemSaveOption.ALL_REPORTS);
    }

    const onSelectSingleReportSaveOption = () => {
        props.onSelectSaveOption(SalesItemSaveOption.SINGLE_REPORT);
    }

    const onSelectThisAndFutureReportsSaveOption = () => {
        props.onSelectSaveOption(SalesItemSaveOption.THIS_AND_FUTURE_REPORTS);
    }

    const toggleCheckbox = () => {
        props.onSetDoNotAskMeAgain(!props.doNotAskMeAgainIsChecked);
    }

    const buttons : Array<IModalButton> = [
        {
            onClick: onCancelClick,
            classes: 'flat',
            isDisabled: isUpdating,
            isLoading: false,
            children: 'Cancel',
        },
        {
            onClick: handleSave,
            classes: 'flat primary',
            isDisabled: isUpdating,
            isLoading: isUpdating,
            children: 'Update This Item',
        },
    ];


    const linkedSalesItems : StringValueSet<SalesItemId> = CreateOrEditSalesItemFormUtils.getLinkedSalesItems(salesItemId, salesItemsById);

    let headerTextString : string;
    let itemsAffectedSection : JSX.Element | null;
    if (linkedSalesItems.size === 0) {
        headerTextString = 'Where should we apply these changes to?';
        itemsAffectedSection = null;
    } else {
        headerTextString = `${ salesItemName } is used in ${ StringUtils.pluralizeNumber('linked sales item', linkedSalesItems.size) }.`;

        const linkedItemRows : Array<JSX.Element> = [];
        linkedSalesItems.forEach((linkedItemId) => {
            const linkedSalesItem = salesItemsById.get(linkedItemId);
            if (typeof linkedSalesItem === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const linkedItemName = linkedSalesItem.getSalesItem().getName();
            const servingsOfCurrentItem = linkedSalesItem.getSalesItem().getComponentServingsBySalesItemId().get(salesItemId);

            linkedItemRows.push((
                <Flex direction="row">
                    <Flex direction="column">
                        { linkedItemName }
                    </Flex>
                    <Flex direction="column">
                        { servingsOfCurrentItem } serving
                    </Flex>
                </Flex>
            ));
        });
        itemsAffectedSection = (
            <div className="linked-items-section pad-bt-xs">
                { linkedItemRows }
            </div>
        );
    }

    const saveOptionsButtons : Array<JSX.Element> = [
        (
            <RadioButton
                key="all"
                isDisabled={ false }
                isChecked={ selectedSaveOption === SalesItemSaveOption.ALL_REPORTS }
                label={ {
                    value: 'All Reports', // TODO maybe adjust if not on an item level report?
                    labelOrientation: LabelOrientation.RIGHT
                } }
                onClick={ onSelectAllReportsSaveOption }
                theme={ RadioButtonTheme.Default }
            />
        )
    ];
    if (isInsideItemLevelReport) {
        saveOptionsButtons.push((
            <RadioButton
                key="only"
                isDisabled={ false }
                isChecked={ selectedSaveOption === SalesItemSaveOption.SINGLE_REPORT }
                label={ {
                    value: 'Only this report',
                    labelOrientation: LabelOrientation.RIGHT
                } }
                onClick={ onSelectSingleReportSaveOption }
                theme={ RadioButtonTheme.Default }
            />
        ));
    }
    saveOptionsButtons.push((
        <RadioButton
            key="future"
            isDisabled={ false }
            isChecked={ selectedSaveOption === SalesItemSaveOption.THIS_AND_FUTURE_REPORTS }
            label={ {
                value: isInsideItemLevelReport ? 'This and future reports' : 'Only future reports',
                labelOrientation: LabelOrientation.RIGHT
            } }
            onClick={ onSelectThisAndFutureReportsSaveOption }
            theme={ RadioButtonTheme.Default }
        />
    ));

    return (
        <Dialog
            className="apply-changes-dialog"
            buttons={ buttons }
            headerText={
                <React.Fragment>
                    <span>{ headerTextString }</span>
                </React.Fragment>
            }
        >
            { itemsAffectedSection }
            <div className="save-type-section">
                { linkedSalesItems.size > 0 && <p>Where should we apply these changes to?</p> }
                <Flex className="option-buttons flex-pb-3" direction="column">{ saveOptionsButtons }</Flex>
                <hr/>
                <CheckBox
                    label={ { value: 'Do not ask me again', labelOrientation: LabelOrientation.RIGHT } }
                    isDisabled={ false }
                    checkBoxTriState={ doNotAskMeAgainIsChecked ? CheckBoxTriState.Checked : CheckBoxTriState.Unchecked }
                    onClick={ toggleCheckbox }
                />
            </div>
        </Dialog>
    );
}
