import InventoryTransferReportModel from 'gen-thrift/inventory_transfer_report_Model_types';

import { BreakageId } from 'api/Breakage/model/BreakageId';
import { BreakageReport } from 'api/Breakage/model/BreakageReport';
import { BreakageReportData } from 'api/Breakage/model/BreakageReportData';
import { ReportMetadata } from 'api/Breakage/model/ReportMetadata';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationThriftToObjectSerializer } from 'api/Location/serializer/LocationThriftToObjectSerializer';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class BreakageThriftToObjectSerializer {

    constructor(
        private userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer,
        private productThriftToObjectSerializer : ProductThriftToObjectSerializer,
        private locationThriftToObjectSerializer : LocationThriftToObjectSerializer,
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getBreakageId(
        breakageId : InventoryTransferReportModel.BreakageReportIdentifier,
    ) : BreakageId {
        return new BreakageId(breakageId.value);
    }

    public getReportMetadata(
        reportMetadata : InventoryTransferReportModel.ReportMetaData,
    ) : ReportMetadata {
        const creationMetadata = new UserAccountIdAndTimestamp(this.userAccountThriftToObjectSerializer.getUserAccountId(reportMetadata.creator), reportMetadata.creationTime);

        let updateMetadata : UserAccountIdAndTimestamp | null;
        if ((reportMetadata.lastUpdater === null) || (reportMetadata.lastUpdateTime === null)) {
            if (reportMetadata.lastUpdater !== reportMetadata.lastUpdateTime) {
                throw new RuntimeException('unexpected report metadata with invalid last update metadata');
            }

            updateMetadata = null;
        } else {
            updateMetadata = new UserAccountIdAndTimestamp(this.userAccountThriftToObjectSerializer.getUserAccountId(reportMetadata.lastUpdater), reportMetadata.lastUpdateTime);
        }

        return new ReportMetadata(creationMetadata, updateMetadata, reportMetadata.perspectiveIsOriginator);
    }

    public getBreakageReportData(
        breakageReportData : InventoryTransferReportModel.BreakageReportInfo
    ) : BreakageReportData {
        const productAmounts = breakageReportData.productAmounts.map((productCountAmount) => {
            return this.productThriftToObjectSerializer.getProductAmount(productCountAmount);
        });

        return new BreakageReportData(
            this.locationThriftToObjectSerializer.getLocationId(breakageReportData.perspectiveLocation),
            productAmounts,
            breakageReportData.breakageTime,
            breakageReportData.note,
            breakageReportData.type
        );
    }

    public getBreakageReport(
        breakageReport : InventoryTransferReportModel.BreakageReportAndMetadata
    ) : BreakageReport {
        return new BreakageReport(
            this.getBreakageReportData(breakageReport.data),
            this.getReportMetadata(breakageReport.metaData),
        );
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getBreakageIds(
        breakageIds : Array<InventoryTransferReportModel.BreakageReportIdentifier>
    ) : StringValueSet<BreakageId> {
        const breakageIdSet = new StringValueSet<BreakageId>();

        breakageIds.forEach((breakageId) => {
            breakageIdSet.add(this.getBreakageId(breakageId));
        });

        return breakageIdSet;
    }

    public getBreakageReports(
        breakageReports : Array<InventoryTransferReportModel.BreakageReportAndMetadata>
    ) : Array<BreakageReport> {
        return breakageReports.map((breakageReport) => {
            return this.getBreakageReport(breakageReport);
        });
    }
}
