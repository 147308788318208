import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React from 'react';

export interface IPreventPageScrollingProps {
    preventWindowTouchMove : boolean;
    addModalOpenClass : boolean;
    focusedElementRef? : HTMLElement;
}

const MODAL_OPEN_CLASS_NAME_WITH_PRECEDING_SPACE = ' modal-open';

// We can add css to this and have it also add color to all our backdrops
export class PreventPageScrolling extends React.Component<IPreventPageScrollingProps, object> {
    private scrollPosition : [number, number] | undefined;

    public UNSAFE_componentWillMount() {
        this.scrollPosition = [
            window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        ];

        if (this.props.addModalOpenClass) {
            const bodyClasses = window.document.body.className;
            window.document.body.className = bodyClasses + MODAL_OPEN_CLASS_NAME_WITH_PRECEDING_SPACE;
            document.body.scrollTop = this.scrollPosition[1];
        }

        if (this.props.preventWindowTouchMove) {
            window.addEventListener('touchmove', this.preventDefault);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps : IPreventPageScrollingProps) {
        if (typeof nextProps.focusedElementRef !== 'undefined' && typeof this.props.focusedElementRef === 'undefined') {
            disableBodyScroll(nextProps.focusedElementRef);
        } else if (typeof nextProps.focusedElementRef === 'undefined' && typeof this.props.focusedElementRef !== 'undefined') {
            enableBodyScroll(this.props.focusedElementRef);
        }
    }

    public componentWillUnmount() {
        if (this.props.addModalOpenClass) {
            const bodyClasses = window.document.body.className;
            window.document.body.className = bodyClasses.replace(MODAL_OPEN_CLASS_NAME_WITH_PRECEDING_SPACE, '');

            window.requestAnimationFrame(() => {
                if (typeof this.scrollPosition !== 'undefined') {
                    window.scrollTo(this.scrollPosition[0], this.scrollPosition[1]);
                }
            });
        }

        if (this.props.preventWindowTouchMove) {
            window.removeEventListener('touchmove', this.preventDefault);
        }

        clearAllBodyScrollLocks();
    }

    public render() {
        return (
            <div className="backdrop" />
        );
    }

    private readonly preventDefault = (event : TouchEvent) => {
        event = event || window.event;

        if (event.preventDefault) {
            event.preventDefault();
        }

        event.returnValue = false;
    }
}
