import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Dispatch } from '@reduxjs/toolkit';

import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';

import { Dialog } from 'shared/components/Dialog';
import { ParInput } from 'shared/components/Product/ParInput';
import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';

import { EditParDialogActions, ActionInterfaces } from './actions';
import { IEditParDialogState } from './reducers';

import './EditParDialog.scss';

export interface IConnectedEditParDialogProps {
    product : Product;
    productId : ProductId;
    initialCount : number | null;
    initialUnit : ProductQuantityUnit;
    onSave : (par : QuantityInUnit<ProductQuantityUnit> | null) => void;
    onClose : () => void;
}

interface IEditParDialogProps extends IConnectedEditParDialogProps {
    dispatch : Dispatch<ActionInterfaces.ISetCount | ActionInterfaces.ISetProductQuantityUnit>;
    editParDialogState : IEditParDialogState;
}

export class EditParDialog extends React.Component<IEditParDialogProps, object> {
    public UNSAFE_componentWillMount() {
        const {
            dispatch,
            initialCount,
            initialUnit,
        } = this.props;

        dispatch(EditParDialogActions.setCount(initialCount));
        dispatch(EditParDialogActions.setProductQuantityUnit(initialUnit));
    }

    public render() {
        const {
            editParDialogState,
            product,
            productId,
            onClose
        } = this.props;

        const {
            count,
            productQuantityUnit,
        } = editParDialogState;

        return (
            <Dialog
                className="edit-par-dialog"
                buttons={
                    [
                        {
                            classes: 'large flat',
                            children: 'Cancel',
                            isDisabled: false,
                            isLoading: false,
                            onClick: onClose,
                        },
                        {
                            classes: 'large flat primary',
                            children: 'Save',
                            isDisabled: false,
                            isLoading: false,
                            onClick: this.handleSave,
                        },
                    ]
                }
                headerText="Edit Par"
            >
                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                    <div className="light-table">
                        <div className="light-table-header">
                            <div className="col-row">
                                <div className="cell col-xs-4 cell-ptb">
                                    Item
                                </div>
                                <div className="cell col-xs-4 cell-ptb">
                                    Size
                                </div>
                                <div className="cell col-xs-4 cell-ptb">
                                    Edit Par
                                </div>
                            </div>
                        </div>
                        <div className="col-row light-table-row">
                            <div className="cell col-xs-4 cell-ptb cell-plr-sm">
                                <div className="item-name">
                                    <span className="product-brand">{ product.getBrand() }</span>
                                    <span className="product-name">{ product.getName() }</span>
                                </div>
                            </div>
                            <div className="cell col-xs-4 cell-ptb cell-plr-sm">
                                <span className="container-label">
                                    { oldPackagingUtils.getDisplayTextForPackaging(product.getOldPackaging(), true) + ' ' }
                                </span>
                            </div>
                            <div className="cell col-xs-4">
                                <ParInput
                                    product={ product }
                                    productId={ productId }
                                    parCount={ count }
                                    parProductQuantityUnit={ productQuantityUnit }
                                    onProductQuantityUnitSelect={ this.onProductQuantityUnitSelect }
                                    handleValueChange={ this.handleValueChange }
                                />
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                    <div className="light-table-header col-row">
                        <div className="cell col-xs-7 cell-ptb">
                            Item
                        </div>
                        <div className="cell col-xs-5 cell-ptb text-right">
                            Size
                        </div>
                    </div>
                    <div className="col-row light-table-row cell-ptb-sm">
                        <div className="cell col-xs-7">
                            <div className="item-name">
                                <span className="product-brand">{ product.getBrand() }</span>
                                <span className="product-name">{ product.getName() }</span>
                            </div>
                        </div>
                        <div className="cell col-xs-5 cell-ptb text-right">
                            <span className="container-label">
                                { oldPackagingUtils.getDisplayTextForPackaging(product.getOldPackaging(), true) + ' ' }
                            </span>
                        </div>
                    </div>
                    <div className="col-row light-table-row cell-ptb-sm">
                        <div className="cell col-xs-12">
                            <label>Edit Par</label>
                            <ParInput
                                product={ product }
                                productId={ productId }
                                parCount={ count }
                                parProductQuantityUnit={ productQuantityUnit }
                                onProductQuantityUnitSelect={ this.onProductQuantityUnitSelect }
                                handleValueChange={ this.handleValueChange }
                            />
                        </div>
                    </div>
                </MediaQuery>
            </Dialog>
        );
    }

    private readonly handleSave = () => {
        const {
            onSave,
            onClose,
            editParDialogState,
        } = this.props;

        const {
            count,
            productQuantityUnit,
        } = editParDialogState;

        let par;
        if (count === null) {
            par = null;
        } else {
            par = new QuantityInUnit(count, productQuantityUnit);
        }

        onSave(par);
        onClose();
    }

    private readonly onProductQuantityUnitSelect = (newUnit : ProductQuantityUnit) => {
        this.props.dispatch(EditParDialogActions.setProductQuantityUnit(newUnit));
    }

    private readonly handleValueChange = (newCount : number | null) => {
        this.props.dispatch(EditParDialogActions.setCount(newCount));
    }
}

interface IStateProps {
    editParDialogState : IEditParDialogState;
}

const mapStateToProps = (state : IStateProps) : IStateProps => {
    return {
        editParDialogState: state.editParDialogState,
    };
};

export const ConnectedEditParDialog = connect<IStateProps, object, IConnectedEditParDialogProps, IStateProps>(mapStateToProps)(EditParDialog);
