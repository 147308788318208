import * as _ from 'lodash';
import { Action } from '@reduxjs/toolkit';

import { ActionTypes, SignUpFormActionInterfaces } from './actions';

import { AddressFormFieldName } from 'shared/components/AddressForm/AddressForm';
import { IValidationInputData } from '../../ValidationInput';

export type signUpFormFieldName = 'firstName' | 'lastName' | 'emailAddress' | 'phone' | 'barName' | AddressFormFieldName | 'optionalZipCode' | 'subscribeToBlog';

export interface ISignUpFormState {
    validationInputDataByFieldName : {
        firstName : IValidationInputData;
        lastName : IValidationInputData;
        emailAddress : IValidationInputData;
        phone : IValidationInputData;
        barName : IValidationInputData;
        country : IValidationInputData;
        city : IValidationInputData;
        state : IValidationInputData;
        streetAddressLine1 : IValidationInputData;
        streetAddressLine2 : IValidationInputData;
        zipCode : IValidationInputData;
        optionalZipCode : IValidationInputData;
        subscribeToBlog : {
            value : boolean;
            isValid : boolean;
            errorMessage : string;
        };
    };
    isValid : boolean;
    errorMessage : string;
    isSubmitting : boolean;
    hasSuccessfullySubmitted : boolean;
}

export const signUpFormInitialValidationInputDataByFieldName = {
    firstName : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    lastName : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    emailAddress : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    phone : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    barName : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    country : {
        value: 'US', // is it ok if this is default?
        isValid: true,
        errorMessage: '',
    },
    city : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    state : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    streetAddressLine1 : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    streetAddressLine2 : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    zipCode : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    optionalZipCode : {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    subscribeToBlog : {
        value : true,
        isValid : true,
        errorMessage : '',
    },
};

const SignUpFormInitialState : ISignUpFormState = {
    validationInputDataByFieldName: signUpFormInitialValidationInputDataByFieldName,
    isValid: true,
    errorMessage: '',
    isSubmitting: false,
    hasSuccessfullySubmitted: false,
};

const reduceSetFormFieldValidationData = (
    state : ISignUpFormState,
    action : SignUpFormActionInterfaces.ISetFormFieldValidationData
) : ISignUpFormState => {
    const updates = {
        validationInputDataByFieldName: {
            [action.payload.field]: {
                value : action.payload.value,
                errorMessage : action.payload.errorMessage,
                isValid : action.payload.isValid,
            },
        },
    };

    return _.merge({}, state, updates);
};

const reduceSetFormValidationData = (
    state : ISignUpFormState,
    action : SignUpFormActionInterfaces.ISetFormValidationData
) : ISignUpFormState => {
    const updates = {
        isValid: action.payload.isValid,
        errorMessage: action.payload.errorMessage,
    };

    return _.merge({}, state, updates);
};

const reduceSetIsSubmitting = (
    state : ISignUpFormState,
    action : SignUpFormActionInterfaces.ISetIsSubmitting
) : ISignUpFormState => {
    const updates = {
        isSubmitting: action.payload.isSubmitting,
    };

    return _.merge({}, state, updates);
};

const reduceSetHasSuccessfullySubmitted = (
    state : ISignUpFormState,
    action : SignUpFormActionInterfaces.ISetHasSuccessfullySubmitted
) : ISignUpFormState => {
    const updates = {
        hasSuccessfullySubmitted: action.payload.hasSuccessfullySubmitted
    };

    return _.merge({}, state, updates);
};

export const SignUpFormReducers = (
    state : ISignUpFormState = SignUpFormInitialState,
    action : Action,
) : ISignUpFormState => {
    switch (action.type) {
        case ActionTypes.SET_FORM_FIELD_VALIDATION_DATA:
            return reduceSetFormFieldValidationData(state, action as SignUpFormActionInterfaces.ISetFormFieldValidationData);
        case ActionTypes.SET_FORM_VALIDATION_DATA:
            return reduceSetFormValidationData(state, action as SignUpFormActionInterfaces.ISetFormValidationData);
        case ActionTypes.SET_IS_SUBMITTING:
            return reduceSetIsSubmitting(state, action as SignUpFormActionInterfaces.ISetIsSubmitting);
        case ActionTypes.SET_HAS_SUCCESSFULLY_SUBMITTED:
            return reduceSetHasSuccessfullySubmitted(state, action as SignUpFormActionInterfaces.ISetHasSuccessfullySubmitted);
        default:
            return state;
    }
};
