import { Action } from '@reduxjs/toolkit';

import { ActionInterfaces, ActionTypes } from './actions';

import { IBevSpotDateTimePickerState } from './IBevSpotDateTimePickerState';

export interface IDateTimePickerStateById { [dateTimePickerId : string] : IBevSpotDateTimePickerState; }
const initialState : IDateTimePickerStateById = {};

const reduceInitializeDateTimePickerState = (state : IDateTimePickerStateById, action : ActionInterfaces.IInitializeDatetimePickerState) => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = action.payload.initialState;
    return newState;
};

const reduceSetHourErrorMessage = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetErrorMessage) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };

    const dateTimePicker = newState[action.payload.dateTimePickerId];
    dateTimePicker.formValues = { ...newState[action.payload.dateTimePickerId].formValues };
    dateTimePicker.formValues.hour = { ...newState[action.payload.dateTimePickerId].formValues.hour };
    dateTimePicker.formValues.hour.errorMessage = action.payload.errorMessage;

    return newState;
};

const reduceSetHourIsValid = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetIsValid) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };

    const dateTimePicker = newState[action.payload.dateTimePickerId];
    dateTimePicker.formValues = { ...newState[action.payload.dateTimePickerId].formValues };
    dateTimePicker.formValues.hour = { ...newState[action.payload.dateTimePickerId].formValues.hour };
    dateTimePicker.formValues.hour.isValid = action.payload.isValid;

    return newState;
};

const reduceSetHourFormValue = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetFormValue) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };

    const dateTimePicker = newState[action.payload.dateTimePickerId];
    dateTimePicker.formValues = { ...newState[action.payload.dateTimePickerId].formValues };
    dateTimePicker.formValues.hour = { ...newState[action.payload.dateTimePickerId].formValues.hour };
    dateTimePicker.formValues.hour.value = action.payload.value;

    return newState;
};

const reduceSetMinuteErrorMessage = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetErrorMessage) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };

    const dateTimePicker = newState[action.payload.dateTimePickerId];
    dateTimePicker.formValues = { ...newState[action.payload.dateTimePickerId].formValues };
    dateTimePicker.formValues.minute = { ...newState[action.payload.dateTimePickerId].formValues.minute };
    dateTimePicker.formValues.minute.errorMessage = action.payload.errorMessage;

    return newState;
};

const reduceSetMinuteIsValid = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetIsValid) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };

    const dateTimePicker = newState[action.payload.dateTimePickerId];
    dateTimePicker.formValues = { ...newState[action.payload.dateTimePickerId].formValues };
    dateTimePicker.formValues.minute = { ...newState[action.payload.dateTimePickerId].formValues.minute };
    dateTimePicker.formValues.minute.isValid = action.payload.isValid;

    return newState;
};

const reduceSetMinuteFormValue = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetFormValue) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };

    const dateTimePicker = newState[action.payload.dateTimePickerId];
    dateTimePicker.formValues = { ...newState[action.payload.dateTimePickerId].formValues };
    dateTimePicker.formValues.minute = { ...newState[action.payload.dateTimePickerId].formValues.minute };
    dateTimePicker.formValues.minute.value = action.payload.value;

    return newState;
};

const reduceSetSelectedDateTime = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetSelectedDateTime) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };
    newState[action.payload.dateTimePickerId].selectedDateTime = action.payload.selectedDateTime;
    return newState;
};

const reduceSetVisibleMonth = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetVisibleMonth) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };
    newState[action.payload.dateTimePickerId].visibleMonth = action.payload.visibleMonth;
    return newState;
};

const reduceSetVisibleYear = (state : IDateTimePickerStateById, action : ActionInterfaces.ISetVisibleYear) : IDateTimePickerStateById => {
    const newState = { ...state };
    newState[action.payload.dateTimePickerId] = { ...newState[action.payload.dateTimePickerId] };
    newState[action.payload.dateTimePickerId].visibleYear = action.payload.visibleYear;
    return newState;
};

export const BevSpotDateTimePickerReducers = (
    state : IDateTimePickerStateById = initialState,
    action : Action
) : IDateTimePickerStateById => {
    switch (action.type) {
        case ActionTypes.INITIALIZE_DATE_TIME_PICKER:
            return reduceInitializeDateTimePickerState(state, action as ActionInterfaces.IInitializeDatetimePickerState);
        case ActionTypes.SET_HOUR_ERROR_MESSAGE:
            return reduceSetHourErrorMessage(state, action as ActionInterfaces.ISetErrorMessage);
        case ActionTypes.SET_HOUR_IS_VALID:
            return reduceSetHourIsValid(state, action as ActionInterfaces.ISetIsValid);
        case ActionTypes.SET_HOUR_FORM_VALUE:
            return reduceSetHourFormValue(state, action as ActionInterfaces.ISetFormValue);
        case ActionTypes.SET_MINUTE_ERROR_MESSAGE:
            return reduceSetMinuteErrorMessage(state, action as ActionInterfaces.ISetErrorMessage);
        case ActionTypes.SET_MINUTE_IS_VALID:
            return reduceSetMinuteIsValid(state, action as ActionInterfaces.ISetIsValid);
        case ActionTypes.SET_MINUTE_FORM_VALUE:
            return reduceSetMinuteFormValue(state, action as ActionInterfaces.ISetFormValue);
        case ActionTypes.SET_SELECTED_DATE_TIME:
            return reduceSetSelectedDateTime(state, action as ActionInterfaces.ISetSelectedDateTime);
        case ActionTypes.SET_VISIBLE_MONTH:
            return reduceSetVisibleMonth(state, action as ActionInterfaces.ISetVisibleMonth);
        case ActionTypes.SET_VISIBLE_YEAR:
            return reduceSetVisibleYear(state, action as ActionInterfaces.ISetVisibleYear);
        default:
            return state;
    }
};
