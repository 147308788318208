import { IStringValue } from 'api/Core/IStringValue';

export class StorageAreaId implements IStringValue {
    constructor (private readonly value : string) {}

    public getValue() : string {
        return this.value;
    }

    public equals(other : StorageAreaId) : boolean {
        return this.getValue() === other.getValue();
    }

    public toString() : string {
        return `{StorageAreaId: ${this.value}}`;
    }
}
