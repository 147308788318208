import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { ProductCount } from './ProductCount';

export class ProductCountEvent {

    constructor (
        private readonly productCounts : Array<ProductCount>,
        private readonly userAccountIdAndTimestamp : UserAccountIdAndTimestamp,
    ) { }

    public getProductCounts() : Array<ProductCount> {
        return this.productCounts;
    }

    public getUserAccountIdAndTimestamp() : UserAccountIdAndTimestamp {
        return this.userAccountIdAndTimestamp;
    }
}
