import { ProductCost } from 'api/Product/model/ProductCost';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { productCountUtils } from 'api/Product/utils/productCountUtils';
import { IExpectedInventoryRowData } from 'apps/ExpectedInventoryReport/models/IExpectedInventoryRowData';
import moment from 'moment-timezone';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';
import * as XLSX from 'xlsx';

import { BreakageId } from 'api/Breakage/model/BreakageId';
import { BreakageReport } from 'api/Breakage/model/BreakageReport';
import { StringValueMap } from 'api/Core/StringValueMap';
import { InventoryCount } from 'api/InventoryCount/model/InventoryCount';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { SalesEntry } from 'api/Reports/model/SalesEntry';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReportWithoutCost } from 'api/Transfer/model/TransferReportWithoutCost';
import { UsageCalculationParameters } from 'api/UsageData/model/UsageCalculationParameters';
import { UsageDataUtils } from 'api/UsageData/utils/usageDataUtils';
import {
    getBreakagesTotalUnitCount, getInventoryUnitCount
} from 'apps/UsageReport/utils/Utils';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { decimalToNumber } from 'shared/utils/decimalUtils';
import { exportUtils } from 'shared/utils/exportUtils';
import { ISortedFilteredAndGroupedResult } from 'shared/utils/sortingFilteringAndGroupingUtils';
import { IReportSpecificData } from '../appTypes';

interface IExpectedInventoryBreakdownResponse {
    usageCalculationParameters : UsageCalculationParameters;
    unitCountsByProductIdByBreakageId : StringValueMap<ProductId, StringValueMap<BreakageId, QuantityInUnit<ProductQuantityUnit>>>;
    unitCountsByProductIdBySalesItemIdForPeriod : StringValueMap<ProductId, StringValueMap<SalesItemId, QuantityInUnit<ProductQuantityUnit>>>;
}

const getUnitCountByProductIdByStorageAreaIdForInventory = (inventoryCount : InventoryCount, productsById : StringValueMap<ProductId, Product>) : StringValueMap<ProductId, StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>>> => {
    const unitCountByProductIdByStorageAreaId = new StringValueMap<ProductId, StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>>>();
    inventoryCount.getProductCountEventsByProductIdByStorageAreaId().forEach((productIdProductCountEventMap, storageAreaId) => {
        productIdProductCountEventMap.forEach((productCountEvent, productId) => {
            const product = productsById.get(productId);
            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            let unitCountByStorageAreaId : StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>> | undefined = unitCountByProductIdByStorageAreaId.get(productId);
            if (typeof unitCountByStorageAreaId === 'undefined') {
                unitCountByStorageAreaId = new StringValueMap();
            }

            const totalCount = productCountUtils.getTotalProductCountFromProductCountEvent(productCountEvent, product, productId);
            const countInPreferredReportingUnit = productCountUtils.convertProductCountUnit(totalCount, product.getPreferredReportingUnit(), product.getPackagingsAndMappings());
            const countInContainers = countInPreferredReportingUnit.getCount();
            const countAsNumber = countInContainers === null ? 0 : decimalToNumber(countInContainers); // TODO 0 as null?

            unitCountByStorageAreaId.set(
                storageAreaId,
                new QuantityInUnit(countAsNumber, product.getPreferredReportingUnit())
            );

            unitCountByProductIdByStorageAreaId.set(productId, unitCountByStorageAreaId);
        });
    });
    return unitCountByProductIdByStorageAreaId;
};

const getUnitCountSoldByProductIdBySalesItemId = (
    salesBySalesItemId : StringValueMap<SalesItemId, SalesEntry>,
    flattenedMapOfProductsInSalesItems : StringValueMap<SalesItemId, StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>>,
    productsById : StringValueMap<ProductId, Product>,
) : StringValueMap<ProductId, StringValueMap<SalesItemId, QuantityInUnit<ProductQuantityUnit>>> => {
    const unitCountsByProductIdBySalesItemIdForPeriod = new StringValueMap<ProductId, StringValueMap<SalesItemId, QuantityInUnit<ProductQuantityUnit>>>();
    salesBySalesItemId.forEach((salesEntry, salesItemId) => {
        const quantitySold = getNumberForUndefinedOrNullValue(salesEntry.getQuantity()) + getNumberForUndefinedOrNullValue(salesEntry.getQuantityAdjustment());
        const productsInSalesItem = flattenedMapOfProductsInSalesItems.get(salesItemId);

        if (typeof productsInSalesItem !== 'undefined') {
            productsInSalesItem.forEach((quantityInUnit, productId) => {
                if (!unitCountsByProductIdBySalesItemIdForPeriod.has(productId)) {
                    unitCountsByProductIdBySalesItemIdForPeriod.set(productId, new StringValueMap<SalesItemId, QuantityInUnit<ProductQuantityUnit>>());
                }

                const unitCountBySalesItemId = unitCountsByProductIdBySalesItemIdForPeriod.get(productId);
                const product = productsById.get(productId);

                if ((typeof unitCountBySalesItemId === 'undefined') || (typeof product === 'undefined')) {
                    throw new RuntimeException('unexpected');
                }

                const packagingsAndMappings = product.getPackagingsAndMappings();
                const preferredReportingUnit = product.getPreferredReportingUnit();
                const unitCountSoldForOneItem = PackagingUtils.convertProductQuantityToUnit(
                    packagingsAndMappings,
                    quantityInUnit,
                    preferredReportingUnit,
                    productId
                ).getQuantity();
                const totalUnitCountSold = unitCountSoldForOneItem * quantitySold;

                unitCountBySalesItemId.set(salesItemId, new QuantityInUnit(totalUnitCountSold, preferredReportingUnit));
            });
        }
    });

    return unitCountsByProductIdBySalesItemIdForPeriod;
};

const getExpectedInventoryBreakdownByUnitCount = (
    inventoryCount : InventoryCount,
    deliveriesByDeliveryId : StringValueMap<DeliveryId, Delivery>,
    transferReportWithoutCostsByTransferId : StringValueMap<TransferId, TransferReportWithoutCost>,
    breakageReportsByBreakageId : StringValueMap<BreakageId, BreakageReport>,
    prepEventsByPrepEventId : StringValueMap<PrepEventId, PrepEvent>,
    salesBySalesItemId : StringValueMap<SalesItemId, SalesEntry>,
    productsById : StringValueMap<ProductId, Product>,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
) : IExpectedInventoryBreakdownResponse => {

    const startUnitCountByProductIdByStorageAreaId = getUnitCountByProductIdByStorageAreaIdForInventory(
        inventoryCount,
        productsById,
    );

    const unitCountByProductIdByDeliveryId = UsageDataUtils.getUnitCountByProductIdByDeliveryId(deliveriesByDeliveryId, productsById);
    const unitCountByProductIdByTransferId = UsageDataUtils.getUnitCountByProductIdByTransferId(transferReportWithoutCostsByTransferId, productsById);
    const {
        inputUnitCountByProductIdByPrepEventId,
        outputUnitCountByProductIdByPrepEventId
    } = UsageDataUtils.getUnitCountsByProductIdByPrepEventId(prepEventsByPrepEventId, productsById);

    const unitCountsByProductIdByBreakageId = UsageDataUtils.getUnitCountByProductIdByBreakageId(breakageReportsByBreakageId, productsById);

    const unitCostsByProductId = new StringValueMap<ProductId, ProductCost>(); // TODO should we calculate this?? no real need right now...

    // TODO could cache this OR put on state so we don't have to recalc all the time...
    const flattenedMapOfProductsInSalesItems = getCachedFlattenedMapOfProductsInSalesItems(salesItemsById, productsById);

    const unitCountsByProductIdBySalesItemIdForPeriod = getUnitCountSoldByProductIdBySalesItemId(salesBySalesItemId, flattenedMapOfProductsInSalesItems, productsById);

    return {
        usageCalculationParameters: new UsageCalculationParameters(
            startUnitCountByProductIdByStorageAreaId,
            new StringValueMap(),
            unitCountByProductIdByDeliveryId,
            unitCountByProductIdByTransferId,
            inputUnitCountByProductIdByPrepEventId,
            outputUnitCountByProductIdByPrepEventId,
            unitCostsByProductId,
        ),
        unitCountsByProductIdByBreakageId,
        unitCountsByProductIdBySalesItemIdForPeriod
    };
};

const getSalesItemsTotalUnitCount = (product : Product, unitCountBySalesItemId : StringValueMap<SalesItemId, QuantityInUnit<ProductQuantityUnit>> | undefined) : QuantityInUnit<ProductQuantityUnit> | undefined => {
    if (typeof unitCountBySalesItemId === 'undefined') {
        return unitCountBySalesItemId;
    }

    let totalUnitCount : number = 0;
    const preferredReportingUnit = product.getPreferredReportingUnit();
    unitCountBySalesItemId.forEach((unitCount, salesItemId) => {
        totalUnitCount += unitCount.getQuantity();
        if (!PackagingUtils.productQuantityUnitsAreEqual(unitCount.getUnit(), preferredReportingUnit)) {
            unitCount = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), unitCount, preferredReportingUnit);
        }
    });
    if (preferredReportingUnit) {
        return new QuantityInUnit<ProductQuantityUnit>(totalUnitCount, preferredReportingUnit);
    }
    return undefined;
};

const getExpectedUnitCountForProduct = (
    product : Product,
    startingInventoryUnitCount : QuantityInUnit<ProductQuantityUnit> | undefined,
    deliveriesAndTransfersAndPrepsCount : QuantityInUnit<ProductQuantityUnit> | null | undefined,
    breakageUnitCount : QuantityInUnit<ProductQuantityUnit> | null | undefined,
    salesUnitCount : QuantityInUnit<ProductQuantityUnit> | undefined,
) : number | null => {

    if (deliveriesAndTransfersAndPrepsCount === null) {
        return null; // not calculable
    }
    const startingInventoryUnitCountInPreferredReportingUnit = startingInventoryUnitCount ? PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), startingInventoryUnitCount, product.getPreferredReportingUnit()).getQuantity() : 0;
    const deliveriesAndTransfersAndPrepsCountInPreferredReportingUnit = deliveriesAndTransfersAndPrepsCount ? PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), deliveriesAndTransfersAndPrepsCount, product.getPreferredReportingUnit()).getQuantity() : 0;
    const breakageUnitCountInPreferredReportingUnit = breakageUnitCount ? PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), breakageUnitCount, product.getPreferredReportingUnit()).getQuantity() : 0;
    const salesUnitCountInPreferredReportingUnit = salesUnitCount ? PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), salesUnitCount, product.getPreferredReportingUnit()).getQuantity() : 0;
    return startingInventoryUnitCountInPreferredReportingUnit + deliveriesAndTransfersAndPrepsCountInPreferredReportingUnit - breakageUnitCountInPreferredReportingUnit - salesUnitCountInPreferredReportingUnit;
};

export const getExpectedInventoryCostForProduct = (
    expectedInventoryUnitCount : number | null | undefined,
    product : Product,
    productCost : ProductCost
) : number | undefined => {
    if (expectedInventoryUnitCount == null) {
        return undefined;
    }
    return expectedInventoryUnitCount * productCostUtils.getCostInUnit(productCost, product.getPreferredReportingUnit(), product.getPackagingsAndMappings());
};

export const getNumberForUndefinedOrNullValue = (value : number | undefined | null) : number => {
    if (typeof value === 'undefined' || value === null) {
        return 0;
    }
    return value;
};

export const getNumberForQuantityInUnitOrUndefinedValue = (quantityInUnit : QuantityInUnit<ProductQuantityUnit> | undefined | null) : number => {
    if (typeof quantityInUnit === 'undefined' || quantityInUnit === null) {
        return 0;
    }
    return quantityInUnit.getQuantity();
};

// We might want to make this function a shared util rather than living here (same issue with CachedSalesItemCostUtils.ts)
let lastSalesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata> | undefined;
let lastProductsById : StringValueMap<ProductId, Product> | undefined;
const cachedFlattenedMapOfProductsInSalesItems = new StringValueMap<SalesItemId, StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>>();
const getCachedFlattenedMapOfProductsInSalesItems = (
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
) : StringValueMap<SalesItemId, StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>> => {
    // Future work: if we have a use case, we can pass in a set of ids to calculate for, and only retrieve those
    // future optimization: can just compare relevant sales item info in sales items by id to see if we need to recalculate (ingredients, yield, serving size)
    if (productsById !== lastProductsById || salesItemsById !== lastSalesItemsById) {
        cachedFlattenedMapOfProductsInSalesItems.clear();
        lastProductsById = productsById;
        lastSalesItemsById = salesItemsById;

        const allItems = SalesItemUtils.flattenSalesItemsIntoProductPerServingComponents(
            salesItemsById,
            productsById,
        );
        allItems.forEach((productMap, salesItemId) => cachedFlattenedMapOfProductsInSalesItems.set(salesItemId, productMap));
        return allItems;
    } else {
        return cachedFlattenedMapOfProductsInSalesItems;
    }
};

const exportReportToExcel = (
    retailerName : string,
    startDate : moment.Moment,
    productsById : StringValueMap<ProductId, Product>,
    productCostsById : StringValueMap<ProductId, ProductCost>,
    sortedFilteredGroupedRowData : ISortedFilteredAndGroupedResult<ProductId>,
    reportSpecificData : IReportSpecificData,
) => {
    const startDateInRetailerTimeZone = startDate.tz(window.GLOBAL_RETAILER_TIME_ZONE);
    const endDateInRetailerTimeZone = moment.utc().tz(window.GLOBAL_RETAILER_TIME_ZONE);
    const fileName = exportUtils.getStandardReportFileName(retailerName, 'expected_inventory', startDateInRetailerTimeZone, endDateInRetailerTimeZone);

    const firstRow = [`Expected inventory for ${ retailerName }`];
    const secondRow = [`${ startDateInRetailerTimeZone.format('MM/DD/YY') } - ${ endDateInRetailerTimeZone.format('MM/DD/YY') }`];

    const headersRow = ['Item', 'Package', 'Starting Count', 'Deliveries/Transfers/Preps', 'Sales', 'Losses', 'Expected Inventory', 'Inventory Value'];
    const arrayOfArraysForSheet : Array<Array<string | number>> = [
        firstRow,
        secondRow,
        [],
        headersRow
    ];

    sortedFilteredGroupedRowData.sortedGroupNamesToDisplay.forEach((groupName : string) => {
        const groupNameRow = [groupName];

        arrayOfArraysForSheet.push(groupNameRow);

        const sortedRowIds = sortedFilteredGroupedRowData.sortedRowIdsToDisplayByGroupName[groupName];
        sortedRowIds.forEach((productId) => {
            const product = productsById.get(productId);

            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const productCost = productCostsById.getRequired(productId);

            const rowData = ExpectedInventoryReportUtils.generateRowData(productId, product, productCost, reportSpecificData);
            const expectedInventoryValueLabel = typeof rowData.expectedInventoryValue === 'undefined' ? '' : FormatMonetaryValueWithCents(rowData.expectedInventoryValue);

            const row = [
                `${ product.getBrand() }${ product.getBrand().length === 0 ? '' : ' '}${ product.getName() }`,
                oldPackagingUtils.getDisplayTextForPackaging(product.getOldPackaging(), true),
                getNumberForQuantityInUnitOrUndefinedValue(rowData.startingInventoryUnitCount),
                getNumberForQuantityInUnitOrUndefinedValue(rowData.deliveriesAndTransfersAndPrepsCount),
                getNumberForQuantityInUnitOrUndefinedValue(rowData.salesUnitCount),
                getNumberForQuantityInUnitOrUndefinedValue(rowData.breakageUnitCount),
                getNumberForUndefinedOrNullValue(rowData.expectedInventoryTotal),
                expectedInventoryValueLabel,
            ];
            arrayOfArraysForSheet.push(row);
        });
        arrayOfArraysForSheet.push([]); // empty row between groups
    });

    const workSheet = XLSX.utils.aoa_to_sheet(arrayOfArraysForSheet);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Expected Inventory');

    exportUtils.writeWorkBookToFile(workBook, fileName, 'xlsx');
};

const generateRowData = (productId : ProductId, product : Product, productCost : ProductCost, reportSpecificData : IReportSpecificData) => {
    const unitCountByBreakageId = reportSpecificData.unitCountsByProductIdByBreakageId.get(productId);
    const unitCountByDeliveryId = reportSpecificData.usageCalculationParameters.getUnitCountsByProductIdByDeliveryId().get(productId);
    const unitCountByTransferId = reportSpecificData.usageCalculationParameters.getUnitCountsByProductIdByTransferId().get(productId);
    const unitCountByStorageAreaIdForStartingInventory = reportSpecificData.usageCalculationParameters.getUnitCountsByProductIdByStorageAreaIdForStartingInventory().get(productId);
    const inputUnitCountByPrepEventId = reportSpecificData.usageCalculationParameters.getInputUnitCountsByProductIdByPrepEventId().get(productId);
    const outputUnitCountByPrepEventId = reportSpecificData.usageCalculationParameters.getOutputUnitCountsByProductIdByPrepEventId().get(productId);
    const unitCountBySalesItemIdForPeriod = reportSpecificData.unitCountsByProductIdBySalesItemIdForPeriod.get(productId);

    const startingInventoryUnitCount = getInventoryUnitCount(product, unitCountByStorageAreaIdForStartingInventory);
    const deliveriesAndTransfersAndPrepsCount =
        UsageDataUtils.getDeliveriesAndTransfersAndPrepsCounts(
            product,
            unitCountByDeliveryId,
            unitCountByTransferId,
            inputUnitCountByPrepEventId,
            outputUnitCountByPrepEventId,
        ).deliveriesAndTransfersAndPrepsTotalCount;

    const breakageUnitCount = getBreakagesTotalUnitCount(product, unitCountByBreakageId);
    const salesUnitCount = ExpectedInventoryReportUtils.getSalesItemsTotalUnitCount(product, unitCountBySalesItemIdForPeriod);

    const expectedInventoryTotal = ExpectedInventoryReportUtils.getExpectedUnitCountForProduct(product, startingInventoryUnitCount, deliveriesAndTransfersAndPrepsCount, breakageUnitCount, salesUnitCount);

    const expectedInventoryValue = ExpectedInventoryReportUtils.getExpectedInventoryCostForProduct(expectedInventoryTotal, product, productCost);

    const rowData : IExpectedInventoryRowData = {
        unitCountByBreakageId,
        unitCountByDeliveryId,
        unitCountByTransferId,
        unitCountByStorageAreaIdForStartingInventory,
        inputUnitCountByPrepEventId,
        outputUnitCountByPrepEventId,
        unitCountBySalesItemIdForPeriod,
        startingInventoryUnitCount,
        deliveriesAndTransfersAndPrepsCount,
        breakageUnitCount,
        salesUnitCount,
        expectedInventoryTotal,
        expectedInventoryValue,
        productCost
    };

    return rowData;
};

export const ExpectedInventoryReportUtils = {
    getExpectedInventoryBreakdownByUnitCount,
    getExpectedUnitCountForProduct,
    getExpectedInventoryCostForProduct,
    getSalesItemsTotalUnitCount,
    getCachedFlattenedMapOfProductsInSalesItems,
    exportReportToExcel,
    getUnitCountSoldByProductIdBySalesItemId,
    generateRowData
};
