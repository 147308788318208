import { StringValueMap } from 'api/Core/StringValueMap';
import { InventoryCount } from 'api/InventoryCount/model/InventoryCount';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { ProductCount } from 'api/InventoryCount/model/ProductCount';
import { ProductCountEvent } from 'api/InventoryCount/model/ProductCountEvent';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { LocationId } from 'api/Location/model/LocationId';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { productCountUtils } from 'api/Product/utils/productCountUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { GroupByOption } from 'shared/models/GroupByOption';

const getTotalContainerProductCountsByProductId = (
    inventoryCount : InventoryCount,
    productsById : StringValueMap<ProductId, Product>,
) : StringValueMap<ProductId, ProductCount> => {
    const productCountsByProductId = new StringValueMap<ProductId, Array<ProductCount>>();

    // Instantiate StringValueMap for all products in any StorageArea
    inventoryCount.getInventoryConfiguration().getSortedProductIdListsByStorageAreaId().forEach((sortedProductIdList) => {
        sortedProductIdList.forEach((productId) => {
            if (typeof productCountsByProductId.get(productId) === 'undefined') {
                productCountsByProductId.set(productId, []);
            }
        });
    });

    inventoryCount.getProductCountEventsByProductIdByStorageAreaId().forEach((productCountEventsByProductId) => {
        productCountEventsByProductId.forEach((productCountEvent, productId) => {
            const productCounts = productCountsByProductId.getRequired(productId);
            const product = productsById.getRequired(productId);

            if (!productCountUtils.isProductCountEventEmpty(productCountEvent)) {
                productCounts.push(productCountUtils.getTotalProductCountFromProductCountEvent(productCountEvent, product, productId));
            }
        });
    });

    const totalContainerProductCountsByProductId = new StringValueMap<ProductId, ProductCount>();
    productCountsByProductId.forEach((productCounts, productId) => {
        const product = productsById.getRequired(productId);

        const packagingsAndMappings = product.getPackagingsAndMappings();
        const productCountsSum = productCountUtils.sumProductCounts(productCounts, packagingsAndMappings);
        const defaultProductQuantityUnitForDisplay = PackagingUtils.getContainerPackagingId(packagingsAndMappings.getPackaging());

        totalContainerProductCountsByProductId.set(
            productId,
            productCountUtils.convertProductCountUnit(productCountsSum, defaultProductQuantityUnitForDisplay, packagingsAndMappings)
        );
    });

    return totalContainerProductCountsByProductId;
};

export enum InventoryCountSortByOption {
    ITEM_NAME = 'ITEM_NAME',
    STORAGE_AREA_INDEX = 'STORAGE_AREA_INDEX',
}

const getExcelExportURL = (
    locationId : LocationId,
    usageStartInventoryCountId : InventoryCountId | null,
    inventoryCountId : InventoryCountId,
    storageAreaId : StorageAreaId | null,
    groupByOption : GroupByOption,
    sortBy : 'custom' | 'default',
) : string => {

    let groupBy : string;
    switch (groupByOption) {
        case GroupByOption.ALL_ITEMS:
            groupBy = 'no_grouping';
            break;
        case GroupByOption.DISTRIBUTOR:
            groupBy = 'distributor';
            break;
        case GroupByOption.CATEGORY:
            groupBy = 'product_category_id';
            break;
        case GroupByOption.ITEM_TYPE:
            groupBy = 'product_type';
            break;
        case GroupByOption.LAST_EDITED:
            groupBy = 'last_edited';
            break;
        default:
            throw new RuntimeException('Unknown inventory count type');
    }

    return `${ url('inventory:excel', null, locationId.getValue(), null) }?group_by=${ groupBy }&location_id=${ (storageAreaId === null) ? '' : storageAreaId.getValue() }` +
    `&reference_id=${ (usageStartInventoryCountId === null) ? '' : usageStartInventoryCountId.getValue() }&finalized_id=${ inventoryCountId.getValue() }&sort_by=${ sortBy }`;
};

interface IInventoryCountSummary {
    readonly sittingValueInDollars : number;
    readonly numberOfCountedItems : number;
    readonly numberOfCountableItems : number;
}

// duplicates logic in InventoryCount.tsx render() >:(
const getTotalAndStorageAreaCountSummary = (
    inventoryCount : InventoryCount,
    productsById : StringValueMap<ProductId, Product>,
    costsByProductId : StringValueMap<ProductId, ProductCost>
) : {totalCountSummary : IInventoryCountSummary, countSummaryByStorageAreaId : StringValueMap<StorageAreaId, IInventoryCountSummary>} => {
    const inventoryConfiguration = inventoryCount.getInventoryConfiguration();

    let numberOfTotalCountableItems : number = 0;
    let numberOfTotalCountedItems : number = 0;
    let totalSittingValueInDollars : number = 0;
    const countSummaryByStorageAreaId = new StringValueMap<StorageAreaId, IInventoryCountSummary>();

    inventoryConfiguration.getStorageAreasById().forEach((storageArea, storageAreaId) => {
        const productCountEventsByProductId = inventoryCount.getProductCountEventsByProductIdByStorageAreaId().get(storageAreaId);
        const productIds = inventoryConfiguration.getSortedProductIdListsByStorageAreaId().get(storageAreaId);

        if (typeof productCountEventsByProductId === 'undefined')  {
            throw new RuntimeException(`unexpected no product count events found for ${storageAreaId}`);
        }


        if (typeof productIds === 'undefined')  {
            throw new RuntimeException(`unexpected no product id list found for ${storageAreaId}`);
        }

        let storageAreaNumberOfCountedItems = 0;
        let storageAreaSittingValueInDollars = 0;

        productCountEventsByProductId.forEach((productCountEvent, productId) => {
            const product = productsById.get(productId);
            const cost = costsByProductId.get(productId);

            if (typeof product === 'undefined') {
                throw new RuntimeException(`unexpected product not found in productsById ${productId}`);
            }

            if (typeof cost === 'undefined') {
                throw new RuntimeException(`unexpected cost not found in costsByProductId ${productId}`);
            }

            if (!productCountUtils.isProductCountEventEmpty(productCountEvent)) {
                const totalProductCount = productCountUtils.getTotalProductCountFromProductCountEvent(productCountEvent, product, productId);
                if (totalProductCount.getCount() !== null) {
                    storageAreaNumberOfCountedItems += 1;
                    storageAreaSittingValueInDollars +=
                        productCostUtils.totalDollarCostOfQuantity(
                            product.getPackagingsAndMappings(),
                            cost,
                            productCountUtils.getQuantityInUnit(totalProductCount),
                            productId);
                }
            }
        });

        const storageAreaNumberOfCountableItems = productIds.length;

        countSummaryByStorageAreaId.set(
            storageAreaId,
            {
                sittingValueInDollars: storageAreaSittingValueInDollars,
                numberOfCountedItems: storageAreaNumberOfCountedItems,
                numberOfCountableItems: storageAreaNumberOfCountableItems,
            }
        );

        numberOfTotalCountableItems += storageAreaNumberOfCountableItems;
        numberOfTotalCountedItems += storageAreaNumberOfCountedItems;
        totalSittingValueInDollars += storageAreaSittingValueInDollars;
    });

    return {
        totalCountSummary : {
            sittingValueInDollars: totalSittingValueInDollars,
            numberOfCountedItems: numberOfTotalCountedItems,
            numberOfCountableItems: numberOfTotalCountableItems,
        },
        countSummaryByStorageAreaId,
    };
};

const getProductCountsByStorageAreaIdByProductId = (
    inventoryCount : InventoryCount,
) : StringValueMap<ProductId, StringValueMap<StorageAreaId, ProductCountEvent>> => {
    const productCountsByStorageAreaIdByProductId = new StringValueMap<ProductId, StringValueMap<StorageAreaId, ProductCountEvent>>();

    // Instantiate StringValueMap for all products in any StorageArea
    inventoryCount.getInventoryConfiguration().getSortedProductIdListsByStorageAreaId().forEach((sortedProductIdList) => {
        sortedProductIdList.forEach((productId) => {
            if (typeof productCountsByStorageAreaIdByProductId.get(productId) === 'undefined') {
                productCountsByStorageAreaIdByProductId.set(productId, new StringValueMap<StorageAreaId, ProductCountEvent>());
            }
        });
    });

    inventoryCount.getProductCountEventsByProductIdByStorageAreaId().forEach((productCountEventsByProductId, storageAreaId) => {
        productCountEventsByProductId.forEach((productCountEvent, productId) => {
            const productCountsByStorageAreaId = productCountsByStorageAreaIdByProductId.getRequired(productId);
            productCountsByStorageAreaId.set(storageAreaId, productCountEvent);
        });
    });

    return productCountsByStorageAreaIdByProductId;
};

export const inventoryCountUtils = {
    getTotalContainerProductCountsByProductId,
    getProductCountsByStorageAreaIdByProductId,
    getExcelExportURL,
    getTotalAndStorageAreaCountSummary,
};
