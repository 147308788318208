import { StringValueMap } from 'api/Core/StringValueMap';
import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { BaseUnit } from 'api/Product/model/BaseUnit';
import { Conversions } from 'api/Product/model/Conversions';
import { Mappings } from 'api/Product/model/Mappings';
import { MassUnit } from 'api/Product/model/MassUnit';
import { OldPackaging } from 'api/Product/model/OldPackaging';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { Price } from 'api/Product/model/Price';
import { Product } from 'api/Product/model/Product';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { Unit } from 'api/Product/model/Unit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { productUtils } from 'api/Product/utils/productUtils';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { ProductFormUtils } from 'apps/ItemCard/utils/ProductFormUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { DateTime } from 'shared/models/DateTime';

const createProductFromProductQuickAddAndOptionIndex = (productQuickAdd : ProductQuickAdd, optionIndex : number, categoriesById : StringValueMap<CategoryId, Category>) : Product => {
    // New Category Id will be null if the category needs to be created
    const optionsArray = productQuickAdd.getOptions();
    const option = optionsArray[optionIndex];
    if (typeof option === 'undefined') {
        throw new RuntimeException('unexpected attempting to create a product from quick add with an invalid selected option');
    }

    const dummyPackaging = oldPackagingUtils.getPackagingWithDummyPackagingIdsFromOldPackaging(option.packaging);
    const userAccountIdAndTimestamp = new UserAccountIdAndTimestamp(new UserAccountId(window.GLOBAL_USER_ID), DateTime.now().toTimestampWithMillisecondPrecision());

    const categoryId = CategoryUtils.getCategoryIdFromName(categoriesById, productQuickAdd.getProductCategoryId());

    const productWithDummyPackaging = new Product(
        productQuickAdd.getBrand(),
        productQuickAdd.getName(),
        new PackagingsAndMappings(
            oldPackagingUtils.getPackagingDataFromPackagingArray([dummyPackaging]),
            new Mappings(new StringValueMap(), new Map()),
            new Conversions(PackagingUtils.getBaseUnitOfPackaging(dummyPackaging), new StringValueMap(), new Map())
        ),
        PackagingUtils.getContainerPackagingId(dummyPackaging),
        new StringValueMap(),
        productQuickAdd.getProductCategoryId(),
        categoryId,
        productQuickAdd.getProductType(),
        new Price(option.suggestedCasePriceInDollars, option.packaging.getUnit()),
        option.suggestedDepositInDollars,
        productQuickAdd.getSku(),
        '',
        productQuickAdd.getNote(),
        userAccountIdAndTimestamp,
    );

    const product = ProductFormUtils.getProductDistributorAndParForEditOrCreate(productWithDummyPackaging, null, null, null, null).product;

    return productUtils.getProductWithNewCategory(
        product,
        product.getNewProductCategoryId(),
        productWithDummyPackaging.getProductCategoryId(),
    );
};

const createPackagingThatContainsUnit = (unit : Unit) : OldPackaging => {
    if (VolumeUnit.isVolumeUnit(unit) || MassUnit.isMassUnit(unit) || BaseUnit.isBaseUnit(unit)) {
        return new OldPackaging(
            new OldPackaging(
                null,
                null,
                unit),
            1,
            PackagingUnit.CONTAINER
        );
    }

    if (unit === PackagingUnit.CASE) {
        return new OldPackaging(
            new OldPackaging(
                new OldPackaging(
                    null,
                    null,
                    BaseUnit.UNIT
                ),
                1,
                PackagingUnit.CONTAINER
            ),
            1,
            PackagingUnit.CASE
        );
    }

    return new OldPackaging(
        new OldPackaging(
            null,
            null,
            VolumeUnit.LITER),
        1,
        unit
    );
};

// NOTE: this does not set price based on quick add price because we are just making a dummy unit
// ONLY USED ON INVENTORY, otherwise should be using the other util
// New Category Id will be null if the category needs to be created
const createProductFromProductQuickAddAndUnit = (productQuickAdd : ProductQuickAdd, unit : Unit, categoriesById : StringValueMap<CategoryId, Category>) : Product => {
    const dummyPackaging = oldPackagingUtils.getPackagingWithDummyPackagingIdsFromOldPackaging(createPackagingThatContainsUnit(unit));
    const userAccountIdAndTimestamp = new UserAccountIdAndTimestamp(new UserAccountId(window.GLOBAL_USER_ID), DateTime.now().toTimestampWithMillisecondPrecision());
    const categoryId = CategoryUtils.getCategoryIdFromName(categoriesById, productQuickAdd.getProductCategoryId());

    const productWithDummyPackaging = new Product(
        productQuickAdd.getBrand(),
        productQuickAdd.getName(),
        new PackagingsAndMappings(
            oldPackagingUtils.getPackagingDataFromPackagingArray([dummyPackaging]),
            new Mappings(new StringValueMap(), new Map()),
            new Conversions(PackagingUtils.getBaseUnitOfPackaging(dummyPackaging), new StringValueMap(), new Map())
        ),
        PackagingUtils.getContainerPackagingId(dummyPackaging),
        new StringValueMap(),
        productQuickAdd.getProductCategoryId(),
        categoryId,
        productQuickAdd.getProductType(),
        new Price(0.00, unit),
        0.00,
        productQuickAdd.getSku(),
        '',
        productQuickAdd.getNote(),
        userAccountIdAndTimestamp,
    );

    const product = ProductFormUtils.getProductDistributorAndParForEditOrCreate(productWithDummyPackaging, null, null, null, null).product;

    return productUtils.getProductWithNewCategory(
        product,
        product.getNewProductCategoryId(),
        productWithDummyPackaging.getProductCategoryId(),
    );
};

export const ProductQuickAddUtils = {
    createProductFromProductQuickAddAndOptionIndex,
    createProductFromProductQuickAddAndUnit,
};
