import { legacy_createStore, Reducer, Store, applyMiddleware, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { object } from 'prop-types';
import React from 'react';
import thunk from 'redux-thunk';
import { Provider as ReduxProvider } from 'react-redux';

import { enableBatching } from 'shared/models/IAction';

// Based on http://www.mattgreer.org/articles/typescript-react-and-redux/

interface IProvider<P> {
    appReducers? : Reducer<P>;
    store? : any;
    services : object;
    children : React.ReactNode;
}

export interface IExtraArguments {
    services : object;
}

// TODO future: we could improve this now that we're on redux-toolkit, but as the initial 
// migration, just make something that works in the way we expect our old typings to work
// the old typings only provide the state type, so copy that here.
export type BevSpotDispatch<S, E extends IExtraArguments> = ThunkDispatch<S, E, AnyAction>

export class Provider<P> extends React.Component<IProvider<P>, any> {
    public static childContextTypes = {
        store: object.isRequired,
    };

    public static createStore<T>(appReducers : Reducer<T>, services : object) : Store<T> {
        const extraArguments : IExtraArguments = {
            services,
        };

        // TODO migrate this to redux toolkit's configureStore for better behavior/modern style
        const store = legacy_createStore(
            enableBatching(appReducers),
            applyMiddleware(
                thunk.withExtraArgument(extraArguments)
            )
        );
        return store;
    }

    public store : Store<P>;
    public target : React.ReactNode;

    constructor(props : IProvider<P>) {
        super(props);

        if (typeof this.props.store !== 'undefined') {
            this.store = this.props.store;
        } else if (typeof this.props.appReducers !== 'undefined') {
            this.store = Provider.createStore(this.props.appReducers, this.props.services);
        } else {
            throw new Error('Provider requires appReducers or store');
        }
        this.target = this.props.children;
    }

    public getChildContext() {
        return {
            store: this.store,
        };
    }

    public render() {
        return (<ReduxProvider store={this.store}>{this.target}</ReduxProvider>)
    }
}
