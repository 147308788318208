import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

// Components
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { Button } from 'shared/components/Button';
import { Dialog } from 'shared/components/Dialog';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { ModalFlex } from 'shared/components/ModalFlex/ModalFlex';
import { Select2DropdownMenuTheme } from 'shared/components/Select2Dropdown/GenericSelect2DropdownMenu';
import { OptionsAndLabelNameTuples, Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';

import { DefaultPourFormFieldName, DefaultPourInfoByFormFieldName, IEditDefaultPourFormState } from './reducers';

import { StringValueMap } from 'api/Core/StringValueMap';
import { DefaultPour } from 'api/Location/interfaces/ILocationSettingsService';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { EditDefaultPourFormActions, IEditDefaultPourFormStore, ActionInterfaces } from './actions';

import './EditDefaultPourForm.scss';

// Constants
import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';
import { BevSpotDispatch } from '../Provider';

export interface IConnectedEditDefaultPourFormProps {
    initialDefaultPour : DefaultPour | null;
    productsById : StringValueMap<ProductId, Product>; // will need for when this is customizable: all of their product categories
    onSave : (defaultPour : DefaultPour) => void;
    onClose : () => void;
    formDirectlyOnPage? : boolean; //  poorly named but eventually we want this form to always be put on a page, not just via dialog... so living in both worlds right now
    isPopup : boolean; // defaults to true
}

interface IEditDefaultPourFormProps extends IConnectedEditDefaultPourFormProps {
    dispatch : BevSpotDispatch<IEditDefaultPourFormStore, ActionInterfaces.IEditDefaultPourFormExtraArguments>;
    editDefaultPourFormState : IEditDefaultPourFormState;
}

// TODO future: this should probably move to wherever sales settings will live
// This component handles form state itself but doesn't make any server calls - in the future, if this lives on many pages
// we may want to have it call the server itself. As it stands right now, it will probably only be on 2 pages so keeping it
// relatively simple by only having it control form state. Another future piece of work could be just having this use local state
// instead of actions/reducers but that would prevent the server calls if we ever wanted to change that.
export class EditDefaultPourForm extends React.Component<IEditDefaultPourFormProps, object> {
    private unitOptions : OptionsAndLabelNameTuples;

    constructor(props : IEditDefaultPourFormProps) {
        super(props);
        this.unitOptions = [[null, this.getDefaultPourDropdownOptions()]];
    }

    public componentDidMount() {
        const {
            dispatch,
            initialDefaultPour,
            productsById,
        } = this.props;
        dispatch(EditDefaultPourFormActions.initializeForm(initialDefaultPour, productsById));
    }

    public UNSAFE_componentWillUpdate(nextProps : Readonly<IEditDefaultPourFormProps>, nextState : Readonly<object>, nextContext : any) : void {
        if (nextProps.initialDefaultPour !== this.props.initialDefaultPour || nextProps.productsById !== this.props.productsById) {
            const {
                dispatch,
                initialDefaultPour,
                productsById,
            } = nextProps;

            dispatch(EditDefaultPourFormActions.initializeForm(initialDefaultPour, productsById));
        }
    }

    public render() {
        const {
            onClose,
            formDirectlyOnPage,
            editDefaultPourFormState,
            isPopup
        } = this.props;

        const { defaultPourForm } = editDefaultPourFormState;

        const addCategoryDropdownOptions = this.getDefaultCategoryDropdownOptions();

        const zeroSelectedDisplay = (
            <Fragment>
                { defaultPourForm.length === 0 && (
                    <div>
                        <span>
                            Select a category from the dropdown to add a standard pour size and make costing recipes faster.
                        </span>
                    </div>
                ) }
            </Fragment>
        );

        const addCategoryDropdownContainer = (
            <Fragment>
                { addCategoryDropdownOptions[0][1].length > 0 && (
                    <div className="add-category-dropdown-container">
                        <Select2DropdownMenu
                            theme={ Select2DropdownMenuTheme.Basic }
                            isSearchable={ false }
                            buttonShouldDisplaySelectedLabel={ true }
                            selectedOptionHeaderShown={ false }
                            sortedOptionsAndLabelName={ addCategoryDropdownOptions }
                            selectedOption={ null }
                            onOptionSelect={ this.handleCreate }
                            placeholderText="Add a pour size"
                            emptyState={ null }
                            hasCreateCustomOption={ false }
                            createCustomOption={ this.doNothing }
                            customOptionGroupLabel={ null }
                            createCustomOptionButtonLabel={ null }
                        />
                    </div>
                ) }
            </Fragment>
        );
        const isMobile = window.matchMedia('(max-width: ' + MAX_MOBILE_WIDTH + 'px)').matches;

        if (formDirectlyOnPage) {
            return (
                <div className="edit-pour-form-container">
                    <label className="form-label input-label static reset-dark-styles">Set your standard pour sizes:</label>
                    { zeroSelectedDisplay }
                    <Flex direction="column" grow={ 1 } className="content-container">
                        { this.createFormList(isMobile) }
                        { addCategoryDropdownContainer }
                    </Flex>
                </div>
            );
        }

        if (isPopup) {
            return (
                <Fragment>
                    <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                        <ModalFlex
                            className="edit-pour-modal-container"
                            buttons={ [
                                {
                                    classes: 'flat',
                                    isDisabled: false,
                                    isLoading: false,
                                    onClick: onClose,
                                    children: 'Cancel'
                                },
                                {
                                    classes: 'flat primary',
                                    isDisabled: false,
                                    isLoading: false,
                                    onClick: this.handleSave,
                                    children: 'Save'
                                }
                            ] }
                            closeButtonShown={ false }
                            footerLabel={ null }
                            footerLeftButton={ null }
                            headerText="Set your standard pour sizes:"
                            modalClasses={ {
                                headerClasses: 'modal-header-container',
                                bodyClasses: 'modal-body-container',
                                footerClasses: 'modal-footer-container'
                            } }
                            onClose={ null }
                        >
                            { zeroSelectedDisplay }
                            <Flex direction="column" grow={ 1 } className="content-container">
                                { this.createFormList(true) }
                                { addCategoryDropdownContainer }
                            </Flex>
                        </ModalFlex>
                    </MediaQuery>
                    <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                        <Dialog
                            className="edit-pour-dialog-container"
                            buttons={
                                [
                                    {
                                        classes: 'large flat',
                                        children: 'Cancel',
                                        isDisabled: false,
                                        isLoading: false,
                                        onClick: onClose,
                                    },
                                    {
                                        classes: 'large primary',
                                        children: 'Save',
                                        isDisabled: false,
                                        isLoading: false,
                                        onClick: this.handleSave,
                                    },
                                ]
                            }
                            headerText="Set your standard pour sizes:"
                        >
                            { zeroSelectedDisplay }
                            <Flex direction="column" className="content-container">
                                { this.createFormList(false) }
                                { addCategoryDropdownContainer }
                            </Flex>
                        </Dialog>
                    </MediaQuery>
                </Fragment>
            );
        } else {
            return (
                <div className="edit-pour-form-container">
                    { zeroSelectedDisplay }
                    <Flex direction="column" className="content-container">
                        { this.createFormList(isMobile) }
                        { addCategoryDropdownContainer }
                    </Flex>
                    <Flex direction="row" justify="end">
                        <div style={ { paddingRight: '36px' } }>
                            <Button buttonClassName="primary pull-left" isDisabled={ false } isLoading={ false } onClick={ this.handleSave }>
                                Save
                            </Button>
                        </div>
                    </Flex>
                </div>
            );
        }
    }

    public readonly getDefaultPour = () => {
        const defaultPour : DefaultPour | null = this.props.dispatch(EditDefaultPourFormActions.getDefaultPourByCategoryFromForm());
        return defaultPour;
    }

    private readonly doNothing = () => undefined;

    private readonly handleCreate = (categoryName : string) => {
        this.props.dispatch(EditDefaultPourFormActions.addCategoryOption(categoryName));
    }

    private readonly handleDeleteGenerator = (index : number) => () => {
        this.props.dispatch(EditDefaultPourFormActions.removeCategoryOption(index));
    }

    private readonly handleSave = () => {
        const {
            onSave,
            onClose,
        } = this.props;

        const defaultPourToSave = this.getDefaultPour();
        if (defaultPourToSave !== null) {
            onSave(defaultPourToSave);
            onClose();
        }
    }

    private readonly getDefaultPourDropdownOptions = () => {
        const defaultPourDropdownOptions : Array<IOption> = [];
        VolumeUnit.getUnits().forEach((volumeUnit) => defaultPourDropdownOptions.push({
            label: oldPackagingUtils.getDisplayTextForUnit(volumeUnit),
            icon: null,
            value: volumeUnit,
        }));
        MassUnit.getUnits().forEach((massUnit) => defaultPourDropdownOptions.push({
            label: oldPackagingUtils.getDisplayTextForUnit(massUnit),
            icon: null,
            value: massUnit,
        }));
        defaultPourDropdownOptions.push({
            label: 'EA',
            icon: null,
            value: 'EA' // each...
        });
        defaultPourDropdownOptions.push({
            label: 'bottle',
            icon: null,
            value: 'unit' // legacy behavior: we called container "unit" - changing the label but keeping the actual value the same
        });
        return defaultPourDropdownOptions;
    }

    private readonly getCategoryOptions = () => {
        const { categoryOptions } = this.props.editDefaultPourFormState;
        if (categoryOptions.length > 0) {
            return new Set(categoryOptions[0][1].map((category) => category.value));
        }
        return new Set();
    }

    private readonly getDefaultCategoryDropdownOptions = () : OptionsAndLabelNameTuples => {
        const { defaultPourForm } = this.props.editDefaultPourFormState;
        const categoriesLeft = new Set(this.getCategoryOptions());
        defaultPourForm.forEach((form : DefaultPourInfoByFormFieldName) => {
            const categoryName = form.category.value;
            // checking if a form already exists for the corresponding category name
            if (categoriesLeft.has(categoryName)) {
                categoriesLeft.delete(categoryName); // if a form for a category exists, remove it from possible dropdown choices
            }
        });
        const dropDownOptions: Array<IOption> = [];
        categoriesLeft.forEach((category) => {
            dropDownOptions.push({
                label: category as string,
                value: category as string,
                icon: null,
            });
        });

        return [[null, dropDownOptions]];
    }

    private readonly onChangeGenerator = (index : number, fieldName : DefaultPourFormFieldName) => (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.dispatch(EditDefaultPourFormActions.onFormFieldChange(index, fieldName, event.target.value));
    }

    private readonly onSelectGenerator = (index : number, fieldName : DefaultPourFormFieldName) => (selectedOptionValue : string) => {
        this.props.dispatch(EditDefaultPourFormActions.onFormFieldChange(index, fieldName, selectedOptionValue));
    }

    // JSX Rendering

    private readonly createFormList = (isMobile : boolean) => {
        // Logic allows for addition of more categories down the road - not just the default 5
        const formList : Array<[JSX.Element, JSX.Element] | [JSX.Element]> = [];
        // Mobile devices only have 1 column whereas other devices have 2 columns
        if (isMobile) {
            this.props.editDefaultPourFormState.defaultPourForm.forEach((rowInfo, index) => {
                formList.push([this.createForm(rowInfo, index)]);
            });
        } else {
            this.props.editDefaultPourFormState.defaultPourForm.forEach((rowInfo, index) => {
                if (index % 2 === 0) {
                    formList.push([this.createForm(rowInfo, index)]);
                } else {
                    formList[Math.floor(index / 2)].push(
                        this.createForm(rowInfo, index));
                }
            });

        }

        if (isMobile) {
            return formList.map((form, index) => (
                <div className="row-double-form-container" key={ `row-container-${index}` }>
                    <Flex direction="row" align="center">
                        { form }
                    </Flex>
                </div>
            ));
        } else {
            return formList.map((form, index) => {
                if (form.length === 2) {
                    return (
                        <div className="row-double-form-container" key={ `row-container-${index}` }>
                            <Flex direction="row" align="center">
                                { form }
                            </Flex>
                        </div>
                    );
                }
                return (
                    <div className="row-single-form-container" key={ `row-container-${index}` }>
                        <Flex direction="row" align="center">
                            { form }
                        </Flex>
                    </div>
                );
            });
        }
    }

    private readonly createForm = (rowInfo : DefaultPourInfoByFormFieldName, index : number) => {
        const unitSelectedOption = this.unitOptions[0][1].find((unitOption) => {
            return unitOption.value === rowInfo.unit.value;
        });
        // specific left-column spacing styling applied to <Button/> component
        const isLeftColumn = index % 2 === 0 ? 'left-column' : '';
        return (
            <Flex direction="row" className="form-container" align="center" key={ `input-container-${index}` }>
                <div className="input-container category">
                    <ValidationInput
                        theme={ ValidationInputTheme.Basic }
                        inputClassName=""
                        type="text"
                        autoFocus={ false }
                        autoComplete={ null }
                        errorMessage={ rowInfo.category.errorMessage }
                        isValid={ rowInfo.category.isValid }
                        handleBlur={ this.doNothing }
                        handleFocus={ null }
                        handleChange={ this.onChangeGenerator(index, 'category') }
                        handleEnterClick={ this.doNothing }
                        label={ null }
                        hintText={ null }
                        value={ rowInfo.category.value }
                        isDisabled={ true } // JUST FOR NOW: disable category (until test backend with legacy nonsense to see if custom categories works ok)
                    />
                </div>
                <div className="input-container quantity">
                    <ValidationInput
                        theme={ ValidationInputTheme.Basic }
                        inputClassName=""
                        type="text"
                        autoFocus={ false }
                        autoComplete={ null }
                        errorMessage={ rowInfo.quantity.errorMessage }
                        isValid={ rowInfo.quantity.isValid }
                        handleBlur={ this.doNothing }
                        handleFocus={ null }
                        handleChange={ this.onChangeGenerator(index, 'quantity') }
                        handleEnterClick={ this.doNothing }
                        label={ null }
                        hintText="e.g. 1"
                        value={ rowInfo.quantity.value }
                        isDisabled={ false }
                    />
                </div>
                <div className="input-container unit">
                    <Select2DropdownMenu
                        theme={ Select2DropdownMenuTheme.Basic }
                        isSearchable={ false }
                        buttonShouldDisplaySelectedLabel={ true }
                        selectedOptionHeaderShown={ false }
                        sortedOptionsAndLabelName={ this.unitOptions }
                        selectedOption={ unitSelectedOption || null }
                        onOptionSelect={ this.onSelectGenerator(index, 'unit') }
                        placeholderText="Select Unit"
                        emptyState={ null }
                        hasCreateCustomOption={ false }
                        createCustomOption={ this.doNothing }
                        customOptionGroupLabel={ null }
                        createCustomOptionButtonLabel={ null }
                    />
                </div>
                <Button
                    buttonClassName={ `flat bevicon bevico-remove-inverse ${isLeftColumn}` }
                    isDisabled={ false }
                    isLoading={ false }
                    onClick={ this.handleDeleteGenerator(index) }
                />
            </Flex>
        );
    }
}

interface IStateProps {
    editDefaultPourFormState : IEditDefaultPourFormState;
}

const mapStateToProps = (state : IStateProps) : IStateProps => {
    return {
        editDefaultPourFormState: state.editDefaultPourFormState,
    };
};

export const ConnectedEditDefaultPourForm = connect<IStateProps, object, IConnectedEditDefaultPourFormProps, IStateProps>(mapStateToProps, null, null, { forwardRef: true })(EditDefaultPourForm);
