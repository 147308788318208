import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { cookieManager } from 'shared/lib/manager';

import 'url-search-params-polyfill';
import 'whatwg-fetch';

export const refreshToken = (callback = () => null, forceRefresh? : boolean) : Promise<void> => {
  const nextRefreshTime = Cookies.get('nextRefresh');
  const hasUserSessionCookie = !!Cookies.get('userSessionIdentifier');

  // If the user is offline, ignore refreshing the JWT
  // Users do not need a valid session for drink_price_tool
  const isOffline = !window.navigator.onLine;
  const isOnDrinkPriceTool = window.location.href.indexOf('drink_price_tool') !== -1;
  if (isOffline || isOnDrinkPriceTool) {
    return Promise.resolve();
  }
  const needsRefreshSoon = nextRefreshTime <= Date.now();
  if (!forceRefresh && !needsRefreshSoon && hasUserSessionCookie) {
    // Refresh once inside buffer period
    setTimeout(() => refreshToken(), nextRefreshTime - Date.now() + 1000);
    return Promise.resolve();
  }

  // Grab current JWT
  return Auth.currentAuthenticatedUser().then((cognitoUser : CognitoUser) => {
    return Auth.currentSession()
    .then((cognitoSession : CognitoUserSession) => {

      // Refresh the token
      const refreshPromise : Promise<CognitoUserSession> = new Promise((resolve, reject) => {
        cognitoUser.refreshSession(cognitoSession.getRefreshToken(), (err : any, newSession : CognitoUserSession) => {
          resolve(newSession);
        });
      });

      return Promise.resolve(refreshPromise).then((newSession) => {
         return Promise.resolve(onNewCognitoSession(newSession));
      });
    });
  }).catch((e) => {
    window.BevspotPlatformClient.logJsError('Error while trying to refresh token', 'refreshJWT.ts', e, true);
    window.location.href = window.AMPLIFY_CONFIG.urls.authBaseUrl;
  });
};

export const onNewCognitoSession = (userSession : CognitoUserSession | null) => {
  const SESSION_REFRESH_BUFFER_PERCENT = 0.1;
  if (userSession) {
    // Refresh time given 10% buffer, will force refresh 10% early
    const userSessionId = userSession.getIdToken();
    const newTokenDurationSeconds = userSessionId.getExpiration() - userSessionId.getIssuedAt();
    const tokenBuffer = (newTokenDurationSeconds * SESSION_REFRESH_BUFFER_PERCENT);
    const newTokenRefreshTime = newTokenDurationSeconds - tokenBuffer;
    const newToken = userSessionId.getJwtToken();

    const currentCookie = Cookies.get('userSessionIdentifier');
    // Session ID was not updated, safe to ignore
    if (newToken === currentCookie) {
      return Promise.resolve();
    }

    // Update JWT token in Lambda, thrift writes fail without this
    return window.fetch(`${window.AMPLIFY_CONFIG.urls.lambdaApiBase}/session`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${newToken}`,
      },
      method: 'POST'
    })
    // Make response from fetch readable
    .then((res) => res.json())
    // Handle response from Lambda
    .then((data) => {
      if (data.error) {
        throw new RuntimeException('Error refreshing token');
      }
      if (data.success) {
        // Reset the JWT using AWS Session Lambda
        setTimeout(() => refreshToken(() => null, false), newTokenRefreshTime * 1000 + 1000);

        cookieManager.setCookie('userSessionIdentifier', newToken, newTokenDurationSeconds);
        cookieManager.setCookie('nextRefresh', ((userSessionId.getExpiration() - tokenBuffer) * 1000).toString());

        // Send use to a next screen if there's a next
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('next')) {
          window.location.href = decodeURIComponent(urlParams.get('next') ?? "");
        }
      }
    });
  }
};

// Find a better solution for this in the future, any file including this will attempt to refresh tokens
// Retool API doesn't have BevspotPlatformClient, so checking this for now
if (window.BevspotPlatformClient) {
  setTimeout(() => {
    refreshToken(() => null, false);
  }, 0);

  window.addEventListener('online', () => {
    refreshToken(() => null, false);
  });
}
