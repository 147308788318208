import { IStringValue } from 'api/Core/IStringValue';

export class CatalogItemId implements IStringValue {
    constructor(private readonly value : string) {
    }

    public getValue() : string {
        return this.value;
    }

    public equals(other : CatalogItemId) : boolean {
        return this.getValue() === other.getValue();
    }

    public toString() : string {
        return `{CatalogItemId: ${this.value}}`;
    }
}
