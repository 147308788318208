import { IPOSIntegrationService } from 'api/Integration/interfaces/IPOSIntegrationService';
import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { LocationId } from 'api/Location/model/LocationId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import IntegrationExceptions from 'gen-thrift/integration_Exception_types';
import { ThunkAction } from 'redux-thunk';
import { ICloverIntegrationRowState } from 'shared/components/PosIntegration/CloverIntegrationRowReducers';
import { IExtraArguments } from 'shared/components/Provider';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { integrationService } from 'shared/lib/manager';
import { IAction, ISetShownAction } from 'shared/models/IAction';

export interface ICloverIntegrationModalStore {
    cloverIntegrationRowState : ICloverIntegrationRowState;
}

export const ActionTypes = {
    SET_MODAL_SHOWN: 'CLOVER_INTEGRATION/SET_MODAL_SHOWN',
    SET_EXISTING_INTEGRATION_STATE: 'CLOVER_INTEGRATION/SET_EXISTING_INTEGRATION_STATE',
    SET_IS_LOADING: 'CLOVER_INTEGRATION/SET_IS_LOADING',

    SET_MODAL_IS_LOADING: 'CLOVER_INTEGRATION/SET_MODAL_IS_LOADING',
    SET_MODAL_ERROR: 'CLOVER_INTEGRATION/SET_MODAL_ERROR',
    SET_MODAL_ACCESS_TOKEN: 'CLOVER_INTEGRATION/SET_MODAL_ACCESS_TOKEN',
};

export namespace CloverIntegrationActionInterfaces {
    export interface ISetLocationSelectorShown extends IAction {
        payload : {
            isShown : boolean
        };
    }

    export interface ISetModalError extends IAction {
        payload : {
            message : string | null
        };
    }

    export interface ISetModalShown extends ISetShownAction {
    }

    export interface ISetModalAccessToken extends IAction {
        payload : {
            accessToken : string | null
        };
    }

    export interface ISetIsLoading extends IAction {
        payload : {
            isLoading : boolean
        };
    }

    export interface ISetExistingIntegrationState extends IAction {
        payload : {
            integrationState : IntegrationState
        };
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        integrationService : IPOSIntegrationService;
    }

    export interface IThunkServices extends IExtraArguments {
        services : IServices;
    }
}

const setModalIsLoading = (
    isLoading : boolean
) : CloverIntegrationActionInterfaces.ISetIsLoading => ({
    payload : {
        isLoading
    },
    type: ActionTypes.SET_MODAL_IS_LOADING,
});

const setModalError = (
    message : string | null
) : CloverIntegrationActionInterfaces.ISetModalError => ({
    payload : {
        message,
    },
    type : ActionTypes.SET_MODAL_ERROR
});

const setModalShown = (
    isShown : boolean
) : CloverIntegrationActionInterfaces.ISetModalShown => ({
    payload : {
        isShown,
    },
    type : ActionTypes.SET_MODAL_SHOWN
});

const setModalAccessToken = (
    accessToken : string | null
) : CloverIntegrationActionInterfaces.ISetModalAccessToken => ({
    payload : {
        accessToken,
    },
    type : ActionTypes.SET_MODAL_ACCESS_TOKEN
});

const setIsLoading = (
    isLoading : boolean
) : CloverIntegrationActionInterfaces.ISetIsLoading => ({
    payload : {
        isLoading
    },
    type: ActionTypes.SET_IS_LOADING,
});

const setExistingCredentialState = (
    integrationState : IntegrationState
) : CloverIntegrationActionInterfaces.ISetExistingIntegrationState => ({
    payload : {
        integrationState,
    },
    type: ActionTypes.SET_EXISTING_INTEGRATION_STATE,
});

const fetchExistingIntegration = (
    locationId : LocationId
) : ThunkAction<Promise<IntegrationState>, ICloverIntegrationModalStore, CloverIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) : Promise<IntegrationState> => {
        dispatch(setIsLoading(true));
        const session = extraArguments.services.userSessionReader.getSessionId();

        return extraArguments.services.integrationService.getIntegrationState('clover', session, locationId)
        .then((data: Set<IntegrationState>) => {
            // Does not support Multi-connection
            const integrationState: IntegrationState = data.values().next().value;
            dispatch(setExistingCredentialState(integrationState));
            dispatch(setIsLoading(false));
            return integrationState;
        })
        .catch((e : Error) => {
            dispatch(setIsLoading(false));
            throw e;
        });
    };
};

const authorizeCloverIntegration = (
    locationId : LocationId,
    cloverMerchantId : string,
    authorizationCode : string
) : ThunkAction<Promise<void>, ICloverIntegrationModalStore, CloverIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {
        const session = extraArguments.services.userSessionReader.getSessionId();

        return integrationService.addCloverIntegration(
            session,
            locationId,
            cloverMerchantId,
            authorizationCode
        ).then((response) => {
            // get updated integration state from backend
            return extraArguments.services.integrationService.getIntegrationState('clover', session, locationId)
            .then((data: Set<IntegrationState>) => {
                // Does not support Multi-connection
                const integrationState: IntegrationState = data.values().next().value;
                dispatch(setExistingCredentialState(integrationState));
                dispatch(setModalIsLoading(false));
            });
        }).catch((error) => {
            if (error instanceof IntegrationExceptions.InvalidAuthorizationCodeException) {
                throw new RuntimeException('Failed to connect with Clover: access denied.');
            }
            dispatch(setModalIsLoading(false));
            throw error;
        });
    };
};

const disassociateLocationWithCloverMerchant = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, ICloverIntegrationModalStore, CloverIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {
        dispatch(setModalIsLoading(true));

        const session = extraArguments.services.userSessionReader.getSessionId();
        return integrationService.removeIntegration(
            'clover',
            session,
            locationId,
        ).then(() => {
            return extraArguments.services.integrationService.getIntegrationState('clover', session, locationId)
            .then((data: Set<IntegrationState>) => {
                // Does not support Multi-connection
                const integrationState: IntegrationState = data.values().next().value;
                dispatch(setExistingCredentialState(integrationState));
                dispatch(setModalIsLoading(false));
            });
        })
        .catch((error) => {
            dispatch(setModalIsLoading(false));
            throw error;
        });
    };
};

const retrieveAccessToken = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, ICloverIntegrationModalStore, CloverIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) : Promise<void> => {
        const session = extraArguments.services.userSessionReader.getSessionId();

        return integrationService.retrieveAccessToken(
            session,
            locationId,
            "clover"
        ).then((accessToken : string | null) => {
            if (accessToken == null || accessToken === "") {
                accessToken = "Access token not found.";
            }

            dispatch(setModalAccessToken(accessToken));
        }).catch((error) => {
            throw error;
        });
    };
};

export const CloverIntegrationRowActions = {
    authorizeCloverIntegration,
    disassociateLocationWithCloverMerchant,
    setModalError,
    setModalShown,
    fetchExistingIntegration,
    setModalAccessToken,
    retrieveAccessToken,
};
