import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class DeliveryLineItem {

    constructor (
        private readonly productId : ProductId,
        private readonly productQuantityUnit : ProductQuantityUnit,
        private readonly quantityInUnits : number,
        private readonly pricePerUnit : number,
        private readonly depositPerUnit : number,
        private readonly discount : number,
        private readonly tax : number,
        private readonly otherAdjustments : number,
        private readonly invoiceUploadId : InvoiceUploadId | null,
        private readonly invoiceUploadLineItemId : string | null,
    ) {
        if ((invoiceUploadId === null) !== (invoiceUploadLineItemId === null)) {
            throw new RuntimeException(`unexpected delivery line item with matching invoice upload (${invoiceUploadId}) and line item id (${invoiceUploadLineItemId})`);
        }
    }

    public getProductId() : ProductId {
        return this.productId;
    }

    public getProductQuantityUnit() : ProductQuantityUnit {
        return this.productQuantityUnit;
    }

    public getQuantityInUnits() : number {
        return this.quantityInUnits;
    }

    public getPricePerUnit() : number {
        return this.pricePerUnit;
    }

    public getDepositPerUnit() : number {
        return this.depositPerUnit;
    }

    public getInvoiceUploadId() : InvoiceUploadId | null {
        return this.invoiceUploadId;
    }

    public getInvoiceUploadLineItemId() : string | null {
        return this.invoiceUploadLineItemId;
    }

    public getDiscount() : number {
        return this.discount;
    }

    public getTax() : number {
        return this.tax;
    }

    public getOtherAdjustments() : number {
        return this.otherAdjustments;
    }
}
