// yeah we prefer rest params for readability but I'm not refactoring this as part of linting updates. If you touch this code, please fix.
/* eslint-disable prefer-rest-params */
import CoreTimeModel from 'gen-thrift/core_time_Model_types';
import InventoryModel from 'gen-thrift/inventory_Model_types';
import InventoryCountService from 'gen-thrift/InventoryCountService';
import InventoryExceptions from 'gen-thrift/inventory_Exceptions_types';

import moment from 'moment-timezone';

import { IOfflineQueueManager } from 'api/Core/OfflineQueueManager/interfaces/IOfflineQueueManager';
import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { IInventoryCountService } from 'api/InventoryCount/interfaces/IInventoryCountService';
import { InventoryCount } from 'api/InventoryCount/model/InventoryCount';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { InventoryCountMetadata } from 'api/InventoryCount/model/InventoryCountMetadata';
import { InventoryCountType } from 'api/InventoryCount/model/InventoryCountType';
import { ProductCount } from 'api/InventoryCount/model/ProductCount';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { InventoryCountObjectToThriftSerializer } from 'api/InventoryCount/serializer/InventoryCountObjectToThriftSerializer';
import { InventoryCountThriftToObjectSerializer } from 'api/InventoryCount/serializer/InventoryCountThriftToObjectSerializer';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import {ThriftApiUtils} from "api/ApiUtils";

export class InventoryCountServiceImpl implements IInventoryCountService {

    constructor (
        private inventoryCountServiceClient : InventoryCountService.InventoryCountServiceClient,
        private offlineQueueManager : IOfflineQueueManager,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer,
        private inventoryCountObjectToThriftSerializer : InventoryCountObjectToThriftSerializer,
        private inventoryCountThriftToObjectSerializer : InventoryCountThriftToObjectSerializer,
        private momentObjectToThriftSerializer : MomentObjectToThriftSerializer
    ) {}

    ////////////////////////
    // GETTERS
    ////////////////////////
    public getInventoryCountMetadatasById (
        userSessionId : UserSessionId,
        inventoryCountIds : StringValueSet<InventoryCountId>,
    ) : Promise<StringValueMap<InventoryCountId, InventoryCountMetadata>> {
        return new Promise<StringValueMap<InventoryCountId, InventoryCountMetadata>>((resolve, reject) => {
            this.inventoryCountServiceClient.getInventoryCountMetadatasById(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountIds(inventoryCountIds),
                (result : Array<InventoryModel.InventoryCountIdAndMetadata> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    const inventoryCountMetadatasByInventoryCountId = this.inventoryCountThriftToObjectSerializer.getMapFromInventoryCountIdsAndMetadata(result);
                    return resolve(inventoryCountMetadatasByInventoryCountId);
                }
            );
        });
    }

    public getInventoryCountsById (
        userSessionId : UserSessionId,
        inventoryCountIds : StringValueSet<InventoryCountId>,
    ) : Promise<StringValueMap<InventoryCountId, InventoryCount>> {
        return new Promise<StringValueMap<InventoryCountId, InventoryCount>>((resolve, reject) => {
            this.inventoryCountServiceClient.getInventoryCountsById(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountIds(inventoryCountIds),
                (result : Array<InventoryModel.InventoryCountIdAndInventoryCount> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    const inventoryCountsById = this.inventoryCountThriftToObjectSerializer.getMapFromInventoryCountIdsAndInventoryCount(result);
                    return resolve(inventoryCountsById);
                }
            );
        });
    }

    public getCurrentInventoryCountIdAndMetadataForLocation (
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<{inventoryCountId : InventoryCountId, inventoryCountMetadata : InventoryCountMetadata}> {
        return new Promise<{inventoryCountId : InventoryCountId, inventoryCountMetadata : InventoryCountMetadata}>((resolve, reject) => {
            this.inventoryCountServiceClient.getCurrentInventoryCountIdAndMetadataForLocation(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                (result : InventoryModel.InventoryCountIdAndMetadata | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve({
                        inventoryCountId: this.inventoryCountThriftToObjectSerializer.getInventoryCountId(result.inventoryCountId),
                        inventoryCountMetadata: this.inventoryCountThriftToObjectSerializer.getInventoryMetadata(result.inventoryMetadata)
                    });
                }
            );
        });
    }

    public getFinalizedInventoryCountMetadatasByIdForLocationInPeriod (
        userSessionId : UserSessionId,
        locationId : LocationId,
        periodStartInclusive : CoreTimeModel.TimestampWithMillisecondPrecision,
        periodEndExclusive : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<StringValueMap<InventoryCountId, InventoryCountMetadata>> {
        return new Promise<StringValueMap<InventoryCountId, InventoryCountMetadata>>((resolve, reject) => {
            this.inventoryCountServiceClient.getFinalizedInventoryCountMetadatasByIdForLocationInPeriod(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                periodStartInclusive,
                periodEndExclusive,
                (result : Array<InventoryModel.InventoryCountIdAndMetadata> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    const inventoryCountMetadataByInventoryCountId = this.inventoryCountThriftToObjectSerializer.getMapFromInventoryCountIdAndMetadatas(result);
                    return resolve(inventoryCountMetadataByInventoryCountId);
                }
            );
        });
    }

    public getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate (
        userSessionId : UserSessionId,
        locationId : LocationId,
        startIndexInclusive : number,
        maxResultSize : number,
    ) : Promise<{inventoryCountIds : Array<InventoryCountId>, inventoryCountMetadatasById : StringValueMap<InventoryCountId, InventoryCountMetadata>}> {
        return new Promise<{inventoryCountIds : Array<InventoryCountId>, inventoryCountMetadatasById : StringValueMap<InventoryCountId, InventoryCountMetadata>}>((resolve, reject) => {
            this.inventoryCountServiceClient.getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                startIndexInclusive,
                maxResultSize,
                (result : Array<InventoryModel.InventoryCountIdAndMetadata> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const sortedResult = result.sort((a, b) => {
                        return b.inventoryMetadata.inventoryCountTime.timeSinceUnixEpoch.value - a.inventoryMetadata.inventoryCountTime.timeSinceUnixEpoch.value;
                    });

                    const inventoryCountIds = this.inventoryCountThriftToObjectSerializer.getInventoryCountIds(
                        sortedResult.map((thriftInventoryCountIdAndMetadata) => {
                            return thriftInventoryCountIdAndMetadata.inventoryCountId;
                        })
                    );

                    const inventoryCountMetadatasById = this.inventoryCountThriftToObjectSerializer.getMapFromInventoryCountIdAndMetadatas(sortedResult);

                    return resolve({
                        inventoryCountIds,
                        inventoryCountMetadatasById
                    });
                }
            );
        });
    }

    public getMostRecentFinalizedInventoryCountIdAndMetadataForLocationBeforeTime (
        userSessionId : UserSessionId,
        locationId : LocationId,
        endTimeInclusive : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<{inventoryCountId : InventoryCountId, inventoryCountMetadata : InventoryCountMetadata}> {
        return new Promise<{inventoryCountId : InventoryCountId, inventoryCountMetadata : InventoryCountMetadata}>((resolve, reject) => {
            this.inventoryCountServiceClient.getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                endTimeInclusive,
                (result : InventoryModel.InventoryCountIdAndMetadata | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve({
                        inventoryCountId: this.inventoryCountThriftToObjectSerializer.getInventoryCountId(result.inventoryCountId),
                        inventoryCountMetadata: this.inventoryCountThriftToObjectSerializer.getInventoryMetadata(result.inventoryMetadata)
                    });
                }
            );
        });
    }

    public getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime (
        userSessionId : UserSessionId,
        locationId : LocationId,
        startTimeInclusive : moment.Moment,
    ) : Promise<{inventoryCountId : InventoryCountId, inventoryCountMetadata : InventoryCountMetadata} | null> {
        return new Promise<{inventoryCountId : InventoryCountId, inventoryCountMetadata : InventoryCountMetadata} | null>((resolve, reject) => {
            this.inventoryCountServiceClient.getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(startTimeInclusive),
                (result : InventoryModel.InventoryCountIdAndMetadata | Error) => {
                    if (result instanceof Error) {
                        if (result instanceof InventoryExceptions.NoSuchInventoryCountException) {
                            return resolve(null);
                        } else {
                            return reject(result);
                        }
                    }

                    return resolve({
                        inventoryCountId: this.inventoryCountThriftToObjectSerializer.getInventoryCountId(result.inventoryCountId),
                        inventoryCountMetadata: this.inventoryCountThriftToObjectSerializer.getInventoryMetadata(result.inventoryMetadata)
                    });
                }
            );
        });
    }

    ////////////////////////
    // CONFIGURATION UPDATERS
    ////////////////////////

    public createAndAddStorageAreaAtInventoryCount (
        userSessionId : UserSessionId,
        storageAreaName : string,
        storageAreaNote : string,
        locationId : LocationId,
        inventoryCountId : InventoryCountId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<StorageAreaId> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<StorageAreaId>((resolve, reject) => {
                    this.inventoryCountServiceClient.createAndAddStorageAreaAtInventoryCount(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        storageAreaName,
                        storageAreaNote,
                        this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                        clientTimestamp,
                        (result : InventoryModel.StorageAreaId | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            const storageAreaId = this.inventoryCountThriftToObjectSerializer.getStorageAreaId(result);
                            return resolve(storageAreaId);
                        }
                    );
                })
        );
    }

    public removeStorageAreaAtInventoryCount (
        userSessionId : UserSessionId,
        storageAreaId : StorageAreaId,
        inventoryCountId : InventoryCountId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {

        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<void>((resolve, reject) => {
                    this.inventoryCountServiceClient.removeStorageAreaAtInventoryCount(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                        clientTimestamp,
                        (result : void | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                })
        );
    }

    public addProductToStorageAreaAtInventoryCount (
        userSessionId : UserSessionId,
        productId : ProductId,
        storageAreaId : StorageAreaId,
        inventoryCountId : InventoryCountId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return this.offlineQueueManager.enqueueServiceCall(this, 'addProductToStorageAreaAtInventoryCount', arguments, () => {
            return ThriftApiUtils.sessionSafe(
                (sessionId) =>
                    new Promise<void>((resolve, reject) => {
                        this.inventoryCountServiceClient.addProductToStorageAreaAtInventoryCount(
                            this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                            this.productObjectToThriftSerializer.getThriftProductId(productId),
                            this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                            this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                            clientTimestamp,
                            (result : void | Error) => {
                                if (result instanceof Error) {
                                    return reject(result);
                                }
                                return resolve();
                            }
                        );
                    })
            );
        });
    }

    public removeProductFromStorageAreaAtInventoryCount (
        userSessionId : UserSessionId,
        productId : ProductId,
        storageAreaId : StorageAreaId,
        inventoryCountId : InventoryCountId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return this.offlineQueueManager.enqueueServiceCall(this, 'removeProductFromStorageAreaAtInventoryCount', arguments, () => {
            return ThriftApiUtils.sessionSafe(
                (sessionId) =>
                    new Promise<void>((resolve, reject) => {
                        this.inventoryCountServiceClient.removeProductFromStorageAreaAtInventoryCount(
                            this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                            this.productObjectToThriftSerializer.getThriftProductId(productId),
                            this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                            this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                            clientTimestamp,
                            (result : void | Error) => {
                                if (result instanceof Error) {
                                    return reject(result);
                                }
                                return resolve();
                            }
                        );
                    })
            );
        });
    }

    public removeProductFromAllStorageAreasAtInventoryCount (
        userSessionId : UserSessionId,
        productId : ProductId,
        inventoryCountId : InventoryCountId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return this.offlineQueueManager.enqueueServiceCall(this, 'removeProductFromAllStorageAreasAtInventoryCount', arguments, () => {
            return ThriftApiUtils.sessionSafe(
                (sessionId) =>
                    new Promise<void>((resolve, reject) => {
                        this.inventoryCountServiceClient.removeProductFromAllStorageAreasAtInventoryCount(
                            this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                            this.productObjectToThriftSerializer.getThriftProductId(productId),
                            this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                            clientTimestamp,
                            (result : void | Error) => {
                                if (result instanceof Error) {
                                    return reject(result);
                                }
                                return resolve();
                            }
                        );
                    })
            );
        });
    }

    ///////////////////////////
    // INVENTORY COUNT UPDATERS
    ///////////////////////////

    public setProductCountAtStorageArea (
        userSessionId : UserSessionId,
        productId : ProductId,
        storageAreaId : StorageAreaId,
        inventoryCountId : InventoryCountId,
        productCounts : Array<ProductCount>,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return this.offlineQueueManager.enqueueServiceCall(this, 'setProductCountAtStorageArea', arguments, () => {
            return ThriftApiUtils.sessionSafe(
                (sessionId) =>
                    new Promise<void>((resolve, reject) => {
                        this.inventoryCountServiceClient.setProductCountAtStorageArea(
                            this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                            this.productObjectToThriftSerializer.getThriftProductId(productId),
                            this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                            this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                            this.inventoryCountObjectToThriftSerializer.getThriftProductCounts(productCounts),
                            clientTimestamp,
                            (result : void | Error) => {
                                if (result instanceof Error) {
                                    return reject(result);
                                }
                                return resolve();
                            }
                        );
                    })
            );
        });
    }

    public createInventoryCount (
        userSessionId : UserSessionId,
        locationId : LocationId,
        inventoryCountTime : CoreTimeModel.TimestampWithMillisecondPrecision,
        inventoryCountType : InventoryCountType,
        name : string,
        note : string,
    ) : Promise<InventoryCountId> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<InventoryCountId>((resolve, reject) => {
                    this.inventoryCountServiceClient.createInventoryCount(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                        inventoryCountTime,
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountType(inventoryCountType),
                        name,
                        note,
                        (result : InventoryModel.InventoryCountId | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve(this.inventoryCountThriftToObjectSerializer.getInventoryCountId(result));
                        }
                    );
                })
        );
    }

    public createMergedInventoryCount (
        userSessionId : UserSessionId,
        locationId : LocationId,
        inventoryCountId0 : InventoryCountId,
        inventoryCountId1 : InventoryCountId,
        inventoryCountTime : moment.Moment,
        inventoryCountType : InventoryCountType,
        name : string,
        note : string,
    ) : Promise<InventoryCountId> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<InventoryCountId>((resolve, reject) => {
                    this.inventoryCountServiceClient.createMergedInventoryCount(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId0),
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId1),
                        this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(inventoryCountTime),
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountType(inventoryCountType),
                        name,
                        note,
                        (result : InventoryModel.InventoryCountId | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve(this.inventoryCountThriftToObjectSerializer.getInventoryCountId(result));
                        }
                    );
                })
        );
    }

    public finalizeInventoryCount (
        userSessionId : UserSessionId,
        inventoryCountId : InventoryCountId,
    ) : Promise<void> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<void>((resolve, reject) => {
                    this.inventoryCountServiceClient.finalizeInventoryCount(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                        (result : void | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                })
        );
    }

    public deleteInventoryCount (
        userSessionId : UserSessionId,
        inventoryCountId : InventoryCountId,
    ) : Promise<void> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<void>((resolve, reject) => {
                    this.inventoryCountServiceClient.deleteInventoryCount(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                        (result : void | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                })
        );
    }

    public setInventoryCountMetadata (
        userSessionId : UserSessionId,
        inventoryCountId : InventoryCountId,
        inventoryCountTime : CoreTimeModel.TimestampWithMillisecondPrecision,
        inventoryCountType : InventoryCountType,
        name : string,
        note : string,
    ) : Promise<void> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<void>((resolve, reject) => {
                    this.inventoryCountServiceClient.setInventoryCountMetadata(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                        inventoryCountTime,
                        this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountType(inventoryCountType),
                        name,
                        note,
                        (result : void | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                })
        );
    }
}
