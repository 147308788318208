export const FormatToMaximumTwoDecimalPlaces = (value: number | null): string => {
    return FormatToMaximumDecimalPlaces(value, 2);
};

const FormatToMaximumDecimalPlaces = (value : number | null, decimalPlaces : number) : string => {
    if (value === null) {
        return '-';
    }
    const multiple = Math.pow(10, decimalPlaces);
    return (Math.round(value * multiple) / multiple).toString(); // cap at n dp
};

const isPlural = (value : number) : boolean => {
    const abs = Math.abs(value);
    return abs === 0 || abs > 1;
};

export const FormatToMinumumTwoAndMaximumThreeDecimalPlaces = (value : number) : string => {
    const currentDecimalCount = (value.toString().split('.')[1] || []).length;

    if (currentDecimalCount <= 2) {
        return value.toFixed(2);
    }

    return value.toFixed(3);
};

const ToFixed = (value : number, decimalRoundedTo : number) : string => {
    // Floating point rounding fix for x.xx5
    // https://stackoverflow.com/a/43998255
    const factor = Math.pow(10, decimalRoundedTo + 4);
    if (value >= 0) {
        return (value + (0.01 / factor)).toFixed(decimalRoundedTo);
    } else {
        return (value - (0.01 / factor)).toFixed(decimalRoundedTo);
    }
};

export const numberUtils = {
    FormatToMaximumTwoDecimalPlaces,
    FormatToMaximumDecimalPlaces,
    isPlural,
    FormatToMinumumTwoAndMaximumThreeDecimalPlaces,
    ToFixed,
};
