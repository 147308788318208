import { StringValueMap } from 'api/Core/StringValueMap';
import { IProductService } from 'api/Product/interfaces/IProductService';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import moment from 'moment-timezone';

import { FreeTrialStatus } from 'api/Location/model/FreeTrialStatus';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationJSONToObjectSerializer } from 'api/Location/serializer/LocationJSONToObjectSerializer';
import { IDeliveryJSONObject } from 'api/Ordering/serializer/IDeliveryJSONObject';

import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import {
    ITransferTotalByProductIdByPartnerLocation,
    TransferTotalByProductId
} from 'apps/AggregatedTransferReport/reducers';
import { IExcessInventoryRowData } from 'apps/ExcessInventory/models/IExcessInventoryRowData';
import { IInventoryChartData } from 'apps/InventoryHistory/models/IInventoryChartData';
import { BevSpotLocation } from 'apps/InventoryTransfer/models/BevSpotLocation';
import { ISalesOnboardingData } from 'apps/NewSalesEntry/reducers/NewSalesEntryOptionReducers';
import { IBreadcrumbCredentialData } from 'shared/components/BreadcrumbIntegrationModal/models';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { IValuesByCategoryName } from 'shared/models/Charts/IValuesByCategoryName';
import { IValuesByDataIdAndCategoryName } from 'shared/models/Charts/IValuesByDataIdAndCategoryName';
import { DateTime, Timezone } from 'shared/models/DateTime';
import { IUserEmailSubscriptionDisplayDataByRetailerId, IUserRetailerEmailSubscriptionData, SubscriptionState, SubscriptionStateString } from 'shared/models/EmailSubscription';
import { PosIntegrationType } from 'shared/models/IntegrationStatus';
import {
    MongoDataInterfaces
} from 'shared/models/LegacyDataInterfaces';
import { IUserAccountInfo, IUserAccountName } from '../models/UserAccountName';

import LocationModel from 'gen-thrift/location_Model_types';

import { AjaxUtils, IRestApiRejection } from 'shared/utils/ajaxUtils';
import { PathUtils } from 'shared/utils/pathUtils';
import { StringValueSet } from 'api/Core/StringValueSet';

export class DjangoApiManager {
    private locationJSONToObjectSerializer = new LocationJSONToObjectSerializer();
    private logJsErrorCallCount = 0;
    private logJsErrorCallCountLastResetTime = moment();

    /**
     * Anna Lee Barber 2/27/17
     * When we can absolutely guarantee the structure of the product data returned by the back end, we should make the product package type more explicit than 'any'
     */
    public getUniqueDisplayNamesForTwoSimilarProducts(duplicateDisplayName : string, product1Package : any, product2Package : any, badDisplayNames : Array<string>, counter : number) : [string, string] {
        let product1DisplayName = duplicateDisplayName;
        let product2DisplayName = duplicateDisplayName;

        let prod1Package = product1Package;
        let prod2Package = product2Package;

        do {
            badDisplayNames.push(product1DisplayName);

            if (prod1Package.content !== null && prod1Package.content.unit !== null && prod2Package.content !== null && prod2Package.content.unit !== null) {

                if (prod1Package.content.unit === 'ml' ||
                    prod1Package.content.unit === 'l' ||
                    prod1Package.content.unit === 'oz' ||
                    prod1Package.content.unit === 'gal' ||
                    prod1Package.content.unit === 'kg' ||
                    prod1Package.content.unit === 'g' || prod1Package.unit === 'lbs') {
                    product1DisplayName = product1DisplayName + ' - ' + prod1Package.quantity_of_content + prod1Package.content.unit;
                    product2DisplayName = product2DisplayName + ' - ' + prod2Package.quantity_of_content + prod2Package.content.unit;
                } else {
                    product1DisplayName = product1DisplayName + ' - ' + prod1Package.content.unit;
                    product2DisplayName = product2DisplayName + ' - ' + prod2Package.content.unit;
                }

                prod1Package = prod1Package.content;
                prod2Package = prod2Package.content;

            } else {
                product2DisplayName = product2DisplayName + ' - ' + counter;
                product1DisplayName = product1DisplayName + ' - ' + (counter + 1);
                counter += 2;
                break;
            }
        } while (product1DisplayName === product2DisplayName);

        return [product1DisplayName, product2DisplayName];

    }

    public getUniqueDisplayNamesForSetOfProducts(productDataById : {[key : string] : any}) {
        const productNamesByProductId : {[key : string] : string} = {};

        const indexOfProductIdByDisplayName : {[key : string] : number} = {};

        const productIdKeys = Object.keys(productDataById);

        let counter = 0;
        const badDisplayNames : Array<string> = []; // maybe should be a set???
        productIdKeys.forEach((productId : string, index : number) => {
            let productDisplayName = productDataById[productId].brand + ' ' + productDataById[productId].name;

            /**
             * Handle the case of duplicate display names
             * Charts assume that Y-Axis Labels (here, the DataId part of IValuesByDataIdAndCategoryName, are unique. Products, however, are not guaranteed to have unique brand + name strings.
             * To make unique display names: first, add unit to the display names. If there are still duplicate displayNames, add a counter to the end of the string.
             * This needs to be done before initializing the IValuesByDataIdAndCategoryName object, so that we can go back and change names that later have a duplicate found without changing
             * the initialization order of the return object.
             */
            if (Object.prototype.hasOwnProperty.call(indexOfProductIdByDisplayName, productDisplayName)) {
                badDisplayNames.push(productDisplayName);

                const indexOfDuplicateName : number = indexOfProductIdByDisplayName[productDisplayName];
                const duplicateDisplayNameProductId = productIdKeys[indexOfDuplicateName];

                delete indexOfProductIdByDisplayName[productDisplayName];

                productDisplayName = productDisplayName + ' (' + productDataById[productId].package.unit + ')';

                let newProductDisplayNameForDuplicate = productNamesByProductId[duplicateDisplayNameProductId] + ' (' + productDataById[duplicateDisplayNameProductId].package.unit + ')';
                if (newProductDisplayNameForDuplicate === productDisplayName) {
                    const displayNameData : [string, string] = this.getUniqueDisplayNamesForTwoSimilarProducts(
                        productDisplayName,
                        productDataById[productId].package,
                        productDataById[duplicateDisplayNameProductId].package,
                        badDisplayNames,
                        counter);
                    productDisplayName = displayNameData[0];
                    newProductDisplayNameForDuplicate = displayNameData[1];
                }

                productNamesByProductId[productId] = productDisplayName;
                productNamesByProductId[duplicateDisplayNameProductId] = newProductDisplayNameForDuplicate;

                indexOfProductIdByDisplayName[productDisplayName] = index;
                indexOfProductIdByDisplayName[newProductDisplayNameForDuplicate] = indexOfDuplicateName;

            } else if (badDisplayNames.indexOf(productDisplayName) > -1) {
                productDisplayName = productDisplayName + ' (' + productDataById[productId].package.unit + ')';

                let prodPackage = productDataById[productId].package;
                while (Object.prototype.hasOwnProperty.call(indexOfProductIdByDisplayName, productDisplayName) || (badDisplayNames.indexOf(productDisplayName) > -1)) {

                    if (Object.prototype.hasOwnProperty.call(indexOfProductIdByDisplayName, productDisplayName)) {
                        const duplicateIndex = indexOfProductIdByDisplayName[productDisplayName];
                        const duplicateProduct = productDataById[productIdKeys[duplicateIndex]];

                        delete indexOfProductIdByDisplayName[productDisplayName];

                        const displayNameData : [string, string] = this.getUniqueDisplayNamesForTwoSimilarProducts(productDisplayName, prodPackage, duplicateProduct.package, badDisplayNames, counter);

                        productDisplayName = displayNameData[0];
                        const newProductDisplayNameForDuplicate = displayNameData[1];

                        productNamesByProductId[productIdKeys[duplicateIndex]] = newProductDisplayNameForDuplicate;
                        indexOfProductIdByDisplayName[newProductDisplayNameForDuplicate] = duplicateIndex;
                    } else {
                        if (prodPackage.content !== null && prodPackage.content.unit !== null) {
                            if (prodPackage.content.unit === 'ml' ||
                                prodPackage.content.unit === 'l' ||
                                prodPackage.content.unit === 'oz' ||
                                prodPackage.content.unit === 'gal' ||
                                prodPackage.content.unit === 'kg' ||
                                prodPackage.content.unit === 'g' || prodPackage.unit === 'lbs') {
                                productDisplayName = productDisplayName + ' - ' + prodPackage.quantity_of_content + prodPackage.content.unit;
                            } else {
                                productDisplayName = productDisplayName + ' - ' + prodPackage.content.unit;
                            }

                            prodPackage = prodPackage.content;
                        } else {
                            productDisplayName = productDisplayName + ' - ' + counter;
                            counter += 1;
                            break;
                        }
                    }
                }
            }

            indexOfProductIdByDisplayName[productDisplayName] = index;
            productNamesByProductId[productId] = productDisplayName;

        });

        return productNamesByProductId;
    }

    // ProductData is in the form that django passes it back in. Don't use the deserializer because for the inventory charts we have to support products with "bad" packaging
    // as long as the the inventory item would still how up on an inventory detail page
    // public so it can be tested
    public djangoInventoryChartDataToSortedDataValuesByDataId(valueByProductId : Array<[string, number]>, productDataById : {[key : string] : any}, categoryName : string) {
        const productNamesByProductId : {[key : string] : string} = this.getUniqueDisplayNamesForSetOfProducts(productDataById);

        const valuesByDataIdAndCategoryName : IValuesByDataIdAndCategoryName = {};
        valueByProductId.forEach((idAndValue : [string, number], index : number) => {
            const productId : string = idAndValue[0];

            const productDisplayName = productNamesByProductId[productId];

            const categoryDict : IValuesByCategoryName = {};

            /**
             * this ensures that on the inventory graphs, where there is only one category per bar, negative or zero values are not displayed as part of the chart.
             * this should not be done for any charts with > 1 category per bar
             */
            if (idAndValue[1] > 0) {
                categoryDict[categoryName] = idAndValue[1];
                valuesByDataIdAndCategoryName[productDisplayName] = categoryDict;
            }
        });

        return valuesByDataIdAndCategoryName;
    }

    public logJsError(message : string, file : string | null, error : Error | undefined, isHandled : boolean) {
        // rate limit: some FE code causes error infinite loops and spamming
        this.logJsErrorCallCount += 1;
        const now = moment();
        if (now.diff(this.logJsErrorCallCountLastResetTime) < 1000) {
            // current limit: 5/s per client
            if (this.logJsErrorCallCount >= 5) {
                // skip if rate limit exceeded
                return;
            }
        } else {
            this.logJsErrorCallCount = 0;
            this.logJsErrorCallCountLastResetTime = now;
        }

        let browser : string = 'Unknown';
        const userAgent = window.navigator.userAgent;

        if (userAgent.indexOf('Chrome') > -1) {
            browser = 'Google Chrome';
        } else if (userAgent.indexOf('Safari') > -1) {
            browser = 'Apple Safari';
        } else if (userAgent.indexOf('Opera') > -1) {
            browser = 'Opera';
        } else if (userAgent.indexOf('Firefox') > -1) {
            browser = 'Mozilla Firefox';
        } else if (userAgent.indexOf('MSIE') > -1) {
            browser = 'UNSUPPORTED Microsoft Internet Explorer';
        } else if (userAgent.indexOf('Trident') > -1) {
            browser = 'Microsoft Internet Explorer';
        } else if (userAgent.indexOf('Edge/') > -1) {
            browser = 'Microsoft Edge';
        }

        let logJsErrorUrl = '/log_js_error/';
        if (window.GLOBAL_RETAILER_ID) {
            logJsErrorUrl += `r/${ window.GLOBAL_RETAILER_ID }/`;
        }

        const formData : FormData = new FormData();
        formData.append('browser', browser);
        formData.append('userAgent', userAgent);
        formData.append('file', file || 'UNKNOWN');
        formData.append('isAdBlockActive', '' + !!window.isAdBlockActive);
        formData.append('isHandled', '' + isHandled);
        formData.append('message', message);
        formData.append('stack', error ? error.stack || (error as any).stacktrace || error : null);
        formData.append('url', window.location.href);

        AjaxUtils.ajaxPostForm(PathUtils.getAbsolutePathForRequest(logJsErrorUrl), formData);
    }

    public getAccessibleRetailers() : Promise<Array<BevSpotLocation>> {

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:list_of_retailers_where_user_can_inventory_transfer'))
            .then((response : any) => {
                const retailers : Array<BevSpotLocation> = [];
                response.forEach((responseRetailer : any) => {
                    retailers.push(new BevSpotLocation({
                        displayName: responseRetailer.name,
                        id: new LocationModel.LocationIdentifier({
                            value: responseRetailer._id,
                        }),
                    }));
                });
                retailers.sort((a, b) => {
                    const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                return Promise.resolve(retailers);
            });
    }

    public getUserNameById(
        userId : UserAccountId
    ) : Promise<IUserAccountName> {
        const queryParameters = {
            user_id: userId.getValue(),
            fields: 'first_name,last_name',
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:user'), queryParameters)
            .then((response : any) => {
                const name : IUserAccountName = {
                    firstName : response.first_name,
                    lastName : response.last_name,
                };
                return Promise.resolve(name);
            });
    }

    public getUserInfoById(
        userId : UserAccountId
    ) {
        const queryParameters = {
            user_id: userId.getValue(),
            fields: 'first_name,last_name,email_address',
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:user'), queryParameters)
            .then((response : any) => {
                const info : IUserAccountInfo = {
                    firstName: response.first_name,
                    lastName: response.last_name,
                    email: response.email,
                };
                return Promise.resolve(info);
            });
    }

    public getRetailerNameById(
        retailerId : LocationModel.LocationIdentifier
    ) : Promise<string> {
        const queryParameters = {
            retailer_id: retailerId.value,
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:retailer'), queryParameters)
            .then((response : any) => {
                let name = 'Unknown Retailer';
                if (response.name) {
                    name = response.name;
                }
                return Promise.resolve(name);
            });
    }

    public getRetailersById(
        retailerIds : Array<LocationModel.LocationIdentifier>
    ) : Promise<{ [key : string] : { name : string } }> {
        const postBody = {
            retailer_ids: retailerIds.map((retailerId) => retailerId.value),
        };
        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:retailer'), postBody)
            .then((response) => {
                return Promise.resolve(response);
            });
    }

    public getDistributorsByRetailerId(
        retailerId : LocationModel.LocationIdentifier
    ) {
        const queryParameters = {
            has_associated_order: true,
            retailer_id: retailerId.value,
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:distributor'), queryParameters)
            .then((response : { [key : string] : { name : string } }) => Promise.resolve(response));
    }

    public getInventoriesWithSittingValueSortedByDate(
        retailerId : LocationModel.LocationIdentifier,
        startIndexInclusive : number,
        maxResultCount : number,
    ) {
        const queryParameters = {
            retailer_id: retailerId.value,
            start_index_inclusive: startIndexInclusive,
            max_result_count: maxResultCount,
            get_sitting_value: true
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:inventory_history'), queryParameters)
            .then((response : Array<MongoDataInterfaces.IInventoryWithReportingData>) => Promise.resolve(response));
    }

    public getInventoriesWithSittingValueInDateRangeSortedByDate(
        retailerId : LocationModel.LocationIdentifier,
        startTimeInclusive : DateTime,
        endTimeExclusive : DateTime,
    ) {
        const queryParameters = {
            retailer_id: retailerId.value,
            start_time_inclusive: startTimeInclusive.getValueInTimezone(Timezone.UTC),
            end_time_exclusive: endTimeExclusive.getValueInTimezone(Timezone.UTC),
            get_sitting_value: true
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:inventory_history'), queryParameters)
            .then((response : Array<MongoDataInterfaces.IInventoryWithReportingData>) => Promise.resolve(response));
    }

    public getInventoriesSortedByDate(
        retailerId : string,
        startIndexInclusive : number,
        maxResultCount : number,
    ) {
        const queryParameters = {
            retailer_id: retailerId,
            start_index_inclusive: startIndexInclusive,
            max_result_count: maxResultCount,
            get_sitting_value: false
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:inventory_history'), queryParameters)
            .then((response : Array<MongoDataInterfaces.IInventory>) => {
                return Promise.resolve(response);
            });
    }

    public getNumberOfFinalizedInventoriesForRetailer(
        retailerId : LocationModel.LocationIdentifier,
    ) {
        const queryParameters = {
            retailer_id: retailerId.value,
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:number_of_inventories_for_retailer'), queryParameters)
        .then((response : { number_of_inventories : number }) => {
            return Promise.resolve(response.number_of_inventories);
        });
    }

    public getOrdersInDateRange(
        retailerId : LocationModel.LocationIdentifier,
        startTimeInclusive : DateTime,
        endTimeExclusive : DateTime,
    ) : Promise<{ [idValue : string] : IDeliveryJSONObject }> {
        const queryParameters = {
            retailer_id: retailerId.value,
            start_time_inclusive: startTimeInclusive.getValueInTimezone(Timezone.UTC),
            end_time_exclusive: endTimeExclusive.getValueInTimezone(Timezone.UTC),
            process_uploads: false,
            date_range_types: ['DELIVERY', 'PLACED', 'APPROVAL'].join('+'),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:retrieve_orders_in_date_range_request'), queryParameters)
        .then((response : { [idValue : string] : IDeliveryJSONObject }) => {
            return Promise.resolve(response);
        });
    }

    public getInventoryReportingDataForMostRecentInventory(
        retailerId : LocationModel.LocationIdentifier,
    ) : Promise<IInventoryChartData> {
        const queryParameters = {
            retailer_id: retailerId.value,
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:inventory_reporting_data'), queryParameters)
            .then((response : any) => {

                /**
                 * Anna Lee Barber 2/27/17
                 * When we can absolutely guarantee the structure of the product data returned by the back end, we should make the product data type more explicit than 'any'
                 */
                const productDataById : {[key : string] : any} = response.product_data_by_id;

                const chartData : IInventoryChartData = {
                    topProductsByDollarValue: this.djangoInventoryChartDataToSortedDataValuesByDataId(response.top_dollar_value_products_product_id_dollar_value_tuples, productDataById, 'dollarValue'),
                    topProductsByUnit: this.djangoInventoryChartDataToSortedDataValuesByDataId(response.top_number_unit_products_by_product_id_unit_value_tuples, productDataById, 'numUnits')
                };

                return Promise.resolve(chartData);
            })
            .catch((rejection : IRestApiRejection) => {
                if (rejection.status === 404) {
                    const chartData : IInventoryChartData = {
                        topProductsByDollarValue: {},
                        topProductsByUnit: {},
                    };
                    return Promise.resolve(chartData);
                }
                return Promise.reject(rejection);
            });
    }

    public getExcessInventoryReportData(
        retailerId : string,
        startInventoryId : string,
        endInventoryId : string
    ) {
        const queryParameters = {
            retailer_id: retailerId,
            start_inventory_id: startInventoryId,
            end_inventory_id: endInventoryId
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:excess_inventory_report_data'), queryParameters)
            .then((response : Array<any>) => {
                const deserializedData : Array<IExcessInventoryRowData> = response.map(
                    (row) => {
                        let productName;
                        if (row.product_brand !== null && row.product_brand.length > 0) {
                            productName = row.product_brand + ' ' + row.product_name;
                        } else {
                            productName = row.product_name;
                        }
                        return {
                            productId : row.product_id,
                            productName,
                            unitPrice: row.bottle_cost,
                            sittingUnits: row.sitting_bottle_count,
                            sittingDollars: row.sitting_dollar_value,
                            averageWeeklyUnitUsage: row.average_weekly_bottle_usage,
                            suggestedPar: row.suggested_par,
                            excessUnits: row.excess_bottle_count,
                            excessDollars: row.excess_dollar_value,
                        };
                    });
                return Promise.resolve(deserializedData);
            });
    }

    public validateZipCode (
        value : string,
    ) {
        return AjaxUtils.ajaxGet(PathUtils.getAbsolutePathForRequest('/user/validate_zipcode/'), { value });
    }

    // todo: move to api/Integration
    public getBreadcrumbCredential(
        locationId : LocationId
    ) {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:breadcrumb_credential'), queryParameters)
        .then((data : MongoDataInterfaces.IBreadcrumbCredentialData) => {
            const deserializedData : IBreadcrumbCredentialData = {
                username : data.breadcrumb_credential.username,
                password : data.breadcrumb_credential.password,
                isValid : data.credential_is_authenticated,
            };
            return Promise.resolve(deserializedData);
        })
        .catch((rejection : IRestApiRejection) => {
            if (rejection.status === 404) {
                return Promise.resolve(null);
            }
            return Promise.reject(rejection);
        });
    }

    public updateBreadcrumbCredential(
        locationId : LocationId,
        username : string,
        password : string,
    ) {
        const body = {
            username,
            password,
        };
        const queryParameters = {
            retailer_id: locationId.getValue(),

        };
        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:breadcrumb_credential'), body, queryParameters);
    }

    public deleteBreadcrumbCredential(
        locationId : LocationId
    ) {
        const queryParameters = {
            retailer_id: locationId.getValue(),

        };
        return AjaxUtils.ajaxDelete(urlWithoutRetailerId('api:breadcrumb_credential'), queryParameters);
    }

    public createItemLevelSalesReportConfiguration(
        locationId : LocationId,
        boundingInventoryId0 : string,
        boundingInventoryId1 : string,
        pmixFiles : Array<File> | null,
        salesOnboardingData : ISalesOnboardingData,
    ) {
        const {
            pourSizeByCategory,
            wellList,
            recipeFiles,
        } = salesOnboardingData;

        const formData : FormData = new FormData();
        formData.append('reference_id', boundingInventoryId0);
        formData.append('finalized_id', boundingInventoryId1);
        formData.append('well_list', wellList);

        // form does not support nested data nicely
        formData.append('pour_size_beer', pourSizeByCategory.beer);
        formData.append('pour_size_wine', pourSizeByCategory.wine);
        formData.append('pour_size_shot', pourSizeByCategory.shot);
        formData.append('pour_size_other', pourSizeByCategory.other);

        recipeFiles.forEach((file : File) => {
            formData.append(file.name, file, '(recipe) ' + file.name);
        });

        if (pmixFiles !== null) {
            pmixFiles.forEach((file : File) => {
                formData.append(file.name, file, '(pmix) ' + file.name);
            });
        }

        return AjaxUtils.ajaxPostForm(
            url('sales:reports:item_level:create_report_configuration', null, locationId.getValue(), null), formData);
    }

    public createItemLevelSalesReportConfigurationViaIntegration(
        locationId : LocationId,
        boundingInventoryId0 : string,
        boundingInventoryId1 : string,
        salesOnboardingData : ISalesOnboardingData,
        integrationType : PosIntegrationType
    ) {
        const {
            pourSizeByCategory,
            wellList,
            recipeFiles,
        } = salesOnboardingData;
        const formData : FormData = new FormData();
        formData.append('reference_id', boundingInventoryId0);
        formData.append('finalized_id', boundingInventoryId1);
        formData.append('integration_type', integrationType);
        formData.append('well_list', wellList);
        // form does not support nested data nicely
        formData.append('pour_size_beer', pourSizeByCategory.beer);
        formData.append('pour_size_wine', pourSizeByCategory.wine);
        formData.append('pour_size_shot', pourSizeByCategory.shot);
        formData.append('pour_size_other', pourSizeByCategory.other);
        recipeFiles.forEach((file : File) => {
            formData.append(file.name, file, '(recipe) ' + file.name);
        });

        return AjaxUtils.ajaxPostForm(
            url('sales:reports:item_level:create_report_configuration_via_integration', null, locationId.getValue(), null), formData);

    }

    public getRetailerFreeTrialStatus(
        retailerId : string,
    ) : Promise<FreeTrialStatus> {
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:retailer_free_trial_status'), { retailer_id: retailerId })
        .then((data) => {
            return this.locationJSONToObjectSerializer.getFreeTrialStatus(data);
        });
    }

    public getEmailSubscriptionsForUser(
    ) : Promise<IUserEmailSubscriptionDisplayDataByRetailerId> {
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:user_email_subscription'), {})
            .then((response : MongoDataInterfaces.IUserEmailSubscriptionDisplayData) => {
                const data : IUserEmailSubscriptionDisplayDataByRetailerId = {};
                // Arguably display-only data like this should be retrieved separately from the core data, but there is no consistent pattern for this at this point yet and we'll leave it here for now:
                const legacySubscribableRetailerNamesById = response.subscribable_retailer_name_by_id;

                const legacySubscriptionDataDyRetailerId = response.subscription_data_by_retailer_id;
                Object.keys(legacySubscriptionDataDyRetailerId).forEach((retailerId : string) => {
                    const legacyUserEmailSubscriptionData : MongoDataInterfaces.IUserRetailerEmailSubscriptionData = legacySubscriptionDataDyRetailerId[retailerId];
                    const {
                        retailer_daily_summary_email_configuration,
                        subscription_state_by_email_type,
                    } = legacyUserEmailSubscriptionData;
                    let dailySummaryEmailConfiguration;
                    if (retailer_daily_summary_email_configuration === null) {
                        dailySummaryEmailConfiguration = {
                            INCLUDE_FINALIZED_INVENTORY_CHANGE: false,
                            INCLUDE_NEW_PRODUCT_ORDER: false,
                            INCLUDE_PRICE_SETTING: false,
                        };
                    } else {
                        dailySummaryEmailConfiguration = {
                            INCLUDE_FINALIZED_INVENTORY_CHANGE: retailer_daily_summary_email_configuration.finalized_inventory_adjustment,
                            INCLUDE_NEW_PRODUCT_ORDER: retailer_daily_summary_email_configuration.product_ordered_not_in_inventory,
                            INCLUDE_PRICE_SETTING: retailer_daily_summary_email_configuration.price_setting,
                        };
                    }
                    const {
                        daily_summary,
                        weekly_summary,
                        order_placed_notification,
                        sales_report_processed_notification,
                        stripe_charge,
                    } = subscription_state_by_email_type;
                    const subscriptionData : IUserRetailerEmailSubscriptionData = {
                        subscriptionStateByEmailTypeString: {
                            RETAILER_DAILY_SUMMARY_EMAIL: SubscriptionState[daily_summary],
                            RETAILER_WEEKLY_SUMMARY_EMAIL: SubscriptionState[weekly_summary],
                            ORDER_PLACED_NOTIFICATION: SubscriptionState[order_placed_notification],
                            SALES_REPORT_PROCESSED_NOTIFICATION: SubscriptionState[sales_report_processed_notification],
                            STRIPE_CHARGE_NOTIFICATION: SubscriptionState[stripe_charge],
                        },
                        dailySummaryEmailConfiguration
                    };

                    data[retailerId] = {
                        retailerName: legacySubscribableRetailerNamesById[retailerId],
                        subscriptionData,
                    };
                });
                return Promise.resolve(data);
            });
    }

    public updateRetailerEmailSubscriptions(
        retailerId : string,
        subscriptions : IUserRetailerEmailSubscriptionData
    ) : Promise<void> {
        const {
            RETAILER_DAILY_SUMMARY_EMAIL,
            RETAILER_WEEKLY_SUMMARY_EMAIL,
            ORDER_PLACED_NOTIFICATION,
            SALES_REPORT_PROCESSED_NOTIFICATION,
            STRIPE_CHARGE_NOTIFICATION,
        } = subscriptions.subscriptionStateByEmailTypeString;
        const {
            INCLUDE_FINALIZED_INVENTORY_CHANGE,
            INCLUDE_NEW_PRODUCT_ORDER,
            INCLUDE_PRICE_SETTING,
        } = subscriptions.dailySummaryEmailConfiguration;

        let legacyRetailerDailySummaryEmailConfiguration;
        if (RETAILER_DAILY_SUMMARY_EMAIL !== SubscriptionState.SUBSCRIPTION_ON) {
            if (INCLUDE_FINALIZED_INVENTORY_CHANGE || INCLUDE_FINALIZED_INVENTORY_CHANGE || INCLUDE_PRICE_SETTING) {
                throw new RuntimeException('expected found checked configuration option on when not subscribing to retailer daily email');
            }
            legacyRetailerDailySummaryEmailConfiguration = null;
        } else {
            legacyRetailerDailySummaryEmailConfiguration = {
                finalized_inventory_adjustment: INCLUDE_FINALIZED_INVENTORY_CHANGE,
                product_ordered_not_in_inventory: INCLUDE_NEW_PRODUCT_ORDER,
                price_setting: INCLUDE_PRICE_SETTING,
            };
        }
        const body : MongoDataInterfaces.IUserRetailerEmailSubscriptionData = {
            subscription_state_by_email_type : {
                daily_summary: SubscriptionState[RETAILER_DAILY_SUMMARY_EMAIL] as SubscriptionStateString,
                weekly_summary: SubscriptionState[RETAILER_WEEKLY_SUMMARY_EMAIL] as SubscriptionStateString,
                order_placed_notification: SubscriptionState[ORDER_PLACED_NOTIFICATION] as SubscriptionStateString,
                sales_report_processed_notification: SubscriptionState[SALES_REPORT_PROCESSED_NOTIFICATION] as SubscriptionStateString,
                stripe_charge: SubscriptionState[STRIPE_CHARGE_NOTIFICATION] as SubscriptionStateString,
            },
            retailer_daily_summary_email_configuration: legacyRetailerDailySummaryEmailConfiguration,
        };
        const queryParameters = {
            retailer_id: retailerId,
        };

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:user_email_subscription'), body, queryParameters);
    }

    public getAggregatedTransferData(
        userSessionId : UserSessionId,
        retailerId : string,
        startDate : moment.Moment,
        endDate : moment.Moment,
        productService : IProductService,
    ) : Promise<ITransferTotalByProductIdByPartnerLocation> {
        const queryParameters = {
            retailer_id: retailerId,
            start_date_inclusive: startDate.format('YYYY-MM-DD HH:mm:ss'),
            end_date_exclusive: endDate.format('YYYY-MM-DD HH:mm:ss'),
        };
        const deserializeTransferTotalByProductId = (transferTotalByProductId : MongoDataInterfaces.IAggregatedTransferDataByProductId, productsById : StringValueMap<ProductId, Product>) => {
            const result : TransferTotalByProductId = new StringValueMap();
            Object.keys(transferTotalByProductId).forEach((productId) => {
                const productIdObject = new ProductId(productId);
                const product = productsById.getRequired(productIdObject);
                const bottlePackaging = PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging());
                result.set(
                    productIdObject,
                    {
                        unitsIn :  new QuantityInUnit(transferTotalByProductId[productId].FROM_PARTNER_TO_PERSPECTIVE_LOCATION.bottle_count, bottlePackaging),
                        dollarsIn :  transferTotalByProductId[productId].FROM_PARTNER_TO_PERSPECTIVE_LOCATION.dollar_value,
                        unitsOut : new QuantityInUnit(transferTotalByProductId[productId].FROM_PERSPECTIVE_TO_PARTNER_LOCATION.bottle_count, bottlePackaging),
                        dollarsOut : transferTotalByProductId[productId].FROM_PERSPECTIVE_TO_PARTNER_LOCATION.dollar_value,
                    }
                );
            });
            return result;
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:aggregated_transfer_data'), queryParameters)
        .then((data : MongoDataInterfaces.IAggregatedTransferData) => {
            const allRelevantProductIds = new StringValueSet<ProductId>();
            Object.keys(data.transfer_data_by_retailer_id).forEach((retailerIdValue) => {
                Object.keys(data.transfer_data_by_retailer_id[retailerIdValue]).forEach((productId) => {
                    allRelevantProductIds.add(new ProductId(productId));
                });
            });
            Object.keys(data.transfer_data_by_custom_location_name).forEach((customPartnerLocationName) => {
                Object.keys(data.transfer_data_by_custom_location_name[customPartnerLocationName]).forEach((productId) => {
                    allRelevantProductIds.add(new ProductId(productId));
                });
            });

            return productService.getProductsById(userSessionId, new LocationId(retailerId), allRelevantProductIds).then((productInformation) => {
                const transferDataByLocationId = new StringValueMap<LocationId, TransferTotalByProductId>();
                Object.keys(data.transfer_data_by_retailer_id).forEach((retailerIdValue) => {
                    transferDataByLocationId.set(new LocationId(retailerIdValue), deserializeTransferTotalByProductId(data.transfer_data_by_retailer_id[retailerIdValue], productInformation.productsById));
                });
                const transferDataByCustomLocationName = new Map<string, TransferTotalByProductId>();
                Object.keys(data.transfer_data_by_custom_location_name).forEach((customPartnerLocationName) => {
                    transferDataByCustomLocationName.set(customPartnerLocationName, deserializeTransferTotalByProductId(data.transfer_data_by_custom_location_name[customPartnerLocationName], productInformation.productsById));
                });

                return {
                    transferDataByLocationId,
                    transferDataByCustomLocationName,
                };
            });
        });
    }
}
