import { DistributorAdjustment } from 'api/Distributor/model/DistributorAdjustment';
import { DistributorDependentObjectSet } from 'api/Distributor/model/DistributorDependentObjectSet';
import { ExtraDistributorProperties, IJSONExtraDistributorProperties } from 'api/Distributor/model/ExtraDistributorProperties';
import DistributorExceptions from 'gen-thrift/distributor_Exceptions_types';
import DistributorModel from 'gen-thrift/distributor_Model_types';
import DistributorDependencyModel from 'gen-thrift/distributor_dependency_Model_types';
import ThriftDistributorService from 'gen-thrift/DistributorService';

import { DistributorObjectToThriftSerializer } from 'api/Distributor/serializer/DistributorObjectToThriftSerializer';
import { DistributorThriftToObjectSerializer } from 'api/Distributor/serializer/DistributorThriftToObjectSerializer';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { IDistributorService } from 'api/Distributor/interfaces/IDistributorService';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DistributorNameCollisionException } from 'api/Distributor/model/DistributorNameCollisionException';
import { DistributorRegionId } from 'api/Distributor/model/DistributorRegionId';
import { RetailerDistributorRelationship } from 'api/Distributor/model/RetailerDistributorRelationship';
import { LocationId } from 'api/Location/model/LocationId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { DistributorRep } from '../model/DistributorRep';
import { DistributorRepId } from '../model/DistributorRepId';

export class DistributorService implements IDistributorService {

    private static buildUrl(name : string) : string {
        return url(name, null, window.GLOBAL_RETAILER_ID, null);
    }

    private static buildFormData(formData : Record<string, any>) : FormData {
        const result = new FormData();
        Object.keys(formData).forEach((key) =>
            result.append(key, JSON.stringify(formData[key]))
         );
        return result;
    }

    private static getDistributorFormData(
        distributorId : DistributorId,
        distributor? : Distributor
    ) : FormData {
        let formData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        if (typeof distributor !== 'undefined') {
            formData = distributor.getRestFormData(formData);
        }
        return formData;
    }

    private static async getAjaxDistributorData(
        thriftPromise : Promise<StringValueMap<DistributorId, Distributor>>,
        formData : FormData,
        includeDistributorsWithNoRelationship : boolean,
    ) : Promise<StringValueMap<DistributorId, Distributor>> {
        return Promise.all([
            thriftPromise,
            AjaxUtils.ajaxGet(DistributorService.buildUrl('account:relationships:get'), formData),
        ])
        .then((responses) => {
            const [ thriftResponse, retailerDistributorRelationshipResponse ] = responses;

            const distributorsById = new StringValueMap<DistributorId, Distributor>();
            thriftResponse.forEach((distributor : Distributor, key : DistributorId) => {
                const relationshipEntry : IJSONExtraDistributorProperties = retailerDistributorRelationshipResponse[key.getValue()];
                if (typeof relationshipEntry !== 'undefined') {
                    distributor.setExtraDistributorProperties(ExtraDistributorProperties.createFromJSONObject(distributor.getName(), relationshipEntry));
                    distributorsById.set(key, distributor);
                } else if (includeDistributorsWithNoRelationship) {
                    distributorsById.set(key, distributor);
                }
            });

            return Promise.resolve(distributorsById);
        });
    }

    constructor(
        private readonly thriftDistributorClient : ThriftDistributorService.DistributorServiceClient,
        private readonly distributorObjectToThriftSerializer : DistributorObjectToThriftSerializer,
        private readonly distributorThriftToObjectSerializer : DistributorThriftToObjectSerializer,
        private readonly userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private readonly locationObjectToThriftSerializer : LocationObjectToThriftSerializer
    ) { }

    public async createDistributor(
        userSessionId : UserSessionId,
        distributor : Distributor,
    ) : Promise<DistributorId> {
        const distributorId : DistributorId = await new Promise<DistributorId>((resolve, reject) => {
            this.thriftDistributorClient.createDistributor(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.distributorObjectToThriftSerializer.getThriftDistributor(distributor),
                (result : DistributorModel.DistributorId | Error) => {
                    if (result instanceof DistributorExceptions.DistributorNameCollisionException) {
                        return resolve(
                            this.distributorThriftToObjectSerializer.getDistributorId(
                                result.collidedDistributorsById[0].distributorId));
                    }
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve(
                        this.distributorThriftToObjectSerializer.getDistributorId(result)
                    );
                }
            );
        });

        await this.addDistributorRelationshipForCurrentRetailer(distributorId, distributor);

        return distributorId;
    }

    public async updateDistributorRetailerRelationshipData(
        userSessionId : UserSessionId,
        distributorId : DistributorId,
        distributor : Distributor,
    ) : Promise<void> {
        await this.setRetailerSpecificData(distributorId, distributor);
    }

    public async deleteDistributor(
        userSessionId : UserSessionId,
        distributorId : DistributorId,
        ) : Promise<void> {
            await this.thriftDistributorClient.deleteDistributor(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId)
            );
    }

    public renameDistributor(
        userSessionId : UserSessionId,
        distributorId : DistributorId,
        name : string,
        shortName : string,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftDistributorClient.updateDistributorName(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId),
                name,
                shortName,
                (result : DistributorModel.DistributorId | Error) => {
                    if (result instanceof DistributorExceptions.DistributorNameCollisionException) {
                        return reject(new DistributorNameCollisionException(this.distributorThriftToObjectSerializer.getDistributorsById(result.collidedDistributorsById)));
                    }
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public setDistributorRegion(
        userSessionId : UserSessionId,
        distributorId : DistributorId,
        regionId : DistributorRegionId
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftDistributorClient.setDistributorRegion(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId),
                this.distributorObjectToThriftSerializer.getThriftDistributorRegionId(regionId),
                (result2 : void | Error) => {
                    if (result2 instanceof Error) {
                        return reject(result2);
                    }
                    return resolve();
                }
            );
        });
    }

    public async getDistributorsById(
        distributorIds : StringValueSet<DistributorId>,
        includeDistributorsWithNoRelationship? : boolean,
    ) : Promise<StringValueMap<DistributorId, Distributor>> {
        if (distributorIds.size === 0) {
            return Promise.resolve(new StringValueMap());
        }

        return DistributorService.getAjaxDistributorData(
            new Promise<StringValueMap<DistributorId, Distributor>>((resolve, reject) => {
                this.thriftDistributorClient.retrieveDistributorIdsAndDistributorsForIdSet(
                    this.distributorObjectToThriftSerializer.getThriftDistributorIds(distributorIds),
                    (result : Array<DistributorModel.DistributorIdAndDistributor> | Error) => {
                        if (result instanceof Error) {
                            return reject(result);
                        } else {
                            return resolve(
                                this.distributorThriftToObjectSerializer.getDistributorsById(result)
                            );
                        }
                    }
                );
            }),
            DistributorService.buildFormData({ distributorIds }),
            (typeof includeDistributorsWithNoRelationship === 'undefined') ? false : includeDistributorsWithNoRelationship
        );
    }

    public getAvailableDistributorsByIdForLocation(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<StringValueMap<DistributorId, Distributor>> {
        return DistributorService.getAjaxDistributorData(
            new Promise<StringValueMap<DistributorId, Distributor>>((resolve, reject) =>
                this.thriftDistributorClient.retrieveDistributorIdsAndDistributorsAvailableToRetailer(
                    this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                    this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                    (result : Array<DistributorModel.DistributorIdAndDistributor> | Error) => {
                        if (result instanceof Error) {
                            return reject(result);
                        } else {
                            return resolve(this.distributorThriftToObjectSerializer.getDistributorsById(result));
                        }
                    }
                )
            ),
            DistributorService.buildFormData({
                userSessionId: userSessionId.getValue(),
                locationId: locationId.getValue()
            }),
            false
        );
    }

    public getRetailerDistributorRelationships(
        locationId : LocationId
    ) : Promise<StringValueMap<DistributorId, RetailerDistributorRelationship>> {
        return AjaxUtils.ajaxGet('/api/retailer/' + locationId.getValue() + '/distributors/', {})
            .then((response : {[key : string] : any}) => {
                const result = new StringValueMap<DistributorId, RetailerDistributorRelationship>();
                const distributorIds = Object.keys(response);

                distributorIds.forEach((distId : string) => {
                    const distributorId = new DistributorId(distId);
                    const relationship = new RetailerDistributorRelationship(
                        response[distId].account_id,
                        response[distId].reps);
                    result.set(distributorId, relationship);
                });

                return Promise.resolve(result);
            });
    }

    public getAllDistributorsById(
        userSessionId : UserSessionId,
    ) : Promise<StringValueMap<DistributorId, Distributor>> {
        return DistributorService.getAjaxDistributorData(
            new Promise<StringValueMap<DistributorId, Distributor>>((resolve, reject) =>
                this.thriftDistributorClient.retrieveAllDistributorIdsAndDistributors(
                    this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                    (result : Array<DistributorModel.DistributorIdAndDistributor> | Error) =>
                        (result instanceof Error) ? reject(result) :
                            resolve(this.distributorThriftToObjectSerializer.getDistributorsById(result))
                )
            ),
            DistributorService.buildFormData({ userSessionId: userSessionId.getValue() }),
            false,
        );
    }

    public getAllRegionIdsByDistributorId(
        userSessionId : UserSessionId
    ) : Promise<StringValueMap<DistributorId, DistributorRegionId | null>> {
        return new Promise<StringValueMap<DistributorId, DistributorRegionId | null>>((resolve, reject) => {
            this.thriftDistributorClient.retrieveAllDistributorIdsAndRegions(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                (result : Array<DistributorModel.DistributorIdAndRegionId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve(
                            this.distributorThriftToObjectSerializer.getRegionIdsByDistributorId(result)
                        );
                    }
                }
            );
        });
    }

    public getAllDistributorRegionIds (
        userSessionId : UserSessionId
    ) : Promise<StringValueSet<DistributorRegionId>> {
        return new Promise<StringValueSet<DistributorRegionId>>((resolve, reject) => {
            this.thriftDistributorClient.retrieveAllDistributorRegionIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                (result : Array<DistributorModel.DistributorRegionId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve(
                            this.distributorThriftToObjectSerializer.getDistributorRegionIds(result)
                        );
                    }
                }
            );
        });
    }

    public getDistributorDependencies (
        distributorId : DistributorId
    ) : Promise<DistributorDependentObjectSet> {
        return new Promise<DistributorDependentObjectSet>((resolve, reject) => {
            this.thriftDistributorClient.retrieveDistributorDependencies(
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId),
                (result : DistributorDependencyModel.DistributorDependentObjectSet | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve(
                            this.distributorThriftToObjectSerializer.getDistributorDependentObjectSet(result)
                        );
                    }
                }
            );
        });
    }

    public addDistributorRelationshipForCurrentRetailer (
        distributorId : DistributorId,
        distributor? : Distributor
    ) : Promise<void> {
        return AjaxUtils.ajaxPostForm(
            DistributorService.buildUrl('account:relationships:add'),
            DistributorService.getDistributorFormData(distributorId, distributor)
        );
    }

    public setRetailerSpecificData(
        distributorId : DistributorId,
        distributor? : Distributor
    ) : Promise<void> {
        return AjaxUtils.ajaxPostForm(
            DistributorService.buildUrl('account:relationships:update'),
            DistributorService.getDistributorFormData(distributorId, distributor)
        );
    }

    public async addRepsToDistributor(
        distributorId : DistributorId,
        reps : Array<DistributorRep>,
    ) : Promise<void> {

        const addRep = async (rep : DistributorRep) : Promise<void> => {
            let repFormData = new FormData();
            repFormData.append('distributor_id', distributorId.getValue());
            repFormData = rep.getRestFormData(repFormData);
            await AjaxUtils.ajaxPostForm(
                DistributorService.buildUrl('account:relationships:add_rep'),
                repFormData,
            );
        };

        const addRepPromises = new Array<Promise<void>>(reps.length);
        reps.forEach((rep) => addRepPromises.push(addRep(rep)));

        await Promise.all(addRepPromises);
    }

    public removeDistributorRep(
        userSessionId : UserSessionId,
        distributorId : DistributorId,
        distributorRepId : DistributorRepId,
    ) : Promise<void> {
        const urlValue = url('account:relationships:remove_rep', null, null, null);

        const formData : FormData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        formData.append('rep_id', distributorRepId.getValue());

        return AjaxUtils.ajaxPostForm(urlValue, formData);
    }

    public updateDistributorRepField(
        userSessionId : UserSessionId,
        distributorId : DistributorId,
        distributorRepId : DistributorRepId,
        field : 'name' | 'email' | 'phone',
        value : string,
    ) : Promise<void> {
        const urlValue = url('account:relationships:update_sms', null, null, null);

        const formData : FormData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        formData.append('rep_id', distributorRepId.getValue());
        formData.append('field', field);
        formData.append(field, value);

        return AjaxUtils.ajaxPostForm(urlValue, formData);
    }

    public async addAdjustmentsToDistributor(
        distributorId : DistributorId,
        adjustments : Array<DistributorAdjustment>,
    ) : Promise<void> {

        const addAdjustment = async (adjustment : DistributorAdjustment) : Promise<void> => {
            let adjustmentFormData = new FormData();
            adjustmentFormData.append('distributor_id', distributorId.getValue());
            adjustmentFormData = adjustment.getRestFormData(adjustmentFormData);
            await AjaxUtils.ajaxPostForm(
                DistributorService.buildUrl('account:relationships:add_adjustment'),
                adjustmentFormData,
            );
        };

        const addAdjustmentPromises = new Array<Promise<void>>(adjustments.length);
        adjustments.forEach((adjustment) => addAdjustmentPromises.push(addAdjustment(adjustment)));

        await Promise.all(addAdjustmentPromises);
    }



    public setShouldSendSMSForDistributorRep(
        userSessionId : UserSessionId,
        distributorId : DistributorId,
        distributorRepId : DistributorRepId,
        shouldSendSMS : boolean,
    ) : Promise<void> {
        const urlValue = url('account:relationships:update_sms', null, null, null);

        const formData : FormData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        formData.append('rep_id', distributorRepId.getValue());
        formData.append('to_sms', shouldSendSMS.toString());

        return AjaxUtils.ajaxPostForm(urlValue, formData);
    }

    public setShouldAttachCSVForDistributorRep(
        userSessionId : UserSessionId,
        distributorId : DistributorId,
        distributorRepId : DistributorRepId,
        shouldAttachCSV : boolean,
    ) : Promise<void> {
        const urlValue = url('account:relationships:update_attach_csv', null, null, null);

        const formData : FormData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        formData.append('rep_id', distributorRepId.getValue());
        formData.append('attach_csv', shouldAttachCSV.toString());

        return AjaxUtils.ajaxPostForm(urlValue, formData);
    }

}
