import React from 'react';
import { StringUtils } from 'shared/utils/stringUtils';

import { IOption } from '../Dropdown/DropdownMenu';
import { SearchBar, SearchBarTheme } from '../SearchBar/SearchBar';

import { Button } from 'shared/components/Button';

import { cleanPhrase } from 'shared/utils/sortingFilteringAndGroupingUtils';

import { OptionsAndLabelNameTuples } from './Select2DropdownMenu';

import './Select2DropdownMenu.scss'; // this is basically copied from BulkEditDropdownMenu.scss

export enum Select2DropdownMenuTheme {
    Default = SearchBarTheme.Default,
    Basic = SearchBarTheme.Basic,
    BasicInverted = SearchBarTheme.BasicInverted,
}

export interface IGenericSelect2DropdownMenuProps {
    isOpen : boolean;
    handleDropdownOpenClose : (dropdownIsOpen : boolean) => void;

    theme? : Select2DropdownMenuTheme;
    classes? : string;

    isSearchable : boolean;
    selectedOptionHeaderShown : boolean;
    sortedOptionsAndLabelName : OptionsAndLabelNameTuples;
    selectedOption : IOption | null; // should be null always if you can select multiple options
    placeholderText : string | null;
    buttonShouldDisplaySelectedLabel : boolean;
    emptyState : JSX.Element | null;
    footerElement : JSX.Element | null;
    maxOptionsDisplayedPerGroup? : number;
    openDirection? : 'up' | 'down';
    isTitleCase? : boolean;
    createCustomOptionIsLoading : boolean; // necessary for support when create custom hits backend (storage areas)
    buttonElement? : JSX.Element; // to replace header button with BevSpot button

    getSelectedOptionElementForHeader : (selectedOption : IOption) => JSX.Element | undefined;
    getOptionElementsForGroup : (filteredOptions : Array<IOption>, selectedOption : IOption | null) => Array<JSX.Element | null>; // TODO consider something besides this selectedOption thing
    hasCreateCustomOption : boolean;
    createCustomOption : ((optionText : string) => void) | null;
    createCustomOptionButtonLabel : string | null;
    customOptionGroupLabel : string | null;  // specifies which group the create button should be in - assumes only one such group exists but WILL render the button wherever the group label is found in sortedOptionsAndLabelName!!
    onDropdownBlur? : () => void;
    onSearchValueChanged? : (newValue : string | null) => void;
}

export interface IGenericSelect2DropdownMenuState {
    searchBarState : {
        value : string | null;
        filteredValue : string | null;
        isDisabled : boolean;
        isFocused : boolean;
    };
    filteredOptionsAndLabelName : OptionsAndLabelNameTuples;
}

export class GenericSelect2DropdownMenu extends React.Component<IGenericSelect2DropdownMenuProps, IGenericSelect2DropdownMenuState> {
    private dropdownMenuOverlayDiv : HTMLDivElement | null;
    private componentIsOpening : boolean;

    constructor(props : IGenericSelect2DropdownMenuProps) {
        super(props);

        this.state = {
            searchBarState: {
                value: null,
                filteredValue : null,
                isDisabled: false,
                isFocused: false,
            },
            filteredOptionsAndLabelName: this.props.sortedOptionsAndLabelName,
            // isOpen: false, // in the future, could pass in isOpen default as a prop and set this to that
        };
        this.dropdownMenuOverlayDiv = null;
        this.componentIsOpening = this.props.isOpen;
    }

    public componentDidMount() {
        // 6/20/17 can't test that this properly triggers the function with karma/sinon
        window.addEventListener('click', this.handleOnOutsideComponentClick);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.handleOnOutsideComponentClick);
    }

    public UNSAFE_componentWillReceiveProps(nextProps : IGenericSelect2DropdownMenuProps) {
        if (nextProps.sortedOptionsAndLabelName !== this.props.sortedOptionsAndLabelName) { // if options have changed, reset the state
            const newFilteredOptions : Array<[string | null, Array<IOption>]> = this.filterOptionsBasedOnSearchTerm(this.state.searchBarState.value, nextProps.sortedOptionsAndLabelName);
            this.setState({
                filteredOptionsAndLabelName: newFilteredOptions,
            });
        }
    }

    public render() {
        const {
            isSearchable,
            selectedOptionHeaderShown,
            openDirection,
            selectedOption,
            emptyState,
            placeholderText,
            hasCreateCustomOption,
            customOptionGroupLabel,
            buttonShouldDisplaySelectedLabel,
            getSelectedOptionElementForHeader,
            footerElement,
            maxOptionsDisplayedPerGroup,
            isOpen,
            buttonElement,
            theme,
        } = this.props;

        const arrowDirection = isOpen ? 'up' : 'down';

        const emptyStateValue = emptyState === null ? 'No results found' : emptyState;
        const emptyStateElement = (
            <div className="dropdown-menu-empty-state-message dropdown-menu-component-option">
                { emptyStateValue }
            </div>
        );

        // We'll probably re-evaluate this behavior, but currently we want the custom option to be nested under a group (including the "un-grouped" case i.e. the button is in the "null" group)
        // and all groups should display even if empty in this case.
        const shouldShowEmptyStateInGroup = hasCreateCustomOption;
        // Potentially each group should have its own empty state. For now just use one.
        const groupEmptyState = shouldShowEmptyStateInGroup ? emptyStateElement : null;

        let dropdownPlaceholderText : string | null = placeholderText;
        if (buttonShouldDisplaySelectedLabel && selectedOption !== null) {
            dropdownPlaceholderText = selectedOption.label;
        }

        const hasCustomOptionClassName = hasCreateCustomOption ? ' dropdown-with-custom-option' : '';

        const totalOptionsShown = this.state.filteredOptionsAndLabelName.reduce((accumulator : number, filteredOptionsAndLabel : [string | null, Array<IOption>]) => {
            return accumulator + filteredOptionsAndLabel[1].length;
        }, 0);

        let dropdownOverlay : JSX.Element | null = null;

        if (isOpen) {
            let searchBarTheme = SearchBarTheme.Boxed;
            if (theme === Select2DropdownMenuTheme.Basic) {
                searchBarTheme = SearchBarTheme.Basic;
            } else if (theme === Select2DropdownMenuTheme.BasicInverted) {
                searchBarTheme = SearchBarTheme.BasicInverted;
            }

            const excludedOptionCount = this.getExcludedOptionCount(
                this.state.filteredOptionsAndLabelName,
                maxOptionsDisplayedPerGroup
            );

            dropdownOverlay = (
                <div className="select-2-dropdown-menu mobile-dropdown-overlay">
                    <div
                        id="generic-dropdown-searchbar"
                        className={ 'select-2-dropdown-inner-container' + (openDirection === 'up' ? ' dropdown-menu-open-up' : '') }
                        ref={ this.getOverlayRef }
                    >
                        { isSearchable &&
                            <SearchBar
                                theme={ searchBarTheme }
                                placeholderText={ placeholderText }
                                value={ this.state.searchBarState.filteredValue }
                                isDisabled={ this.state.searchBarState.isDisabled }
                                isFocused={ this.state.searchBarState.isFocused }
                                clearSearchBar={ this.handleOnClearSearchBar }
                                handleOnChange={ this.handleOnSearchBarValueChange }
                                handleEnterClick={ this.handleOnSearchBarEnterClick }
                                handleBlur={ this.handleOnSearchBarBlur }
                                handleFocus={ this.handleOnSearchBarFocus }
                            />
                        }
                        <div className={ 'dropdown-menu-component-options' + hasCustomOptionClassName }>
                            <div className="dropdown-menu-component-options-scroll">
                                { selectedOption !== null && selectedOptionHeaderShown &&
                                    <div className="current-dropdown-value">
                                        { getSelectedOptionElementForHeader(selectedOption) }
                                    </div>
                                }
                                { this.getGroupLists(
                                    this.state.filteredOptionsAndLabelName,
                                    selectedOption,
                                    hasCreateCustomOption,
                                    customOptionGroupLabel,
                                    groupEmptyState,
                                    maxOptionsDisplayedPerGroup
                                ) }
                                { (excludedOptionCount > 0) &&
                                    <div className="dropdown-menu-component-option-list">
                                        <div className="dropdown-group-label">Filter to see { excludedOptionCount } more option{ excludedOptionCount === 1 ? '' : 's' }...</div>
                                    </div>
                                }
                            </div>
                            {
                                (totalOptionsShown === 0 && !shouldShowEmptyStateInGroup) &&
                                emptyStateElement
                            }
                        </div>
                        { footerElement }
                    </div>
                </div>
            );
        }

        return (
            <div className={ this.generateClassNames() }>
                <div
                    className={ `${ buttonElement ? '' : 'dropdown-menu-component-button btn' }` } // want to be able to use normal button styles if buttonElement passed in
                    onClick={ this.handleSetDropdownIsOpen }
                >
                    { buttonElement &&
                        <div className="dropdown-placeholder-bevspot-button">{ buttonElement }</div>
                    }
                    { !buttonElement &&
                        <div>
                            <div className="dropdown-placeholder-string ellipsis-out">
                                { dropdownPlaceholderText }
                            </div>
                            <span className={ `dropdown-menu-component-arrow bevicon bevico-arrow_drop_${ arrowDirection }` }/>
                        </div>
                    }
                    { (theme === Select2DropdownMenuTheme.Basic || theme === Select2DropdownMenuTheme.BasicInverted) &&
                        <React.Fragment>
                            <hr className="active-border"/>
                            <hr className="bottom-border"/>
                        </React.Fragment>
                    }
                </div>
                { dropdownOverlay }
            </div>
        );
    }

    private generateClassNames() {
        const {
            theme,
            classes,
        } = this.props;

        const themeClasses = (classes || '') + ' select-2-dropdown select-2-dropdown-field dropdown-menu-component';

        switch (theme) {
            case Select2DropdownMenuTheme.Basic:
                return themeClasses + ' theme-basic';
            case Select2DropdownMenuTheme.BasicInverted:
                return themeClasses + ' theme-basic-inverted';
            default:
                return themeClasses;
        }
    }

    private readonly handleSetDropdownIsOpen = (event : React.MouseEvent<HTMLElement>) => {
        this.props.handleDropdownOpenClose(!this.props.isOpen);
        this.componentIsOpening = true;
        this.setState({
            searchBarState: {
                ...this.state.searchBarState,
                isFocused: true
            }
        });
    }

    private readonly handleOnOutsideComponentClick = (event : Event) : void => {
        if (!this.componentIsOpening && (this.dropdownMenuOverlayDiv === null || !this.dropdownMenuOverlayDiv.contains(event.target as Element))) {
            this.closeDropdown();
            event.stopPropagation();
        }
        this.componentIsOpening = false;
    }

    private readonly closeDropdown = () : void => {
        if (this.props.isOpen) {
            if (this.props.onDropdownBlur) { // rename this
                this.props.onDropdownBlur();
            }
            this.props.handleDropdownOpenClose(false);
        }
        this.componentIsOpening = false;
    }

    private readonly getExcludedOptionCount = (
        filteredOptionsAndLabelName : OptionsAndLabelNameTuples,
        maxOptionsDisplayedPerGroup? : number,
    ) : number => {
        if (undefined === maxOptionsDisplayedPerGroup) {
            return 0;
        }

        return filteredOptionsAndLabelName.map((value : [string | null, Array<IOption>]) => {
            const filteredOptions = value[1];
            return Math.max(0, filteredOptions.length - maxOptionsDisplayedPerGroup);
        })
        .reduce((sum, current) => sum + current, 0);
    }

    private readonly getGroupLists = (
        filteredOptionsAndLabelName : OptionsAndLabelNameTuples,
        selectedOption : IOption | null,
        hasCreateCustomOption : boolean,
        customOptionGroupLabel : string | null,
        groupEmptyState : JSX.Element | null,
        maxOptionsDisplayedPerGroup? : number,
    ) : Array<JSX.Element | null> => {
        const groupedOptions = filteredOptionsAndLabelName.map((value : [string | null, Array<IOption>], index : number) => {
            const [labelName, filteredOptions] = value;

            let limitedFilteredOptions : Array<IOption>;
            if (undefined === maxOptionsDisplayedPerGroup) {
                limitedFilteredOptions = filteredOptions;
            } else {
                limitedFilteredOptions = filteredOptions.slice(0, maxOptionsDisplayedPerGroup);
            }

            const groupHasCreateCustomOption = hasCreateCustomOption && customOptionGroupLabel === labelName;
            const groupIsEmpty = limitedFilteredOptions.length === 0;
            const searchBarStateValid = this.state.searchBarState.value !== null && this.state.searchBarState.value.length !== 0;
            let buttonText = this.state.searchBarState.value ? `Add "${ this.state.searchBarState.value }"` : 'Type to create new';
            if (this.props.createCustomOptionButtonLabel) {
                buttonText = this.props.createCustomOptionButtonLabel;
            }

            return groupIsEmpty && !groupEmptyState ? null : (
                <div className="dropdown-menu-component-option-list" key={ index }>
                    { labelName !== null &&
                        <div className="dropdown-group-label">{ labelName }</div>
                    }

                    { this.props.getOptionElementsForGroup(limitedFilteredOptions, selectedOption) }

                    { groupIsEmpty && groupEmptyState }

                    { groupHasCreateCustomOption && !searchBarStateValid && !this.props.createCustomOptionButtonLabel &&
                        <div onClick={ this.setSearchBarInFocus } className="create-new-button">{ buttonText }</div>
                    }

                    { ((groupHasCreateCustomOption && searchBarStateValid) || (this.props.createCustomOptionButtonLabel && this.props.hasCreateCustomOption))  &&
                        <Button
                            buttonClassName="add-custom-dropdown-option-button reset-dark-styles primary flat with-icon"
                            isDisabled={ (this.state.searchBarState.value === null && this.props.isSearchable) }
                            isLoading={ false }
                            onClick={ this.handleOnCreateCustomOption }
                        >
                            <span className="button-text-container">
                                <span className="bevicon bevico-add" />
                                <span className="button-text">{ buttonText }</span>
                            </span>
                        </Button>
                    }
                </div>
            );
        });
        return groupedOptions;
    }

    private readonly handleOnCreateCustomOption = () => {
        if ((this.state.searchBarState.value !== null || !this.props.isSearchable) && this.props.createCustomOption !== null) {
            this.props.createCustomOption(this.state.searchBarState.value ? this.state.searchBarState.value : '');
            this.closeDropdown();
        }
    }

    private readonly getOverlayRef = (element : HTMLDivElement) => {
        this.dropdownMenuOverlayDiv = element;
    }

    private readonly handleOnSearchBarValueChange = (searchTerm : string) => {

        let modifiedSearchTerm = searchTerm;
        if (this.props.isTitleCase) {
            modifiedSearchTerm = StringUtils.normalizeToTitleCase(searchTerm);
        }

        const newOptions = this.filterOptionsBasedOnSearchTerm(searchTerm, this.props.sortedOptionsAndLabelName);
        this.setState({
            searchBarState: {
                ...this.state.searchBarState,
                value: modifiedSearchTerm,
                filteredValue: searchTerm,
            },
            filteredOptionsAndLabelName: newOptions,
        });
        if (this.props.onSearchValueChanged) {
            this.props.onSearchValueChanged(modifiedSearchTerm);
        }
    }

    private readonly handleOnClearSearchBar = () => {
        const newOptions = this.filterOptionsBasedOnSearchTerm(null, this.props.sortedOptionsAndLabelName);
        this.setState({
            searchBarState: {
                ...this.state.searchBarState,
                value: null,
                filteredValue: null,
                isFocused: true
            },
            filteredOptionsAndLabelName: newOptions,
        });

        if (this.props.onSearchValueChanged) {
            this.props.onSearchValueChanged(null);
        }
    }

    private readonly handleOnSearchBarBlur = () => {
        // TODO do nothing? ???????
    }

    private readonly handleOnSearchBarFocus = () => {
        // TODO do nothing? ???????
    }

    private readonly handleOnSearchBarEnterClick = (searchTerm : string) => {
        // TODO do nothing??
    }

    private readonly setSearchBarInFocus = () => {
        const searchBarId = document.getElementById("generic-dropdown-searchbar");
        if (searchBarId) {
            const searchBarInput = searchBarId.getElementsByTagName('input')[0];
            searchBarInput.focus();
        }
    }

    // TODO future: could pass in optional filter function to use instead of this
    // it'd be nice if this matched the in-page filtering
    private readonly filterOptionsBasedOnSearchTerm = (searchTerm : string | null, sortedOptionsAndLabelName : Array<[string | null, Array<IOption>]>) : Array<[string | null, Array<IOption>]> => {
        if (searchTerm === null) {
            return sortedOptionsAndLabelName;
        }

        const cleanedSearchTerm = cleanPhrase(searchTerm);

        const newFilteredOptions : OptionsAndLabelNameTuples = [];

        sortedOptionsAndLabelName.forEach((value : [string | null, Array<IOption>], index : number) => {
            const newFilteredOptionsForLabel = value[1].filter((option : IOption) => {
                const cleanedLabel = cleanPhrase(option.label);
                const cleanedValue = cleanPhrase(option.value);

                if (cleanedLabel.indexOf(cleanedSearchTerm) > -1 || cleanedValue.indexOf(cleanedSearchTerm) > -1) {
                    return true;
                }
                return false;
            });
            newFilteredOptions[index] = [value[0], newFilteredOptionsForLabel];
        });
        return newFilteredOptions;

    }
}
