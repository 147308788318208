import React from 'react';

import './PositionablePopup.scss';

const POPUP_EDGE_PADDING = 10;

export type PositionablePopupSide = 'above' | 'left' | 'right' | 'below' | 'center';

export interface IPopupProps {
    preferredPositionArray : Array<PositionablePopupSide>;
    className : string;
    caretScaleFactor? : number;
    children : React.ReactNode;
}

export class PositionablePopup extends React.Component<IPopupProps, object> {

    private popupDiv : HTMLDivElement | undefined;
    private caretDiv : HTMLDivElement | undefined;

    public render() {
        return (
            <div className={ 'positionable-popup ' + this.props.className } ref={ this.onPopupDivRef }>
                <div className="positionable-popup-body">
                    { this.props.children }
                </div>
                <div className="positionable-popup-caret" ref={ this.onCaretDivRef }/>
            </div>
        );
    }

    public componentDidUpdate() {
        this.positionPopup();
    }

    public componentDidMount() {
        this.positionPopup();
    }

    private positionPopup = () => {
        const {
            preferredPositionArray,
            caretScaleFactor,
        } = this.props;

        // If we don't have our two local element variables defined, then do nothing by returning early.
        const popupDiv = this.popupDiv;
        const caretDiv = this.caretDiv;
        if (typeof popupDiv === 'undefined' || typeof caretDiv === 'undefined') {
            return;
        }

        const anchorDiv = popupDiv.parentElement;
        if (anchorDiv === null) {
            return;
        }

        // First we figure out how much space we have on each side of the anchor, and how much space the popup needs.

        const anchorRect = anchorDiv.getBoundingClientRect();

        // Include header height in top calculation so popup doesn't get cut off
        const headerElement = document.querySelector(".page-header");
        const headerHeight = headerElement instanceof HTMLElement ? headerElement.offsetHeight : 0;

        const spaceToLeftEdge = anchorRect.left;
        const spaceToRightEdge = window.innerWidth - anchorRect.right;
        const spaceToTop = anchorRect.top - headerHeight;
        const spaceToBottom = window.innerHeight - anchorRect.bottom;

        const popupHeightNeeded = popupDiv.offsetHeight + POPUP_EDGE_PADDING;
        const popupWidthNeeded = popupDiv.offsetWidth + POPUP_EDGE_PADDING;

        let positioningClass : string | null = null;

        // Next setup test functions based upon current spacing.

        const positionTestFunctions : { [p : string] : () => string | null } = {
            above: () => {
                return spaceToTop > popupHeightNeeded ? 'position-above' : null;
            },
            below: () => {
                return spaceToBottom > popupHeightNeeded ? 'position-below' : null;
            },
            left: () => {
                return spaceToLeftEdge > popupWidthNeeded ? 'position-left' : null;
            },
            right: () => {
                return spaceToRightEdge > popupWidthNeeded ? 'position-right' : null;
            },
            center: () => {
                return 'position-center';
            }
        };

        // Check each positioning option in the preferred array, the first one that has space wins.

        for (const position of preferredPositionArray) {
            positioningClass = positionTestFunctions[position]();
            if (positioningClass) {
                break;
            }
        }

        // If we can't find a viable position, default to below.
        let noViablePosition = false;
        if (!positioningClass) {
            positioningClass = 'position-below';
            noViablePosition = true;
        }

        // Adding the positioning class will move the popup to the correct side of the anchor.

        popupDiv.classList.add(positioningClass);

        if (positioningClass === 'position-center' || noViablePosition) {
            return;
        }

        // Next we get the new bounding rect of the popup and calculate any needed adjustments in X & Y for the popup & caret.

        const popupRectAfterPositioning = popupDiv.getBoundingClientRect();

        let offsetX = 0;
        const distanceFromRightEdge = window.innerWidth  - popupRectAfterPositioning.right;
        if (distanceFromRightEdge < 0) {
            offsetX = distanceFromRightEdge - POPUP_EDGE_PADDING;
        }
        const distanceFromLeftEdge = popupRectAfterPositioning.left;
        if (distanceFromLeftEdge < 0) {
            offsetX = -1 * distanceFromLeftEdge + POPUP_EDGE_PADDING;
        }

        let offsetY = 0;
        const distanceFromBottom = window.innerHeight - popupRectAfterPositioning.bottom;
        if (distanceFromBottom < 0) {
            offsetY = distanceFromBottom - POPUP_EDGE_PADDING;
        }
        const distanceFromTop = popupRectAfterPositioning.top;
        if (distanceFromTop < 0) {
            offsetY = POPUP_EDGE_PADDING - distanceFromTop;
        }

        // Last we apply adjustments.

        if (offsetX !== 0) {
            popupDiv.style.transform = `translateX(calc(-50% + ${ offsetX }px))`;
            caretDiv.style.transform = `translateX(calc(-50% + ${ offsetX * -1 }px)) rotate(45deg)`;
        } else if (offsetY !== 0) {
            popupDiv.style.transform = `translateY(calc(-50% + ${ offsetY }px))`;
            caretDiv.style.transform = `translateY(calc(-50% + ${ offsetY * -1 }px)) rotate(45deg)`;
        }

        if (typeof caretScaleFactor !== 'undefined') {
            const caretDivStyleTransform = caretDiv.style.transform;
            if (!caretDivStyleTransform || (caretDivStyleTransform.indexOf('scale(') === -1)) {
                caretDiv.style.transform += ` scale(${caretScaleFactor})`; // Does not handle cases where the caretScaleFactor changes between renders
            }
        }
    }

    private onPopupDivRef = (div : HTMLDivElement) => {
        this.popupDiv = div;
    }

    private onCaretDivRef = (div : HTMLDivElement) => {
        this.caretDiv = div;
    }
}
