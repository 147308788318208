import { QueryStringNameNotFoundException } from '../exceptions/QueryStringNameNotFoundException';
import { IQueryStringReader } from '../interfaces/IQueryStringReader';

export class QueryStringManagerImpl implements IQueryStringReader {
    public getValuesForName(name : string) : Array<string> {
        const token = name + '=';

        const queryStringWithoutLeadingQuestionMark = window.location.search.substring(1);
        const queryStringParameterList = queryStringWithoutLeadingQuestionMark.split('&');

        const result : Array<string> = [];

        for (let i = 0; i < queryStringParameterList.length; i++) {
            const queryStringParameter = queryStringParameterList[i];

            if (queryStringParameter.indexOf(token) === 0) {
                const value = queryStringParameter.substring(token.length, queryStringParameter.length);
                result.push(value);
            }
        }

        if (result.length === 0) {
            throw new QueryStringNameNotFoundException();
        }

        return result;
    }
}
