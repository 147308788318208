import * as moment from 'moment-timezone';
import { getCurrencySymbol } from 'shared/models/Currency';
import * as XLSX from 'xlsx';
import { BookType, WorkBook } from 'xlsx/types';

const MAX_SHEET_NAME_LENGTH = 31;   // https://www.ibm.com/support/pages/max-length-sheet-name-31

// future work: if mobile ios starts supporting a.download, we will not need to do this check
const canDownloadFile = () : boolean => {
    // based off of implementation of write_dl() in xlsx.js -- these are the checks they use
    // typescript no longer supports msSaveBlob on navigator but it is required in IE11 // TODO can we stop supporting IE11?
    if (typeof navigator !== 'undefined' && (navigator as any).msSaveBlob) { // IE 11 does not support a.download, but does use msSaveBlob
        return true;
    } else if (typeof URL !== 'undefined' && typeof document !== 'undefined' && document.createElement && (URL as any).createObjectURL) {
        const a = document.createElement('a');
        if (typeof a.download !== 'undefined' && a.download !== null) { // mobile ios has not implemented a.download which prevents the export from working correctly
            return true;
        }
    }
    return false;
};

// adapted from https://github.com/SheetJS/js-xlsx/blob/master/README.md
// TODO can we just add this to the typings instead of re-defining? this is just what's in XLSX
const s2ab = (s : string) => { // string to array buffer
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        // eslint-disable-next-line no-bitwise
        view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
};

// iOS solution adapted in part from write_dl() in xlsx.js & many workarounds via the internet
const writeWorkBookToFile = (workBook : WorkBook, fileName : string, bookType : BookType) => {
    const canDownloadFileNormally = canDownloadFile();

    if (canDownloadFileNormally) {
        XLSX.writeFile(workBook, fileName, { bookType });
    } else { // iOS does not support a.download, so we need to create a plain link
        const wbout = XLSX.write(workBook, { type: 'binary', bookType: 'xlsx' });

        if (typeof Blob !== 'undefined') { // Blob is supported in iOS so this works
            const blob = new Blob([s2ab(wbout)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // if we wanted to support csv we can switch the type based on bookType
            });

            if (window.navigator.userAgent.match('CriOS')) { // Chrome iOS does not like opening an object URL for some reason. But Safari iOS does not like this solution
                const reader = new FileReader();
                reader.onloadend = () => {
                    const result = reader.result;
                    if (typeof result !== 'string') {
                        throw new Error('data url result unexpectedly null in iOS chrome ');
                    }
                    window.open(result);
                };
                reader.readAsDataURL(blob);
            } else {
                const href = URL.createObjectURL(blob);
                window.open(href, '_self'); // IMPORTANT: this does not work on iOS if you use target=_blank
            }
        }
    }
};

export class CellFormat {
    public static MONETARY_VALUE = new CellFormat(`[$${getCurrencySymbol()}]#,##0.00;-[$${getCurrencySymbol()}]#,##0.00`);
    public static PERCENT = new CellFormat("0.00%");

    private formatString: string;

    private constructor(formatString : string) {
        this.formatString = formatString;
    }

    public get format(): string {
        return this.formatString;
    }
}

const formatNumberCellsInColumn = (workSheet : XLSX.WorkSheet, columnIndex : number, format : CellFormat) => {
    const sheetRange = workSheet['!ref'];
    if (typeof sheetRange === 'undefined') {
        return;
    }

    /* get worksheet range */
    const range = XLSX.utils.decode_range(sheetRange);
    for (let i = range.s.r; i <= range.e.r; ++i) {
        formatNumberCell(workSheet, columnIndex, i, format);
    }
};

const formatNumberCell = (workSheet : XLSX.WorkSheet, columnIndex : number, rowIndex : number, format : CellFormat) => {
    const ref = XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex });

    /* if the particular row did not contain data for the column, the cell will not be generated */
    if (!workSheet[ref]) {
        return;
    }

    if (workSheet[ref].v === '') {
        return;
    }

    /* `.t == "n"` for number cells */
    if ((workSheet[ref].t === 'n') || !isNaN(Number(workSheet[ref].v))) {
        workSheet[ref].t = 'n';
        workSheet[ref].z = format.format;
    }
};

// retailer identifier could be a name (with spaces) or id...
const getStandardReportFileName = (retailerIdOrName : string, reportName : string, startDateInRetailerTZ : moment.Moment, endDateInRetailerTZ : moment.Moment | null) => {
    // File name = [RETAILER]_[REPORT]_[DATE](Optional: _to_[DATE 2])
    const fileName = `${ retailerIdOrName.replace(/\s/g, '_') }_${ reportName.replace(/\s/g, '_') }_${ startDateInRetailerTZ.format('Y-MM-DD') }${ endDateInRetailerTZ === null ? '' : `_to_${ endDateInRetailerTZ.format('Y-MM-DD') }` }.xlsx`;
    return fileName;
};

export interface IColumnInfo {
    headerText : string;
    formatting? : CellFormat | null;
}

function constructSheetFromSortedFilteredGroupedResult<T>(
    headerInfo : Array<IColumnInfo>,
    sortedGroupNamesToDisplay : Array<string>,
    sortedRowIdsByGroupName : {[groupName : string] : Array<T>},
    createRowFunction : (rowId : T) => Array<string | number>,
    isRowIncluded : (rowId : T) => boolean
) : XLSX.WorkSheet {
    const headerRow = headerInfo.map((info) => info.headerText);
    const arrayOfArraysForSheet : Array<Array<string | number>> = [headerRow];

    sortedGroupNamesToDisplay.forEach((groupName : string) => {
        const groupNameRow = [groupName];
        arrayOfArraysForSheet.push(groupNameRow);

        const includedItems : Array<Array<string | number>> = [];
        const excludedItems : Array<Array<string | number>> = [];
        const sortedRowIds = sortedRowIdsByGroupName[groupName];
        sortedRowIds.forEach((rowId) => {
            const row = createRowFunction(rowId);
            if (isRowIncluded(rowId)) {
                includedItems.push(row);
            } else {
                excludedItems.push(row);
            }
        });
        arrayOfArraysForSheet.push(...includedItems);
        if (excludedItems.length > 0) {
            arrayOfArraysForSheet.push(['Excluded Items']);
            arrayOfArraysForSheet.push(...excludedItems);
        }
        arrayOfArraysForSheet.push([]); // empty row between groups
    });

    const worksheet = XLSX.utils.aoa_to_sheet(arrayOfArraysForSheet);
    // format number cells where applicable
    headerInfo.forEach((columnInfo, index) => {
        if (columnInfo.formatting) {
            exportUtils.formatNumberCellsInColumn(worksheet, index, columnInfo.formatting);
        }
    });
    return worksheet;
}

const getNumberOrUndefinedDisplay = (value : number | undefined | null) => {
    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    return Number.isInteger(value) ? value : +value.toFixed(2);
};

// Mirrors the functionality of excel_utils.py:_clean_worksheet_name()
const cleanWorksheetName = (worksheetName : string) => {
    let newWorksheetName = worksheetName;
    // Excel doesn't like single-quotes at the start of a sheet name.

    // This is a Javascript implementation of Python's strip() function, as used in _clean_worksheet_name().
    // TODO: Put this somewhere more globally accessible?
    let start = 0;
    while ("'".indexOf(worksheetName[start]) >= 0) {
        start += 1;
    }

    let end = worksheetName.length - 1;
    while ("'".indexOf(worksheetName[end]) >= 0) {
        end -= 1;
    }

    newWorksheetName = worksheetName.substring(start, end + 1);

    const specialCharacters = [":", "\\", "/", "?", "*", "[", "]"];
    specialCharacters.forEach((specialCharacter) => {
        newWorksheetName = newWorksheetName.replace(specialCharacter, "_");
    });

    return newWorksheetName;
};

export const exportUtils = {
    MAX_SHEET_NAME_LENGTH,
    constructSheetFromSortedFilteredGroupedResult,
    writeWorkBookToFile,
    formatNumberCellsInColumn,
    formatNumberCell,
    getNumberOrUndefinedDisplay,
    getStandardReportFileName,
    cleanWorksheetName
};
