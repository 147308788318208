import { IStringValue } from 'api/Core/IStringValue';

export class CategoryLevelSalesReportConfigurationId implements IStringValue {
    constructor (private readonly value : string) { }

    public getValue() : string {
        return this.value;
    }

    public equals(other : CategoryLevelSalesReportConfigurationId) : boolean {
        return this.getValue() === other.getValue();
    }

    public toString() : string {
        return `{CategoryLevelSalesReportConfigurationId: ${this.value}}`;
    }
}
