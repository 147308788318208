import { IStringValue } from 'api/Core/IStringValue';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';

import { productObjectToJSONSerializer } from 'shared/lib/manager';
import { PackagingUtils } from '../utils/PackagingUtils';

export class ProductDistributorAssociationIdentifier implements IStringValue {
    public static fromProductDistributorAssociation(productDistributorAssociation : ProductDistributorAssociation) {
        return new ProductDistributorAssociationIdentifier(
            productDistributorAssociation.getProductId(),
            productDistributorAssociation.getProductQuantityUnit(),
            productDistributorAssociation.getDistributorId());
    }

    constructor(
        private readonly productId : ProductId,
        private readonly productQuantityUnit : ProductQuantityUnit,
        private readonly distributorId : DistributorId | null,
    ) {}

    public getProductId() : ProductId {
        return this.productId;
    }

    public getDistributorId() : DistributorId | null {
        return this.distributorId;
    }

    public getProductQuantityUnit() : ProductQuantityUnit {
        return this.productQuantityUnit;
    }

    public getValue() : string {
        return `${ this.productId.getValue() }-${ (this.distributorId === null) ? 'null' : this.distributorId.getValue() }-${ productObjectToJSONSerializer.getProductQuantityUnit(this.productQuantityUnit) }`;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof ProductDistributorAssociationIdentifier)) {
            return false;
        }

        const thisDistributorId = this.getDistributorId();

        return this.getProductId().equals(other.getProductId()) &&
            ((thisDistributorId === other.getDistributorId()) || ((thisDistributorId !== null) && thisDistributorId.equals(other.getDistributorId()))) &&
            PackagingUtils.productQuantityUnitsAreEqual(this.getProductQuantityUnit(), other.getProductQuantityUnit());
    }

    public toString() : string {
        return `{ProductDistributorAssociationIdentifier: ${this.getValue()}}`;
    }
}
