import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { ProductId } from 'api/Product/model/ProductId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { Unit } from 'api/Product/model/Unit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { UnitUtils } from 'api/Product/utils/UnitUtils';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { packagingWeightUtils } from 'api/Product/utils/packagingWeightUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';

import { IProductFormData, ProductFormFieldName, ProductFormValidationInputDataByFieldName, PackagingWeightFormFieldName, ProductDistributorAssociationFormFieldName } from '../reducers/ItemCardReducers';
import { ProductFormUtils } from '../utils/ProductFormUtils';
import { ProductModificationConfirmationDialog } from './ProductModificationConfirmationDialog';
import { ProductDistributorAssociationRow } from './ProductDistributorAssociationRow';
import { ProductDistributorAssociationFormId } from '../model/ProductDistributorAssociationFormId';

import { PriceEstimates } from 'apps/ItemCard/components/PriceEstimates';
import { Button } from 'shared/components/Button';
import { CheckBox } from 'shared/components/CheckBox';
import { DeleteProductsConfirmationDialog } from 'shared/components/DeleteProductsConfirmationDialog';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { ExpandingNote, ExpandingNoteTheme } from 'shared/components/ExpandingNote';
import { PackagingForm, PackagingFormValidationInputDataByFieldName } from 'shared/components/Product/PackagingForm';
import { PackagingFormUtils } from 'shared/components/Product/PackagingFormUtils';
import {
    addToSortedLabelOptionList,
    addToSortedOptionList,
    makeOptionFromValue,
    OptionsAndLabelNameTuples,
    Select2DropdownMenu
} from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput } from 'shared/components/ValidationInput';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { getCurrencySymbol } from 'shared/models/Currency';
import { LabelOrientation } from 'shared/models/InputLabel';

import '../css/ProductForm.scss';

export interface IProductFormProps {
    readonly productId : ProductId | null;
    readonly categoriesById : StringValueMap<CategoryId, Category>;
    readonly validationInputDataByFieldName : ProductFormValidationInputDataByFieldName;
    readonly isValid : boolean;
    readonly errorMessage : string;
    readonly isSubmitting : boolean;
    readonly productIsActive : boolean;
    readonly productFormData : IProductFormData;
    readonly initialPackagings : Array<Packaging | PackagingFormValidationInputDataByFieldName | null>;
    readonly onFormFieldChange : (fieldName : ProductFormFieldName, value : string) => void;
    readonly onFormFieldBlur : (fieldName : ProductFormFieldName, value : string) => void;
    readonly setUnitsWithConversionForms : (units : Array<string>) => void;
    readonly onConversionFieldChange : (unit : string, value : string) => void;
    readonly onConversionFieldBlur : (unit : string, value : string) => void;
    readonly onPackagingWeightFormFieldChange : (packagingIdValue : string, fieldName : PackagingWeightFormFieldName, value : string) => void;
    readonly onPackagingWeightFormFieldBlur : (packagingIdValue : string, fieldName : PackagingWeightFormFieldName, value : string) => void;
    readonly onSetPackagingIdHasPackagingWeightForm : (packagingIdValue : string, hasPackagingWeightForm : boolean) => void;
    readonly onSetPackagingWeightFormPackagingIdValue : (oldPackagingIdValue : string, newPackagingIdValue : string) => void;
    readonly onAddProductDistributorAssociationForm : (packagingIndex : number) => void;
    readonly onProductDistributorAssociationFormFieldChange : (formId : ProductDistributorAssociationFormId, fieldName : ProductDistributorAssociationFormFieldName, value : string, packagingIndex : number | null | undefined) => void;
    readonly onProductDistributorAssociationFormFieldBlur : (formId : ProductDistributorAssociationFormId, fieldName : ProductDistributorAssociationFormFieldName, value : string, packagingIndex : number | null | undefined) => void;
    readonly onRemoveProductDistributorAssociationForms : (formIds : StringValueSet<ProductDistributorAssociationFormId>) => void;
    readonly onSaveProductForm : (productId : ProductId | null, packagings : Array<Packaging | null>, shouldCheckIfPackagingHasBeenModified : boolean) => void;
    readonly onCreateCustomDistributorClick : (distributorName : string) => void;
    readonly onProductFormCancelClick : () => void;
    readonly productModificationConfirmationDialogIsShown : boolean;
    readonly onProductModificationConfirmationDialogCancelClick : () => void;
    readonly deleteProductConfirmationDialogIsShown : boolean;
    readonly setDeleteProductConfirmationDialogIsShown : (isShown : boolean) => void;
    readonly onConfirmDeleteProduct : (productId : ProductId) => void;
    readonly onSetProductActiveState : (productId : ProductId, setAsActive : boolean) => void;
    readonly onSetFocusedField : (fieldName : ProductFormFieldName | PackagingWeightFormFieldName | null) => void;
    readonly focusedField : ProductFormFieldName | PackagingWeightFormFieldName | null;
}

interface IProductFormState {
    categorySearchBarValue : string | null;
}

export class ProductForm extends React.Component<IProductFormProps, IProductFormState> {
    private readonly currencySymbol = getCurrencySymbol();
    private packagingFormData : Array<{
        packagingForm : PackagingForm | null,
        initialPackaging : Packaging | PackagingFormValidationInputDataByFieldName | null,
        ref : (packagingForm : PackagingForm) => void,
        isShown : boolean
    }>;

    private displayedEmptyWeightValue : string = "";
    private displayedFullWeightValue : string = "";
    private displayedEmptyWeightUnitValue : string = "";
    private displayedFullWeightUnitValue : string = "";
    private productDistributorAssociationFormPackagingIndexByFormId = new StringValueMap<ProductDistributorAssociationFormId, number>();

    public constructor(props : IProductFormProps) {
        super(props);

        this.packagingFormData = this.getInitialPackagingFormData(props.initialPackagings);
        this.state = {
            categorySearchBarValue : null,
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps : IProductFormProps) {
        if (((nextProps.productId === null) && (nextProps.initialPackagings !== this.props.initialPackagings)) || (nextProps.productId !== this.props.productId)) {
            this.productDistributorAssociationFormPackagingIndexByFormId = new StringValueMap();
            this.packagingFormData = this.getInitialPackagingFormData(nextProps.initialPackagings);
        }
    }

    public render() {
        const {
            validationInputDataByFieldName,
            isValid,
            errorMessage,
            isSubmitting,
            productFormData,
            onRemoveProductDistributorAssociationForms,
            onProductFormCancelClick,
            productModificationConfirmationDialogIsShown,
            deleteProductConfirmationDialogIsShown,
            productId,
            categoriesById,
            productIsActive,
        } = this.props;

        const weightUnitOptions = ProductFormUtils.getWeightUnitOptions();

        let categoryIdSortedOptionsAndLabelNames : OptionsAndLabelNameTuples =
            productFormData.categorySortedOptionsAndLabelNames;
        let categorySelectedIOption : IOption | null = null;

        const categoryIdValue : string | null = validationInputDataByFieldName.categoryId.value;
        if (categoryIdValue) {
            const categoryId = new CategoryId(categoryIdValue);
            if (categoriesById.has(categoryId)) {
                categorySelectedIOption =
                    ProductFormUtils.makeCategoryOption(categoryId, categoriesById.getRequired(categoryId));
                categoryIdSortedOptionsAndLabelNames =
                    addToSortedLabelOptionList(
                        categoryIdSortedOptionsAndLabelNames,
                        categoryIdValue,
                        null,
                        CategoryUtils.categoryCompareFunction);
            } else {
                categorySelectedIOption =
                    makeOptionFromValue(categoryIdValue);
                categoryIdSortedOptionsAndLabelNames =
                    addToSortedLabelOptionList(
                        categoryIdSortedOptionsAndLabelNames,
                        categoryIdValue,
                        null,
                        CategoryUtils.categoryCompareFunction);
            }
        }

        let distributorSelectedIOption : IOption | null = null;
        productFormData.distributorSortedOptionsAndLabelNames.forEach(([labelName, iOptions]) => {
            iOptions.forEach((iOption) => {
                if (iOption.value === validationInputDataByFieldName.distributorId.value) {
                    distributorSelectedIOption = iOption;
                }
            });
        });

        const shownPackagingFormsValidationInputDataByFieldName = this.getShownPackagingFormsValidationInputDataByFieldName();

        let priceUnitSortedOptionsAndLabelName : OptionsAndLabelNameTuples = [];

        if (shownPackagingFormsValidationInputDataByFieldName.length > 0) { // TODO Cheezy
            const packagingFormValidationInputDataByFieldName = shownPackagingFormsValidationInputDataByFieldName[0];

            priceUnitSortedOptionsAndLabelName = ProductFormUtils.getPriceUnitIOptions(packagingFormValidationInputDataByFieldName);
        }

        let productTypeSortedOptionsAndLabelNames : OptionsAndLabelNameTuples =
            productFormData.productTypeSortedOptionsAndLabelNames;
        let productTypeSelectedIOption : IOption | null = null;

        const productTypeValue : string | null = validationInputDataByFieldName.type.value;
        if (productTypeValue) {
            productTypeSelectedIOption =
                makeOptionFromValue(productTypeValue);
            productTypeSortedOptionsAndLabelNames =
                addToSortedOptionList(productTypeSortedOptionsAndLabelNames, productTypeValue, null);
        }

        // Deep copy so modifications below don't change saved options
        const glCodeSortedOptionsAndLabelNames : OptionsAndLabelNameTuples = JSON.parse(JSON.stringify(productFormData.glCodeSortedOptionsAndLabelNames));
        let glCodeSelectedIOption = null;
        let glCodeIsCustom = true;
        const glCodeValue = validationInputDataByFieldName.glCode.value;
        const selectedCategory = categoryIdValue ? categoriesById.get(new CategoryId(categoryIdValue)) : undefined;

        if (glCodeValue) {
            glCodeSelectedIOption = {
                value: validationInputDataByFieldName.glCode.value,
                label: validationInputDataByFieldName.glCode.value,
                icon: null,
            };

            let selectedValueIsIncludedInOptions = false;
            glCodeSortedOptionsAndLabelNames.forEach(([labelName, iOptions], i) => {
                iOptions.forEach((iOption, j) => {
                    if (typeof selectedCategory !== 'undefined' && selectedCategory.getGlCode().trim() === iOption.value) {
                        iOption = {
                            value: iOption.value,
                            label: `Default to Category GL Code (${iOption.value})`,
                            icon: null,
                        };
                        glCodeSortedOptionsAndLabelNames[0][1].splice(j, 1);
                        glCodeSortedOptionsAndLabelNames[0][1].splice(0, 0, iOption);
                    }
                    if (iOption.value === glCodeValue) {
                        selectedValueIsIncludedInOptions = true;
                        glCodeSelectedIOption = iOption;
                        glCodeIsCustom = labelName  === 'Custom GL Codes';
                    }
                });
            });

            if (!selectedValueIsIncludedInOptions) {
                glCodeSortedOptionsAndLabelNames[0][1].splice(0, 0, glCodeSelectedIOption);
            }
        }

        const allPackagingUnitSortedOptionsAndLabelName = ProductFormUtils.getAllPackagingUnitIOptions(shownPackagingFormsValidationInputDataByFieldName, true);
        let preferredReportingUnitSelectedIOption : IOption | null = null;
        let parUnitSelectedIOption : IOption | null = null;
        let costUnitSelectedIOption : IOption | null = null;
        if (shownPackagingFormsValidationInputDataByFieldName.length > 0) {
            allPackagingUnitSortedOptionsAndLabelName[0][1].forEach((packagingUnitOption) => {
                if (validationInputDataByFieldName.preferredReportingUnit.value && (packagingUnitOption.value === validationInputDataByFieldName.preferredReportingUnit.value)) {
                    preferredReportingUnitSelectedIOption = packagingUnitOption;
                }

                if (validationInputDataByFieldName.parUnit.value && (packagingUnitOption.value === validationInputDataByFieldName.parUnit.value)) {
                    parUnitSelectedIOption = packagingUnitOption;
                }

                if (validationInputDataByFieldName.costUnit.value && (packagingUnitOption.value === validationInputDataByFieldName.costUnit.value)) {
                    costUnitSelectedIOption = packagingUnitOption;
                }
            });
        }

        const baseUnitSortedOptionsAndLabelName = ProductFormUtils.getBaseUnitIOptions(shownPackagingFormsValidationInputDataByFieldName);
        let baseUnitSelectedIOption : IOption | null = null;
        if ((shownPackagingFormsValidationInputDataByFieldName.length > 0) && validationInputDataByFieldName.baseUnit.value) {
            baseUnitSortedOptionsAndLabelName[0][1].forEach((baseUnitOption) => {
                if (baseUnitOption.value === validationInputDataByFieldName.baseUnit.value) {
                    baseUnitSelectedIOption = baseUnitOption;
                }
            });
        }

        const priceUnitSelectedIOption = validationInputDataByFieldName.priceUnit.value ? {
            value: validationInputDataByFieldName.priceUnit.value,
            label: validationInputDataByFieldName.priceUnit.value ? oldPackagingUtils.getDisplayTextForUnit(validationInputDataByFieldName.priceUnit.value as Unit) : '',
            icon: null,
        } : null;

        const shownPackagingFormDataIndexByFormPackagingIdValue = ProductFormUtils.getPackagingFormDataIndexByFormProductQuantityUnitValue(this.packagingFormData);

        const distributorSortedOptionsAndLabelNamesWithoutNullVendor : OptionsAndLabelNameTuples = []; // TODO Cheezy clean up when other list of vendors is no longer needed
        const myVendorsIOptions : Array<IOption> = [];
        productFormData.distributorSortedOptionsAndLabelNames[0][1].forEach((option) => {
            if (option.value) {
                myVendorsIOptions.push(option);
            }
        });
        distributorSortedOptionsAndLabelNamesWithoutNullVendor.push([productFormData.distributorSortedOptionsAndLabelNames[0][0], myVendorsIOptions]);

        const packagingFormRows : Array<JSX.Element> = [];
        this.packagingFormData.forEach((data, index) => {
            if (data.isShown) {
                const productDistributorAssociationFormIds = new StringValueSet<ProductDistributorAssociationFormId>();
                const productDistributorAssociationRows : Array<JSX.Element> = [];

                if (window.GLOBAL_FEATURE_ACCESS.multi_vendor) {
                    let unitSortedOptionsAndLabelNames : OptionsAndLabelNameTuples = [];
                    if (data.packagingForm) {
                        unitSortedOptionsAndLabelNames = ProductFormUtils.getAllPackagingUnitIOptions([data.packagingForm.getValidationInputDataByFieldName()], false);
                    }

                    validationInputDataByFieldName.productDistributorAssociations.forEach((form, formId) => {
                        let productDistributorAssociationFormPackagingIndex = form.packagingIndex;

                        if (productDistributorAssociationFormPackagingIndex === null) {
                            if (this.productDistributorAssociationFormPackagingIndexByFormId.has(formId)) {
                                productDistributorAssociationFormPackagingIndex = this.productDistributorAssociationFormPackagingIndexByFormId.getRequired(formId);
                            } else {
                                const unitValueIndex = shownPackagingFormDataIndexByFormPackagingIdValue.get(form.validationInputDataByFieldName.unit.value);

                                if (unitValueIndex) {
                                    productDistributorAssociationFormPackagingIndex = unitValueIndex.index;
                                }
                            }

                            if (productDistributorAssociationFormPackagingIndex !== null) {
                                this.productDistributorAssociationFormPackagingIndexByFormId.set(formId, productDistributorAssociationFormPackagingIndex);
                            }
                        }

                        if (productDistributorAssociationFormPackagingIndex === index) {
                            productDistributorAssociationRows.push(
                                <ProductDistributorAssociationRow
                                    key={ formId.getValue() }
                                    productDistributorAssociationFormId={ formId }
                                    productDistributorAssociationForm={ form }
                                    distributorSortedOptionsAndLabelNames={ distributorSortedOptionsAndLabelNamesWithoutNullVendor }
                                    unitSortedOptionsAndLabelNames={ unitSortedOptionsAndLabelNames }
                                    onFormFieldChange={ this.onProductDistributorAssociationRowFormFieldChange }
                                    onFormFieldBlur={ this.onProductDistributorAssociationRowFormFieldBlur }
                                    onRemoveProductDistributorAssociationForms={ onRemoveProductDistributorAssociationForms }
                                    onCreateCustomDistributorClick={ this.onCreateCustomDistributorClick }
                                />
                            );
                        }
                    });
                }

                const onPackagingFormFieldChange = (packagingFormValidationInputDataByFieldName : PackagingFormValidationInputDataByFieldName) => {
                    this.onPackagingFormFieldChange({
                        index,
                        packagingFormValidationInputDataByFieldName
                    });
                };

                packagingFormRows.push(
                    <div key={ index } className="product-form-row">
                        <div className={ `product-form-row-content product-form-row-content-fill ${ window.GLOBAL_FEATURE_ACCESS.multi_packaging ? 'card' : '' }` }>
                            { (window.GLOBAL_FEATURE_ACCESS.multi_packaging || (shownPackagingFormsValidationInputDataByFieldName.length > 1)) &&
                                <Button
                                    buttonClassName="flat right"
                                    isDisabled={ shownPackagingFormsValidationInputDataByFieldName.length <= 1 }
                                    isLoading={ false }
                                    onClick={ this.onRemovePackagingFormClick(index, productDistributorAssociationFormIds) }
                                >
                                    X
                                </Button>
                            }
                            <PackagingForm
                                ref={ data.ref }
                                initialPackaging={ data.initialPackaging }
                                onFormFieldChange={ onPackagingFormFieldChange }
                            />

                            { window.GLOBAL_FEATURE_ACCESS.multi_vendor &&
                                <div className="product-distributor-associations">
                                    { productDistributorAssociationRows.length > 0 &&
                                        <h6>Purchasing Options</h6>
                                    }
                                    { productDistributorAssociationRows }

                                    <Button
                                        buttonClassName="flat primary with-icon no-pad"
                                        isDisabled={ false }
                                        isLoading={ false }
                                        onClick={ this.onAddProductDistributorAssociationForm(index) }
                                    >
                                        <span className="main-icon-left bevicon bevico-add"/>
                                        Add Purchasing Option
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                );
            }
        });

        const packagingIdValuesWithPackagingWeightForms = new Set(Object.keys(validationInputDataByFieldName.packagingWeights));
        const packagingWeightFormPackagingIdIOptions = ProductFormUtils.getPackagingWeightFormPackagingIdIOptions(shownPackagingFormsValidationInputDataByFieldName, packagingIdValuesWithPackagingWeightForms);

        let availablePackagingIdOptionsCount = 0;
        packagingWeightFormPackagingIdIOptions.forEach((iOption) => {
            if (!iOption.disabled) {
                availablePackagingIdOptionsCount += 1;
            }
        });
        const canAddPackagingWeightForm = validationInputDataByFieldName.useCustomWeights.value && (availablePackagingIdOptionsCount > 0);

        const packagingWeightFormRows : Array<JSX.Element> = [];
        if (validationInputDataByFieldName.useCustomWeights.value) {
            Object.keys(validationInputDataByFieldName.packagingWeights).map((packagingIdValue) => {
                const packagingWeightFormValidationInputDataByFieldName = validationInputDataByFieldName.packagingWeights[packagingIdValue];

                let packagingIdSelectedIOption : IOption | null = null;
                allPackagingUnitSortedOptionsAndLabelName[0][1].forEach((packagingUnitOption) => {
                    if (packagingUnitOption.value === packagingIdValue) {
                        packagingIdSelectedIOption = packagingUnitOption;
                    }
                });

                let packagingWeightFormIsValid = true;
                Object.keys(packagingWeightFormValidationInputDataByFieldName).forEach((packagingWeightKey) => {
                    const fieldName = packagingWeightKey as PackagingWeightFormFieldName;
                    const value = (packagingWeightFormValidationInputDataByFieldName)[fieldName].value;
        
                    const validationResult = ProductFormUtils.validatePackagingWeightValueByFieldName(fieldName, value);
                    packagingWeightFormIsValid = packagingWeightFormIsValid && validationResult.isValid;    
                });

                let defaultEmptyWeightValue : null | string = null;
                let defaultFullWeightValue : null | string = null;
                let defaultEmptyWeightUnit : null | MassUnit = null;
                let defaultFullWeightUnit : null | MassUnit = null;
                const shownPackagingFormDataIndex = shownPackagingFormDataIndexByFormPackagingIdValue.get(packagingIdValue);
                if (packagingWeightFormIsValid && (typeof shownPackagingFormDataIndex !== 'undefined')) {
                    const packagingFormData = this.packagingFormData[shownPackagingFormDataIndex.index];

                    if (packagingFormData.packagingForm && (shownPackagingFormDataIndex.productQuantityUnit instanceof PackagingId)) {
                        const packaging = packagingFormData.packagingForm.getPackaging(false);

                        if (packaging) {
                            const containedPackaging = PackagingUtils.getPackagingForContainedPackagingId(packaging, shownPackagingFormDataIndex.productQuantityUnit);
                            const formPackagingWeight = ProductFormUtils.getPackagingWeight(packagingWeightFormValidationInputDataByFieldName);

                            const defaultPackagingWeight = packagingWeightUtils.getPackagingWeightWithDefaultsApplied(containedPackaging, formPackagingWeight);
                            const defaultFullWeight = defaultPackagingWeight.getFullWeight();
                            const defaultEmptyWeight = defaultPackagingWeight.getEmptyWeight();

                            if (defaultEmptyWeight) {
                                defaultEmptyWeightValue = defaultEmptyWeight.getQuantity().toFixed(1);
                                defaultEmptyWeightUnit = defaultEmptyWeight.getUnit();
                            }

                            if (defaultFullWeight) {
                                defaultFullWeightValue = defaultFullWeight.getQuantity().toFixed(1);
                                defaultFullWeightUnit = defaultFullWeight.getUnit();
                            }
                        }
                    }
                }

                let emptyWeightUnitSelectedIOption = null;
                if (!packagingWeightFormValidationInputDataByFieldName.emptyWeightQuantity.value && defaultEmptyWeightValue && defaultEmptyWeightUnit) {
                    emptyWeightUnitSelectedIOption = {
                        value: defaultEmptyWeightUnit,
                        label: oldPackagingUtils.getDisplayTextForUnit(defaultEmptyWeightUnit),
                        icon: null,
                    };
                } else if (packagingWeightFormValidationInputDataByFieldName.emptyWeightUnit.value) {
                    emptyWeightUnitSelectedIOption = {
                        value: packagingWeightFormValidationInputDataByFieldName.emptyWeightUnit.value,
                        label: oldPackagingUtils.getDisplayTextForUnit(packagingWeightFormValidationInputDataByFieldName.emptyWeightUnit.value as MassUnit),
                        icon: null,
                    };
                }

                let fullWeightUnitSelectedIOption = null;
                if (!packagingWeightFormValidationInputDataByFieldName.fullWeightQuantity.value && defaultFullWeightValue && defaultFullWeightUnit) {
                    fullWeightUnitSelectedIOption = {
                        value: defaultFullWeightUnit,
                        label: oldPackagingUtils.getDisplayTextForUnit(defaultFullWeightUnit),
                        icon: null,
                    };
                } else if (packagingWeightFormValidationInputDataByFieldName.fullWeightUnit.value) {
                    fullWeightUnitSelectedIOption = {
                        value: packagingWeightFormValidationInputDataByFieldName.fullWeightUnit.value,
                        label: oldPackagingUtils.getDisplayTextForUnit(packagingWeightFormValidationInputDataByFieldName.fullWeightUnit.value as MassUnit),
                        icon: null,
                    };
                }

                this.displayedEmptyWeightValue = packagingWeightFormValidationInputDataByFieldName.emptyWeightQuantity.value || defaultEmptyWeightValue || '';
                this.displayedFullWeightValue = packagingWeightFormValidationInputDataByFieldName.fullWeightQuantity.value || defaultFullWeightValue || '';
                this.displayedEmptyWeightUnitValue = emptyWeightUnitSelectedIOption ? emptyWeightUnitSelectedIOption.value : '';
                this.displayedFullWeightUnitValue = fullWeightUnitSelectedIOption ? fullWeightUnitSelectedIOption.value : '';

                packagingWeightFormRows.push(
                    <div key={ packagingIdValue } className="packaging-weight-row">
                        <Button
                            buttonClassName="remove-btn with-icon flat"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ this.onRemovePackagingWeightFormClick(packagingIdValue) }
                        >
                            <span className="bevicon bevico-remove"/>
                        </Button>
                        <div className="product-form-row-content product-form-row-content-fill">
                            <div className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                <div className="product-form-row-flex-block packaging-id-dropdown floating-dropdown-menu">
                                    <Select2DropdownMenu
                                        isSearchable={ false }
                                        buttonShouldDisplaySelectedLabel={ true }
                                        selectedOptionHeaderShown={ false }
                                        sortedOptionsAndLabelName={ [[null, packagingWeightFormPackagingIdIOptions]] }
                                        selectedOption={ packagingIdSelectedIOption }
                                        onOptionSelect={ this.onPackagingWeightFormPackagingIdValueSelect(packagingIdValue) }
                                        placeholderText="Select Unit"
                                        emptyState={ null }
                                        hasCreateCustomOption={ false }
                                        createCustomOption={ this.doNothing }
                                        customOptionGroupLabel={ null }
                                        createCustomOptionButtonLabel={ null }
                                    />
                                </div>
                                <div className="product-form-row-flex-block empty-and-full-weight-section floating-dropdown-menu">
                                    <div className="product-form-weight-cell">
                                        <div className="product-form-weight-cell-input-wrap">
                                            <ValidationInput
                                                key="emptyWeightQuantity"
                                                inputClassName=""
                                                type="text"
                                                autoFocus={ false }
                                                autoComplete={ null }
                                                value={ packagingWeightFormValidationInputDataByFieldName.emptyWeightQuantity.value }
                                                errorMessage={ packagingWeightFormValidationInputDataByFieldName.emptyWeightQuantity.errorMessage }
                                                isValid={ packagingWeightFormValidationInputDataByFieldName.emptyWeightQuantity.isValid }
                                                handleBlur={ this.onEmptyWeightQuantityBlur(packagingIdValue) }
                                                handleFocus={ null }
                                                handleChange={ this.onEmptyWeightQuantityChange(packagingIdValue) }
                                                handleEnterClick={ this.doNothing }
                                                label="Tare"
                                                hintText={ defaultEmptyWeightValue }
                                                isDisabled={ false }
                                            />
                                        </div>
                                        <div className="floating-dropdown-menu">
                                            <Select2DropdownMenu
                                                isSearchable={ false }
                                                buttonShouldDisplaySelectedLabel={ true }
                                                selectedOptionHeaderShown={ false }
                                                sortedOptionsAndLabelName={ weightUnitOptions }
                                                selectedOption={ emptyWeightUnitSelectedIOption }
                                                onOptionSelect={ this.onEmptyWeightUnitSelect(packagingIdValue) }
                                                placeholderText={ null }
                                                emptyState={ null }
                                                hasCreateCustomOption={ false }
                                                createCustomOption={ this.doNothing }
                                                customOptionGroupLabel={ null }
                                                isDisabled={ false }
                                                createCustomOptionButtonLabel={ null }
                                            />
                                        </div>
                                    </div>
                                    <div className="product-form-weight-cell">
                                        <div className="product-form-weight-cell-input-wrap">
                                            <ValidationInput
                                                key="fullWeightQuantity"
                                                inputClassName=""
                                                type="text"
                                                autoFocus={ false }
                                                autoComplete={ null }
                                                value={ packagingWeightFormValidationInputDataByFieldName.fullWeightQuantity.value }
                                                errorMessage={ packagingWeightFormValidationInputDataByFieldName.fullWeightQuantity.errorMessage }
                                                isValid={ packagingWeightFormValidationInputDataByFieldName.fullWeightQuantity.isValid }
                                                handleBlur={ this.onFullWeightQuantityBlur(packagingIdValue) }
                                                handleFocus={ null }
                                                handleChange={ this.onFullWeightQuantityChange(packagingIdValue) }
                                                handleEnterClick={ this.doNothing }
                                                label="Full"
                                                hintText={ defaultFullWeightValue }
                                                isDisabled={ false }
                                            />
                                        </div>
                                        <div className="floating-dropdown-menu">
                                            <Select2DropdownMenu
                                                isSearchable={ false }
                                                buttonShouldDisplaySelectedLabel={ true }
                                                selectedOptionHeaderShown={ false }
                                                sortedOptionsAndLabelName={ weightUnitOptions }
                                                selectedOption={ fullWeightUnitSelectedIOption }
                                                onOptionSelect={ this.onFullWeightUnitSelect(packagingIdValue) }
                                                placeholderText={ null }
                                                emptyState={ null }
                                                hasCreateCustomOption={ false }
                                                createCustomOption={ this.doNothing }
                                                customOptionGroupLabel={ null }
                                                isDisabled={ false }
                                                createCustomOptionButtonLabel={ null }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }

        const sortedPriceEstimates : Array<{ unit : string, priceEstimateString : string}> = []; // TODO Cheezy
        const categorySearchBarValue = this.state.categorySearchBarValue;
        const isDuplicateCategoryName = categorySearchBarValue ? CategoryUtils.isDuplicateCategoryName(categorySearchBarValue, null, categoriesById) : false;
        return (
            <div className="product-form">
                <div className="product-form-container">
                    <div className="item-card-header dark">
                        <ValidationInput
                            key="brand"
                            inputClassName="product-form-brand-input"
                            type="text"
                            autoFocus={ false }
                            autoComplete={ null }
                            value={ validationInputDataByFieldName.brand.value }
                            errorMessage={ validationInputDataByFieldName.brand.errorMessage }
                            isValid={ validationInputDataByFieldName.brand.isValid }
                            handleBlur={ this.onBrandChangeOrBlur }
                            handleFocus={ null }
                            handleChange={ this.onBrandChangeOrBlur }
                            handleEnterClick={ this.doNothing }
                            label="Item Brand"
                            hintText={ null }
                            isDisabled={ false }
                        />

                        <ValidationInput
                            key="name"
                            inputClassName="product-form-name-input"
                            type="text"
                            autoFocus={ false }
                            autoComplete={ null }
                            value={ validationInputDataByFieldName.name.value }
                            errorMessage={ validationInputDataByFieldName.name.errorMessage }
                            isValid={ validationInputDataByFieldName.name.isValid }
                            handleBlur={ this.onNameChangeOrBlur }
                            handleFocus={ null }
                            handleChange={ this.onNameChangeOrBlur }
                            handleEnterClick={ this.doNothing }
                            label="Item Name"
                            hintText={ null }
                            isDisabled={ false }
                        />
                        <div className="product-form-dropdown-row">
                            <div className={ `floating-dropdown-menu ${ !validationInputDataByFieldName.categoryId.isValid ? 'validation-error' : ''}` }>
                                { !validationInputDataByFieldName.categoryId.isValid &&
                                    <span className="error-text">{ validationInputDataByFieldName.categoryId.errorMessage }</span>
                                }
                                <label>Category</label>
                                <Select2DropdownMenu
                                    isSearchable={ true }
                                    buttonShouldDisplaySelectedLabel={ true }
                                    selectedOptionHeaderShown={ true }
                                    sortedOptionsAndLabelName={ categoryIdSortedOptionsAndLabelNames }
                                    selectedOption={ categorySelectedIOption }
                                    onOptionSelect={ this.onCategoryIdSelect }
                                    placeholderText="Select Category"
                                    emptyState={ null }
                                    hasCreateCustomOption={ !isDuplicateCategoryName }
                                    createCustomOption={ isDuplicateCategoryName ? null : this.onCreateCustomCategoryId }
                                    onSearchValueChanged={ this.onCategorySearchValueChanged }
                                    customOptionGroupLabel={ null }
                                    isTitleCase={ true }
                                    createCustomOptionButtonLabel={ null }
                                />
                            </div>
                            <div className="floating-dropdown-menu">
                                <label>Subcategory (optional)</label>
                                <Select2DropdownMenu
                                    isSearchable={ true }
                                    buttonShouldDisplaySelectedLabel={ true }
                                    selectedOptionHeaderShown={ true }
                                    sortedOptionsAndLabelName={ productTypeSortedOptionsAndLabelNames }
                                    selectedOption={ productTypeSelectedIOption }
                                    onOptionSelect={ this.onTypeSelect }
                                    placeholderText="Select Subcategory"
                                    emptyState={ null }
                                    hasCreateCustomOption={ true }
                                    createCustomOption={ this.onCreateCustomType }
                                    customOptionGroupLabel={ null }
                                    isTitleCase={ true }
                                    createCustomOptionButtonLabel={ null }
                                />
                            </div>
                        </div>
                    </div>

                    <section className="product-form-section">
                        <header className="product-form-section-header col-row">
                            <div className="col-xs-6 no-pad">
                                <h4>Packaging</h4>
                            </div>
                            <div className="col-xs-6 text-right no-pad">
                                { window.GLOBAL_FEATURE_ACCESS.multi_packaging &&
                                    <Button
                                        buttonClassName="flat with-icon primary right"
                                        isDisabled={ false }
                                        isLoading={ false }
                                        onClick={ this.onAddPackagingFormClick }
                                    >                                        
                                        <span className="main-icon-left bevicon bevico-add"/>
                                        <span>Add Packaging</span>
                                    </Button>
                                }
                            </div>
                        </header>

                        <div className="product-form-content">
                            <div>
                                { packagingFormRows }
                            </div>
                                {/* vendor and vendor sku */}
                                { !window.GLOBAL_FEATURE_ACCESS.multi_vendor && 
                                    <div className="product-form-block">
                                        <div className="product-form-row vendor-form-row">
                                            <div className="product-form-row-content">
                                                <h5>Vendor</h5>
                                            </div>
                                            <div className="product-form-row-content product-form-row-content-fill">
                                                <div className="product-form-row-flex-block-wrap">
                                                    <div className="floating-dropdown-menu">
                                                        <div className="vendor-flex-box floating-dropdown-menu">
                                                            <Select2DropdownMenu
                                                                isSearchable={ true }
                                                                buttonShouldDisplaySelectedLabel={ true }
                                                                selectedOptionHeaderShown={ true }
                                                                sortedOptionsAndLabelName={ productFormData.distributorSortedOptionsAndLabelNames }
                                                                selectedOption={ distributorSelectedIOption }
                                                                onOptionSelect={ this.onDistributorSelect }
                                                                placeholderText="Select Vendor"
                                                                emptyState={ null }
                                                                hasCreateCustomOption={ true }
                                                                createCustomOption={ this.onCreateCustomDistributorClick }
                                                                customOptionGroupLabel="My Vendors"
                                                                createCustomOptionButtonLabel={ null }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-form-row base-unit-row">
                                            <div className="product-form-row-content">
                                                <h5>SKU</h5>
                                            </div>
                                            <div className="product-form-row-content product-form-row-content-fill">
                                                <div className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                                    <div className="product-form-row-flex-block">
                                                        <ValidationInput
                                                            key="sku"
                                                            inputClassName=""
                                                            type="text"
                                                            autoFocus={ false }
                                                            autoComplete={ null }
                                                            value={ validationInputDataByFieldName.sku.value }
                                                            errorMessage={ validationInputDataByFieldName.sku.errorMessage }
                                                            isValid={ validationInputDataByFieldName.sku.isValid }
                                                            handleBlur={ this.onSkuChangeOrBlur }
                                                            handleFocus={ null }
                                                            handleChange={ this.onSkuChangeOrBlur }
                                                            handleEnterClick={ this.doNothing }
                                                            label={ null }
                                                            hintText="SKU from invoice"
                                                            isDisabled={ false }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* price and deposit */}
                                { !window.GLOBAL_FEATURE_ACCESS.multi_vendor && 
                                    <div className="product-form-block">
                                        <div className="product-form-row price-form-row">
                                            <div className="product-form-row-content">
                                                <h5>Price</h5>
                                            </div>
                                            <div className="product-form-row-content product-form-row-content-fill">
                                                <div className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                                    <div className="product-form-row-flex-block floating-dropdown-menu">
                                                        <ValidationInput
                                                            key="priceAmount"
                                                            inputClassName=""
                                                            type="text"
                                                            autoFocus={ false }
                                                            autoComplete={ null }
                                                            errorMessage={ validationInputDataByFieldName.priceAmount.errorMessage }
                                                            isValid={ validationInputDataByFieldName.priceAmount.isValid }
                                                            handleBlur={ this.onPriceAmountBlur }
                                                            handleFocus={ null }
                                                            handleChange={ this.onPriceAmountChange }
                                                            handleEnterClick={ this.doNothing }
                                                            label={ null }
                                                            hintText={ null }
                                                            value={ validationInputDataByFieldName.priceAmount.value }
                                                            isDisabled={ false }
                                                            isCurrencyInput={ true }
                                                        />
                                                        <span>/</span>
                                                        <div className="floating-dropdown-menu">
                                                            <Select2DropdownMenu
                                                                isSearchable={ false }
                                                                buttonShouldDisplaySelectedLabel={ true }
                                                                selectedOptionHeaderShown={ false }
                                                                sortedOptionsAndLabelName={ priceUnitSortedOptionsAndLabelName }
                                                                selectedOption={ priceUnitSelectedIOption }
                                                                onOptionSelect={ this.onPriceUnitSelect }
                                                                placeholderText="Select Unit"
                                                                emptyState={ null }
                                                                hasCreateCustomOption={ false }
                                                                createCustomOption={ this.doNothing }
                                                                customOptionGroupLabel={ null }
                                                                createCustomOptionButtonLabel={ null }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-form-row base-unit-row">
                                            <div className="product-form-row-content">
                                                <h5>Deposit / { priceUnitSelectedIOption ? priceUnitSelectedIOption.label : '?' }</h5>
                                            </div>
                                            <div className="product-form-row-content product-form-row-content-fill">
                                                <div className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                                    <div className="product-form-row-flex-block">
                                                        <ValidationInput
                                                            key="unitDeposit"
                                                            inputClassName=""
                                                            type="text"
                                                            autoFocus={ false }
                                                            autoComplete={ null }
                                                            errorMessage={ validationInputDataByFieldName.unitDeposit.errorMessage }
                                                            isValid={ validationInputDataByFieldName.unitDeposit.isValid }
                                                            value={ validationInputDataByFieldName.unitDeposit.value }
                                                            handleBlur={ this.onUnitDepositBlur }
                                                            handleFocus={ null }
                                                            handleChange={ this.onUnitDepositChange }
                                                            handleEnterClick={ this.doNothing }
                                                            label={ null }
                                                            hintText={ null }
                                                            isDisabled={ false }
                                                            isCurrencyInput={ true }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="product-form-row-flex-block-wrap">
                                                    <PriceEstimates
                                                        currencySymbol={ this.currencySymbol }
                                                        sortedPriceEstimates={ sortedPriceEstimates }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                        </div>
                    </section>
                    <section className="product-form-section product-form-section-reporting">
                        <header className="product-form-section-header col-row">
                            <div className="col-xs-6 no-pad">
                                <h4>Reporting</h4>
                            </div>
                        </header>
                            <div className="product-form-content">

                                {/* preferred reporting unit */}
                                <div className="product-form-block">
                                    <div className="product-form-row preferred-reporting-unit-row">
                                        <div className="product-form-row-content">
                                            <h5>Reporting Unit</h5>
                                        </div>
                                        <div className="product-form-row-content product-form-row-content-fill">
                                            <div className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                                <div className="product-form-row-flex-block">
                                                    <div className="floating-dropdown-menu">
                                                        <Select2DropdownMenu
                                                            isSearchable={ false }
                                                            buttonShouldDisplaySelectedLabel={ true }
                                                            selectedOptionHeaderShown={ false }
                                                            sortedOptionsAndLabelName={ allPackagingUnitSortedOptionsAndLabelName }
                                                            selectedOption={ preferredReportingUnitSelectedIOption }
                                                            onOptionSelect={ this.onPreferredReportingUnitSelect }
                                                            placeholderText="Select Unit"
                                                            emptyState={ null }
                                                            hasCreateCustomOption={ false }
                                                            createCustomOption={ this.doNothing }
                                                            customOptionGroupLabel={ null }
                                                            createCustomOptionButtonLabel={ null }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    { (window.GLOBAL_FEATURE_ACCESS.multi_packaging || (shownPackagingFormsValidationInputDataByFieldName.length > 1)) &&
                                        <div className="product-form-row base-unit-row">
                                            <div className="product-form-row-content">
                                                <h5>Base Unit</h5>
                                            </div>
                                            <div className="product-form-row-content product-form-row-content-fill">
                                                <div className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                                    <div className="product-form-row-flex-block">
                                                        <div className="floating-dropdown-menu">
                                                            <Select2DropdownMenu
                                                                isSearchable={ false }
                                                                buttonShouldDisplaySelectedLabel={ true }
                                                                selectedOptionHeaderShown={ false }
                                                                sortedOptionsAndLabelName={ baseUnitSortedOptionsAndLabelName }
                                                                selectedOption={ baseUnitSelectedIOption }
                                                                onOptionSelect={ this.onBaseUnitSelect }
                                                                placeholderText="Select Unit"
                                                                emptyState={ null }
                                                                hasCreateCustomOption={ false }
                                                                createCustomOption={ this.doNothing }
                                                                customOptionGroupLabel={ null }
                                                                createCustomOptionButtonLabel={ null }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="product-form-block">
                                    <div className="product-form-row cost-form-row">
                                        <div className="product-form-row-content">
                                            <h5>Cost</h5>
                                        </div>
                                        <div className="product-form-row-content product-form-row-content-fill">
                                            <div className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                                <div className="product-form-row-flex-block cost-form-row floating-dropdown-menu">
                                                    <ValidationInput
                                                        key="costAmount"
                                                        inputClassName=""
                                                        type="text"
                                                        autoFocus={ false }
                                                        autoComplete={ null }
                                                        errorMessage={ validationInputDataByFieldName.costAmount.errorMessage }
                                                        isValid={ validationInputDataByFieldName.costAmount.isValid }
                                                        handleBlur={ this.onCostAmountBlur }
                                                        handleFocus={ null }
                                                        handleChange={ this.onCostAmountChange }
                                                        handleEnterClick={ this.doNothing }
                                                        label={ null }
                                                        hintText={ null }
                                                        value={ validationInputDataByFieldName.costAmount.value }
                                                        isDisabled={ false }
                                                        isCurrencyInput={ true }
                                                    />
                                                    <span>/</span>
                                                    <div className="floating-dropdown-menu">
                                                        <Select2DropdownMenu
                                                            isSearchable={ false }
                                                            buttonShouldDisplaySelectedLabel={ true }
                                                            selectedOptionHeaderShown={ false }
                                                            sortedOptionsAndLabelName={ allPackagingUnitSortedOptionsAndLabelName }
                                                            selectedOption={ costUnitSelectedIOption }
                                                            onOptionSelect={ this.onCostUnitSelect }
                                                            placeholderText="Select Unit"
                                                            emptyState={ null }
                                                            hasCreateCustomOption={ false }
                                                            createCustomOption={ this.doNothing }
                                                            customOptionGroupLabel={ null }
                                                            createCustomOptionButtonLabel={ null }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-form-row par-form-row">
                                        <div className="product-form-row-content">
                                            <h5>Par</h5>
                                        </div>
                                        <div className="product-form-row-content product-form-row-content-fill">
                                            <div className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                                <div className="product-form-row-flex-block par-form-row floating-dropdown-menu">
                                                    <ValidationInput
                                                        key="parCount"
                                                        inputClassName=""
                                                        type="text"
                                                        autoFocus={ false }
                                                        autoComplete={ null }
                                                        errorMessage={ validationInputDataByFieldName.parCount.errorMessage }
                                                        isValid={ validationInputDataByFieldName.parCount.isValid }
                                                        handleBlur={ this.onParCountBlur }
                                                        handleFocus={ null }
                                                        handleChange={ this.onParCountChange }
                                                        handleEnterClick={ this.doNothing }
                                                        label={ null }
                                                        hintText={ null }
                                                        value={ validationInputDataByFieldName.parCount.value }
                                                        isDisabled={ false }
                                                    />
                                                    <div className="floating-dropdown-menu">
                                                        <Select2DropdownMenu
                                                            isSearchable={ false }
                                                            buttonShouldDisplaySelectedLabel={ true }
                                                            selectedOptionHeaderShown={ false }
                                                            sortedOptionsAndLabelName={ allPackagingUnitSortedOptionsAndLabelName }
                                                            selectedOption={ parUnitSelectedIOption }
                                                            onOptionSelect={ this.onParUnitSelect }
                                                            placeholderText="Select Unit"
                                                            emptyState={ null }
                                                            hasCreateCustomOption={ false }
                                                            createCustomOption={ this.doNothing }
                                                            customOptionGroupLabel={ null }
                                                            createCustomOptionButtonLabel={ null }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* conversions */}
                                { (window.GLOBAL_FEATURE_ACCESS.multi_packaging || (shownPackagingFormsValidationInputDataByFieldName.length > 1)) &&
                                    <div className="product-form-block">
                                        <div className="product-form-row conversions-row">
                                            <div className="product-form-row-content">
                                                <h5>Conversions</h5>
                                            </div>
                                            <div className="product-form-row-content product-form-row-content-fill">
                                                {
                                                    Object.keys(validationInputDataByFieldName.conversions).map((unit) => {
                                                        const onConversionFieldChange = (event : React.ChangeEvent<HTMLInputElement>) => {
                                                            return this.props.onConversionFieldChange(unit, event.currentTarget.value);
                                                        };

                                                        const onConversionFieldBlur = (event : React.FocusEvent<HTMLInputElement>) => {
                                                            return this.props.onConversionFieldBlur(unit, event.currentTarget.value);
                                                        };

                                                        const validationInputData = validationInputDataByFieldName.conversions[unit];

                                                        return (
                                                            <div key={ unit } className="product-form-row-flex-block-wrap flex-block-wrap-row-desktop">
                                                                <div className="product-form-row-flex-block">
                                                                    <span className="unit-label">{ oldPackagingUtils.getDisplayTextForUnit(unit as any) }</span>
                                                                    <span className="cell-pr-sm">{'->'}</span>
                                                                    <ValidationInput
                                                                        inputClassName=""
                                                                        type="text"
                                                                        autoFocus={ false }
                                                                        autoComplete={ null }
                                                                        value={ validationInputData.value }
                                                                        errorMessage={ validationInputData.errorMessage }
                                                                        isValid={ validationInputData.isValid }
                                                                        handleBlur={ onConversionFieldBlur }
                                                                        handleFocus={ null }
                                                                        handleChange={ onConversionFieldChange }
                                                                        handleEnterClick={ this.doNothing }
                                                                        label={ null }
                                                                        hintText={ null }
                                                                        isDisabled={ false }
                                                                    />
                                                                    <span className="base-unit-label">{ baseUnitSelectedIOption ? baseUnitSelectedIOption.label : '?' }</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                    </section>

                    { window.GLOBAL_FEATURE_ACCESS.bottle_weighing &&
                        <section className="product-form-section">
                            <header className="product-form-section-header">
                                <h4>Inventory Weight</h4>
                            </header>

                            <div className="product-form-content">
                                <div className="product-form-block">
                                    <div className={ `product-form-row ${!validationInputDataByFieldName.useCustomWeights.value ? 'disabled' : ''}` }>
                                        <div className="product-form-row-content">
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <CheckBox
                                                        checkBoxTriState={ validationInputDataByFieldName.useCustomWeights.value ? CheckBoxTriState.Checked : CheckBoxTriState.Unchecked }
                                                        isDisabled={ false }
                                                        label={ {
                                                            labelOrientation: LabelOrientation.LEFT,
                                                            value: 'Set custom tare/full weights for this item',
                                                        } }
                                                        onClick={ this.onUseCustomWeightsClick }
                                                    />
                                                </div>
                                            </div>

                                            { canAddPackagingWeightForm &&
                                                <Button
                                                    buttonClassName="flat"
                                                    isDisabled={ false }
                                                    isLoading={ false }
                                                    onClick={ this.onAddPackagingWeightFormClick }
                                                >
                                                    <span>Add Packaging Weight</span>
                                                </Button>
                                            }

                                            { packagingWeightFormRows }
                                            
                                            <div className="product-form-fine-print">
                                                <p>
                                                    <small>Used for weighing items with a standard mass scale. Set custom weights for the most accurate results.</small>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }

                    <section className="product-form-section">
                        <header className="product-form-section-header">
                            <h4>For your records</h4>
                        </header>

                        <div className="product-form-content">
                            <div className="product-form-block">
                                <div className="product-form-row">
                                    <div className="product-form-row-content">
                                        <h5>
                                            GL Code&nbsp;
                                            {
                                                glCodeIsCustom &&
                                                <span
                                                    title="Custom GL Code added. If integrating with an accounting system through BevSpot, item will inherit category GL code for the integration."
                                                    className="bevicon bevico-warning gl-code-warning"
                                                />
                                            }
                                        </h5>
                                    </div>
                                    <div className="product-form-row-content product-form-row-content-fill">
                                        <div className="product-form-row-flex-block-wrap">
                                            <div className="product-form-row-flex-block floating-dropdown-menu">
                                                <Select2DropdownMenu
                                                    isSearchable={ true }
                                                    buttonShouldDisplaySelectedLabel={ true }
                                                    selectedOptionHeaderShown={ true }
                                                    sortedOptionsAndLabelName={ glCodeSortedOptionsAndLabelNames }
                                                    selectedOption={ glCodeSelectedIOption }
                                                    onOptionSelect={ this.onGLCodeSelect }
                                                    placeholderText="Select GL Code"
                                                    emptyState={ null }
                                                    hasCreateCustomOption={ true }
                                                    createCustomOption={ this.onGLCodeSelect }
                                                    customOptionGroupLabel={ "Custom GL Codes" }
                                                    isTitleCase={ false }
                                                    createCustomOptionButtonLabel={ null }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-form-content">
                            <div className="product-form-block">
                                <div className="product-form-row">
                                    <div className="product-form-row-content">
                                        <h5>Your notes</h5>
                                    </div>
                                    <div className="product-form-row-content">
                                        <ExpandingNote
                                            theme={ ExpandingNoteTheme.Underline }
                                            onChange={ this.onNoteChange }
                                            onBlur={ this.doNothing }
                                            noteContent={ validationInputDataByFieldName.note.value }
                                            placeholder="Add anything you want to keep track of: ordering info, tasting notes, etc."
                                            isDisabled={ false }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="item-card-controls">
                    { productId !== null && // archive/unarchive + delete for edit products view
                        <div className="left-buttons">
                            { productIsActive &&
                                <Button
                                    buttonClassName="flat with-icon"
                                    isDisabled={ isSubmitting }
                                    isLoading={ false }
                                    onClick={ this.onSetProductAsInactive }
                                >
                                    <span className="bevicon bevico-archive main-icon-left"/>
                                    <span>Archive</span>
                                </Button>
                            }
                            { !productIsActive &&
                                <Button
                                    buttonClassName="flat with-icon"
                                    isDisabled={ isSubmitting }
                                    isLoading={ false }
                                    onClick={ this.onSetProductAsActive }
                                >
                                    <span className="bevicon bevico-unarchive main-icon-left"/>
                                    <span>Unarchive</span>
                                </Button>
                            }
                            <Button
                                buttonClassName="flat with-icon danger"
                                isDisabled={ isSubmitting }
                                isLoading={ false }
                                onClick={ this.onShowDeleteProductConfirmationDialog }
                            >
                                <span className="bevicon bevico-delete main-icon-left"/>
                                <span>Delete</span>
                            </Button>
                        </div>
                    }

                    { !isValid &&
                        <div className="product-form-error-message">{ errorMessage }</div>
                    }

                    <div className="right-buttons">
                        <Button
                            buttonClassName="flat"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ onProductFormCancelClick }
                        >
                            Cancel
                        </Button>
                        <Button
                            buttonClassName="flat primary"
                            isDisabled={ isSubmitting }
                            isLoading={ isSubmitting }
                            onClick={ this.onProductFormSaveClick }
                        >
                            Save
                        </Button>
                    </div>
                </div>

                { productModificationConfirmationDialogIsShown &&
                    <ProductModificationConfirmationDialog
                        onCreateNewClick={ this.onProductModificationConfirmationDialogCreateNewClick }
                        onModifyExistingClick={ this.onProductModificationConfirmationDialogModifyExistingClick }
                        onCancelClick={ this.onProductModificationConfirmationDialogCancelClick }
                    />
                }
                { deleteProductConfirmationDialogIsShown &&
                    <DeleteProductsConfirmationDialog
                        onCancelClick={ this.onCloseDeleteProductConfirmationDialog }
                        onDeleteItemsClick={ this.handleConfirmDeleteProduct }
                        productIds={ new StringValueSet() }
                        productsById={ new StringValueMap() }
                        productIdentificationString={ (validationInputDataByFieldName.brand.value + ' ' + validationInputDataByFieldName.name.value) }
                        deleteActionInformationText={ null }
                    />
                }
            </div>
        );
    }

    private readonly getInitialPackagingFormData = (initialPackagings : Array<Packaging | PackagingFormValidationInputDataByFieldName | null>) => {
        return initialPackagings.map((initialPackaging, index) => {
            return {
                packagingForm: null,
                initialPackaging,
                ref: this.createPackagingFormRef(index),
                isShown: true,
            };
        });
    }

    private readonly createPackagingFormRef = (index : number) => {
        return (packagingForm : PackagingForm) => {
            this.packagingFormData[index].packagingForm = packagingForm;
        };
    }

    private readonly onBrandChangeOrBlur = (event : React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
        this.props.onFormFieldChange('brand', event.currentTarget.value);
    }

    private readonly onNameChangeOrBlur = (event : React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
        this.props.onFormFieldChange('name', event.currentTarget.value);
    }

    private readonly onCategoryIdSelect = (categoryIdValue : string) => {
        this.props.onFormFieldChange('categoryId', categoryIdValue);
    }

    private readonly onCreateCustomCategoryId = (categoryIdValue : string) => {
        this.props.onFormFieldChange('categoryId', categoryIdValue);
    }

    private readonly onTypeSelect = (productType : string) => {
        this.props.onFormFieldChange('type', productType);
    }

    private readonly onCreateCustomType = (productType : string) => {
        this.props.onFormFieldChange('type', productType);
    }

    private readonly onPackagingFormFieldChange = (changeData : {index: number, packagingFormValidationInputDataByFieldName : PackagingFormValidationInputDataByFieldName} | null) => {
        const shownPackagingFormsValidationInputDataByFieldName = this.getShownPackagingFormsValidationInputDataByFieldName();

        // TODO Cheezy can be smarter with replacement in case where user simply wants to rename package by comparing old and new values
        if (changeData) {
            shownPackagingFormsValidationInputDataByFieldName.push(changeData.packagingFormValidationInputDataByFieldName);

            const changeDataPackagingUnitSortedOptionsAndLabelName = ProductFormUtils.getAllPackagingUnitIOptions([changeData.packagingFormValidationInputDataByFieldName], true);
            const changeDataPackagingUnitSortedOptionsAndLabelNameValueSet = new Set<string>();
            changeDataPackagingUnitSortedOptionsAndLabelName.forEach((labelNameAndOptions) => {
                labelNameAndOptions[1].forEach((iOption) => {
                    changeDataPackagingUnitSortedOptionsAndLabelNameValueSet.add(iOption.value);
                });
            });

            this.productDistributorAssociationFormPackagingIndexByFormId.forEach((productDistributorAssociationFormPackagingIndex, formId) => {
                if (productDistributorAssociationFormPackagingIndex === changeData.index) {
                    const productDistributorAssociationForm = this.props.validationInputDataByFieldName.productDistributorAssociations.getRequired(formId);
                    const unitValue = productDistributorAssociationForm.validationInputDataByFieldName.unit.value;
                    const priceUnitValue = productDistributorAssociationForm.validationInputDataByFieldName.priceUnit.value;
                    const depositUnitValue = productDistributorAssociationForm.validationInputDataByFieldName.depositUnit.value;
    
                    if (!changeDataPackagingUnitSortedOptionsAndLabelNameValueSet.has(unitValue)) {
                        this.props.onProductDistributorAssociationFormFieldBlur(formId, 'unit', '', changeData.index);
                    }
    
                    if (!changeDataPackagingUnitSortedOptionsAndLabelNameValueSet.has(priceUnitValue)) {
                        this.props.onProductDistributorAssociationFormFieldBlur(formId, 'priceUnit', '', changeData.index);
                    }
    
                    if (!changeDataPackagingUnitSortedOptionsAndLabelNameValueSet.has(depositUnitValue)) {
                        this.props.onProductDistributorAssociationFormFieldBlur(formId, 'depositUnit', '', changeData.index);
                    }
                }
            });
        }

        if (shownPackagingFormsValidationInputDataByFieldName.length > 0) {
            const allPackagingUnitSortedOptionsAndLabelName = ProductFormUtils.getAllPackagingUnitIOptions(shownPackagingFormsValidationInputDataByFieldName, false);

            const allPackagingUnitSortedOptionsAndLabelNameValueSet = new Set<string>();
            allPackagingUnitSortedOptionsAndLabelName.forEach((labelNameAndOptions) => {
                labelNameAndOptions[1].forEach((iOption) => {
                    allPackagingUnitSortedOptionsAndLabelNameValueSet.add(iOption.value);
                });
            });

            const costUnitValue = this.props.validationInputDataByFieldName.costUnit.value;
            if (!allPackagingUnitSortedOptionsAndLabelNameValueSet.has(costUnitValue)) {
                this.props.onFormFieldBlur('costUnit', allPackagingUnitSortedOptionsAndLabelName[0][1][0].value || '');
            }

            const parUnitValue = this.props.validationInputDataByFieldName.parUnit.value;
            if (!allPackagingUnitSortedOptionsAndLabelNameValueSet.has(parUnitValue)) {
                this.props.onFormFieldBlur('parUnit', allPackagingUnitSortedOptionsAndLabelName[0][1][0].value || '');
            }

            const preferredReportingUnitValue = this.props.validationInputDataByFieldName.preferredReportingUnit.value;
            if (!allPackagingUnitSortedOptionsAndLabelNameValueSet.has(preferredReportingUnitValue)) {
                if (shownPackagingFormsValidationInputDataByFieldName[0].comesAsCase.value) {
                    this.props.onFormFieldBlur('preferredReportingUnit', allPackagingUnitSortedOptionsAndLabelName[0][1][1].value || '');
                } else {
                    this.props.onFormFieldBlur('preferredReportingUnit', allPackagingUnitSortedOptionsAndLabelName[0][1][0].value || '');
                }
            }

            const baseUnitSortedOptionsAndLabelName = ProductFormUtils.getBaseUnitIOptions(shownPackagingFormsValidationInputDataByFieldName);
            let baseUnitValue = this.props.validationInputDataByFieldName.baseUnit.value;

            const baseUnitIndex = baseUnitSortedOptionsAndLabelName[0][1].findIndex((iOption) => {
                return iOption.value === baseUnitValue;
            });

            if (baseUnitIndex === -1) {
                baseUnitValue = baseUnitSortedOptionsAndLabelName[0][1][0].value || '';
                this.props.onFormFieldBlur('baseUnit', baseUnitValue);
            }

            this.handleBaseUnitChange(baseUnitValue, changeData);

            const priceUnitSortedOptionsAndLabelName = ProductFormUtils.getPriceUnitIOptions(shownPackagingFormsValidationInputDataByFieldName[0]);
            const priceUnitValue = this.props.validationInputDataByFieldName.priceUnit.value;
    
            const priceUnitIndex = priceUnitSortedOptionsAndLabelName[0][1].findIndex((iOption) => {
                return iOption.value === priceUnitValue;
            });
    
            if (priceUnitIndex === -1) {
                this.props.onFormFieldBlur('priceUnit', priceUnitSortedOptionsAndLabelName[0][1][0].value || '');
            } else {
                this.props.onFormFieldBlur('priceUnit', priceUnitValue); // To cause rerender
            }
        } else {
            this.props.onFormFieldBlur('priceUnit', '');
            this.props.onFormFieldBlur('costUnit', '');
            this.props.onFormFieldBlur('parUnit', '');
            this.props.onFormFieldBlur('preferredReportingUnit', '');
            this.props.onFormFieldBlur('baseUnit', '');
            this.props.setUnitsWithConversionForms([]);
        }

        const packagingIdValuesWithPackagingWeightForms = new Set(Object.keys(this.props.validationInputDataByFieldName.packagingWeights));
        const packagingWeightFormPackagingIdIOptions = ProductFormUtils.getPackagingWeightFormPackagingIdIOptions(shownPackagingFormsValidationInputDataByFieldName, packagingIdValuesWithPackagingWeightForms);

        const availablePackagingIdValues = new Set();
        packagingWeightFormPackagingIdIOptions.forEach((iOption) => {
            availablePackagingIdValues.add(iOption.value);
        });

        Object.keys(this.props.validationInputDataByFieldName.packagingWeights).map((packagingIdValue) => {
            if (!availablePackagingIdValues.has(packagingIdValue)) {
                this.props.onSetPackagingIdHasPackagingWeightForm(packagingIdValue, false);
            }
        });
    }

    private readonly handleBaseUnitChange = (baseUnitValue : string, changeData : {index: number, packagingFormValidationInputDataByFieldName : PackagingFormValidationInputDataByFieldName} | null) => {
        const shownPackagingFormsValidationInputDataByFieldName = this.getShownPackagingFormsValidationInputDataByFieldName();
        if (changeData) {
            shownPackagingFormsValidationInputDataByFieldName.push(changeData.packagingFormValidationInputDataByFieldName);
        }

        if (shownPackagingFormsValidationInputDataByFieldName.length > 0) {
            const oldConversions = this.props.validationInputDataByFieldName.conversions;
            const oldSelectedBaseUnitValue = this.props.validationInputDataByFieldName.baseUnit.value;

            const conversionUnits = ProductFormUtils.getUnitsWithConversionForms(shownPackagingFormsValidationInputDataByFieldName, baseUnitValue);
            this.props.setUnitsWithConversionForms(conversionUnits);

            if (oldSelectedBaseUnitValue === baseUnitValue) {
                conversionUnits.forEach((unit) => {
                    if (oldConversions[unit]) {
                        this.props.onConversionFieldChange(unit, oldConversions[unit].value);
                    }
                });
            } else if ((MassUnit.isMassUnitValue(baseUnitValue) && MassUnit.isMassUnitValue(oldSelectedBaseUnitValue)) || (VolumeUnit.isVolumeUnitValue(baseUnitValue) && VolumeUnit.isVolumeUnitValue(oldSelectedBaseUnitValue))) {
                const convertedUnitQuantity = UnitUtils.convertUnitQuantity(new QuantityInUnit(1, oldSelectedBaseUnitValue as MassUnit | VolumeUnit), baseUnitValue as MassUnit | VolumeUnit).getQuantity();

                conversionUnits.forEach((unit) => {
                    if (oldConversions[unit]) {
                        if (oldConversions[unit].isValid && oldConversions[unit].value) {
                            this.props.onConversionFieldChange(unit, (parseFloat(oldConversions[unit].value) * convertedUnitQuantity).toString());
                        } else {
                            this.props.onConversionFieldChange(unit, oldConversions[unit].value);
                        }
                    }
                });
            } else if (oldConversions[baseUnitValue]) {
                const convertedUnitQuantity = (oldConversions[baseUnitValue].isValid && oldConversions[baseUnitValue].value) ? (1.0 / parseFloat(oldConversions[baseUnitValue].value)) : null;

                conversionUnits.forEach((unit) => {
                    if (oldConversions[unit]) {
                        if (oldConversions[unit].isValid && oldConversions[unit].value && (convertedUnitQuantity !== null)) {
                            this.props.onConversionFieldChange(unit, (parseFloat(oldConversions[unit].value) * convertedUnitQuantity).toString());
                        } else {
                            this.props.onConversionFieldChange(unit, oldConversions[unit].value);
                        }
                    } else if ((unit === oldSelectedBaseUnitValue) && (convertedUnitQuantity !== null)) {
                        this.props.onConversionFieldChange(unit, convertedUnitQuantity.toString());
                    }
                });
            }

            // TODO Cheezy more conversion cases?
            // Like handling same unit of measure
        } else {
            this.props.setUnitsWithConversionForms([]);
        }
    }

    private readonly onPriceAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onFormFieldChange('priceAmount', event.currentTarget.value);
    }

    private readonly onPriceAmountBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.props.onFormFieldBlur('priceAmount', event.currentTarget.value);
        this.props.onSetFocusedField(null);
    }

    private readonly onPreferredReportingUnitSelect = (preferredReportingUnitValue : string) => {
        this.props.onFormFieldChange('preferredReportingUnit', preferredReportingUnitValue);
    }

    private readonly onBaseUnitSelect = (baseUnitValue : string) => {
        this.props.onFormFieldChange('baseUnit', baseUnitValue);
        this.handleBaseUnitChange(baseUnitValue, null);
    }

    private readonly onPriceUnitSelect = (priceUnitValue : string) => {
        this.props.onFormFieldChange('priceUnit', priceUnitValue);
    }

    private readonly onUnitDepositChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onFormFieldChange('unitDeposit', event.currentTarget.value);
    }

    private readonly onUnitDepositBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.props.onFormFieldBlur('unitDeposit', event.currentTarget.value);
        this.props.onSetFocusedField(null);
    }

    private readonly onDistributorSelect = (distributorIdValue : string) => {
        this.props.onFormFieldChange('distributorId', distributorIdValue);
    }

    private readonly onCreateCustomDistributorClick = (distributorName : string) => {
        this.props.onCreateCustomDistributorClick(distributorName);
    }

    private readonly onUseCustomWeightsClick = () => {
        const validationInputDataByFieldName = this.props.validationInputDataByFieldName;
        const newUseCustomWeights = validationInputDataByFieldName.useCustomWeights.value ? '' : 'true';
        return this.props.onFormFieldChange('useCustomWeights', newUseCustomWeights);
    }

    private readonly onAddPackagingFormClick = () => {
        this.packagingFormData.push({
            packagingForm: null,
            initialPackaging: null,
            ref: this.createPackagingFormRef(this.packagingFormData.length),
            isShown: true, 
        });
        this.onPackagingFormFieldChange(null);
        this.onScrollToNewPackaging();
    }

    private readonly onScrollToNewPackaging = () => {
        const packagingUnitDropdown = $(document).find('.product-form-section-reporting');
        const container = $(document).find('.product-form-container');
        if (packagingUnitDropdown && container) {
            if (window.GLOBAL_FEATURE_ACCESS) {
                container.animate({
                    scrollTop: packagingUnitDropdown.offset().top - container.offset().top + container.scrollTop() - 100
                }, 500);
            } else {
                container.animate({
                    scrollTop: packagingUnitDropdown.offset().top - container.offset().top + container.scrollTop() - 200
                }, 500);
            }
        }
    }

    private readonly onRemovePackagingFormClick = (index : number, productDistributorAssociationFormIdsToRemove: StringValueSet<ProductDistributorAssociationFormId>) => {
        return () => {
            this.packagingFormData[index].isShown = false;
            this.onPackagingFormFieldChange(null);
            this.props.onRemoveProductDistributorAssociationForms(productDistributorAssociationFormIdsToRemove);
        };
    }

    private readonly onAddProductDistributorAssociationForm = (packagingIndex : number) => {
        return () => {
            this.props.onAddProductDistributorAssociationForm(packagingIndex);
        };
    }

    private readonly onAddPackagingWeightFormClick = () => {
        const packagingIdValuesWithPackagingWeightForms = new Set(Object.keys(this.props.validationInputDataByFieldName.packagingWeights));
        const packagingWeightFormPackagingIdIOptions = ProductFormUtils.getPackagingWeightFormPackagingIdIOptions(this.getShownPackagingFormsValidationInputDataByFieldName(), packagingIdValuesWithPackagingWeightForms);

        const iOptionIndex = packagingWeightFormPackagingIdIOptions.findIndex((iOption) => {
            return iOption.disabled === false;
        });

        if (iOptionIndex !== -1) {
            const packagingIdValue = packagingWeightFormPackagingIdIOptions[iOptionIndex].value;
            this.props.onSetPackagingIdHasPackagingWeightForm(packagingIdValue, true);
        }
    }

    private readonly onPackagingWeightFormPackagingIdValueSelect = (oldPackagingIdValue : string) => {
        return (newPackagingIdValue : string) => {
            this.props.onSetPackagingWeightFormPackagingIdValue(oldPackagingIdValue, newPackagingIdValue);
        };
    }

    private readonly onEmptyWeightQuantityChange = (packagingIdValue : string) => {
        return (event : React.ChangeEvent<HTMLInputElement>) => {
            this.props.onPackagingWeightFormFieldChange(packagingIdValue, 'emptyWeightQuantity', event.currentTarget.value);
            this.props.onPackagingWeightFormFieldChange(packagingIdValue, 'emptyWeightUnit', this.displayedEmptyWeightUnitValue);
        };
    }

    private readonly onEmptyWeightQuantityBlur = (packagingIdValue : string) => {
        return (event : React.FocusEvent<HTMLInputElement>) => {
            this.props.onPackagingWeightFormFieldBlur(packagingIdValue, 'emptyWeightQuantity', event.currentTarget.value);
            this.props.onPackagingWeightFormFieldChange(packagingIdValue, 'emptyWeightUnit', this.displayedEmptyWeightUnitValue);
        };
    }

    private readonly onEmptyWeightUnitSelect = (packagingIdValue : string) => {
        return (unitValue : string) => {
            this.props.onPackagingWeightFormFieldBlur(packagingIdValue, 'emptyWeightUnit', unitValue);
            this.props.onPackagingWeightFormFieldBlur(packagingIdValue, 'emptyWeightQuantity', this.displayedEmptyWeightValue);
        };
    }

    private readonly onFullWeightQuantityChange = (packagingIdValue : string) => {
        return (event : React.ChangeEvent<HTMLInputElement>) => {
            this.props.onPackagingWeightFormFieldChange(packagingIdValue, 'fullWeightQuantity', event.currentTarget.value);
            this.props.onPackagingWeightFormFieldChange(packagingIdValue, 'fullWeightUnit', this.displayedFullWeightUnitValue);
        };
    }

    private readonly onFullWeightQuantityBlur = (packagingIdValue : string) => {
        return (event : React.FocusEvent<HTMLInputElement>) => {
            this.props.onPackagingWeightFormFieldBlur(packagingIdValue, 'fullWeightQuantity', event.currentTarget.value);
            this.props.onPackagingWeightFormFieldChange(packagingIdValue, 'fullWeightUnit', this.displayedFullWeightUnitValue);
        };
    }

    private readonly onFullWeightUnitSelect = (packagingIdValue : string) => {
        return (unitValue : string) => {
            this.props.onPackagingWeightFormFieldBlur(packagingIdValue, 'fullWeightUnit', unitValue);
            this.props.onPackagingWeightFormFieldBlur(packagingIdValue, 'fullWeightQuantity', this.displayedFullWeightValue);
        };
    }

    private readonly onRemovePackagingWeightFormClick = (packagingIdValue : string) => {
        return () => {
            this.props.onSetPackagingIdHasPackagingWeightForm(packagingIdValue, false);
        };
    }

    private readonly onSkuChangeOrBlur = (event : React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
        return this.props.onFormFieldChange('sku', event.currentTarget.value);
    }

    private readonly onGLCodeSelect = (glCode : string) => {
        return this.props.onFormFieldChange('glCode', glCode);
    }

    private readonly onNoteChange = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
        return this.props.onFormFieldChange('note', event.currentTarget.value);
    }

    private readonly onCostAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onFormFieldChange('costAmount', event.currentTarget.value);
    }

    private readonly onCostAmountBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.props.onFormFieldBlur('costAmount', event.currentTarget.value);
    }

    private readonly onCostUnitSelect = (costUnitValue : string) => {
        this.props.onFormFieldChange('costUnit', costUnitValue);
    }

    private readonly onParCountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onFormFieldChange('parCount', event.currentTarget.value);
    }

    private readonly onParCountBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.props.onFormFieldBlur('parCount', event.currentTarget.value);
    }

    private readonly onParUnitSelect = (parUnitValue : string) => {
        this.props.onFormFieldChange('parUnit', parUnitValue);
    }

    private readonly onProductDistributorAssociationRowFormFieldChange = (formId : ProductDistributorAssociationFormId, fieldName : ProductDistributorAssociationFormFieldName, value : string) => {
        this.props.onProductDistributorAssociationFormFieldChange(formId, fieldName, value, undefined);
    }

    private readonly onProductDistributorAssociationRowFormFieldBlur = (formId : ProductDistributorAssociationFormId, fieldName : ProductDistributorAssociationFormFieldName, value : string) => {
        this.props.onProductDistributorAssociationFormFieldBlur(formId, fieldName, value, undefined);
    }

    private readonly onProductFormSaveClick = () => {
        this.onSaveClick(this.props.productId, true);
    }

    private readonly onProductModificationConfirmationDialogModifyExistingClick = () => {
        this.onSaveClick(this.props.productId, false);
    }

    private readonly onProductModificationConfirmationDialogCreateNewClick = () => {
        this.onSaveClick(null, false);
    }

    private readonly onSaveClick = (productId : ProductId | null, shouldCheckIfPackagingHasBeenModified : boolean) => {
        const packagings : Array<Packaging | null> = [];
        this.packagingFormData.forEach((data) => {
            if (data.isShown && data.packagingForm) {
                packagings.push(data.packagingForm.getPackaging(true));
            }
        });

        this.props.onSaveProductForm(productId, packagings, shouldCheckIfPackagingHasBeenModified);
    }

    private readonly onProductModificationConfirmationDialogCancelClick = () => {
        this.props.onProductModificationConfirmationDialogCancelClick();
    }

    private readonly onCloseDeleteProductConfirmationDialog = () => {
        this.props.setDeleteProductConfirmationDialogIsShown(false);
    }

    private readonly onShowDeleteProductConfirmationDialog = () => {
        this.props.setDeleteProductConfirmationDialogIsShown(true);
    }

    private readonly onSetProductAsActive = () => {
        if (this.props.productId) {
            this.props.onSetProductActiveState(this.props.productId, true);
        }
    }

    private readonly onSetProductAsInactive = () => {
        if (this.props.productId) {
            this.props.onSetProductActiveState(this.props.productId, false);
        }
    }

    private readonly handleConfirmDeleteProduct = () => {
        if (this.props.productId) {
            this.props.onConfirmDeleteProduct(this.props.productId);
        }
    }

    private readonly getShownPackagingFormsValidationInputDataByFieldName = () => {
        const shownPackagingFormsValidationInputDataByFieldName : Array<PackagingFormValidationInputDataByFieldName> = [];
        this.packagingFormData.forEach((data) => {
            if (data.isShown) {
                let packagingFormValidationInputDataByFieldName;
                if (data.packagingForm) {
                    packagingFormValidationInputDataByFieldName = data.packagingForm.getValidationInputDataByFieldName();
                } else if (data.initialPackaging) {
                    packagingFormValidationInputDataByFieldName = PackagingFormUtils.getValidationInputDataByFieldNameFromInitialPackaging(data.initialPackaging);
                }

                if (packagingFormValidationInputDataByFieldName) {
                    shownPackagingFormsValidationInputDataByFieldName.push(packagingFormValidationInputDataByFieldName);
                }
            }
        });

        return shownPackagingFormsValidationInputDataByFieldName;
    }


    private readonly onCategorySearchValueChanged = (newValue : string | null) => {
        this.setState({
            ...this.state,
            categorySearchBarValue : newValue,
        });
    }

    private readonly doNothing = () => {
        // Do Nothing
    }
}
