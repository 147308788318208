import { ThunkAction } from 'redux-thunk';

import UnauthenticatedUserSessionModel from 'gen-thrift/unauthenticated_user_session_Model_types';

import { IUserAccountInfoReader } from 'api/UserAccount/interfaces/IUserAccountInfoReader';
import { IAccountRetailerData } from 'api/UserAccount/model/AccountInfo';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { IUserAccountRetailersIdsByGroup } from 'api/UserAccount/model/UserAccountRetailersByGroup';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { DjangoApiManager } from 'shared/api/DjangoApiManager';

import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';
import { ContextTab } from './ContextTab';
import {
    IUserAccountGroupInfo,
    IUserAccountRetailer,
    IUserAccountRetailersByGroup,
 } from './reducers';
 import { ISignUpOptionsState } from './types';

import { IAccountSessionCreator } from 'shared/lib/account/interfaces/IAccountSessionCreator';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { IAccountSessionTerminator } from 'shared/lib/account/interfaces/IAccountSessionTerminator';
import { ICookieDeleter } from 'shared/lib/cookie/interfaces/ICookieDeleter';
import { ICookieReader } from 'shared/lib/cookie/interfaces/ICookieReader';
import { IQueryStringReader } from 'shared/lib/url/interfaces/IQueryStringReader';

import { ISignUpData } from 'shared/components/SignUpOrSignIn/ISignUpData';

import { UnknownCookieException } from 'shared/lib/cookie/exceptions/UnknownCookieException';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { QueryStringNameNotFoundException } from 'shared/lib/url/exceptions/QueryStringNameNotFoundException';

import { PageContext, UserSessionUtils, validatePath } from './userSessionUtils';

import { Auth } from 'aws-amplify';
import { Observer } from 'shared/utils/observer';

export const ActionTypes = {
    FETCH_USER_INFO: 'USER_SESSION/FETCH_USER_INFO',
    SET_USER_INFO: 'USER_SESSION/SET_USER_INFO',
    SET_ACTIVE_TAB: 'USER_SESSION/SET_ACTIVE_TAB',
    SET_AVAILABLE_TABS: 'USER_SESSION/SET_AVAILABLE_TABS',
    SET_USER_POPDOWN_OPEN: 'USER_SESSION/SET_USER_POPDOWN_OPEN',
    SET_USER_RETAILERS: 'USER_SESSION/SET_USER_RETAILERS',
    SET_PAGE_CONTEXT: 'USER_SESSION/SET_PAGE_CONTEXT',
};

export namespace ActionInterfaces {
    export interface ISetUserRetailers extends IAction {
        payload : {
            userRetailers : IUserAccountRetailersByGroup
        };
    }

    export interface ISetUserPopdownOpen extends IAction {
        payload : {
            open : boolean
        };
    }

    export interface ISetAvailableTabs extends IAction {
        payload : {
            availabelTabs : Array<ContextTab>
        };
    }

    export interface ISetActiveTab extends IAction {
        payload : {
            activeTab : ContextTab
        };
    }

    export interface ISetUserInfo extends IAction {
        payload : {
            firstName : string,
            lastName : string
        };
    }

    export interface ISetPageContext extends IAction {
        payload : {
            pageContext : PageContext,
        };
    }

    export interface IUserSessionActionCreatorsMapObject extends IActionCreatorsMapObject {
        onSuccessfulSignIn : () => ThunkAction<Promise<void>, ISignUpOptionsState, {services : ActionInterfaces.IServices}>;
        onSuccessfulSignUp : (
            userAccountIdentifier : UserAccountId,
            signUpData : ISignUpData,
            retailerId : string | null,
            isActionByAdmin : boolean,
        ) => ThunkAction<Promise<UserAccountId>, ISignUpOptionsState, {services : ActionInterfaces.IServices}>;
        logoutUser : () => ThunkAction<void, ISignUpOptionsState, { services : ActionInterfaces.IServices }>;
        fetchUserInfo : () => ThunkAction<void, ISignUpOptionsState, {services : ActionInterfaces.IServices}>;
        setUserPopdownOpen : (open : boolean) => ActionInterfaces.ISetUserPopdownOpen;
        setUserRetailers : (userRetailers : IUserAccountRetailersByGroup) => ActionInterfaces.ISetUserRetailers;
        setPageContext : (pageContext : PageContext) => ActionInterfaces.ISetPageContext;
        setActiveTab : (activeTab : ContextTab) => ActionInterfaces.ISetActiveTab;
        setAvailableTabs : (availableTabs : Array<ContextTab>) => ActionInterfaces.ISetAvailableTabs;
        determinePageContext : () => ThunkAction<Promise<string>, ISignUpOptionsState, {services : ActionInterfaces.IServices}>;
    }

    export interface IServices {
        userSessionCreator : IAccountSessionCreator<UserAccountId>;
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        userSessionTerminator : IAccountSessionTerminator;
        userAccountInfoReader : IUserAccountInfoReader;
        queryStringReader : IQueryStringReader;
        cookieDeleter : ICookieDeleter;
        cookieReader : ICookieReader;
        userPasswordManager : any; // TODO Cheezy Take care of anys
        userSessionManager : any;
        userAccountManager : any;
        unauthenticatedUserSessionManager : any;
        djangoApiManager : DjangoApiManager;
    }
}

const setUserRetailers = (
    userRetailers : IUserAccountRetailersByGroup
) : ActionInterfaces.ISetUserRetailers => ({
    payload: {
        userRetailers,
    },
    type: ActionTypes.SET_USER_RETAILERS,
});

const setUserPopdownOpen = (
    open : boolean
) : ActionInterfaces.ISetUserPopdownOpen => ({
    payload: {
        open,
    },
    type: ActionTypes.SET_USER_POPDOWN_OPEN,
});

const setAvailableTabs = (
    availabelTabs : Array<ContextTab>
) : ActionInterfaces.ISetAvailableTabs => ({
    payload: {
        availabelTabs,
    },
    type: ActionTypes.SET_AVAILABLE_TABS,
});

const setActiveTab = (
    activeTab : ContextTab
) : ActionInterfaces.ISetActiveTab => ({
    payload: {
        activeTab,
    },
    type: ActionTypes.SET_ACTIVE_TAB,
});

const setUserInfo = (
    firstName : string,
    lastName : string
) : ActionInterfaces.ISetUserInfo => ({
    payload: {
        firstName,
        lastName,
    },
    type: ActionTypes.SET_USER_INFO,
});

const setPageContext = (
    pageContext : PageContext
) : ActionInterfaces.ISetPageContext => ({
    payload: {
        pageContext
    },
    type: ActionTypes.SET_PAGE_CONTEXT,
});

const fetchUserInfo = () : ThunkAction<void, ISignUpOptionsState, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : void => {
        extraArguments.services.userSessionReader.getAccountId()
        .then((userAccountId : UserAccountId) => {
            return Promise.all([
                extraArguments.services.userAccountInfoReader.getAccountRetailers(userAccountId),
                extraArguments.services.userAccountInfoReader.getAccountRetailersByGroup(userAccountId),
                extraArguments.services.userAccountInfoReader.getAccountInfo(userAccountId),
            ]).then((result : [ IAccountRetailerData, IUserAccountRetailersIdsByGroup, { firstName : string, lastName : string } ]) => {
                const accountRetailerData = result[0];
                const userAccountRetailersByGroup = result[1];
                const groups : Array<IUserAccountGroupInfo> = [];
                const ungrouped : Array<IUserAccountRetailer> = [];
                const sandboxes : Array<IUserAccountRetailer> = [];

                userAccountRetailersByGroup.groups.forEach((group) => {
                    const groupLength = groups.push({
                        id: group.id,
                        name: group.name,
                        orderedRetailerIds: []
                    });
                    const newGroup = groups[groupLength - 1];
                    group.orderedRetailerIds.forEach((retailerId, index) => {
                        const retailer = {
                            id: retailerId,
                            name: accountRetailerData.retailerNameByRetailerId[retailerId],
                            accountType: accountRetailerData.retailerAccountTypeByRetailerId[retailerId],
                            isSandbox: accountRetailerData.isSandboxByRetailerId[retailerId],
                        };
                        if (accountRetailerData.isSandboxByRetailerId[retailerId]) {
                            sandboxes.push(retailer);
                        } else {
                            newGroup.orderedRetailerIds.push(retailer);
                        }
                    });
                });

                userAccountRetailersByGroup.ungrouped.forEach((retailerId, index) => {
                    const retailer = {
                        id: retailerId,
                        name: accountRetailerData.retailerNameByRetailerId[retailerId],
                        accountType: accountRetailerData.retailerAccountTypeByRetailerId[retailerId],
                        isSandbox: accountRetailerData.isSandboxByRetailerId[retailerId],
                    };
                    if (accountRetailerData.isSandboxByRetailerId[retailerId]) {
                        sandboxes.push(retailer);
                    } else {
                        ungrouped.push(retailer);
                    }
                });

                dispatch(setUserRetailers({
                    groups,
                    ungrouped,
                    sandboxes
                }));
                dispatch(setUserInfo(
                    result[2].firstName,
                    result[2].lastName
                ));
            });
        });
    };
};

const onSuccessfulSignIn = () : ThunkAction<Promise<void>, ISignUpOptionsState, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<void> => {
        const authenticatedUserSessionId : UserSessionId = extraArguments.services.userSessionManager.getSessionId();
        if (authenticatedUserSessionId !== null) {
            extraArguments.services.userSessionManager.getAccountId()
            .then((userAccountIdentifier : UserAccountId) => {
                Observer.identifyAuthenticated(userAccountIdentifier, null, null, null);
                Observer.observeAction('Logged into account', {});
            });
        }

        return new Promise((resolve) => {
            const defaultNextUrl = '/';
            let nextUrl : string;

            try {
                const nextUrlValues : Array<string> = extraArguments.services.queryStringReader.getValuesForName('next');

                if (1 === nextUrlValues.length) {
                    nextUrl = decodeURIComponent(nextUrlValues[0]);
                } else {
                    nextUrl = defaultNextUrl;
                }
            } catch (e) {
                if (e instanceof QueryStringNameNotFoundException) {
                    nextUrl = defaultNextUrl;
                } else {
                    throw new RuntimeException(`unexpected ${e}`);
                }
            }

            window.location.href = nextUrl;
            resolve();
        });
    };
};

const onSuccessfulSignUp = (
    userAccountIdentifier : UserAccountId,
    signUpData : ISignUpData,
    retailerId : string | null,
    isActionByAdmin : boolean,
) : ThunkAction<Promise<UserAccountId>, ISignUpOptionsState, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<UserAccountId> => {
        let hubspotCookie : string | null = null;
        try {
            hubspotCookie = extraArguments.services.cookieReader.getCookie('hubspotutk');
        } catch (error) {
            if (!(error instanceof UnknownCookieException)) {
                throw error;
            }
        }

        return Promise.all([
            extraArguments.services.unauthenticatedUserSessionManager.getOrCreateUnauthenticatedUserSessionId(),
            Observer.trackConversion.userCreated({
                email: signUpData.emailAddress,
                phone: signUpData.phone,
                bar_name: signUpData.barName,
                first_name: signUpData.firstName,
                last_name: signUpData.lastName,
                zip_code: signUpData.optionalZipCode,
                subscribe_to_blog: signUpData.subscribeToBlog,
                hubspot_cookie : hubspotCookie,
                is_admin_created: isActionByAdmin,
                should_receive_marketing: true,
                source_url: window.location.href,
                creator_user_id: isActionByAdmin ? window.GLOBAL_USER_ID : null,
                retailer_id: retailerId
            })
        ])
        .then((result) => {
            const unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId = result[0];
            const userAccountId = userAccountIdentifier;
            const userFullName = signUpData.firstName + ' ' + signUpData.lastName;
            Observer.identifyAuthenticated(userAccountIdentifier, userFullName, signUpData.emailAddress, null);
            Observer.observeAction('New User Account Created', {
                referrer: document.referrer,
                page: window.location.pathname,
                unauthenticatedUserSessionId: unauthenticatedUserSessionId.value,
            });

            Observer.identifyUnauthenticated(unauthenticatedUserSessionId);
            Observer.observeAction('Clicked button to submit sign up form', {});
            Observer.observeAction('Successfully Submitted Sign Up Form', {
                userAccountId: userAccountId.getValue(),
            });

            return userAccountId;
        });
    };
};

const logoutUser = () : ThunkAction<void, ISignUpOptionsState, { services : ActionInterfaces.IServices }> => {
  return (dispatch, getState, extraArguments) : void => {
    Auth.signOut()
      .then(() => {
        extraArguments.services.cookieDeleter.deleteCookie('userSessionIdentifier');
        extraArguments.services.cookieDeleter.deleteCookie('refreshToken');
        extraArguments.services.cookieDeleter.deleteCookie('nextRefresh');
        localStorage.clear();

        // duplicates src/main/web/user_auth/src/ui/lib/Utils.js getUrlForPublicSite
        let host = '';
        if (window.location.hostname.slice(-12) === '.bevspot.com') {
            host = 'https://app.bevspot.com';
        } else if (window.location.hostname.slice(-12) === '.bevspot.net') {
            host = 'https://app-staging.bevspot.net';
        }
        window.location.href = host + '/auth';
      });
  };
};

const determinePageContext = () : ThunkAction<Promise<string>, ISignUpOptionsState, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<string> => {
        return new Promise((resolve, reject) => {
            let path = window.location.pathname;
            const retailerIdIndex = Math.max(path.indexOf('/r/'), path.indexOf('/g/'));
            if (retailerIdIndex > -1) {
                path = path.substr(0, retailerIdIndex);
            }
            // if no retailer/group id, remove the trailing slash
            if (path.lastIndexOf('/') === path.length - 1) {
                path = path.substr(0, path.length - 1) ;
            }
            if (!validatePath(path)) {
                // remove any inventory ids from the path
                if (/\w+(-\w+){4}/.test(path)) {
                    path = path.substr(0, path.lastIndexOf('/'));
                // remove any sales report ids from the path
                } else if (/\w{24}/.test(path)) {
                    path = path.substr(0, path.lastIndexOf('/'));
                }
            }
            const pageContext: PageContext = path as PageContext; // type cast alert! ensure all use of this pageContext is validated!
            dispatch(setPageContext(pageContext));
            /** @NOTE user menu page context now defaulted to home, all help now being provided through elev.io */
            // if (typeof UserSessionUtils.contextTabsByPageContext[pageContext] === 'undefined') {
            //     dispatch(setAvailableTabs(UserSessionUtils.contextTabsByPageContext.DEFAULT));
            // } else {
            //     const availableTabs = UserSessionUtils.contextTabsByPageContext[pageContext];
            //     dispatch(setAvailableTabs(availableTabs));
            //     if (availableTabs.includes(ContextTab.VIDEOS)) {
            //         dispatch(setActiveTab(ContextTab.VIDEOS));
            //     }
            // }
            dispatch(setAvailableTabs(UserSessionUtils.contextTabsByPageContext.DEFAULT));

            resolve(pageContext);
        });
    };
};

export const UserSessionActions : ActionInterfaces.IUserSessionActionCreatorsMapObject = {
    onSuccessfulSignIn,
    onSuccessfulSignUp,
    logoutUser,
    fetchUserInfo,
    setPageContext,
    setActiveTab,
    setAvailableTabs,
    setUserPopdownOpen,
    setUserRetailers,
    determinePageContext,
};
