import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { Action } from '@reduxjs/toolkit';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { DefaultPour } from 'api/Location/interfaces/ILocationSettingsService';
import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { ISearchBarState } from 'shared/models/ISearchBarState';

import { SalesEntry } from 'api/Reports/model/SalesEntry';
import { PosItem } from 'api/SalesData/model/PosItem';
import { SalesInputRowId } from 'shared/components/SalesInputTable/SalesInputRow';
import { CreateOrEditSalesItemActionInterfaces, CreateOrEditSalesItemActionTypes } from '../actions/actions';

const DEFAULT_MENU_GROUPS = ['']; // TODO: make this real
export const DEFAULT_MENU_GROUP_OPTIONS = DEFAULT_MENU_GROUPS.map((optionValue) => {
    return {
        value: optionValue,
        icon: null,
        label: optionValue,
    };
});

export interface IPmixItemInformation {
    posItem : PosItem;
    salesEntry : SalesEntry;
}

export type IngredientType = 'salesItem' | 'product';

export type ComponentName = 'salesItemInformation' | 'saveIsDisabled' | 'saveInProgress' | 'ingredientRow' |
'flaggedSalesItemAlert' | 'linkedSalesItemInfo' | 'excludedSalesItemAlert' | 'unsavedChangesModal' | 'applyChangesModal' | 'miscellaneousCost' | 'salesPriceAndTaxHintPopover' | 'ingredientSearchBarDropdown' |
'searchBarSlimCreate' | 'createNewSalesItemFields' | 'ingredientInfoPopover' | 'onSaveSnackBar' | 'linkedItemsPopover' | 'subRecipeInfo' | 'shortcutPopover' |
'doNotAskForSaveOptionsAgain' | 'omitItemConfirmationDialog' | 'recipePdfRenderer' | 'recipePdfIsLoading';

export type SalesItemFormFieldName = 'salesItemName' | 'menuGroup' | 'posId' | 'note';

// TODO: `ingredientSearch` might not be used now that we have the <IngredientSearchBar/>
export const slimCreateSalesItemFormFieldNames = new Set(['newSalesItemName', 'newSalesItemYieldAmount', 'newSalesItemYieldUnit', 'newSalesItemServingSizeAmount', 'newSalesItemServingSizeUnit']);
export type SlimCreateSalesItemFormFieldName = 'newSalesItemName' | 'newSalesItemYieldAmount' | 'newSalesItemYieldUnit' | 'newSalesItemServingSizeAmount' | 'newSalesItemServingSizeUnit';
export type IngredientFormFieldName = 'ingredientQuantityAmount' | 'ingredientQuantityUnit' | 'yieldAmount' | 'yieldUnit' | 'servingSizeAmount' | 'servingSizeUnit' | SlimCreateSalesItemFormFieldName;

export type SalesInformationFormFieldName = 'salesPrice' | 'costPercentage' | 'salesProfit' | 'totalCost' | 'priceAndTax' | 'miscCost';

export type SalesItemFormValidationByFieldName = { [fieldName in SalesItemFormFieldName] : IValidationInputData };

export type IngredientFormValidationByFieldName = { [fieldName in IngredientFormFieldName] : IValidationInputData };

export type SalesInformationFormValidationByFieldName = { [fieldName in SalesInformationFormFieldName] : IValidationInputData };

export interface IProductAndSalesItemSearchBar {
    selectedIngredientId : ProductId | SalesItemId | ProductQuickAdd | null;
    searchBar : ISearchBarState;
    highlightedIngredientId : ProductId | SalesItemId | ProductQuickAdd | null;
    labelNamesAndSortedProductIdsToDisplay : Array<[string, Array<ProductId>]>;
    labelNamesAndSortedSalesItemIdsToDisplay : Array<[string, Array<SalesItemId>]>;
    labelNamesAndSortedProductQuickAddItemsToDisplay : Array<[string, Array<ProductQuickAdd>]>;
}

export type ProductIngredientRowFormFieldName = 'quantity' | 'unit';
export type ProductIngredientRowInfoByFormFieldName = Record<ProductIngredientRowFormFieldName, IValidationInputData>;
export interface IProductIngredientRowInfo {
    ingredientId : ProductId;
    formInfo : ProductIngredientRowInfoByFormFieldName;
}

export type ProductQuickAddRowFormFieldName = 'quantity' | 'unit';
export type ProductQuickAddRowInfoByFormFieldName = Record<ProductQuickAddRowFormFieldName, IValidationInputData>;

export type SalesItemIngredientRowFormFieldName = 'quantity';
export type SalesItemIngredientRowInfoByFormFieldName = Record<SalesItemIngredientRowFormFieldName, IValidationInputData>;
export interface ISalesItemIngredientRowInfo {
    ingredientId : SalesItemId;
    formInfo : SalesItemIngredientRowInfoByFormFieldName;
}

export interface ISalesItemForm {
    // TODO: cleanup based on what we do and don't need form validation on?
    salesItemInfoForm : SalesItemFormValidationByFieldName;
    ingredientForm : IngredientFormValidationByFieldName;
    salesInformationForm : SalesInformationFormValidationByFieldName;

    lastEditedMetadata : UserAccountIdAndTimestamp | null;
    selectedNeedsAttentionCategory : string | null;

    menuGroupOptions : Array<IOption>;
    ingredientSearchBar : IProductAndSalesItemSearchBar;
    orderedIngredientItems : Array<ProductId | SalesItemId>;
    productIngredientItemsById : StringValueMap<ProductId, IProductIngredientRowInfo>;
    salesItemIngredientItemsById : StringValueMap<SalesItemId, ISalesItemIngredientRowInfo>;
}

export interface ISalesItemFormData {
    productsById : StringValueMap<ProductId, Product>;
    categoriesById : StringValueMap<CategoryId, Category>;
    activeProductIds : StringValueSet<ProductId>; // NOTE: not sure if we need this yet but saw it in prep event and item card reducers so maybe worth adding?
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    productCostsByProductId : StringValueMap<ProductId, ProductCost>;
    retailerTaxPercentage : number | null;
    defaultPourByProductCategory : DefaultPour | null;
    currentUserAccountId : UserAccountId;
    namesByUserAccountId : StringValueMap<UserAccountId, { firstName : string, lastName : string }>;
    quickAddProducts : Array<ProductQuickAdd>;
}

export enum SalesItemSaveOption {
    ALL_REPORTS = 'ALL_REPORTS',
    SINGLE_REPORT = 'SINGLE_REPORT',
    THIS_AND_FUTURE_REPORTS = 'THIS_AND_FUTURE_REPORTS'
}

export interface ICreateOrEditSalesItemState {
    salesItemId : SalesInputRowId | null;

    salesItemForm : ISalesItemForm;
    salesItemFormData : ISalesItemFormData | null;
    linkedItems : StringValueSet<SalesItemId>;

    isShownByComponentName : { [componentName in ComponentName] : boolean };
    popoverIngredientIdIsShown : StringValueSet<ProductId | SalesItemId>;
    // saving
    selectedSaveOption : SalesItemSaveOption;
    idToGoToAfterSave : SalesInputRowId | null;

    // these are optimizations so we don't have to look through the entire context array multiple times
    previousSalesItemId : SalesInputRowId | null;
    nextSalesItemId : SalesInputRowId | null;

    // pdf rendering
    salesItemImageUploadUrl : string | null;
}

const ingredientSearchBarInitialState : IProductAndSalesItemSearchBar = {
    selectedIngredientId: null,
    searchBar: {
        searchTerm: null,
        isDisabled: false,
        isFocused: false,
    },
    highlightedIngredientId: null,
    labelNamesAndSortedProductIdsToDisplay: [],
    labelNamesAndSortedSalesItemIdsToDisplay: [],
    labelNamesAndSortedProductQuickAddItemsToDisplay: [],
};

const salesItemInfoFormInitialState = {
    salesItemName: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    menuGroup: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    posId: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    note: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
};

// should units have initial value?
export const DEFAULT_SERVING_AND_YIELD_UNIT = 'Serving';
export const DEFAULT_SERVING_AND_YIELD_AMOUNT = '1.00';
export const ingredientFormInitialState = {
    ingredientQuantityAmount: {
        value: '1.00',
        errorMessage: '',
        isValid: true,
    },
    ingredientQuantityUnit: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    yieldAmount: {
        value: DEFAULT_SERVING_AND_YIELD_AMOUNT,
        errorMessage: '',
        isValid: true,
    },
    yieldUnit: {
        value: DEFAULT_SERVING_AND_YIELD_UNIT,
        errorMessage: '',
        isValid: true,
    },
    servingSizeAmount: {
        value: DEFAULT_SERVING_AND_YIELD_AMOUNT,
        errorMessage: '',
        isValid: true,
    },
    servingSizeUnit: {
        value: DEFAULT_SERVING_AND_YIELD_UNIT,
        errorMessage: '',
        isValid: true,
    },
    newSalesItemName: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    newSalesItemYieldAmount: {
        value: DEFAULT_SERVING_AND_YIELD_AMOUNT,
        errorMessage: '',
        isValid: true,
    },
    newSalesItemYieldUnit: {
        value: DEFAULT_SERVING_AND_YIELD_UNIT,
        errorMessage: '',
        isValid: true,
    },
    newSalesItemServingSizeAmount: {
        value: DEFAULT_SERVING_AND_YIELD_AMOUNT,
        errorMessage: '',
        isValid: true,
    },
    newSalesItemServingSizeUnit: {
        value: DEFAULT_SERVING_AND_YIELD_UNIT,
        errorMessage: '',
        isValid: true,
    },
};

const salesInformationFormInitialState = {
    salesPrice: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    costPercentage: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    salesProfit: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    priceAndTax: {
        value: '',
        errorMessage: '',
        isValid: true,
    },
    totalCost: {
        value: '0.00',
        errorMessage: '',
        isValid: true,
    },
    miscCost: {
        value: '0.00',
        errorMessage: '',
        isValid: true,
    },
};

export const initialState : ICreateOrEditSalesItemState = {
    salesItemId: null,
    salesItemForm: {
        salesItemInfoForm: salesItemInfoFormInitialState,
        ingredientForm: ingredientFormInitialState,
        salesInformationForm: salesInformationFormInitialState,

        lastEditedMetadata: null,
        selectedNeedsAttentionCategory: null,
        menuGroupOptions: DEFAULT_MENU_GROUP_OPTIONS,
        ingredientSearchBar: ingredientSearchBarInitialState,
        orderedIngredientItems: [],
        productIngredientItemsById: new StringValueMap(),
        salesItemIngredientItemsById: new StringValueMap(),
    },
    salesItemFormData: null,
    linkedItems: new StringValueSet(),

    isShownByComponentName: {
        salesItemInformation: false,
        saveIsDisabled: true,
        saveInProgress: false,
        ingredientRow: false,
        flaggedSalesItemAlert: false,
        linkedSalesItemInfo: false,
        excludedSalesItemAlert: false,
        unsavedChangesModal: false,
        applyChangesModal: false,
        miscellaneousCost: false,
        salesPriceAndTaxHintPopover: false,
        ingredientSearchBarDropdown: false,
        searchBarSlimCreate: false,
        createNewSalesItemFields: false,
        ingredientInfoPopover: false,
        linkedItemsPopover: false,
        onSaveSnackBar: false,
        subRecipeInfo: false,
        shortcutPopover: false,
        omitItemConfirmationDialog: false,
        doNotAskForSaveOptionsAgain: false, // IMPORTANT: If this default ever changes to true must make sure that the dialog shows up the first time you try to save! Probably requires another boolean on the state (firstSave or something)
        recipePdfRenderer: false,
        recipePdfIsLoading: false,
    },
    popoverIngredientIdIsShown: new StringValueSet(),

    selectedSaveOption: SalesItemSaveOption.ALL_REPORTS,
    idToGoToAfterSave: null,

    previousSalesItemId: null,
    nextSalesItemId: null,

    salesItemImageUploadUrl: null,
};

const reduceSetSalesItemId = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSalesItemId
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemId: action.payload.salesItemId,
    };
};

const reduceSetNeedsAttentionCategory = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetNeedsAttentionCategory
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            selectedNeedsAttentionCategory: action.payload.needsAttentionCategory
        }
    };
};

const reduceSetSalesItemFormValidationByFieldName = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSalesItemFormValidationByFieldName
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            salesItemInfoForm: {
                ...state.salesItemForm.salesItemInfoForm,
                [action.payload.fieldName]: {
                    value: action.payload.value,
                    errorMessage: action.payload.errorMessage,
                    isValid: action.payload.isValid,
                },
            },
        },
    };
};

const reduceSetIngredientFormValidationByFieldName = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetIngredientFormValidationByFieldName
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            ingredientForm: {
                ...state.salesItemForm.ingredientForm,
                [action.payload.fieldName]: {
                    value: action.payload.value,
                    errorMessage: action.payload.errorMessage,
                    isValid: action.payload.isValid,
                },
            }
        },
    };
};

const reduceSetSalesItemFormData = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSalesItemFormData
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemFormData: action.payload.salesItemFormData
    };
};

const reduceSetLinkedItems = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetLinkedItems,
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        linkedItems: action.payload.linkedItems,
    };
};

const reduceSetComponentIsShown = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetComponentIsShown
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        isShownByComponentName: {
            ...state.isShownByComponentName,
            [action.payload.componentName]: action.payload.isShown,
        },
    };
};

const reduceSetSelectedPopoverIngredientIds = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetPopoverIngredientIdIsShown
) : ICreateOrEditSalesItemState => {
    let newIngredientPopoverId : StringValueSet<ProductId | SalesItemId>;
    if (action.payload.isShown) {
        newIngredientPopoverId = new StringValueSet(action.payload.popoverIngredientIdIsShown);
    } else {
        newIngredientPopoverId = new StringValueSet();
    }

    return {
        ...state,
        popoverIngredientIdIsShown: newIngredientPopoverId,
    };
};

const reduceUpdateMenuGroup = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.IUpdateMenuGroup
) : ICreateOrEditSalesItemState => {

    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            salesItemInfoForm: {
                ...state.salesItemForm.salesItemInfoForm,
                menuGroup: {
                    ...state.salesItemForm.salesItemInfoForm.menuGroup,
                    value: action.payload.value,
                },
            }
        }
    };
};

const reduceSetSalesItemForm = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSalesItemForm
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: action.payload.salesItemForm
    };
};

const generateNewOrderedIngredientItems = (
    orderedIngredientItems : Array<ProductId | SalesItemId>,
    ingredientId : ProductId | SalesItemId,
    isRemoveIngredient : boolean
) => {
    const previousRowIndex = orderedIngredientItems.findIndex((existingIngredientId) => {
        return existingIngredientId.equals(ingredientId);
    });

    const newOrderedIngredientItems = orderedIngredientItems.slice();

    if (isRemoveIngredient) {
        if (previousRowIndex !== -1) {
            newOrderedIngredientItems.splice(previousRowIndex, 1);
        }
    } else {
        if (previousRowIndex === -1) { // if not already in array, add to end
            newOrderedIngredientItems.push(ingredientId);
        } else {
            newOrderedIngredientItems[previousRowIndex] = ingredientId;
        }
    }
    return newOrderedIngredientItems;
};

const reduceSetProductIngredientItemsRow = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetProductIngredientItemsRow
) : ICreateOrEditSalesItemState => {
    const orderedIngredientItems = generateNewOrderedIngredientItems(
        state.salesItemForm.orderedIngredientItems,
        action.payload.ingredientId,
        action.payload.formInfo === null,
    );
    const productIngredientItemsById = new StringValueMap(state.salesItemForm.productIngredientItemsById);
    if (action.payload.formInfo === null) {
        productIngredientItemsById.delete(action.payload.ingredientId);
    } else {
        productIngredientItemsById.set(action.payload.ingredientId, {
            ingredientId: action.payload.ingredientId,
            formInfo: action.payload.formInfo
        });
    }

    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            orderedIngredientItems,
            productIngredientItemsById,
        }
    };
};

const reduceSetSalesItemIngredientItemsRow = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSalesItemIngredientItemsRow
) : ICreateOrEditSalesItemState => {
    const orderedIngredientItems = generateNewOrderedIngredientItems(
        state.salesItemForm.orderedIngredientItems,
        action.payload.ingredientId,
        action.payload.formInfo === null,
    );

    const salesItemIngredientItemsById = new StringValueMap(state.salesItemForm.salesItemIngredientItemsById);
    if (action.payload.formInfo === null) {
        salesItemIngredientItemsById.delete(action.payload.ingredientId);
    } else {
        salesItemIngredientItemsById.set(action.payload.ingredientId, {
            ingredientId: action.payload.ingredientId,
            formInfo: action.payload.formInfo
        });
    }

    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            orderedIngredientItems,
            salesItemIngredientItemsById,
        }
    };
};

// WIP - will find more things that need to be reset as this is used in practice.
const reduceResetSalesItemForm = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.IResetSalesItemForm
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        isShownByComponentName: {
            ...state.isShownByComponentName,
            createNewSalesItemFields: false,
            saveIsDisabled: true,
        },
        salesItemId: null,
        linkedItems: new StringValueSet(),
        salesItemForm: {
            ...state.salesItemForm,

            ingredientSearchBar: {
                ...state.salesItemForm.ingredientSearchBar,
                selectedIngredientId: null,
            },

            // reset to initial
            salesItemInfoForm: initialState.salesItemForm.salesItemInfoForm,
            ingredientForm: initialState.salesItemForm.ingredientForm,
            salesInformationForm: initialState.salesItemForm.salesInformationForm,
            lastEditedMetadata: initialState.salesItemForm.lastEditedMetadata,
            selectedNeedsAttentionCategory: initialState.salesItemForm.selectedNeedsAttentionCategory,
            orderedIngredientItems: initialState.salesItemForm.orderedIngredientItems,
            productIngredientItemsById: initialState.salesItemForm.productIngredientItemsById,
            salesItemIngredientItemsById: initialState.salesItemForm.salesItemIngredientItemsById,
        }
    };
};

const reduceSetSortedMenuGroupOptions = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSortedMenuGroupOptions
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            menuGroupOptions: action.payload.sortedOptions
        }
    };
};

const reduceSetSelectedSaveOption = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSelectedSaveOption
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        selectedSaveOption: action.payload.selectedSaveOption
    };
};

const reduceSetIngredientSearchBarHighlightedIngredientId = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarHighlightedIngredientId
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            ingredientSearchBar: {
                ...state.salesItemForm.ingredientSearchBar,
                highlightedIngredientId: action.payload.ingredientId,
            }
        }
    };
};

const reduceSetIngredientSearchBarLabelNamesAndSortedProductIds = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedProductIdsToDisplay
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            ingredientSearchBar: {
                ...state.salesItemForm.ingredientSearchBar,
                labelNamesAndSortedProductIdsToDisplay: action.payload.labelNamesAndSortedProductIdsToDisplay,
            }
        }
    };
};

const reduceSetIngredientSearchBarLabelNamesAndSortedSalesItemIds = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedSalesItemIdsToDisplay
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            ingredientSearchBar: {
                ...state.salesItemForm.ingredientSearchBar,
                labelNamesAndSortedSalesItemIdsToDisplay: action.payload.labelNamesAndSortedSalesItemIdsToDisplay,
            }
        }
    };
};

const reduceSetIngredientSearchBarLabelNamesAndSortedProductQuickAddItems = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedProductQuickAddItemsToDisplay
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            ingredientSearchBar: {
                ...state.salesItemForm.ingredientSearchBar,
                labelNamesAndSortedProductQuickAddItemsToDisplay: action.payload.labelNamesAndSortedProductQuickAddItemsToDisplay,
            }
        }
    };
};

const reduceSetIngredientSearchBarState = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarState
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            ingredientSearchBar: {
                ...state.salesItemForm.ingredientSearchBar,
                searchBar: action.payload.searchBar,
            }
        }
    };
};

const reduceSetSelectedIngredientId = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSelectedIngredientId
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            ingredientSearchBar: {
                ...state.salesItemForm.ingredientSearchBar,
                selectedIngredientId: action.payload.selectedIngredientId,
            }
        }
    };
};

const reduceResetAddNewIngredientFormSection = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.IResetAddNewIngredientFormSection
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        isShownByComponentName: {
            ...state.isShownByComponentName,
            createNewSalesItemFields: false,
        },
        salesItemForm: {
            ...state.salesItemForm,
            ingredientForm: {
                ...state.salesItemForm.ingredientForm,
                ingredientQuantityAmount: ingredientFormInitialState.ingredientQuantityAmount,
                ingredientQuantityUnit: ingredientFormInitialState.ingredientQuantityUnit,
                newSalesItemName: ingredientFormInitialState.newSalesItemName,
                newSalesItemServingSizeAmount: ingredientFormInitialState.newSalesItemServingSizeAmount,
                newSalesItemServingSizeUnit: ingredientFormInitialState.newSalesItemServingSizeUnit,
                newSalesItemYieldAmount: ingredientFormInitialState.newSalesItemYieldAmount,
                newSalesItemYieldUnit: ingredientFormInitialState.newSalesItemYieldUnit,
            }
        }
    };
};

const reduceSetSalesInformationFormValidationByFieldName = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSalesInformationFormValidationByFieldName
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemForm: {
            ...state.salesItemForm,
            salesInformationForm: {
                ...state.salesItemForm.salesInformationForm,
                [action.payload.fieldName]: {
                    value: action.payload.value,
                    errorMessage: action.payload.errorMessage,
                    isValid: action.payload.isValid,
                },
            },
        },
    };
};

const reduceSetDefaultPourByProductCategory = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetDefaultPourByProductCategory
) : ICreateOrEditSalesItemState => {
    if (state.salesItemFormData === null) {
        return state; // called before initial load, just ignore (default pour data will be fetched on initial load)
    }

    return {
        ...state,
        salesItemFormData: {
            ...state.salesItemFormData,
            defaultPourByProductCategory: action.payload.defaultPourByProductCategory
        },
    };
};

const reduceSetNextAndPreviousItemIds = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetNextAndPreviousItemIds
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        nextSalesItemId: action.payload.nextSalesItemId,
        previousSalesItemId: action.payload.previousSalesItemId
    };
};

const reduceSetIdToGoToAfterSave = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetIdToGoToAfterSave
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        idToGoToAfterSave: action.payload.idToGoToAfterSave
    };
};

const reduceSetSalesItemImageUploadUrl = (
    state : ICreateOrEditSalesItemState,
    action : CreateOrEditSalesItemActionInterfaces.ISetSalesItemImageUploadUrl
) : ICreateOrEditSalesItemState => {
    return {
        ...state,
        salesItemImageUploadUrl: action.payload.salesItemImageUploadUrl,
    };
};

export const CreateOrEditSalesItemReducers = (
    state : ICreateOrEditSalesItemState = initialState,
    action : Action
) : ICreateOrEditSalesItemState => {
    switch (action.type) {
        case CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_ID:
            return reduceSetSalesItemId(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSalesItemId
            );
        case CreateOrEditSalesItemActionTypes.SET_NEEDS_ATTENTION_CATEGORY:
            return reduceSetNeedsAttentionCategory(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetNeedsAttentionCategory
            );
        case CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_FORM_VALIDATION_BY_FIELD_NAME:
            return reduceSetSalesItemFormValidationByFieldName(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSalesItemFormValidationByFieldName
            );
        case CreateOrEditSalesItemActionTypes.SET_INGREDIENT_FORM_VALIDATION_BY_FIELD_NAME:
            return reduceSetIngredientFormValidationByFieldName(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetIngredientFormValidationByFieldName
            );
        case CreateOrEditSalesItemActionTypes.SET_SALES_INFORMATION_FORM_VALIDATION_BY_FIELD_NAME:
            return reduceSetSalesInformationFormValidationByFieldName(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSalesInformationFormValidationByFieldName
            );
        case CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_FORM_DATA:
            return reduceSetSalesItemFormData(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSalesItemFormData
            );
        case CreateOrEditSalesItemActionTypes.SET_LINKED_ITEMS:
            return reduceSetLinkedItems(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetLinkedItems
            );
        case CreateOrEditSalesItemActionTypes.SET_COMPONENT_IS_SHOWN:
            return reduceSetComponentIsShown(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetComponentIsShown
            );
        case CreateOrEditSalesItemActionTypes.SET_POPOVER_INGREDIENT_ID_IS_SHOWN:
            return reduceSetSelectedPopoverIngredientIds (
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetPopoverIngredientIdIsShown
            );
        case CreateOrEditSalesItemActionTypes.UPDATE_MENU_GROUP:
            return reduceUpdateMenuGroup(
                state,
                action as CreateOrEditSalesItemActionInterfaces.IUpdateMenuGroup
            );
        case CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_FORM:
            return reduceSetSalesItemForm(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSalesItemForm
            );
        case CreateOrEditSalesItemActionTypes.RESET_SALES_ITEM_FORM:
            return reduceResetSalesItemForm(
                state,
                action as CreateOrEditSalesItemActionInterfaces.IResetSalesItemForm
            );
        case CreateOrEditSalesItemActionTypes.SET_PRODUCT_INGREDIENT_ITEMS_ROW:
            return reduceSetProductIngredientItemsRow(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetProductIngredientItemsRow
            );
        case CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_INGREDIENT_ITEMS_ROW:
            return reduceSetSalesItemIngredientItemsRow(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSalesItemIngredientItemsRow
            );
        case CreateOrEditSalesItemActionTypes.SET_SORTED_MENU_GROUP_OPTIONS:
            return reduceSetSortedMenuGroupOptions(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSortedMenuGroupOptions
            );
        case CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_HIGHLIGHTED_ID:
            return reduceSetIngredientSearchBarHighlightedIngredientId(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarHighlightedIngredientId
            );
        case CreateOrEditSalesItemActionTypes.SET_SELECTED_SAVE_OPTION:
            return reduceSetSelectedSaveOption(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSelectedSaveOption
            );
        case CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_PRODUCT_IDS_TO_DISPLAY:
            return reduceSetIngredientSearchBarLabelNamesAndSortedProductIds(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedProductIdsToDisplay
            );
        case CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_SALES_ITEM_IDS_TO_DISPLAY:
            return reduceSetIngredientSearchBarLabelNamesAndSortedSalesItemIds(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedSalesItemIdsToDisplay
            );
        case CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_PRODUCT_QUICK_ADD_ITEMS_TO_DISPLAY:
            return reduceSetIngredientSearchBarLabelNamesAndSortedProductQuickAddItems(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedProductQuickAddItemsToDisplay
            );
        case CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_STATE:
            return reduceSetIngredientSearchBarState(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarState
            );
        case CreateOrEditSalesItemActionTypes.SET_SELECTED_INDREDIENT_ID:
            return reduceSetSelectedIngredientId(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetSelectedIngredientId
            );
        case CreateOrEditSalesItemActionTypes.RESET_ADD_NEW_INGREDIENT_FORM_SECTION:
            return reduceResetAddNewIngredientFormSection(
                state,
                action as CreateOrEditSalesItemActionInterfaces.IResetAddNewIngredientFormSection
            );
        case CreateOrEditSalesItemActionTypes.SET_DEFAULT_POUR_BY_PRODUCT_CATEGORY:
            return reduceSetDefaultPourByProductCategory(
                state,
                action as CreateOrEditSalesItemActionInterfaces.ISetDefaultPourByProductCategory
            );
        case CreateOrEditSalesItemActionTypes.SET_NEXT_AND_PREVIOUS_IDS:
            return reduceSetNextAndPreviousItemIds(state, action as CreateOrEditSalesItemActionInterfaces.ISetNextAndPreviousItemIds);
        case CreateOrEditSalesItemActionTypes.SET_ID_TO_GO_TO_AFTER_SAVE:
            return reduceSetIdToGoToAfterSave(state, action as CreateOrEditSalesItemActionInterfaces.ISetIdToGoToAfterSave);
        case CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_IMAGE_UPLOAD_URL:
            return reduceSetSalesItemImageUploadUrl(state, action as CreateOrEditSalesItemActionInterfaces.ISetSalesItemImageUploadUrl);
        default:
            return state;
    }
};
