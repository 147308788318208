
import moment from 'moment-timezone';
import React from 'react';

import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { DeliveryLineItem } from 'api/Ordering/model/DeliveryLineItem';
import { DeliveryUtils } from 'api/Ordering/utils/DeliveryUtils';
import { BaseUnit } from 'api/Product/model/BaseUnit';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { IProductHistoryData } from '../reducers/ItemCardReducers';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

export interface IItemCardHistoryPriceProps {
    productId : ProductId;
    product : Product | null;
    productHistoryData : IProductHistoryData;
}

interface IDeliveryAndProductLineItem {
    delivery : Delivery;
    productLineItem : DeliveryLineItem;
}

export class ItemCardHistoryPrice extends React.Component<IItemCardHistoryPriceProps, object> {
    public render() {
        const {
            productId,
            product,
            productHistoryData
        } = this.props;

        if (!product) {
            return <div className="item-price-history"/>;
        }

        const deliveriesWithProduct = new Array<IDeliveryAndProductLineItem>();
        productHistoryData.deliveriesByDeliveryId.forEach((delivery : Delivery, deliveryId : DeliveryId) => {
            delivery.getDeliveryLineItems().forEach((productLineItem) => {
                if (productLineItem.getProductId().equals(productId)) {
                    deliveriesWithProduct.push({
                        delivery,
                        productLineItem
                    });
                }
            });
        });

        deliveriesWithProduct.sort((deliveryAndProductLineItem1, deliveryAndProductLineItem2) => {
            const date1 = deliveryAndProductLineItem1.delivery.getDateDeliveredUTC().toDate();
            const date2 = deliveryAndProductLineItem2.delivery.getDateDeliveredUTC().toDate();
            if (date1.getTime() < date2.getTime()) {
                return 1;
            } else if (date1.getTime() === date2.getTime()) {
                return 0;
            } else {
                return -1;
            }
        });

        let percentChangeSinceLastOrderStr = 'N/A';
        let percentChangePositive : boolean | null = null;
        let timeIntervalSinceLastOrderStr = '';
        let mostRecentPriceStr = '';
        let avgPriceStr = '';
        if (deliveriesWithProduct.length > 0) {
            let totalPaidInPricePerUnit = 0;
            let totalUnits = 0;
            let mostRecentPrice = 0;
            let mostRecentPricePerLineItemUnit = 0;
            let productQuantityUnitDisplayText;
            let priceProductQuantityUnitText;
            let mostRecentUnit : ProductQuantityUnit;
            let secondMostRecentPrice = 0;
            deliveriesWithProduct.forEach((deliveryAndProductLineItem, index) => {
                const packagingsAndMappings = product.getPackagingsAndMappings();
                const lineItem = deliveryAndProductLineItem.productLineItem;

                let priceProductQuantityUnit : ProductQuantityUnit;
                const productPricingUnit = product.getUnitPrice().getUnit();
                if (PackagingUnit.isPackagingUnit(productPricingUnit) || BaseUnit.isBaseUnit(productPricingUnit)) {
                    const packagingForContainedPackagingName = PackagingUtils.getPackagingForContainedPackagingName(packagingsAndMappings.getPackaging(), productPricingUnit);

                    if (packagingForContainedPackagingName === null) {
                        throw new RuntimeException(`unexpected unable to get packaging for contained packaging name ${productPricingUnit} ${packagingsAndMappings.getPackaging()}`);
                    }

                    const packagingId = packagingForContainedPackagingName.getPackagingId();
                    if (packagingId === null) {
                        throw new RuntimeException('unexpected contained packaging has no packagingId');
                    }

                    priceProductQuantityUnit = packagingId;
                } else {
                    priceProductQuantityUnit = product.getPreferredReportingUnit();
                }

                const quantityOfPackageInUnit = PackagingUtils.convertProductQuantityToUnit(
                    packagingsAndMappings,
                    new QuantityInUnit(lineItem.getQuantityInUnits(), lineItem.getProductQuantityUnit()),
                    priceProductQuantityUnit,
                    productId
                );

                // get average price based on preferred reporting unit
                const quantityOfAvgPackageInUnit = PackagingUtils.convertProductQuantityToUnit(
                    packagingsAndMappings,
                    new QuantityInUnit(lineItem.getQuantityInUnits(), lineItem.getProductQuantityUnit()),
                    product.getPreferredReportingUnit(),
                    productId
                );

                const lineItemTotal = DeliveryUtils.getTotalCostOfDeliveryLineItem(lineItem);
                totalUnits += quantityOfAvgPackageInUnit.getQuantity();
                totalPaidInPricePerUnit += lineItemTotal;

                let quantityOfPackageInUnitQuantity = quantityOfPackageInUnit.getQuantity();
                let lineItemQuantityInUnits = lineItem.getQuantityInUnits();
                if (quantityOfPackageInUnitQuantity === 0) {
                    quantityOfPackageInUnitQuantity = 1;
                }
                if (lineItemQuantityInUnits === 0) {
                    lineItemQuantityInUnits = 1;
                }
                if (index === 0) {
                    mostRecentPrice = lineItemTotal / quantityOfPackageInUnitQuantity;
                    mostRecentUnit = lineItem.getProductQuantityUnit();
                    mostRecentPricePerLineItemUnit = lineItemTotal / lineItemQuantityInUnits;
                }
                if (index === 1) {
                    secondMostRecentPrice = lineItemTotal / quantityOfPackageInUnitQuantity;
                }
                productQuantityUnitDisplayText = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), mostRecentUnit, false);
                priceProductQuantityUnitText = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), quantityOfAvgPackageInUnit.getUnit(), false);
            });

            mostRecentPriceStr = `${ FormatMonetaryValueWithCents(mostRecentPricePerLineItemUnit) } / ${ productQuantityUnitDisplayText }`;

            avgPriceStr = `${ FormatMonetaryValueWithCents((totalPaidInPricePerUnit / (totalUnits === 0 ? 1 : totalUnits))) } / ${ priceProductQuantityUnitText }`            ;

            const timeIntervalBetweenOrders = moment(deliveriesWithProduct[0].delivery.getDateDeliveredUTC()).toNow(true);
            timeIntervalSinceLastOrderStr = '(' + timeIntervalBetweenOrders + ' ago)';

            if (deliveriesWithProduct.length > 1) {
                const percentChange = Math.round(((mostRecentPrice / secondMostRecentPrice) - 1.0) * 1000) / 10;
                percentChangeSinceLastOrderStr = percentChange.toFixed(1) + '%';
                if (percentChange !== 0.0) {
                    percentChangePositive = percentChange > 0;
                }
            }
        }

        return (
            <div className="item-price-history">
                <div className="item-price-history-label">History</div>
                <div className="item-price-history-lower-row">
                    <div className="most-recent">
                        <div className="label">
                            Most recent price <span className="detail-label">{ timeIntervalSinceLastOrderStr }</span>
                        </div>
                        <div className="most-recent-price-hbox">
                            { mostRecentPriceStr.length > 0 &&
                                <div className="price ellipsis-out">
                                    { mostRecentPriceStr }
                                </div>
                            }
                            { mostRecentPriceStr.length === 0 &&
                                <div className="price ellipsis-out">
                                    N/A
                                </div>
                            }
                            { percentChangeSinceLastOrderStr !== 'N/A' &&
                                <div className="percentage-change">
                                    { percentChangePositive !== null &&
                                        <span className={ 'bevicon bevico-arrow-forward percentage-change-arrow ' + (percentChangePositive ? 'positive' : 'negative') }/>
                                    }
                                    <div className="percentage-change-vbox">
                                        { percentChangeSinceLastOrderStr }
                                        <div className="since-last-order">between last two orders</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="average-price text-right">
                        <div className="label">
                            Avg. price <span className="detail-label">{ `(Last ${ productHistoryData.numMonthsOfData } mo${ productHistoryData.numMonthsOfData !== 1 ? 's' : '' }.)` }</span>
                        </div>
                            { avgPriceStr.length > 0 &&
                                <div className="price ellipsis-out">
                                    { avgPriceStr }
                                </div>
                            }
                            { avgPriceStr.length === 0 &&
                                <div className="price ellipsis-out">
                                    N/A
                                </div>
                            }
                    </div>
                </div>
                { deliveriesWithProduct.length === 0 &&
                    <div className="no-orders">
                        <span className="bevicon bevico-new-releases"/>
                        <span>
                            This item hasn't been ordered in the last six months.  Once this item has been ordered, pricing data will show here.
                        </span>
                    </div>
                }
            </div>
        );
    }
}
