import React from 'react';
import { connect } from 'react-redux';

import { Button } from 'shared/components/Button';

import { DropdownMenu, IDropdownMenuProps, IOption } from 'shared/components/Dropdown/DropdownMenu';
import { IInventoryPeriodSelectorState } from 'shared/components/InventoryPeriodSelector/InventoryPeriodSelectorState';
import { IFullInventory } from 'shared/models/Inventory';

import { InventoryPeriodSelectorActions, TypedDispatch } from 'shared/components/InventoryPeriodSelector/InventoryPeriodSelectorActions';

import './InventoryPeriodSelector.scss';

export interface IInventoryPeriodSelectorProps {
    startInventorySelectProps : IDropdownMenuProps;
    endInventorySelectProps : IDropdownMenuProps;
    onSubmit : () => Promise<void>;
    shouldShowPeriodStartIsNotEarlierThanEndError : boolean;
    submitButtonText? : string;
}

export class InventoryPeriodSelector extends React.Component<IInventoryPeriodSelectorProps, object> {
    public render() {
        const {
            startInventorySelectProps,
            endInventorySelectProps,
            shouldShowPeriodStartIsNotEarlierThanEndError,
            onSubmit,
            submitButtonText
        } = this.props;

        const submitButtonChildren : JSX.Element = submitButtonText ? (<div>{ submitButtonText }</div>) : (<div>Run <span className="hidden-xs">Report</span></div>);

        return (
            <div className="inventory-period-selector">
                <DropdownMenu
                    styleType={ startInventorySelectProps.styleType }
                    onClick={ startInventorySelectProps.onClick }
                    onBlur={ startInventorySelectProps.onBlur }
                    dropdownOptionsShown={ startInventorySelectProps.dropdownOptionsShown }
                    onOptionClick={ startInventorySelectProps.onOptionClick }
                    selectedOptionValue={ startInventorySelectProps.selectedOptionValue }
                    options={ startInventorySelectProps.options }
                    placeholderText={ null }
                />
                <span className="bevicon bevico-arrow-forward"/>
                <DropdownMenu
                    styleType={ endInventorySelectProps.styleType }
                    onClick={ endInventorySelectProps.onClick }
                    onBlur={ endInventorySelectProps.onBlur }
                    dropdownOptionsShown={ endInventorySelectProps.dropdownOptionsShown }
                    onOptionClick={ endInventorySelectProps.onOptionClick }
                    selectedOptionValue={ endInventorySelectProps.selectedOptionValue }
                    options={ endInventorySelectProps.options }
                    placeholderText={ null }
                />
                <Button
                    buttonClassName="submit-inventory-period"
                    isDisabled={ shouldShowPeriodStartIsNotEarlierThanEndError }
                    isLoading={ false }
                    onClick={ onSubmit }
                >
                    { submitButtonChildren }
                </Button>
                { shouldShowPeriodStartIsNotEarlierThanEndError &&
                    <div className="error-text">
                        Starting inventory must be earlier than ending inventory.
                    </div>
                }
            </div>
        );
    }
}

export interface IConnectedInventoryPeriodSelectorProps {
    onSubmitInventoryPeriod : (startInventoryId : string, endInventoryId : string) => Promise<void>;
}

interface IMapStateToProps {
    inventoryPeriodSelectorState : IInventoryPeriodSelectorState;
}
const mapStateToProps = (state : IMapStateToProps, ownProps : IConnectedInventoryPeriodSelectorProps) : IMapStateToProps => {
    return {
        inventoryPeriodSelectorState : state.inventoryPeriodSelectorState,
    };
};

interface IMapDispatchToProps {
    dispatch : TypedDispatch;
}
const mapDispatchToProps = (dispatch : TypedDispatch, ownProps : IConnectedInventoryPeriodSelectorProps) : IMapDispatchToProps => {
    return {
        dispatch
    };
};

const mergeProps = (stateProps : IMapStateToProps, dispatchProps : IMapDispatchToProps, ownProps : IConnectedInventoryPeriodSelectorProps) : IInventoryPeriodSelectorProps => {
    const {
        dispatch,
    } = dispatchProps;

    const {
        inventoryPeriodSelectorState
    } = stateProps;

    const options : Array<IOption> = [];
    inventoryPeriodSelectorState.sortedInventories.forEach((inventory : IFullInventory) => {
        options.push({
            label: inventory.date.getValue('MMM. DD YYYY') + ' ' + inventory.name,
            value: inventory.id,
            icon: null
        });
    });

    let shouldShowPeriodStartIsNotEarlierThanEndError : boolean;
    if (inventoryPeriodSelectorState.selectedStartInventoryId === null || inventoryPeriodSelectorState.selectedEndInventoryId === null) {
        shouldShowPeriodStartIsNotEarlierThanEndError = false;
    } else {
        const datesByInventoryId = inventoryPeriodSelectorState.datesByInventoryId;
        shouldShowPeriodStartIsNotEarlierThanEndError = datesByInventoryId[inventoryPeriodSelectorState.selectedStartInventoryId].toMilliseconds() >= datesByInventoryId[inventoryPeriodSelectorState.selectedEndInventoryId].toMilliseconds();
    }

    const startInventorySelectProps : IDropdownMenuProps =  {
        styleType: 'outline',
        placeholderText: '',
        onClick: () => dispatch(InventoryPeriodSelectorActions.setStartInventoryOptionsShown(!inventoryPeriodSelectorState.startInventoryOptionsShown)),
        onOptionClick: (option : IOption) => {
            dispatch(InventoryPeriodSelectorActions.setStartInventoryId(option.value));
            dispatch(InventoryPeriodSelectorActions.setStartInventoryOptionsShown(false));
        },
        options,
        dropdownOptionsShown: inventoryPeriodSelectorState.startInventoryOptionsShown,
        selectedOptionValue: inventoryPeriodSelectorState.selectedStartInventoryId,
        onBlur: () => dispatch(InventoryPeriodSelectorActions.setStartInventoryOptionsShown(false)),
    };

    const endInventorySelectProps : IDropdownMenuProps = {
        styleType: 'outline',
        placeholderText: '',
        onClick: () => dispatch(InventoryPeriodSelectorActions.setEndInventoryOptionsShown(!inventoryPeriodSelectorState.endInventoryOptionsShown)),
        onOptionClick: (option : IOption) => {
            dispatch(InventoryPeriodSelectorActions.setEndInventoryId(option.value));
            dispatch(InventoryPeriodSelectorActions.setEndInventoryOptionsShown(false));
        },
        options,
        dropdownOptionsShown: inventoryPeriodSelectorState.endInventoryOptionsShown,
        selectedOptionValue: inventoryPeriodSelectorState.selectedEndInventoryId,
        onBlur: () => dispatch(InventoryPeriodSelectorActions.setEndInventoryOptionsShown(false))
    };

    return {
        startInventorySelectProps,
        endInventorySelectProps,
        onSubmit : () => {
            if (inventoryPeriodSelectorState.selectedStartInventoryId !== null && inventoryPeriodSelectorState.selectedEndInventoryId !== null) {
                return ownProps.onSubmitInventoryPeriod(inventoryPeriodSelectorState.selectedStartInventoryId, inventoryPeriodSelectorState.selectedEndInventoryId);
            }
            return Promise.resolve();
        },
        shouldShowPeriodStartIsNotEarlierThanEndError,
    };
};

export const ConnectedInventoryPeriodSelector =
    connect<IMapStateToProps, IMapDispatchToProps, IConnectedInventoryPeriodSelectorProps, IInventoryPeriodSelectorProps, IMapStateToProps>(mapStateToProps, mapDispatchToProps, mergeProps)(InventoryPeriodSelector);
