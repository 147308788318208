import { IPOSIntegrationService } from 'api/Integration/interfaces/IPOSIntegrationService';
import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { IPosLocation } from 'api/Integration/model/PosLocation';
import { LocationId } from 'api/Location/model/LocationId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import IntegrationExceptions from 'gen-thrift/integration_Exception_types';
import { ThunkAction } from 'redux-thunk';
import { ISquareIntegrationRowState } from 'shared/components/PosIntegration/SquareIntegrationRowReducers';
import { IExtraArguments } from 'shared/components/Provider';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { integrationService } from 'shared/lib/manager';
import { IAction, ISetShownAction } from 'shared/models/IAction';

export interface ISquareIntegrationModalStore {
    squareIntegrationRowState : ISquareIntegrationRowState;
}

export const ActionTypes = {
    SET_MODAL_SHOWN: 'SQUARE_INTEGRATION/SET_MODAL_SHOWN',
    SET_EXISTING_INTEGRATION_STATE: 'SQUARE_INTEGRATION/SET_EXISTING_INTEGRATION_STATE',
    SET_IS_LOADING: 'SQUARE_INTEGRATION/SET_IS_LOADING',

    SET_MODAL_IS_LOADING: 'SQUARE_INTEGRATION/SET_MODAL_IS_LOADING',
    SET_SQUARE_LOCATIONS: 'SQUARE_INTEGRATION/SET_SQUARE_LOCATIONS',
    SET_SQUARE_LOCATION_SELECTOR_SHOWN: 'SQUARE_INTEGRATION/SET_SQUARE_LOCATION_SELECTOR_SHOWN',
    SET_SELECTED_MERCHANT: 'SQUARE_INTEGRATION/SET_SELECTED_MERCHANT',
    SET_MODAL_ERROR: 'SQUARE_INTEGRATION/SET_MODAL_ERROR',
    SET_MODAL_ACCESS_TOKEN: 'SQUARE_INTEGRATION/SET_MODAL_ACCESS_TOKEN'
};

export namespace SquareIntegrationActionInterfaces {
    export interface ISetSquareLocations extends IAction {
        payload : {
            squareLocations : Array<IPosLocation>
        };
    }
    export interface ISetLocationSelectorShown extends IAction {
        payload : {
            isShown : boolean
        };
    }

    export interface ISetModalError extends IAction {
        payload : {
            message : string | null
        };
    }

    export interface ISetModalShown extends ISetShownAction {
    }

    export interface ISetModalAccessToken extends IAction {
        payload : {
            accessToken : string | null
        };
    }

    export interface ISetIsLoading extends IAction {
        payload : {
            isLoading : boolean
        };
    }

    export interface ISetExistingIntegrationState extends IAction {
        payload : {
            integrationState: Set<IntegrationState>
        };
    }

    export interface ISetSelectedMerchant extends IAction {
        payload: {
            merchantId: string | null
        };
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        integrationService : IPOSIntegrationService;
    }
    export interface IThunkServices extends IExtraArguments {
        services : IServices;
    }
}

const setModalIsLoading = (
    isLoading : boolean
) : SquareIntegrationActionInterfaces.ISetIsLoading => ({
    payload : {
        isLoading
    },
    type: ActionTypes.SET_MODAL_IS_LOADING,
});

const setSquareLocations = (
    squareLocations : Array<IPosLocation>
) : SquareIntegrationActionInterfaces.ISetSquareLocations => ({
    payload : { squareLocations },
    type : ActionTypes.SET_SQUARE_LOCATIONS,
});

const setLocationSelectorIsShown = (
    isShown : boolean
) : SquareIntegrationActionInterfaces.ISetLocationSelectorShown => ({
    payload : {
        isShown,
    },
    type : ActionTypes.SET_SQUARE_LOCATION_SELECTOR_SHOWN
});

const setModalError = (
    message : string | null
) : SquareIntegrationActionInterfaces.ISetModalError => ({
    payload : {
        message,
    },
    type : ActionTypes.SET_MODAL_ERROR
});

const setModalShown = (
    isShown : boolean
) : SquareIntegrationActionInterfaces.ISetModalShown => ({
    payload : {
        isShown,
    },
    type : ActionTypes.SET_MODAL_SHOWN
});

const setIsLoading = (
    isLoading : boolean
) : SquareIntegrationActionInterfaces.ISetIsLoading => ({
    payload : {
        isLoading
    },
    type: ActionTypes.SET_IS_LOADING,
});

const setExistingCredentialState = (
    integrationState : Set<IntegrationState>
) : SquareIntegrationActionInterfaces.ISetExistingIntegrationState => ({
    payload : {
        integrationState,
    },
    type: ActionTypes.SET_EXISTING_INTEGRATION_STATE,
});

const setSelectedMerchant = (
    merchantId: string | null
): SquareIntegrationActionInterfaces.ISetSelectedMerchant => ({
    payload: {
        merchantId,
    },
    type: ActionTypes.SET_SELECTED_MERCHANT
});

const setModalAccessToken = (
    accessToken : string | null
) : SquareIntegrationActionInterfaces.ISetModalAccessToken => ({
    payload : {
        accessToken,
    },
    type : ActionTypes.SET_MODAL_ACCESS_TOKEN
});

const fetchExistingIntegration = (
    locationId: LocationId
): ThunkAction<Promise<Set<IntegrationState>>, ISquareIntegrationModalStore, SquareIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments): Promise<Set<IntegrationState>> => {
        dispatch(setIsLoading(true));
        const session = extraArguments.services.userSessionReader.getSessionId();
        return extraArguments.services.integrationService.getIntegrationState('square', session, locationId)
        .then((data: Set<IntegrationState>) => {
            dispatch(setExistingCredentialState(data));
            dispatch(setIsLoading(false));
            return data;
        })
        .catch((e: Error) => {
            dispatch(setIsLoading(false));
            throw e;
        });
    };
};

const processAuthorization = (
    locationId : LocationId,
    authorizationCode : string
) : ThunkAction<Promise<void>, ISquareIntegrationModalStore, SquareIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {

        const session = extraArguments.services.userSessionReader.getSessionId();
        return integrationService.authorizeAndListAccessiblePosLocations(
            session,
            locationId,
            'square',
            authorizationCode
        ).then((response) => {
            if (response.length > 1) {
                dispatch(setSquareLocations(response));
                dispatch(setLocationSelectorIsShown(true));
            } else if (response.length === 1) {
                return dispatch(associateLocationWithSquareLocation(locationId, response[0].id));
            } else {
                dispatch(setModalError('You have no locations in your Square account. Please create one and come back to complete the setup.'));
            }
            dispatch(setModalShown(true));
        });
    };
};

const associateLocationWithSquareLocation = (
    locationId : LocationId,
    squareLocationId : string
) : ThunkAction<Promise<void>, ISquareIntegrationModalStore, SquareIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {
        dispatch(setModalIsLoading(true));

        const session = extraArguments.services.userSessionReader.getSessionId();
        return integrationService.associateAuthorizedLocationWithMerchant(
            session,
            locationId,
            'square',
            squareLocationId
        ).then(() => {
            // get updated integration state from backend
            return extraArguments.services.integrationService.getIntegrationState('square', session, locationId)
            .then((data: Set<IntegrationState>) => {
                dispatch(setExistingCredentialState(data));
                dispatch(setLocationSelectorIsShown(false));
                dispatch(setModalIsLoading(false));
            });
        }).catch((error) => {
            dispatch(setModalIsLoading(false));
            if (error instanceof IntegrationExceptions.IntegrationNotAccessibleException) {
                // this is theoretically possible if user revoked authorization from a different page right after attempting to connect...
                throw new RuntimeException('Failed to connect with Square: access denied.');
            }
            if (error instanceof IntegrationExceptions.LocationAlreadyHasIntegrationException) {
                dispatch(setModalError('This Square merchant is already connected with your BevSpot location. Please select a different Square merchant.'));
                return;
            }
            throw error;
        });
    };
};

const disassociateLocationWithSquareLocation = (
    locationId : LocationId,
    merchantId: string,
) : ThunkAction<Promise<void>, ISquareIntegrationModalStore, SquareIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {
        dispatch(setModalIsLoading(true));

        const session = extraArguments.services.userSessionReader.getSessionId();
        return integrationService.removeIntegrationForMultiConnectionType(
            'square',
            session,
            locationId,
            merchantId,
        ).then(() => {
            return extraArguments.services.integrationService.getIntegrationState('square', session, locationId)
            .then((data: Set<IntegrationState>) => {
                dispatch(setExistingCredentialState(data));
                dispatch(setModalIsLoading(false));
                dispatch(setSelectedMerchant(null));
            });
        })
        .catch((error) => {
            dispatch(setModalIsLoading(false));
            throw error;
        });
    };
};

const retrieveAccessToken = (
    locationId : LocationId,
    merchantId: string,
) : ThunkAction<Promise<void>, ISquareIntegrationModalStore, SquareIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) : Promise<void> => {
        const session = extraArguments.services.userSessionReader.getSessionId();

        return integrationService.retrieveAccessTokenForMultiConnectionType(
            session,
            locationId,
            merchantId,
            "square",
        ).then((accessToken : string | null) => {
            if (accessToken == null || accessToken === "") {
                accessToken = "Access token not found.";
            }
            dispatch(setModalAccessToken(accessToken));
        }).catch((error) => {
            throw error;
        });
    };
};

export const SquareIntegrationRowActions = {
    processAuthorization,
    associateLocationWithSquareLocation,
    disassociateLocationWithSquareLocation,
    setModalError,
    setModalShown,
    setModalAccessToken,
    retrieveAccessToken,
    fetchExistingIntegration,
    setSelectedMerchant,
};
