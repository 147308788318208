import { Action, ActionCreator, Reducer } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { IErrorState } from './IErrorState';

export interface IAction extends Action {
    payload : {
        [key : string] : any;
    };
}

export interface IEmptyAction extends IAction {
    payload : object;
}

export interface IEmptyActionCreator extends ActionCreator<IEmptyAction> {
    () : IEmptyAction;
}

export interface IFailureAction extends IAction {
    payload : {
        error : Error;
    };
}

export interface IFailureActionCreator extends ActionCreator<IFailureAction> {
    (error : Error) : IFailureAction;
}

export interface ISetErrorAction extends IAction {
    payload : {
        error : IErrorState | null;
    };
}

export interface ISetErrorActionCreator extends ActionCreator<ISetErrorAction> {
    (error : IErrorState | null) : ISetErrorAction;
}

export interface ISetShownAction extends IAction {
    payload : {
        isShown : boolean;
    };
}

export interface ISetShownActionCreator extends ActionCreator<ISetShownAction> {
    (isShown : boolean) : ISetShownAction;
}

export interface ISetStringAction extends IAction {
    payload : {
        value : string;
    };
}

export interface IActionCreatorsMapObject {
  [key : string] : ActionCreator<IAction> | ActionCreator<ThunkAction<any, any, any>>;
}

const BATCH_ACTION_TYPE = '_BATCHING_REDUCER_BATCH_ACTION_';

// TODO Allow this to also take ThunkAction<any, any, any>
export function batchActions(actions : Array<IAction>) : IAction {
        return {
        type: BATCH_ACTION_TYPE,
        payload: actions
    };
}

// This is called in the Provider on all Reducers
export function enableBatching(reducer : Reducer<any>) {
    return function batchingReducer(state : any, action : Action) {
        if (action && (action.type === BATCH_ACTION_TYPE)) {
            const payload = (action as IAction).payload;
            return payload.reduce(batchingReducer, state);
        }

        return reducer(state, action);
    };
}
