import React from 'react';
import MediaQuery from 'react-responsive';

import { AccountType } from 'api/Location/model/AccountType';
import { OldPackaging } from 'api/Product/model/OldPackaging';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';

import { PackagingFormUtils } from './PackagingFormUtils';

import { Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { IValidationInputData, ValidationInput } from 'shared/components/ValidationInput';
import { MAX_MOBILE_WIDTH } from 'shared/constants';

import './PackagingForm.scss';

const COMES_AS_CASE_QUANTITY_ERROR_MESSAGE = 'Quantity per case must be greater than 1 to be a case';

const DEFAULT_FOOD_PACKAGING = new Packaging(
    new PackagingId('defaultFoodPackagingId'),
    'other',
    new Packaging(new PackagingId('EA'), 'EA', null, null, null),
    1,
    null
);

const DEFAULT_BEVERAGE_PACKAGING = new Packaging(
    new PackagingId('defaultBeveragePackagingId'),
    'bottle',
    new Packaging(null, null, null, null, VolumeUnit.MILLILITER),
    750,
    null
);

export type PackagingFormFieldName = 'unitName' | 'contentQuantity' | 'contentUnit' | 'caseName' | 'caseQuantity';
type ComponentName = 'infoPopover';

interface IPackagingFormValidationInputDataByFieldName {
    unitName : IValidationInputData;
    contentQuantity : IValidationInputData;
    contentUnit : IValidationInputData;
    caseName : IValidationInputData;
    caseQuantity : IValidationInputData;
    comesAsCase : {
        value : boolean;
        isValid : boolean;
        errorMessage : string;
    };
}
export type PackagingFormValidationInputDataByFieldName = IPackagingFormValidationInputDataByFieldName;

interface IPackagingFormState {
    validationInputDataByFieldName : PackagingFormValidationInputDataByFieldName;
    isValid : boolean;
    errorMessage : string;
    isShownByComponentName : { [componentName in ComponentName] : boolean };
}

export interface IPackagingFormProps {
    readonly initialPackaging : Packaging | PackagingFormValidationInputDataByFieldName | null;
    readonly onFormFieldChange : ((packagingFormValidationInputDataByFieldName : IPackagingFormValidationInputDataByFieldName) => void) | null;
}

export class PackagingForm extends React.Component<IPackagingFormProps, IPackagingFormState> {
    private defaultPackaging = (window.GLOBAL_RETAILER_ACCOUNT_TYPE === 'beverage') ? DEFAULT_BEVERAGE_PACKAGING : DEFAULT_FOOD_PACKAGING;

    public constructor(props : IPackagingFormProps) {
        super(props);
        const validationInputDataByFieldName = PackagingFormUtils.getValidationInputDataByFieldNameFromInitialPackaging(this.props.initialPackaging || this.defaultPackaging);

        this.state = {
            validationInputDataByFieldName,
            isValid: true,
            errorMessage: '',
            isShownByComponentName: {
                infoPopover: false,
            }
        };

        if (this.props.onFormFieldChange) {
            this.props.onFormFieldChange(this.state.validationInputDataByFieldName);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps : IPackagingFormProps) {
        if (nextProps.initialPackaging !== this.props.initialPackaging) {
            const validationInputDataByFieldName = PackagingFormUtils.getValidationInputDataByFieldNameFromInitialPackaging(nextProps.initialPackaging || this.defaultPackaging);

            this.setState((state) => {
                return {
                    ...state,
                    validationInputDataByFieldName,
                };
            }, this.onSetState);
        }
    }

    public render() {
        const validationInputDataByFieldName = this.state.validationInputDataByFieldName;

        const unitNameSortedOptionsAndLabelName = PackagingFormUtils.getUnitNameSortedOptionsAndLabelName(window.GLOBAL_RETAILER_ACCOUNT_TYPE as AccountType);
        const unitNameSelectedOption = PackagingFormUtils.getIOptionForUnitValue(validationInputDataByFieldName.unitName.value);
        // const unitNameSelectedOptionLabel = PackagingFormUtils.getDisplayTextForPackagingUnitValueAndQuantity(validationInputDataByFieldName.unitName.value, validationInputDataByFieldName.caseQuantity.value);

        const contentUnitSortedOptionsAndLabelName = PackagingFormUtils.getContentUnitSortedOptionsAndLabelName(validationInputDataByFieldName.unitName.value);
        const contentUnitSelectedOption = PackagingFormUtils.getIOptionForUnitValue(validationInputDataByFieldName.contentUnit.value);

        const isKeg = (validationInputDataByFieldName.unitName.value === 'keg');

        return (
            <div className="packaging-form">
                <div className="packaging-preview">
                    <span className="packaging-preview-string">Appears as:</span>
                    <span className="packaging-preview-string-data">&nbsp;<strong>{ PackagingFormUtils.getAppearsAsText(validationInputDataByFieldName) }</strong></span>
                </div>
                <MediaQuery minWidth={ MAX_MOBILE_WIDTH }>
                    <div className="packaging-headers">
                        <h6>Case Information</h6>
                        <h6>Unit Information</h6>
                        <h6/>
                        <h6/>
                    </div>
                </MediaQuery>
                <div className="packaging-section units-layer">
                    <div className="packaging-section-subsection">
                        <div className="packaging-case-section">
                            <label className="input-label static reset-dark-styles">
                                Case Size
                            </label>
                            <ValidationInput
                                type="text"
                                label={ null }
                                hintText="Optional"
                                value={ validationInputDataByFieldName.caseQuantity.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ validationInputDataByFieldName.caseQuantity.isValid }
                                isDisabled={ isKeg ? true : false }
                                errorMessage={ validationInputDataByFieldName.caseQuantity.errorMessage }
                                inputClassName="text-right reset-dark-styles"
                                handleEnterClick={ this.doNothing }
                                handleChange={ this.onCaseQuantityChange }
                                handleBlur={ this.onCaseQuantityBlur }
                                handleFocus={ this.doNothing }
                            />
                        </div>
                    </div>
                    <div className="packaging-section-subsection">
                        <label className="input-label static reset-dark-styles">
                            Unit Size
                        </label>
                        <div className="packaging-input-group">
                            <ValidationInput
                                type="text"
                                label={ null }
                                hintText="?"
                                value={ validationInputDataByFieldName.contentQuantity.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ validationInputDataByFieldName.contentQuantity.isValid }
                                isDisabled={ false }
                                errorMessage={ validationInputDataByFieldName.contentQuantity.errorMessage }
                                inputClassName="text-right reset-dark-styles"
                                handleEnterClick={ this.doNothing }
                                handleChange={ this.onContentQuantityChange }
                                handleBlur={ this.onContentQuantityBlur }
                                handleFocus={ this.doNothing }
                            />
                        </div>
                    </div>
                    <div className="packaging-section-subsection">
                        <label className="input-label static reset-dark-styles">
                            Unit of Measure
                        </label>
                        <div className="floating-dropdown-menu">
                            <Select2DropdownMenu
                                isSearchable={ false }
                                buttonShouldDisplaySelectedLabel={ true }
                                selectedOptionHeaderShown={ false }
                                sortedOptionsAndLabelName={ contentUnitSortedOptionsAndLabelName }
                                selectedOption={ contentUnitSelectedOption }
                                onOptionSelect={ this.onContentUnitSelect }
                                placeholderText="?"
                                emptyState={ null }
                                hasCreateCustomOption={ false }
                                createCustomOption={ this.doNothing }
                                customOptionGroupLabel={ null }
                                createCustomOptionButtonLabel={ null }
                            />
                        </div>
                    </div>
                    <div className="packaging-section-subsection">
                        <label className="input-label reset-dark-styles">
                            Unit Name
                        </label>
                        <div className="floating-dropdown-menu">
                            <Select2DropdownMenu
                                isSearchable={ false }
                                buttonShouldDisplaySelectedLabel={ true }
                                selectedOptionHeaderShown={ false }
                                sortedOptionsAndLabelName={ unitNameSortedOptionsAndLabelName }
                                selectedOption={ unitNameSelectedOption }
                                onOptionSelect={ this.onUnitNameSelect }
                                placeholderText="Select Unit"
                                emptyState={ null }
                                hasCreateCustomOption={ false }
                                createCustomOption={ this.doNothing }
                                customOptionGroupLabel={ null }
                                createCustomOptionButtonLabel={ null }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public readonly getValidationInputDataByFieldName = () : PackagingFormValidationInputDataByFieldName => {
        return this.state.validationInputDataByFieldName;
    }

    public readonly getPackaging = (shouldSetFormFieldValidationData : boolean) : Packaging | null => {
        const oldPackaging = this.getOldPackaging(shouldSetFormFieldValidationData);
        if (oldPackaging) {
            return oldPackagingUtils.getPackagingWithDummyPackagingIdsFromOldPackaging(oldPackaging);
        }

        return null;
    }

    public readonly getOldPackaging = (shouldSetFormFieldValidationData : boolean) : OldPackaging | null => {
        const formIsValid = this.validateForm(shouldSetFormFieldValidationData);
        const validationInputDataByFieldName = this.state.validationInputDataByFieldName;

        if (formIsValid) {
            const contentUnitPackaging = new OldPackaging(null, null, oldPackagingUtils.getUnitFromValue(validationInputDataByFieldName.contentUnit.value));
            const containerPackaging = new OldPackaging(contentUnitPackaging, parseFloat(validationInputDataByFieldName.contentQuantity.value), oldPackagingUtils.getUnitFromValue(validationInputDataByFieldName.unitName.value));

            if (validationInputDataByFieldName.caseQuantity.value) {
                return new OldPackaging(containerPackaging, parseFloat(validationInputDataByFieldName.caseQuantity.value), oldPackagingUtils.getUnitFromValue(validationInputDataByFieldName.caseName.value));
            }

            return containerPackaging;
        }

        return null;
    }

    private readonly onUnitNameSelect = (unitName : string) => {
        this.onFormFieldChange('unitName', unitName);

        if (unitName === 'keg') {
            this.setState((state) => {
                return {
                    ...state,
                    validationInputDataByFieldName: {
                        ...state.validationInputDataByFieldName,
                        comesAsCase: {
                            value: false,
                            isValid: true,
                            errorMessage: '',
                        },
                        caseQuantity: {
                            value: '',
                            isValid: true,
                            errorMessage: '',
                        }
                    }
                };
            }, this.onSetState);
        }
    }

    private readonly onContentQuantityChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.onFormFieldChange('contentQuantity', event.currentTarget.value);
    }

    private readonly onContentQuantityBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        let blurValue = event.currentTarget.value.trim();

        if (PackagingFormUtils.validateValueByFieldName('contentQuantity', blurValue).isValid) {
            blurValue = parseFloat(blurValue).toString();
        }

        this.onFormFieldChange('contentQuantity', blurValue);
    }

    private readonly onContentUnitSelect = (contentUnit : string) => {
        this.onFormFieldChange('contentUnit', contentUnit);
    }

    private readonly onCaseQuantityChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.onFormFieldChange('caseQuantity', event.currentTarget.value);
    }

    private readonly onCaseQuantityBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        let blurValue = event.currentTarget.value;
        if (PackagingFormUtils.validateValueByFieldName('caseQuantity', blurValue).isValid && blurValue !== '') {
            blurValue = parseFloat(blurValue).toString();
        }

        this.onFormFieldChange('caseQuantity', blurValue);
    }

    private readonly validateForm = (shouldSetFormFieldValidationData : boolean) : boolean => {
        let formIsValid = true;
        let formChanges : { [f in PackagingFormFieldName]? : IValidationInputData } = {};

        const validationInputDataByFieldName = this.state.validationInputDataByFieldName;
        Object.keys(validationInputDataByFieldName).forEach((key) => {
            if (key !== 'comesAsCase') {
                const fieldName = key as PackagingFormFieldName;
                const value = (validationInputDataByFieldName)[fieldName].value;

                const validationResult = PackagingFormUtils.validateValueByFieldName(fieldName, value);

                const { isValid, errorMessage } = validationResult;
                formIsValid = formIsValid && isValid;

                if (shouldSetFormFieldValidationData) {
                    formChanges = {
                        ...formChanges,
                        [fieldName]: {
                            value,
                            isValid,
                            errorMessage,
                        }
                    };
                }
            }
        });

        if (validationInputDataByFieldName.comesAsCase.value && !validationInputDataByFieldName.caseQuantity.value) {
            if (shouldSetFormFieldValidationData) {
                formChanges = {
                    ...formChanges,
                    caseQuantity: {
                        value: validationInputDataByFieldName.caseQuantity.value,
                        isValid: false,
                        errorMessage: COMES_AS_CASE_QUANTITY_ERROR_MESSAGE,
                    }
                };
            }
            formIsValid = false;
        }

        if (shouldSetFormFieldValidationData) {
            this.setState((state) => {
                return {
                    ...state,
                    validationInputDataByFieldName: {
                        ...validationInputDataByFieldName,
                        ...formChanges
                    }
                };
            });
        }

        return formIsValid;
    }

    private readonly onFormFieldChange = (fieldName : PackagingFormFieldName, value : string) => {
        this.setState((state) => {
            const {
                isValid,
                errorMessage,
            } = PackagingFormUtils.validateValueByFieldName(fieldName, value);

            let formChanges : { [f in PackagingFormFieldName]? : IValidationInputData } = {
                [fieldName]: {
                    value,
                    isValid,
                    errorMessage,
                }
            };

            let caseFormChange = {};

            const validationInputDataByFieldName = state.validationInputDataByFieldName;

            if (fieldName === 'unitName') {
                const contentUnitValue = validationInputDataByFieldName.contentUnit.value;
                if (contentUnitValue && !PackagingFormUtils.contentUnitIsCompatibleWithUnitName(value, contentUnitValue)) {
                    formChanges = {
                        ...formChanges,
                        contentUnit: {
                            value: PackagingFormUtils.getContentUnitOptions(value)[0],
                            isValid: true,
                            errorMessage: '',
                        }
                    };
                }
            } else if (fieldName === 'caseQuantity') {
                const caseIsValid = value !== '';
                caseFormChange = {
                    ...caseFormChange,
                    comesAsCase: {
                        value: caseIsValid,
                        isValid: true,
                        errorMessage: '',
                    },
                };
            }

            return {
                ...state,
                validationInputDataByFieldName: {
                    ...validationInputDataByFieldName,
                    ...formChanges,
                    ...caseFormChange
                },
            };
        }, this.onSetState);
    }

    private readonly onSetState = () => {
        if (this.props.onFormFieldChange) {
            this.props.onFormFieldChange(this.state.validationInputDataByFieldName);
        }
    }

    private readonly doNothing = () => {
        // Do Nothing
    }
}
