//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import UserAccountsModel from './accounts_user_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import LocationModel from './location_Model_types';
import LocationExceptions from './location_Exceptions_types';
import IntegrationModel from './integration_Model_types';
import IntegrationExceptions from './integration_Exception_types';
import AccountsExceptions from './accounts_Exceptions_types';
import CoreTimeModel from './core_time_Model_types';

var IntegrationService = {};
export default IntegrationService;
//HELPER FUNCTIONS AND STRUCTURES

IntegrationService.IntegrationService_getIntegrationState_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.integrationType = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.integrationType !== undefined && args.integrationType !== null) {
      this.integrationType = args.integrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field integrationType is unset!');
    }
  }
};
IntegrationService.IntegrationService_getIntegrationState_args.prototype = {};
IntegrationService.IntegrationService_getIntegrationState_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.integrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_getIntegrationState_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_getIntegrationState_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.integrationType !== null && this.integrationType !== undefined) {
    output.writeFieldBegin('integrationType', Thrift.Type.I32, 3);
    output.writeI32(this.integrationType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_getIntegrationState_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.unsupportedPosIntegrationTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [IntegrationModel.IntegrationState]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
  }
};
IntegrationService.IntegrationService_getIntegrationState_result.prototype = {};
IntegrationService.IntegrationService_getIntegrationState_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.SET) {
        var _size0 = 0;
        var _rtmp34;
        this.success = [];
        var _etype3 = 0;
        _rtmp34 = input.readSetBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new IntegrationModel.IntegrationState();
          elem6.read(input);
          this.success.push(elem6);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_getIntegrationState_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_getIntegrationState_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.SET, 0);
    output.writeSetBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter7 in this.success)
    {
      if (this.success.hasOwnProperty(iter7))
      {
        iter7 = this.success[iter7];
        iter7.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 6);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_removeIntegration_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.integrationType = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.integrationType !== undefined && args.integrationType !== null) {
      this.integrationType = args.integrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field integrationType is unset!');
    }
  }
};
IntegrationService.IntegrationService_removeIntegration_args.prototype = {};
IntegrationService.IntegrationService_removeIntegration_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.integrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_removeIntegration_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_removeIntegration_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.integrationType !== null && this.integrationType !== undefined) {
    output.writeFieldBegin('integrationType', Thrift.Type.I32, 3);
    output.writeI32(this.integrationType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_removeIntegration_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.unsupportedPosIntegrationTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
  }
};
IntegrationService.IntegrationService_removeIntegration_result.prototype = {};
IntegrationService.IntegrationService_removeIntegration_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_removeIntegration_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_removeIntegration_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 6);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.merchantId = null;
  this.integrationType = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.merchantId !== undefined && args.merchantId !== null) {
      this.merchantId = args.merchantId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field merchantId is unset!');
    }
    if (args.integrationType !== undefined && args.integrationType !== null) {
      this.integrationType = args.integrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field integrationType is unset!');
    }
  }
};
IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_args.prototype = {};
IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.merchantId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.integrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_removeIntegrationForMultiConnectionType_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.merchantId !== null && this.merchantId !== undefined) {
    output.writeFieldBegin('merchantId', Thrift.Type.STRING, 3);
    output.writeString(this.merchantId);
    output.writeFieldEnd();
  }
  if (this.integrationType !== null && this.integrationType !== undefined) {
    output.writeFieldBegin('integrationType', Thrift.Type.I32, 4);
    output.writeI32(this.integrationType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.unsupportedPosIntegrationTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
  }
};
IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_result.prototype = {};
IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_removeIntegrationForMultiConnectionType_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 6);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_importSalesEntriesViaIntegration_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.startTime = null;
  this.endTime = null;
  this.includePosMenuData = null;
  this.requireRealtimePosData = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.startTime !== undefined && args.startTime !== null) {
      this.startTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.startTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field startTime is unset!');
    }
    if (args.endTime !== undefined && args.endTime !== null) {
      this.endTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.endTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field endTime is unset!');
    }
    if (args.includePosMenuData !== undefined && args.includePosMenuData !== null) {
      this.includePosMenuData = args.includePosMenuData;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field includePosMenuData is unset!');
    }
    if (args.requireRealtimePosData !== undefined && args.requireRealtimePosData !== null) {
      this.requireRealtimePosData = args.requireRealtimePosData;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field requireRealtimePosData is unset!');
    }
  }
};
IntegrationService.IntegrationService_importSalesEntriesViaIntegration_args.prototype = {};
IntegrationService.IntegrationService_importSalesEntriesViaIntegration_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.startTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.startTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.endTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.endTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.includePosMenuData = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.BOOL) {
        this.requireRealtimePosData = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_importSalesEntriesViaIntegration_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_importSalesEntriesViaIntegration_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.startTime !== null && this.startTime !== undefined) {
    output.writeFieldBegin('startTime', Thrift.Type.STRUCT, 3);
    this.startTime.write(output);
    output.writeFieldEnd();
  }
  if (this.endTime !== null && this.endTime !== undefined) {
    output.writeFieldBegin('endTime', Thrift.Type.STRUCT, 4);
    this.endTime.write(output);
    output.writeFieldEnd();
  }
  if (this.includePosMenuData !== null && this.includePosMenuData !== undefined) {
    output.writeFieldBegin('includePosMenuData', Thrift.Type.BOOL, 5);
    output.writeBool(this.includePosMenuData);
    output.writeFieldEnd();
  }
  if (this.requireRealtimePosData !== null && this.requireRealtimePosData !== undefined) {
    output.writeFieldBegin('requireRealtimePosData', Thrift.Type.BOOL, 6);
    output.writeBool(this.requireRealtimePosData);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_importSalesEntriesViaIntegration_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.locationDoesNotHaveIntegrationException = null;
  this.posHistoryLimitExceededException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.LocationDoesNotHaveIntegrationException) {
    this.locationDoesNotHaveIntegrationException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.PosHistoryLimitExceededException) {
    this.posHistoryLimitExceededException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new IntegrationModel.SalesImportResult(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.locationDoesNotHaveIntegrationException !== undefined && args.locationDoesNotHaveIntegrationException !== null) {
      this.locationDoesNotHaveIntegrationException = args.locationDoesNotHaveIntegrationException;
    }
    if (args.posHistoryLimitExceededException !== undefined && args.posHistoryLimitExceededException !== null) {
      this.posHistoryLimitExceededException = args.posHistoryLimitExceededException;
    }
  }
};
IntegrationService.IntegrationService_importSalesEntriesViaIntegration_result.prototype = {};
IntegrationService.IntegrationService_importSalesEntriesViaIntegration_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new IntegrationModel.SalesImportResult();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationDoesNotHaveIntegrationException = new IntegrationExceptions.LocationDoesNotHaveIntegrationException();
        this.locationDoesNotHaveIntegrationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.posHistoryLimitExceededException = new IntegrationExceptions.PosHistoryLimitExceededException();
        this.posHistoryLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_importSalesEntriesViaIntegration_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_importSalesEntriesViaIntegration_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationDoesNotHaveIntegrationException !== null && this.locationDoesNotHaveIntegrationException !== undefined) {
    output.writeFieldBegin('locationDoesNotHaveIntegrationException', Thrift.Type.STRUCT, 6);
    this.locationDoesNotHaveIntegrationException.write(output);
    output.writeFieldEnd();
  }
  if (this.posHistoryLimitExceededException !== null && this.posHistoryLimitExceededException !== undefined) {
    output.writeFieldBegin('posHistoryLimitExceededException', Thrift.Type.STRUCT, 7);
    this.posHistoryLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.posIntegrationType = null;
  this.authorizationCode = null;
  this.codeVerifier = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.posIntegrationType !== undefined && args.posIntegrationType !== null) {
      this.posIntegrationType = args.posIntegrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posIntegrationType is unset!');
    }
    if (args.authorizationCode !== undefined && args.authorizationCode !== null) {
      this.authorizationCode = args.authorizationCode;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authorizationCode is unset!');
    }
    if (args.codeVerifier !== undefined && args.codeVerifier !== null) {
      this.codeVerifier = args.codeVerifier;
    }
  }
};
IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_args.prototype = {};
IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.posIntegrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.authorizationCode = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.codeVerifier = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addIntegrationThroughOAuthFlow_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.posIntegrationType !== null && this.posIntegrationType !== undefined) {
    output.writeFieldBegin('posIntegrationType', Thrift.Type.I32, 3);
    output.writeI32(this.posIntegrationType);
    output.writeFieldEnd();
  }
  if (this.authorizationCode !== null && this.authorizationCode !== undefined) {
    output.writeFieldBegin('authorizationCode', Thrift.Type.STRING, 4);
    output.writeString(this.authorizationCode);
    output.writeFieldEnd();
  }
  if (this.codeVerifier !== null && this.codeVerifier !== undefined) {
    output.writeFieldBegin('codeVerifier', Thrift.Type.STRING, 5);
    output.writeString(this.codeVerifier);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.locationAlreadyHasIntegrationException = null;
  this.failedToExchangeOAuthToken = null;
  this.unsupportedPosIntegrationTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.LocationAlreadyHasIntegrationException) {
    this.locationAlreadyHasIntegrationException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.FailedToExchangeOAuthToken) {
    this.failedToExchangeOAuthToken = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.locationAlreadyHasIntegrationException !== undefined && args.locationAlreadyHasIntegrationException !== null) {
      this.locationAlreadyHasIntegrationException = args.locationAlreadyHasIntegrationException;
    }
    if (args.failedToExchangeOAuthToken !== undefined && args.failedToExchangeOAuthToken !== null) {
      this.failedToExchangeOAuthToken = args.failedToExchangeOAuthToken;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
  }
};
IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_result.prototype = {};
IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAlreadyHasIntegrationException = new IntegrationExceptions.LocationAlreadyHasIntegrationException();
        this.locationAlreadyHasIntegrationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.failedToExchangeOAuthToken = new IntegrationExceptions.FailedToExchangeOAuthToken();
        this.failedToExchangeOAuthToken.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addIntegrationThroughOAuthFlow_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAlreadyHasIntegrationException !== null && this.locationAlreadyHasIntegrationException !== undefined) {
    output.writeFieldBegin('locationAlreadyHasIntegrationException', Thrift.Type.STRUCT, 6);
    this.locationAlreadyHasIntegrationException.write(output);
    output.writeFieldEnd();
  }
  if (this.failedToExchangeOAuthToken !== null && this.failedToExchangeOAuthToken !== undefined) {
    output.writeFieldBegin('failedToExchangeOAuthToken', Thrift.Type.STRUCT, 7);
    this.failedToExchangeOAuthToken.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 8);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_refreshPosCacheSince_args = function(args) {
  this.actor = null;
  this.integrationType = null;
  this.locationId = null;
  this.time = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.integrationType !== undefined && args.integrationType !== null) {
      this.integrationType = args.integrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field integrationType is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.time !== undefined && args.time !== null) {
      this.time = new CoreTimeModel.TimestampWithMillisecondPrecision(args.time);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field time is unset!');
    }
  }
};
IntegrationService.IntegrationService_refreshPosCacheSince_args.prototype = {};
IntegrationService.IntegrationService_refreshPosCacheSince_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.integrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.time = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.time.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_refreshPosCacheSince_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_refreshPosCacheSince_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.integrationType !== null && this.integrationType !== undefined) {
    output.writeFieldBegin('integrationType', Thrift.Type.I32, 2);
    output.writeI32(this.integrationType);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 3);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.time !== null && this.time !== undefined) {
    output.writeFieldBegin('time', Thrift.Type.STRUCT, 4);
    this.time.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_refreshPosCacheSince_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownLocationIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.integrationNotAccessibleException = null;
  this.unsupportedPosIntegrationTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.IntegrationNotAccessibleException) {
    this.integrationNotAccessibleException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.integrationNotAccessibleException !== undefined && args.integrationNotAccessibleException !== null) {
      this.integrationNotAccessibleException = args.integrationNotAccessibleException;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
  }
};
IntegrationService.IntegrationService_refreshPosCacheSince_result.prototype = {};
IntegrationService.IntegrationService_refreshPosCacheSince_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.integrationNotAccessibleException = new IntegrationExceptions.IntegrationNotAccessibleException();
        this.integrationNotAccessibleException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_refreshPosCacheSince_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_refreshPosCacheSince_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.integrationNotAccessibleException !== null && this.integrationNotAccessibleException !== undefined) {
    output.writeFieldBegin('integrationNotAccessibleException', Thrift.Type.STRUCT, 7);
    this.integrationNotAccessibleException.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 6);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_adminListAllIntegrations_args = function(args) {
  this.actor = null;
  this.integrationType = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.integrationType !== undefined && args.integrationType !== null) {
      this.integrationType = args.integrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field integrationType is unset!');
    }
  }
};
IntegrationService.IntegrationService_adminListAllIntegrations_args.prototype = {};
IntegrationService.IntegrationService_adminListAllIntegrations_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.integrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_adminListAllIntegrations_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_adminListAllIntegrations_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.integrationType !== null && this.integrationType !== undefined) {
    output.writeFieldBegin('integrationType', Thrift.Type.I32, 2);
    output.writeI32(this.integrationType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_adminListAllIntegrations_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unsupportedPosIntegrationTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new IntegrationModel.ListIntegrationResult(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
  }
};
IntegrationService.IntegrationService_adminListAllIntegrations_result.prototype = {};
IntegrationService.IntegrationService_adminListAllIntegrations_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new IntegrationModel.ListIntegrationResult();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_adminListAllIntegrations_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_adminListAllIntegrations_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 7);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.posIntegrationType = null;
  this.authorizationCode = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.posIntegrationType !== undefined && args.posIntegrationType !== null) {
      this.posIntegrationType = args.posIntegrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posIntegrationType is unset!');
    }
    if (args.authorizationCode !== undefined && args.authorizationCode !== null) {
      this.authorizationCode = args.authorizationCode;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authorizationCode is unset!');
    }
  }
};
IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_args.prototype = {};
IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.posIntegrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.authorizationCode = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_authorizeAndListAccessiblePosLocations_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.posIntegrationType !== null && this.posIntegrationType !== undefined) {
    output.writeFieldBegin('posIntegrationType', Thrift.Type.I32, 3);
    output.writeI32(this.posIntegrationType);
    output.writeFieldEnd();
  }
  if (this.authorizationCode !== null && this.authorizationCode !== undefined) {
    output.writeFieldBegin('authorizationCode', Thrift.Type.STRING, 4);
    output.writeString(this.authorizationCode);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.failedToExchangeOAuthToken = null;
  this.locationAlreadyHasIntegrationException = null;
  this.unsupportedPosIntegrationTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.FailedToExchangeOAuthToken) {
    this.failedToExchangeOAuthToken = args;
    return;
  }
  if (args instanceof IntegrationExceptions.LocationAlreadyHasIntegrationException) {
    this.locationAlreadyHasIntegrationException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [IntegrationModel.PosLocation]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.failedToExchangeOAuthToken !== undefined && args.failedToExchangeOAuthToken !== null) {
      this.failedToExchangeOAuthToken = args.failedToExchangeOAuthToken;
    }
    if (args.locationAlreadyHasIntegrationException !== undefined && args.locationAlreadyHasIntegrationException !== null) {
      this.locationAlreadyHasIntegrationException = args.locationAlreadyHasIntegrationException;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
  }
};
IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_result.prototype = {};
IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.success = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new IntegrationModel.PosLocation();
          elem14.read(input);
          this.success.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.failedToExchangeOAuthToken = new IntegrationExceptions.FailedToExchangeOAuthToken();
        this.failedToExchangeOAuthToken.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAlreadyHasIntegrationException = new IntegrationExceptions.LocationAlreadyHasIntegrationException();
        this.locationAlreadyHasIntegrationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_authorizeAndListAccessiblePosLocations_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter15 in this.success)
    {
      if (this.success.hasOwnProperty(iter15))
      {
        iter15 = this.success[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.failedToExchangeOAuthToken !== null && this.failedToExchangeOAuthToken !== undefined) {
    output.writeFieldBegin('failedToExchangeOAuthToken', Thrift.Type.STRUCT, 6);
    this.failedToExchangeOAuthToken.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAlreadyHasIntegrationException !== null && this.locationAlreadyHasIntegrationException !== undefined) {
    output.writeFieldBegin('locationAlreadyHasIntegrationException', Thrift.Type.STRUCT, 7);
    this.locationAlreadyHasIntegrationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 8);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.posIntegrationType = null;
  this.merchantId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.posIntegrationType !== undefined && args.posIntegrationType !== null) {
      this.posIntegrationType = args.posIntegrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posIntegrationType is unset!');
    }
    if (args.merchantId !== undefined && args.merchantId !== null) {
      this.merchantId = args.merchantId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field merchantId is unset!');
    }
  }
};
IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_args.prototype = {};
IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.posIntegrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.merchantId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_associateAuthorizedLocationWithMerchant_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.posIntegrationType !== null && this.posIntegrationType !== undefined) {
    output.writeFieldBegin('posIntegrationType', Thrift.Type.I32, 3);
    output.writeI32(this.posIntegrationType);
    output.writeFieldEnd();
  }
  if (this.merchantId !== null && this.merchantId !== undefined) {
    output.writeFieldBegin('merchantId', Thrift.Type.STRING, 4);
    output.writeString(this.merchantId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.locationAlreadyHasIntegrationException = null;
  this.integrationNotAccessibleException = null;
  this.unsupportedPosIntegrationTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.LocationAlreadyHasIntegrationException) {
    this.locationAlreadyHasIntegrationException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.IntegrationNotAccessibleException) {
    this.integrationNotAccessibleException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.locationAlreadyHasIntegrationException !== undefined && args.locationAlreadyHasIntegrationException !== null) {
      this.locationAlreadyHasIntegrationException = args.locationAlreadyHasIntegrationException;
    }
    if (args.integrationNotAccessibleException !== undefined && args.integrationNotAccessibleException !== null) {
      this.integrationNotAccessibleException = args.integrationNotAccessibleException;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
  }
};
IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_result.prototype = {};
IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAlreadyHasIntegrationException = new IntegrationExceptions.LocationAlreadyHasIntegrationException();
        this.locationAlreadyHasIntegrationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.integrationNotAccessibleException = new IntegrationExceptions.IntegrationNotAccessibleException();
        this.integrationNotAccessibleException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_associateAuthorizedLocationWithMerchant_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAlreadyHasIntegrationException !== null && this.locationAlreadyHasIntegrationException !== undefined) {
    output.writeFieldBegin('locationAlreadyHasIntegrationException', Thrift.Type.STRUCT, 6);
    this.locationAlreadyHasIntegrationException.write(output);
    output.writeFieldEnd();
  }
  if (this.integrationNotAccessibleException !== null && this.integrationNotAccessibleException !== undefined) {
    output.writeFieldBegin('integrationNotAccessibleException', Thrift.Type.STRUCT, 7);
    this.integrationNotAccessibleException.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 8);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_getOmnivoreIntegrationState_args = function(args) {
  this.actor = null;
  this.locationId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
  }
};
IntegrationService.IntegrationService_getOmnivoreIntegrationState_args.prototype = {};
IntegrationService.IntegrationService_getOmnivoreIntegrationState_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_getOmnivoreIntegrationState_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_getOmnivoreIntegrationState_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_getOmnivoreIntegrationState_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new IntegrationModel.OmnivoreIntegrationState(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
IntegrationService.IntegrationService_getOmnivoreIntegrationState_result.prototype = {};
IntegrationService.IntegrationService_getOmnivoreIntegrationState_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new IntegrationModel.OmnivoreIntegrationState();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_getOmnivoreIntegrationState_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_getOmnivoreIntegrationState_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addOmnivoreIntegration_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.omnivoreLocationId = null;
  this.posType = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.omnivoreLocationId !== undefined && args.omnivoreLocationId !== null) {
      this.omnivoreLocationId = args.omnivoreLocationId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field omnivoreLocationId is unset!');
    }
    if (args.posType !== undefined && args.posType !== null) {
      this.posType = args.posType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posType is unset!');
    }
  }
};
IntegrationService.IntegrationService_addOmnivoreIntegration_args.prototype = {};
IntegrationService.IntegrationService_addOmnivoreIntegration_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.omnivoreLocationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.posType = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addOmnivoreIntegration_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addOmnivoreIntegration_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.omnivoreLocationId !== null && this.omnivoreLocationId !== undefined) {
    output.writeFieldBegin('omnivoreLocationId', Thrift.Type.STRING, 3);
    output.writeString(this.omnivoreLocationId);
    output.writeFieldEnd();
  }
  if (this.posType !== null && this.posType !== undefined) {
    output.writeFieldBegin('posType', Thrift.Type.STRING, 4);
    output.writeString(this.posType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addOmnivoreIntegration_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.locationAlreadyHasIntegrationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.LocationAlreadyHasIntegrationException) {
    this.locationAlreadyHasIntegrationException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.locationAlreadyHasIntegrationException !== undefined && args.locationAlreadyHasIntegrationException !== null) {
      this.locationAlreadyHasIntegrationException = args.locationAlreadyHasIntegrationException;
    }
  }
};
IntegrationService.IntegrationService_addOmnivoreIntegration_result.prototype = {};
IntegrationService.IntegrationService_addOmnivoreIntegration_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAlreadyHasIntegrationException = new IntegrationExceptions.LocationAlreadyHasIntegrationException();
        this.locationAlreadyHasIntegrationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addOmnivoreIntegration_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addOmnivoreIntegration_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAlreadyHasIntegrationException !== null && this.locationAlreadyHasIntegrationException !== undefined) {
    output.writeFieldBegin('locationAlreadyHasIntegrationException', Thrift.Type.STRUCT, 6);
    this.locationAlreadyHasIntegrationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addSpotOnIntegration_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.spotOnLocationId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.spotOnLocationId !== undefined && args.spotOnLocationId !== null) {
      this.spotOnLocationId = args.spotOnLocationId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field spotOnLocationId is unset!');
    }
  }
};
IntegrationService.IntegrationService_addSpotOnIntegration_args.prototype = {};
IntegrationService.IntegrationService_addSpotOnIntegration_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.spotOnLocationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addSpotOnIntegration_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addSpotOnIntegration_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.spotOnLocationId !== null && this.spotOnLocationId !== undefined) {
    output.writeFieldBegin('spotOnLocationId', Thrift.Type.STRING, 3);
    output.writeString(this.spotOnLocationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addSpotOnIntegration_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.locationAlreadyHasIntegrationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.LocationAlreadyHasIntegrationException) {
    this.locationAlreadyHasIntegrationException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.locationAlreadyHasIntegrationException !== undefined && args.locationAlreadyHasIntegrationException !== null) {
      this.locationAlreadyHasIntegrationException = args.locationAlreadyHasIntegrationException;
    }
  }
};
IntegrationService.IntegrationService_addSpotOnIntegration_result.prototype = {};
IntegrationService.IntegrationService_addSpotOnIntegration_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAlreadyHasIntegrationException = new IntegrationExceptions.LocationAlreadyHasIntegrationException();
        this.locationAlreadyHasIntegrationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addSpotOnIntegration_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addSpotOnIntegration_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAlreadyHasIntegrationException !== null && this.locationAlreadyHasIntegrationException !== undefined) {
    output.writeFieldBegin('locationAlreadyHasIntegrationException', Thrift.Type.STRUCT, 6);
    this.locationAlreadyHasIntegrationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addHeartlandIntegration_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.heartlandApiKey = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.heartlandApiKey !== undefined && args.heartlandApiKey !== null) {
      this.heartlandApiKey = args.heartlandApiKey;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field heartlandApiKey is unset!');
    }
  }
};
IntegrationService.IntegrationService_addHeartlandIntegration_args.prototype = {};
IntegrationService.IntegrationService_addHeartlandIntegration_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.heartlandApiKey = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addHeartlandIntegration_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addHeartlandIntegration_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.heartlandApiKey !== null && this.heartlandApiKey !== undefined) {
    output.writeFieldBegin('heartlandApiKey', Thrift.Type.STRING, 3);
    output.writeString(this.heartlandApiKey);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addHeartlandIntegration_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.locationAlreadyHasIntegrationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.LocationAlreadyHasIntegrationException) {
    this.locationAlreadyHasIntegrationException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.locationAlreadyHasIntegrationException !== undefined && args.locationAlreadyHasIntegrationException !== null) {
      this.locationAlreadyHasIntegrationException = args.locationAlreadyHasIntegrationException;
    }
  }
};
IntegrationService.IntegrationService_addHeartlandIntegration_result.prototype = {};
IntegrationService.IntegrationService_addHeartlandIntegration_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAlreadyHasIntegrationException = new IntegrationExceptions.LocationAlreadyHasIntegrationException();
        this.locationAlreadyHasIntegrationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addHeartlandIntegration_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addHeartlandIntegration_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAlreadyHasIntegrationException !== null && this.locationAlreadyHasIntegrationException !== undefined) {
    output.writeFieldBegin('locationAlreadyHasIntegrationException', Thrift.Type.STRUCT, 6);
    this.locationAlreadyHasIntegrationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addCloverIntegration_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.cloverMerchantId = null;
  this.authorizationCode = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.cloverMerchantId !== undefined && args.cloverMerchantId !== null) {
      this.cloverMerchantId = args.cloverMerchantId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field cloverMerchantId is unset!');
    }
    if (args.authorizationCode !== undefined && args.authorizationCode !== null) {
      this.authorizationCode = args.authorizationCode;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authorizationCode is unset!');
    }
  }
};
IntegrationService.IntegrationService_addCloverIntegration_args.prototype = {};
IntegrationService.IntegrationService_addCloverIntegration_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.cloverMerchantId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.authorizationCode = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addCloverIntegration_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addCloverIntegration_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.cloverMerchantId !== null && this.cloverMerchantId !== undefined) {
    output.writeFieldBegin('cloverMerchantId', Thrift.Type.STRING, 3);
    output.writeString(this.cloverMerchantId);
    output.writeFieldEnd();
  }
  if (this.authorizationCode !== null && this.authorizationCode !== undefined) {
    output.writeFieldBegin('authorizationCode', Thrift.Type.STRING, 4);
    output.writeString(this.authorizationCode);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_addCloverIntegration_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.locationAlreadyHasIntegrationException = null;
  this.invalidAuthorizationCodeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.LocationAlreadyHasIntegrationException) {
    this.locationAlreadyHasIntegrationException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.InvalidAuthorizationCodeException) {
    this.invalidAuthorizationCodeException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.locationAlreadyHasIntegrationException !== undefined && args.locationAlreadyHasIntegrationException !== null) {
      this.locationAlreadyHasIntegrationException = args.locationAlreadyHasIntegrationException;
    }
    if (args.invalidAuthorizationCodeException !== undefined && args.invalidAuthorizationCodeException !== null) {
      this.invalidAuthorizationCodeException = args.invalidAuthorizationCodeException;
    }
  }
};
IntegrationService.IntegrationService_addCloverIntegration_result.prototype = {};
IntegrationService.IntegrationService_addCloverIntegration_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAlreadyHasIntegrationException = new IntegrationExceptions.LocationAlreadyHasIntegrationException();
        this.locationAlreadyHasIntegrationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalidAuthorizationCodeException = new IntegrationExceptions.InvalidAuthorizationCodeException();
        this.invalidAuthorizationCodeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_addCloverIntegration_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_addCloverIntegration_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAlreadyHasIntegrationException !== null && this.locationAlreadyHasIntegrationException !== undefined) {
    output.writeFieldBegin('locationAlreadyHasIntegrationException', Thrift.Type.STRUCT, 6);
    this.locationAlreadyHasIntegrationException.write(output);
    output.writeFieldEnd();
  }
  if (this.invalidAuthorizationCodeException !== null && this.invalidAuthorizationCodeException !== undefined) {
    output.writeFieldBegin('invalidAuthorizationCodeException', Thrift.Type.STRUCT, 7);
    this.invalidAuthorizationCodeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_retrieveAccessToken_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.integrationType = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.integrationType !== undefined && args.integrationType !== null) {
      this.integrationType = args.integrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field integrationType is unset!');
    }
  }
};
IntegrationService.IntegrationService_retrieveAccessToken_args.prototype = {};
IntegrationService.IntegrationService_retrieveAccessToken_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.integrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_retrieveAccessToken_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_retrieveAccessToken_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.integrationType !== null && this.integrationType !== undefined) {
    output.writeFieldBegin('integrationType', Thrift.Type.I32, 3);
    output.writeI32(this.integrationType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_retrieveAccessToken_result = function(args) {
  this.success = null;
  this.integrationNotAccessibleException = null;
  if (args instanceof IntegrationExceptions.IntegrationNotAccessibleException) {
    this.integrationNotAccessibleException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.integrationNotAccessibleException !== undefined && args.integrationNotAccessibleException !== null) {
      this.integrationNotAccessibleException = args.integrationNotAccessibleException;
    }
  }
};
IntegrationService.IntegrationService_retrieveAccessToken_result.prototype = {};
IntegrationService.IntegrationService_retrieveAccessToken_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.integrationNotAccessibleException = new IntegrationExceptions.IntegrationNotAccessibleException();
        this.integrationNotAccessibleException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_retrieveAccessToken_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_retrieveAccessToken_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.integrationNotAccessibleException !== null && this.integrationNotAccessibleException !== undefined) {
    output.writeFieldBegin('integrationNotAccessibleException', Thrift.Type.STRUCT, 1);
    this.integrationNotAccessibleException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.merchantId = null;
  this.integrationType = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.merchantId !== undefined && args.merchantId !== null) {
      this.merchantId = args.merchantId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field merchantId is unset!');
    }
    if (args.integrationType !== undefined && args.integrationType !== null) {
      this.integrationType = args.integrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field integrationType is unset!');
    }
  }
};
IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_args.prototype = {};
IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.merchantId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.integrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_retrieveAccessTokenForMultiConnectionType_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.merchantId !== null && this.merchantId !== undefined) {
    output.writeFieldBegin('merchantId', Thrift.Type.STRING, 3);
    output.writeString(this.merchantId);
    output.writeFieldEnd();
  }
  if (this.integrationType !== null && this.integrationType !== undefined) {
    output.writeFieldBegin('integrationType', Thrift.Type.I32, 4);
    output.writeI32(this.integrationType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_result = function(args) {
  this.success = null;
  this.integrationNotAccessibleException = null;
  if (args instanceof IntegrationExceptions.IntegrationNotAccessibleException) {
    this.integrationNotAccessibleException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = args.success;
    }
    if (args.integrationNotAccessibleException !== undefined && args.integrationNotAccessibleException !== null) {
      this.integrationNotAccessibleException = args.integrationNotAccessibleException;
    }
  }
};
IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_result.prototype = {};
IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRING) {
        this.success = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.integrationNotAccessibleException = new IntegrationExceptions.IntegrationNotAccessibleException();
        this.integrationNotAccessibleException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_retrieveAccessTokenForMultiConnectionType_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRING, 0);
    output.writeString(this.success);
    output.writeFieldEnd();
  }
  if (this.integrationNotAccessibleException !== null && this.integrationNotAccessibleException !== undefined) {
    output.writeFieldBegin('integrationNotAccessibleException', Thrift.Type.STRUCT, 1);
    this.integrationNotAccessibleException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_triggerMenuSyncJob_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.integrationType = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.integrationType !== undefined && args.integrationType !== null) {
      this.integrationType = args.integrationType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field integrationType is unset!');
    }
  }
};
IntegrationService.IntegrationService_triggerMenuSyncJob_args.prototype = {};
IntegrationService.IntegrationService_triggerMenuSyncJob_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.integrationType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_triggerMenuSyncJob_args.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_triggerMenuSyncJob_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.integrationType !== null && this.integrationType !== undefined) {
    output.writeFieldBegin('integrationType', Thrift.Type.I32, 3);
    output.writeI32(this.integrationType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationService_triggerMenuSyncJob_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.unsupportedPosIntegrationTypeException = null;
  this.unknownCredentialsException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof IntegrationExceptions.UnsupportedPosIntegrationTypeException) {
    this.unsupportedPosIntegrationTypeException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownCredentialsException) {
    this.unknownCredentialsException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.unsupportedPosIntegrationTypeException !== undefined && args.unsupportedPosIntegrationTypeException !== null) {
      this.unsupportedPosIntegrationTypeException = args.unsupportedPosIntegrationTypeException;
    }
    if (args.unknownCredentialsException !== undefined && args.unknownCredentialsException !== null) {
      this.unknownCredentialsException = args.unknownCredentialsException;
    }
  }
};
IntegrationService.IntegrationService_triggerMenuSyncJob_result.prototype = {};
IntegrationService.IntegrationService_triggerMenuSyncJob_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unsupportedPosIntegrationTypeException = new IntegrationExceptions.UnsupportedPosIntegrationTypeException();
        this.unsupportedPosIntegrationTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownCredentialsException = new AccountsExceptions.UnknownCredentialsException();
        this.unknownCredentialsException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationService.IntegrationService_triggerMenuSyncJob_result.prototype.write = function(output) {
  output.writeStructBegin('IntegrationService_triggerMenuSyncJob_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unsupportedPosIntegrationTypeException !== null && this.unsupportedPosIntegrationTypeException !== undefined) {
    output.writeFieldBegin('unsupportedPosIntegrationTypeException', Thrift.Type.STRUCT, 6);
    this.unsupportedPosIntegrationTypeException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownCredentialsException !== null && this.unknownCredentialsException !== undefined) {
    output.writeFieldBegin('unknownCredentialsException', Thrift.Type.STRUCT, 7);
    this.unknownCredentialsException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationService.IntegrationServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
IntegrationService.IntegrationServiceClient.prototype = {};
IntegrationService.IntegrationServiceClient.prototype.getIntegrationState = function(actor, locationId, integrationType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getIntegrationState(pendingError, actor, locationId, integrationType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getIntegrationState();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_getIntegrationState = function(pendingError, actor, locationId, integrationType, callback) {
  this.output.writeMessageBegin('getIntegrationState', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_getIntegrationState_args();
  args.actor = actor;
  args.locationId = locationId;
  args.integrationType = integrationType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getIntegrationState() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_getIntegrationState = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_getIntegrationState_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getIntegrationState failed: unknown result';
};
IntegrationService.IntegrationServiceClient.prototype.removeIntegration = function(actor, locationId, integrationType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_removeIntegration(pendingError, actor, locationId, integrationType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_removeIntegration();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_removeIntegration = function(pendingError, actor, locationId, integrationType, callback) {
  this.output.writeMessageBegin('removeIntegration', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_removeIntegration_args();
  args.actor = actor;
  args.locationId = locationId;
  args.integrationType = integrationType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_removeIntegration() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_removeIntegration = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_removeIntegration_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.removeIntegrationForMultiConnectionType = function(actor, locationId, merchantId, integrationType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_removeIntegrationForMultiConnectionType(pendingError, actor, locationId, merchantId, integrationType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_removeIntegrationForMultiConnectionType();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_removeIntegrationForMultiConnectionType = function(pendingError, actor, locationId, merchantId, integrationType, callback) {
  this.output.writeMessageBegin('removeIntegrationForMultiConnectionType', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_args();
  args.actor = actor;
  args.locationId = locationId;
  args.merchantId = merchantId;
  args.integrationType = integrationType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_removeIntegrationForMultiConnectionType() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_removeIntegrationForMultiConnectionType = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_removeIntegrationForMultiConnectionType_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.importSalesEntriesViaIntegration = function(actor, locationId, startTime, endTime, includePosMenuData, requireRealtimePosData, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_importSalesEntriesViaIntegration(pendingError, actor, locationId, startTime, endTime, includePosMenuData, requireRealtimePosData, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_importSalesEntriesViaIntegration();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_importSalesEntriesViaIntegration = function(pendingError, actor, locationId, startTime, endTime, includePosMenuData, requireRealtimePosData, callback) {
  this.output.writeMessageBegin('importSalesEntriesViaIntegration', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_importSalesEntriesViaIntegration_args();
  args.actor = actor;
  args.locationId = locationId;
  args.startTime = startTime;
  args.endTime = endTime;
  args.includePosMenuData = includePosMenuData;
  args.requireRealtimePosData = requireRealtimePosData;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_importSalesEntriesViaIntegration() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_importSalesEntriesViaIntegration = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_importSalesEntriesViaIntegration_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.locationDoesNotHaveIntegrationException) {
    throw result.locationDoesNotHaveIntegrationException;
  }
  if (null !== result.posHistoryLimitExceededException) {
    throw result.posHistoryLimitExceededException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'importSalesEntriesViaIntegration failed: unknown result';
};
IntegrationService.IntegrationServiceClient.prototype.addIntegrationThroughOAuthFlow = function(actor, locationId, posIntegrationType, authorizationCode, codeVerifier, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_addIntegrationThroughOAuthFlow(pendingError, actor, locationId, posIntegrationType, authorizationCode, codeVerifier, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_addIntegrationThroughOAuthFlow();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_addIntegrationThroughOAuthFlow = function(pendingError, actor, locationId, posIntegrationType, authorizationCode, codeVerifier, callback) {
  this.output.writeMessageBegin('addIntegrationThroughOAuthFlow', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_args();
  args.actor = actor;
  args.locationId = locationId;
  args.posIntegrationType = posIntegrationType;
  args.authorizationCode = authorizationCode;
  args.codeVerifier = codeVerifier;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_addIntegrationThroughOAuthFlow() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_addIntegrationThroughOAuthFlow = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_addIntegrationThroughOAuthFlow_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.locationAlreadyHasIntegrationException) {
    throw result.locationAlreadyHasIntegrationException;
  }
  if (null !== result.failedToExchangeOAuthToken) {
    throw result.failedToExchangeOAuthToken;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.refreshPosCacheSince = function(actor, integrationType, locationId, time, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_refreshPosCacheSince(pendingError, actor, integrationType, locationId, time, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_refreshPosCacheSince();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_refreshPosCacheSince = function(pendingError, actor, integrationType, locationId, time, callback) {
  this.output.writeMessageBegin('refreshPosCacheSince', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_refreshPosCacheSince_args();
  args.actor = actor;
  args.integrationType = integrationType;
  args.locationId = locationId;
  args.time = time;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_refreshPosCacheSince() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_refreshPosCacheSince = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_refreshPosCacheSince_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.integrationNotAccessibleException) {
    throw result.integrationNotAccessibleException;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.adminListAllIntegrations = function(actor, integrationType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_adminListAllIntegrations(pendingError, actor, integrationType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_adminListAllIntegrations();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_adminListAllIntegrations = function(pendingError, actor, integrationType, callback) {
  this.output.writeMessageBegin('adminListAllIntegrations', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_adminListAllIntegrations_args();
  args.actor = actor;
  args.integrationType = integrationType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_adminListAllIntegrations() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_adminListAllIntegrations = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_adminListAllIntegrations_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'adminListAllIntegrations failed: unknown result';
};
IntegrationService.IntegrationServiceClient.prototype.authorizeAndListAccessiblePosLocations = function(actor, locationId, posIntegrationType, authorizationCode, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_authorizeAndListAccessiblePosLocations(pendingError, actor, locationId, posIntegrationType, authorizationCode, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_authorizeAndListAccessiblePosLocations();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_authorizeAndListAccessiblePosLocations = function(pendingError, actor, locationId, posIntegrationType, authorizationCode, callback) {
  this.output.writeMessageBegin('authorizeAndListAccessiblePosLocations', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_args();
  args.actor = actor;
  args.locationId = locationId;
  args.posIntegrationType = posIntegrationType;
  args.authorizationCode = authorizationCode;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_authorizeAndListAccessiblePosLocations() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_authorizeAndListAccessiblePosLocations = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_authorizeAndListAccessiblePosLocations_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.failedToExchangeOAuthToken) {
    throw result.failedToExchangeOAuthToken;
  }
  if (null !== result.locationAlreadyHasIntegrationException) {
    throw result.locationAlreadyHasIntegrationException;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'authorizeAndListAccessiblePosLocations failed: unknown result';
};
IntegrationService.IntegrationServiceClient.prototype.associateAuthorizedLocationWithMerchant = function(actor, locationId, posIntegrationType, merchantId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_associateAuthorizedLocationWithMerchant(pendingError, actor, locationId, posIntegrationType, merchantId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_associateAuthorizedLocationWithMerchant();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_associateAuthorizedLocationWithMerchant = function(pendingError, actor, locationId, posIntegrationType, merchantId, callback) {
  this.output.writeMessageBegin('associateAuthorizedLocationWithMerchant', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_args();
  args.actor = actor;
  args.locationId = locationId;
  args.posIntegrationType = posIntegrationType;
  args.merchantId = merchantId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_associateAuthorizedLocationWithMerchant() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_associateAuthorizedLocationWithMerchant = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_associateAuthorizedLocationWithMerchant_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.locationAlreadyHasIntegrationException) {
    throw result.locationAlreadyHasIntegrationException;
  }
  if (null !== result.integrationNotAccessibleException) {
    throw result.integrationNotAccessibleException;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.getOmnivoreIntegrationState = function(actor, locationId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getOmnivoreIntegrationState(pendingError, actor, locationId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getOmnivoreIntegrationState();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_getOmnivoreIntegrationState = function(pendingError, actor, locationId, callback) {
  this.output.writeMessageBegin('getOmnivoreIntegrationState', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_getOmnivoreIntegrationState_args();
  args.actor = actor;
  args.locationId = locationId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getOmnivoreIntegrationState() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_getOmnivoreIntegrationState = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_getOmnivoreIntegrationState_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getOmnivoreIntegrationState failed: unknown result';
};
IntegrationService.IntegrationServiceClient.prototype.addOmnivoreIntegration = function(actor, locationId, omnivoreLocationId, posType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_addOmnivoreIntegration(pendingError, actor, locationId, omnivoreLocationId, posType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_addOmnivoreIntegration();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_addOmnivoreIntegration = function(pendingError, actor, locationId, omnivoreLocationId, posType, callback) {
  this.output.writeMessageBegin('addOmnivoreIntegration', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_addOmnivoreIntegration_args();
  args.actor = actor;
  args.locationId = locationId;
  args.omnivoreLocationId = omnivoreLocationId;
  args.posType = posType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_addOmnivoreIntegration() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_addOmnivoreIntegration = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_addOmnivoreIntegration_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.locationAlreadyHasIntegrationException) {
    throw result.locationAlreadyHasIntegrationException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.addSpotOnIntegration = function(actor, locationId, spotOnLocationId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_addSpotOnIntegration(pendingError, actor, locationId, spotOnLocationId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_addSpotOnIntegration();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_addSpotOnIntegration = function(pendingError, actor, locationId, spotOnLocationId, callback) {
  this.output.writeMessageBegin('addSpotOnIntegration', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_addSpotOnIntegration_args();
  args.actor = actor;
  args.locationId = locationId;
  args.spotOnLocationId = spotOnLocationId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_addSpotOnIntegration() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_addSpotOnIntegration = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_addSpotOnIntegration_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.locationAlreadyHasIntegrationException) {
    throw result.locationAlreadyHasIntegrationException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.addHeartlandIntegration = function(actor, locationId, heartlandApiKey, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_addHeartlandIntegration(pendingError, actor, locationId, heartlandApiKey, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_addHeartlandIntegration();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_addHeartlandIntegration = function(pendingError, actor, locationId, heartlandApiKey, callback) {
  this.output.writeMessageBegin('addHeartlandIntegration', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_addHeartlandIntegration_args();
  args.actor = actor;
  args.locationId = locationId;
  args.heartlandApiKey = heartlandApiKey;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_addHeartlandIntegration() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_addHeartlandIntegration = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_addHeartlandIntegration_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.locationAlreadyHasIntegrationException) {
    throw result.locationAlreadyHasIntegrationException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.addCloverIntegration = function(actor, locationId, cloverMerchantId, authorizationCode, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_addCloverIntegration(pendingError, actor, locationId, cloverMerchantId, authorizationCode, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_addCloverIntegration();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_addCloverIntegration = function(pendingError, actor, locationId, cloverMerchantId, authorizationCode, callback) {
  this.output.writeMessageBegin('addCloverIntegration', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_addCloverIntegration_args();
  args.actor = actor;
  args.locationId = locationId;
  args.cloverMerchantId = cloverMerchantId;
  args.authorizationCode = authorizationCode;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_addCloverIntegration() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_addCloverIntegration = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_addCloverIntegration_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.locationAlreadyHasIntegrationException) {
    throw result.locationAlreadyHasIntegrationException;
  }
  if (null !== result.invalidAuthorizationCodeException) {
    throw result.invalidAuthorizationCodeException;
  }
  return;
};
IntegrationService.IntegrationServiceClient.prototype.retrieveAccessToken = function(actor, locationId, integrationType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveAccessToken(pendingError, actor, locationId, integrationType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveAccessToken();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_retrieveAccessToken = function(pendingError, actor, locationId, integrationType, callback) {
  this.output.writeMessageBegin('retrieveAccessToken', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_retrieveAccessToken_args();
  args.actor = actor;
  args.locationId = locationId;
  args.integrationType = integrationType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveAccessToken() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_retrieveAccessToken = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_retrieveAccessToken_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.integrationNotAccessibleException) {
    throw result.integrationNotAccessibleException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveAccessToken failed: unknown result';
};
IntegrationService.IntegrationServiceClient.prototype.retrieveAccessTokenForMultiConnectionType = function(actor, locationId, merchantId, integrationType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveAccessTokenForMultiConnectionType(pendingError, actor, locationId, merchantId, integrationType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveAccessTokenForMultiConnectionType();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_retrieveAccessTokenForMultiConnectionType = function(pendingError, actor, locationId, merchantId, integrationType, callback) {
  this.output.writeMessageBegin('retrieveAccessTokenForMultiConnectionType', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_args();
  args.actor = actor;
  args.locationId = locationId;
  args.merchantId = merchantId;
  args.integrationType = integrationType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveAccessTokenForMultiConnectionType() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_retrieveAccessTokenForMultiConnectionType = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_retrieveAccessTokenForMultiConnectionType_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.integrationNotAccessibleException) {
    throw result.integrationNotAccessibleException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveAccessTokenForMultiConnectionType failed: unknown result';
};
IntegrationService.IntegrationServiceClient.prototype.triggerMenuSyncJob = function(actor, locationId, integrationType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_triggerMenuSyncJob(pendingError, actor, locationId, integrationType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_triggerMenuSyncJob();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

IntegrationService.IntegrationServiceClient.prototype.send_triggerMenuSyncJob = function(pendingError, actor, locationId, integrationType, callback) {
  this.output.writeMessageBegin('triggerMenuSyncJob', Thrift.MessageType.CALL, this.seqid);
  var args = new IntegrationService.IntegrationService_triggerMenuSyncJob_args();
  args.actor = actor;
  args.locationId = locationId;
  args.integrationType = integrationType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_triggerMenuSyncJob() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

IntegrationService.IntegrationServiceClient.prototype.recv_triggerMenuSyncJob = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new IntegrationService.IntegrationService_triggerMenuSyncJob_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.unsupportedPosIntegrationTypeException) {
    throw result.unsupportedPosIntegrationTypeException;
  }
  if (null !== result.unknownCredentialsException) {
    throw result.unknownCredentialsException;
  }
  return;
};
