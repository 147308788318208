import * as d3 from 'd3';

import { IValuesByCategoryName } from 'shared/models/Charts/IValuesByCategoryName';
import { IValuesByDataIdAndCategoryName } from 'shared/models/Charts/IValuesByDataIdAndCategoryName';

import { LinearScale } from 'shared/models/StackedBarChart/LinearScale';
import { OrdinalBandScale } from 'shared/models/StackedBarChart/OrdinalBandScale';

interface ICategoryStackSeriesDatum {
    [key : string] : number;
}

//////////////////////////////////////
// Linear Scale
//////////////////////////////////////
const getLinearScale = (
    minValue : number,
    maxValue : number,
    chartWidthOrHeight : number,
) : LinearScale => {
    const scale : d3.ScaleLinear<number, number> = d3.scaleLinear()
        .rangeRound([0, chartWidthOrHeight])
        .domain([minValue, maxValue]);

    return new LinearScale(scale);
};

const scaleValueLinear = (
    value : number,
    scaleLinear : LinearScale,
) : number => {
    const scaleDomainValues : {minimum : number, maximum : number} = scaleLinear.getDomainValues();

    if (value < scaleDomainValues.minimum || value > scaleDomainValues.maximum) {
        /**
         * @todo Anna Lee Barber (January 31, 2017)
         * Would be good to be able to communicate this happening to engineering (probably a case of an unexpected negative value)
         */
        // eslint-disable-next-line no-console
        console.warn('chart value not in specified domain (' + value + ' < ' + scaleDomainValues.minimum + ' || ' + value + ' > ' + scaleDomainValues.maximum + ')');

        // djangoApiManager.logJsError(
        //     'chart value not in specified domain (' + value + ' < ' + scaleDomainValues.minimum + ' || ' + value + ' > ' + scaleDomainValues.maximum + ')',
        //     null,
        //     new RuntimeException('chart value not in specified domain'),
        //     true
        // );

        return scaleDomainValues.minimum;
    }

    return scaleLinear.scaleLinear(value);
};

//////////////////////////////////////
// Ordinal Scale
//////////////////////////////////////
/**
 * Notes on d3.scaleBand
 * -- align() tells d3 how leftover unused space in the range is distributed.
 *      0 on a Y-axis means "to the bottom", 1 means all extra at the top, 0.5 splits the two evenly.
 *      here, 0 is specified on the assumption that bars in a bar chart should start at the top of the chart's svg with no padding
 * -- rangeRound() ensures integers are given for y-values, to avoid antialiasing
 */
const getOrdinalScale = (
    keys : Array<string>,
    minValue : number,
    maxValue : number,
) : OrdinalBandScale => {
    const scale : d3.ScaleBand<string> = d3.scaleBand()
        .domain(keys)
        .rangeRound([minValue, maxValue])
        .align(0);

    return new OrdinalBandScale(scale);
};

const scaleValueOrdinal = (
    keyName : string,
    scale : OrdinalBandScale,
) : number | null => {
    const value = scale.scaleOrdinal(keyName);

    if (typeof value === 'undefined') {
        return null;
    }

    return value;
};

//////////////////////////////////////
// Invert Scale
//////////////////////////////////////
const invertScaledValue = (
    scaledValue : number,
    scaleLinear : LinearScale,
) : number => {
    // TODO test function
    return scaleLinear.scaleLinear.invert(scaledValue);
};

//////////////////////////////////////
// Chart Data
//////////////////////////////////////
const getMaximumTotalCategoryValueFromDataValuesObject = (
    valuesByDataIdAndCategoryName : IValuesByDataIdAndCategoryName,
) : number => {
    const dataIds = Object.keys(valuesByDataIdAndCategoryName);

    const maxValue = dataIds.reduce(
        (previousMaxValue : number, currentDataId : string) => {
            const valuesByCategoryName : IValuesByCategoryName = valuesByDataIdAndCategoryName[currentDataId];

            const totalForId : number = Object.keys(valuesByCategoryName).reduce(
                (previousCategoryTotal : number, currentCategoryName : string) => {
                    const nullableValue : number | null = valuesByCategoryName[currentCategoryName];
                    const value : number = (nullableValue === null) ? 0 : nullableValue;

                    if (value >= 0) {
                        // if negative, do not calculate for max X-val display
                        return previousCategoryTotal + value;
                    }

                    return previousCategoryTotal;
                },
                0);

            return Math.max(previousMaxValue, totalForId);
        },
        0);

    return maxValue;
};

const getBarStackDataFromCategoryData = (
    valuesByCategoryName : IValuesByCategoryName,
    valuesAreNegative : boolean,
) : Array<d3.Series<ICategoryStackSeriesDatum, string>> => {
    const categoryNames : Array<string> = Object.keys(valuesByCategoryName);

    // for display purposes, values need to either be all positive or all negative -- if there is a negative value in positive set, set that value to 0 (or vice versa)
    const stack = d3.stack()
        .keys(categoryNames)
        .value((object, key) => {
            if (valuesAreNegative) {
                return Math.min(object[key], 0);
            }

            return Math.max(object[key], 0);
        })
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone);

    const nonNullableValuesByCategoryName : any = {};
    Object.keys(valuesByCategoryName).forEach(
        (categoryName : string) => {
            const nullableValue : number | null = valuesByCategoryName[categoryName];
            const value : number = (nullableValue === null) ? 0 : nullableValue;

            nonNullableValuesByCategoryName[categoryName] = value;
        }
    );

    return stack([nonNullableValuesByCategoryName]); // wrap as array neccessary for d3.stack()
};

export const utils = {
    getLinearScale,
    scaleValueLinear,
    getOrdinalScale,
    scaleValueOrdinal,
    invertScaledValue,
    getMaximumTotalCategoryValueFromDataValuesObject,
    getBarStackDataFromCategoryData,
};
