import { IStringValue } from 'api/Core/IStringValue';

export class InvoiceUploadId implements IStringValue {
    constructor (private readonly value : string) {}

    public getValue() : string {
        return this.value;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof InvoiceUploadId)) {
            return false;
        }

        return this.getValue() === other.getValue();
    }

    public toString() : string {
        return `{InvoiceUploadId: ${this.value}}`;
    }
}
