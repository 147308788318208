import { StringValueSet } from 'api/Core/StringValueSet';
import { OrderDateQueryType } from 'api/Ordering/model/OrderDateQueryType';
import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { LocationId } from 'api/Location/model/LocationId';
import { IDeliveryReport, IOrderingService } from 'api/Ordering/interfaces/IOrderingService';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { OrderingJSONToObjectSerializer } from 'api/Ordering/serializer/OrderingJSONToObjectSerializer';
import { OrderingObjectToJSONSerializer } from 'api/Ordering/serializer/OrderingObjectToJSONSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { DJANGO_API_UTC_TIMESTAMP_FORMAT } from 'shared/constants';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class OrderingServiceImpl implements IOrderingService {

    constructor (
        private readonly orderingJSONToObjectSerializer : OrderingJSONToObjectSerializer,
        private readonly orderingObjectToJSONSerializer : OrderingObjectToJSONSerializer
    ) { }

    public getDeliveryCountForLocation(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<number> {
        return AjaxUtils.ajaxGet(url('ordering:record:get_delivery_count', null, locationId.getValue(), null))
        .then((response) => {
            return response.count;
        });
    }

    public getDeliveriesForLocationInPeriod (
        userSessionId : UserSessionId,
        locationId : LocationId,
        periodStartInclusive : moment.Moment,
        periodEndExclusive : moment.Moment,
        dateQueryTypes : Set<OrderDateQueryType> = new Set(['DELIVERY']),
    ) : Promise<StringValueMap<DeliveryId, Delivery>> {
        if (dateQueryTypes.size === 0) {
            throw new RuntimeException('at least 1 date query type is required');
        }

        const queryParameters = {
            retailer_id: locationId.getValue(),
            start_time_inclusive: periodStartInclusive.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            end_time_exclusive: periodEndExclusive.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            date_range_types: Array.from(dateQueryTypes.values()).join('+'),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:retrieve_orders_in_date_range_request'), queryParameters)
        .then((response) => {
            return this.orderingJSONToObjectSerializer.getDeliveriesById(response);
        });
    }

    public getDeliveryMetadataForLocationInPeriod(
        userSessionId : UserSessionId,
        locationId : LocationId,
        dateQueryTypes : Set<OrderDateQueryType>,
        periodStartInclusive : moment.Moment,
        periodEndExclusive : moment.Moment
    ) {
        if (dateQueryTypes.size === 0) {
            throw new RuntimeException('at least 1 date query type is required');
        }

        const queryParameters = {
            retailer_id: locationId.getValue(),
            start_time_inclusive: periodStartInclusive.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            end_time_exclusive: periodEndExclusive.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            include_metadata_only: true,
            date_range_types: Array.from(dateQueryTypes.values()).join('+'),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:retrieve_orders_in_date_range_request'), queryParameters)
        .then((response) => {
            return this.orderingJSONToObjectSerializer.getDeliveryMetadataById(response);
        });
    }

    public recordNewOrderForLocation (
        locationId : LocationId,
        distributorId : DistributorId,
        datePlaced : moment.Moment,
        dateDelivered : moment.Moment,
        recorderName : string,
        dateRecorded : moment.Moment,
        userDefinedTotal : number | null,
        invoiceNumber : string | null,
        deliveryReport : IDeliveryReport | null,
    ) : Promise<DeliveryId> {

        const deliveryReportForRequest = deliveryReport === null ? null : {
            time_delivered: deliveryReport.dateDelivered.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            time_reported: deliveryReport.dateReported.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            reporter_user_id: deliveryReport.reporterUserId.getValue(),
        };

        const requestBody = {
            distributor_id: distributorId.getValue(),
            retailer_id: locationId.getValue(),
            distributor_reps: [],
            invoice_number: invoiceNumber,
            line_items: [],
            note_to_rep: '',
            note_to_self: '',
            purchase_order_number: '',
            order_hash: 'testhash',
            predicted_delivery_time: dateDelivered.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            price_adjustments_by_product_category_id: {},
            uncategorized_price_adjustments: [],
            recorder_name: recorderName,
            time_placed: datePlaced.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            time_recorded: dateRecorded.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            delivery_report: deliveryReportForRequest,
            user_defined_total: userDefinedTotal,
            approval_event: null,
            ready_for_approval_event: null,
            last_update_event: null,
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:order_detail'), requestBody)
        .then((response) => {
            return new DeliveryId(response.order_id);
        });
    }

    public getDeliveryAndDeliveryHash(
        deliveryId : DeliveryId,
    ) : Promise<{ delivery : Delivery, deliveryHash : string }> {
        const queryParameters = {
            order_id: deliveryId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:order_detail'), queryParameters)
        .then((response) => {
            return {
                delivery: this.orderingJSONToObjectSerializer.getDelivery(response.order_detail),
                deliveryHash: response.order_detail.order_hash,
            };
        });
    }

    public getDeliveriesById(
        deliveryIds : StringValueSet<DeliveryId>
    ) : Promise<StringValueMap<DeliveryId, Delivery>> {
        if (deliveryIds.size === 0) {
            return Promise.resolve(new StringValueMap<DeliveryId, Delivery>());
        }

        const deliveryIdValues : Array<string> = [];
        deliveryIds.forEach((deliveryId : DeliveryId) => {
            deliveryIdValues.push(deliveryId.getValue());
        });
        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:batch_order_detail'), deliveryIdValues)
        .then((response) => {
            return Promise.resolve(this.orderingJSONToObjectSerializer.getDeliveriesById(response));
        });
    }

    public updateDelivery(
        locationId : LocationId,
        deliveryId : DeliveryId,
        delivery : Delivery,
        deliveryHash : string,
    ) : Promise<string> {
        const queryParameters = {
            order_id: deliveryId.getValue(),
        };

        const postBody = this.orderingObjectToJSONSerializer.getJSONDeliveryFromDelivery(locationId, delivery, deliveryHash);
        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:order_detail'), postBody, queryParameters)
        .then((response) => {
            return response.order_hash;
        });
    }

    public deleteOrderRecord  (
        deliveryId : DeliveryId,
        deleteAttachedInvoices : boolean
    ) : Promise<void> {
        const queryParameters = {
            order_id: deliveryId.getValue(),
            delete_attached_invoices: deleteAttachedInvoices
        };

        return AjaxUtils.ajaxDelete(urlWithoutRetailerId('api:order_detail'), queryParameters);
    }

    public retrieveNumberOfInvoicesProcessedToDateFromLastBillingCharge(
        locationId : LocationId,
        periodEndExclusive : moment.Moment,
    ) : Promise<{ numberOfInvoicesProcessed : number, startTime : moment.Moment }> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            end_date_exclusive: periodEndExclusive.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:number_of_invoices_processed'), queryParameters)
        .then((response : { number_of_invoices_processed : number, start_for_date_range : string }) => {
            return {
                numberOfInvoicesProcessed: response.number_of_invoices_processed,
                startTime: moment.utc(response.start_for_date_range)
            };
        });
    }

    public addOrderItemsToCart (
        orderId: DeliveryId,
        option: 'overwrite' | 'sum' | 'ignore'
    ): Promise<void> {
        const queryParameters = {
            order_id: orderId.getValue(),
            option
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:add_order_items_to_cart'), null, queryParameters, "text");
    }
}
