import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { decimalToNumber } from 'shared/utils/decimalUtils';

const getTotalCostOfProductInOutput = (prepEvent : PrepEvent, productId : ProductId, product : Product) : number => {
    const productAmountInOutput = prepEvent.getOutputQuantityOfProductByProductId().get(productId);
    const outputPrice = prepEvent.getOutputPriceByProductId().get(productId);
    if (typeof productAmountInOutput === 'undefined') {
        throw new RuntimeException('unexpected productAmountInOutput is undefined');
    }
    if (typeof outputPrice === 'undefined') {
        throw new RuntimeException('unexpected outputPrice is undefined');
    }

    const packagingsAndMappings = product.getPackagingsAndMappings();

    const outputQuantityInPriceUnit = PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, productAmountInOutput, outputPrice.getUnit(), productId);

    return outputQuantityInPriceUnit.getQuantity() * decimalToNumber(outputPrice.getCost());
};

export const PrepEventUtils = {
    getTotalCostOfProductInOutput,
};
