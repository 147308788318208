import { IPOSIntegrationService } from 'api/Integration/interfaces/IPOSIntegrationService';
import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { IPosLocation } from 'api/Integration/model/PosLocation';
import { LocationId } from 'api/Location/model/LocationId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { ThunkAction } from 'redux-thunk';
import { IHeartlandIntegrationRowState } from 'shared/components/PosIntegration/HeartlandIntegrationRowReducers';
import { IExtraArguments } from 'shared/components/Provider';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { integrationService } from 'shared/lib/manager';
import { IAction, ISetShownAction } from 'shared/models/IAction';

export interface IHeartlandIntegrationModalStore {
    heartlandIntegrationRowState : IHeartlandIntegrationRowState;
}

export const ActionTypes = {
    SET_MODAL_SHOWN: 'HEARTLAND_INTEGRATION/SET_MODAL_SHOWN',
    SET_IS_LOADING: 'HEARTLAND_INTEGRATION/SET_IS_LOADING',
    SET_INTEGRATION_STATE: 'HEARTLAND_INTEGRATION/SET_INTEGRATION_STATE',
    SET_MODAL_IS_LOADING: 'HEARTLAND_INTEGRATION/SET_MODAL_IS_LOADING',
    SET_MODAL_ERROR: 'HEARTLAND_INTEGRATION/SET_MODAL_ERROR',
    SET_MODAL_ACCESS_TOKEN: 'HEARTLAND_INTEGRATION/SET_MODAL_ACCESS_TOKEN'
};

export namespace HeartlandIntegrationActionInterfaces {
    export interface ISetHeartlandLocations extends IAction {
        payload : {
            heartlandLocations : Array<IPosLocation>
        };
    }

    export interface ISetLocationSelectorShown extends IAction {
        payload : {
            isShown : boolean
        };
    }

    export interface ISetModalError extends IAction {
        payload : {
            message : string | null
        };
    }

    export interface ISetModalShown extends ISetShownAction {
    }

    export interface ISetIsLoading extends IAction {
        payload : {
            isLoading : boolean
        };
    }

    export interface ISetModalAccessToken extends IAction {
        payload : {
            accessToken : string | null
        };
    }

    export interface ISetIntegrationState extends IAction {
        payload : {
            integrationState : IntegrationState
        };
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        integrationService : IPOSIntegrationService;
    }

    export interface IThunkServices extends IExtraArguments {
        services : IServices;
    }
}

const setIntegrationState = (
    integrationState : IntegrationState
) : HeartlandIntegrationActionInterfaces.ISetIntegrationState => ({
    payload : {
        integrationState,
    },
    type: ActionTypes.SET_INTEGRATION_STATE,
});

const fetchExistingIntegration = (
    locationId : LocationId
) : ThunkAction<Promise<IntegrationState>, IHeartlandIntegrationModalStore, HeartlandIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) : Promise<IntegrationState> => {
        dispatch(setIsLoading(true));
        const session = extraArguments.services.userSessionReader.getSessionId();

        return extraArguments.services.integrationService.getIntegrationState('heartland', session, locationId)
        .then((data: Set<IntegrationState>) => {
            // Does not support Multi-connection
            const integrationState: IntegrationState = data.values().next().value;
            dispatch(setIntegrationState(integrationState));
            dispatch(setIsLoading(false));
            return integrationState;
        })
        .catch((e : Error) => {
            dispatch(setIsLoading(false));
            throw e;
        });
    };
};

const associateWithLocation = (
    locationId : LocationId,
    heartlandApiKey : string,
) : ThunkAction<Promise<void>, IHeartlandIntegrationModalStore, HeartlandIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {
        const session = extraArguments.services.userSessionReader.getSessionId();

        return integrationService.addHeartlandIntegration(
            session,
            locationId,
            heartlandApiKey
        ).then(() => {
            dispatch(fetchExistingIntegration(locationId));
        }).catch((error) => {
            throw error;
        });
    };
};

const disassociateWithLocation = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, IHeartlandIntegrationModalStore, HeartlandIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {
        const session = extraArguments.services.userSessionReader.getSessionId();

        return integrationService.removeIntegration(
            'heartland',
            session,
            locationId,
        ).then(() => {
            dispatch(fetchExistingIntegration(locationId));
        }).catch((error) => {
            throw error;
        });
    };
};

const retrieveAccessToken = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, IHeartlandIntegrationModalStore, HeartlandIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) : Promise<void> => {
        const session = extraArguments.services.userSessionReader.getSessionId();

        return integrationService.retrieveAccessToken(
            session,
            locationId,
            "heartland"
        ).then((accessToken) => {
            if (accessToken == null || accessToken === "") {
                accessToken = "Access token not found.";
            }

            dispatch(setModalAccessToken(accessToken));
        }).catch((error) => {
            throw error;
        });
    };
};

const setIsLoading = (
    isLoading : boolean
) : HeartlandIntegrationActionInterfaces.ISetIsLoading => ({
    payload : {
        isLoading
    },
    type: ActionTypes.SET_IS_LOADING,
});

const setModalShown = (
    isShown : boolean
) : HeartlandIntegrationActionInterfaces.ISetModalShown => ({
    payload : {
        isShown,
    },
    type : ActionTypes.SET_MODAL_SHOWN
});

const setModalError = (
    message : string | null
) : HeartlandIntegrationActionInterfaces.ISetModalError => ({
    payload : {
        message,
    },
    type : ActionTypes.SET_MODAL_ERROR
});

const setModalAccessToken = (
    accessToken : string | null
) : HeartlandIntegrationActionInterfaces.ISetModalAccessToken => ({
    payload : {
        accessToken,
    },
    type : ActionTypes.SET_MODAL_ACCESS_TOKEN
});

export const HeartlandIntegrationRowActions = {
    fetchExistingIntegration,
    setModalShown,
    setModalError,
    setModalAccessToken,
    retrieveAccessToken,
    associateWithLocation,
    disassociateWithLocation
};
