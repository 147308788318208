// TODO later rename this file to reflect what it actually does
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';

import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ALL_ITEMS_GROUP_NAME, GroupByOption } from 'shared/models/GroupByOption';
import { SortDirection } from 'shared/models/SortDirection';
import {
    defaultGroupNameComparator,
    getBrandAndNameStringForSort
} from 'shared/utils/productSortingFilteringAndGroupingUtils';
import { cleanPhrase, SortingFilteringAndGroupingUtil } from 'shared/utils/sortingFilteringAndGroupingUtils';
import { getDefaultTimestampGroupName } from 'shared/utils/timestampSortingFilteringAndGroupingUtils';

interface IUnassignedItemsSortingUtilExtraArgs {
    productsById : StringValueMap<ProductId, Product>;
    storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>;
    storageAreasById : StringValueMap<StorageAreaId, StorageArea>;
}

const isTokenInString = (str : string, token : string) : boolean => {
    return (str.indexOf(token) > -1);
};

const createProductStringForFilter = (product : Product, storageAreaNamesForProduct : Array<string>) : string => {
    const brand = cleanPhrase(product.getBrand());
    const name = cleanPhrase(product.getName());
    const category = cleanPhrase(product.getProductCategoryId());
    const type = cleanPhrase(product.getProductType());
    const sku = cleanPhrase(product.getSku());
    const note = cleanPhrase(product.getNote()); // right now tokenizing all words in note -- may want to update behavior in the future to only search entire phrase

    const cleanedStorageAreaNamesForProduct = storageAreaNamesForProduct.map((storageAreaName : string) => cleanPhrase(storageAreaName));

    const productStringArray = [brand, name, category, type, sku, note].concat(cleanedStorageAreaNamesForProduct);

    return productStringArray.join(' ');
};

const productFilterStringCache = new StringValueMap<ProductId, string>();
let lastProductsById = new StringValueMap<ProductId, Product>();

const cachedCreateProductStringForFilter = (
    productId : ProductId,
    productsById : StringValueMap<ProductId, Product>,
    storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>,
    storageAreasById : StringValueMap<StorageAreaId, StorageArea>,
) : string => {

    if (productsById !== lastProductsById) {
        lastProductsById = productsById;
        productFilterStringCache.clear();
    }

    if (productFilterStringCache.has(productId)) {
        return productFilterStringCache.getRequired(productId);
    }

    const product = productsById.getRequired(productId);
    const storageAreaIdSet = storageAreaIdSetByProductId.getRequired(productId);
    const storageAreaNamesForProduct : Array<string> = [];
    storageAreaIdSet.forEach((storageAreaId) => {
        const storageArea = storageAreasById.getRequired(storageAreaId);
        storageAreaNamesForProduct.push(storageArea.getName());
    });

    const productStringForFilter =  createProductStringForFilter(product, storageAreaNamesForProduct);
    productFilterStringCache.set(productId, productStringForFilter);

    return productStringForFilter;
};

const rowIdComparator = (productId1 : ProductId, productId2 : ProductId, columnSort : IColumnSorting, extraArgs : IUnassignedItemsSortingUtilExtraArgs) : number => {
    const direction = columnSort.direction;
    const sortedBy = columnSort.sortedBy;

    const product1 = extraArgs.productsById.getRequired(productId1);
    const product2 = extraArgs.productsById.getRequired(productId2);
    const storageAreaIdSet1 = extraArgs.storageAreaIdSetByProductId.getRequired(productId1);
    const storageAreaIdSet2 = extraArgs.storageAreaIdSetByProductId.getRequired(productId2);

    let comparisonResult : number;
    switch (sortedBy) {
        case 'ITEM_NAME':
            const nameString1 = getBrandAndNameStringForSort(product1);
            const nameString2 = getBrandAndNameStringForSort(product2);
            comparisonResult = nameString1.localeCompare(nameString2);
            break;
        case 'STORAGE_AREAS':
            comparisonResult = storageAreaIdSet1.size - storageAreaIdSet2.size; // TODO compare this correctly
            break;
        default:
            throw new RuntimeException('unhandled sort field');
    }

    if (direction === SortDirection.DESCENDING) {
        return comparisonResult * -1;
    }

    return comparisonResult;
};

const rowIdFilterFunction = (productId : ProductId, filterTerm : string | null, extraArgs : IUnassignedItemsSortingUtilExtraArgs) : boolean => {
    if (filterTerm === null || filterTerm === '') {
        return true;
    }

    const productsById = extraArgs.productsById;

    const productStringForSearch = cachedCreateProductStringForFilter(productId, productsById, extraArgs.storageAreaIdSetByProductId, extraArgs.storageAreasById);
    const filterTokens = filterTerm.split(' ');

    for (let i = 0; i < filterTokens.length; i++) {
        if (!isTokenInString(productStringForSearch, filterTokens[i])) {
            return false;
        }
    }

    return true;
};

const getGroupNameForRowId = (productId : ProductId, groupByOption : GroupByOption, extraArgs : IUnassignedItemsSortingUtilExtraArgs) : string => {
    const product = extraArgs.productsById.getRequired(productId);
    switch (groupByOption) {
        case GroupByOption.ALL_ITEMS:
            return ALL_ITEMS_GROUP_NAME;
        case GroupByOption.CATEGORY:
            return product.getProductCategoryId();
        case GroupByOption.ITEM_TYPE:
            return product.getProductType();
        case GroupByOption.LAST_EDITED:
            return getDefaultTimestampGroupName(product.getLastUpdateEvent().getTimestamp());
        default:
            throw new RuntimeException('unexpected groupByOption');
    }
};

const intermediateResultCacheIsValid = (rowIds : Array<ProductId>, lastRowIds : Array<ProductId>, extraArgs : IUnassignedItemsSortingUtilExtraArgs, lastExtraArgs : IUnassignedItemsSortingUtilExtraArgs) : boolean => {
    return ((rowIds === lastRowIds) &&
            (extraArgs.productsById === lastExtraArgs.productsById) &&
            (extraArgs.storageAreaIdSetByProductId === extraArgs.storageAreaIdSetByProductId) &&
            (extraArgs.storageAreasById === extraArgs.storageAreasById));
};

const groupNameComparator = (groupName1 : string, groupName2 : string, groupByOption : GroupByOption, extraArgs : IUnassignedItemsSortingUtilExtraArgs) => {
    return defaultGroupNameComparator(groupName1, groupName2, groupByOption);
};

export const unassignedItemsSortingUtil = new SortingFilteringAndGroupingUtil(
    rowIdComparator,
    rowIdFilterFunction,
    getGroupNameForRowId,
    groupNameComparator,
    intermediateResultCacheIsValid
);
