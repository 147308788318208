import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import 'shared/css/UserIcon.scss';

export interface IUserIconProps {
    onClick : () => void;
    showCloseIcon : boolean;
    firstName : string;
    lastName : string;
}

export class UserIcon extends React.Component<IUserIconProps, object> {
    public render() {
        const {
            onClick,
            showCloseIcon,
            firstName,
            lastName,
        } = this.props;

        let initials : string;
        if (firstName.length > 0 && lastName.length > 0) {
            initials = (firstName.trim()[0] + lastName.trim()[0]).toUpperCase();
        } else {
            initials = '';
        }
        const closeElement = <span className="bevicon bevico-close react-user-icon-bevicon" key={ 1 } />;
        const initialsElement = <span className="react-user-icon-initials" key={ 2 }>{ initials }</span>;

        return (
            <span
                className={ `react-user-icon ${showCloseIcon ? 'react-user-icon-close' : ''}` }
                onClick={ onClick }
            >
                <TransitionGroup
                    className="scale-user-icon-transition"
                >
                    <CSSTransition
                        appear={true}
                        exit={false}
                        classNames="scale-user-icon"
                        timeout={{enter: 0, exit: 0}}
                    >
                        { showCloseIcon ? closeElement : initialsElement }
                    </CSSTransition>
                </TransitionGroup>
            </span>
        );
    }
}
