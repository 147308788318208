import { ProductCount } from 'api/InventoryCount/model/ProductCount';
import { ProductCountEvent } from 'api/InventoryCount/model/ProductCountEvent';
import { Mappings } from 'api/Product/model/Mappings';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { decimalToNumber, numberToDecimal, numberToDecimalRoundingDown } from 'shared/utils/decimalUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

const getAppropriateDecimalScale = (n : number) : number => {
    const decimalDigits = n.toString().split('.')[1];
    if (decimalDigits) {
        return Math.min(decimalDigits.length, 6);
    }

    return 0;
};

const resolveProductCountUnit = (
    productCount : ProductCount,
    mappings : Mappings
) : ProductCount => {
    const count = productCount.getCount();

    if (count === null) {
        const resolvedProductQuantityUnit = PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit(1, productCount.getProductQuantityUnit()), mappings);
        return new ProductCount(null, resolvedProductQuantityUnit.getUnit());
    } else {
        return getProductCount(
            PackagingUtils.resolveProductQuantityUnit(getQuantityInUnit(productCount), mappings),
        );
    }
};

const getProductCount = (quantityInUnit : QuantityInUnit<any>) : ProductCount => {
    const scale = { value : getAppropriateDecimalScale(quantityInUnit.getQuantity()) };
    return new ProductCount(
        numberToDecimalRoundingDown(quantityInUnit.getQuantity(), scale),
        quantityInUnit.getUnit(),
    );
};

const getQuantityInUnit = (productCount : ProductCount) : QuantityInUnit<any> => {
    const count = productCount.getCount();
    if (count === null) {
        throw new RuntimeException('Cannot convert null ProductCount to QuantityInUnit');
    }

    return new QuantityInUnit(
        decimalToNumber(count),
        productCount.getProductQuantityUnit(),
    );
};

const convertProductCountUnit = (
    productCount : ProductCount,
    newProductQuantityUnit : ProductQuantityUnit,
    packagingsAndMappings : PackagingsAndMappings
) : ProductCount => {
    if (productCount.getProductQuantityUnit() === newProductQuantityUnit) {
        return productCount;
    }

    const count = productCount.getCount();
    if (count === null) {
        return new ProductCount(null, newProductQuantityUnit);
    }

    const covertedQuantityInUnit = PackagingUtils.convertProductQuantityToUnit(
        packagingsAndMappings,
        getQuantityInUnit(productCount),
        newProductQuantityUnit,
    );

    return getProductCount(covertedQuantityInUnit);
};

const sumProductCounts = (
    productCounts : Array<ProductCount>,
    packagingsAndMappings : PackagingsAndMappings
) : ProductCount => {
    const containerPackagingId = PackagingUtils.getContainerPackagingId(packagingsAndMappings.getPackaging());
    let totalProductCountValue : number | null = null;

    productCounts.forEach((productCount) => {
        const count = productCount.getCount();

        if (count !== null) {
            totalProductCountValue = totalProductCountValue || 0;
            const covertedQuantityInUnit = PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, getQuantityInUnit(productCount), containerPackagingId);
            totalProductCountValue = totalProductCountValue + covertedQuantityInUnit.getQuantity();
        }
    });

    return new ProductCount(
        (totalProductCountValue === null) ? null : numberToDecimalRoundingDown(totalProductCountValue, { value : getAppropriateDecimalScale(totalProductCountValue) }),
        containerPackagingId
    );
};

const isProductCountEventEmpty = (
    productCountEvent : ProductCountEvent
) : boolean => {
    for (const productCount of productCountEvent.getProductCounts()) {
        if (productCount.getCount() !== null) {
            return false;
        }
    }
    return true;
};

const getTotalProductCountFromProductCountEvent = (
    productCountEvent : ProductCountEvent,
    product : Product,
    productId? : ProductId,
) :  ProductCount => {
    const resolvedProductCountEvent = resolveProductCountEvent(productCountEvent, product);
    const preferredCountUnit = getPreferredCountUnitForProductCount(resolvedProductCountEvent, product);
    const productCounts = resolvedProductCountEvent.getProductCounts();
    if (productCounts.length === 0) {
        throw new RuntimeException('unexpected product count event found with no product counts ' + productId);
    }

    const packagingsAndMappings = product.getPackagingsAndMappings();

    let totalCount = 0;
    productCounts.forEach((productCount) => {
        const count = productCount.getCount();
        if (count) {
            const convertedCount = PackagingUtils.convertProductQuantityToUnit(
                packagingsAndMappings,
                new QuantityInUnit(decimalToNumber(count), productCount.getProductQuantityUnit()),
                preferredCountUnit,
                productId,
            );
            totalCount += convertedCount.getQuantity();
        }
    });

    return new ProductCount(
        numberToDecimal(totalCount),
        preferredCountUnit
    );
};

const resolveProductCountEvent = (
    productCountEvent : ProductCountEvent,
    product : Product
) => {
    const resolvedProductCounts : Array<ProductCount> = [];
    productCountEvent.getProductCounts().forEach((productCount) => {
        resolvedProductCounts.push(
            productCountUtils.resolveProductCountUnit(productCount, product.getPackagingsAndMappings().getMappings())
        );
    });
    return new ProductCountEvent(resolvedProductCounts, productCountEvent.getUserAccountIdAndTimestamp());
};

const getPreferredCountUnitForProductCount = (
    productCountEvent : ProductCountEvent,
    product : Product
) : ProductQuantityUnit => {
    let hasMultipleUnits = false;
    let commonUnit : ProductQuantityUnit | null = null;
    let selectedCountUnit : ProductQuantityUnit | null = null;
    productCountEvent.getProductCounts().forEach((productCount : ProductCount) => {
        if (commonUnit === null) {
            commonUnit = productCount.getProductQuantityUnit();
        } else if (!PackagingUtils.productQuantityUnitsAreEqual(commonUnit, productCount.getProductQuantityUnit())) {
            hasMultipleUnits = true;
        }

        if (productCount.getCount() === null) {
            if (selectedCountUnit !== null) {
                throw new RuntimeException('unexpected product count event found with multiple empty product counts');
            }
            selectedCountUnit = productCount.getProductQuantityUnit();
        }
    });

    if (selectedCountUnit !== null) {
        return selectedCountUnit;
    }

    return (hasMultipleUnits || commonUnit === null) ? product.getPreferredReportingUnit() : commonUnit;
};

const getSelectedCountUnitForProductCount = (
    productCountEvent : ProductCountEvent
) : ProductQuantityUnit | null => {
    const selectedCount = productCountEvent.getProductCounts().find((productCount) => productCount.getCount() === null);
    return typeof selectedCount === 'undefined' ? null : selectedCount.getProductQuantityUnit();
};

export const productCountUtils = {
    getQuantityInUnit,
    sumProductCounts,
    convertProductCountUnit,
    resolveProductCountUnit,
    resolveProductCountEvent,
    isProductCountEventEmpty,
    getTotalProductCountFromProductCountEvent,
    getPreferredCountUnitForProductCount,
    getSelectedCountUnitForProductCount,
};
