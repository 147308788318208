import React from 'react';
import MediaQuery from 'react-responsive';

import { StringValueSet } from 'api/Core/StringValueSet';
import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { Product } from 'api/Product/model/Product';

import { CheckBox } from 'shared/components/CheckBox';
import { ContextMenu } from 'shared/components/ContextMenu';
import { Pill } from 'shared/components/Pill';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { AddToStorageAreasDropdown, IAddToStorageAreasDropdownProps } from '../AddToStorageAreasDropdown';

import 'apps/InventoryCount/css/UnassignedItemRow.scss';

import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';
import { ProductName } from 'shared/components/ProductName/ProductName';

export interface IUnassignedItemRowProps {
    readonly product : Product;
    readonly isActive : boolean;
    readonly isDeleted : boolean;
    readonly assignedStorageAreaIds : StringValueSet<StorageAreaId>;
    readonly onRemoveStorageAreaClick : (storageAreaId : StorageAreaId) => void;
    readonly addToStorageAreasDropdownProps : IAddToStorageAreasDropdownProps;
    readonly menuIsShown : boolean;
    readonly isSelected : boolean;
    readonly setMenuIsShown : (isShown : boolean) => void;
    readonly onSetProductAsInactiveClick : () => void;
    readonly onDeleteProductClick : () => void;
    readonly setIsSelected : (isSelected : boolean) => void;
}

export class UnassignedItemRow extends React.Component<IUnassignedItemRowProps, object> {
    private readonly classNamePrefix = 'unassigned-item-row';

    public render() {
        const {
            product,
            isActive,
            isDeleted,
            addToStorageAreasDropdownProps,
            menuIsShown,
            isSelected,
            setMenuIsShown,
            onSetProductAsInactiveClick,
            onDeleteProductClick,
        } = this.props;

        const checkBoxState = isSelected ? CheckBoxTriState.Checked : CheckBoxTriState.Unchecked;

        return (
            <div>
                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                    <div
                        className={ `${ this.classNamePrefix }-container light-table-row col-row cell-plr-sm ${ isActive ? '' : 'archived-item' } ${ isDeleted ? 'deleted-item' : '' }` }
                    >

                        <div className="cell col-xs-8 col-sm-4">
                            <div className="col-row">
                                <div className="cell col-xs-1 cell-ptb">
                                    { isActive &&
                                        <CheckBox
                                            checkBoxTriState={ checkBoxState }
                                            isDisabled={ false }
                                            onClick={ this.handleOnSelect }
                                            label={ null }
                                        />
                                    }
                                </div>
                                <div className={ `${ this.classNamePrefix }-product-container col-sm-11 cell-ptb cell-plr` } >
                                    <ProductName 
                                        product={ product } 
                                    />
                                    { isDeleted &&
                                    <span className="container-symbol">
                                        <span className="bevicon bevico-delete" />
                                    </span>
                                    }
                                    { !isActive && !isDeleted &&
                                    <span className="container-symbol">
                                        <span className="bevicon bevico-archived-item" />
                                    </span>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="cell col-xs-12 col-sm-5 col-lg-6 cell-ptb-sm cell-plr-sm">
                            { isActive && this.createStorageAreaPills() }
                        </div>

                        <div className="cell col-xs-4 col-sm-3 col-lg-2 cell-ptb">
                            <div className="col-row">
                                <div className="cell col-sm-9 col-md-10 text-right">
                                    { isActive &&
                                        <AddToStorageAreasDropdown
                                            sortedStorageAreaIds={ addToStorageAreasDropdownProps.sortedStorageAreaIds }
                                            storageAreasById={ addToStorageAreasDropdownProps.storageAreasById }
                                            savedCheckBoxStatesByStorageAreaId={ addToStorageAreasDropdownProps.savedCheckBoxStatesByStorageAreaId }
                                            toggleDropdownButtonText={ addToStorageAreasDropdownProps.toggleDropdownButtonText }
                                            showDropdown={ addToStorageAreasDropdownProps.showDropdown }
                                            setShowDropdown={ addToStorageAreasDropdownProps.setShowDropdown }
                                            onDropdownCancelClick={ addToStorageAreasDropdownProps.onDropdownCancelClick }
                                            onDropdownSaveClick={ addToStorageAreasDropdownProps.onDropdownSaveClick }
                                            onDropdownBlur={ addToStorageAreasDropdownProps.onDropdownBlur }
                                            onCreateNewStorageArea={ addToStorageAreasDropdownProps.onCreateNewStorageArea }
                                            isOffline={ addToStorageAreasDropdownProps.isOffline }
                                        />
                                    }
                                </div>
                                <div className="cell col-sm-3 col-md-2 text-right">
                                    <ContextMenu
                                        items={ [
                                            {
                                                label: 'Archive Item',
                                                iconClass: 'bevicon bevico-archive',
                                                handler: onSetProductAsInactiveClick,
                                                isDisabled: !isActive,
                                            },
                                            {
                                                label: 'Delete Item',
                                                iconClass: 'bevicon bevico-delete',
                                                handler: onDeleteProductClick,
                                                isDisabled: isDeleted,
                                            },
                                        ] }
                                        menuIsShown={ menuIsShown }
                                        setMenuIsShown={ setMenuIsShown }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                    <div
                        className={ `${ this.classNamePrefix }-container light-table-row ${ isActive ? '' : 'archived-item' } ${ isDeleted ? 'deleted-item' : '' }` }
                    >
                        <div className="col-row">
                            <div className="cell col-xs-1 cell-ptb cell-plr-sm">
                                { isActive &&
                                    <CheckBox
                                        checkBoxTriState={ checkBoxState }
                                        isDisabled={ false }
                                        onClick={ this.handleOnSelect }
                                        label={ null }
                                    />
                                }
                            </div>
                            <div className={ `${ this.classNamePrefix }-product-container cell col-xs-5 cell-ptb cell-plr-sm` } >
                                <ProductName 
                                    product={ product } 
                                />
                                { isDeleted &&
                                <span className="container-symbol">
                                    <span className="bevicon bevico-delete" />
                                </span>
                                }
                                { !isActive && !isDeleted &&
                                <span className="container-symbol">
                                    <span className="bevicon bevico-archived-item" />
                                </span>
                                }
                            </div>
                            <div className=" cell col-xs-4 cell-ptb cell-plr-sm">
                                { isActive &&
                                    <AddToStorageAreasDropdown
                                        sortedStorageAreaIds={ addToStorageAreasDropdownProps.sortedStorageAreaIds }
                                        storageAreasById={ addToStorageAreasDropdownProps.storageAreasById }
                                        savedCheckBoxStatesByStorageAreaId={ addToStorageAreasDropdownProps.savedCheckBoxStatesByStorageAreaId }
                                        toggleDropdownButtonText={ addToStorageAreasDropdownProps.toggleDropdownButtonText }
                                        showDropdown={ addToStorageAreasDropdownProps.showDropdown }
                                        setShowDropdown={ addToStorageAreasDropdownProps.setShowDropdown }
                                        onDropdownCancelClick={ addToStorageAreasDropdownProps.onDropdownCancelClick }
                                        onDropdownSaveClick={ addToStorageAreasDropdownProps.onDropdownSaveClick }
                                        onDropdownBlur={ addToStorageAreasDropdownProps.onDropdownBlur }
                                        onCreateNewStorageArea={ addToStorageAreasDropdownProps.onCreateNewStorageArea }
                                        isOffline={ addToStorageAreasDropdownProps.isOffline }
                                    />
                                }
                            </div>
                            <div className="cell col-xs-2 text-right">
                                <ContextMenu
                                    items={ [
                                        {
                                            label: 'Archive Item',
                                            iconClass: 'bevicon bevico-archive',
                                            handler: onSetProductAsInactiveClick,
                                            isDisabled: !isActive,
                                        },
                                        {
                                            label: 'Delete Item',
                                            iconClass: 'bevicon bevico-delete',
                                            handler: onDeleteProductClick,
                                            isDisabled: isDeleted,
                                        },
                                    ] }
                                    menuIsShown={ menuIsShown }
                                    setMenuIsShown={ setMenuIsShown }
                                />
                            </div>
                        </div>
                        <div className="col-row">
                            <div className="cell col-xs-12 cell-plr-sm">
                                { isActive && this.createStorageAreaPills() }
                            </div>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }

    private readonly createStorageAreaPills = () : JSX.Element => {
        const {
            assignedStorageAreaIds,
            onRemoveStorageAreaClick,
            addToStorageAreasDropdownProps,
        } = this.props;

        const {
            sortedStorageAreaIds,
            storageAreasById,
        } = addToStorageAreasDropdownProps;

        if (assignedStorageAreaIds.size === 0) {
            return (
                <div
                    className={ `${ this.classNamePrefix }-storage-area-pills disabled` }
                >
                    No storage area assigned
                </div>
            );
        }

        const pills : Array<JSX.Element> = [];
        sortedStorageAreaIds.forEach((storageAreaId : StorageAreaId, index : number) => {
            if (assignedStorageAreaIds.has(storageAreaId)) {
                const storageArea : StorageArea | undefined = storageAreasById.getRequired(storageAreaId);
                const onClick = () => onRemoveStorageAreaClick(storageAreaId);

                pills.push(
                    <Pill
                        key={ storageAreaId.getValue() }
                        label={ storageArea.getName() }
                        onRemoveClick={ onClick }
                    />
                );
            }
        });

        return (
            <div
                className={ `${ this.classNamePrefix }-storage-area-pills` }
            >
                { pills }
            </div>
        );
    }

    private readonly handleOnSelect = (event : React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        this.props.setIsSelected(!this.props.isSelected);
    }
}
