import { DistributorId } from 'api/Distributor/model/DistributorId';
import React from 'react';
import { connect } from 'react-redux';

import { IRecordNewOrderStore, RecordNewOrderActions, RecordOrderDispatch } from './RecordNewOrderActions';
import { IRecordNewOrderState } from './RecordNewOrderReducers';

import { BevSpotDateTimePickerType } from 'shared/components/BevSpotDateTimePicker/BevSpotDateTimePicker';
import { CheckBox } from 'shared/components/CheckBox';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { FileUploadActions } from 'shared/components/FileUpload/actions';
import { FileUpload } from 'shared/components/FileUpload/FileUpload';
import { IFileUploadState } from 'shared/components/FileUpload/reducers';
import { PopupDateTimePicker } from 'shared/components/PopupDateTimePicker';
import { Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput } from 'shared/components/ValidationInput';

import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { getCurrencySymbol } from 'shared/models/Currency';
import { DateTime } from 'shared/models/DateTime';
import { IInputLabel, LabelOrientation } from 'shared/models/InputLabel';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import './RecordNewOrderView.scss';

interface IOwnProps {
    id : string;
    hideOrderHasBeenDeliveredField : boolean;
    hideInvoiceUploadField : boolean;
}

interface IRecordNewOrderViewProps extends IOwnProps {
    recordNewOrderState : IRecordNewOrderState;
    fileUploadState : IFileUploadState;
}

interface IDispatchProps {
    dispatch : RecordOrderDispatch;
}

interface IConnectedRecordNewOrderViewProps extends IRecordNewOrderViewProps, IDispatchProps {
    dispatch : RecordOrderDispatch;
}

export type RecordOrderDateTimePickerUniqueId = 'RecordNewOrderDateDeliveredDateTimePicker' | 'RecordNewOrderDatePlacedDateTimePicker';

export class RecordNewOrderView extends React.Component<IConnectedRecordNewOrderViewProps, object> {
    private readonly dateDeliveredDateTimePickerUniqueId : RecordOrderDateTimePickerUniqueId = 'RecordNewOrderDateDeliveredDateTimePicker';
    private readonly datePlacedDateTimePickerUniqueId : RecordOrderDateTimePickerUniqueId = 'RecordNewOrderDatePlacedDateTimePicker';

    private readonly currencySymbol = getCurrencySymbol();

    constructor(props : IConnectedRecordNewOrderViewProps) {
        super(props);

        if (!props.recordNewOrderState.recordNewOrderViewForm[props.id]) {
            this.props.dispatch(RecordNewOrderActions.initializeRecordNewOrderViewForm(this.props.id));
        }
    }

    public UNSAFE_componentWillMount() {
        $(document).on('customVendorCreated', (event, createVendorResult, distributor) => {
            const distributorIdValue = createVendorResult.distributor_id;
            const distributorId = new DistributorId(distributorIdValue);
            this.props.dispatch(RecordNewOrderActions.onNewDistributorCreated(distributor, distributorId));
            this.onDistributorDropdownOptionChange(distributorId.getValue());
        });
    }

    public render() {
        const {
            recordNewOrderState,
            fileUploadState,
            id,
            hideOrderHasBeenDeliveredField,
            hideInvoiceUploadField,
        } = this.props;

        const deliveredCheckboxLabel : IInputLabel = {
            labelOrientation: LabelOrientation.RIGHT,
            value: 'Delivered',
        };

        const retailerCanUploadInvoices = window.GLOBAL_FEATURE_ACCESS.invoice_upload || window.GLOBAL_FEATURE_ACCESS.invoice_processing;

        const hasOptionalFeatures = window.GLOBAL_FEATURE_ACCESS.invoice_upload || window.GLOBAL_FEATURE_ACCESS.invoice_totals;
        let optionalSectionTipString : string = '';
        if (hasOptionalFeatures) {
            const descriptionForInvoiceUpload = retailerCanUploadInvoices ? 'a photo or scan of your invoice ' : '';
            const andOrString = (retailerCanUploadInvoices && window.GLOBAL_FEATURE_ACCESS.invoice_totals) ? 'and/or ' : '';
            const descriptionForInvoiceTotal = window.GLOBAL_FEATURE_ACCESS.invoice_totals ? 'the total amount ' : '';
            optionalSectionTipString = `Adding ${ descriptionForInvoiceUpload }${ andOrString }${ descriptionForInvoiceTotal }can help you keep track of orders placed outside of Bevspot.`;
        }

        const recordNewOrderForm = recordNewOrderState.recordNewOrderViewForm[id];
        if (!recordNewOrderForm) {
            // loading
            return <div/>;
        }

        const prefix = id ? id + '~' : '';
        const datePlacedDateTimePickerIsShown = (recordNewOrderState.isShownByComponentName as any)[prefix + 'datePlacedDateTimePicker'] || false; // TODO Dirty fix because component wasn't working as is
        const dateDeliveredDateTimePickerIsShown = (recordNewOrderState.isShownByComponentName as any)[prefix + 'dateDeliveredDateTimePicker'] || false; // TODO Dirty fix because component wasn't working as is

        return (
            <div className="record-new-order-view">
                <div className="col-row">
                    <h5 className="normal col-xs-12">Record Order</h5>
                    <div className="col-xs-12 col-sm-6 cell-pb">
                        <label className="cell-pb">Vendor</label>
                        <Select2DropdownMenu
                            onOptionSelect={ this.onDistributorDropdownOptionChange }
                            sortedOptionsAndLabelName={ recordNewOrderForm.sortedDistributorOptionsAndLabelName }
                            selectedOption={ recordNewOrderForm.currentDistributorOption }
                            placeholderText="Select Vendor"
                            hasCreateCustomOption={ true }
                            createCustomOption={ this.onCreateNewVendor }
                            buttonShouldDisplaySelectedLabel={ true }
                            emptyState={ null }
                            isSearchable={ true }
                            selectedOptionHeaderShown={ true }
                            customOptionGroupLabel={ "My Vendors" }
                            createCustomOptionButtonLabel={ null }
                        />
                    </div>
                    <div className="invoice-number-input col-xs-12 col-sm-6 cell-pb">
                        <label className="cell-pb">Invoice #<span> (optional)</span></label>
                        <ValidationInput
                            inputClassName="boxed-input"
                            type="text"
                            autoFocus={ false }
                            autoComplete={ null }
                            errorMessage={ recordNewOrderForm.validationInputByFieldName.invoiceNumber.errorMessage }
                            isValid={ recordNewOrderForm.validationInputByFieldName.invoiceNumber.isValid }
                            handleBlur={ this.handleOnInvoiceNumberFormFieldBlur }
                            handleFocus={ null }
                            handleChange={ this.handleOnInvoiceNumberFormFieldChange }
                            handleEnterClick={ this.doNothingFunction }
                            label={ null }
                            hintText={ null }
                            value={ recordNewOrderForm.validationInputByFieldName.invoiceNumber.value }
                            isDisabled={ false }
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 cell-pb">
                        <label className="cell-pb">Placed</label>
                        <PopupDateTimePicker
                            setDateTimePickerIsShown={ this.handleSetDatePlacedDateTimePickerShown }
                            dateTimePickerIsShown={ datePlacedDateTimePickerIsShown }
                            dateTimePickerProps={ {
                                buttons: [],
                                initialDateTime: recordNewOrderForm.datePlaced,
                                setDateTime: this.handleDatePlacedOnDateChange,
                                type: BevSpotDateTimePickerType.POPUP,
                                uniqueId: this.datePlacedDateTimePickerUniqueId,
                                shouldShowTime: true,
                            } }
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 cell-pb">
                        <label className="cell-pb">Delivered</label>
                        <PopupDateTimePicker
                            setDateTimePickerIsShown={ this.handleSetDateDeliveredDateTimePickerShown }
                            dateTimePickerIsShown={ dateDeliveredDateTimePickerIsShown }
                            dateTimePickerProps={ {
                                buttons: [],
                                initialDateTime: recordNewOrderForm.dateDelivered,
                                setDateTime: this.handleDateDeliveredOnDateChange,
                                type: BevSpotDateTimePickerType.POPUP,
                                uniqueId: this.dateDeliveredDateTimePickerUniqueId,
                                shouldShowTime: true,
                            } }
                        />
                        { !hideOrderHasBeenDeliveredField &&
                            <div className="is-confirmed-checkbox cell-pt-sm text-right">
                                <CheckBox
                                    checkBoxTriState={ recordNewOrderForm.orderHasBeenDelivered ? CheckBoxTriState.Checked : CheckBoxTriState.Unchecked }
                                    isDisabled={ false }
                                    label={ deliveredCheckboxLabel }
                                    onClick={ this.handleSetOrderHasBeenDelivered }
                                />
                            </div>
                        }
                    </div>
                    { window.GLOBAL_FEATURE_ACCESS.invoice_totals &&
                        <div className="invoice-total-input col-xs-12 col-sm-6 cell-pb">
                            <label className="cell-pb">Total Amount <span>(optional)</span></label>
                            <div className="currency-symbol-input-wrapper">
                                <span className="currency-symbol">{ this.currencySymbol }</span>
                                <ValidationInput
                                    inputClassName="boxed-input"
                                    type="text"
                                    autoFocus={ false }
                                    autoComplete={ null }
                                    errorMessage={ recordNewOrderForm.validationInputByFieldName.invoiceTotal.errorMessage }
                                    isValid={ recordNewOrderForm.validationInputByFieldName.invoiceTotal.isValid }
                                    handleBlur={ this.handleOnInvoiceTotalFormFieldBlur }
                                    handleFocus={ null }
                                    handleChange={ this.handleOnInvoiceTotalFormFieldChange }
                                    handleEnterClick={ this.doNothingFunction }
                                    label={ null }
                                    hintText={ null }
                                    value={ recordNewOrderForm.validationInputByFieldName.invoiceTotal.value }
                                    isDisabled={ false }
                                />
                            </div>
                        </div>
                    }
                </div>

                { !hideInvoiceUploadField &&
                    <div className="col-row">
                        { retailerCanUploadInvoices &&
                            <div className="invoice-upload col-xs-12 cell-ptb">
                                <label className="cell-pb">
                                    Invoice Images
                                    { !recordNewOrderForm.shouldSubmitUploadForProcessing &&
                                        <span> (optional)</span>
                                    }
                                </label>
                                <FileUpload
                                    id={ id }
                                    onFileInputChange={ this.onFileInputChange }
                                    fileUploadState={ fileUploadState[id] || [] }
                                    buttonText="Upload Attachments"
                                    maxFileUploadCount={ 10 }
                                />
                                <small>{ optionalSectionTipString }</small>
                                { window.GLOBAL_FEATURE_ACCESS.invoice_processing &&
                                    <div className="invoice-upload-option-container cell-ptb-xl">
                                        <div className="check-box-wrapper cell-pb-sm">
                                            <CheckBox
                                                checkBoxTriState={ recordNewOrderForm.shouldSubmitUploadForProcessing ? CheckBoxTriState.Checked : CheckBoxTriState.Unchecked }
                                                isDisabled={ false }
                                                label={ {
                                                    value: `Submit attached uploads for processing?`,
                                                    labelOrientation: LabelOrientation.RIGHT
                                                } }
                                                onClick={ this.handleSetShouldSubmitUploadForProcessing }
                                            />
                                        </div>
                                        <small>Submitted pdf, .jpeg and .png invoices will be processed within 24 hours. Accuracy is reliant on legibility.</small>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }

    private readonly handleSetDateDeliveredDateTimePickerShown = (isShown : boolean) => {
        this.onSetDateTimePickerShown(this.dateDeliveredDateTimePickerUniqueId, isShown);
    }

    private readonly handleSetDatePlacedDateTimePickerShown = (isShown : boolean) => {
        this.onSetDateTimePickerShown(this.datePlacedDateTimePickerUniqueId, isShown);
    }

    private readonly handleDateDeliveredOnDateChange = (dateTime : DateTime) => {
        this.onDateChange(this.dateDeliveredDateTimePickerUniqueId, dateTime);
    }

    private readonly handleDatePlacedOnDateChange = (dateTime : DateTime) => {
        this.onDateChange(this.datePlacedDateTimePickerUniqueId, dateTime);
    }

    private readonly onFileInputChange = (id : string, index : number, file : File | null) => {
        this.props.dispatch(FileUploadActions.setFile(id, index, file));
    }

    private readonly onDateChange = (dateTimePickerUniqueId : RecordOrderDateTimePickerUniqueId, dateTime : DateTime) => {
        if (dateTimePickerUniqueId === 'RecordNewOrderDateDeliveredDateTimePicker') {
            this.props.dispatch(RecordNewOrderActions.setOrderDate(this.props.id, 'dateDelivered', dateTime));
        } else if (dateTimePickerUniqueId === 'RecordNewOrderDatePlacedDateTimePicker') {
            this.props.dispatch(RecordNewOrderActions.setOrderDate(this.props.id, 'datePlaced', dateTime));
        }
    }

    private readonly onCreateNewVendor = (vendorName : string) => {
        $(document).trigger('openCreateVendorModal', { name: vendorName });
    }

    private readonly onSetDateTimePickerShown = (dateTimePickerUniqueId : string, isShown : boolean) => {
        if (dateTimePickerUniqueId === 'RecordNewOrderDateDeliveredDateTimePicker') {
            this.props.dispatch(RecordNewOrderActions.setComponentIsShown(this.props.id, 'dateDeliveredDateTimePicker', isShown));
        } else if (dateTimePickerUniqueId === 'RecordNewOrderDatePlacedDateTimePicker') {
            this.props.dispatch(RecordNewOrderActions.setComponentIsShown(this.props.id, 'datePlacedDateTimePicker', isShown));
        }
    }

    private readonly handleOnInvoiceTotalFormFieldChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.dispatch(RecordNewOrderActions.onFormFieldChange(this.props.id, 'invoiceTotal', event.currentTarget.value));
    }
    private readonly handleOnInvoiceTotalFormFieldBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.props.dispatch(RecordNewOrderActions.onFormFieldChange(this.props.id, 'invoiceTotal', event.currentTarget.value));
    }

    private readonly handleOnInvoiceNumberFormFieldChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.dispatch(RecordNewOrderActions.onFormFieldChange(this.props.id, 'invoiceNumber', event.currentTarget.value));
    }
    private readonly handleOnInvoiceNumberFormFieldBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.props.dispatch(RecordNewOrderActions.onFormFieldChange(this.props.id, 'invoiceNumber', event.currentTarget.value));
    }

    private readonly handleSetOrderHasBeenDelivered = () => {
        this.props.dispatch(RecordNewOrderActions.setOrderHasBeenDelivered(this.props.id, !this.props.recordNewOrderState.recordNewOrderViewForm[this.props.id].orderHasBeenDelivered));
    }

    private readonly handleSetShouldSubmitUploadForProcessing = () => {
        this.props.dispatch(RecordNewOrderActions.setShouldSubmitUploadForProcessing(this.props.id, !this.props.recordNewOrderState.recordNewOrderViewForm[this.props.id].shouldSubmitUploadForProcessing));
    }

    private readonly onDistributorDropdownOptionChange = (newValue : string) => {
        // TODO this logic should probably not live here
        const currentOptionsAndLabelNames = this.props.recordNewOrderState.recordNewOrderViewForm[this.props.id].sortedDistributorOptionsAndLabelName;
        const currentOptions : Array<IOption> = [];
        currentOptionsAndLabelNames.forEach((value) => currentOptions.push(...value[1]));

        let indexOfSelectedValue : number | null = null;
        const dropdownOption = currentOptions.filter((option : IOption, index : number) => {
            if (option.value === newValue) {
                indexOfSelectedValue = index;
                return true;
            }
            return false;
        })[0];

        if (indexOfSelectedValue === null) {
            throw new RuntimeException('unexpected selected value not found in dropdown options');
        }

        this.props.dispatch(RecordNewOrderActions.setCurrentDistributorOption(this.props.id, dropdownOption));
    }

    private readonly doNothingFunction = () => undefined;

}

export interface IRecordNewOrderViewStateProps {
    recordNewOrderState : IRecordNewOrderState;
    fileUploadState : IFileUploadState;
}

const mapStateToProps = (state : IRecordNewOrderViewStateProps) : IRecordNewOrderViewStateProps => {
    return {
        recordNewOrderState: state.recordNewOrderState,
        fileUploadState: state.fileUploadState,
    };
};

const mapDispatchToProps = (dispatch : RecordOrderDispatch) : IDispatchProps => {
    return {
        dispatch
    };
};

const mergeProps = (state : IRecordNewOrderViewStateProps, dispatchProps : IDispatchProps, ownProps : IOwnProps) : IConnectedRecordNewOrderViewProps => {
    return {
        recordNewOrderState: state.recordNewOrderState,
        fileUploadState: state.fileUploadState,
        id: ownProps.id,
        dispatch: dispatchProps.dispatch,
        hideOrderHasBeenDeliveredField : ownProps.hideOrderHasBeenDeliveredField,
        hideInvoiceUploadField : ownProps.hideInvoiceUploadField,
    };
};

export const ConnectedRecordNewOrderView = connect<IRecordNewOrderViewStateProps, IDispatchProps, IOwnProps, IConnectedRecordNewOrderViewProps, IRecordNewOrderStore>(mapStateToProps, mapDispatchToProps, mergeProps)(RecordNewOrderView);
