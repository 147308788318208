import { cookieManager, userSessionManager } from 'shared/lib/manager';

export interface IRestApiRejection {
    message : string;
    status : number;
    xhr : XMLHttpRequest;
    error : Error | null;
    type : string | null;
    content : any | null;
}

const buildQueryString = (queryObject : any) => {
    const queryParameters : Array<string> = [];
    let queryString = '';
    Object.keys(queryObject || {}).forEach((key : string) => {
        queryParameters.push(key + '=' + encodeURIComponent(queryObject[key]));
    });
    if (queryParameters.length > 0) {
        queryString = '?' + queryParameters.join('&');
    }
    return queryString;
};

const handleXhrOnLoad = (resolve : (value : any) => void, reject : (reason : any) => void, xhr : XMLHttpRequest) : void => {
    if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 204)) {
        if (xhr.responseType === '' && xhr.responseText === '') {
            return resolve(undefined);
        }
        if (xhr.responseType === 'text') {
            return resolve(xhr.response);
        }
        try {
            const responseJSON = JSON.parse(xhr.response);

            if (typeof(responseJSON.success) !== 'undefined' && !responseJSON.success) {
                if (responseJSON.error === 'user_required') {
                    userSessionManager.terminateSession();
                    window.location.reload();
                } else {
                    return reject({
                        message: 'Request failed',
                        status: xhr.status,
                        xhr,
                        error: responseJSON,
                        type: null,
                        content: null,
                    });
                }
            }

            return resolve(responseJSON);
        } catch (err) {
            const e = err as Error;
            const rejection : IRestApiRejection = {
                message: e.message,
                status: xhr.status,
                xhr,
                error: e,
                type: null,
                content: null,
            };
            // eslint-disable-next-line no-console
            console.error('Failed to parse response', xhr);
            return reject(rejection);
        }
    } else {
        return handleXhrOnLoadCaughtException(reject, xhr);
    }
};

const handleXhrOnLoadCaughtException = (reject : (reason : any) => void, xhr : XMLHttpRequest) : void => {
    if (xhr.status === 401) {
        userSessionManager.terminateSession();
        window.location.reload();
    }

    if (xhr.responseType === 'text' || xhr.responseType === '') {
        const rejection : IRestApiRejection = {
            message: xhr.responseText || xhr.statusText,
            status: xhr.status,
            xhr,
            error: null,
            type: null,
            content: null,
        };
        return reject(rejection);
    }
    try {
        const responseJSON = JSON.parse(xhr.response);

        const rejection : IRestApiRejection = {
            message: responseJSON.message || xhr.statusText,
            status: xhr.status,
            xhr,
            error: null,
            type: responseJSON.exception_type,
            content: responseJSON.content,
        };
        return reject(rejection);
    } catch (err) {
        const e = err as Error;
        const rejection : IRestApiRejection = {
            message: e.message,
            status: xhr.status,
            xhr,
            error: e,
            type: null,
            content: null,
        };
        // eslint-disable-next-line no-console
        console.error('Failed to parse response', xhr);
        return reject(rejection);
    }
};

export const AjaxUtils = {
    ajaxGet(url : string, queryObject? : any) : Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const cacheBustingQueryObject = (queryObject || {}); // IE 11 caches GET requests
            cacheBustingQueryObject._cache_busting_arg_ = +new Date(); // this parameter name must be in the backend's excluded parameter set (see api_utils.py)

            xhr.open('GET', url + buildQueryString(cacheBustingQueryObject));

            const authToken = cookieManager.getCookie('userSessionIdentifier');
            xhr.setRequestHeader('Authorization', 'Bearer ' + authToken);

            xhr.withCredentials = true;
            xhr.onload = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    try {
                        const responseJSON = JSON.parse(xhr.response);
                        if (typeof(responseJSON.success) !== 'undefined' && !responseJSON.success) {
                            return reject({
                                message: 'Request failed',
                                status: xhr.status,
                                xhr,
                                error: responseJSON,
                                type: null,
                                content: null,
                            });
                        }
                        return resolve(responseJSON);
                    } catch (err) {
                        const e = err as Error;
                        const rejection : IRestApiRejection = {
                            message: e.message,
                            status: xhr.status,
                            xhr,
                            error: e,
                            type: null,
                            content: null,
                        };
                        // eslint-disable-next-line no-console
                        console.error('Failure to parse response', xhr);
                        return reject(rejection);
                    }
                } else {
                    return handleXhrOnLoadCaughtException(reject, xhr);
                }
            };
            xhr.onerror = (e) => {
                return handleXhrOnLoadCaughtException(reject, xhr);
            };
            xhr.send();
        });
    },

    ajaxPost(url : string, postBody : any, queryObject? : any, type? : 'text' | 'json') : Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url + buildQueryString(queryObject));
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('X-CSRFToken', (window as any).CSRF_TOKEN);

            const authToken = cookieManager.getCookie('userSessionIdentifier');
            xhr.setRequestHeader('Authorization', 'Bearer ' + authToken);

            xhr.onload = () => {
                if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 201)) {
                    if (type === 'text') {
                        return resolve(xhr.response);
                    }

                    try {
                        const responseJSON = JSON.parse(xhr.response);
                        if (typeof(responseJSON.success) !== 'undefined' && !responseJSON.success) {
                            return reject({
                                message: 'Request failed',
                                status: xhr.status,
                                xhr,
                                error: responseJSON,
                                type: null,
                                content: null,
                            });
                        }
                        return resolve(responseJSON);
                    } catch (err) {
                        const e = err as Error;
                        const rejection : IRestApiRejection = {
                            message: e.message,
                            status: xhr.status,
                            xhr,
                            error: e,
                            type: null,
                            content: null,
                        };
                        // eslint-disable-next-line no-console
                        console.error('Failure to parse response', xhr);
                        return reject(rejection);
                    }
                } else {
                    return handleXhrOnLoadCaughtException(reject, xhr);
                }
            };
            xhr.onerror = (e) => {
                return handleXhrOnLoadCaughtException(reject, xhr);
            };
            xhr.send(JSON.stringify(postBody || undefined));
        });
    },

    ajaxPostForm(url : string, formData? : FormData, queryObject? : any) : Promise<any> {
        // Lesman 4/24/2017
        // NOTE: you do not set the Content-Type on this request,
        // the browser will automatically encode the form data and
        // set the appropriate Content-Type. The encoding is browser
        // specific so you MUST allow the browser to set the header
        return new Promise<any>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url + buildQueryString(queryObject));
            xhr.withCredentials = true;
            xhr.setRequestHeader('X-CSRFToken', (window as any).CSRF_TOKEN);

            const authToken = cookieManager.getCookie('userSessionIdentifier');
            xhr.setRequestHeader('Authorization', 'Bearer ' + authToken);

            xhr.onload = () => handleXhrOnLoad(resolve, reject, xhr); // TODO Cheezy why doesn't this check readyState and status??
            xhr.onerror = (e) => {
                return handleXhrOnLoadCaughtException(reject, xhr);
            };
            xhr.send(formData);
        });
    },

    ajaxPut(url : string, postBody : any, queryObject? : any) : Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url + buildQueryString(queryObject));
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('X-CSRFToken', (window as any).CSRF_TOKEN);

            const authToken = cookieManager.getCookie('userSessionIdentifier');
            xhr.setRequestHeader('Authorization', 'Bearer ' + authToken);

            xhr.onload = () => handleXhrOnLoad(resolve, reject, xhr);
            xhr.onerror = (e) => {
                return handleXhrOnLoadCaughtException(reject, xhr);
            };
            xhr.send(JSON.stringify(postBody || undefined));
        });
    },

    ajaxDelete(url : string, queryObject? : any, body? : any) : Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('DELETE', url + buildQueryString(queryObject));
            xhr.withCredentials = true;
            xhr.setRequestHeader('X-CSRFToken', (window as any).CSRF_TOKEN);
            const authToken = cookieManager.getCookie('userSessionIdentifier');
            xhr.setRequestHeader('Authorization', 'Bearer ' + authToken);

            xhr.onload = () => handleXhrOnLoad(resolve, reject, xhr);
            xhr.onerror = (e) => {
                return handleXhrOnLoadCaughtException(reject, xhr);
            };
            xhr.send(JSON.stringify(body || undefined));
        });
    },
};
