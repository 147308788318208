import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Mappings } from 'api/Product/model/Mappings';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { ProductId } from "api/Product/model/ProductId";
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { UnitUtils } from 'api/Product/utils/UnitUtils';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { productObjectToJSONSerializer } from 'shared/lib/manager';
import { Product } from 'api/Product/model/Product';
import { numberUtils } from 'shared/utils/numberUtils';
import { v4 as uuidv4 } from 'uuid';
import { oldPackagingUtils } from './oldPackagingUtils';

export const UNIT_DROPDOWN_LABEL = 'By Unit';
export const VOLUME_DROPDOWN_LABEL = 'By Volume';
export const WEIGHT_DROPDOWN_LABEL = 'By Weight';

const productQuantityUnitsAreEqual = (pqu1: ProductQuantityUnit, pqu2: ProductQuantityUnit): boolean => {
    if (pqu1 instanceof PackagingId && pqu2 instanceof PackagingId) {
        return pqu1.equals(pqu2);
    } else {
        return pqu1 === pqu2; // TODO alb verify this works?
    }
};

const getPackagingForContainedPackagingId = (packaging : Packaging, packagingId : PackagingId, productId? : ProductId) : Packaging => {
    let currentPackaging : Packaging | null = packaging;
    while (currentPackaging) {
        const currentPackagingId = currentPackaging.getPackagingId();
        if (currentPackagingId && packagingId.equals(currentPackagingId)) {
            return currentPackaging;
        }

        currentPackaging = currentPackaging.getContent();
    }

    throw new RuntimeException(`${ productId }: ${ packagingId } not contained within packaging ${ packaging.toString() }`);
};

const getBaseUnitOfPackaging = (packaging : Packaging) : ProductQuantityUnit => {
    let baseUnit : ProductQuantityUnit | null = null;
    let currentPackaging : Packaging | null = packaging;
    while (currentPackaging) {
        baseUnit = currentPackaging.getPackagingId() || currentPackaging.getUnit();
        currentPackaging = currentPackaging.getContent();
    }
    if (baseUnit === null) {
        throw new RuntimeException('unexpected baseUnit is null');
    }

    return baseUnit;
};

const isProductQuantityUnitCompatibleWithPackagings = (packagingsAndMappings : PackagingsAndMappings, productQuantityUnit : ProductQuantityUnit) : boolean => {
    if (productQuantityUnit instanceof PackagingId) {
        return packagingsAndMappings.getAvailablePackagingByPackagingId().has(productQuantityUnit);
    }

    if (MassUnit.isMassUnit(productQuantityUnit)) {
        return (packagingsAndMappings.getAvailableMassUnits().size > 0);
    } else if (VolumeUnit.isVolumeUnit(productQuantityUnit)) {
        return (packagingsAndMappings.getAvailableVolumeUnits().size > 0);
    }

    return false;
};

const getQuantityInBaseUnit = (
    packagingsAndMappings : PackagingsAndMappings,
    quantityInUnit : QuantityInUnit<ProductQuantityUnit>,
    productId? : ProductId
) : QuantityInUnit<ProductQuantityUnit> => {
    const resolvedQuantityInUnit = resolveProductQuantityUnit(quantityInUnit, packagingsAndMappings.getMappings());

    const packagingByPackagingId = packagingsAndMappings.getAvailablePackagingByPackagingId();

    let currentQuantity = resolvedQuantityInUnit.getQuantity();
    let currentProductQuantityUnit = resolvedQuantityInUnit.getUnit();

    while ((currentProductQuantityUnit instanceof PackagingId) && packagingByPackagingId.has(currentProductQuantityUnit) && packagingByPackagingId.getRequired(currentProductQuantityUnit).getContent()) {
        const packaging = packagingByPackagingId.getRequired(currentProductQuantityUnit);
        const content = packaging.getContent();

        if (content === null) {
            throw new RuntimeException("unexpected no packaging content found for productId: " + productId + " packaging: " + currentProductQuantityUnit);
        }

        const contentProductQuantityUnit = content.getUnit() || content.getPackagingId();
        if (contentProductQuantityUnit === null) {
            throw new RuntimeException('"contentProductQuantityUnit" is unexpectedly null');
        }
    
        const quantityOfContent = packaging.getQuantityOfContent();
        if (quantityOfContent === null) {
            throw new RuntimeException('"quantityOfContent" is unexpectedly null');
        }

        currentQuantity = currentQuantity * quantityOfContent;
        currentProductQuantityUnit = contentProductQuantityUnit;
    }

    const conversions = packagingsAndMappings.getConversions();
    const baseUnit = conversions.getBaseUnit();

    if (productQuantityUnitsAreEqual(currentProductQuantityUnit, baseUnit)) {
        return new QuantityInUnit(currentQuantity, baseUnit);
    }

    if (currentProductQuantityUnit instanceof PackagingId) {
        const packagingIdConversions = conversions.getPackagingIdConversions();

        if (packagingIdConversions.has(currentProductQuantityUnit)) {
            const conversionQuantity = packagingIdConversions.getRequired(currentProductQuantityUnit);

            return new QuantityInUnit(currentQuantity * conversionQuantity, baseUnit);
        }
    } else {
        if (!(baseUnit instanceof PackagingId)) {
            if ((MassUnit.isMassUnit(baseUnit) && MassUnit.isMassUnit(currentProductQuantityUnit)) || (VolumeUnit.isVolumeUnit(baseUnit) && VolumeUnit.isVolumeUnit(currentProductQuantityUnit))) {
                const convertedUnitQuantity = UnitUtils.convertUnitQuantity(new QuantityInUnit(1, currentProductQuantityUnit), baseUnit).getQuantity();

                return new QuantityInUnit(currentQuantity * convertedUnitQuantity, baseUnit);
            }
        }

        const unitOfMeasureConversions = conversions.getUnitOfMeasureConversions();

        const currentUnit = currentProductQuantityUnit;
        unitOfMeasureConversions.forEach((conversionQuantity, unitOfMeasure) => {
            if ((MassUnit.isMassUnit(unitOfMeasure) && MassUnit.isMassUnit(currentUnit)) || (VolumeUnit.isVolumeUnit(unitOfMeasure) && VolumeUnit.isVolumeUnit(currentUnit))) {
                const convertedUnitQuantity = UnitUtils.convertUnitQuantity(new QuantityInUnit(1, currentUnit), unitOfMeasure).getQuantity();

                currentQuantity = currentQuantity * convertedUnitQuantity * conversionQuantity;
                currentProductQuantityUnit = baseUnit;
            }
        });

        if (productQuantityUnitsAreEqual(currentProductQuantityUnit, baseUnit)) {
            return new QuantityInUnit(currentQuantity, baseUnit);
        }
    }
    throw new RuntimeException(`Could not convert productId: ${ productId }, productQuantityUnit: ${ quantityInUnit.getUnit() } to preferreBaseUnit with packagingsAndMappings: ${ JSON.stringify(packagingsAndMappings) }`);
};

// Exported

const resolveProductQuantityUnit = (
    quantityInUnit : QuantityInUnit<ProductQuantityUnit>,
    mappings : Mappings,
) : QuantityInUnit<ProductQuantityUnit> => {
    const productQuantityUnit = quantityInUnit.getUnit();

    if (productQuantityUnit instanceof PackagingId) {
        const packagingIdMappings = mappings.getPackagingIdMappings();

        if (packagingIdMappings.has(productQuantityUnit)) {
            const mappedProductQuantity = packagingIdMappings.get(productQuantityUnit);
            if (typeof mappedProductQuantity === 'undefined') {
                throw new RuntimeException('unexpected mappedProductQuantity is undefined');
            }

            return new QuantityInUnit(
                quantityInUnit.getQuantity() * mappedProductQuantity.getQuantity(),
                mappedProductQuantity.getUnit()
            );
        }
    } else {
        const unitOfMeasureMappings = mappings.getUnitOfMeasureMappings();
        let resolvedProductQuantityUnit : QuantityInUnit<PackagingId> | null = null;
        unitOfMeasureMappings.forEach((mappedProductQuantity, unitOfMeasure) => {
            if ((MassUnit.isMassUnit(unitOfMeasure) && MassUnit.isMassUnit(productQuantityUnit)) || (VolumeUnit.isVolumeUnit(unitOfMeasure) && VolumeUnit.isVolumeUnit(productQuantityUnit))) {
                if (resolvedProductQuantityUnit) {
                    throw new RuntimeException('Unexpectedly encountered duplicate mappings from the same unit type');
                }

                const convertedQuantityInUnit = UnitUtils.convertUnitQuantity(quantityInUnit as QuantityInUnit<MassUnit | VolumeUnit>, unitOfMeasure);
                resolvedProductQuantityUnit =  new QuantityInUnit(
                    convertedQuantityInUnit.getQuantity() * mappedProductQuantity.getQuantity(),
                    mappedProductQuantity.getUnit()
                );
            }
        });

        if (resolvedProductQuantityUnit) {
            return resolvedProductQuantityUnit;
        }
    }

    return quantityInUnit;
};

const convertProductQuantityToUnit = <T extends ProductQuantityUnit>(
    packagingsAndMappings : PackagingsAndMappings,
    quantityInUnit : QuantityInUnit<ProductQuantityUnit>,
    unit : T,
    productId? : ProductId      // needed for quickly identifying packaging issues - please add if you're in the context
) : QuantityInUnit<T> => {
    const inputQuantityInBaseUnitOfPackaging = getQuantityInBaseUnit(packagingsAndMappings, quantityInUnit, productId);
    const quantityOfOutputUnitInBaseUnitOfPackaging = getQuantityInBaseUnit(packagingsAndMappings, new QuantityInUnit(1, unit), productId);

    return new QuantityInUnit(
        inputQuantityInBaseUnitOfPackaging.getQuantity() / quantityOfOutputUnitInBaseUnitOfPackaging.getQuantity(),
        unit
    );
};

const getContainerPackagingId = (packaging : Packaging) : PackagingId => {
    let basePackagingId : PackagingId | null = null;
    let currentPackaging : Packaging | null = packaging;

    while (currentPackaging) {
        const packagingId = currentPackaging.getPackagingId();
        const content = currentPackaging.getContent(); // PTODO handles BaseUnits
        if (packagingId && content) {
            basePackagingId = packagingId;
        }

        currentPackaging = currentPackaging.getContent();
    }

    if (basePackagingId === null) {
        throw new RuntimeException('unexpected basePackagingId is null');
    }

    return basePackagingId;
};

// Returns the packaging name(eg. 'case'), without any of the content or quantity information.
// If you want quantity/content included, use getDisplayTextForProductQuantityInUnit or getPackagingDisplayTextForProductQuantityUnit
const getDisplayTextForProductQuantityUnit = (
    packagingsAndMappings : PackagingsAndMappings,
    productQuantityUnit : ProductQuantityUnit,
    isPlural : boolean
) : string => {
    const resolvedQuantityInUnit = resolveProductQuantityUnit(new QuantityInUnit(1, productQuantityUnit), packagingsAndMappings.getMappings());
    const resolvedProductQuantityUnit = resolvedQuantityInUnit.getUnit();
    const resolvedQuantityInUnitMultiplier = resolvedQuantityInUnit.getQuantity();

    let displayText : string;
    if (resolvedProductQuantityUnit instanceof PackagingId) {
        const packaging = packagingsAndMappings.getAvailablePackagingByPackagingId().getRequired(resolvedProductQuantityUnit);
        const packagingName = packaging.getName();

        if (packagingName === null) { // PTODO
            throw new RuntimeException('unexpected packaging name is null');
        }
        displayText = packagingName;
    } else {
        displayText =  getDisplayTextForVolumeOrMassUnit(resolvedProductQuantityUnit);
    }

    if ((isPlural || (numberUtils.isPlural(resolvedQuantityInUnitMultiplier))) && (resolvedProductQuantityUnit instanceof PackagingId)) {
        if (displayText.endsWith('x') || displayText.endsWith('h')) { // PTODO
            displayText += 'e';
        }
        displayText +=  's';
    }

    return displayText;
};

// This methods resolves (changes) the input unit. Usages of this method without explicitly resolving unit in the caller might be incorrect.
const getPackagingDisplayTextForProductQuantityUnit = (
    packagingsAndMappings : PackagingsAndMappings,
    productQuantityUnit : ProductQuantityUnit,
    isPlural : boolean
) : string => {
    let displayTextPrefix : string;
    let displayTextSuffix : string = '';
    // It's arguable whether we should resolve unit here... unit is usually associated with numeric quantity which should be updated when the unit is changed.
    const resolvedQuantityInUnit = resolveProductQuantityUnit(new QuantityInUnit(1, productQuantityUnit), packagingsAndMappings.getMappings());
    const resolvedProductQuantityUnit = resolvedQuantityInUnit.getUnit();
    if (resolvedProductQuantityUnit instanceof PackagingId) {
        const packaging = packagingsAndMappings.getAvailablePackagingByPackagingId().getRequired(resolvedProductQuantityUnit);
        const packagingName = packaging.getName();

        if (packagingName === null) { // PTODO
            throw new RuntimeException('unexpected packaging name is null');
        }
        const packagingContent = packaging.getContent();
        displayTextPrefix = packagingName;
        displayTextSuffix = packagingContent ? ` (${ getDisplayTextForPackaging(packaging, (packagingContent.getContent() !== null)) })` : '';
    } else {
        displayTextPrefix = getDisplayTextForVolumeOrMassUnit(resolvedProductQuantityUnit);
    }

    if ((isPlural || numberUtils.isPlural(resolvedQuantityInUnit.getQuantity())) && resolvedProductQuantityUnit instanceof PackagingId) {
        if (displayTextPrefix.endsWith('x') || displayTextPrefix.endsWith('h')) { // PTODO
            displayTextPrefix += 'e';
        }
        displayTextPrefix +=  's';
    }

    return displayTextPrefix + displayTextSuffix;
};

const getDisplayTextForProductQuantityInUnit = (
    packagingsAndMappings : PackagingsAndMappings,
    productQuantityInUnit : QuantityInUnit<ProductQuantityUnit>
) : string => {
    const resolvedQuantityInUnit = resolveProductQuantityUnit(productQuantityInUnit, packagingsAndMappings.getMappings());
    const resolvedProductQuantityUnit = resolvedQuantityInUnit.getUnit();
    const resolvedQuantityInUnitMultiplier = resolvedQuantityInUnit.getQuantity();
    const productQuantityUnitDisplayText = getPackagingDisplayTextForProductQuantityUnit(packagingsAndMappings, resolvedQuantityInUnit.getUnit(),  numberUtils.isPlural(resolvedQuantityInUnitMultiplier));

    if (resolvedProductQuantityUnit instanceof PackagingId) {
        return numberUtils.FormatToMaximumTwoDecimalPlaces(resolvedQuantityInUnitMultiplier) + ' ' + productQuantityUnitDisplayText;
    } else {
        return numberUtils.FormatToMaximumTwoDecimalPlaces(resolvedQuantityInUnitMultiplier) + productQuantityUnitDisplayText;
    }
};

const getPackagingForContainedPackagingName = (packaging : Packaging, packagingName : string) : Packaging | null => {
    let currentPackaging : Packaging | null = packaging;
    while (currentPackaging) {
        const currentPackagingName = currentPackaging.getName(); // PTODO
        if (currentPackagingName && (currentPackagingName === packagingName)) {
            return currentPackaging;
        }

        currentPackaging = currentPackaging.getContent();
    }

    return null;
};

const arePackagingsEqualWithName = (packaging0 : Packaging, packaging1 : Packaging) : boolean => {
    const areEqual = packaging0.getUnit() === packaging1.getUnit()
        && packaging0.getName() === packaging1.getName()    // PTODO
        && packaging0.getQuantityOfContent() === packaging1.getQuantityOfContent();

    const content0 = packaging0.getContent();
    const content1 = packaging1.getContent();
    if (content0 && content1) {
        return areEqual && arePackagingsEqualWithName(content0, content1);
    }
    return areEqual && (content0 === null && content1 === null);
};

// this is similar to code in Keypad.tsx, but not quite the same because we allow slightly different options for inventorying
// (no bottle weights here, but we do allow all units of measure that match the base, not just the exact match)
const getAvailableUnitOptionsFromPackaging = (packaging : Packaging, includeUnitOfMeasureConversions : boolean) : Array<ProductQuantityUnit> => {
    const availableUnits : Array<ProductQuantityUnit> = [];

    let currentPackaging : Packaging | null = packaging;
    while (currentPackaging) {
        const currentPackagingId = currentPackaging.getPackagingId();
        const currentPackagingUnit = currentPackaging.getUnit();

        if (currentPackagingId) {
            availableUnits.push(currentPackagingId);
        }

        if (currentPackagingUnit) { // add all base packagings available for mass or volume, if applicable
            if (includeUnitOfMeasureConversions) {
                if (MassUnit.isMassUnit(currentPackagingUnit)) {
                    MassUnit.getUnits().forEach((massUnit) => availableUnits.push(massUnit));
                } else {
                    VolumeUnit.getUnits().forEach((volumeUnit) => {
                        availableUnits.push(volumeUnit);
                    });
                }
            } else {
                availableUnits.push(currentPackagingUnit);
            }
        }

        currentPackaging = currentPackaging.getContent();
    }

    return availableUnits;
};

const getDropdownOptionForUnit = (unit : ProductQuantityUnit, packagingsAndMappings : PackagingsAndMappings) : IOption => {
    return {
        icon: null,
        label: getDisplayTextForProductQuantityUnit(packagingsAndMappings, unit, false),
        value: productObjectToJSONSerializer.getProductQuantityUnit(unit),
    };
};

const getAvailableUnitOptionsForDropdown = (packagingsAndMappings : PackagingsAndMappings) : OptionsAndLabelNameTuples => {
    const availableUnits = getAvailableUnitOptionsFromPackaging(packagingsAndMappings.getPackaging(), true);
    const unitsAsDropdownOptions : Array<IOption> = availableUnits.map((unit) => {
        return getDropdownOptionForUnit(unit, packagingsAndMappings);
    });

    return [[null, unitsAsDropdownOptions]];
};

// TODO Cheezy cleanup
const getAvailableUnitOptionsAndLabelNames = (packagingsAndMappings : PackagingsAndMappings, includeUnitOfMeasureConversions : boolean, productQuantityUnitsToInclude? : StringValueSet<ProductQuantityUnit>) : OptionsAndLabelNameTuples => {
    const optionsAndLabelNameTuples : OptionsAndLabelNameTuples = [];

    const packagingIdDropdownOptions : Array<IOption> = [];
    const addedPackagingIds = new StringValueSet<PackagingId>();

    packagingsAndMappings.getPackagingData().forEach((packagingData) => {
        if (!packagingData.deleted) {
            let currentPackaging : Packaging | null = packagingData.packaging;
            while (currentPackaging) {
                const currentPackagingId = currentPackaging.getPackagingId();
                const currentPackagingContent : Packaging | null = currentPackaging.getContent();

                if (currentPackagingId && !addedPackagingIds.has(currentPackagingId) && ((typeof productQuantityUnitsToInclude === 'undefined') || productQuantityUnitsToInclude.has(currentPackagingId))) {
                    const descriptorString = currentPackagingContent ? ` (${ getDisplayTextForPackaging(currentPackaging, (currentPackagingContent.getContent() !== null)) })` : '';

                    packagingIdDropdownOptions.push({
                        icon: null,
                        label: getDisplayTextForProductQuantityUnit(packagingsAndMappings, currentPackagingId, false) + descriptorString,
                        value: currentPackagingId.getValue(),
                    });
                    addedPackagingIds.add(currentPackagingId);
                }
        
                currentPackaging = currentPackagingContent;
            }
        }
    });

    optionsAndLabelNameTuples.push([UNIT_DROPDOWN_LABEL, packagingIdDropdownOptions]);

    if (packagingsAndMappings.getAvailableVolumeUnits().size > 0) {
        const volumeUnitDropdownOptions : Array<IOption> = [];
        VolumeUnit.getUnits().forEach((volumeUnit) => {
            if ((includeUnitOfMeasureConversions || packagingsAndMappings.getAvailableVolumeUnits().has(volumeUnit)) && ((typeof productQuantityUnitsToInclude === 'undefined') || productQuantityUnitsToInclude.has(volumeUnit))) {
                volumeUnitDropdownOptions.push(getDropdownOptionForUnit(volumeUnit, packagingsAndMappings));
            }
        });

        optionsAndLabelNameTuples.push([VOLUME_DROPDOWN_LABEL, volumeUnitDropdownOptions]);
    }

    if (packagingsAndMappings.getAvailableMassUnits().size > 0) {
        const massUnitDropdownOptions : Array<IOption> = [];
        MassUnit.getUnits().forEach((massUnit) => {
            if ((includeUnitOfMeasureConversions || packagingsAndMappings.getAvailableMassUnits().has(massUnit)) && ((typeof productQuantityUnitsToInclude === 'undefined') || productQuantityUnitsToInclude.has(massUnit))) {
                massUnitDropdownOptions.push(getDropdownOptionForUnit(massUnit, packagingsAndMappings));
            }
        });

        optionsAndLabelNameTuples.push([WEIGHT_DROPDOWN_LABEL, massUnitDropdownOptions]);
    }

    return optionsAndLabelNameTuples;
};

const getAvailablePackagingOptionsAndLabelNamesForProducts = (productsById : StringValueMap<ProductId, Product>, includeUnitOfMeasureConversions : boolean) => {
    const packagingOptions : { [packagingName : string] : StringValueMap<ProductId, ProductQuantityUnit> } = {};
    const optionsAndLabelNameTuples : OptionsAndLabelNameTuples = [];

    const packagingNames : Set<string> = new Set();
    const massUnitNames : Set<string> = new Set();
    const volumeUnitNames : Set<string> = new Set();
    productsById.forEach((product, productId) => {
        const optionsAndLabelNames = getAvailableUnitOptionsAndLabelNames(product.getPackagingsAndMappings(), includeUnitOfMeasureConversions);
        optionsAndLabelNames.forEach((value) => {
            value[1].forEach((iOption) => {
                if (!packagingOptions[iOption.label]) {
                    packagingOptions[iOption.label] = new StringValueMap<ProductId, ProductQuantityUnit>();
                }
                switch (value[0]) {
                    case UNIT_DROPDOWN_LABEL:
                        packagingOptions[iOption.label].set(productId, new PackagingId(iOption.value));
                        packagingNames.add(iOption.label);
                        break;
                    case VOLUME_DROPDOWN_LABEL:
                        packagingOptions[iOption.label].set(productId, VolumeUnit.getByVolumeUnitValue(iOption.value));
                        volumeUnitNames.add(iOption.label);
                        break;
                    case WEIGHT_DROPDOWN_LABEL:
                        packagingOptions[iOption.label].set(productId, MassUnit.getByMassUnitValue(iOption.value));
                        massUnitNames.add(iOption.label);
                        break;
                }
            });
        });
    });
    const packagingIdDropdownOptions : Array<IOption> = [];
    const volumeUnitDropdownOptions : Array<IOption> = [];
    const massUnitDropdownOptions : Array<IOption> = [];
    Array.from(packagingNames).sort().forEach((packagingName) => {
        packagingIdDropdownOptions.push({
            icon: null,
            label: packagingName,
            value: packagingName,
        });
    });
    Array.from(massUnitNames).sort().forEach((massUnitName) => {
        volumeUnitDropdownOptions.push({
            icon: null,
            label: massUnitName,
            value: massUnitName,
        });
    });
    Array.from(volumeUnitNames).sort().forEach((volumeUnitName) => {
        massUnitDropdownOptions.push({
            icon: null,
            label: volumeUnitName,
            value: volumeUnitName,
        });
    });

    optionsAndLabelNameTuples.push([UNIT_DROPDOWN_LABEL, packagingIdDropdownOptions]);
    optionsAndLabelNameTuples.push([VOLUME_DROPDOWN_LABEL, volumeUnitDropdownOptions]);
    optionsAndLabelNameTuples.push([WEIGHT_DROPDOWN_LABEL, massUnitDropdownOptions]);

    return {
        packagingOptions,
        optionsAndLabelNameTuples,
    };
};

export const getPackagingWithNewPackagingId = (
    packaging : Packaging,
    oldPackagingIdToNewPackagingId : StringValueMap<PackagingId, PackagingId>,
    generatePackagingIdFunc : () => PackagingId
) : Packaging => {
    const unit = packaging.getUnit();

    if (unit) {
        return new Packaging(null, null, null, null, unit);
    } else {
        const oldPackagingId = packaging.getPackagingId();
        if (oldPackagingId === null) {
            throw new RuntimeException('unexpected oldPackagingId is null');
        }
        const packagingId = oldPackagingIdToNewPackagingId.has(oldPackagingId) ? oldPackagingIdToNewPackagingId.getRequired(oldPackagingId) : generatePackagingIdFunc();
        const content = packaging.getContent();

        const newPackaging = new Packaging(
            packagingId,
            packaging.getName(),
            (content === null) ? null : getPackagingWithNewPackagingId(content, oldPackagingIdToNewPackagingId, generatePackagingIdFunc),
            packaging.getQuantityOfContent(),
            unit
        );

        oldPackagingIdToNewPackagingId.set(oldPackagingId, packagingId);

        return newPackaging;
    }
};

// PTODO
// export const getProductQuantityUnitAndMultiplierByProductQuantityUnitValue = (
//     productPackaging : Packaging,
//     updatedProductPackaging : Packaging
// ) : StringValueMap<PackagingId, QuantityInUnit<PackagingId>> => {
//     const productQuantityUnitAndMultiplierByProductQuantityUnitValue = new StringValueMap<PackagingId, QuantityInUnit<PackagingId>>();

//     const casePackagingId = getPackagingIdForLayer(productPackaging, 1);
//     const containerPackagingId = getPackagingIdForLayer(productPackaging, 0);
//     let eachPackagingId : PackagingId | null = null;
//     const basePackaging = getPackagingToListFromSmallest(productPackaging)[0];
//     if ((basePackaging.getPackagingId() !== null) && (basePackaging.getContent() === null)) {
//         eachPackagingId = basePackaging.getPackagingId();
//     }

//     const updatedCasePackagingId = getPackagingIdForLayer(updatedProductPackaging, 1);
//     const updatedContainerPackagingId = getPackagingIdForLayer(updatedProductPackaging, 0);
//     let updatedEachPackagingId : PackagingId | null = null;
//     const updatedBasePackaging = getPackagingToListFromSmallest(updatedProductPackaging)[0];
//     if ((updatedBasePackaging.getPackagingId() !== null) && (updatedBasePackaging.getContent() === null)) {
//         updatedEachPackagingId = updatedBasePackaging.getPackagingId();
//     }

//     // map case -> case, else container
//     if (casePackagingId !== null) {
//         let casePackagingIdMappedProductQuantityUnit : ProductQuantityUnit;
//         if (updatedCasePackagingId !== null) {
//             casePackagingIdMappedProductQuantityUnit = updatedCasePackagingId;
//         } else {
//             if (updatedContainerPackagingId === null) {
//                 throw new RuntimeException('unexpected');
//             }

//             casePackagingIdMappedProductQuantityUnit = updatedContainerPackagingId;
//         }

//         productQuantityUnitAndMultiplierByProductQuantityUnitValue.set(
//             casePackagingId,
//             new QuantityInUnit(1, casePackagingIdMappedProductQuantityUnit)
//         );
//     }

//     // map container -> container
//     if ((containerPackagingId !== null) && (updatedContainerPackagingId !== null)) {
//         productQuantityUnitAndMultiplierByProductQuantityUnitValue.set(
//             containerPackagingId,
//             new QuantityInUnit(1, updatedContainerPackagingId)
//         );
//     } else {
//         throw new RuntimeException('unexpected');
//     }

//     // map each -> each, else container
//     if (eachPackagingId !== null) {
//         let eachPackagingIdMappedProductQuantityUnit : ProductQuantityUnit;
//         if (updatedEachPackagingId !== null) {
//             eachPackagingIdMappedProductQuantityUnit = updatedEachPackagingId;
//         } else {
//             if (updatedContainerPackagingId === null) {
//                 throw new RuntimeException('unexpected');
//             }

//             eachPackagingIdMappedProductQuantityUnit = updatedContainerPackagingId;
//         }

//         productQuantityUnitAndMultiplierByProductQuantityUnitValue.set(
//             eachPackagingId,
//             new QuantityInUnit(1, eachPackagingIdMappedProductQuantityUnit)
//         );
//     }

//     // # Remove mappings where key -> value match
//     productQuantityUnitAndMultiplierByProductQuantityUnitValue.forEach((quantityInUnit, packagingId) => {
//         if (packagingId.equals(quantityInUnit.getUnit())) {
//             productQuantityUnitAndMultiplierByProductQuantityUnitValue.delete(packagingId);
//         }
//     });

//     return productQuantityUnitAndMultiplierByProductQuantityUnitValue;
// };

// const getPackagingIdForLayer = (packaging : Packaging, index : number) : PackagingId | null => {
//     const packagingIdsFromSmallest : Array<PackagingId> = [];

//     let currentPackaging : Packaging | null = packaging;
//     while (currentPackaging) {
//         const currentPackagingId = currentPackaging.getPackagingId();
//         const currentContent = currentPackaging.getContent();
//         if (currentContent && currentPackagingId) {
//             packagingIdsFromSmallest.splice(0, 0, currentPackagingId);
//         }

//         currentPackaging = currentPackaging.getContent();
//     }

//     if (index >= packagingIdsFromSmallest.length) {
//         return null;
//     }

//     return packagingIdsFromSmallest[index];
// };

// const getPackagingToListFromSmallest = (packaging : Packaging) => {
//     const packagingFromSmallest : Array<Packaging> = [];

//     let currentPackaging : Packaging | null = packaging;
//     while (currentPackaging) {
//         packagingFromSmallest.splice(0, 0, currentPackaging);
//         currentPackaging = currentPackaging.getContent();
//     }

//     return packagingFromSmallest;
// };

const generatePackagingId = () : PackagingId => {
    return new PackagingId(uuidv4());
};

const getBasePackaging = (packaging : Packaging) : Packaging => {
    const content = packaging.getContent();
    if (content === null) {
        return packaging;
    }

    return getBasePackaging(content);
};

const getDisplayTextForPackaging = (packaging : Packaging, showContainerType : boolean) => {
    const oldPackaging = oldPackagingUtils.getOldPackagingFromPackaging(packaging);
    return oldPackagingUtils.getDisplayTextForPackaging(oldPackaging, showContainerType);
};

const getDisplayTextForVolumeOrMassUnit = (volumeOrMassUnit : VolumeUnit | MassUnit) => {
    switch (volumeOrMassUnit) {
        case VolumeUnit.LITER:
            return 'L';
        case VolumeUnit.MILLILITER:
            return 'ml';
        case VolumeUnit.CENTILITER:
            return  'cl';
        case VolumeUnit.GALLON:
            return 'gal';
        case VolumeUnit.QUART:
            return  'qt';
        case VolumeUnit.PINT:
            return 'pt';
        case VolumeUnit.OUNCE:
            return 'fl. oz';
        case VolumeUnit.BAR_SPOON:
            return 'bsp';
        case VolumeUnit.DASH:
            return 'dash';
        case MassUnit.GRAM:
            return 'g';
        case MassUnit.KILOGRAM:
            return 'kg';
        case MassUnit.POUND:
            return 'lbs';
        case MassUnit.DRY_OUNCE:
            return 'oz (dry)';
        default:
            throw new RuntimeException('unexpected value for unit');
    }
};

const getBottlesInPreferredReportingUnit = (product: Product) => {
    return PackagingUtils.convertProductQuantityToUnit(
        product.getPackagingsAndMappings(),
        new QuantityInUnit<ProductQuantityUnit>(
            1,
            PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging())
        ),
        product.getPreferredReportingUnit()
    ).getQuantity();
};

const isCase = (
    productQuantityUnit : ProductQuantityUnit,
    packagingsAndMappings : PackagingsAndMappings
) => {
    const resolvedProductQuantityUnit = PackagingUtils.resolveProductQuantityUnit(
        new QuantityInUnit(0, productQuantityUnit),
        packagingsAndMappings.getMappings()
    ).getUnit();
    if (!(resolvedProductQuantityUnit instanceof PackagingId)) {
        return false;
    }
    return packagingsAndMappings.getAvailablePackagingByPackagingId().getRequired(resolvedProductQuantityUnit).getName() === PackagingUnit.CASE;
};

const getChildProductQuantityUnit = (
    packaging : Packaging
) : ProductQuantityUnit | null => {
    const content = packaging.getContent();
    if (content === null) {
        return null;
    }
    return content.getPackagingId() == null ? content.getUnit() : content.getPackagingId();
};

export const PackagingUtils = {
    resolveProductQuantityUnit,
    convertProductQuantityToUnit,
    getContainerPackagingId,
    getDisplayTextForPackaging,
    getDisplayTextForProductQuantityUnit,
    getPackagingDisplayTextForProductQuantityUnit,
    getDisplayTextForProductQuantityInUnit,
    getPackagingForContainedPackagingName,
    getAvailablePackagingOptionsAndLabelNamesForProducts,
    arePackagingsEqualWithName,
    isProductQuantityUnitCompatibleWithPackagings,
    getBaseUnitOfPackaging,
    getAvailableUnitOptionsFromPackaging,
    getDropdownOptionForUnit,
    getAvailableUnitOptionsForDropdown,
    getAvailableUnitOptionsAndLabelNames,
    getPackagingForContainedPackagingId,
    generatePackagingId,
    getBasePackaging,
    productQuantityUnitsAreEqual,
    getPackagingWithNewPackagingId,
    getBottlesInPreferredReportingUnit,
    isCase,
    getChildProductQuantityUnit,
    // getProductQuantityUnitAndMultiplierByProductQuantityUnitValue,
};
