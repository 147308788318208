import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { Conversions } from 'api/Product/model/Conversions';
import { Mappings } from 'api/Product/model/Mappings';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { ProductMergeEvent } from 'api/Product/model/ProductMergeEvent';
import { OldPackaging } from 'api/Product/model/OldPackaging';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingWeight } from 'api/Product/model/PackagingWeight';
import { Price } from 'api/Product/model/Price';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import {
    IConversionsJSONObject,
    IMappingsJSONObject,
    IMassJSON,
    IMergeEventJsonObject,
    IOldPackagingJSONObject,
    IPackagingDataJSONObject,
    IPackagingJSONObject,
    ICategoryJsonObject,
    IProductJSONObject,
    IQuantityInUnitJSONObject,
    IWeightsByPackagingIdJSONObject
} from 'api/Product/serializer/IProductJSONObject';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { UserAccountUtils } from 'api/UserAccount/utils/UserAccountUtils';

export class ProductJSONToObjectSerializer {

    public getProduct(
        productObject : IProductJSONObject
    ) : Product {
        if (typeof productObject.packages === 'undefined') { // PTODO Don't need this check when all is done
            throw new RuntimeException('new_package is unexpectedly not defined');
        }

        if (typeof productObject.package_mappings === 'undefined') { // PTODO Don't need this check when all is done
            throw new RuntimeException('package_mappings is unexpectedly not defined');
        }

        if (typeof productObject.conversions === 'undefined') { // PTODO Don't need this check when all is done
            throw new RuntimeException('conversions is unexpectedly not defined');
        }

        if (typeof productObject.preferred_base_unit === 'undefined') { // PTODO Don't need this check when all is done
            throw new RuntimeException('preferred_base_unit is unexpectedly not defined');
        }

        if (typeof productObject.preferred_reporting_unit === 'undefined') { // PTODO Don't need this check when all is done
            throw new RuntimeException('preferred_reporting_unit is unexpectedly not defined');
        }

        if (typeof productObject.weights_by_package_id === 'undefined') { // PTODO Don't need this check when all is done
            throw new RuntimeException('weights_by_package_id is unexpectedly not defined');
        }

        if (typeof productObject.last_update_event === 'undefined') {
            throw new RuntimeException('last_update_event is unexpectedly not defined');
        }

        if (typeof productObject.new_product_category_id === 'undefined') {
            throw new RuntimeException('new_product_category_id is unexpectedly not defined');
        }

        if (productObject.price.iso_4217_currency_code !== 'USD' || productObject.deposit.iso_4217_currency_code !== 'USD') {
            throw new RuntimeException('unexpected currency code');
        }

        const categoryId = productObject.new_product_category_id;

        // In Python we allow product_type, sku, and note to be None, but they are effectively empty strings + this helps with UI concerns
        return new Product(
            productObject.brand,
            productObject.name,
            this.getPackagingsAndMappings(productObject.packages, productObject.package_mappings, productObject.preferred_base_unit, productObject.conversions),
            this.getProductQuantityUnit(productObject.preferred_reporting_unit),
            this.getWeightsByPackagingId(productObject.weights_by_package_id),
            productObject.product_category_id,
            (categoryId && categoryId.length >= 0) ? new CategoryId(categoryId) : null,
            productObject.product_type || '',
            this.getPrice(productObject.price, productObject.price_unit),
            productObject.deposit.value,
            productObject.sku || '',
            productObject.gl_code || '',
            productObject.note || '',
            UserAccountUtils.getUserAccountIdAndTimestampObjectFromJSON(productObject.last_update_event)
        );
    }

    public getProductsById(
        productObjectsByIdValue : {[productIdValue : string] : IProductJSONObject}
    ) : StringValueMap<ProductId, Product> {
        const productsById = new StringValueMap<ProductId, Product>();
        Object.keys(productObjectsByIdValue).forEach((productIdValue : string) => {
            productsById.set(
                this.getProductId(productIdValue),
                this.getProduct(productObjectsByIdValue[productIdValue])
            );
        });

        return productsById;
    }

    public getProductHashesById(
        productHashesByIdValue : {[productIdValue : string] : string}
    ) : StringValueMap<ProductId, string> {
        const productHashesById = new StringValueMap<ProductId, string>();
        Object.keys(productHashesByIdValue).forEach((productIdValue : string) => {
            productHashesById.set(
                this.getProductId(productIdValue),
                productHashesByIdValue[productIdValue]
            );
        });

        return productHashesById;
    }

    public getProductIsDeletedById(
        productIdDeletedByIdValue : {[productIdValue : string] : boolean}
    ) : StringValueMap<ProductId, boolean> {
        const productIsDeletedById = new StringValueMap<ProductId, boolean>();
        Object.keys(productIdDeletedByIdValue).forEach((productIdValue : string) => {
            productIsDeletedById.set(
                this.getProductId(productIdValue),
                productIdDeletedByIdValue[productIdValue]
            );
        });

        return productIsDeletedById;
    }

    public getOldPackaging(
        packageObject : IOldPackagingJSONObject,
    ) : OldPackaging {
        return new OldPackaging(
            (packageObject.content === null) ? null : this.getOldPackaging(packageObject.content),
            packageObject.quantity_of_content,
            oldPackagingUtils.getUnitFromValue(packageObject.unit)
        );
    }

    public getPackaging(
        packageObject : IPackagingJSONObject,
    ) : Packaging {
        const unit = (packageObject.unit === null) ? null : oldPackagingUtils.getUnitFromValue(packageObject.unit);
        if (!(unit === null || MassUnit.isMassUnit(unit) || VolumeUnit.isVolumeUnit(unit))) {
            throw new RuntimeException(`unit is unexpectedly PackagingUnit or BaseUnit ${ unit }`);
        }

        return new Packaging(
            (packageObject.package_id === null) ? null : this.getPackagingId(packageObject.package_id),
            packageObject.name,
            (packageObject.content === null) ? null : this.getPackaging(packageObject.content),
            packageObject.quantity_of_content,
            unit
        );
    }

    public getProductQuantityUnit (
        productQuantityUnitValue : string
    ) : ProductQuantityUnit {
        if (MassUnit.isMassUnitValue(productQuantityUnitValue)) {
            return MassUnit.getByMassUnitValue(productQuantityUnitValue);
        } else if (VolumeUnit.isVolumeUnitValue(productQuantityUnitValue)) {
            return VolumeUnit.getByVolumeUnitValue(productQuantityUnitValue);
        } else {
            return this.getPackagingId(productQuantityUnitValue);
        }
    }

    public getQuantityInUnit(
        quantityInUnitJSONObject : IQuantityInUnitJSONObject
    ) : QuantityInUnit<ProductQuantityUnit> {
        return new QuantityInUnit(
            quantityInUnitJSONObject.quantity,
            this.getProductQuantityUnit(quantityInUnitJSONObject.product_quantity_unit)
        );
    }

    public getPackagingsAndMappings(
        packagingDataObject : IPackagingDataJSONObject,
        mappingsObject : IMappingsJSONObject,
        baseUnitValue : string,
        conversionsObject : IConversionsJSONObject,
    ) : PackagingsAndMappings {
        const packagingData = packagingDataObject.map((p) => {
            return {
                deleted: p.deleted,
                isActive: p.is_active,
                packaging: this.getPackaging(p.package)
            };
        });

        return new PackagingsAndMappings(
            packagingData,
            this.getMappings(mappingsObject),
            this.getConversions(baseUnitValue, conversionsObject),
        );
    }

    public getMappings(
        mappingsObject : IMappingsJSONObject,
    ) : Mappings {
        const {
            package_id_mappings,
            unit_of_measure_mappings,
        } = mappingsObject;

        const packagingIdMappings = new StringValueMap<PackagingId, QuantityInUnit<PackagingId>>();
        Object.keys(package_id_mappings).forEach((packagingIdValue) => {
            const productQuantityUnit = this.getProductQuantityUnit(packagingIdValue);
            if (!(productQuantityUnit instanceof PackagingId)) {
                throw new RuntimeException('productQuantityUnit unit is unexpectedly not a PackagingId');
            }

            const quantityInUnit = this.getQuantityInUnit(package_id_mappings[packagingIdValue]);
            if (!(quantityInUnit.getUnit() instanceof PackagingId)) {
                throw new RuntimeException('mapping unit is unexpectedly not PackagingId');
            }

            packagingIdMappings.set(productQuantityUnit, quantityInUnit as QuantityInUnit<PackagingId>);
        });

        const unitOfMeasureMappings = new Map<MassUnit | VolumeUnit, QuantityInUnit<PackagingId>>();
        Object.keys(unit_of_measure_mappings).forEach((unitOfMeasureValue) => {
            const productQuantityUnit = this.getProductQuantityUnit(unitOfMeasureValue);
            if (productQuantityUnit instanceof PackagingId) {
                throw new RuntimeException('productQuantityUnit unit is unexpectedly a PackagingId');
            }

            const quantityInUnit = this.getQuantityInUnit(unit_of_measure_mappings[unitOfMeasureValue]);
            if (!(quantityInUnit.getUnit() instanceof PackagingId)) {
                throw new RuntimeException('mapping unit is unexpectedly not PackagingId');
            }

            unitOfMeasureMappings.set(productQuantityUnit, quantityInUnit as QuantityInUnit<PackagingId>);
        });

        return new Mappings(
            packagingIdMappings,
            unitOfMeasureMappings
        );
    }

    public getConversions(
        baseUnitValue : string,
        conversionsObject : IConversionsJSONObject,
    ) : Conversions {
        const packagingIdConversions = new StringValueMap<PackagingId, number>();
        const unitOfMeasureConversions = new Map<MassUnit | VolumeUnit, number>();

        Object.keys(conversionsObject).forEach((productQuantityUnitValue) => {
            const productQuantityUnit = this.getProductQuantityUnit(productQuantityUnitValue);
            const quantityInUnit = conversionsObject[productQuantityUnitValue];

            if (productQuantityUnit instanceof PackagingId) {
                packagingIdConversions.set(productQuantityUnit, quantityInUnit);
            } else {
                unitOfMeasureConversions.set(productQuantityUnit, quantityInUnit);
            }
        });

        return new Conversions(
            this.getProductQuantityUnit(baseUnitValue),
            packagingIdConversions,
            unitOfMeasureConversions
        );
    }

    public getWeightsByPackagingId(
        weightsByPackagingIdObject : IWeightsByPackagingIdJSONObject,
    ) : StringValueMap<PackagingId, PackagingWeight> {
        const weightsByPackagingId = new StringValueMap<PackagingId, PackagingWeight>();

        Object.keys(weightsByPackagingIdObject).forEach((packagingIdValue) => {
            const packagingId = this.getPackagingId(packagingIdValue);
            const packagingWeightObject = weightsByPackagingIdObject[packagingIdValue];

            weightsByPackagingId.set(
                packagingId,
                new PackagingWeight(
                    packagingWeightObject.empty_weight === null ? null : this.getMass(packagingWeightObject.empty_weight),
                    packagingWeightObject.full_weight === null ? null : this.getMass(packagingWeightObject.full_weight),
                )
            );
        });

        return weightsByPackagingId;
    }

    public getMergeEvent(
        mergeEventObject : IMergeEventJsonObject | null
    ) : ProductMergeEvent | null {
        if (!mergeEventObject) {
            return null;
        }
        const mergedFromProductIds = mergeEventObject.merged_from_product_ids;
        const storageAreasAffected = mergeEventObject.storage_areas_affected;
        const salesItemsAffected = mergeEventObject.sales_items_affected;
        const cartItemsAffected = mergeEventObject.cart_items_affected;

        const mergedFromProductIdSet = new StringValueSet<ProductId>();
        const storageAreasAffectedSet = new StringValueSet<StorageAreaId>();
        const salesItemsAffectedSet = new StringValueSet<SalesItemId>();
        const cartItemsAffectedSet = new StringValueSet<ProductId>();
        mergedFromProductIds.forEach((productId) => {
            mergedFromProductIdSet.add(this.getProductId(productId));
        });
        storageAreasAffected.forEach((storageAreaId) => {
            storageAreasAffectedSet.add(new StorageAreaId(storageAreaId));
        });
        salesItemsAffected.forEach((salesItemId) => {
            salesItemsAffectedSet.add(new SalesItemId(salesItemId));
        });
        cartItemsAffected.forEach((cartItemId) => {
            cartItemsAffectedSet.add(this.getProductId(cartItemId));
        });
        return new ProductMergeEvent(
            UserAccountUtils.getUserAccountIdAndTimestampObjectFromJSON(mergeEventObject.user_account_id_and_timestamp),
            this.getProductId(mergeEventObject.merged_to_product_id),
            mergedFromProductIdSet,
            storageAreasAffectedSet,
            salesItemsAffectedSet,
            cartItemsAffectedSet,
        );
    }

    public getCategory(
        categoryJson : ICategoryJsonObject
    ) : Category {
        return new Category(
            categoryJson.name,
            categoryJson.gl_code,
            categoryJson.is_deleted,
            categoryJson.category_hash,
        );
    }

    public getCategoriesById(
        categoryObjectsByIdValue : {[categoryIdValue : string] : ICategoryJsonObject}
    ) : StringValueMap<CategoryId, Category> {
        const categoriesById = new StringValueMap<CategoryId, Category>();
        Object.keys(categoryObjectsByIdValue).forEach((categoryIdValue : string) => {
            categoriesById.set(
                this.getCategoryId(categoryIdValue),
                this.getCategory(categoryObjectsByIdValue[categoryIdValue])
            );
        });

        return categoriesById;
    }

    public getMass(
        mass : IMassJSON
    ) : QuantityInUnit<MassUnit> {
        return new QuantityInUnit(
            mass.quantity,
            MassUnit.getByMassUnitValue(mass.unit_of_mass)
        );
    }

    public getPrice(
        price : {iso_4217_currency_code : string, value : number},
        priceUnitValue : string
    ) : Price {
        return new Price(
            price.value,
            oldPackagingUtils.getUnitFromValue(priceUnitValue)
        );
    }

    public getProductId(
        productIdValue : string
    ) : ProductId {
        return new ProductId(productIdValue);
    }

    public getCategoryId(
        categoryIdValue : string
    ) : CategoryId {
        return new CategoryId(categoryIdValue);
    }

    private getPackagingId(
        packagingIdValue : string
    ) : PackagingId {
        return new PackagingId(packagingIdValue);
    }
}
