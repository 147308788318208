import React from 'react';

import { connect } from 'react-redux';

import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

import { SignInForm } from '../SignInForm/SignInForm';
import { SignUpForm } from '../SignUpForm/SignUpForm';
import { ThanksForRegistering } from '../ThanksForRegistering/ThanksForRegistering';

import { signInFormFieldName } from '../SignInForm/reducers';
import { signUpFormFieldName } from '../SignUpForm/reducers';

import { ISignUpData } from '../ISignUpData';
import { SignInFormActions, SignInFormDispatch } from '../SignInForm/actions';
import { SignUpFormDispatch, SignUpFormActions } from '../SignUpForm/actions';
import { ThanksForRegisteringActions, ThanksForRegisteringDispatch } from '../ThanksForRegistering/actions';
import { SignUpOptionsFormActions } from './actions';
import { IActiveFormState, ISignUpOptionsState } from './reducers';

import { AddressFormFieldName } from 'shared/components/AddressForm/AddressForm';
import { ModalFlex } from 'shared/components/ModalFlex/ModalFlex';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { PathUtils } from 'shared/utils/pathUtils';

import '../../../css/SignUpOptionsForm.scss';

export interface ISignUpOptionsProps {
    signUpOptionsState : ISignUpOptionsState;
    availableFormOptions : Array<IActiveFormState>;
    isEmbeddedInModal : boolean;
    isDismissible : boolean;
    formIsVisibleOnLoad : boolean;
    additionalUserFieldsAreShown : boolean;
    additionalRetailerFieldsAreShown : boolean;
    additionalRetailerFieldsAreRequired : boolean;
    onSuccessfulSignIn : () => Promise<void>;
    onSuccessfulSignUp : (userAccountIdentifier : UserAccountId, signUpData : ISignUpData) => Promise<void>;
}

export interface IBoundSignUpOptionsProps extends ISignUpOptionsProps {
    dispatch : SignUpFormDispatch & ThanksForRegisteringDispatch & SignInFormDispatch;
}

export class SignUpOptions extends React.Component<IBoundSignUpOptionsProps, object> {

    public UNSAFE_componentWillMount() {
        const {
            dispatch,
            formIsVisibleOnLoad,
        } = this.props;

        dispatch(SignUpOptionsFormActions.onSetFormIsShown(formIsVisibleOnLoad));
    }

    public render() {
        const {
            dispatch,
            availableFormOptions,
            isEmbeddedInModal,
            isDismissible,
            additionalUserFieldsAreShown,
            additionalRetailerFieldsAreShown,
            additionalRetailerFieldsAreRequired,
            signUpOptionsState,
            onSuccessfulSignIn,
            onSuccessfulSignUp,
        } = this.props;

        const activeForm = signUpOptionsState.signUpOptionsForm.activeForm;

        const handleModalCloseButtonClick = () => {
            return dispatch(SignUpOptionsFormActions.onSetFormIsShown(false));
        };
        // const descriptionsByActiveForm : { [activeFormKey in IActiveFormState] : string } = {
        //     signUpForm : 'Gain access to all of our premium content, including ebooks, trends, and the Drink Price Tool, free!',
        //     signInForm : 'Already have an account? Log in here.',
        //     signUpAndCreateRetailerForm : 'Try out our full suite of inventory, ordering and reporting software at your bar or restaurant.',
        // };
        const getSignUpOptionsForm = () : JSX.Element => {
            const signUpOptionsFormTabs = (
                <div className="sign-up-or-sign-in-form-tabs">
                    { availableFormOptions.map((formOption) => this.getTabElement(formOption, formOption === activeForm)) }
                </div>
            );

            const getActiveForm = () : JSX.Element => {
                let renderedForm;

                const onSignUpFormFieldChange = (field : signUpFormFieldName, value : string | boolean) => {
                    return dispatch(SignUpFormActions.onFormFieldChange(field, value));
                };

                const onSignUpFormFieldBlur = (field : signUpFormFieldName, value : string | boolean) => {
                    return dispatch(SignUpFormActions.onFormFieldBlur(field, value));
                };

                const setAddressFormField = (fieldName : AddressFormFieldName, validationInputData : IValidationInputData) => {
                    return dispatch(SignUpFormActions.onSetAddressFormField(fieldName, validationInputData));
                };

                switch (activeForm) {
                    case 'signUpForm':
                        const onSignUpFormSubmit = () => {
                            dispatch(SignUpFormActions.onSubmit(signUpOptionsState.signUpOptionsForm.signUpForm, additionalRetailerFieldsAreShown, additionalRetailerFieldsAreRequired, additionalRetailerFieldsAreRequired))
                            .then((userAccountIdentifier : UserAccountId | void) => {
                                if (userAccountIdentifier) {
                                    const validationInputDataByFieldName = signUpOptionsState.signUpOptionsForm.signUpForm.validationInputDataByFieldName;
                                    onSuccessfulSignUp(
                                        userAccountIdentifier,
                                        {
                                            firstName : validationInputDataByFieldName.firstName.value,
                                            lastName : validationInputDataByFieldName.lastName.value,
                                            emailAddress : validationInputDataByFieldName.emailAddress.value,
                                            phone : validationInputDataByFieldName.phone.value,
                                            barName : validationInputDataByFieldName.barName.value,
                                            country : validationInputDataByFieldName.country.value,
                                            city : validationInputDataByFieldName.city.value,
                                            state : validationInputDataByFieldName.state.value,
                                            streetAddressLine1 : validationInputDataByFieldName.streetAddressLine1.value,
                                            streetAddressLine2 : validationInputDataByFieldName.streetAddressLine2.value,
                                            zipCode : validationInputDataByFieldName.zipCode.value,
                                            optionalZipCode : validationInputDataByFieldName.optionalZipCode.value,
                                            subscribeToBlog : validationInputDataByFieldName.subscribeToBlog.value,
                                        }
                                    )
                                    .then(() => {
                                        dispatch(SignUpFormActions.onSetHasSuccessfullySubmitted(true));
                                    });
                                }
                            });
                        };

                        renderedForm = (
                            <SignUpForm
                                headerTitle="Create your BevSpot account now!"
                                headerMessage="Get unlimited access to free tools, premium content, and join thousands of other Food & Beverage professionals who are changing the way they run their operations."
                                signUpFormState={ signUpOptionsState.signUpOptionsForm.signUpForm }
                                onFormFieldChange={ onSignUpFormFieldChange }
                                onFormFieldBlur={ onSignUpFormFieldBlur }
                                additionalUserFieldsAreShown={ additionalUserFieldsAreShown }
                                additionalRetailerFieldsAreShown={ additionalRetailerFieldsAreShown }
                                additionalRetailerFieldsAreRequired={ additionalRetailerFieldsAreRequired }
                                submitButtonContents="JOIN BEVSPOT"
                                onSubmit={ onSignUpFormSubmit }
                                setAddressFormField={ setAddressFormField }
                            />
                        );
                        break;

                    case 'signUpAndCreateRetailerForm':
                        const startFreeTrialUrl = PathUtils.getAbsolutePathForRequest(`/start_free_trial${ window.location.search }`);

                        const onSignUpAndCreateRetailerFormSubmit = () => {
                            dispatch(SignUpFormActions.onSubmit(signUpOptionsState.signUpOptionsForm.signUpForm, false, false, true))
                            .then((userAccountIdentifier : UserAccountId | void) => {
                                if (userAccountIdentifier) {
                                    const validationInputDataByFieldName = signUpOptionsState.signUpOptionsForm.signUpForm.validationInputDataByFieldName;
                                    onSuccessfulSignUp(
                                        userAccountIdentifier,
                                        {
                                            firstName : validationInputDataByFieldName.firstName.value,
                                            lastName : validationInputDataByFieldName.lastName.value,
                                            emailAddress : validationInputDataByFieldName.emailAddress.value,
                                            phone : validationInputDataByFieldName.phone.value,
                                            barName : validationInputDataByFieldName.barName.value,
                                            country : validationInputDataByFieldName.country.value,
                                            city : validationInputDataByFieldName.city.value,
                                            state : validationInputDataByFieldName.state.value,
                                            streetAddressLine1 : validationInputDataByFieldName.streetAddressLine1.value,
                                            streetAddressLine2 : validationInputDataByFieldName.streetAddressLine2.value,
                                            zipCode : validationInputDataByFieldName.zipCode.value,
                                            optionalZipCode : validationInputDataByFieldName.optionalZipCode.value,
                                            subscribeToBlog : validationInputDataByFieldName.subscribeToBlog.value,
                                        }
                                    )
                                    .then(() => {
                                        window.location.href = startFreeTrialUrl;
                                    });
                                }
                            });
                        };

                        renderedForm = (
                            <div>
                                <SignUpForm
                                    headerTitle="Try BevSpot Food & Beverage for free"
                                    headerMessage="No credit card required. Get started in less than 5 minutes."
                                    signUpFormState={ signUpOptionsState.signUpOptionsForm.signUpForm }
                                    onFormFieldChange={ onSignUpFormFieldChange }
                                    onFormFieldBlur={ onSignUpFormFieldBlur }
                                    additionalUserFieldsAreShown={ false }
                                    additionalRetailerFieldsAreShown={ false }
                                    additionalRetailerFieldsAreRequired={ false }
                                    submitButtonContents="START FREE TRIAL"
                                    onSubmit={ onSignUpAndCreateRetailerFormSubmit }
                                    setAddressFormField={ this.doNothing }
                                />
                                <small>
                                    Already have a user account? <a href={ startFreeTrialUrl }>Log In</a>
                                </small>
                            </div>
                        );
                        break;

                    case 'signInForm':
                        const onSignInFormFieldChange = (field : signInFormFieldName, value : string) => {
                            return dispatch(SignInFormActions.onFormFieldChange(field, value));
                        };

                        const onSignInFormFieldBlur = (field : signInFormFieldName, value : string) => {
                            return;
                        };

                        const onSignInFormSubmit = () => {
                            dispatch(SignInFormActions.onSubmit(signUpOptionsState.signUpOptionsForm.signInForm))
                            .then((success : boolean | void) => {
                                if (success) {
                                    onSuccessfulSignIn()
                                    .then(() => {
                                        if (isEmbeddedInModal) {
                                            dispatch(SignInFormActions.onResetForm());
                                            dispatch(SignUpOptionsFormActions.onSetFormIsShown(false));
                                        }
                                    });
                                }
                            });
                        };

                        renderedForm = (
                            <SignInForm
                                signInFormState={ signUpOptionsState.signUpOptionsForm.signInForm }
                                onFormFieldChange={ onSignInFormFieldChange }
                                onFormFieldBlur={ onSignInFormFieldBlur }
                                onSubmit={ onSignInFormSubmit }
                            />
                        );
                        break;

                    default:
                        throw new RuntimeException('unimplemented for activeForm: ' + activeForm);
                }

                return (
                    <div className="form-section">
                        { renderedForm }
                    </div>
                );
            };

            if (isEmbeddedInModal) {
                return (
                    <ModalFlex
                        className="sign-up-or-sign-in-modal"
                        buttons={ null }
                        closeButtonShown={ false }
                        footerLabel={ null }
                        footerLeftButton={ null }
                        headerText={
                            <div>
                                { isDismissible &&
                                    <div className="sign-up-or-sign-in-modal-close-button" onClick={ handleModalCloseButtonClick }>
                                        <div className="bevicon bevico-close" />
                                    </div>
                                }
                                { signUpOptionsFormTabs }
                            </div>
                        }
                        modalClasses={ {
                            headerClasses: null,
                            bodyClasses: null,
                            footerClasses: null,
                        } }
                        onClose={ null }
                    >
                        <div className="sign-up-or-sign-in-form">
                            { getActiveForm() }
                        </div>
                    </ModalFlex>
                );
            } else {
                return (
                    <div className="sign-up-or-sign-in-form">
                        { signUpOptionsFormTabs }
                        { getActiveForm() }
                    </div>
                );
            }
        };

        const getThanksForRegistering = () : JSX.Element => {
            return (
                <ThanksForRegistering
                    isEmbeddedInModal={ false }
                    onSendEmailAgainClick={ this.onSendEmailAgainClick }
                    onCloseButtonClick={ this.onCloseButtonClick }
                    isResendingEmail={ signUpOptionsState.thanksForRegistering.isResendingEmail }
                    isEmailResent={ signUpOptionsState.thanksForRegistering.isEmailResent }
                />
            );
        };

        const getRenderedComponent = () : JSX.Element | null => {
            if (signUpOptionsState.signUpOptionsForm.formIsShown || !isEmbeddedInModal) {
                if (signUpOptionsState.signUpOptionsForm.signUpForm.hasSuccessfullySubmitted) {
                    return getThanksForRegistering();
                } else {
                    return getSignUpOptionsForm();
                }
            }

            return null;
        };

        return getRenderedComponent();
    }

    private readonly onSendEmailAgainClick = () => {
        this.props.dispatch(ThanksForRegisteringActions.onResendEmail(this.props.signUpOptionsState.signUpOptionsForm.signUpForm.validationInputDataByFieldName.emailAddress.value));
    }

    private readonly onCloseButtonClick = () => {
        this.props.dispatch(SignUpOptionsFormActions.onSetFormIsShown(false));
        this.props.dispatch(SignUpFormActions.onResetForm());
    }

    private getTabElement = (key : IActiveFormState, isActive : boolean) => {
        let tabText : string | null = null;
        switch (key) {
            case 'signUpAndCreateRetailerForm':
                tabText = 'Start Trial';
                break;
            case 'signUpForm':
                tabText = 'Create Account';
                break;
            case 'signInForm':
                tabText = 'Log In';
                break;
            default:
                break;
        }
        const onClick = () => {
            return this.props.dispatch(SignUpOptionsFormActions.onSetActiveForm(key));
        };
        return (
            <a key={ key } className={ isActive ? 'active-tab' : '' } onClick={ onClick }>
                <p className="tab-head">{ tabText }</p>
            </a>
        );
    }

    private readonly doNothing = () => undefined;
}

export interface IConnectedSignUpOptionsProps {
    availableFormOptions : Array<IActiveFormState>;
    isEmbeddedInModal : boolean;
    isDismissible : boolean;
    formIsVisibleOnLoad : boolean;
    additionalUserFieldsAreShown : boolean;
    additionalRetailerFieldsAreShown : boolean;
    additionalRetailerFieldsAreRequired : boolean;
    onSuccessfulSignIn : () => Promise<void>;
    onSuccessfulSignUp : (userAccountIdentifier : UserAccountId, signUpData : ISignUpData) => Promise<void>;
}

interface IState {
    signUpOptionsState : ISignUpOptionsState;
}

const mapStateToProps = (state : IState, ownProps : IConnectedSignUpOptionsProps) : ISignUpOptionsProps => {
    return {
        signUpOptionsState : state.signUpOptionsState,
        availableFormOptions : ownProps.availableFormOptions,
        isEmbeddedInModal : ownProps.isEmbeddedInModal,
        isDismissible : ownProps.isDismissible,
        formIsVisibleOnLoad : ownProps.formIsVisibleOnLoad,
        additionalUserFieldsAreShown : ownProps.additionalUserFieldsAreShown,
        additionalRetailerFieldsAreShown: ownProps.additionalRetailerFieldsAreShown,
        additionalRetailerFieldsAreRequired : ownProps.additionalRetailerFieldsAreRequired,
        onSuccessfulSignIn : ownProps.onSuccessfulSignIn,
        onSuccessfulSignUp : ownProps.onSuccessfulSignUp,
    };
};

export const ConnectedSignUpOptions = connect<ISignUpOptionsProps, object, IConnectedSignUpOptionsProps, IState>(mapStateToProps)(SignUpOptions);
