import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ModalWithHeaderAndFooter } from 'shared/components/ModalWithHeaderAndFooter';
import { InterLocationProductMapping } from 'api/Transfer/model/InterLocationProductMapping';
import { BrandAndName } from 'shared/components/Product/BrandAndName';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

export interface ISummaryItemsModal {
    headerText : string;
    partnerLocationName : string;
    unmappedProductIds : Array<ProductId>;
    addedMappings : Array<InterLocationProductMapping>;
    productsById : StringValueMap<ProductId, Product>;
    modalActions : {
        handleBackButton() : void;
        handleContinueButton() : Promise<void> | void;
    };
}

export class SummaryItemsModal extends React.Component<ISummaryItemsModal, object> {
    public render() {
        const {
            headerText,
            partnerLocationName,
            unmappedProductIds,
            addedMappings,
            productsById,
            modalActions,
        } = this.props;

        const summaryComplete = unmappedProductIds.length === 0 || (addedMappings.length === unmappedProductIds.length);

        let countSummary : JSX.Element;
        const tableRows : Array<JSX.Element> = [];
        if (summaryComplete) {
            countSummary = (
                <div>
                    <span className="bevicon bevico-check"/>
                    <div className="summary-items-ready-count">
                        <p>Items are ready to be transferred.</p>
                    </div>
                    
                    { (addedMappings.length > 0) &&
                        <div>New transfer mappings to create:</div>
                    }
                </div>
            );

            addedMappings.forEach((interLocationProductMapping, index) => {
                const sourceProductId = interLocationProductMapping.getSourceProductId();
                const targetProductId = interLocationProductMapping.getTargetProductId();
                const sourceProduct = productsById.getRequired(sourceProductId);
                const targetProduct = productsById.getRequired(targetProductId);

                const sourceQoP = interLocationProductMapping.getSourceQuantityOfProduct();
                const targetQoP = interLocationProductMapping.getTargetQuantityOfProduct();
                const sourceProductQuantityUnit = PackagingUtils.getDisplayTextForProductQuantityUnit(sourceProduct.getPackagingsAndMappings(), sourceQoP.getUnit(), false);
                const targetProductQuantityUnit = PackagingUtils.getDisplayTextForProductQuantityUnit(targetProduct.getPackagingsAndMappings(), targetQoP.getUnit(), false);
                const sourceProductQuantity = sourceQoP.getQuantity();
                const targetProductQuantity = targetQoP.getQuantity();

                tableRows.push(
                    <div className="summary-item-row col-row cell-ptb-md"  key={ `${index}-${sourceProductId.getValue()}-${targetProductId.getValue()}` }>
                        <div className="cell cell-ptb-md col-xs-5 cell-plr">
                            <BrandAndName
                                brand={ sourceProduct.getBrand() }
                                name={ sourceProduct.getName() }
                                category={ sourceProduct.getProductCategoryId() }
                                subcategory={ sourceProduct.getProductType() }
                            />
                            <span>Base Unit: { sourceProductQuantity }{ sourceProductQuantityUnit }</span>
                        </div>
                        <div className="cell cell-ptb-md col-xs-2 cell-plr">
                            <span className="bevicon bevico-arrow-forward"/>
                        </div>
                        <div className="cell cell-ptb-md col-xs-5 cell-plr">
                            <BrandAndName
                                brand={ targetProduct.getBrand() }
                                name={ targetProduct.getName() }
                                category={ targetProduct.getProductCategoryId() }
                                subcategory={ targetProduct.getProductType() }
                            />
                            <span>Base Unit: { targetProductQuantity }{ targetProductQuantityUnit }</span>
                        </div>
                        { (sourceProductQuantityUnit !== targetProductQuantityUnit || sourceProductQuantity !== targetProductQuantity) && <span className="unmatched-unit-warning">Unit size does not match, this may cause errors in reporting.</span> }
                    </div>
                );
            });
        } else {
            countSummary = (
                <div>
                    <span className="bevicon bevico-error"/>
                    <div className="summary-items-ready-count">
                        <p>Some items are not ready to be transferred.</p>
                    </div>
                </div>
            );

            unmappedProductIds.forEach((productId, index) => {
                const product = productsById.getRequired(productId);

                tableRows.push(
                    <div className="summary-item-row cell-ptb-md"  key={ `${index}-${productId.getValue()}` }>
                        <BrandAndName
                            brand={ product.getBrand() }
                            name={ product.getName() }
                            category={ product.getProductCategoryId() }
                            subcategory={ product.getProductType() }
                        />
                    </div>
                );
            });
        }

        return (
            <ModalWithHeaderAndFooter
                className=""
                closeButtonShown={ false }
                onClose={ null }
                buttons={ [{
                    classes: 'flat',
                    children: summaryComplete ? 'Finish Transfer' : 'Match Items',
                    isDisabled: false,
                    isLoading: false,
                    onClick: () => modalActions.handleContinueButton(),
                }] }
                footerLeftButton={ {
                    classes: 'flat',
                    children: 'Back',
                    isDisabled: false,
                    isLoading: false,
                    onClick: () => modalActions.handleBackButton(),
                } }
                footerLabel={ null }
                headerText={ headerText }
                modalClasses={ {
                    headerClasses: null,
                    bodyClasses: 'content-body-scroll',
                    footerClasses: null,
                } }
            >
                <div className="summary-items-panel">
                    <div className="summary-items-ready">
                        { countSummary }
                    </div>
                    { !summaryComplete &&
                        <div className="summary-items-waiting">
                            <span className="bevicon bevico-warning"/>
                            <div className="summary-items-waiting-count">
                                <p><strong>{ unmappedProductIds.length } items</strong> need to be matched.</p>
                            </div>
                            <div className="summary-items-waiting-instructions">
                                <p>Please take a quick look through the <strong>{ partnerLocationName }</strong> inventory to help us find matching items so that we can complete your transfer.</p>
                            </div>
                        </div>
                    }
                    { tableRows }
                </div>
            </ModalWithHeaderAndFooter>
        );
    }
}
