import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { DocumentProps } from 'react-pdf';
import Viewer from 'react-viewer';

import './css/FileViewer.scss';
import {
    SpreadsheetViewer,
    SUPPORTED_SPREADSHEET_FILE_TYPES
} from 'shared/components/FileViewer/components/SpreadsheetViewer';
import { fileReaderUtils } from 'shared/utils/fileReaderUtils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface IFileViewerProps {
    readonly isShown : boolean;
    readonly isSplitView : boolean;
    readonly filePath : string;
    readonly fileAlias : string;
    readonly file : File | null;
    readonly hiddenCSVHeaders? : Array<string>;
    readonly csvColumnSort? : Array<string>;
    readonly onClose : (() => void) | null;
}

interface IFileViewerState {
    pdfViewer : {
        initialZoom : number;
        zoomScale : number;
        currentPage : number;
        rerender : boolean;
        rotation : number;
    };
    controlsAreShown : boolean;
    fileContent : string | ArrayBuffer | null;
}

interface IPDFPageData {
    originalWidth : number;
    rotate : number;
}

export const FILE_VIEWER_SHOWN_CLASS_NAME = 'file-viewer-shown';
export const FILE_VIEWER_HIDING_CLASS_NAME = 'file-viewer-hiding';

export class FileViewer extends React.Component<IFileViewerProps, IFileViewerState> {
    private fileViewerContainer : HTMLDivElement | undefined;
    private filePageCount : number = 1;
    private pdfDocumentContainerRef : React.RefObject<HTMLDivElement> = React.createRef();

    public constructor(props : IFileViewerProps) {
        super(props);
        this.state = {
            pdfViewer : {
                initialZoom: 1,
                zoomScale: 1,
                currentPage : 0,
                rerender : true,
                rotation : 0,
            },
            controlsAreShown: false,
            fileContent: null,
        };

        this.handleFileContentUpdate(props);
        if (props.isSplitView) {
            // Adapted from InvoiceUploadViewer, applies classes to the body to shift all other content 50% to the right
            $('body').toggleClass(FILE_VIEWER_HIDING_CLASS_NAME, !props.isShown);
            $('body').toggleClass(FILE_VIEWER_SHOWN_CLASS_NAME, props.isShown);
        }
        // Re-render the page because remote image files don't load properly on first render.
        window.setTimeout(() => {
            this.componentDidUpdate();
        }, 25);
    }

    public UNSAFE_componentWillReceiveProps(nextProps : IFileViewerProps) {
        if (nextProps.filePath && (nextProps.filePath !== this.props.filePath)) {
            const nextFileExtension = fileReaderUtils.getFileExtensionFromPath(nextProps.filePath);
            const rerenderForPdf = (nextFileExtension === 'pdf');
            this.handleFileContentUpdate(nextProps);
            this.setState((state) => {
                return {
                    ...state,
                    fileContent : null,
                    pdfViewer : {
                        ...this.state.pdfViewer,
                        rerender : rerenderForPdf,
                        currentPage : 0,
                    },
                };
            });
        }

        if (nextProps.isShown !== this.props.isShown) {
            this.setState((state) => {
                return {
                    ...state,
                    pdfViewer : {
                        ...this.state.pdfViewer,
                        rerender : true,
                    },
                };
            });

            if (nextProps.isSplitView) {
                // Adapted from InvoiceUploadViewer, applies classes to the body to shift all other content 50% to the right
                $('body').toggleClass(FILE_VIEWER_HIDING_CLASS_NAME, !nextProps.isShown);
                $('body').toggleClass(FILE_VIEWER_SHOWN_CLASS_NAME, nextProps.isShown);
            }
        }
        // Added by the generic image viewer for some reason
        document.body.style.overflow = "";
        document.body.style.paddingRight = "";
    }

    public render() {
        const {
            isShown,
            filePath,
            file,
            fileAlias,
            hiddenCSVHeaders,
            csvColumnSort,
            onClose
        } = this.props;

        if (!isShown) {
            return (<span/>);
        }

        const fileExtension = fileReaderUtils.getFileExtensionFromPath(filePath);
        const currentPage = this.state.pdfViewer.currentPage;
        const isSpreadsheet = fileExtension !== null && SUPPORTED_SPREADSHEET_FILE_TYPES.includes(fileExtension.toLowerCase());
        const fileContent = this.state.fileContent;
        const fileIsLoading = (file !== null && fileContent === null) || (isSpreadsheet && fileContent === null);
        const showPDFViewer = fileExtension === 'pdf' && !fileIsLoading;
        const showSpreadsheetViewer = isSpreadsheet && !fileIsLoading;
        const showGenericFileViewer = typeof fileExtension !== 'undefined' && !showPDFViewer && !showSpreadsheetViewer && !fileIsLoading;
        return (
            <div className="file-viewer">
                <div className={ "file-viewer-container " + (showGenericFileViewer ? '' : 'hidden') } ref={ this.fileViewerContainerRefFunction } />
                <Viewer
                    visible={ showGenericFileViewer }
                    images={ showGenericFileViewer ? [{
                        alt: fileAlias ? fileAlias : filePath,
                        src: (file !== null && typeof fileContent === 'string') ? fileContent : filePath,
                        downloadUrl: file !== null ? '' : filePath,
                    }] : [] }
                    activeIndex={ 0 }
                    container={ this.fileViewerContainer }
                    noNavbar={ true }
                    scalable={ false }
                    downloadable={ typeof fileContent === "string" ? false : true }
                    noClose={ onClose ? false : true }
                    onClose={ onClose ? onClose : undefined }
                />

                { showPDFViewer &&
                    <div ref={this.pdfDocumentContainerRef} className="file-viewer-container react-pdfjs-viewer" id="pdfjs-viewer">
                        <div className="react-viewer react-viewer-inline">
                            <div className="react-viewer-mask" />
                            <div className={"react-viewer-close react-viewer-btn " + (onClose ? '' : 'hidden')} onClick={ onClose ? onClose : undefined }>
                                <span className="bevicon react-viewer-icon-close" />
                            </div>
                            <div className={"react-viewer-canvas pdf-viewer"}>
                                { !this.state.pdfViewer.rerender &&
                                    <Document
                                        file={ file ? this.state.fileContent : filePath }
                                        onLoadSuccess={ this.onPDFViewerDocumentComplete }
                                    >
                                        <Page
                                            pageIndex={ this.state.pdfViewer.currentPage }
                                            scale={ this.state.pdfViewer.zoomScale }
                                            onLoadSuccess={ this.onPDFPageLoadSuccess }
                                            rotate={ this.state.pdfViewer.rotation }
                                        />
                                    </Document>
                                }
                            </div>
                            <div className="react-viewer-footer">
                                <p className="react-viewer-attribute"><span className="react-viewer-img-details">{ fileAlias ? fileAlias : filePath }</span></p>
                                { this.state.controlsAreShown &&
                                    <ul className="react-viewer-toolbar">
                                        <li className="react-viewer-btn" onClick={ this.onPDFViewerZoomIn }>
                                            <i className="react-viewer-icon react-viewer-icon-zoomIn" />
                                        </li>
                                        <li className="react-viewer-btn" onClick={ this.onPDFViewerZoomOut }>
                                            <i className="react-viewer-icon react-viewer-icon-zoomOut" />
                                        </li>
                                        <li className="react-viewer-btn" onClick={ this.onPDFViewerRotateLeft }>
                                            <i className="react-viewer-icon react-viewer-icon-rotateLeft" />
                                        </li>
                                        <li className="react-viewer-btn" onClick={ this.onPDFViewerRotateRight }>
                                            <i className="react-viewer-icon react-viewer-icon-rotateRight" />
                                        </li>
                                        <li className="react-viewer-btn" onClick={ this.onPDFViewerReset }>
                                            <i className="react-viewer-icon react-viewer-icon-reset" />
                                        </li>
                                        <li className={ (currentPage === 0 ? 'react-viewer-btn-disabled' : 'react-viewer-btn') } onClick={ currentPage === 0 ? undefined : this.onPDFViewerPreviousPage }>
                                            <i className="react-viewer-icon react-viewer-icon-prev" />
                                        </li>
                                        <li className={ (currentPage + 1 === this.filePageCount ? 'react-viewer-btn-disabled' : 'react-viewer-btn') } onClick={ this.filePageCount === 1 ? undefined : this.onPDFViewerNextPage }>
                                            <i className="react-viewer-icon react-viewer-icon-next" />
                                        </li>
                                        <li className="react-viewer-btn" onClick={ this.onDownload }>
                                            <i className="react-viewer-icon react-viewer-icon-download" />
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                }
                { showSpreadsheetViewer &&
                    <div className="spreadsheet-viewer-container">
                        <SpreadsheetViewer
                            filePath={ fileAlias }
                            content={ fileContent !== null ? fileContent : '' }
                            hiddenColumnHeaders={ hiddenCSVHeaders }
                            columnOrder={ csvColumnSort }
                            onClose={ onClose }
                        />
                    </div>
                }
            </div>
        );
    }

    public componentDidUpdate() {
        // PDF viewer doesn't re-render automatically when the contents are changed, this will force the re-render
        if (this.state.pdfViewer.rerender) {
            this.setState((state) => {
                return {
                    ...state,
                    pdfViewer : {
                        ...state.pdfViewer,
                        rerender : false,
                    },
                };
            });
        }

        // If there are no shown controls, re-display them after 600ms.
        if (!this.state.controlsAreShown) {
            window.setTimeout(() => {
                this.setState((state) => {
                    return {
                        ...state,
                        controlsAreShown: true,
                    };
                });
            }, 600);
        }
    }

    private fileViewerContainerRefFunction = (fileViewerContainer : HTMLDivElement) => {
        this.fileViewerContainer = fileViewerContainer;
    }

    private onPDFPageLoadSuccess = (page : IPDFPageData) => {
        // Takes a couple milliseconds to update the DOM with width/height information
        window.setTimeout(() => {
            if (this.pdfDocumentContainerRef.current && page.originalWidth) {
                const scale = this.pdfDocumentContainerRef.current.offsetWidth / page.originalWidth;
                this.setState({
                    pdfViewer: {
                        ...this.state.pdfViewer,
                        zoomScale: scale,
                        initialZoom: scale,
                        rotation: page.rotate,
                    }
                });
            }
        }, 5);
    }

    private onPDFViewerDocumentComplete: DocumentProps["onLoadSuccess"] = (pdfDocument) => {
        this.filePageCount = pdfDocument.numPages;

        this.setState({
            ...this.state,
            controlsAreShown: true,
        });
    }

    private onPDFViewerZoomIn = () => {
        this.setState((state) => {
            return {
                ...state,
                pdfViewer : {
                    ...state.pdfViewer,
                    zoomScale: state.pdfViewer.zoomScale + (state.pdfViewer.initialZoom * .1),
                },
                controlsAreShown: false,
            };
        });
    }

    private onPDFViewerZoomOut = () => {
        this.setState((state) => {
            return {
                ...state,
                pdfViewer : {
                    ...state.pdfViewer,
                    zoomScale: state.pdfViewer.zoomScale - (state.pdfViewer.initialZoom * .1),
                },
                controlsAreShown: false,
            };
        });
    }

    private onPDFViewerRotateLeft = () => {
        this.setState((state) => {
            return {
                ...state,
                pdfViewer : {
                    ...state.pdfViewer,
                    rotation : state.pdfViewer.rotation === 0 ? 270 : state.pdfViewer.rotation - 90,
                },
                controlsAreShown: false,
            };
        });
    }

    private onPDFViewerRotateRight = () => {
        this.setState((state) => {
            return {
                ...state,
                pdfViewer : {
                    ...state.pdfViewer,
                    rotation : state.pdfViewer.rotation === 270 ? 0 : state.pdfViewer.rotation + 90,
                },
                controlsAreShown: false,
            };
        });
    }

    private onPDFViewerReset = () => {
        this.setState((state) => {
            return {
                ...state,
                pdfViewer : {
                    currentPage: 0,
                    rotation : 0,
                    zoomScale: 1,
                    initialZoom: 1,
                    rerender: true
                },
            };
        });
    }

    private onPDFViewerPreviousPage = () => {
        const newPDFViewerCurrentPage = this.state.pdfViewer.currentPage - 1;
        if (newPDFViewerCurrentPage >= 0) {
            this.setState((state) => {
                return {
                    ...state,
                    pdfViewer : {
                        ...state.pdfViewer,
                        currentPage : newPDFViewerCurrentPage
                    },
                    controlsAreShown: false,
                };
            });
        }
    }

    private onPDFViewerNextPage = () => {
        const newPDFViewerCurrentPage = this.state.pdfViewer.currentPage + 1;
        if (newPDFViewerCurrentPage >= 0) {
            this.setState((state) => {
                return {
                    ...state,
                    pdfViewer : {
                        ...state.pdfViewer,
                        currentPage : newPDFViewerCurrentPage
                    },
                    controlsAreShown: false,
                };
            });
        }
    }

    private handleFileContentUpdate = (props : IFileViewerProps) => {
        const fileExtension = fileReaderUtils.getFileExtensionFromPath(props.filePath);
        if (fileExtension) {
            if (props.file) {
                const fileReader = new FileReader();
                fileReader.onload = (event) => {
                    this.onFileContentChanged(fileReader.result);
                };
                if (fileExtension === 'pdf') {
                    fileReader.readAsArrayBuffer(props.file);
                } else if (SUPPORTED_SPREADSHEET_FILE_TYPES.includes(fileExtension.toLowerCase())) {
                    const rABS = !!fileReader.readAsBinaryString;
                    if (rABS) {
                        fileReader.readAsBinaryString(props.file);
                    } else {
                        fileReader.readAsArrayBuffer(props.file);
                    }
                } else {
                    fileReader.readAsDataURL(props.file);
                }
            } else if (SUPPORTED_SPREADSHEET_FILE_TYPES.includes(fileExtension.toLowerCase())) {
                fetch(props.filePath)
                .then((response) => response.arrayBuffer())
                .then((data) => {
                    this.onFileContentChanged(data);
                });
            }
            // Remote PDF and Generic(Image/Text) files are handled by their respective viewers
        }
    }

    private onFileContentChanged = (newContent : string | ArrayBuffer | null) => {
        this.setState((state) => {
            return {
                ...state,
                fileContent : newContent,
                pdfViewer : {
                    ...this.state.pdfViewer,
                    rerender : true,
                }
            };
        });
    }

    private onDownload = () => {
        if (this.props.filePath) {
            window.open(this.props.filePath, '_blank');
        }
    }
}
