import React from 'react';
import MediaQuery from 'react-responsive';

import _ from 'lodash';

import { PosItemId } from 'api/SalesData/model/PosItemId';
import { Button } from 'shared/components/Button';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { RowGroupHeader } from 'shared/components/LightTable/RowGroupHeader';
import { PageHeader, PageHeaderTheme } from 'shared/components/PageHeader/PageHeader';
import { PageTitle } from 'shared/components/PageTitle/PageTitle';
import { RowOptimizationWrapper } from 'shared/components/RowOptimizationWrapper/RowOptimizationWrapper';
import { StickyHeaderWrapper } from 'shared/components/StickyHeaderWrapper/StickyHeaderWrapper';
import { MIN_TABLET_WIDTH } from 'shared/constants';
import { ISortedFilteredAndGroupedResult } from 'shared/utils/sortingFilteringAndGroupingUtils';
import { TableControls } from '../LightTable/TableControls';
import { SearchBar, SearchBarTheme } from '../SearchBar/SearchBar';
import { OmittedItemRow } from './OmittedItemRow';
import { OmittedItemsSortingUtil } from './OmittedItemsSortingUtil';

import './OmittedItemsView.scss';

export type OmittedItemRowId = [PosItemId, string]; // rowid = pos id + name
interface IOmittedItemsViewProps {
    readonly unsortedRowIds : Set<OmittedItemRowId>;

    readonly onRestoreItems : (items : Set<OmittedItemRowId>) => void;
    readonly onLeaveView : () => void;
}

interface IOmittedItemsViewState {
    sortedFilteredGroupedResult : ISortedFilteredAndGroupedResult<OmittedItemRowId>;
    currentlyRestoringIds : Set<OmittedItemRowId>;
    searchTerm : string | null;
}

// this is used on report creation & expected inventory to show which items are not going to be pulled into the report
// it will also be used on the sales settings page to show the global list
export class OmittedItemsView extends React.Component<IOmittedItemsViewProps, IOmittedItemsViewState> {

    // normally we would put throttled sorting, etc in an actions file. however, in the interest of time and because
    // this is not a main view and because it is used on different pages, just putting this in the view for now. if this
    // ever becomes more complex or we start to have performance issues, we should consider moving this to a redux store
    private readonly throttledUpdateSortedFilteredGroupedResult = _.throttle(
        () => {
            OmittedItemsSortingUtil.getSortedFilteredAndGroupedResult(
                Array.from(this.props.unsortedRowIds.keys()),
                {
                    sortedBy: 'name',
                    direction: 1
                },
                this.state.searchTerm,
                'All Items',
                {
                    unsortedRowIds: this.props.unsortedRowIds,
                }
            )
            .then((result : ISortedFilteredAndGroupedResult<OmittedItemRowId>) => {
                this.setState({
                    sortedFilteredGroupedResult: result
                });
            });
        },
        200, { leading : false }
    );

    constructor(props : IOmittedItemsViewProps) {
        super(props);

        this.state = {
            sortedFilteredGroupedResult: {
                sortedGroupNamesToDisplay: [],
                sortedRowIdsToDisplayByGroupName: {},
                unfilteredSortedRowIdsByGroupName: {},
                panelIsOpenByGroupNameFromFiltering: {},
            },
            currentlyRestoringIds: new Set(),
            searchTerm: null,
        };
    }

    public componentDidMount() {
        this.updateSortedFilteredGroupedResult();
    }

    public UNSAFE_componentWillReceiveProps(nextProps : IOmittedItemsViewProps) {
        if (nextProps.unsortedRowIds !== this.props.unsortedRowIds) {
            const newCurrentlyRestoringIds = new Set(this.state.currentlyRestoringIds);
            newCurrentlyRestoringIds.forEach((posId) => {
                if (!nextProps.unsortedRowIds.has(posId)) {
                    newCurrentlyRestoringIds.delete(posId);
                }
            });

            this.setState({
                currentlyRestoringIds: newCurrentlyRestoringIds
            });
            this.updateSortedFilteredGroupedResult();
        }
    }

    public render() {
        const {
            onLeaveView,
            unsortedRowIds
        } = this.props;

        const {
            searchTerm
        } = this.state;

        return (
            <div className="omitted-items-view">
                <PageHeader
                    theme={ PageHeaderTheme.Default }
                >
                    <Button
                        buttonClassName="icon flat bevicon bevico-arrow-back exit-view"
                        isDisabled={ false }
                        isLoading={ false }
                        onClick={ onLeaveView }
                    />
                    <PageTitle
                        darkTheme={ false }
                        heading="Omitted Items"
                        secondaryHeading={ `${ unsortedRowIds.size } ${ 'item' }${ unsortedRowIds.size === 1 ? '' : 's'}` }
                    />
                </PageHeader>
                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                    <Flex direction="row" className="page-about-banner" justify="space-between">
                        <Flex direction="column" grow={ 3 } justify="center">
                            <h4 className="normal">
                                This is a list of all of the items from your POS system that will not be included in sales reports.<br/>
                                Click “Restore” on an item and it will be included in future reports.
                            </h4>
                        </Flex>
                    </Flex>
                </MediaQuery>
                <StickyHeaderWrapper>
                    <TableControls>
                        <Flex direction="row" grow={ 1 } shrink={ 0 } basis="auto" inline={ true } className="flex-px-3" align="center" justify="space-between">
                            <div className="flex-child">
                                <SearchBar
                                    theme={ SearchBarTheme.Basic }
                                    isDisabled={ false }
                                    placeholderText="Search by name or pos ID"
                                    value={ searchTerm }
                                    isFocused={ false }
                                    handleBlur={ this.doNothing }
                                    handleFocus={ null }
                                    handleEnterClick={ this.doNothing }
                                    handleOnChange={ this.onSearchTermChange }
                                    clearSearchBar={ this.onClearSearchTerm }
                                />
                            </div>
                        </Flex>
                    </TableControls>
                </StickyHeaderWrapper>
                <div className="omitted-items-table">
                    <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                        <StickyHeaderWrapper>
                            <Flex className="omitted-items-table-header light-table-header" direction="row">
                                <Flex direction="column" className="flex-px-2 static-table-header" grow={ 4 } justify="center">
                                    <div className="flex-child cell cell-ptb">
                                        POS ID
                                    </div>
                                </Flex>
                                <Flex direction="column" className="flex-px-2 static-table-header" grow={ 4 } justify="center">
                                    <div className="flex-child cell cell-ptb">
                                        Name
                                    </div>
                                </Flex>
                                <Flex direction="column" className="flex-px-2 static-table-header" grow={ 4 }/>
                            </Flex>
                        </StickyHeaderWrapper>
                    </MediaQuery>
                    { this.getGroupPanels() }
                </div>
            </div>
        );
    }

    private readonly getGroupPanels = () : Array<JSX.Element> => {
        const {
            sortedFilteredGroupedResult,
            currentlyRestoringIds
        } = this.state;

        const groupPanels : Array<JSX.Element> = [];
        sortedFilteredGroupedResult.sortedGroupNamesToDisplay.forEach((groupName : string) => {
            const rowIds = sortedFilteredGroupedResult.sortedRowIdsToDisplayByGroupName[groupName];
            const rowFactory = (index : number) => {
                const rowId = rowIds[index];

                const posId = rowId[0];
                const name = rowId[1];

                const restoreDisabled = currentlyRestoringIds.has(rowId);

                const onRestoreItem = () => this.onRestoreItem(rowId);

                return (
                    <OmittedItemRow
                        className={ `${ index % 2 ? '' : 'omitted-item-row-odd' }` }
                        key={ posId.getValue() + name }
                        rowId={ posId }
                        name={ name }
                        posId={ posId.getValue() }
                        onRestore={ onRestoreItem }
                        restoreDisabled={ restoreDisabled }
                    />
                );
            };

            let rowContainer : HTMLDivElement;
            const containerBodyRefFunction = (containerBody : HTMLDivElement) => {
                rowContainer = containerBody;
            };

            const getRowContainer = () => {
                return rowContainer;
            };

            const isOpen = true; // isOpenByGroupName[groupName];
            const numRows = rowIds.length;

            const onRestoreGroup = () => {
                this.handleOnRestoreItems(new Set(rowIds));
            };

            groupPanels.push(
                <div className="light-table-group-container" key={ groupName }>
                    <StickyHeaderWrapper>
                        <div className="group-header">
                            <RowGroupHeader
                                selectAllState={ null }
                                onSelectAllClick={ this.doNothing }
                                groupName={ groupName }
                                isOpen={ isOpen }
                                visibleItemCount={ rowIds.length }
                                totalItemCount={ rowIds.length } // totalUnfilteredItemCount
                                onClick={ this.doNothing }
                                showExpandArrow={ false }
                            >
                                <Button
                                    buttonClassName="flat primary"
                                    onClick={ onRestoreGroup }
                                    isDisabled={ false }
                                    isLoading={ false }
                                >
                                    { `Restore "${ groupName }"` }
                                </Button>
                            </RowGroupHeader>
                        </div>
                    </StickyHeaderWrapper>
                    <div className="group-container-body" ref={ containerBodyRefFunction }>
                        <RowOptimizationWrapper
                            numRows={ numRows }
                            rowFactory={ rowFactory }
                            getRowContainer={ getRowContainer }
                            isOpen={ isOpen }
                        />
                    </div>
                </div>
            );
        });

        return groupPanels;
    }

    private readonly handleOnRestoreItems = (items : Set<OmittedItemRowId>) => {
        const newIdSet = new Set(this.state.currentlyRestoringIds);
        items.forEach((item) => newIdSet.add(item));
        this.setState({
            currentlyRestoringIds: newIdSet
        });

        this.props.onRestoreItems(items);
    }

    private readonly onRestoreItem = (itemId : OmittedItemRowId) => {
        this.handleOnRestoreItems(new Set([itemId]));
    }

    private readonly doNothing = () => undefined;

    private readonly updateSortedFilteredGroupedResult = () => {
        this.throttledUpdateSortedFilteredGroupedResult();
    }

    private readonly onSearchTermChange = (searchTerm : string | null) => {
        this.setState({ searchTerm });
        this.throttledUpdateSortedFilteredGroupedResult();
    }

    private readonly onClearSearchTerm = () => this.onSearchTermChange(null);
}
