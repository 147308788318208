import { StringValueMap } from 'api/Core/StringValueMap';
import { Conversions } from 'api/Product/model/Conversions';
import { Mappings } from 'api/Product/model/Mappings';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { QuantityInUnit } from './QuantityInUnit';

export class PackagingsAndMappings {
    private nonDeletedPackagingData : Array<{packaging: Packaging, isActive: boolean}>;
    private availablePackagingByPackagingId : StringValueMap<PackagingId, Packaging>;
    private availableVolumeUnits : Set<VolumeUnit>;
    private availableMassUnits : Set<MassUnit>;

    constructor(
        private readonly packagingData : Array<{deleted: boolean, isActive: boolean, packaging: Packaging}>,
        private readonly mappings : Mappings,
        private readonly conversions : Conversions,
    ) {
        // Populate helper fields
        const nonDeletedPackagingData : Array<{packaging: Packaging, isActive: boolean}> = [];
        const availablePackagingByPackagingId = new StringValueMap<PackagingId, Packaging>();
        const availableVolumeUnits = new Set<VolumeUnit>();
        const availableMassUnits = new Set<MassUnit>();

        this.packagingData.forEach((data) => {
            if (!data.deleted) {
                nonDeletedPackagingData.push({
                    packaging: data.packaging,
                    isActive: data.isActive,
                });

                let currentPackaging : Packaging | null = data.packaging;

                while (currentPackaging) {
                    const currentPackagingId = currentPackaging.getPackagingId();

                    if (currentPackagingId) {
                        availablePackagingByPackagingId.set(currentPackagingId, currentPackaging);
                    } else {
                        const currentUnit = currentPackaging.getUnit();
                        if (currentUnit === null) {
                            throw new RuntimeException("unexpected packaging data has no packaging id or unit: " + currentPackaging);
                        }

                        if (VolumeUnit.isVolumeUnit(currentUnit)) {
                            availableVolumeUnits.add(currentUnit);
                        } else if (MassUnit.isMassUnit(currentUnit)) {
                            availableMassUnits.add(currentUnit);
                        }
                    }

                    currentPackaging = currentPackaging.getContent();
                }
            }
        });

        const conversionsBaseUnit = this.conversions.getBaseUnit();

        // Validate conversions
        // Every non-deleted package base should either be the conversions base unit or represented in conversion keys
        this.packagingData.forEach((data) => {
            if (!data.deleted) {
                const basePackaging = PackagingUtils.getBasePackaging(data.packaging);
                const basePackagingProductQuantityUnit = basePackaging.getPackagingId() || basePackaging.getUnit();
                if (basePackagingProductQuantityUnit === null) {
                    throw new RuntimeException('Packaging data exists with no base product quantity unit: ' + data.packaging);
                }

                if (PackagingUtils.productQuantityUnitsAreEqual(conversionsBaseUnit, basePackagingProductQuantityUnit)) {
                    return;
                }

                if (basePackagingProductQuantityUnit instanceof PackagingId) {
                    if (conversions.getPackagingIdConversions().has(basePackagingProductQuantityUnit)) {
                        return;
                    }
                } else {
                    if (!(conversionsBaseUnit instanceof PackagingId)) {
                        if ((MassUnit.isMassUnit(basePackagingProductQuantityUnit) && MassUnit.isMassUnit(conversionsBaseUnit)) || (VolumeUnit.isVolumeUnit(basePackagingProductQuantityUnit) && VolumeUnit.isVolumeUnit(conversionsBaseUnit))) {
                            return;
                        }
                    }

                    if ((MassUnit.isMassUnit(basePackagingProductQuantityUnit) && conversions.hasMassUnit()) || (VolumeUnit.isVolumeUnit(basePackagingProductQuantityUnit) && conversions.hasVolumeUnit())) {
                        return;
                    }
                }

                throw new RuntimeException("Packaging data exists with no base product quantity unit that is represented in conversions: " + basePackagingProductQuantityUnit + " in " + this.packagingData);
            }
        });

        // Validate that all mappings map to current package_id or unit
        const allMappingQuantities : Array<QuantityInUnit<PackagingId>> = Array.from(mappings.getPackagingIdMappings().values()).concat(Array.from(mappings.getUnitOfMeasureMappings().values()));
        allMappingQuantities.forEach((quantityInUnit) => {
            const resolvedProductQuantityUnit = quantityInUnit.getUnit();

            if (resolvedProductQuantityUnit instanceof PackagingId) {
                if (availablePackagingByPackagingId.has(resolvedProductQuantityUnit)) {
                    return;
                }
                
                throw new RuntimeException("mapping exists that does not resolve to non-deleted package_id" + resolvedProductQuantityUnit.getValue());
            } else if ((MassUnit.isMassUnit(resolvedProductQuantityUnit) && (availableMassUnits.size === 0)) || (VolumeUnit.isVolumeUnit(resolvedProductQuantityUnit) && (availableVolumeUnits.size === 0))) {
                throw new RuntimeException("mapping exists to unit that is not available in packages:" + resolvedProductQuantityUnit);
            }

        });

        if  (this.mappings.hasMassUnit() && (availableMassUnits.size > 0)) {
            throw new RuntimeException("mapping exists for MassUnit, but packaging also includes MassUnit");
        }

        if  (this.mappings.hasVolumeUnit() && (availableVolumeUnits.size > 0)) {
            throw new RuntimeException("mapping exists for VolumeUnit, but packaging also includes VolumeUnit");
        }

        // Validate that non-deleted packaging have no mapping entries
        this.packagingData.forEach((data) => {
            if (!data.deleted) {
                let currentPackaging : Packaging | null = data.packaging;

                while (currentPackaging) {
                    const currentPackagingId = currentPackaging.getPackagingId();

                    if (currentPackagingId) {
                        if (mappings.getPackagingIdMappings().has(currentPackagingId)) {
                            throw new RuntimeException("mapping should not exist for non-deleted packagingId");
                        }
                    }

                    currentPackaging = currentPackaging.getContent();
                }
            }
        });

        this.nonDeletedPackagingData = nonDeletedPackagingData;
        this.availablePackagingByPackagingId = availablePackagingByPackagingId;
        this.availableVolumeUnits = availableVolumeUnits;
        this.availableMassUnits = availableMassUnits;
    }

    public getPackagingData() {
        return this.packagingData;
    }

    public getNonDeletedPackagingData() {
        return this.nonDeletedPackagingData;
    }

    public getAvailableMassUnits() : Set<MassUnit> {
        return this.availableMassUnits;
    }

    public getAvailableVolumeUnits() : Set<VolumeUnit> {
        return this.availableVolumeUnits;
    }

    public getAvailablePackagingByPackagingId() : StringValueMap<PackagingId, Packaging> {
        return this.availablePackagingByPackagingId;
    }

    // PTODO Deprecated. Should be deleted after multi-packaging updates
    public getPackaging() : Packaging {
        return this.packagingData[0].packaging;
    }

    public getMappings() : Mappings {
        return this.mappings;
    }

    public getConversions() : Conversions {
        return this.conversions;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof PackagingsAndMappings)) {
            return false;
        }

        if (!this.conversions.equals(other.getConversions()) || !this.mappings.equals(other.getMappings())) {
            return false;
        }

        if (this.packagingData.length !== other.getPackagingData().length) {
            return false;
        }

        let packagingDataIsEqual = true;
        this.packagingData.forEach((data, index) => {
            const otherData = other.getPackagingData()[index];
            packagingDataIsEqual = packagingDataIsEqual && data.packaging.equals(otherData.packaging) && (data.deleted === otherData.deleted) && (data.isActive === otherData.isActive);
        });

        return packagingDataIsEqual;
    }

}
