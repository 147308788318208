//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';
import LocationModel from './location_Model_types';
import ProductModel from './product_Model_types';
import CoreMathModel from './core_math_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import MoneyModel from './money_Model_types';



var InventoryModel = {};
export default InventoryModel;
InventoryModel.InventoryCountType = {
  '0' : 'COMPLETE',
  'COMPLETE' : 0,
  '1' : 'PARTIAL',
  'PARTIAL' : 1
};
InventoryModel.StorageAreaId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
InventoryModel.StorageAreaId.prototype = {};
InventoryModel.StorageAreaId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.StorageAreaId.prototype.write = function(output) {
  output.writeStructBegin('StorageAreaId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.StorageArea = function(args) {
  this.name = null;
  this.note = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
  }
};
InventoryModel.StorageArea.prototype = {};
InventoryModel.StorageArea.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.StorageArea.prototype.write = function(output) {
  output.writeStructBegin('StorageArea');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 2);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.InventoryCountId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
InventoryModel.InventoryCountId.prototype = {};
InventoryModel.InventoryCountId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.InventoryCountId.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.ProductCount = function(args) {
  this.count = null;
  this.productQuantityUnit = null;
  if (args) {
    if (args.count !== undefined && args.count !== null) {
      this.count = new CoreMathModel.Decimal(args.count);
    }
    if (args.productQuantityUnit !== undefined && args.productQuantityUnit !== null) {
      this.productQuantityUnit = new ProductModel.ProductQuantityUnit(args.productQuantityUnit);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productQuantityUnit is unset!');
    }
  }
};
InventoryModel.ProductCount.prototype = {};
InventoryModel.ProductCount.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.count = new CoreMathModel.Decimal();
        this.count.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productQuantityUnit = new ProductModel.ProductQuantityUnit();
        this.productQuantityUnit.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.ProductCount.prototype.write = function(output) {
  output.writeStructBegin('ProductCount');
  if (this.count !== null && this.count !== undefined) {
    output.writeFieldBegin('count', Thrift.Type.STRUCT, 1);
    this.count.write(output);
    output.writeFieldEnd();
  }
  if (this.productQuantityUnit !== null && this.productQuantityUnit !== undefined) {
    output.writeFieldBegin('productQuantityUnit', Thrift.Type.STRUCT, 2);
    this.productQuantityUnit.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.StorageAreaIdAndStorageArea = function(args) {
  this.storageAreaId = null;
  this.storageArea = null;
  if (args) {
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.storageArea !== undefined && args.storageArea !== null) {
      this.storageArea = new InventoryModel.StorageArea(args.storageArea);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageArea is unset!');
    }
  }
};
InventoryModel.StorageAreaIdAndStorageArea.prototype = {};
InventoryModel.StorageAreaIdAndStorageArea.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageArea = new InventoryModel.StorageArea();
        this.storageArea.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.StorageAreaIdAndStorageArea.prototype.write = function(output) {
  output.writeStructBegin('StorageAreaIdAndStorageArea');
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 1);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageArea !== null && this.storageArea !== undefined) {
    output.writeFieldBegin('storageArea', Thrift.Type.STRUCT, 2);
    this.storageArea.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.StorageAreaIdAndSortedProductIdList = function(args) {
  this.storageAreaId = null;
  this.sortedProductIdList = null;
  if (args) {
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.sortedProductIdList !== undefined && args.sortedProductIdList !== null) {
      this.sortedProductIdList = Thrift.copyList(args.sortedProductIdList, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sortedProductIdList is unset!');
    }
  }
};
InventoryModel.StorageAreaIdAndSortedProductIdList.prototype = {};
InventoryModel.StorageAreaIdAndSortedProductIdList.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.sortedProductIdList = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ProductModel.ProductId();
          elem6.read(input);
          this.sortedProductIdList.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.StorageAreaIdAndSortedProductIdList.prototype.write = function(output) {
  output.writeStructBegin('StorageAreaIdAndSortedProductIdList');
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 1);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.sortedProductIdList !== null && this.sortedProductIdList !== undefined) {
    output.writeFieldBegin('sortedProductIdList', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.sortedProductIdList.length);
    for (var iter7 in this.sortedProductIdList)
    {
      if (this.sortedProductIdList.hasOwnProperty(iter7))
      {
        iter7 = this.sortedProductIdList[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.ProductCountEvent = function(args) {
  this.productCounts = null;
  this.metadata = null;
  if (args) {
    if (args.productCounts !== undefined && args.productCounts !== null) {
      this.productCounts = Thrift.copyList(args.productCounts, [InventoryModel.ProductCount]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCounts is unset!');
    }
    if (args.metadata !== undefined && args.metadata !== null) {
      this.metadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.metadata);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field metadata is unset!');
    }
  }
};
InventoryModel.ProductCountEvent.prototype = {};
InventoryModel.ProductCountEvent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.productCounts = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new InventoryModel.ProductCount();
          elem14.read(input);
          this.productCounts.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.metadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.metadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.ProductCountEvent.prototype.write = function(output) {
  output.writeStructBegin('ProductCountEvent');
  if (this.productCounts !== null && this.productCounts !== undefined) {
    output.writeFieldBegin('productCounts', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.productCounts.length);
    for (var iter15 in this.productCounts)
    {
      if (this.productCounts.hasOwnProperty(iter15))
      {
        iter15 = this.productCounts[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.metadata !== null && this.metadata !== undefined) {
    output.writeFieldBegin('metadata', Thrift.Type.STRUCT, 2);
    this.metadata.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.ProductCountEventNew = function(args) {
  this.productCounts = null;
  this.metadata = null;
  if (args) {
    if (args.productCounts !== undefined && args.productCounts !== null) {
      this.productCounts = Thrift.copyList(args.productCounts, [InventoryModel.ProductCount]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCounts is unset!');
    }
    if (args.metadata !== undefined && args.metadata !== null) {
      this.metadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.metadata);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field metadata is unset!');
    }
  }
};
InventoryModel.ProductCountEventNew.prototype = {};
InventoryModel.ProductCountEventNew.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.productCounts = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new InventoryModel.ProductCount();
          elem22.read(input);
          this.productCounts.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.metadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.metadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.ProductCountEventNew.prototype.write = function(output) {
  output.writeStructBegin('ProductCountEventNew');
  if (this.productCounts !== null && this.productCounts !== undefined) {
    output.writeFieldBegin('productCounts', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.productCounts.length);
    for (var iter23 in this.productCounts)
    {
      if (this.productCounts.hasOwnProperty(iter23))
      {
        iter23 = this.productCounts[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.metadata !== null && this.metadata !== undefined) {
    output.writeFieldBegin('metadata', Thrift.Type.STRUCT, 2);
    this.metadata.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.StorageAreaIdAndProductIdAndCountEvent = function(args) {
  this.storageAreaId = null;
  this.productId = null;
  this.productCountEvent = null;
  if (args) {
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.productCountEvent !== undefined && args.productCountEvent !== null) {
      this.productCountEvent = new InventoryModel.ProductCountEvent(args.productCountEvent);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCountEvent is unset!');
    }
  }
};
InventoryModel.StorageAreaIdAndProductIdAndCountEvent.prototype = {};
InventoryModel.StorageAreaIdAndProductIdAndCountEvent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.productCountEvent = new InventoryModel.ProductCountEvent();
        this.productCountEvent.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.StorageAreaIdAndProductIdAndCountEvent.prototype.write = function(output) {
  output.writeStructBegin('StorageAreaIdAndProductIdAndCountEvent');
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 1);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.productCountEvent !== null && this.productCountEvent !== undefined) {
    output.writeFieldBegin('productCountEvent', Thrift.Type.STRUCT, 3);
    this.productCountEvent.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.InventoryCountMetadata = function(args) {
  this.inventoryCountTime = null;
  this.inventoryCountType = null;
  this.creationMetadata = null;
  this.finalizationMetadata = null;
  this.deletionMetadata = null;
  this.name = null;
  this.note = null;
  this.locationId = null;
  if (args) {
    if (args.inventoryCountTime !== undefined && args.inventoryCountTime !== null) {
      this.inventoryCountTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.inventoryCountTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountTime is unset!');
    }
    if (args.inventoryCountType !== undefined && args.inventoryCountType !== null) {
      this.inventoryCountType = args.inventoryCountType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountType is unset!');
    }
    if (args.creationMetadata !== undefined && args.creationMetadata !== null) {
      this.creationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.creationMetadata);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field creationMetadata is unset!');
    }
    if (args.finalizationMetadata !== undefined && args.finalizationMetadata !== null) {
      this.finalizationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.finalizationMetadata);
    }
    if (args.deletionMetadata !== undefined && args.deletionMetadata !== null) {
      this.deletionMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.deletionMetadata);
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
  }
};
InventoryModel.InventoryCountMetadata.prototype = {};
InventoryModel.InventoryCountMetadata.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.inventoryCountTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.inventoryCountType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.creationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.creationMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.finalizationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.finalizationMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.deletionMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.deletionMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.InventoryCountMetadata.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountMetadata');
  if (this.inventoryCountTime !== null && this.inventoryCountTime !== undefined) {
    output.writeFieldBegin('inventoryCountTime', Thrift.Type.STRUCT, 1);
    this.inventoryCountTime.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountType !== null && this.inventoryCountType !== undefined) {
    output.writeFieldBegin('inventoryCountType', Thrift.Type.I32, 2);
    output.writeI32(this.inventoryCountType);
    output.writeFieldEnd();
  }
  if (this.creationMetadata !== null && this.creationMetadata !== undefined) {
    output.writeFieldBegin('creationMetadata', Thrift.Type.STRUCT, 3);
    this.creationMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.finalizationMetadata !== null && this.finalizationMetadata !== undefined) {
    output.writeFieldBegin('finalizationMetadata', Thrift.Type.STRUCT, 4);
    this.finalizationMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.deletionMetadata !== null && this.deletionMetadata !== undefined) {
    output.writeFieldBegin('deletionMetadata', Thrift.Type.STRUCT, 5);
    this.deletionMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 6);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 7);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 8);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.InventoryCountIdAndMetadata = function(args) {
  this.inventoryCountId = null;
  this.inventoryMetadata = null;
  if (args) {
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.inventoryMetadata !== undefined && args.inventoryMetadata !== null) {
      this.inventoryMetadata = new InventoryModel.InventoryCountMetadata(args.inventoryMetadata);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryMetadata is unset!');
    }
  }
};
InventoryModel.InventoryCountIdAndMetadata.prototype = {};
InventoryModel.InventoryCountIdAndMetadata.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryMetadata = new InventoryModel.InventoryCountMetadata();
        this.inventoryMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.InventoryCountIdAndMetadata.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountIdAndMetadata');
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 1);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryMetadata !== null && this.inventoryMetadata !== undefined) {
    output.writeFieldBegin('inventoryMetadata', Thrift.Type.STRUCT, 2);
    this.inventoryMetadata.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.InventoryConfiguration = function(args) {
  this.sortedStorageAreaIds = null;
  this.storageAreasById = null;
  this.sortedProductIdListsByStorageAreaId = null;
  if (args) {
    if (args.sortedStorageAreaIds !== undefined && args.sortedStorageAreaIds !== null) {
      this.sortedStorageAreaIds = Thrift.copyList(args.sortedStorageAreaIds, [InventoryModel.StorageAreaId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sortedStorageAreaIds is unset!');
    }
    if (args.storageAreasById !== undefined && args.storageAreasById !== null) {
      this.storageAreasById = Thrift.copyList(args.storageAreasById, [InventoryModel.StorageAreaIdAndStorageArea]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreasById is unset!');
    }
    if (args.sortedProductIdListsByStorageAreaId !== undefined && args.sortedProductIdListsByStorageAreaId !== null) {
      this.sortedProductIdListsByStorageAreaId = Thrift.copyList(args.sortedProductIdListsByStorageAreaId, [InventoryModel.StorageAreaIdAndSortedProductIdList]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sortedProductIdListsByStorageAreaId is unset!');
    }
  }
};
InventoryModel.InventoryConfiguration.prototype = {};
InventoryModel.InventoryConfiguration.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.sortedStorageAreaIds = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new InventoryModel.StorageAreaId();
          elem30.read(input);
          this.sortedStorageAreaIds.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size31 = 0;
        var _rtmp335;
        this.storageAreasById = [];
        var _etype34 = 0;
        _rtmp335 = input.readListBegin();
        _etype34 = _rtmp335.etype;
        _size31 = _rtmp335.size;
        for (var _i36 = 0; _i36 < _size31; ++_i36)
        {
          var elem37 = null;
          elem37 = new InventoryModel.StorageAreaIdAndStorageArea();
          elem37.read(input);
          this.storageAreasById.push(elem37);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size38 = 0;
        var _rtmp342;
        this.sortedProductIdListsByStorageAreaId = [];
        var _etype41 = 0;
        _rtmp342 = input.readListBegin();
        _etype41 = _rtmp342.etype;
        _size38 = _rtmp342.size;
        for (var _i43 = 0; _i43 < _size38; ++_i43)
        {
          var elem44 = null;
          elem44 = new InventoryModel.StorageAreaIdAndSortedProductIdList();
          elem44.read(input);
          this.sortedProductIdListsByStorageAreaId.push(elem44);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.InventoryConfiguration.prototype.write = function(output) {
  output.writeStructBegin('InventoryConfiguration');
  if (this.sortedStorageAreaIds !== null && this.sortedStorageAreaIds !== undefined) {
    output.writeFieldBegin('sortedStorageAreaIds', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.sortedStorageAreaIds.length);
    for (var iter45 in this.sortedStorageAreaIds)
    {
      if (this.sortedStorageAreaIds.hasOwnProperty(iter45))
      {
        iter45 = this.sortedStorageAreaIds[iter45];
        iter45.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.storageAreasById !== null && this.storageAreasById !== undefined) {
    output.writeFieldBegin('storageAreasById', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.storageAreasById.length);
    for (var iter46 in this.storageAreasById)
    {
      if (this.storageAreasById.hasOwnProperty(iter46))
      {
        iter46 = this.storageAreasById[iter46];
        iter46.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.sortedProductIdListsByStorageAreaId !== null && this.sortedProductIdListsByStorageAreaId !== undefined) {
    output.writeFieldBegin('sortedProductIdListsByStorageAreaId', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.sortedProductIdListsByStorageAreaId.length);
    for (var iter47 in this.sortedProductIdListsByStorageAreaId)
    {
      if (this.sortedProductIdListsByStorageAreaId.hasOwnProperty(iter47))
      {
        iter47 = this.sortedProductIdListsByStorageAreaId[iter47];
        iter47.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.InventoryCount = function(args) {
  this.inventoryConfiguration = null;
  this.productCountEventsByProductIdByStorageAreaId = null;
  if (args) {
    if (args.inventoryConfiguration !== undefined && args.inventoryConfiguration !== null) {
      this.inventoryConfiguration = new InventoryModel.InventoryConfiguration(args.inventoryConfiguration);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryConfiguration is unset!');
    }
    if (args.productCountEventsByProductIdByStorageAreaId !== undefined && args.productCountEventsByProductIdByStorageAreaId !== null) {
      this.productCountEventsByProductIdByStorageAreaId = Thrift.copyList(args.productCountEventsByProductIdByStorageAreaId, [InventoryModel.StorageAreaIdAndProductIdAndCountEvent]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCountEventsByProductIdByStorageAreaId is unset!');
    }
  }
};
InventoryModel.InventoryCount.prototype = {};
InventoryModel.InventoryCount.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryConfiguration = new InventoryModel.InventoryConfiguration();
        this.inventoryConfiguration.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size48 = 0;
        var _rtmp352;
        this.productCountEventsByProductIdByStorageAreaId = [];
        var _etype51 = 0;
        _rtmp352 = input.readListBegin();
        _etype51 = _rtmp352.etype;
        _size48 = _rtmp352.size;
        for (var _i53 = 0; _i53 < _size48; ++_i53)
        {
          var elem54 = null;
          elem54 = new InventoryModel.StorageAreaIdAndProductIdAndCountEvent();
          elem54.read(input);
          this.productCountEventsByProductIdByStorageAreaId.push(elem54);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.InventoryCount.prototype.write = function(output) {
  output.writeStructBegin('InventoryCount');
  if (this.inventoryConfiguration !== null && this.inventoryConfiguration !== undefined) {
    output.writeFieldBegin('inventoryConfiguration', Thrift.Type.STRUCT, 1);
    this.inventoryConfiguration.write(output);
    output.writeFieldEnd();
  }
  if (this.productCountEventsByProductIdByStorageAreaId !== null && this.productCountEventsByProductIdByStorageAreaId !== undefined) {
    output.writeFieldBegin('productCountEventsByProductIdByStorageAreaId', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.productCountEventsByProductIdByStorageAreaId.length);
    for (var iter55 in this.productCountEventsByProductIdByStorageAreaId)
    {
      if (this.productCountEventsByProductIdByStorageAreaId.hasOwnProperty(iter55))
      {
        iter55 = this.productCountEventsByProductIdByStorageAreaId[iter55];
        iter55.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.InventoryCountIdAndInventoryCount = function(args) {
  this.inventoryCountId = null;
  this.inventoryCount = null;
  if (args) {
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.inventoryCount !== undefined && args.inventoryCount !== null) {
      this.inventoryCount = new InventoryModel.InventoryCount(args.inventoryCount);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCount is unset!');
    }
  }
};
InventoryModel.InventoryCountIdAndInventoryCount.prototype = {};
InventoryModel.InventoryCountIdAndInventoryCount.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCount = new InventoryModel.InventoryCount();
        this.inventoryCount.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.InventoryCountIdAndInventoryCount.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountIdAndInventoryCount');
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 1);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCount !== null && this.inventoryCount !== undefined) {
    output.writeFieldBegin('inventoryCount', Thrift.Type.STRUCT, 2);
    this.inventoryCount.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryModel.FinalizedInventoryAdjustmentEvent = function(args) {
  this.inventoryCountId = null;
  this.storageAreaId = null;
  this.productId = null;
  this.inventoryDate = null;
  this.newProductCount = null;
  this.oldProductCount = null;
  this.productCost = null;
  if (args) {
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.inventoryDate !== undefined && args.inventoryDate !== null) {
      this.inventoryDate = new CoreTimeModel.TimestampWithMillisecondPrecision(args.inventoryDate);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryDate is unset!');
    }
    if (args.newProductCount !== undefined && args.newProductCount !== null) {
      this.newProductCount = new InventoryModel.ProductCountEvent(args.newProductCount);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field newProductCount is unset!');
    }
    if (args.oldProductCount !== undefined && args.oldProductCount !== null) {
      this.oldProductCount = new InventoryModel.ProductCountEvent(args.oldProductCount);
    }
    if (args.productCost !== undefined && args.productCost !== null) {
      this.productCost = new ProductModel.ProductCost(args.productCost);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCost is unset!');
    }
  }
};
InventoryModel.FinalizedInventoryAdjustmentEvent.prototype = {};
InventoryModel.FinalizedInventoryAdjustmentEvent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryDate = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.inventoryDate.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.newProductCount = new InventoryModel.ProductCountEvent();
        this.newProductCount.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.oldProductCount = new InventoryModel.ProductCountEvent();
        this.oldProductCount.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.productCost = new ProductModel.ProductCost();
        this.productCost.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryModel.FinalizedInventoryAdjustmentEvent.prototype.write = function(output) {
  output.writeStructBegin('FinalizedInventoryAdjustmentEvent');
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 1);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 2);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 3);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryDate !== null && this.inventoryDate !== undefined) {
    output.writeFieldBegin('inventoryDate', Thrift.Type.STRUCT, 4);
    this.inventoryDate.write(output);
    output.writeFieldEnd();
  }
  if (this.newProductCount !== null && this.newProductCount !== undefined) {
    output.writeFieldBegin('newProductCount', Thrift.Type.STRUCT, 5);
    this.newProductCount.write(output);
    output.writeFieldEnd();
  }
  if (this.oldProductCount !== null && this.oldProductCount !== undefined) {
    output.writeFieldBegin('oldProductCount', Thrift.Type.STRUCT, 6);
    this.oldProductCount.write(output);
    output.writeFieldEnd();
  }
  if (this.productCost !== null && this.productCost !== undefined) {
    output.writeFieldBegin('productCost', Thrift.Type.STRUCT, 7);
    this.productCost.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

