import * as _ from 'lodash';
import { Action } from '@reduxjs/toolkit';

import { IDatesByInventoryId } from 'shared/models/IDatesByInventoryId';
import { IFullInventory } from 'shared/models/Inventory';
import { IInventoryPeriodSelectorState } from './InventoryPeriodSelectorState';

import { ActionInterfaces, ActionTypes } from './InventoryPeriodSelectorActions';

/*
This is NOT a true shared component - only one instance of inventory period selector can be maintained in a connected app using these reducers.
*/
const initialState : IInventoryPeriodSelectorState = {
    isLoading : false,
    datesByInventoryId : {},
    sortedInventories : [],
    selectedStartInventoryId : null,
    selectedEndInventoryId : null,
    startInventoryOptionsShown : false,
    endInventoryOptionsShown : false,
};

const setStartInventoryOptionsShown = (
    state : IInventoryPeriodSelectorState,
    action : ActionInterfaces.ISetStartInventoryOptionsShown
) : IInventoryPeriodSelectorState => {
    const updates = {
        startInventoryOptionsShown : action.payload.showOptions
    };
    return _.merge({}, state, updates);
};

const setStartInventory = (
    state : IInventoryPeriodSelectorState,
    action : ActionInterfaces.ISetStartInventoryId
) : IInventoryPeriodSelectorState => {
    const updates = {
        selectedStartInventoryId : action.payload.inventoryId
    };
    return _.merge({}, state, updates);
};

const setEndInventoryOptionsShown = (
    state : IInventoryPeriodSelectorState,
    action : ActionInterfaces.ISetEndInventoryOptionsShown
) : IInventoryPeriodSelectorState => {
    const updates = {
        endInventoryOptionsShown : action.payload.showOptions
    };
    return _.merge({}, state, updates);
};

const setEndInventory = (
    state : IInventoryPeriodSelectorState,
    action : ActionInterfaces.ISetEndInventoryId
) : IInventoryPeriodSelectorState => {
    const updates = {
        selectedEndInventoryId : action.payload.inventoryId
    };
    return _.merge({}, state, updates);
};

const reduceFetchInventoriesRequest = (
    state : IInventoryPeriodSelectorState,
    action : ActionInterfaces.IFetchInventoriesRequest,
) : IInventoryPeriodSelectorState => {
    const updates = {
        isLoading : true
    };
    return _.merge({}, state, updates);
};

const reduceFetchInventoriesSuccess = (
    state : IInventoryPeriodSelectorState,
    action : ActionInterfaces.IFetchInventoriesSuccess,
) : IInventoryPeriodSelectorState => {
    const sortedInventories = action.payload.inventories;
    // TODO: move this to thunk/utils?
    const datesByInventoryId : IDatesByInventoryId = {};
    sortedInventories.forEach((inventory : IFullInventory) => {
        datesByInventoryId[inventory.id] = inventory.date;
    });
    const updates : IInventoryPeriodSelectorState = {
        isLoading : false,
        startInventoryOptionsShown : false,
        endInventoryOptionsShown : false,
        selectedStartInventoryId : null,
        selectedEndInventoryId : null,
        sortedInventories,
        datesByInventoryId,
    };
    return _.assign({}, state, updates);
};

const reduceFetchInventoriesFailure = (
    state : IInventoryPeriodSelectorState,
    action : ActionInterfaces.IFetchInventoriesFailure,
) : IInventoryPeriodSelectorState => {
    const updates = {
        isLoading : false
    };
    return _.merge({}, state, updates);
};

export const InventoryPeriodSelectorReducers = (
    state : IInventoryPeriodSelectorState = initialState,
    action : Action
) : IInventoryPeriodSelectorState => {
    switch (action.type) {
        case ActionTypes.SET_START_INVENTORY_OPTIONS_SHOWN:
            return setStartInventoryOptionsShown(
                state,
                action as ActionInterfaces.ISetStartInventoryOptionsShown
            );
        case ActionTypes.SET_START_INVENTORY_ID:
            return setStartInventory(
                state,
                action as ActionInterfaces.ISetStartInventoryId
            );
        case ActionTypes.SET_END_INVENTORY_OPTIONS_SHOWN:
            return setEndInventoryOptionsShown(
                state,
                action as ActionInterfaces.ISetEndInventoryOptionsShown
            );
        case ActionTypes.SET_END_INVENTORY_ID:
            return setEndInventory(
                state,
                action as ActionInterfaces.ISetEndInventoryId
            );
        case ActionTypes.FETCH_INVENTORIES_REQUEST:
            return reduceFetchInventoriesRequest(
                state,
                action as ActionInterfaces.IFetchInventoriesRequest
            );
        case ActionTypes.FETCH_INVENTORIES_SUCCESS:
            return reduceFetchInventoriesSuccess(
                state,
                action as ActionInterfaces.IFetchInventoriesSuccess
            );
        case ActionTypes.FETCH_INVENTORIES_FAILURE:
            return reduceFetchInventoriesFailure(
                state,
                action as ActionInterfaces.IFetchInventoriesFailure
            );
        default:
            return state;
    }
};
