import * as _ from 'lodash';
import { Action } from '@reduxjs/toolkit';

import { ActionTypes, SignInFormActionInterfaces } from './actions';

import { IValidationInputData } from '../../ValidationInput';

export type signInFormFieldName = 'emailAddressOrAccountId' | 'password';

export interface ISignInFormState {
    validationInputDataByFieldName : {
        emailAddressOrAccountId : IValidationInputData;
        password : IValidationInputData;
    };
    isValid : boolean;
    errorMessage : string;
    isSubmitting : boolean;
}

const signInFormInitialState : ISignInFormState = {
    validationInputDataByFieldName : {
        emailAddressOrAccountId: {
            value: '',
            isValid: true,
            errorMessage: '',
        },
        password: {
            value: '',
            isValid: true,
            errorMessage: '',
        },
    },
    isValid: true,
    errorMessage: '',
    isSubmitting: false,
};

const reduceSetFormFieldValidationData = (
    state : ISignInFormState,
    action : SignInFormActionInterfaces.ISetFormFieldValidationData
) : ISignInFormState => {
    const updates = {
        validationInputDataByFieldName: {
            [action.payload.field]: {
                value : action.payload.value,
                errorMessage : action.payload.errorMessage,
                isValid : action.payload.isValid,
            },
        },
    };

    return _.merge({}, state, updates);
};

const reduceSetFormValidationData = (
    state : ISignInFormState,
    action : SignInFormActionInterfaces.ISetFormValidationData
) : ISignInFormState => {
    const updates = {
        isValid: action.payload.isValid,
        errorMessage: action.payload.errorMessage,
    };

    return _.merge({}, state, updates);
};

const reduceSetIsSubmitting = (
    state : ISignInFormState,
    action : SignInFormActionInterfaces.ISetIsSubmitting
) : ISignInFormState => {
    const updates = {
        isSubmitting: action.payload.isSubmitting,
    };

    return _.merge({}, state, updates);
};

export const SignInFormReducers = (
    state : ISignInFormState = signInFormInitialState,
    action : Action,
) : ISignInFormState => {
    switch (action.type) {
        case ActionTypes.SET_FORM_FIELD_VALIDATION_DATA:
            return reduceSetFormFieldValidationData(state, action as SignInFormActionInterfaces.ISetFormFieldValidationData);
        case ActionTypes.SET_FORM_VALIDATION_DATA:
            return reduceSetFormValidationData(state, action as SignInFormActionInterfaces.ISetFormValidationData);
        case ActionTypes.SET_IS_SUBMITTING:
            return reduceSetIsSubmitting(state, action as SignInFormActionInterfaces.ISetIsSubmitting);
        default:
            return state;
    }
};
