//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import InventoryModel from './inventory_Model_types';
import InventoryExceptions from './inventory_Exceptions_types';
import ProductModel from './product_Model_types';
import ProductExceptions from './product_Exceptions_types';
import ProductAmountExceptions from './product_amount_Exceptions_types';
import LocationModel from './location_Model_types';
import LocationExceptions from './location_Exceptions_types';
import CoreTimeModel from './core_time_Model_types';
import CoreMathModel from './core_math_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import MoneyModel from './money_Model_types';

var InventoryCountService = {};
export default InventoryCountService;
//HELPER FUNCTIONS AND STRUCTURES

InventoryCountService.InventoryCountService_getInventoryCountsById_args = function(args) {
  this.actor = null;
  this.inventoryCountIdSet = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.inventoryCountIdSet !== undefined && args.inventoryCountIdSet !== null) {
      this.inventoryCountIdSet = Thrift.copyList(args.inventoryCountIdSet, [InventoryModel.InventoryCountId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountIdSet is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_getInventoryCountsById_args.prototype = {};
InventoryCountService.InventoryCountService_getInventoryCountsById_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.inventoryCountIdSet = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new InventoryModel.InventoryCountId();
          elem6.read(input);
          this.inventoryCountIdSet.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountsById_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getInventoryCountsById_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIdSet !== null && this.inventoryCountIdSet !== undefined) {
    output.writeFieldBegin('inventoryCountIdSet', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.inventoryCountIdSet.length);
    for (var iter7 in this.inventoryCountIdSet)
    {
      if (this.inventoryCountIdSet.hasOwnProperty(iter7))
      {
        iter7 = this.inventoryCountIdSet[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountsById_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownInventoryCountIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryModel.InventoryCountIdAndInventoryCount]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
  }
};
InventoryCountService.InventoryCountService_getInventoryCountsById_result.prototype = {};
InventoryCountService.InventoryCountService_getInventoryCountsById_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.success = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new InventoryModel.InventoryCountIdAndInventoryCount();
          elem14.read(input);
          this.success.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountsById_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getInventoryCountsById_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter15 in this.success)
    {
      if (this.success.hasOwnProperty(iter15))
      {
        iter15 = this.success[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 5);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_args = function(args) {
  this.actor = null;
  this.inventoryCountIdSet = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.inventoryCountIdSet !== undefined && args.inventoryCountIdSet !== null) {
      this.inventoryCountIdSet = Thrift.copyList(args.inventoryCountIdSet, [InventoryModel.InventoryCountId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountIdSet is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_args.prototype = {};
InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.inventoryCountIdSet = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new InventoryModel.InventoryCountId();
          elem22.read(input);
          this.inventoryCountIdSet.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getInventoryCountMetadatasById_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIdSet !== null && this.inventoryCountIdSet !== undefined) {
    output.writeFieldBegin('inventoryCountIdSet', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.inventoryCountIdSet.length);
    for (var iter23 in this.inventoryCountIdSet)
    {
      if (this.inventoryCountIdSet.hasOwnProperty(iter23))
      {
        iter23 = this.inventoryCountIdSet[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownInventoryCountIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryModel.InventoryCountIdAndMetadata]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
  }
};
InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_result.prototype = {};
InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.success = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new InventoryModel.InventoryCountIdAndMetadata();
          elem30.read(input);
          this.success.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getInventoryCountMetadatasById_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter31 in this.success)
    {
      if (this.success.hasOwnProperty(iter31))
      {
        iter31 = this.success[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 5);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_args = function(args) {
  this.actor = null;
  this.locationId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_args.prototype = {};
InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.noSuchInventoryCountException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof InventoryExceptions.NoSuchInventoryCountException) {
    this.noSuchInventoryCountException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryModel.InventoryCountIdAndMetadata(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.noSuchInventoryCountException !== undefined && args.noSuchInventoryCountException !== null) {
      this.noSuchInventoryCountException = args.noSuchInventoryCountException;
    }
  }
};
InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_result.prototype = {};
InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryModel.InventoryCountIdAndMetadata();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.noSuchInventoryCountException = new InventoryExceptions.NoSuchInventoryCountException();
        this.noSuchInventoryCountException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.noSuchInventoryCountException !== null && this.noSuchInventoryCountException !== undefined) {
    output.writeFieldBegin('noSuchInventoryCountException', Thrift.Type.STRUCT, 6);
    this.noSuchInventoryCountException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.periodStartInclusive = null;
  this.periodEndExclusive = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.periodStartInclusive !== undefined && args.periodStartInclusive !== null) {
      this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodStartInclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodStartInclusive is unset!');
    }
    if (args.periodEndExclusive !== undefined && args.periodEndExclusive !== null) {
      this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodEndExclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodEndExclusive is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_args.prototype = {};
InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodStartInclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodEndExclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.periodStartInclusive !== null && this.periodStartInclusive !== undefined) {
    output.writeFieldBegin('periodStartInclusive', Thrift.Type.STRUCT, 3);
    this.periodStartInclusive.write(output);
    output.writeFieldEnd();
  }
  if (this.periodEndExclusive !== null && this.periodEndExclusive !== undefined) {
    output.writeFieldBegin('periodEndExclusive', Thrift.Type.STRUCT, 4);
    this.periodEndExclusive.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryModel.InventoryCountIdAndMetadata]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_result.prototype = {};
InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.success = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new InventoryModel.InventoryCountIdAndMetadata();
          elem38.read(input);
          this.success.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter39 in this.success)
    {
      if (this.success.hasOwnProperty(iter39))
      {
        iter39 = this.success[iter39];
        iter39.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.periodStartInclusive = null;
  this.periodEndExclusive = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.periodStartInclusive !== undefined && args.periodStartInclusive !== null) {
      this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodStartInclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodStartInclusive is unset!');
    }
    if (args.periodEndExclusive !== undefined && args.periodEndExclusive !== null) {
      this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodEndExclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodEndExclusive is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_args.prototype = {};
InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodStartInclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodEndExclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.periodStartInclusive !== null && this.periodStartInclusive !== undefined) {
    output.writeFieldBegin('periodStartInclusive', Thrift.Type.STRUCT, 3);
    this.periodStartInclusive.write(output);
    output.writeFieldEnd();
  }
  if (this.periodEndExclusive !== null && this.periodEndExclusive !== undefined) {
    output.writeFieldBegin('periodEndExclusive', Thrift.Type.STRUCT, 4);
    this.periodEndExclusive.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryModel.InventoryCountIdAndMetadata]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_result.prototype = {};
InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size40 = 0;
        var _rtmp344;
        this.success = [];
        var _etype43 = 0;
        _rtmp344 = input.readListBegin();
        _etype43 = _rtmp344.etype;
        _size40 = _rtmp344.size;
        for (var _i45 = 0; _i45 < _size40; ++_i45)
        {
          var elem46 = null;
          elem46 = new InventoryModel.InventoryCountIdAndMetadata();
          elem46.read(input);
          this.success.push(elem46);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter47 in this.success)
    {
      if (this.success.hasOwnProperty(iter47))
      {
        iter47 = this.success[iter47];
        iter47.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.startIndexInclusive = null;
  this.maxResultSize = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.startIndexInclusive !== undefined && args.startIndexInclusive !== null) {
      this.startIndexInclusive = args.startIndexInclusive;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field startIndexInclusive is unset!');
    }
    if (args.maxResultSize !== undefined && args.maxResultSize !== null) {
      this.maxResultSize = args.maxResultSize;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field maxResultSize is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_args.prototype = {};
InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.startIndexInclusive = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.maxResultSize = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.startIndexInclusive !== null && this.startIndexInclusive !== undefined) {
    output.writeFieldBegin('startIndexInclusive', Thrift.Type.I64, 3);
    output.writeI64(this.startIndexInclusive);
    output.writeFieldEnd();
  }
  if (this.maxResultSize !== null && this.maxResultSize !== undefined) {
    output.writeFieldBegin('maxResultSize', Thrift.Type.I64, 4);
    output.writeI64(this.maxResultSize);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryModel.InventoryCountIdAndMetadata]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_result.prototype = {};
InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size48 = 0;
        var _rtmp352;
        this.success = [];
        var _etype51 = 0;
        _rtmp352 = input.readListBegin();
        _etype51 = _rtmp352.etype;
        _size48 = _rtmp352.size;
        for (var _i53 = 0; _i53 < _size48; ++_i53)
        {
          var elem54 = null;
          elem54 = new InventoryModel.InventoryCountIdAndMetadata();
          elem54.read(input);
          this.success.push(elem54);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter55 in this.success)
    {
      if (this.success.hasOwnProperty(iter55))
      {
        iter55 = this.success[iter55];
        iter55.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.endTimeExclusive = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.endTimeExclusive !== undefined && args.endTimeExclusive !== null) {
      this.endTimeExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.endTimeExclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field endTimeExclusive is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_args.prototype = {};
InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.endTimeExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.endTimeExclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.endTimeExclusive !== null && this.endTimeExclusive !== undefined) {
    output.writeFieldBegin('endTimeExclusive', Thrift.Type.STRUCT, 3);
    this.endTimeExclusive.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.noSuchInventoryCountException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof InventoryExceptions.NoSuchInventoryCountException) {
    this.noSuchInventoryCountException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryModel.InventoryCountIdAndMetadata(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.noSuchInventoryCountException !== undefined && args.noSuchInventoryCountException !== null) {
      this.noSuchInventoryCountException = args.noSuchInventoryCountException;
    }
  }
};
InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_result.prototype = {};
InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryModel.InventoryCountIdAndMetadata();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.noSuchInventoryCountException = new InventoryExceptions.NoSuchInventoryCountException();
        this.noSuchInventoryCountException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.noSuchInventoryCountException !== null && this.noSuchInventoryCountException !== undefined) {
    output.writeFieldBegin('noSuchInventoryCountException', Thrift.Type.STRUCT, 6);
    this.noSuchInventoryCountException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.startTimeInclusive = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.startTimeInclusive !== undefined && args.startTimeInclusive !== null) {
      this.startTimeInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.startTimeInclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field startTimeInclusive is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_args.prototype = {};
InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.startTimeInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.startTimeInclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.startTimeInclusive !== null && this.startTimeInclusive !== undefined) {
    output.writeFieldBegin('startTimeInclusive', Thrift.Type.STRUCT, 3);
    this.startTimeInclusive.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.noSuchInventoryCountException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof InventoryExceptions.NoSuchInventoryCountException) {
    this.noSuchInventoryCountException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryModel.InventoryCountIdAndMetadata(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.noSuchInventoryCountException !== undefined && args.noSuchInventoryCountException !== null) {
      this.noSuchInventoryCountException = args.noSuchInventoryCountException;
    }
  }
};
InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_result.prototype = {};
InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryModel.InventoryCountIdAndMetadata();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.noSuchInventoryCountException = new InventoryExceptions.NoSuchInventoryCountException();
        this.noSuchInventoryCountException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.noSuchInventoryCountException !== null && this.noSuchInventoryCountException !== undefined) {
    output.writeFieldBegin('noSuchInventoryCountException', Thrift.Type.STRUCT, 6);
    this.noSuchInventoryCountException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_args = function(args) {
  this.actor = null;
  this.storageAreaName = null;
  this.storageAreaNote = null;
  this.locationId = null;
  this.inventoryCountId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.storageAreaName !== undefined && args.storageAreaName !== null) {
      this.storageAreaName = args.storageAreaName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaName is unset!');
    }
    if (args.storageAreaNote !== undefined && args.storageAreaNote !== null) {
      this.storageAreaNote = args.storageAreaNote;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaNote is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.storageAreaName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.storageAreaNote = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_createAndAddStorageAreaAtInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaName !== null && this.storageAreaName !== undefined) {
    output.writeFieldBegin('storageAreaName', Thrift.Type.STRING, 2);
    output.writeString(this.storageAreaName);
    output.writeFieldEnd();
  }
  if (this.storageAreaNote !== null && this.storageAreaNote !== undefined) {
    output.writeFieldBegin('storageAreaNote', Thrift.Type.STRING, 3);
    output.writeString(this.storageAreaNote);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 4);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 5);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 6);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryModel.StorageAreaId(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
  }
};
InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryModel.StorageAreaId();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_createAndAddStorageAreaAtInventoryCount_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 6);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 7);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_args = function(args) {
  this.actor = null;
  this.storageAreaId = null;
  this.inventoryCountId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_removeStorageAreaAtInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 2);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 3);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 4);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownStorageAreaIdExceptions = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdExceptions = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownStorageAreaIdExceptions !== undefined && args.unknownStorageAreaIdExceptions !== null) {
      this.unknownStorageAreaIdExceptions = args.unknownStorageAreaIdExceptions;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
  }
};
InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdExceptions = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdExceptions.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_removeStorageAreaAtInventoryCount_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdExceptions !== null && this.unknownStorageAreaIdExceptions !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdExceptions', Thrift.Type.STRUCT, 5);
    this.unknownStorageAreaIdExceptions.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 6);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 7);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_args = function(args) {
  this.actor = null;
  this.productId = null;
  this.storageAreaId = null;
  this.inventoryCountId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_addProductToStorageAreaAtInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 3);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 4);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 5);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownProductIdException = null;
  this.unknownStorageAreaIdExceptions = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdExceptions = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unknownStorageAreaIdExceptions !== undefined && args.unknownStorageAreaIdExceptions !== null) {
      this.unknownStorageAreaIdExceptions = args.unknownStorageAreaIdExceptions;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
  }
};
InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdExceptions = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdExceptions.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_addProductToStorageAreaAtInventoryCount_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 5);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdExceptions !== null && this.unknownStorageAreaIdExceptions !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdExceptions', Thrift.Type.STRUCT, 6);
    this.unknownStorageAreaIdExceptions.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 7);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 8);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_args = function(args) {
  this.actor = null;
  this.productId = null;
  this.storageAreaId = null;
  this.inventoryCountId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_removeProductFromStorageAreaAtInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 3);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 4);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 5);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownProductIdException = null;
  this.unknownStorageAreaIdExceptions = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdExceptions = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unknownStorageAreaIdExceptions !== undefined && args.unknownStorageAreaIdExceptions !== null) {
      this.unknownStorageAreaIdExceptions = args.unknownStorageAreaIdExceptions;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
  }
};
InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdExceptions = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdExceptions.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_removeProductFromStorageAreaAtInventoryCount_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 5);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdExceptions !== null && this.unknownStorageAreaIdExceptions !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdExceptions', Thrift.Type.STRUCT, 6);
    this.unknownStorageAreaIdExceptions.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 7);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 8);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_args = function(args) {
  this.actor = null;
  this.productId = null;
  this.inventoryCountId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 3);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 4);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownProductIdException = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
  }
};
InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 5);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 7);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 8);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_setProductCountAtStorageArea_args = function(args) {
  this.actor = null;
  this.productId = null;
  this.storageAreaId = null;
  this.inventoryCountId = null;
  this.productCount = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.productCount !== undefined && args.productCount !== null) {
      this.productCount = Thrift.copyList(args.productCount, [InventoryModel.ProductCount]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCount is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_setProductCountAtStorageArea_args.prototype = {};
InventoryCountService.InventoryCountService_setProductCountAtStorageArea_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        var _size56 = 0;
        var _rtmp360;
        this.productCount = [];
        var _etype59 = 0;
        _rtmp360 = input.readListBegin();
        _etype59 = _rtmp360.etype;
        _size56 = _rtmp360.size;
        for (var _i61 = 0; _i61 < _size56; ++_i61)
        {
          var elem62 = null;
          elem62 = new InventoryModel.ProductCount();
          elem62.read(input);
          this.productCount.push(elem62);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_setProductCountAtStorageArea_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_setProductCountAtStorageArea_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 3);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 4);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.productCount !== null && this.productCount !== undefined) {
    output.writeFieldBegin('productCount', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.productCount.length);
    for (var iter63 in this.productCount)
    {
      if (this.productCount.hasOwnProperty(iter63))
      {
        iter63 = this.productCount[iter63];
        iter63.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 6);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_setProductCountAtStorageArea_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownProductIdException = null;
  this.unknownStorageAreaIdExceptions = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdExceptions = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unknownStorageAreaIdExceptions !== undefined && args.unknownStorageAreaIdExceptions !== null) {
      this.unknownStorageAreaIdExceptions = args.unknownStorageAreaIdExceptions;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
  }
};
InventoryCountService.InventoryCountService_setProductCountAtStorageArea_result.prototype = {};
InventoryCountService.InventoryCountService_setProductCountAtStorageArea_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdExceptions = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdExceptions.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_setProductCountAtStorageArea_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_setProductCountAtStorageArea_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 5);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdExceptions !== null && this.unknownStorageAreaIdExceptions !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdExceptions', Thrift.Type.STRUCT, 6);
    this.unknownStorageAreaIdExceptions.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 7);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 8);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createInventoryCount_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.inventoryCountTime = null;
  this.inventoryCountType = null;
  this.name = null;
  this.note = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.inventoryCountTime !== undefined && args.inventoryCountTime !== null) {
      this.inventoryCountTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.inventoryCountTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountTime is unset!');
    }
    if (args.inventoryCountType !== undefined && args.inventoryCountType !== null) {
      this.inventoryCountType = args.inventoryCountType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountType is unset!');
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_createInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_createInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.inventoryCountTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.inventoryCountType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_createInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountTime !== null && this.inventoryCountTime !== undefined) {
    output.writeFieldBegin('inventoryCountTime', Thrift.Type.STRUCT, 3);
    this.inventoryCountTime.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountType !== null && this.inventoryCountType !== undefined) {
    output.writeFieldBegin('inventoryCountType', Thrift.Type.I32, 4);
    output.writeI32(this.inventoryCountType);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 5);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 6);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createInventoryCount_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.currentInventoryCountAlreadyExistsForLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.CurrentInventoryCountAlreadyExistsForLocationException) {
    this.currentInventoryCountAlreadyExistsForLocationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryModel.InventoryCountId(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.currentInventoryCountAlreadyExistsForLocationException !== undefined && args.currentInventoryCountAlreadyExistsForLocationException !== null) {
      this.currentInventoryCountAlreadyExistsForLocationException = args.currentInventoryCountAlreadyExistsForLocationException;
    }
  }
};
InventoryCountService.InventoryCountService_createInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_createInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryModel.InventoryCountId();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.currentInventoryCountAlreadyExistsForLocationException = new InventoryExceptions.CurrentInventoryCountAlreadyExistsForLocationException();
        this.currentInventoryCountAlreadyExistsForLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_createInventoryCount_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.currentInventoryCountAlreadyExistsForLocationException !== null && this.currentInventoryCountAlreadyExistsForLocationException !== undefined) {
    output.writeFieldBegin('currentInventoryCountAlreadyExistsForLocationException', Thrift.Type.STRUCT, 5);
    this.currentInventoryCountAlreadyExistsForLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createMergedInventoryCount_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.inventoryCountId0 = null;
  this.inventoryCountId1 = null;
  this.inventoryCountTime = null;
  this.inventoryCountType = null;
  this.name = null;
  this.note = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.inventoryCountId0 !== undefined && args.inventoryCountId0 !== null) {
      this.inventoryCountId0 = new InventoryModel.InventoryCountId(args.inventoryCountId0);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId0 is unset!');
    }
    if (args.inventoryCountId1 !== undefined && args.inventoryCountId1 !== null) {
      this.inventoryCountId1 = new InventoryModel.InventoryCountId(args.inventoryCountId1);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId1 is unset!');
    }
    if (args.inventoryCountTime !== undefined && args.inventoryCountTime !== null) {
      this.inventoryCountTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.inventoryCountTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountTime is unset!');
    }
    if (args.inventoryCountType !== undefined && args.inventoryCountType !== null) {
      this.inventoryCountType = args.inventoryCountType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountType is unset!');
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_createMergedInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_createMergedInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId0 = new InventoryModel.InventoryCountId();
        this.inventoryCountId0.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId1 = new InventoryModel.InventoryCountId();
        this.inventoryCountId1.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.inventoryCountTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I32) {
        this.inventoryCountType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createMergedInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_createMergedInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId0 !== null && this.inventoryCountId0 !== undefined) {
    output.writeFieldBegin('inventoryCountId0', Thrift.Type.STRUCT, 3);
    this.inventoryCountId0.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId1 !== null && this.inventoryCountId1 !== undefined) {
    output.writeFieldBegin('inventoryCountId1', Thrift.Type.STRUCT, 4);
    this.inventoryCountId1.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountTime !== null && this.inventoryCountTime !== undefined) {
    output.writeFieldBegin('inventoryCountTime', Thrift.Type.STRUCT, 5);
    this.inventoryCountTime.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountType !== null && this.inventoryCountType !== undefined) {
    output.writeFieldBegin('inventoryCountType', Thrift.Type.I32, 6);
    output.writeI32(this.inventoryCountType);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 7);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 8);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createMergedInventoryCount_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  this.unknownInventoryCountIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryModel.InventoryCountId(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
  }
};
InventoryCountService.InventoryCountService_createMergedInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_createMergedInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryModel.InventoryCountId();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_createMergedInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_createMergedInventoryCount_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 6);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_finalizeInventoryCount_args = function(args) {
  this.actor = null;
  this.inventoryCountId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_finalizeInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_finalizeInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_finalizeInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_finalizeInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 2);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_finalizeInventoryCount_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  this.inventoryCountIsFinalizedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsFinalizedException) {
    this.inventoryCountIsFinalizedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
    if (args.inventoryCountIsFinalizedException !== undefined && args.inventoryCountIsFinalizedException !== null) {
      this.inventoryCountIsFinalizedException = args.inventoryCountIsFinalizedException;
    }
  }
};
InventoryCountService.InventoryCountService_finalizeInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_finalizeInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsFinalizedException = new InventoryExceptions.InventoryCountIsFinalizedException();
        this.inventoryCountIsFinalizedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_finalizeInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_finalizeInventoryCount_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 5);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 6);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsFinalizedException !== null && this.inventoryCountIsFinalizedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsFinalizedException', Thrift.Type.STRUCT, 7);
    this.inventoryCountIsFinalizedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_deleteInventoryCount_args = function(args) {
  this.actor = null;
  this.inventoryCountId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_deleteInventoryCount_args.prototype = {};
InventoryCountService.InventoryCountService_deleteInventoryCount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_deleteInventoryCount_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_deleteInventoryCount_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 2);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_deleteInventoryCount_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
  }
};
InventoryCountService.InventoryCountService_deleteInventoryCount_result.prototype = {};
InventoryCountService.InventoryCountService_deleteInventoryCount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_deleteInventoryCount_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_deleteInventoryCount_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 5);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 6);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_setInventoryCountMetadata_args = function(args) {
  this.actor = null;
  this.inventoryCountId = null;
  this.inventoryCountTime = null;
  this.inventoryCountType = null;
  this.name = null;
  this.note = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.inventoryCountTime !== undefined && args.inventoryCountTime !== null) {
      this.inventoryCountTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.inventoryCountTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountTime is unset!');
    }
    if (args.inventoryCountType !== undefined && args.inventoryCountType !== null) {
      this.inventoryCountType = args.inventoryCountType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountType is unset!');
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
  }
};
InventoryCountService.InventoryCountService_setInventoryCountMetadata_args.prototype = {};
InventoryCountService.InventoryCountService_setInventoryCountMetadata_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.inventoryCountTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.inventoryCountType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_setInventoryCountMetadata_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_setInventoryCountMetadata_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 2);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountTime !== null && this.inventoryCountTime !== undefined) {
    output.writeFieldBegin('inventoryCountTime', Thrift.Type.STRUCT, 3);
    this.inventoryCountTime.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountType !== null && this.inventoryCountType !== undefined) {
    output.writeFieldBegin('inventoryCountType', Thrift.Type.I32, 4);
    output.writeI32(this.inventoryCountType);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 5);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 6);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountService_setInventoryCountMetadata_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownInventoryCountIdException = null;
  this.inventoryCountIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownInventoryCountIdException) {
    this.unknownInventoryCountIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.InventoryCountIsDeletedException) {
    this.inventoryCountIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownInventoryCountIdException !== undefined && args.unknownInventoryCountIdException !== null) {
      this.unknownInventoryCountIdException = args.unknownInventoryCountIdException;
    }
    if (args.inventoryCountIsDeletedException !== undefined && args.inventoryCountIsDeletedException !== null) {
      this.inventoryCountIsDeletedException = args.inventoryCountIsDeletedException;
    }
  }
};
InventoryCountService.InventoryCountService_setInventoryCountMetadata_result.prototype = {};
InventoryCountService.InventoryCountService_setInventoryCountMetadata_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownInventoryCountIdException = new InventoryExceptions.UnknownInventoryCountIdException();
        this.unknownInventoryCountIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountIsDeletedException = new InventoryExceptions.InventoryCountIsDeletedException();
        this.inventoryCountIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCountService.InventoryCountService_setInventoryCountMetadata_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountService_setInventoryCountMetadata_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownInventoryCountIdException !== null && this.unknownInventoryCountIdException !== undefined) {
    output.writeFieldBegin('unknownInventoryCountIdException', Thrift.Type.STRUCT, 5);
    this.unknownInventoryCountIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountIsDeletedException !== null && this.inventoryCountIsDeletedException !== undefined) {
    output.writeFieldBegin('inventoryCountIsDeletedException', Thrift.Type.STRUCT, 6);
    this.inventoryCountIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCountService.InventoryCountServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
InventoryCountService.InventoryCountServiceClient.prototype = {};
InventoryCountService.InventoryCountServiceClient.prototype.getInventoryCountsById = function(actor, inventoryCountIdSet, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getInventoryCountsById(pendingError, actor, inventoryCountIdSet, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getInventoryCountsById();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_getInventoryCountsById = function(pendingError, actor, inventoryCountIdSet, callback) {
  this.output.writeMessageBegin('getInventoryCountsById', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_getInventoryCountsById_args();
  args.actor = actor;
  args.inventoryCountIdSet = inventoryCountIdSet;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getInventoryCountsById() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_getInventoryCountsById = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_getInventoryCountsById_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getInventoryCountsById failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.getInventoryCountMetadatasById = function(actor, inventoryCountIdSet, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getInventoryCountMetadatasById(pendingError, actor, inventoryCountIdSet, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getInventoryCountMetadatasById();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_getInventoryCountMetadatasById = function(pendingError, actor, inventoryCountIdSet, callback) {
  this.output.writeMessageBegin('getInventoryCountMetadatasById', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_args();
  args.actor = actor;
  args.inventoryCountIdSet = inventoryCountIdSet;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getInventoryCountMetadatasById() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_getInventoryCountMetadatasById = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_getInventoryCountMetadatasById_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getInventoryCountMetadatasById failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.getCurrentInventoryCountIdAndMetadataForLocation = function(actor, locationId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getCurrentInventoryCountIdAndMetadataForLocation(pendingError, actor, locationId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getCurrentInventoryCountIdAndMetadataForLocation();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_getCurrentInventoryCountIdAndMetadataForLocation = function(pendingError, actor, locationId, callback) {
  this.output.writeMessageBegin('getCurrentInventoryCountIdAndMetadataForLocation', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_args();
  args.actor = actor;
  args.locationId = locationId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getCurrentInventoryCountIdAndMetadataForLocation() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_getCurrentInventoryCountIdAndMetadataForLocation = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_getCurrentInventoryCountIdAndMetadataForLocation_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.noSuchInventoryCountException) {
    throw result.noSuchInventoryCountException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getCurrentInventoryCountIdAndMetadataForLocation failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.getFinalizedInventoryCountMetadatasByIdForLocationInPeriod = function(actor, locationId, periodStartInclusive, periodEndExclusive, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod(pendingError, actor, locationId, periodStartInclusive, periodEndExclusive, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod = function(pendingError, actor, locationId, periodStartInclusive, periodEndExclusive, callback) {
  this.output.writeMessageBegin('getFinalizedInventoryCountMetadatasByIdForLocationInPeriod', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_args();
  args.actor = actor;
  args.locationId = locationId;
  args.periodStartInclusive = periodStartInclusive;
  args.periodEndExclusive = periodEndExclusive;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationInPeriod_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getFinalizedInventoryCountMetadatasByIdForLocationInPeriod failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.getInventoryCountMetadatasByIdForLocationFinalizedInPeriod = function(actor, locationId, periodStartInclusive, periodEndExclusive, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod(pendingError, actor, locationId, periodStartInclusive, periodEndExclusive, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod = function(pendingError, actor, locationId, periodStartInclusive, periodEndExclusive, callback) {
  this.output.writeMessageBegin('getInventoryCountMetadatasByIdForLocationFinalizedInPeriod', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_args();
  args.actor = actor;
  args.locationId = locationId;
  args.periodStartInclusive = periodStartInclusive;
  args.periodEndExclusive = periodEndExclusive;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_getInventoryCountMetadatasByIdForLocationFinalizedInPeriod_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getInventoryCountMetadatasByIdForLocationFinalizedInPeriod failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate = function(actor, locationId, startIndexInclusive, maxResultSize, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate(pendingError, actor, locationId, startIndexInclusive, maxResultSize, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate = function(pendingError, actor, locationId, startIndexInclusive, maxResultSize, callback) {
  this.output.writeMessageBegin('getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_args();
  args.actor = actor;
  args.locationId = locationId;
  args.startIndexInclusive = startIndexInclusive;
  args.maxResultSize = maxResultSize;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getFinalizedInventoryCountMetadatasByIdForLocationByIndexSortedByDate failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime = function(actor, locationId, endTimeExclusive, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime(pendingError, actor, locationId, endTimeExclusive, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime = function(pendingError, actor, locationId, endTimeExclusive, callback) {
  this.output.writeMessageBegin('getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_args();
  args.actor = actor;
  args.locationId = locationId;
  args.endTimeExclusive = endTimeExclusive;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.noSuchInventoryCountException) {
    throw result.noSuchInventoryCountException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getMostRecentFinalizedInventoryIdAndMetadataForLocationBeforeTime failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime = function(actor, locationId, startTimeInclusive, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime(pendingError, actor, locationId, startTimeInclusive, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime = function(pendingError, actor, locationId, startTimeInclusive, callback) {
  this.output.writeMessageBegin('getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_args();
  args.actor = actor;
  args.locationId = locationId;
  args.startTimeInclusive = startTimeInclusive;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.noSuchInventoryCountException) {
    throw result.noSuchInventoryCountException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getOldestFinalizedInventoryIdAndMetadataForLocationAtOrAfterTime failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.createAndAddStorageAreaAtInventoryCount = function(actor, storageAreaName, storageAreaNote, locationId, inventoryCountId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createAndAddStorageAreaAtInventoryCount(pendingError, actor, storageAreaName, storageAreaNote, locationId, inventoryCountId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createAndAddStorageAreaAtInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_createAndAddStorageAreaAtInventoryCount = function(pendingError, actor, storageAreaName, storageAreaNote, locationId, inventoryCountId, clientTimestamp, callback) {
  this.output.writeMessageBegin('createAndAddStorageAreaAtInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_args();
  args.actor = actor;
  args.storageAreaName = storageAreaName;
  args.storageAreaNote = storageAreaNote;
  args.locationId = locationId;
  args.inventoryCountId = inventoryCountId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createAndAddStorageAreaAtInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_createAndAddStorageAreaAtInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_createAndAddStorageAreaAtInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createAndAddStorageAreaAtInventoryCount failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.removeStorageAreaAtInventoryCount = function(actor, storageAreaId, inventoryCountId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_removeStorageAreaAtInventoryCount(pendingError, actor, storageAreaId, inventoryCountId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_removeStorageAreaAtInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_removeStorageAreaAtInventoryCount = function(pendingError, actor, storageAreaId, inventoryCountId, clientTimestamp, callback) {
  this.output.writeMessageBegin('removeStorageAreaAtInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_args();
  args.actor = actor;
  args.storageAreaId = storageAreaId;
  args.inventoryCountId = inventoryCountId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_removeStorageAreaAtInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_removeStorageAreaAtInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_removeStorageAreaAtInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownStorageAreaIdExceptions) {
    throw result.unknownStorageAreaIdExceptions;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  return;
};
InventoryCountService.InventoryCountServiceClient.prototype.addProductToStorageAreaAtInventoryCount = function(actor, productId, storageAreaId, inventoryCountId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_addProductToStorageAreaAtInventoryCount(pendingError, actor, productId, storageAreaId, inventoryCountId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_addProductToStorageAreaAtInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_addProductToStorageAreaAtInventoryCount = function(pendingError, actor, productId, storageAreaId, inventoryCountId, clientTimestamp, callback) {
  this.output.writeMessageBegin('addProductToStorageAreaAtInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_args();
  args.actor = actor;
  args.productId = productId;
  args.storageAreaId = storageAreaId;
  args.inventoryCountId = inventoryCountId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_addProductToStorageAreaAtInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_addProductToStorageAreaAtInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_addProductToStorageAreaAtInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unknownStorageAreaIdExceptions) {
    throw result.unknownStorageAreaIdExceptions;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  return;
};
InventoryCountService.InventoryCountServiceClient.prototype.removeProductFromStorageAreaAtInventoryCount = function(actor, productId, storageAreaId, inventoryCountId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_removeProductFromStorageAreaAtInventoryCount(pendingError, actor, productId, storageAreaId, inventoryCountId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_removeProductFromStorageAreaAtInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_removeProductFromStorageAreaAtInventoryCount = function(pendingError, actor, productId, storageAreaId, inventoryCountId, clientTimestamp, callback) {
  this.output.writeMessageBegin('removeProductFromStorageAreaAtInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_args();
  args.actor = actor;
  args.productId = productId;
  args.storageAreaId = storageAreaId;
  args.inventoryCountId = inventoryCountId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_removeProductFromStorageAreaAtInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_removeProductFromStorageAreaAtInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_removeProductFromStorageAreaAtInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unknownStorageAreaIdExceptions) {
    throw result.unknownStorageAreaIdExceptions;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  return;
};
InventoryCountService.InventoryCountServiceClient.prototype.removeProductFromAllStorageAreasAtInventoryCount = function(actor, productId, inventoryCountId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_removeProductFromAllStorageAreasAtInventoryCount(pendingError, actor, productId, inventoryCountId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_removeProductFromAllStorageAreasAtInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_removeProductFromAllStorageAreasAtInventoryCount = function(pendingError, actor, productId, inventoryCountId, clientTimestamp, callback) {
  this.output.writeMessageBegin('removeProductFromAllStorageAreasAtInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_args();
  args.actor = actor;
  args.productId = productId;
  args.inventoryCountId = inventoryCountId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_removeProductFromAllStorageAreasAtInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_removeProductFromAllStorageAreasAtInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_removeProductFromAllStorageAreasAtInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  return;
};
InventoryCountService.InventoryCountServiceClient.prototype.setProductCountAtStorageArea = function(actor, productId, storageAreaId, inventoryCountId, productCount, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_setProductCountAtStorageArea(pendingError, actor, productId, storageAreaId, inventoryCountId, productCount, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_setProductCountAtStorageArea();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_setProductCountAtStorageArea = function(pendingError, actor, productId, storageAreaId, inventoryCountId, productCount, clientTimestamp, callback) {
  this.output.writeMessageBegin('setProductCountAtStorageArea', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_setProductCountAtStorageArea_args();
  args.actor = actor;
  args.productId = productId;
  args.storageAreaId = storageAreaId;
  args.inventoryCountId = inventoryCountId;
  args.productCount = productCount;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_setProductCountAtStorageArea() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_setProductCountAtStorageArea = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_setProductCountAtStorageArea_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unknownStorageAreaIdExceptions) {
    throw result.unknownStorageAreaIdExceptions;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  return;
};
InventoryCountService.InventoryCountServiceClient.prototype.createInventoryCount = function(actor, locationId, inventoryCountTime, inventoryCountType, name, note, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createInventoryCount(pendingError, actor, locationId, inventoryCountTime, inventoryCountType, name, note, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_createInventoryCount = function(pendingError, actor, locationId, inventoryCountTime, inventoryCountType, name, note, callback) {
  this.output.writeMessageBegin('createInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_createInventoryCount_args();
  args.actor = actor;
  args.locationId = locationId;
  args.inventoryCountTime = inventoryCountTime;
  args.inventoryCountType = inventoryCountType;
  args.name = name;
  args.note = note;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_createInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_createInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.currentInventoryCountAlreadyExistsForLocationException) {
    throw result.currentInventoryCountAlreadyExistsForLocationException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createInventoryCount failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.createMergedInventoryCount = function(actor, locationId, inventoryCountId0, inventoryCountId1, inventoryCountTime, inventoryCountType, name, note, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createMergedInventoryCount(pendingError, actor, locationId, inventoryCountId0, inventoryCountId1, inventoryCountTime, inventoryCountType, name, note, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createMergedInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_createMergedInventoryCount = function(pendingError, actor, locationId, inventoryCountId0, inventoryCountId1, inventoryCountTime, inventoryCountType, name, note, callback) {
  this.output.writeMessageBegin('createMergedInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_createMergedInventoryCount_args();
  args.actor = actor;
  args.locationId = locationId;
  args.inventoryCountId0 = inventoryCountId0;
  args.inventoryCountId1 = inventoryCountId1;
  args.inventoryCountTime = inventoryCountTime;
  args.inventoryCountType = inventoryCountType;
  args.name = name;
  args.note = note;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createMergedInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_createMergedInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_createMergedInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createMergedInventoryCount failed: unknown result';
};
InventoryCountService.InventoryCountServiceClient.prototype.finalizeInventoryCount = function(actor, inventoryCountId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_finalizeInventoryCount(pendingError, actor, inventoryCountId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_finalizeInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_finalizeInventoryCount = function(pendingError, actor, inventoryCountId, callback) {
  this.output.writeMessageBegin('finalizeInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_finalizeInventoryCount_args();
  args.actor = actor;
  args.inventoryCountId = inventoryCountId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_finalizeInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_finalizeInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_finalizeInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  if (null !== result.inventoryCountIsFinalizedException) {
    throw result.inventoryCountIsFinalizedException;
  }
  return;
};
InventoryCountService.InventoryCountServiceClient.prototype.deleteInventoryCount = function(actor, inventoryCountId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteInventoryCount(pendingError, actor, inventoryCountId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteInventoryCount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_deleteInventoryCount = function(pendingError, actor, inventoryCountId, callback) {
  this.output.writeMessageBegin('deleteInventoryCount', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_deleteInventoryCount_args();
  args.actor = actor;
  args.inventoryCountId = inventoryCountId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteInventoryCount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_deleteInventoryCount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_deleteInventoryCount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  return;
};
InventoryCountService.InventoryCountServiceClient.prototype.setInventoryCountMetadata = function(actor, inventoryCountId, inventoryCountTime, inventoryCountType, name, note, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_setInventoryCountMetadata(pendingError, actor, inventoryCountId, inventoryCountTime, inventoryCountType, name, note, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_setInventoryCountMetadata();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.send_setInventoryCountMetadata = function(pendingError, actor, inventoryCountId, inventoryCountTime, inventoryCountType, name, note, callback) {
  this.output.writeMessageBegin('setInventoryCountMetadata', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCountService.InventoryCountService_setInventoryCountMetadata_args();
  args.actor = actor;
  args.inventoryCountId = inventoryCountId;
  args.inventoryCountTime = inventoryCountTime;
  args.inventoryCountType = inventoryCountType;
  args.name = name;
  args.note = note;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_setInventoryCountMetadata() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCountService.InventoryCountServiceClient.prototype.recv_setInventoryCountMetadata = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCountService.InventoryCountService_setInventoryCountMetadata_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownInventoryCountIdException) {
    throw result.unknownInventoryCountIdException;
  }
  if (null !== result.inventoryCountIsDeletedException) {
    throw result.inventoryCountIsDeletedException;
  }
  return;
};
