import { ThunkAction } from 'redux-thunk';

import { IAction, IActionCreatorsMapObject } from '../../../models/IAction';
import { validateValueByFieldName } from '../utils';

import { ISignInFormState, signInFormFieldName } from './reducers';

import { UserSessionManagerImpl } from '../../../lib/account/impl/UserSessionManagerImpl';
import { BevSpotDispatch } from 'shared/components/Provider';

export interface ISignInFormStore {
    signInFormState : ISignInFormState;
}

export const ActionTypes = {
    SET_FORM_FIELD_VALIDATION_DATA : 'SIGN_IN_FORM/SET_FORM_FIELD_VALIDATION_DATA',
    SET_FORM_VALIDATION_DATA : 'SIGN_IN_FORM/SET_FORM_VALIDATION_DATA',
    SET_IS_SUBMITTING : 'SIGN_IN_FORM/SET_IS_SUBMITTING',
};

export namespace SignInFormActionInterfaces {
    export interface ISetFormFieldValidationData extends IAction {
        payload : {
            field : signInFormFieldName;
            value : string;
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetFormValidationData extends IAction {
        payload : {
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetIsSubmitting extends IAction {
        payload : {
            isSubmitting : boolean;
        };
    }

    export interface ISignInFormActionCreatorsMapObject extends IActionCreatorsMapObject {
        onFormFieldChange : (
            field : signInFormFieldName,
            value : string
        ) => ThunkAction<void, ISignInFormStore, {services : SignInFormActionInterfaces.IServices}>;
        onSubmit : (
            formState : ISignInFormState,
        ) => ThunkAction<Promise<boolean | void>, ISignInFormStore, {services : SignInFormActionInterfaces.IServices}>;
        onResetForm : () => ThunkAction<void, ISignInFormStore, {services : SignInFormActionInterfaces.IServices}>;
    }

    export interface IServices {
        userSessionManager : UserSessionManagerImpl;
    }
}

export type SignInFormDispatch = BevSpotDispatch<ISignInFormStore, { services: SignInFormActionInterfaces.IServices }>;

const setFormFieldValidationData = (
    field : signInFormFieldName,
    value : string,
    isValid : boolean,
    errorMessage : string
) : SignInFormActionInterfaces.ISetFormFieldValidationData => ({
    payload : {
        field,
        value,
        isValid,
        errorMessage,
    },
    type : ActionTypes.SET_FORM_FIELD_VALIDATION_DATA,
});

const setFormValidationData = (
    isValid : boolean,
    errorMessage : string
) : SignInFormActionInterfaces.ISetFormValidationData => ({
    payload : {
        isValid,
        errorMessage,
    },
    type : ActionTypes.SET_FORM_VALIDATION_DATA,
});

const setIsSubmitting = (
    isSubmitting : boolean
) : SignInFormActionInterfaces.ISetIsSubmitting => ({
    payload : {
        isSubmitting,
    },
    type : ActionTypes.SET_IS_SUBMITTING,
});

const onFormFieldChange = (
    field : signInFormFieldName,
    value : string,
) : ThunkAction<void, ISignInFormStore, {services : SignInFormActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : void => {
        const validationResult = validateValueByFieldName(field, value, null);
        dispatch(setFormFieldValidationData(field, value, validationResult.isValid, validationResult.errorMessage));
    };
};

const onSubmit = (
    formState : ISignInFormState,
) : ThunkAction<Promise<boolean | void>, ISignInFormStore, {services : SignInFormActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<boolean | void> => {
        const validationInputDataByFieldName = formState.validationInputDataByFieldName;
        let formIsValid = true;
        Object.keys(validationInputDataByFieldName).forEach((key) => {
            const fieldName = key as signInFormFieldName;
            const fieldValue = (validationInputDataByFieldName)[fieldName].value;
            const validationResult = validateValueByFieldName(fieldName, fieldValue, null);

            const { isValid, errorMessage } = validationResult;
            formIsValid = formIsValid && isValid;

            dispatch(setFormFieldValidationData(fieldName, fieldValue, isValid, errorMessage));
        });

        if (formIsValid) {
            return Promise.resolve(false);
        } else {
            dispatch(setFormValidationData(false, 'Please correctly fill in the form fields.'));
        }

        return Promise.resolve(false);
    };
};

const onResetForm = (
) : ThunkAction<void, ISignInFormStore, {services : SignInFormActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : void => {
        const formFields : Array<signInFormFieldName> = ['emailAddressOrAccountId', 'password']; // This could be more robust

        for (let i = 0; i < formFields.length; i++) {
            dispatch(setFormFieldValidationData(formFields[i], '', true, ''));
        }

        dispatch(setIsSubmitting(false));
    };
};

export const SignInFormActions : SignInFormActionInterfaces.ISignInFormActionCreatorsMapObject = {
    onFormFieldChange,
    onSubmit,
    onResetForm,
};
