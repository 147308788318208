import { ICategoryService } from 'api/Product/interfaces/ICategoryService';
import { IProductCostService } from 'api/Product/interfaces/IProductCostService';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { ProductCost } from 'api/Product/model/ProductCost';
import { productUtils } from 'api/Product/utils/productUtils';
import AccountsExceptions from 'gen-thrift/accounts_Exceptions_types';
import moment from 'moment/moment';
import { ThunkAction } from 'redux-thunk';

import LocationModel from 'gen-thrift/location_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { IProductDistributorService } from 'api/Product/interfaces/IProductDistributorService';
import { IProductService } from 'api/Product/interfaces/IProductService';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { transferReportProductDataUtils } from 'apps/InventoryTransfer/utils/transferReportProductDataUtils';
import { UserSessionManagerImpl } from 'shared/lib/account/impl/UserSessionManagerImpl';

import { DjangoApiManager } from 'shared/api/DjangoApiManager';
import { momentObjectToThriftSerializer } from 'shared/lib/manager';

import { IAction, IActionCreatorsMapObject, IEmptyAction, IFailureAction } from 'shared/models/IAction';

import { IUnmappedProductState } from '../reducers/unmatchedProductModalReducers';

import ProductExceptions from 'gen-thrift/product_Exceptions_types';
import { LocationId } from 'api/Location/model/LocationId';
import { ILocationProductService } from 'api/Location/interfaces/ILocationProductService';
import { StringValueSet } from 'api/Core/StringValueSet';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { InterLocationProductMapping } from 'api/Transfer/model/InterLocationProductMapping';
import { IProductMappingService } from 'api/Transfer/interfaces/IProductMappingService';
import { BevSpotDispatch } from 'shared/components/Provider';

export interface IUnmappedProductStore {
    unmappedProductState : IUnmappedProductState;
}

export const ActionTypes = {
    ADD_PRODUCT_MAPPING: 'UNMAPPED_PRODUCT_MODAL/ADD_PRODUCT_MAPPING',
    ADD_PRODUCT_TO_LOCATION_FAILURE: 'UNMAPPED_PRODUCT_MODAL/ADD_PRODUCT_TO_LOCATION_FAILURE',
    ADD_PRODUCT_TO_LOCATION_REQUEST: 'UNMAPPED_PRODUCT_MODAL/ADD_PRODUCT_TO_LOCATION_REQUEST',
    ADD_PRODUCT_TO_LOCATION_SUCCESS: 'UNMAPPED_PRODUCT_MODAL/ADD_PRODUCT_TO_LOCATION_SUCCESS',
    SET_ADDED_MAPPINGS: 'UNMAPPED_PRODUCT_MODAL/SET_ADDED_MAPPINGS',
    SET_EXISTING_MAPPINGS: 'UNMAPPED_PRODUCT_MODAL/SET_EXISTING_MAPPINGS',
    SET_MODAL_STEP_STATE: 'UNMAPPED_PRODUCT_MODAL/SET_MODAL_STEP_STATE',
    CLEAR_WAS_PRODUCT_ADDED_TO_LOCATION_BY_STEP: 'UNMAPPED_PRODUCT_MODAL/CLEAR_WAS_PRODUCT_ADDED_TO_LOCATION_BY_STEP',
    SET_SELECTED_PRODUCT_FORM: 'UNMAPPED_PRODUCT_MODAL/SET_SELECTED_PRODUCT_FORM',
    SET_UNMAPPED_PRODUCT_IDS: 'UNMAPPED_PRODUCT_MODAL/SET_UNMAPPED_PRODUCT_IDS',
    UPDATE_SEARCH_TERM: 'UNMAPPED_PRODUCT_MODAL/UPDATE_SEARCH_TERM',
    UPDATE_DISPLAYED_SEARCH_RESULTS: 'UNMAPPED_PRODUCT_MODAL/UPDATE_DISPLAYED_SEARCH_RESULTS',
    FETCH_LOCATION_PRODUCTS_FAILURE: 'UNMAPPED_PRODUCT_MODAL/FETCH_LOCATION_PRODUCTS_FAILURE',
    FETCH_LOCATION_PRODUCTS_REQUEST: 'UNMAPPED_PRODUCT_MODAL/FETCH_LOCATION_PRODUCTS_REQUEST',
    FETCH_LOCATION_PRODUCTS_SUCCESS: 'UNMAPPED_PRODUCT_MODAL/FETCH_LOCATION_PRODUCTS_SUCCESS',
    SET_SEARCH_RESULTS: 'UNMAPPED_PRODUCT_MODAL/SET_SEARCH_RESULTS',
};

export namespace ActionInterfaces {
    export interface ISetProductIdsAction extends IAction {
        payload : {
            productIds : Array<ProductId>;
        };
    }
    export interface ISetSelectedProductFormAction extends IAction {
        payload : {
            productId : ProductId | null;
            quantity : {
                value : string;
                isValid : boolean;
                errorMessage : string;
            };
            productQuantityUnit : ProductQuantityUnit;
        };
    }
    export interface ISetExistingMappingsAction extends IAction {
        payload : {
            productMappings : Array<InterLocationProductMapping>;
        };
    }
    export interface ISetModalStepStateAction extends IAction {
        payload : {
            itemNumber : number;
            productsById : StringValueMap<ProductId, Product>;
        };
    }
    export interface IAddProductMappingAction extends IAction {
        payload : {
            productMapping : InterLocationProductMapping;
        };
    }
    export interface ISetAddedMappingsAction extends IAction {
        payload : {
            addedMappings : Array<InterLocationProductMapping>;
        };
    }
    export interface IUpdateSearchTermAction extends IAction {
        payload : {
            searchTerm : string;
        };
    }
    export interface IUpdateDisplayedSearchResultsAction extends IAction {
        payload : {
            products : Array<ProductId>,
        };
    }
    export interface IAddProductToLocationRequestAction extends IAction {
        payload : {
            product : Product;
            locationId : LocationModel.LocationIdentifier;
        };
    }
    export interface IAddProductToLocationSuccessAction extends IAction {
        payload : {
            productId : ProductId;
            product : Product;
        };
    }
    export interface IFetchLocationProductsRequestAction extends IAction {
        payload : {
            locationId : LocationModel.LocationIdentifier;
        };
    }
    export interface ISetSearchResults extends IAction {
        payload : {
            products : Array<ProductId>;
        };
    }

    export interface IFetchLocationProductsSuccessAction extends IAction {
        payload : {
            productsById : StringValueMap<ProductId, Product>;
        };
    }

    export interface IUnmatchedProductModalActionCreatorsMapObject extends IActionCreatorsMapObject {
        setUnmappedProductIds(
            productIds : Array<ProductId>
        ) : ActionInterfaces.ISetProductIdsAction;
        setExistingMappings(
            productMappings : Array<InterLocationProductMapping>
        ) : ActionInterfaces.ISetExistingMappingsAction;
        setSelectedProductForm(
            productId : ProductId | null,
            quantity : {
                value : string;
                isValid : boolean;
                errorMessage : string;
            },
            productQuantityUnit : ProductQuantityUnit
        ) : ActionInterfaces.ISetSelectedProductFormAction;
        setModalStepState(itemNumber : number, productsById : StringValueMap<ProductId, Product>) : ActionInterfaces.ISetModalStepStateAction;
        clearWasProductAddedToLocationByStep() : IEmptyAction;
        addProductMapping(productMapping : InterLocationProductMapping) : ActionInterfaces.IAddProductMappingAction;
        setAddedMappings(
            addedMappings : Array<InterLocationProductMapping>
        ) : ActionInterfaces.ISetAddedMappingsAction;
        setSearchResults(
            products : Array<ProductId>
        ) : ActionInterfaces.ISetSearchResults;
        updateSearchTerm(
            searchTerm : string
        ) : ActionInterfaces.IUpdateSearchTermAction;
        updateDisplayedSearchResults(
            products : Array<ProductId>
        ) : ActionInterfaces.IUpdateDisplayedSearchResultsAction;
        saveMappings(
            productMappings : Array<InterLocationProductMapping>
        ) : ThunkAction<Promise<void>, IUnmappedProductStore, {services : ActionInterfaces.IServices}>;
        addProductToLocation(
            productId : ProductId,
            product : Product,
            locationId : LocationModel.LocationIdentifier,
        ) : ThunkAction<Promise<{ productId : ProductId, product : Product}>, IUnmappedProductStore, {services : ActionInterfaces.IServices}>;
        fetchLocationProducts(
            locationId : LocationModel.LocationIdentifier
        ) : ThunkAction<Promise<StringValueMap<ProductId, Product>>, IUnmappedProductStore, {services : ActionInterfaces.IServices}>;
        addProductToLocationSuccess(
            productId : ProductId,
            product : Product
        ) : ActionInterfaces.IAddProductToLocationSuccessAction;
    }

    export interface IUnmatchedProductModalAsynchronousActionCreators extends IActionCreatorsMapObject {
        addProductToLocationRequest(
            product : Product,
            locationId : LocationModel.LocationIdentifier,
        ) : ActionInterfaces.IAddProductToLocationRequestAction;
        addProductToLocationSuccess(
            productId : ProductId,
            product : Product
        ) : ActionInterfaces.IAddProductToLocationSuccessAction;
        addProductToLocationFailure(
            error : Error
        ) : IFailureAction;
        fetchLocationProductsSuccess(
            productsById : StringValueMap<ProductId, Product>
        ) : ActionInterfaces.IFetchLocationProductsSuccessAction;
        fetchLocationProductsRequest(
            locationId : LocationModel.LocationIdentifier
        ) : ActionInterfaces.IFetchLocationProductsRequestAction;
        fetchLocationProductsFailure(
            error : Error
        ) : IFailureAction;
    }

    export interface IServices {
        djangoApiManager : DjangoApiManager;
        productService : IProductService;
        productDistributorService : IProductDistributorService;
        locationProductService : ILocationProductService;
        userSessionManager : UserSessionManagerImpl;
        productMappingService : IProductMappingService;
        categoryService : ICategoryService;
        productCostService : IProductCostService;
    }
}

export type UnmappedProductDispatch = BevSpotDispatch<IUnmappedProductStore, { services: ActionInterfaces.IServices }>

const setUnmappedProductIds = (
    productIds : Array<ProductId>,
) : ActionInterfaces.ISetProductIdsAction => ({
    payload: {
        productIds,
    },
    type: ActionTypes.SET_UNMAPPED_PRODUCT_IDS,
});

const setSelectedProductForm = (
    productId : ProductId | null,
    quantity : {
        value : string,
        isValid : boolean,
        errorMessage : string,
    },
    productQuantityUnit : ProductQuantityUnit,
) : ActionInterfaces.ISetSelectedProductFormAction => ({
    payload: {
        productId,
        quantity,
        productQuantityUnit,
    },
    type: ActionTypes.SET_SELECTED_PRODUCT_FORM,
});

const setExistingMappings = (
    productMappings : Array<InterLocationProductMapping>
) : ActionInterfaces.ISetExistingMappingsAction => ({
    payload: {
        productMappings,
    },
    type: ActionTypes.SET_EXISTING_MAPPINGS,
});

const setModalStepState = (itemNumber : number, productsById : StringValueMap<ProductId, Product>) : ActionInterfaces.ISetModalStepStateAction => ({
    payload: {
        itemNumber,
        productsById,
    },
    type: ActionTypes.SET_MODAL_STEP_STATE,
});

const addProductMapping = (
    productMapping : InterLocationProductMapping
) : ActionInterfaces.IAddProductMappingAction => ({
    payload: {
        productMapping,
    },
    type: ActionTypes.ADD_PRODUCT_MAPPING,
});

const setAddedMappings = (
    addedMappings : Array<InterLocationProductMapping>
) : ActionInterfaces.ISetAddedMappingsAction => ({
    payload: {
        addedMappings,
    },
    type: ActionTypes.SET_ADDED_MAPPINGS,
});

const setSearchResults = (
    products : Array<ProductId>
) : ActionInterfaces.ISetSearchResults => ({
    payload: {
        products,
    },
    type: ActionTypes.SET_SEARCH_RESULTS,
});

const updateSearchTerm = (
    searchTerm : string
) : ActionInterfaces.IUpdateSearchTermAction => ({
    payload: {
        searchTerm,
    },
    type: ActionTypes.UPDATE_SEARCH_TERM,
});

const updateDisplayedSearchResults = (
    products : Array<ProductId>
) : ActionInterfaces.IUpdateDisplayedSearchResultsAction => ({
    payload: {
        products,
    },
    type: ActionTypes.UPDATE_DISPLAYED_SEARCH_RESULTS,
});

const clearWasProductAddedToLocationByStep = () : IEmptyAction => ({
    payload: {},
    type: ActionTypes.CLEAR_WAS_PRODUCT_ADDED_TO_LOCATION_BY_STEP,
});

/**
 * Asynchronous Actions
 */

const saveMappings = (
    productMappings : Array<InterLocationProductMapping>,
) : ThunkAction<Promise<void>, IUnmappedProductStore, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<void> => {
        const userSessionId = extraArguments.services.userSessionManager.getSessionId();

        return extraArguments.services.productMappingService.setProductQuantityUnitMappings(userSessionId, productMappings)
        .then(() => {
            return Promise.resolve();
        })
        .catch((error : Error) => {
            if (error instanceof AccountsExceptions.UnknownActorException) {
                throw error;
            }

            return Promise.reject(error);
        });
    };
};

const resolvePartnerProductCategoryId = (
    sourceCategoryId : CategoryId | null,
    sourceLocationId : LocationId,
    destinationLocationId : LocationId,
) : ThunkAction<Promise<CategoryId | null>, IUnmappedProductStore, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<CategoryId | null> => {
        const sessionId = extraArguments.services.userSessionManager.getSessionId();
        const categoryIdSet = sourceCategoryId ? new StringValueSet([sourceCategoryId]) : new StringValueSet<CategoryId>();
        return Promise.all([
            extraArguments.services.categoryService.getCategoriesForRetailer(sessionId, destinationLocationId),
            extraArguments.services.categoryService.getCategoriesById(sessionId, sourceLocationId, categoryIdSet)
        ]).then((results) => {
            const destinationCategories = results[0];
            const sourceCategories = results[1];
            let categoryIdPromise: Promise<null | CategoryId> = Promise.resolve(null);
            if (sourceCategoryId) {
                let newProductCategoryId = null;
                let newCategory : Category | null = null;
                const sourceCategory = sourceCategories.getRequired(sourceCategoryId);
                destinationCategories.forEach((category, categoryId) => {
                    // Find matching category in destination retailer, prefer categories that aren't deleted
                    if (newCategory === null && !category.getIsDeleted() && category.getName().trim().toLowerCase() === sourceCategory.getName().trim().toLowerCase()) {
                        newProductCategoryId = categoryId;
                        newCategory = category;
                    }
                });

                if (!newProductCategoryId) {
                    categoryIdPromise = Promise.resolve(
                        extraArguments.services.categoryService.createCategory(
                            sessionId,
                            destinationLocationId,
                            new Category(
                                sourceCategory.getName(),
                                sourceCategory.getGlCode(),
                                false,
                                ''
                            )
                        ).then((categoryInformation) => {
                            return Promise.resolve(categoryInformation.category_id);
                        })
                    );
                } else {
                    categoryIdPromise = Promise.resolve(newProductCategoryId);
                }
            }
            return Promise.resolve(categoryIdPromise);
        });
    };
};

const addProductToLocationRequest = (
    product : Product,
    locationId : LocationModel.LocationIdentifier,
) : ActionInterfaces.IAddProductToLocationRequestAction => ({
    payload: {
        product,
        locationId,
    },
    type: ActionTypes.ADD_PRODUCT_TO_LOCATION_REQUEST,
});

const addProductToLocationSuccess = (
    productId : ProductId,
    product : Product
) : ActionInterfaces.IAddProductToLocationSuccessAction => ({
    payload: {
        productId,
        product,
    },
    type: ActionTypes.ADD_PRODUCT_TO_LOCATION_SUCCESS,
});

const addProductToLocationFailure = (
    error : Error
) : IFailureAction => ({
    payload: {
        error,
    },
    type: ActionTypes.ADD_PRODUCT_TO_LOCATION_FAILURE,
});

// TODO maybe just pass product id? and have the call return product as well.
const addProductToLocation = (
    productId : ProductId,
    product : Product,
    locationIdentifier : LocationModel.LocationIdentifier,
) : ThunkAction<Promise<{ productId : ProductId, product : Product}>, IUnmappedProductStore, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<{productId : ProductId, product : Product}> => {
        dispatch(addProductToLocationRequest(product, locationIdentifier));
        const sessionId = extraArguments.services.userSessionManager.getSessionId();
        const sourceLocationId = new LocationId(window.GLOBAL_RETAILER_ID);
        const destinationLocationId = new LocationId(locationIdentifier.value);
        const categoryIdSet = new StringValueSet<CategoryId>();
        const sourceCategoryId = product.getNewProductCategoryId();
        if (sourceCategoryId) {
            categoryIdSet.add(sourceCategoryId);
        }

        return Promise.resolve(dispatch(resolvePartnerProductCategoryId(
            sourceCategoryId,
            sourceLocationId,
            destinationLocationId,
        ))).then((categoryId) => {
            product = productUtils.getProductWithNewCategory(product, categoryId, product.getProductCategoryId());
            const metaFetch : [Promise<ProductId>, Promise<StringValueMap<ProductId, DistributorId | null>>, Promise<StringValueMap<ProductId, ProductCost>>] = [
                extraArguments.services.productService.createProduct(sessionId, destinationLocationId, product)
                .then((newProductId) => {
                    return extraArguments.services.locationProductService.setProductAsActive(sessionId, destinationLocationId, newProductId)
                    .then(() => {
                        return newProductId;
                    });
                }),
                extraArguments.services.productDistributorService.retrieveDistributorIdsByProductIdForProductIds(sessionId, [productId]),
                extraArguments.services.productCostService.getCurrentProductCostsByProductId(sessionId, new StringValueSet([productId]), sourceLocationId)
            ];

            return Promise.all(metaFetch)
            .then((data : [ProductId, StringValueMap<ProductId, DistributorId | null>, StringValueMap<ProductId, ProductCost>]) => {
                const addedProductId : ProductId = data[0];
                const distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null> = data[1];
                const productCostsByProductId = data[2];

                const distributorId = distributorIdsByProductId.get(productId);
                if ((typeof distributorId !== 'undefined') && distributorId !== null) {
                    extraArguments.services.productDistributorService.associateDistributorIdWithProductId(sessionId, addedProductId, distributorId)
                    .catch((error : Error) => {
                        if (error instanceof AccountsExceptions.UnknownActorException) {
                            throw error;
                        }

                        if (error instanceof ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
                            return Promise.resolve();
                        }
                        /**
                         * Ben Leichter (March 8, 2017)
                         * At this point, all of the actions necessary for adding a product
                         * to a location have been taken. If this promise fails, the newly
                         * created product just won't have a distributor associated with it.
                         * Showing the something went wrong dialog here would probably be more
                         * confusing than helpful for the user.
                         *
                         * Should start logging these errors at some point.
                         */
                    });
                }

                const productCost = productCostsByProductId.getRequired(productId);
                const clientTimestamp = momentObjectToThriftSerializer.getThriftTimestampFromMoment(moment());
                extraArguments.services.productCostService.setCurrentProductCost(sessionId, addedProductId, productCost, clientTimestamp).catch((error : Error) => {
                    throw error;
                });

                return extraArguments.services.productService.getProductsById(sessionId, destinationLocationId, new StringValueSet([addedProductId]))
                .then((productsByIdResult) => {
                    const newProduct = productsByIdResult.productsById.getRequired(addedProductId);

                    // this gets the state out of date with productsById... so call it later :(
                    // dispatch(addProductToLocationSuccess(addedProductId, newProduct));
                    return { product: newProduct, productId: addedProductId };
                });
            })
            .catch((error : Error) => {
                if (error instanceof AccountsExceptions.UnknownActorException) {
                    throw error;
                }

                dispatch(addProductToLocationFailure(error));
                return Promise.reject(error);
            });
        });
    };
};

const fetchLocationProductsRequest = (
    locationId : LocationModel.LocationIdentifier
) : ActionInterfaces.IFetchLocationProductsRequestAction => ({
    payload: {
        locationId,
    },
    type: ActionTypes.FETCH_LOCATION_PRODUCTS_REQUEST,
});

const fetchLocationProductsSuccess = (
    productsById : StringValueMap<ProductId, Product>
) : ActionInterfaces.IFetchLocationProductsSuccessAction => ({
    payload: {
        productsById,
    },
    type: ActionTypes.FETCH_LOCATION_PRODUCTS_SUCCESS,
});

const fetchLocationProductsFailure = (
    error : Error
) : IFailureAction => ({
    payload: {
        error,
    },
    type: ActionTypes.FETCH_LOCATION_PRODUCTS_FAILURE,
});

const fetchLocationProducts = (
    locationIdentifier : LocationModel.LocationIdentifier
) : ThunkAction<Promise<StringValueMap<ProductId, Product>>, IUnmappedProductStore, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<StringValueMap<ProductId, Product>> => {
        dispatch(fetchLocationProductsRequest(locationIdentifier));

        const locationId = new LocationId(locationIdentifier.value);
        const userSessionId = extraArguments.services.userSessionManager.getSessionId();

        return extraArguments.services.locationProductService.getProductIds(userSessionId, locationId)
        .then((resultProductIds) => {
            return extraArguments.services.productService.getProductsById(userSessionId, locationId, resultProductIds)
            .then((result) => {
                const productsById = result.productsById;
                transferReportProductDataUtils.sortProducts(Array.from(productsById.keys()), productsById);
                dispatch(fetchLocationProductsSuccess(productsById));
                return Promise.resolve(productsById);
            });
        })
        .catch((error : Error) => {
            dispatch(fetchLocationProductsFailure(error));
            return Promise.reject(error);
        });
    };
};

export const UnmatchedProductModalAsyncActions : ActionInterfaces.IUnmatchedProductModalAsynchronousActionCreators = {
    addProductToLocationRequest,
    addProductToLocationSuccess,
    addProductToLocationFailure,
    fetchLocationProductsRequest,
    fetchLocationProductsSuccess,
    fetchLocationProductsFailure,
};

export const UnmatchedProductModalActions : ActionInterfaces.IUnmatchedProductModalActionCreatorsMapObject = {
    setUnmappedProductIds,
    setSelectedProductForm,
    setExistingMappings,
    setModalStepState,
    addProductMapping,
    setAddedMappings,
    setSearchResults,
    updateSearchTerm,
    updateDisplayedSearchResults,
    saveMappings,
    addProductToLocation,
    fetchLocationProducts,
    clearWasProductAddedToLocationByStep,
    addProductToLocationSuccess
};
