import { RuntimeException } from "shared/lib/general/exceptions/RuntimeException";

export enum InvoiceStatus {
    UPLOAD_NEEDED = 'Upload Needed',
    UPLOADED = 'Uploaded',
    PROCESSING = 'Processing',
    ATTACH_TO_DELIVERY = 'Delivery Needed',
    NEEDS_REVIEW = 'Needs Review',
    APPROVED = 'Approved',
    PROCESSING_FAILED = 'Processing Failed',
    READY_FOR_APPROVAL = "Ready for Approval",
}

const invoiceStatusByValue = new Map<string, InvoiceStatus>();
Object.keys(InvoiceStatus).forEach((key) => {
    // @ts-expect-error This is a bad pattern and we should not be using it any more. See BaseUnit.ts for more extensive comment
    invoiceStatusByValue.set(InvoiceStatus[key as any], InvoiceStatus[key as any] as InvoiceStatus);
});

// This is bad modern practice! do not follow this pattern. TODO: refactor to not redeclare vars
// eslint-disable-next-line no-redeclare
export namespace InvoiceStatus {
    export function getByInvoiceStatusValue(invoiceStatusValue : string) : InvoiceStatus {
        const invoiceStatus = invoiceStatusByValue.get(invoiceStatusValue);

        if (typeof invoiceStatus === 'undefined') {
            throw new RuntimeException('unexpected value: ' + invoiceStatusValue);
        }

        return invoiceStatus;
    }

    export function getAllInvoiceStatuses() : Array<InvoiceStatus> {
        return Array.from(invoiceStatusByValue.values());
    }

    export function getAvailableInvoiceStatusesForRetailerFeatures() : Array<InvoiceStatus> {
        const retailerFeatures = window.GLOBAL_FEATURE_ACCESS;
        const availableInvoiceStatuses = new Set(invoiceStatusByValue.values());

        if (!retailerFeatures.invoice_processing) {
            availableInvoiceStatuses.delete(InvoiceStatus.PROCESSING);
        }

        if (!(retailerFeatures.invoice_processing || retailerFeatures.plate_iq_integration || retailerFeatures.fintech_integration)) {
            availableInvoiceStatuses.delete(InvoiceStatus.ATTACH_TO_DELIVERY);
            availableInvoiceStatuses.delete(InvoiceStatus.NEEDS_REVIEW);
            availableInvoiceStatuses.delete(InvoiceStatus.READY_FOR_APPROVAL);
        }

        return Array.from(availableInvoiceStatuses);
    }
}
