
import React from 'react';

import { PreventPageScrolling } from 'shared/components/PreventPageScrolling';

import './SlideInModal.scss';

export interface ISlideInModalProps {
    isOpen : boolean;
    onDismiss : () => void;
    children : React.ReactNode;
}

const ANIMATION_DURATION = .5;

export class SlideInModal extends React.Component<ISlideInModalProps, object> {

    private isOpen = false;
    private nextIsOpen : boolean | undefined;
    private isInTransition = false;

    public render() {
        const {
            isOpen,
            onDismiss,
        } = this.props;

        const isOpenOrInTransition = isOpen || this.isInTransition;

        const innerDivStyles : React.CSSProperties = {
            transform: (this.isOpen ? 'translateX(0%)' : 'translateX(100%)'),
            transitionDuration: ANIMATION_DURATION + 's'
        };

        const dimissUnderlayStyles : React.CSSProperties = {
            opacity: (this.isOpen ? 1.0 : 0.0),
            transitionDuration: ANIMATION_DURATION + 's',
        };

        return (
            <div className={ 'slide-in-modal' + (isOpenOrInTransition ? ' open' : '') }>
                { isOpenOrInTransition && [
                    <PreventPageScrolling
                        key="0"
                        preventWindowTouchMove={ false }
                        addModalOpenClass={ true }
                    />,
                    <div key="1" className="dismiss-underlay" onClick={ onDismiss } style={ dimissUnderlayStyles }/>,
                    <div key="2" className="inner-div" style={ innerDivStyles }>
                        { this.props.children }
                    </div>
                ] }
            </div>
        );
    }

    public UNSAFE_componentWillReceiveProps(newProps : ISlideInModalProps) {
        if (newProps.isOpen !== this.isOpen) {
            this.isInTransition = true;
            this.nextIsOpen = newProps.isOpen;
            setTimeout(() => {
                this.isInTransition = false;
                this.forceUpdate();
            }, ANIMATION_DURATION * 1000);
        }
    }

    public componentDidUpdate() {
        if (typeof this.nextIsOpen !== 'undefined') {
            this.isOpen = this.nextIsOpen;
            this.nextIsOpen = undefined;
            setTimeout(() => {
                this.forceUpdate();
            });
        }
    }
}
