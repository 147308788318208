import { IStringValue } from 'api/Core/IStringValue';

export class FintechLocationId implements IStringValue {
    constructor(
        private readonly accountId : string,
        private readonly storeNumber : string
    ) {
    }

    public getAccountId() : string {
        return this.accountId;
    }

    public getStoreNumber() : string {
        return this.storeNumber;
    }

    public equals(other : IStringValue) : boolean {
        if (other instanceof FintechLocationId) {
            return this.accountId === other.accountId && this.storeNumber === other.storeNumber;
        }
        return false;
    }

    public getValue() : string {
        return `FintechLocationId(${ this.accountId },${ this.storeNumber })`;
    }

    public toString() : string {
        return `{FintechLocationId: ${ this.accountId },${ this.storeNumber }}`;
    }
}
