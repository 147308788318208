import { StringValueMap } from 'api/Core/StringValueMap';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountUtils } from 'api/UserAccount/utils/UserAccountUtils';
import React from 'react';
import { connect } from 'react-redux';

import { StringValueSet } from 'api/Core/StringValueSet';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Packaging } from 'api/Product/model/Packaging';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { getDateTimeFromTimestamp, getTimezoneFromString } from 'shared/utils/dateTimeUtils';
import { URLStringUtils } from 'shared/utils/urlStringUtils';

import { IItemCardStore, ItemCardActions, ActionInterfaces } from './actions/ItemCardActions';
import { ItemCardHeader } from './components/ItemCardHeader';
import { ItemCardHistory } from './components/ItemCardHistory';
import { ProductForm } from './components/ProductForm';
import { ProductDistributorAssociationFormId } from './model/ProductDistributorAssociationFormId';
import { IItemCardState, ProductFormFieldName, PackagingWeightFormFieldName, ProductDistributorAssociationFormFieldName } from './reducers/ItemCardReducers';

import { PackagingFormValidationInputDataByFieldName } from 'shared/components/Product/PackagingForm';
import { DEFAULT_PACKAGING_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME } from 'shared/components/Product/PackagingFormUtils';
import { SlideInModal } from 'shared/components/SlideInModal/SlideInModal';
import { batchActions, IAction } from 'shared/models/IAction';
import { BevSpotDispatch } from 'shared/components/Provider';

import './css/ItemCard.scss';
import { ProductFormUtils, DEFAULT_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME } from './utils/ProductFormUtils';

export interface IItemCardProps {
    itemCardState : IItemCardState;
}

export interface IConnectedItemCardProps extends IItemCardProps {
    dispatch : BevSpotDispatch<IItemCardStore, ActionInterfaces.IItemCardExtraArguments>;
}

const ITEM_ID_URL_PARAM = 'item_card_id';

interface ICreateItemModalEventData {
    name?: string;
    brand?: string;
    categoryValue?: string;
    sku?: string; // TODO Remove when everything is multivendor
    distributorIdValue?: string | null; // TODO Remove when everything is multivendor
    priceAmount?: string; // TODO Remove when everything is multivendor
    priceUnit?: string; // TODO Remove when everything is multivendor
    packaging?: {
        caseContentQuantity: string;
        containerContentQuantity: string;
        containerContentUnit: string;
        containerType: string;
        comesAsCase: boolean;
    } | null;
    productDistributorAssociation?: {
        distributorId: string;
        sku: string;
        priceAmount: string;
        depositAmount: string;
        unit: string;
        priceUnit: string;
        depositUnit: string;
    } | null;
}

export const ItemCardEvents = {
    loadCreateItemModal: (loadCreateItemModalEventData : ICreateItemModalEventData) => {
        $(document).trigger('loadCreateItemModal', loadCreateItemModalEventData);
    }
};

export class ItemCard extends React.Component<IConnectedItemCardProps, object> {
    private packagingFormsValidationInputDataByFieldName : Array<PackagingFormValidationInputDataByFieldName | null> = [null];

    public UNSAFE_componentWillMount() {
        const {
            dispatch,
        } = this.props;

        dispatch(ItemCardActions.fetchItemCardData())
        .then(() => {
            if (window.GLOBAL_FEATURE_ACCESS.item_card) {
                $(document).on('loadProductHistory', (event, eventData) => {
                    dispatch(ItemCardActions.onSetSelectedItemCardView('productHistory'));
                    dispatch(ItemCardActions.onShowItemCardForProductId(new ProductId(eventData.detail.productId)));
                });

                $(document).on('reloadItemCard', () => {
                    dispatch(ItemCardActions.fetchItemCardData());
                });
            }

            $(document).on('loadEditItemModal', (event, eventData) => {
                this.packagingFormsValidationInputDataByFieldName = [];
                if (eventData) {
                    if (eventData.shouldCreateNewPackaging) {
                        this.packagingFormsValidationInputDataByFieldName = [null];
                    }
                }

                this.updateURLWithProductId(eventData.detail.productId);

                dispatch(ItemCardActions.onShowItemCardForProductId(new ProductId(eventData.detail.productId)))
                .then(() => {
                    dispatch(ItemCardActions.onSetSelectedItemCardView('editProduct'));
                    if (eventData && eventData.shouldCreateNewPackaging) {
                        const packagingUnitDropdown = $(document).find('div.packaging-form div.packaging-section-subsection:last-child div.dropdown-menu-component-button').last();
                        const container = $(document).find('.product-form-container');
                        if (packagingUnitDropdown.length && container.length) {
                            packagingUnitDropdown.click();
                            container.animate({
                                scrollTop: packagingUnitDropdown.offset().top - container.offset().top + container.scrollTop() - 100
                            }, 500);
                        }
                    }
                });
            });

            $(document).on('loadCreateItemModal', (event, eventData : ICreateItemModalEventData) => {
                this.packagingFormsValidationInputDataByFieldName = [null];

                dispatch(ItemCardActions.onShowItemCardForProductId(null))
                .then(() => {
                    const actions : Array<IAction> = [];

                    if (eventData) {
                        if (eventData.brand) {
                            actions.push(ItemCardActions.setFormFieldValidationData('brand', eventData.brand, true, ''));
                        }

                        if (eventData.name) {
                            actions.push(ItemCardActions.setFormFieldValidationData('name', eventData.name, true, ''));
                        }

                        if (eventData.sku) {
                            actions.push(ItemCardActions.setFormFieldValidationData('sku', eventData.sku, true, ''));
                        }

                        if (eventData.distributorIdValue) {
                            actions.push(ItemCardActions.setFormFieldValidationData('distributorId', eventData.distributorIdValue, true, ''));
                        }

                        if (eventData.categoryValue) {
                            actions.push(ItemCardActions.setFormFieldValidationData('categoryId', eventData.categoryValue, true, ''));
                        }

                        if (eventData.priceAmount) {
                            actions.push(ItemCardActions.setFormFieldValidationData('priceAmount', eventData.priceAmount, true, ''));
                        }

                        if (eventData.priceUnit) {
                            actions.push(ItemCardActions.setFormFieldValidationData('priceUnit', eventData.priceUnit, true, ''));
                        }

                        const formProductQuantityUnitValueByUnitValue = new StringValueMap<string, string>();
                        if (eventData.packaging) {
                            const packagingFormValidationInputDataByFieldName = JSON.parse(JSON.stringify(DEFAULT_PACKAGING_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME));

                            // TODO Feels like there should be some kind of UI form validation on a few of these fields

                            if (eventData.packaging.comesAsCase && eventData.packaging.caseContentQuantity) {
                                packagingFormValidationInputDataByFieldName.caseQuantity.value = eventData.packaging.caseContentQuantity;
                            }

                            packagingFormValidationInputDataByFieldName.contentUnit.value = eventData.packaging.containerContentUnit;
                            packagingFormValidationInputDataByFieldName.contentQuantity.value = eventData.packaging.containerContentQuantity;
                            packagingFormValidationInputDataByFieldName.unitName.value = eventData.packaging.containerType;
                            packagingFormValidationInputDataByFieldName.comesAsCase.value = eventData.packaging.comesAsCase;

                            this.packagingFormsValidationInputDataByFieldName = [packagingFormValidationInputDataByFieldName];

                            const sortedFormProductQuantityUnitValuesAndLabels = ProductFormUtils.getSortedFormProductQuantityUnitValuesAndLabels(packagingFormValidationInputDataByFieldName);
                            sortedFormProductQuantityUnitValuesAndLabels.forEach((s) => {
                                formProductQuantityUnitValueByUnitValue.set(s.shortLabel, s.value);
                            });
                        }

                        if (eventData.productDistributorAssociation && window.GLOBAL_FEATURE_ACCESS.multi_vendor) {
                            const productDistributorAssociationFormValidationInputDataByFieldName = JSON.parse(JSON.stringify(DEFAULT_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME));

                            productDistributorAssociationFormValidationInputDataByFieldName.distributorId.value = eventData.productDistributorAssociation.distributorId;
                            productDistributorAssociationFormValidationInputDataByFieldName.sku.value = eventData.productDistributorAssociation.sku;
                            productDistributorAssociationFormValidationInputDataByFieldName.priceAmount.value = eventData.productDistributorAssociation.priceAmount;
                            productDistributorAssociationFormValidationInputDataByFieldName.depositAmount.value = eventData.productDistributorAssociation.depositAmount;

                            if (formProductQuantityUnitValueByUnitValue.size > 0) {
                                productDistributorAssociationFormValidationInputDataByFieldName.unit.value = formProductQuantityUnitValueByUnitValue.get(eventData.productDistributorAssociation.unit) || '';
                                productDistributorAssociationFormValidationInputDataByFieldName.priceUnit.value = formProductQuantityUnitValueByUnitValue.get(eventData.productDistributorAssociation.priceUnit) || '';
                                productDistributorAssociationFormValidationInputDataByFieldName.depositUnit.value = formProductQuantityUnitValueByUnitValue.get(eventData.productDistributorAssociation.depositUnit) || '';
                            }

                            this.props.dispatch(ItemCardActions.onAddProductDistributorAssociationForm(0, productDistributorAssociationFormValidationInputDataByFieldName));
                        }
                    }

                    dispatch(batchActions(actions));
                    dispatch(ItemCardActions.onSetSelectedItemCardView('createProduct'));
                });
            });

            $(document).on('customVendorCreated', (event, createVendorResult, distributor) => {
                const distributorIdValue = createVendorResult.distributor_id;
                const distributorId = new DistributorId(distributorIdValue);
                dispatch(ItemCardActions.onUpdateDistributor(new StringValueSet([distributorId])))
                .then(() => {
                    dispatch(ItemCardActions.setFormFieldValidationData('distributorId', distributorIdValue, true, ''));
                });
            });

            const itemIdValue = URLStringUtils.getQueryParameterFromUrl(ITEM_ID_URL_PARAM);
            if (itemIdValue) {
                $(document).trigger('loadEditItemModal', {
                    detail: {
                        productId: itemIdValue,
                    }
                });
            }
        });
    }

    public render() {
        const {
            itemCardState
        } = this.props;

        const {
            itemCardData,
            productHistoryData,
            productFormData,
            productId,
            selectedItemCardView,
            productForm,
        } = itemCardState;

        let product : Product | undefined;
        let initialPackagings : Array<Packaging | PackagingFormValidationInputDataByFieldName | null> = this.packagingFormsValidationInputDataByFieldName;
        let lastEditedDateString;
        let lastEditedByString;
        if (itemCardData && productId) {
            product = itemCardData.productsById.get(productId);
            if (product) {
                const relevantProductPackagings : Array<Packaging | PackagingFormValidationInputDataByFieldName | null> = [];
                product.getPackagingsAndMappings().getPackagingData().forEach((packagingData) => {
                    if (!packagingData.deleted) {
                        relevantProductPackagings.push(packagingData.packaging);
                    }
                });

                this.packagingFormsValidationInputDataByFieldName.forEach((packaging) => {
                    relevantProductPackagings.push(packaging);
                });
                initialPackagings = relevantProductPackagings;

                const timestampDateTime = getDateTimeFromTimestamp(product.getLastUpdateEvent().getTimestamp(), getTimezoneFromString(window.GLOBAL_RETAILER_TIME_ZONE));

                lastEditedDateString = `Last Edited ${ timestampDateTime.getValue('ddd, MMM. DD YYYY, h:mm a') }`;
                lastEditedByString = `by ${ UserAccountUtils.getUserAccountNameString(product.getLastUpdateEvent().getUserAccountId(), new UserAccountId(window.GLOBAL_USER_ID), itemCardData.namesByUserAccountId) }`;
            }
        }

        return (
            <SlideInModal isOpen={ itemCardState.isShownByComponentName.itemCardModal } onDismiss={ this.doNothing }>
                <div className="item-card">

                    <nav className="item-card-nav">
                        <ul className="item-card-nav-list">
                            { (selectedItemCardView === 'createProduct') &&
                                <li className="item-card-nav-item">
                                    Create
                                </li>
                            }
                            { (selectedItemCardView !== 'createProduct') &&
                                 <li className={ 'item-card-nav-item ' + (selectedItemCardView === 'editProduct' ? 'active' : '') } onClick={ this.onEditProductViewOptionClick }>
                                    Details
                                </li>
                            }

                            { (window.GLOBAL_FEATURE_ACCESS.item_card) && (selectedItemCardView !== 'createProduct') &&
                                <li className={ 'item-card-nav-item ' + (selectedItemCardView === 'productHistory' ? 'active' : '') } onClick={ this.onProductHistoryViewOptionClick }>
                                    History
                                </li>
                            }
                        </ul>

                        { (selectedItemCardView === 'productHistory') &&
                            <button onClick={ this.onCloseClick } className="bevicon bevico-close close-button" />
                        }

                        { (selectedItemCardView !== 'productHistory') &&
                            <div className={'item-card-last-edited'}>
                                <span>{lastEditedDateString}</span>
                                <span>{lastEditedByString}</span>
                            </div>
                        }
                    </nav>

                    <div className="item-card-container">
                        { (selectedItemCardView === 'createProduct' || selectedItemCardView === 'editProduct') &&
                            <ProductForm
                                productId={ productId }
                                categoriesById={ itemCardData ? itemCardData.categoriesById : new StringValueMap() }
                                validationInputDataByFieldName={ productForm.validationInputDataByFieldName }
                                isValid={ productForm.isSubmitting }
                                errorMessage={ productForm.errorMessage }
                                isSubmitting={ productForm.isSubmitting }
                                productFormData={ productFormData }
                                initialPackagings={ initialPackagings }
                                onFormFieldChange={ this.onProductFormFieldChange }
                                onFormFieldBlur={ this.onProductFormFieldBlur }
                                setUnitsWithConversionForms={ this.setUnitsWithConversionForms }
                                onConversionFieldChange={ this.onConversionFieldChange }
                                onConversionFieldBlur={ this.onConversionFieldBlur }
                                onPackagingWeightFormFieldChange={ this.onPackagingWeightFormFieldChange }
                                onPackagingWeightFormFieldBlur={ this.onPackagingWeightFormFieldBlur }
                                onSetPackagingIdHasPackagingWeightForm={ this.onSetPackagingIdHasPackagingWeightForm }
                                onSetPackagingWeightFormPackagingIdValue={ this.onSetPackagingWeightFormPackagingIdValue }
                                onAddProductDistributorAssociationForm={ this.onAddProductDistributorAssociationForm }
                                onProductDistributorAssociationFormFieldChange={ this.onProductDistributorAssociationFormFieldChange }
                                onProductDistributorAssociationFormFieldBlur={ this.onProductDistributorAssociationFormFieldBlur }
                                onRemoveProductDistributorAssociationForms={ this.onRemoveProductDistributorAssociationForms }
                                onSaveProductForm={ this.onSaveProductForm }
                                onCreateCustomDistributorClick={ this.onCreateCustomDistributorClick }
                                onProductFormCancelClick={ this.onCloseClick }
                                productModificationConfirmationDialogIsShown={ itemCardState.isShownByComponentName.productModificationConfirmationDialog }
                                onProductModificationConfirmationDialogCancelClick={ this.onProductModificationConfirmationDialogCancelClick }
                                deleteProductConfirmationDialogIsShown={ itemCardState.isShownByComponentName.deleteProductConfirmationDialog }
                                setDeleteProductConfirmationDialogIsShown={ this.onSetDeleteProductConfirmationDialogIsShown }
                                productIsActive={ (itemCardData && productId) ? itemCardData.activeProductIds.has(productId) : true }
                                onSetProductActiveState={ this.onSetProductActiveState }
                                onConfirmDeleteProduct={ this.onDeleteProduct }
                                onSetFocusedField={ this.onSetFocusedField }
                                focusedField={ itemCardState.focusedField }
                            />
                        }

                        { (selectedItemCardView === 'productHistory') && productId && typeof product !== 'undefined' && (itemCardData != null) &&
                            <ItemCardHeader
                                product={ product }
                                namesByUserAccountId={ itemCardData.namesByUserAccountId }
                            />
                        }

                        { (selectedItemCardView === 'productHistory') && productId && typeof product !== 'undefined' && (itemCardData != null) && (productHistoryData != null) &&
                            <ItemCardHistory
                                product={ product }
                                productId={ productId }
                                itemCardData={ itemCardData }
                                productHistoryData={ productHistoryData }
                                productsById={ itemCardData.productsById }
                            />
                        }

                        { ((productId && typeof product === 'undefined') || ((selectedItemCardView === 'productHistory') && (productHistoryData === null))) &&
                            <div className="loading-overlay">
                                <span className="bevicon bevico-spinner2 make-it-spin loading-spinner"/>
                            </div>
                        }
                    </div>
                </div>
            </SlideInModal>
        );
    }

    private readonly onEditProductViewOptionClick = () => {
        this.props.dispatch(ItemCardActions.onSetSelectedItemCardView('editProduct'));
    }

    private readonly onProductHistoryViewOptionClick = () => {
        this.props.dispatch(ItemCardActions.onSetSelectedItemCardView('productHistory'));
    }

    private readonly onCloseClick = () => {
        this.updateURLWithProductId(null);
        this.props.dispatch(ItemCardActions.setComponentIsShown('itemCardModal', false));
    }

    private readonly onProductFormFieldChange = (fieldName : ProductFormFieldName, value : string) => {
        this.props.dispatch(ItemCardActions.onFormFieldChange(fieldName, value));
    }

    private readonly onProductFormFieldBlur = (fieldName : ProductFormFieldName, value : string) => {
        this.props.dispatch(ItemCardActions.onFormFieldBlur(fieldName, value));
    }

    private readonly onPackagingWeightFormFieldChange = (packagingIdValue : string, fieldName : PackagingWeightFormFieldName, value : string) => {
        this.props.dispatch(ItemCardActions.onPackagingWeightFormFieldChange(packagingIdValue, fieldName, value));
    }

    private readonly onPackagingWeightFormFieldBlur = (packagingIdValue : string, fieldName : PackagingWeightFormFieldName, value : string) => {
        this.props.dispatch(ItemCardActions.onPackagingWeightFormFieldBlur(packagingIdValue, fieldName, value));
    }

    private readonly onSetPackagingIdHasPackagingWeightForm = (packagingIdValue : string, hasPackagingWeightForm : boolean) => {
        this.props.dispatch(ItemCardActions.setPackagingIdHasPackagingWeightForm(packagingIdValue, hasPackagingWeightForm));
    }

    private readonly onSetPackagingWeightFormPackagingIdValue = (oldPackagingIdValue : string, newPackagingIdValue : string) => {
        this.props.dispatch(ItemCardActions.setPackagingWeightFormPackagingIdValue(oldPackagingIdValue, newPackagingIdValue));
    }

    private readonly setUnitsWithConversionForms = (units : Array<string>) => {
        this.props.dispatch(ItemCardActions.setUnitsWithConversionForms(units));
    }

    private readonly onConversionFieldChange = (unit : string, value : string) => {
        this.props.dispatch(ItemCardActions.onConversionFieldChange(unit, value));
    }

    private readonly onConversionFieldBlur = (unit : string, value : string) => {
        this.props.dispatch(ItemCardActions.onConversionFieldBlur(unit, value));
    }

    private readonly onAddProductDistributorAssociationForm = (packagingIndex : number) => {
        this.props.dispatch(ItemCardActions.onAddProductDistributorAssociationForm(packagingIndex, null));
    }

    private readonly onProductDistributorAssociationFormFieldChange = (formId : ProductDistributorAssociationFormId, fieldName : ProductDistributorAssociationFormFieldName, value : string, packagingIndex : number | null | undefined) => {
        this.props.dispatch(ItemCardActions.onProductDistributorAssociationFormFieldChange(formId, fieldName, value, packagingIndex));
    }

    private readonly onProductDistributorAssociationFormFieldBlur = (formId : ProductDistributorAssociationFormId, fieldName : ProductDistributorAssociationFormFieldName, value : string, packagingIndex : number | null | undefined) => {
        this.props.dispatch(ItemCardActions.onProductDistributorAssociationFormFieldBlur(formId, fieldName, value, packagingIndex));
    }

    private readonly onRemoveProductDistributorAssociationForms = (formIds : StringValueSet<ProductDistributorAssociationFormId>) => {
        this.props.dispatch(ItemCardActions.removeProductDistributorAssociationForms(formIds));
    }

    private readonly onSaveProductForm = (productId : ProductId | null, packagings : Array<Packaging | null>, shouldCheckIfPackagingHasBeenModified : boolean) => {
        const {
            dispatch,
        } = this.props;

        dispatch(ItemCardActions.onSaveProductForm(productId, packagings, shouldCheckIfPackagingHasBeenModified))
        .then((resultProductId) => {
            if (resultProductId) {
                if (productId) {
                    $(document).trigger('customItemSuccessfullyEdited', [{ productId: productId.getValue() }]);
                } else {
                    $(document).trigger('customItemSuccessfullyCreated', [resultProductId.getValue()]);
                }

                this.updateURLWithProductId(null);
                dispatch(ItemCardActions.setComponentIsShown('itemCardModal', false));
            }
        });
    }

    private readonly onProductModificationConfirmationDialogCancelClick = () => {
        this.props.dispatch(ItemCardActions.setComponentIsShown('productModificationConfirmationDialog', false));
    }

    private readonly onCreateCustomDistributorClick = (distributorName : string) => {
        $(document).trigger('openCreateVendorModal', { name: distributorName });
    }

    private readonly onSetDeleteProductConfirmationDialogIsShown = (isShown : boolean) => {
        this.props.dispatch(ItemCardActions.setComponentIsShown('deleteProductConfirmationDialog', isShown));
    }

    // for consideration: is there something we can do to ensure that pages with the edit item modal
    // implement functions to handle delete/archive/edit custom events (or hide the delete/archive buttons where appropriate)?
    private readonly onDeleteProduct = (productId : ProductId) => {
        this.props.dispatch(ItemCardActions.onDeleteProduct(productId))
        .then(() => {
            $(document).trigger('customItemDeleted', [{ productIds: [productId.getValue()] }]);
        });
    }

    private readonly onSetProductActiveState = (productId : ProductId, setAsActive : boolean) => {
        this.props.dispatch(ItemCardActions.onSetProductActiveState(productId, setAsActive))
        .then(() => {
            $(document).trigger('customItemActiveStateChanged', [{ productId: productId.getValue(), isActive: setAsActive }]);
        });
    }

    private readonly doNothing = () => {
        // Do Nothing
    }

    private readonly onSetFocusedField = (fieldName : ProductFormFieldName | PackagingWeightFormFieldName | null) => {
        this.props.dispatch(ItemCardActions.setFocusedField(fieldName));
    }

    private readonly updateURLWithProductId = (productId : string | null) => {
        const newURL = URLStringUtils.replaceOrRemoveURLParameter(window.location.href, ITEM_ID_URL_PARAM, productId);
        window.history.replaceState(null, '', newURL);
    }
}

const mapStateToProps = (state : IItemCardProps) : IItemCardProps => {
    return {
        itemCardState: state.itemCardState,
    };
};

export const ConnectedItemCard = connect<IItemCardProps, object, object, IConnectedItemCardProps>(mapStateToProps)(ItemCard);
