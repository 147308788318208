import { BevSpotDispatch } from "shared/components/Provider";
import { ThunkAction } from 'redux-thunk';

import { IInventoryPeriodSelectorState } from './InventoryPeriodSelectorState';

import { DjangoApiManager } from 'shared/api/DjangoApiManager';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { DateTime, Timezone } from 'shared/models/DateTime';
import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';
import { IFullInventory } from 'shared/models/Inventory';
import { MongoDataInterfaces } from 'shared/models/LegacyDataInterfaces';

export interface IInventoryPeriodSelectorStore {
    inventoryPeriodSelectorState : IInventoryPeriodSelectorState;
}

export const ActionTypes = {
    SET_START_INVENTORY_OPTIONS_SHOWN: 'INVENTORY_PERIOD_SELECTOR/SET_START_INVENTORY_OPTIONS_SHOWN',
    SET_START_INVENTORY_ID: 'INVENTORY_PERIOD_SELECTOR/SET_START_INVENTORY_ID',
    SET_END_INVENTORY_OPTIONS_SHOWN: 'INVENTORY_PERIOD_SELECTOR/SET_END_INVENTORY_OPTIONS_SHOWN',
    SET_END_INVENTORY_ID: 'INVENTORY_PERIOD_SELECTOR/SET_END_INVENTORY_ID',
    FETCH_INVENTORIES_REQUEST : 'INVENTORY_PERIOD_SELECTOR/FETCH_INVENTORIES_REQUEST',
    FETCH_INVENTORIES_SUCCESS : 'INVENTORY_PERIOD_SELECTOR/FETCH_INVENTORIES_SUCCESS',
    FETCH_INVENTORIES_FAILURE : 'INVENTORY_PERIOD_SELECTOR/FETCH_INVENTORIES_FAILURE',
};

export namespace ActionInterfaces {
    export interface IFetchInventoriesRequest extends IAction {
        payload : {
            retailerId : string
        };
    }
    export interface IFetchInventoriesSuccess extends IAction {
        payload : {
            inventories : Array<IFullInventory>
        };
    }
    export interface IFetchInventoriesFailure extends IAction {
    }

    export interface ISetStartInventoryOptionsShown extends IAction {
        payload : {
            showOptions : boolean
        };
    }
    export interface ISetStartInventoryId extends IAction {
        payload : {
            inventoryId : string
        };
    }

    export interface ISetEndInventoryOptionsShown extends IAction {
        payload : {
            showOptions : boolean
        };
    }
    export interface ISetEndInventoryId extends IAction {
        payload : {
            inventoryId : string
        };
    }

    export interface IInventoryPeriodSelectorActionCreators extends IActionCreatorsMapObject {
        setStartInventoryOptionsShown(shown : boolean) : ISetStartInventoryOptionsShown;
        setStartInventoryId(inventoryId : string) : ISetStartInventoryId;    // where to handle null?
        setEndInventoryOptionsShown(shown : boolean) : ISetEndInventoryOptionsShown;
        setEndInventoryId(inventoryId : string) : ISetEndInventoryId;
        fetchInventoriesForRetailer(
            retailerId : string,
            timezone : Timezone
        ) : ThunkAction<Promise<Array<IFullInventory>>, IInventoryPeriodSelectorStore, {services : ActionInterfaces.IServices}>;
    }

    export interface IServices {
        djangoApiManager : DjangoApiManager;
    }
}

export type TypedDispatch = BevSpotDispatch<IInventoryPeriodSelectorStore, {services : ActionInterfaces.IServices}>

// These action creators are exported only for test purposes.
export const fetchInventoriesRequest = (
    retailerId : string
) : ActionInterfaces.IFetchInventoriesRequest => ({
    payload: {
        retailerId
    },
    type: ActionTypes.FETCH_INVENTORIES_REQUEST
});

export const fetchInventoriesSuccess = (
    inventories : Array<IFullInventory>
) : ActionInterfaces.IFetchInventoriesSuccess => ({
    payload: {
        inventories
    },
    type: ActionTypes.FETCH_INVENTORIES_SUCCESS,
});

export const fetchInventoriesFailure = (
    error : Error
) : ActionInterfaces.IFetchInventoriesFailure => ({
    payload: {
        error
    },
    type: ActionTypes.FETCH_INVENTORIES_FAILURE,
});

export const setStartInventoryOptionsShown = (
    showOptions : boolean
) : ActionInterfaces.ISetStartInventoryOptionsShown => ({
    payload: {
        showOptions
    },
    type: ActionTypes.SET_START_INVENTORY_OPTIONS_SHOWN
});

export const setStartInventoryId = (
    inventoryId : string
) : ActionInterfaces.ISetStartInventoryId => ({
    payload : {
        inventoryId
    },
    type: ActionTypes.SET_START_INVENTORY_ID
});

export const setEndInventoryOptionsShown = (
    showOptions : boolean
) : ActionInterfaces.ISetEndInventoryOptionsShown => ({
    payload: {
        showOptions
    },
    type: ActionTypes.SET_END_INVENTORY_OPTIONS_SHOWN
});

export const setEndInventoryId = (
    inventoryId : string
) : ActionInterfaces.ISetEndInventoryId => ({
    payload : {
        inventoryId
    },
    type: ActionTypes.SET_END_INVENTORY_ID
});

const fetchInventoriesForRetailer = (
    retailerId : string,
    timezone : Timezone
) : ThunkAction<Promise<Array<IFullInventory>>, IInventoryPeriodSelectorStore, {services : ActionInterfaces.IServices}> => {
    return (dispatch, getState, extraArguments) : Promise<Array<IFullInventory>> => {
        dispatch(fetchInventoriesRequest(retailerId));
        return extraArguments.services.djangoApiManager.getInventoriesSortedByDate(retailerId, 0, 10000)
        .then((data : Array<MongoDataInterfaces.IInventory>) => {
            const serializedData : Array<IFullInventory> = data
            .filter((inventory : MongoDataInterfaces.IInventory) => !inventory.is_partial)
            .map((inventory : MongoDataInterfaces.IInventory) => {
                return {
                    id: inventory.id,
                    date: new DateTime({
                        format: 'YYYY-MM-DD HH:mm:ss',
                        timezone,
                        utcTime: inventory.date,
                    }),
                    name: inventory.name,
                };
            });
            dispatch(fetchInventoriesSuccess(serializedData));
            return Promise.resolve(serializedData);
        })
        .catch((error : Error) => {
            dispatch(fetchInventoriesFailure(error));
            // redirect to error page
            throw new RuntimeException(error.stack);
        });
    };
};

export const InventoryPeriodSelectorActions : ActionInterfaces.IInventoryPeriodSelectorActionCreators = {
    setStartInventoryOptionsShown,
    setStartInventoryId,
    setEndInventoryOptionsShown,
    setEndInventoryId,
    fetchInventoriesForRetailer,
};
