import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { IUserName } from 'api/UserAccount/model/IUserName';
import { UserEvent } from 'api/UserAccount/model/UserEvent';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import * as _ from 'lodash';

import { LocationJSONToObjectSerializer } from 'api/Location/serializer/LocationJSONToObjectSerializer';
import { IUserAccountInfoReader } from 'api/UserAccount/interfaces/IUserAccountInfoReader';
import { IUserAccountInfoWriter } from 'api/UserAccount/interfaces/IUserAccountInfoWriter';
import { IAccountRetailerData } from 'api/UserAccount/model/AccountInfo';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { IUserAccountRetailersByGroupJson, IUserAccountRetailersIdsByGroup } from 'api/UserAccount/model/UserAccountRetailersByGroup';
import { onNewCognitoSession } from 'shared/global/refreshJWT';

import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { PathUtils } from 'shared/utils/pathUtils';

export class UserAccountInfoServiceImpl implements IUserAccountInfoReader, IUserAccountInfoWriter {
    constructor(
        private readonly locationJSONToObjectSerializer : LocationJSONToObjectSerializer
    ) { }

    ////////////////////////
    // IUserAccountInfoReader
    ////////////////////////
    public getNamesForAccountIds(accountIds: StringValueSet<UserAccountId>): Promise<StringValueMap<UserAccountId, IUserName>> {
        const requestBody : Array<string> = [];
        accountIds.forEach((userId) => requestBody.push(userId.getValue()));
        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:batch_user'), requestBody)
        .then((response : { [userId : string] : {first_name: string, last_name: string} }) => {
            const result = new StringValueMap<UserAccountId, IUserName>();
            Object.keys(response).forEach((userIdValue) => {
                const user = response[userIdValue];
                result.set(new UserAccountId(userIdValue), {firstName: user.first_name, lastName: user.last_name});
            });
            return result;
        });
    }

    public getNameForAccountId(accountId : UserAccountId) {
        const userCookie = Cookies.get('userSessionIdentifier');
        if (userCookie) {
            const userObject : {given_name: string, family_name: string, 'cognito:username': string} = jwtDecode(userCookie);
            if (userObject['cognito:username'] === accountId.getValue()) {
                return Promise.resolve({
                    firstName: userObject.given_name,
                    lastName: userObject.family_name
                });
            }
        }

        const queryParameters = {
            user_id: accountId.getValue(),
            fields: 'first_name,last_name'
        };
        return AjaxUtils.ajaxGet(PathUtils.getAbsolutePathForRequest('/api/user/'), queryParameters)
            .then((response : any) => {
                return {
                    firstName: response.first_name,
                    lastName: response.last_name,
                };
            });
    }

    public getAccountInfo(accountId : UserAccountId) {
        return new Promise<any>((resolve, reject) => {
            Auth.currentAuthenticatedUser()
            .then((user) => {
                if (user) {
                    onNewCognitoSession(user.signInUserSession);
                    return resolve({
                        firstName: user.attributes.given_name,
                        lastName: user.attributes.family_name,
                        phoneNumber: user.attributes.phone_number || '',
                        emailAddress: user.attributes.email
                    });
                } else {
                    return reject('No user found');
                }
            }).catch(() => {
                return reject('No user found');
            });
        });
    }

    public getAccountRetailers(accountId : UserAccountId) : Promise<IAccountRetailerData> {
        return AjaxUtils.ajaxGet(PathUtils.getAbsolutePathForRequest('/api/accessible_retailers/'), {})
        .then((response : any) => {
            return Promise.resolve({
                retailerIds: _.map(response, (retailer : any) => retailer.id),
                retailerNameByRetailerId: _.fromPairs(
                    _.map(response, (retailer : any) => [ retailer.id, retailer.name ])
                ),
                retailerFeaturesByRetailerId: _.fromPairs(
                    _.map(response, (retailer : any) => [ retailer.id, retailer.features ])
                ),
                retailerAccountTypeByRetailerId: _.fromPairs(
                    _.map(response, (retailer : any) => [ retailer.id, this.locationJSONToObjectSerializer.getAccountType(retailer.account_type) ])
                ),
                isInFreeTrialByRetailerId: _.fromPairs(
                    _.map(response, (retailer : any) => [ retailer.id, retailer.is_is_free_trial ])
                ),
                isSandboxByRetailerId: _.fromPairs(
                    _.map(response, (retailer : any) => [ retailer.id, retailer.is_sandbox ])
                ),
            });
        });
    }

    public getAccountRetailersByGroup(accountId : UserAccountId) : Promise<IUserAccountRetailersIdsByGroup> {
        return AjaxUtils.ajaxGet(PathUtils.getAbsolutePathForRequest('/api/accessible_retailers_by_group/'), {})
        .then((userAccountRetailersByGroup : IUserAccountRetailersByGroupJson) => {
            const groups = userAccountRetailersByGroup.groups.map((group) => {
                return {
                    id: group.id,
                    name: group.name,
                    orderedRetailerIds: group.ordered_retailer_ids,
                };
            });
            return {
                groups,
                ungrouped: userAccountRetailersByGroup.ungrouped,
            };
        });
    }

    public getEventHasOccurred(sessionId : UserSessionId, event : UserEvent) : Promise<boolean> {
        const queryParameters = {
            event,
        };

        return AjaxUtils.ajaxGet(PathUtils.getAbsolutePathForRequest('/user/get_event_has_occurred'), queryParameters)
        .then((response : any) => {
            return Promise.resolve(response[event]);
        });
    }

    ////////////////////////
    // IUserAccountInfoWriter
    ////////////////////////

    public setAccountInfo(firstName : string, lastName : string, phoneNumber : string | null, accountId : UserAccountId) : Promise<any> {
        const postBody = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
        };

        const queryParameters = {
            user_id: accountId.getValue(),
        };

        return AjaxUtils.ajaxPut(PathUtils.getAbsolutePathForRequest('/api/user/'), postBody, queryParameters)
        .then((response : any) => {
            return Promise.resolve(response);
        });
    }

    public setEventHasOccurred(sessionId : UserSessionId, event : UserEvent) : Promise<void> {
        const queryParameters = {
            event,
        };

        return AjaxUtils.ajaxPut(PathUtils.getAbsolutePathForRequest('/user/set_event_has_occurred'), {}, queryParameters)
        .then((response : any) => {
            return Promise.resolve(response);
        });
    }
}
