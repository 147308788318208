import * as _ from 'lodash';
import { Action } from '@reduxjs/toolkit';

import { ActionInterfaces, ActionTypes } from '../actions/actions';
import { IErrorNotificationDialogState } from '../models/ErrorNotificationDialogState';
import { ErrorNotificationTypeEnum } from '../models/ErrorNotificationTypeEnum';

const errorNotificationDialogInitialState : IErrorNotificationDialogState = {
    errorNotificationType: ErrorNotificationTypeEnum.NONE,
};

const reduceSetErrorNotificationType = (
    state : IErrorNotificationDialogState,
    action : ActionInterfaces.ISetErrorNotificationType
) : IErrorNotificationDialogState => {
    const updates = {
        errorNotificationType : action.payload.errorNotificationType,
    };
    return _.merge({}, state, updates);
};

export const ErrorNotificationDialogReducers = (
    state : IErrorNotificationDialogState = errorNotificationDialogInitialState,
    action : Action
) : IErrorNotificationDialogState => {
    switch (action.type) {
        case ActionTypes.SET_ERROR_NOTIFICATION_TYPE:
            return reduceSetErrorNotificationType(
                state,
                action as ActionInterfaces.ISetErrorNotificationType
            );
        default:
            return state;
    }
};
