import { IStringValue } from 'api/Core/IStringValue';

export class UserAccountId implements IStringValue {
    constructor (private readonly value : string) { }

    public getValue() : string {
        return this.value;
    }

    public equals(other : UserAccountId) : boolean {
        return this.getValue() === other.getValue();
    }

    public toString() : string {
        return `{UserAccountId: ${this.value}}`;
    }
}
