import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { decimalToNumber } from 'shared/utils/decimalUtils';
import * as XLSX from 'xlsx';

import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { BreakageId } from 'api/Breakage/model/BreakageId';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { UsageData } from 'api/UsageData/model/UsageData';
import { UsageDataUtils } from 'api/UsageData/utils/usageDataUtils';

import { getCurrencySymbol } from 'shared/models/Currency';
import { CellFormat, exportUtils } from 'shared/utils/exportUtils';

export const getInventoryUnitCount = (product : Product, unitCountByStorageAreaIdForInventory : StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>> | undefined) => {
    const preferredReportingUnit = product.getPreferredReportingUnit();
    if (unitCountByStorageAreaIdForInventory) {
        let sum = 0;
        unitCountByStorageAreaIdForInventory.forEach((quantityInUnit, storageAreaId) => {
            if (!PackagingUtils.productQuantityUnitsAreEqual(preferredReportingUnit, quantityInUnit.getUnit())) {
                quantityInUnit = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), quantityInUnit, preferredReportingUnit);
            }
            sum += quantityInUnit.getQuantity();
        });
        return new QuantityInUnit<ProductQuantityUnit>(sum, preferredReportingUnit);
    }
};

export const getBreakagesTotalUnitCount = (product : Product, unitCountByBreakageId : StringValueMap<BreakageId, QuantityInUnit<ProductQuantityUnit>> | undefined) => {
    let breakageUnitCount = 0;
    const preferredReportingUnit = product.getPreferredReportingUnit();

    if (typeof unitCountByBreakageId !== 'undefined') {
        unitCountByBreakageId.forEach((count : QuantityInUnit<ProductQuantityUnit>, breakageId : BreakageId) => {
            if (!PackagingUtils.productQuantityUnitsAreEqual(preferredReportingUnit, count.getUnit())) {
                count = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), count, preferredReportingUnit);
            }
            breakageUnitCount += count.getQuantity();
        });
        return new QuantityInUnit<ProductQuantityUnit>(breakageUnitCount, preferredReportingUnit);
    }
};

export const exportReportToExcel = (
    retailerName : string,
    productsById : StringValueMap<ProductId, Product>,
    sortedGroupNamesToDisplay : Array<string>,
    unfilteredSortedProductIdsByGroupName : {[groupName : string] : Array<ProductId>},
    usageData : UsageData,
    unitCountsByProductIdByBreakageId : StringValueMap<ProductId, StringValueMap<BreakageId, QuantityInUnit<ProductQuantityUnit>>>,
    unitCostsByProductId : StringValueMap<ProductId, ProductCost>,
) => {
    const fileName = `${ retailerName.replace(/\s/g, '_') }_cost_of_goods.xlsx`;

    const currencySymbol = getCurrencySymbol();

    const headers = [
        { headerText: 'Brand' },
        { headerText: 'Name' },
        { headerText: 'Reporting Unit' },
        { headerText: 'Starting Count' },
        { headerText: 'Deliveries' },
        { headerText: 'Transfers In' },
        { headerText: 'Transfers Out' },
        { headerText: 'Prep Events Input' },
        { headerText: 'Prep Events Output' },
        { headerText: 'Ending Count' },
        { headerText: 'Unit Usage' },
        { headerText: `Unit Cost (${ currencySymbol })`, formatting: CellFormat.MONETARY_VALUE },
        { headerText: `COGS (${ currencySymbol })`, formatting: CellFormat.MONETARY_VALUE },
        { headerText: 'Reported Losses' },
        { headerText: `Reported Loss Cost (${ currencySymbol })`, formatting: CellFormat.MONETARY_VALUE },
    ];

    const usageCalculationParameters = usageData.getUsageCalculationParameters();

    const createRow = (productId : ProductId) => {
        const product = productsById.getRequired(productId);
        const preferredReportingUnit = product.getPreferredReportingUnit();
        const packagingsAndMappings = product.getPackagingsAndMappings();
        const unitCountByStorageAreaIdForStartingInventory = usageCalculationParameters.getUnitCountsByProductIdByStorageAreaIdForStartingInventory().get(productId);
        const unitCountByStorageAreaIdForEndingInventory = usageCalculationParameters.getUnitCountsByProductIdByStorageAreaIdForEndingInventory().get(productId);
        const unitCountByDeliveryId = usageCalculationParameters.getUnitCountsByProductIdByDeliveryId().get(productId);
        const unitCountByTransferId = usageCalculationParameters.getUnitCountsByProductIdByTransferId().get(productId);
        const inputUnitCountByPrepEventId = usageCalculationParameters.getInputUnitCountsByProductIdByPrepEventId().get(productId);
        const outputUnitCountByPrepEventId = usageCalculationParameters.getOutputUnitCountsByProductIdByPrepEventId().get(productId);
        const unitCountByBreakageId = unitCountsByProductIdByBreakageId.get(productId);
        const usage = usageData.getUsageByProductId().get(productId);

        const startingInventoryUnitCount : QuantityInUnit<ProductQuantityUnit> | undefined = getInventoryUnitCount(product, unitCountByStorageAreaIdForStartingInventory);
        const endingInventoryUnitCount : QuantityInUnit<ProductQuantityUnit> | undefined = getInventoryUnitCount(product, unitCountByStorageAreaIdForEndingInventory);

        const deliveriesAndTransfersAndPrepsCount = UsageDataUtils.getDeliveriesAndTransfersAndPrepsCounts(
            product,
            unitCountByDeliveryId,
            unitCountByTransferId || undefined,
            inputUnitCountByPrepEventId,
            outputUnitCountByPrepEventId,
        );

        let unitCost = usageCalculationParameters.getUnitCostsByProductId().get(productId);
        if (unitCost === undefined) {
            unitCost = unitCostsByProductId.getRequired(productId);
        }
        const unitCostInPreferredReportingUnit = productCostUtils.getProductCostInUnit(packagingsAndMappings, unitCost, preferredReportingUnit);

        const breakagesTotalUnitCount = getBreakagesTotalUnitCount(product, unitCountByBreakageId);
        const breakageUnitCount = breakagesTotalUnitCount ? PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, breakagesTotalUnitCount, preferredReportingUnit).getQuantity() : undefined;
        const unitUsage = usage ? PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, usage.unitUsage, preferredReportingUnit) : undefined;

        const deliveriesUnitCountInPreferredReportingUnit = deliveriesAndTransfersAndPrepsCount.deliveriesUnitCount ?
            PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, deliveriesAndTransfersAndPrepsCount.deliveriesUnitCount, preferredReportingUnit).getQuantity() :
            undefined;
        const transfersInUnitCountInPreferredReportingUnit = deliveriesAndTransfersAndPrepsCount.transfersInUnitCount ?
            PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, deliveriesAndTransfersAndPrepsCount.transfersInUnitCount, preferredReportingUnit).getQuantity() :
            undefined;
        const transfersOutUnitCountInPreferredReportingUnit = deliveriesAndTransfersAndPrepsCount.transfersOutUnitCount ?
            PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, deliveriesAndTransfersAndPrepsCount.transfersOutUnitCount, preferredReportingUnit).getQuantity() :
            undefined;
        const prepEventsInputUnitCountInPreferredReportingUnit = deliveriesAndTransfersAndPrepsCount.prepEventsInputUnitCount ?
            PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, deliveriesAndTransfersAndPrepsCount.prepEventsInputUnitCount, preferredReportingUnit).getQuantity() :
            undefined;
        const prepEventsOutputUnitCountInPreferredReportingUnit = deliveriesAndTransfersAndPrepsCount.prepEventsOutputUnitCount ?
            PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, deliveriesAndTransfersAndPrepsCount.prepEventsOutputUnitCount, preferredReportingUnit).getQuantity() :
            undefined;
        const unitUsageCount = unitUsage ? unitUsage.getQuantity() : undefined;
        const dollarUsage = usage ? usage.dollarUsage : undefined;

        return [
            product.getBrand(),
            product.getName(),
            PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), product.getPreferredReportingUnit(), false),
            exportUtils.getNumberOrUndefinedDisplay(startingInventoryUnitCount ? startingInventoryUnitCount.getQuantity() : undefined),
            exportUtils.getNumberOrUndefinedDisplay(deliveriesUnitCountInPreferredReportingUnit),
            exportUtils.getNumberOrUndefinedDisplay(transfersInUnitCountInPreferredReportingUnit),
            exportUtils.getNumberOrUndefinedDisplay(transfersOutUnitCountInPreferredReportingUnit),
            exportUtils.getNumberOrUndefinedDisplay(prepEventsInputUnitCountInPreferredReportingUnit),
            exportUtils.getNumberOrUndefinedDisplay(prepEventsOutputUnitCountInPreferredReportingUnit),
            exportUtils.getNumberOrUndefinedDisplay(endingInventoryUnitCount ? endingInventoryUnitCount.getQuantity() : undefined),
            exportUtils.getNumberOrUndefinedDisplay(unitUsageCount),
            exportUtils.getNumberOrUndefinedDisplay(dollarUsage),
            exportUtils.getNumberOrUndefinedDisplay(breakageUnitCount),
            exportUtils.getNumberOrUndefinedDisplay((typeof breakageUnitCount === 'undefined' ? 0 : breakageUnitCount) * decimalToNumber(unitCostInPreferredReportingUnit.getCost())),
        ];
    };

    const worksheet = exportUtils.constructSheetFromSortedFilteredGroupedResult(
        headers,
        sortedGroupNamesToDisplay,
        unfilteredSortedProductIdsByGroupName,
        createRow,
        () => true,
    );

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, worksheet, 'Cost of Goods Report');

    exportUtils.writeWorkBookToFile(workBook, fileName, 'xlsx');
};
