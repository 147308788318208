import { LocationId } from 'api/Location/model/LocationId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { IPOSIntegrationService } from 'api/Integration/interfaces/IPOSIntegrationService';
import { oauthUtils } from 'shared/components/PosIntegration/OAuthUtils';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { integrationService } from 'shared/lib/manager';
import { IAction, ISetShownAction } from 'shared/models/IAction';
import { ThunkAction } from 'redux-thunk';
import { IExtraArguments } from 'shared/components/Provider';

export interface IRSeriesIntegrationModalStore {
    rSeriesIntegrationRowState: IRSeriesIntegrationRowState;
}

export interface IRSeriesIntegrationRowState {
    isLoading: boolean;
    modalIsShown: boolean;
    existingIntegration: Set<IntegrationState> | null;
    selectedMerchantId: string | null;
    modal: IRSeriesIntegrationModalState;
}

export interface IRSeriesIntegrationModalState {
    isLoading: boolean;
    error: string | null;
    accessToken: string | null;
}

export const ActionTypes = {
    SET_MODAL_SHOWN: 'R_SERIES_INTEGRATION/SET_MODAL_SHOWN',
    SET_EXISTING_INTEGRATION_STATE: 'R_SERIES_INTEGRATION/SET_EXISTING_INTEGRATION_STATE',
    SET_IS_LOADING: 'R_SERIES_INTEGRATION/SET_IS_LOADING',
    SET_MODAL_ERROR: 'R_SERIES_INTEGRATION/SET_MODAL_ERROR',
    SET_MODAL_ACCESS_TOKEN: 'R_SERIES_INTEGRATION/SET_MODAL_ACCESS_TOKEN',
    SET_SELECTED_MERCHANT: 'R_SERIES_INTEGRATION/SET_SELECTED_MERCHANT',
};

export namespace RSeriesIntegrationActionInterfaces {
    export interface ISetModalShown extends ISetShownAction {
    }

    export interface ISetModalError extends IAction {
        payload: {
            message: string | null;
        };
    }

    export interface ISetIsLoading extends IAction {
        payload: {
            isLoading: boolean
        };
    }

    export interface ISetModalAccessToken extends IAction {
        payload: {
            accessToken: string | null
        };
    }

    export interface ISetExistingIntegrationState extends IAction {
        payload: {
            integrationState: Set<IntegrationState>
        };
    }

    export interface ISetSelectedMerchant extends IAction {
        payload: {
            merchantId: string | null
        };
    }

    export interface IServices {
        userSessionReader: IAccountSessionReader<UserSessionId, UserAccountId>;
        integrationService: IPOSIntegrationService;
    }

    export interface IThunkServices extends IExtraArguments {
        services: IServices;
    }
}

const setModalShown = (
    isShown: boolean
): RSeriesIntegrationActionInterfaces.ISetModalShown => ({
    payload: {
        isShown,
    },
    type: ActionTypes.SET_MODAL_SHOWN
});

const setModalAccessToken = (
    accessToken: string | null
): RSeriesIntegrationActionInterfaces.ISetModalAccessToken => ({
    payload: {
        accessToken,
    },
    type: ActionTypes.SET_MODAL_ACCESS_TOKEN
});

const setIsLoading = (
    isLoading: boolean
): RSeriesIntegrationActionInterfaces.ISetIsLoading => ({
    payload: {
        isLoading
    },
    type: ActionTypes.SET_IS_LOADING,
});

const setExistingIntegrationState = (
    integrationState: Set<IntegrationState>
): RSeriesIntegrationActionInterfaces.ISetExistingIntegrationState => ({
    payload: {
        integrationState,
    },
    type: ActionTypes.SET_EXISTING_INTEGRATION_STATE,
});

const setSelectedMerchant = (
    merchantId: string | null
): RSeriesIntegrationActionInterfaces.ISetSelectedMerchant => ({
    payload: {
        merchantId,
    },
    type: ActionTypes.SET_SELECTED_MERCHANT
});

const setModalError = (
    message: string | null
): RSeriesIntegrationActionInterfaces.ISetModalError => ({
    payload: {
        message,
    },
    type: ActionTypes.SET_MODAL_ERROR
});

const fetchExistingIntegration = (
    locationId: LocationId
): ThunkAction<Promise<Set<IntegrationState>>, IRSeriesIntegrationModalStore, RSeriesIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments): Promise<Set<IntegrationState>> => {
        dispatch(setIsLoading(true));
        const session = extraArguments.services.userSessionReader.getSessionId();
        return extraArguments.services.integrationService.getIntegrationState('rSeries', session, locationId)
        .then((data: Set<IntegrationState>) => {
            dispatch(setExistingIntegrationState(data));
            dispatch(setIsLoading(false));
            return data;
        })
        .catch((e: Error) => {
            dispatch(setIsLoading(false));
            throw e;
        });
    };
};

const processAuthorization = (
    locationId: LocationId,
    authorizationCode: string
): ThunkAction<Promise<void>, IRSeriesIntegrationModalStore, RSeriesIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {
        dispatch(setIsLoading(true));
        const session = extraArguments.services.userSessionReader.getSessionId();

        return extraArguments.services.integrationService.addIntegrationThroughOAuthFlow(
            'rSeries',
            session,
            locationId,
            authorizationCode,
            oauthUtils.getPKCEVerifier() || undefined
        )
        .then(() => {
            dispatch(fetchExistingIntegration(locationId));
        })
        .catch((error) => {
            dispatch(setIsLoading(false));
            throw error;
        });
    };
};

const disconnect = (
    locationId: LocationId,
    merchantId: string,
): ThunkAction<Promise<void>, IRSeriesIntegrationModalStore, RSeriesIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments) => {
        const session = extraArguments.services.userSessionReader.getSessionId();
        return integrationService.removeIntegrationForMultiConnectionType(
            'square',
            session,
            locationId,
            merchantId,
        ).then(() => {
            dispatch(fetchExistingIntegration(locationId));
        }).catch((error) => {
            throw error;
        });
    };
};

const retrieveAccessToken = (
    locationId: LocationId,
    merchantId: string,
): ThunkAction<Promise<void>, IRSeriesIntegrationModalStore, RSeriesIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch, getState, extraArguments): Promise<void> => {
        const session = extraArguments.services.userSessionReader.getSessionId();
        return extraArguments.services.integrationService.retrieveAccessTokenForMultiConnectionType(
            session,
            locationId,
            merchantId,
            'rSeries'
        ).then((accessToken: string | null) => {
            if (accessToken == null || accessToken === "") {
                accessToken = "Access token not found.";
            }
            dispatch(setModalAccessToken(accessToken));
        });
    };
};

export const RSeriesIntegrationRowActions = {
    setModalShown,
    fetchExistingIntegration,
    processAuthorization,
    disconnect,
    setModalError,
    setModalAccessToken,
    retrieveAccessToken,
    setSelectedMerchant,
};
