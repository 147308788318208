import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { Action } from '@reduxjs/toolkit';
import {
    ActionTypes,
    CloverIntegrationActionInterfaces,
} from 'shared/components/PosIntegration/CloverIntegrationRowActions';

export interface ICloverIntegrationModalState {
    isLoading : boolean;
    error : string | null;
    accessToken : string | null;
}

export interface ICloverIntegrationRowState {
    isLoading : boolean;
    modalIsShown : boolean;
    existingIntegration : IntegrationState | null;
    modal : ICloverIntegrationModalState;
}

const initialState : ICloverIntegrationRowState = {
    isLoading : false,
    existingIntegration : null,
    modalIsShown : false,
    modal : {
        isLoading : false,
        error: null,
        accessToken: null,
    }
};

const reduceSetModalIsLoading = (
    state : ICloverIntegrationRowState,
    action : CloverIntegrationActionInterfaces.ISetIsLoading
) : ICloverIntegrationRowState => {
    return {
        ...state,
        modal : {
            ...state.modal,
            isLoading : action.payload.isLoading,
        }
    };
};

const reduceSetModalShown = (
    state : ICloverIntegrationRowState,
    action : CloverIntegrationActionInterfaces.ISetModalShown
) : ICloverIntegrationRowState => {
    return {
        ...state,
        modalIsShown : action.payload.isShown,
    };
};

const reduceSetIsLoading = (
    state : ICloverIntegrationRowState,
    action : CloverIntegrationActionInterfaces.ISetIsLoading
) : ICloverIntegrationRowState => {
    return {
        ...state,
        isLoading : action.payload.isLoading,
    };
};

const reduceSetModalError = (
    state : ICloverIntegrationRowState,
    action : CloverIntegrationActionInterfaces.ISetModalError
) : ICloverIntegrationRowState => {
    return {
        ...state,
        modal: {
            ...state.modal,
            error: action.payload.message
        }
    };
};

const reduceSetModalAccessToken = (
    state : ICloverIntegrationRowState,
    action : CloverIntegrationActionInterfaces.ISetModalAccessToken
) : ICloverIntegrationRowState => {
    return {
        ...state,
        modal: {
            ...state.modal,
            accessToken: action.payload.accessToken
        }
    };
};

const reduceSetExistingIntegrationState = (
    state : ICloverIntegrationRowState,
    action : CloverIntegrationActionInterfaces.ISetExistingIntegrationState
) : ICloverIntegrationRowState => {
    return {
        ...state,
        existingIntegration: action.payload.integrationState,
    };
};

export const CloverIntegrationRowReducers = (
    state : ICloverIntegrationRowState = initialState,
    action : Action,
) : ICloverIntegrationRowState => {
    switch (action.type) {
        case ActionTypes.SET_IS_LOADING:
            return reduceSetIsLoading(state, action as CloverIntegrationActionInterfaces.ISetIsLoading);
        case ActionTypes.SET_EXISTING_INTEGRATION_STATE:
            return reduceSetExistingIntegrationState(state, action as CloverIntegrationActionInterfaces.ISetExistingIntegrationState);
        case ActionTypes.SET_MODAL_SHOWN:
            return reduceSetModalShown(state, action as CloverIntegrationActionInterfaces.ISetModalShown);
        case ActionTypes.SET_MODAL_IS_LOADING:
            return reduceSetModalIsLoading(state, action as CloverIntegrationActionInterfaces.ISetIsLoading);
        case ActionTypes.SET_MODAL_ERROR:
            return reduceSetModalError(state, action as CloverIntegrationActionInterfaces.ISetModalError);
        case ActionTypes.SET_MODAL_ACCESS_TOKEN:
            return reduceSetModalAccessToken(state, action as CloverIntegrationActionInterfaces.ISetModalAccessToken);
        default:
            return state;
    }
};
