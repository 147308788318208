import { StringValueMap } from 'api/Core/StringValueMap';
import { Address } from 'api/Distributor/model/Address';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorAdjustment } from 'api/Distributor/model/DistributorAdjustment';
import { DistributorAdjustmentId } from 'api/Distributor/model/DistributorAdjustmentId';
import { DistributorDependentObjectSet } from 'api/Distributor/model/DistributorDependentObjectSet';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DistributorRep } from 'api/Distributor/model/DistributorRep';
import { ExtraDistributorProperties } from 'api/Distributor/model/ExtraDistributorProperties';
import { DefaultPaymentTermOptions, PaymentTerm } from 'api/Distributor/model/PaymentTerm';
import { LocationId } from 'api/Location/model/LocationId';
import { Scope } from 'api/Location/model/Scope';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { Validation } from 'shared/validators/validators';
import { FieldSpec as FieldSpec } from './models/FormFieldSpec';
import { VendorInfo } from './models/VendorInfo';

export type IFormData = { [fieldName in FieldName]: any };
export type IRepFormData = { [fieldName in RepFieldName]: any };
export type IAdjustmentFormData = { [fieldName in AdjustmentFieldName]: any };

export interface IState {
    // Entire App
    vendorInfo : VendorInfo | null;
    // Sidebar
    selectedVendorId : DistributorId | null;
    // Details
    formData : IFormData;
    deliveryDays : Array<boolean> | null;
    reps : Map<string, IRepFormData>;
    newReps : Array<IRepFormData>;
    adjustments : Map<string, IAdjustmentFormData>;
    newAdjustments : Array<IAdjustmentFormData>;
    nameModify : boolean;
    sortedVendors : Array<[string, DistributorId]>;
    categories : StringValueMap<CategoryId, Category>;
    paymentTerm : PaymentTerm;
    vendorDependenciesObject : DistributorDependentObjectSet | null;
}

export type FieldType = 'text' | 'boolean';

export type FieldName =
    'name' |
    'accountNumber' |
    'officePhone' |
    'website' |
    'accountingId' |
    'addressLine1' |
    'addressLine2' |
    'city' |
    'state' |
    'zipcode' |
    'caseMinimum' |
    'dollarMinimum' |
    'notes';

export type RepFieldName =
    'name' |
    'email' |
    'emailNotifications' |
    'attachCsv' |
    'cell' |
    'smsNotifications';

export type AdjustmentFieldName =
    'name' |
    'category_id';

const caseMinimumValidation = Validation.or(Validation.empty,
    Validation.and(Validation.short, Validation.validateNonNegativeNumber));

const dollarMinimumValidation = Validation.or(Validation.empty,
    Validation.and(Validation.short, Validation.validateUSD));

const nameValidation = Validation.or(Validation.empty, Validation.short);

const emailValidation = Validation.validateEmailAddress;

const cellValidation = Validation.or(Validation.empty, Validation.validateUSPhoneNumber);

export type FormFieldSpec = FieldSpec<FieldName, FieldType, string>;
export const FormFieldSpecs : Record<FieldName, FormFieldSpec> = {
    name: new FieldSpec('name', 'text', 'New Vendor Name', '', true, nameValidation, null),
    accountNumber: new FieldSpec('accountNumber', 'text', 'Account Number', '', false, Validation.short, null),
    officePhone: new FieldSpec('officePhone', 'text', 'Office Phone', '', false, Validation.short, null),
    website: new FieldSpec('website', 'text', 'Website', '', false, Validation.long, null),
    accountingId: new FieldSpec('accountingId', 'text', 'Accounting Id', '', false, Validation.short, null),
    addressLine1: new FieldSpec('addressLine1', 'text', 'Address Line 1', '', true, Validation.short, null),
    addressLine2: new FieldSpec('addressLine2', 'text', 'Address Line 2', '', true, Validation.short, null),
    city: new FieldSpec('city', 'text', 'City', '', true, Validation.short, null),
    state: new FieldSpec('state', 'text', 'State/Province/Region', '', true, Validation.short, null),
    zipcode: new FieldSpec('zipcode', 'text', 'Zip/Postal Code', '', true, Validation.short, null),
    caseMinimum: new FieldSpec('caseMinimum', 'text', 'Case Minimum', '', false, caseMinimumValidation, null),
    dollarMinimum: new FieldSpec('dollarMinimum', 'text', 'Dollar Minimum', '', false, dollarMinimumValidation, null),
    notes: new FieldSpec('notes', 'text', 'Notes', '', false, Validation.long, null),
};

export type RepFormFieldSpec = FieldSpec<RepFieldName, FieldType, any>;
export const RepFormFieldSpecs : Record<RepFieldName, RepFormFieldSpec> = {
    name: new FieldSpec('name', 'text', 'Name', '', false, Validation.short, null),
    email: new FieldSpec('email', 'text', 'Email', 'Invalid email', false, null, emailValidation),
    emailNotifications: new FieldSpec('emailNotifications', 'boolean', 'Send orders via email', '', false, null, null),
    attachCsv: new FieldSpec('attachCsv', 'boolean', 'Attach order CSV to email', '', false, null, null),
    cell: new FieldSpec('cell', 'text', 'Cell', 'Invalid phone number', false, null, cellValidation),
    smsNotifications: new FieldSpec('smsNotifications', 'boolean', 'Send orders via text', '', false, null, null),
};

export type AdjustmentFormFieldSpec = FieldSpec<AdjustmentFieldName, FieldType, any>;
export const AdjustmentFormFieldSpecs : Record<AdjustmentFieldName, AdjustmentFormFieldSpec> = {
    name: new FieldSpec('name', 'text', 'Name', '', false, Validation.short, null),
    category_id: new FieldSpec('category_id', 'text', 'Category ID', '', false, Validation.short, null),
};

// Auto-fill `true` for days Monday through Friday
export const createDefaultDeliveryDays = () => (new Array<boolean>(8).fill(false).fill(true, 1, 6));

export const createBlankFormFields = () => ({
    name: '',
    accountNumber: '',
    officePhone: '',
    website: '',
    accountingId: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipcode: '',
    caseMinimum: '',
    dollarMinimum: '',
    notes: '',
});

export const createFormFieldsFromVendorInfo = (
    vendorInfo : VendorInfo | null,
    vendorId : DistributorId | null
) : IFormData => {

    if (vendorInfo === null) {
        return createBlankFormFields();
    }

    const vendor : Distributor | null = vendorInfo.getVendorById(vendorId);
    if (vendor === null) {
        return createBlankFormFields();
    }

    const extra : ExtraDistributorProperties = vendor.getExtraDistributorProperties();
    return {
        name: vendor.getName(),
        accountNumber: extra.accountNumber,
        officePhone: extra.officePhone,
        website: extra.website,
        accountingId: extra.accountingId,
        addressLine1: extra.address.line1,
        addressLine2: extra.address.line2,
        city: extra.address.city,
        state: extra.address.state,
        zipcode: extra.address.zipcode,
        caseMinimum: extra.caseMinimum.toString(),
        dollarMinimum: extra.dollarMinimum.toString(),
        notes: extra.notes,
    };
};

export const createDeliveryDaysFromVendorInfo = (
    vendorInfo : VendorInfo | null,
    vendorId : DistributorId | null,
) : Array<boolean> => {

    if (vendorInfo === null) {
        return createDefaultDeliveryDays();
    }

    const vendor : Distributor | null = vendorInfo.getVendorById(vendorId);
    if (vendor === null) {
        return createDefaultDeliveryDays();
    }

    return vendor.getExtraDistributorProperties().deliveryDays;
};

export const createRepFormDataFromVendorInfo = (
    vendorInfo : VendorInfo | null,
    vendorId : DistributorId | null,
) : Map<string, IRepFormData> => {

    if (vendorInfo === null) {
        return new Map();
    }

    const vendor : Distributor | null = vendorInfo.getVendorById(vendorId);
    if (vendor === null) {
        return new Map();
    }

    const extraDistributorProperties = vendor.getExtraDistributorProperties();
    if (extraDistributorProperties === null) {
        return new Map();
    }

    const result = new Map<string, IRepFormData>();
    extraDistributorProperties.reps.forEach((rep, key) =>
        result.set(key, {
            name: rep.name,
            email: rep.email,
            emailNotifications: true,
            attachCsv: rep.attachCsv,
            cell: rep.cell,
            smsNotifications: rep.smsNotifications,
        })
    );

    return result;
};

export const getPaymentTermFromVendorInfo = (
    vendorInfo : VendorInfo | null,
    vendorId : DistributorId | null,
) : PaymentTerm => {
    if (vendorInfo === null) {
        return new PaymentTerm(DefaultPaymentTermOptions.NONE);
    }

    const vendor : Distributor | null = vendorInfo.getVendorById(vendorId);
    if (vendor === null) {
        return new PaymentTerm(DefaultPaymentTermOptions.NONE);
    }

    return vendor.getExtraDistributorProperties().paymentTerm;
};


export const createAdjustmentFormDataFromVendorInfo = (
    vendorInfo : VendorInfo | null,
    vendorId : DistributorId | null,
) : Map<string, IAdjustmentFormData> => {

    if (vendorInfo === null) {
        return new Map();
    }

    const vendor : Distributor | null = vendorInfo.getVendorById(vendorId);
    if (vendor === null) {
        return new Map();
    }

    const extraDistributorProperties = vendor.getExtraDistributorProperties();
    if (extraDistributorProperties === null) {
        return new Map();
    }

    const result = new Map<string, IAdjustmentFormData>();
    extraDistributorProperties.adjustments.forEach((adjustment, key) =>
        result.set(key.getValue(), {
            name: adjustment.name,
            category_id: adjustment.categoryId,
        })
    );

    return result;
};

export const createVendorInfoFromFormFields = (
    formData : IFormData,
    repFormData : Map<string, IRepFormData>,
    adjustmentFormData : Map<string, IAdjustmentFormData>,
    deliveryDays : Array<boolean> | null,
    paymentTerm : PaymentTerm
) : Distributor | null => {
    if (formData === null || deliveryDays === null) {
        return null;
    }

    const reps : Map<string, DistributorRep> = new Map();
    if (repFormData !== null) {
        repFormData.forEach((rep, key) => reps.set(
            key, createRepVendorInfoFromRepFormFields(rep)));
    }

    const adjustments : StringValueMap<DistributorAdjustmentId, DistributorAdjustment> = new StringValueMap();
    if (adjustmentFormData !== null) {
        adjustmentFormData.forEach((adjustment, key) => adjustments.set(
            new DistributorAdjustmentId(key), createAdjustmentVendorInfoFromAdjustmentFormFields(adjustment)));
    }

    return new Distributor(
        Scope.createRetailerScope(new LocationId(window.GLOBAL_RETAILER_ID)),
        formData.name,
        formData.name,
        new ExtraDistributorProperties(
            formData.accountNumber,
            formData.officePhone,
            formData.website,
            formData.accountingId,
            new Address(
                formData.addressLine1,
                formData.addressLine2,
                formData.city,
                formData.state,
                formData.zipcode),
            deliveryDays,
            formData.caseMinimum,
            formData.dollarMinimum,
            formData.notes,
            reps,
            adjustments,
            paymentTerm
        )
    );
};

export const createRepVendorInfoFromRepFormFields = (repFormData : IRepFormData) : DistributorRep =>
    new DistributorRep(
        repFormData.name,
        repFormData.email,
        true,
        repFormData.attachCsv,
        repFormData.cell.replace(/\D/g, ''),
        repFormData.smsNotifications,
    );

export const createAdjustmentVendorInfoFromAdjustmentFormFields = (adjustmentFormData : IAdjustmentFormData) : DistributorAdjustment =>
    new DistributorAdjustment(
        adjustmentFormData.name,
        adjustmentFormData.category_id,
    );
