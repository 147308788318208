import { Unit } from 'api/Product/model/Unit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export enum BaseUnit {
    EACH = 'EA',
    UNIT = 'unit',
}

/**
 * NOTE FOR ALL MODELS WITH @ts-expect-error 
 * The way we declare enum models is not good modern typescript practice. Ideally we would set unitByValue by having
 * a key type like `type BaseUnitKey = keyof typeof BaseUnit`, then we could enumerate/access values that way
 * Unfortunately due to the redeclared var namespace, keyof typeof will include all functions not just the enum
 * values. Doing a full refactor is not part of the scope of the current work (updating linting), but this is a dangerous game to play.
 * This code as-is works, if it's never touched again. If you have to touch this model, please  refactor it (and all similar models) 
 * to either A) be a class with enum values or B) export the enum and utils separately instead of under the same namespace
 **/

const unitByValue = new Map<string, BaseUnit>();
Object.keys(BaseUnit).forEach((key) => {
    // @ts-expect-error This is a bad pattern and we should not be using it any more.
    unitByValue.set(BaseUnit[key as any], BaseUnit[key as any] as BaseUnit);
});

// This is bad modern practice! do not follow this pattern. TODO: refactor to not redeclare vars
// eslint-disable-next-line no-redeclare
export namespace BaseUnit {
    export function isBaseUnit(unit : Unit) : unit is BaseUnit {
        return (typeof unitByValue.get(unit) !== 'undefined');
    }

    export function isBaseUnitValue(unitValue : string) : boolean {
        return (typeof unitByValue.get(unitValue) !== 'undefined');
    }

    export function getByBaseUnitValue(unitValue : string) : BaseUnit {
        const unit = unitByValue.get(unitValue);

        if (typeof unit === 'undefined') {
            throw new RuntimeException('unexpected value: ' + unitValue);
        }

        return unit;
    }

    export function getUnits() : Array<BaseUnit> {
        return Array.from(unitByValue.values());
    }
}
