import { Cart } from 'api/Cart/model/Cart';
import { CartItem } from 'api/Cart/model/CartItem';
import { CartUtils } from 'api/Cart/utils/CartUtils';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Price } from 'api/Product/model/Price';
import { ProductMergeEvent } from 'api/Product/model/ProductMergeEvent';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { PackagingWeight } from 'api/Product/model/PackagingWeight';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { IMergeEventJsonObject } from 'api/Product/serializer/IProductJSONObject';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import {
    ProductConflictFormValidationInputDataByFieldName
} from 'apps/MergeItemCard/components/ResolveProductConflictForm';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { DateTime } from 'shared/models/DateTime';
import { Conversions } from 'api/Product/model/Conversions';
import { Mappings } from 'api/Product/model/Mappings';
import { StringValueSet } from 'api/Core/StringValueSet';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { ProductDistributorAssociationIdentifier } from 'api/Product/model/ProductDistributorAssociationIdentifier';
import { ProductCost } from 'api/Product/model/ProductCost';

const getQuantityInUnitResolutionOptionsOnMergedProduct = (
    product1 : Product,
    product2 : Product,
    mergedProduct : Product,
    product1QuantityInUnit : QuantityInUnit<ProductQuantityUnit>,
    product2QuantityInUnit : QuantityInUnit<ProductQuantityUnit>,
    oldPackagingIdToNewPackagingId : StringValueMap<PackagingId, PackagingId>,
) : Array<QuantityInUnit<ProductQuantityUnit>> => {
    product1QuantityInUnit = PackagingUtils.resolveProductQuantityUnit(product1QuantityInUnit, product1.getPackagingsAndMappings().getMappings());
    product2QuantityInUnit = PackagingUtils.resolveProductQuantityUnit(product2QuantityInUnit, product2.getPackagingsAndMappings().getMappings());
    const product1Unit = product1QuantityInUnit.getUnit();
    let product2Unit = product2QuantityInUnit.getUnit();
    if (product2Unit instanceof PackagingId) {
        product2Unit = oldPackagingIdToNewPackagingId.getRequired(product2Unit);
        product2QuantityInUnit = new QuantityInUnit<ProductQuantityUnit>(
            product2QuantityInUnit.getQuantity(),
            product2Unit,
        );
    }

    const resolutionOptions : Array<QuantityInUnit<ProductQuantityUnit>> = [ product1QuantityInUnit, product2QuantityInUnit ];
    const product2ToProduct1QuantityOfProductConversion = PackagingUtils.convertProductQuantityToUnit(mergedProduct.getPackagingsAndMappings(), product2QuantityInUnit, product1Unit);

    if (product1Unit === product2Unit) {
        resolutionOptions.push(
            new QuantityInUnit(
                product2ToProduct1QuantityOfProductConversion.getQuantity() + product1QuantityInUnit.getQuantity(),
                product1Unit,
            )
        );
    } else {
        resolutionOptions.push(
            new QuantityInUnit(
                product2ToProduct1QuantityOfProductConversion.getQuantity() + product1QuantityInUnit.getQuantity(),
                product1Unit,
            )
        );
    }
    return resolutionOptions;
};

const getMergedProductAndPackageMappings = (
    product1 : Product,
    product2 : Product,
    validationInputDataByFieldName : ProductConflictFormValidationInputDataByFieldName,
    generatePackagingId : () => PackagingId
) : {
    mergedProduct: Product,
    oldPackagingIdToNewPackagingId : StringValueMap<PackagingId, PackagingId>,
} => {
    const packagingIdConversions : StringValueMap<PackagingId, number> = new StringValueMap();
    const unitOfMeasureConversions : Map<MassUnit | VolumeUnit, number> = new Map();
    const product1Conversions = product1.getPackagingsAndMappings().getConversions();
    const product2Conversions = product2.getPackagingsAndMappings().getConversions();
    const product1BaseUnit = PackagingUtils.resolveProductQuantityUnit(
        new QuantityInUnit<ProductQuantityUnit>(1, product1Conversions.getBaseUnit()),
        product1.getPackagingsAndMappings().getMappings(),
    ).getUnit();
    const product2BaseUnit = PackagingUtils.resolveProductQuantityUnit(
        new QuantityInUnit<ProductQuantityUnit>(1, product2Conversions.getBaseUnit()),
        product2.getPackagingsAndMappings().getMappings(),
    ).getUnit();
    const product1ReportingUnit = PackagingUtils.resolveProductQuantityUnit(
        new QuantityInUnit<ProductQuantityUnit>(1, product1.getPreferredReportingUnit()),
        product1.getPackagingsAndMappings().getMappings(),
    ).getUnit();
    const product2ReportingUnit = PackagingUtils.resolveProductQuantityUnit(
        new QuantityInUnit<ProductQuantityUnit>(1, product2.getPreferredReportingUnit()),
        product2.getPackagingsAndMappings().getMappings(),
    ).getUnit();
    const selectedBaseUnit = validationInputDataByFieldName.baseUnit.value;
    const selectedReportingUnit = validationInputDataByFieldName.preferredReportingUnit.value;
    const oldPackagingIdToNewPackagingId : StringValueMap<PackagingId, PackagingId> = new StringValueMap();
    const baseUnitConversionMultiplier = parseFloat(validationInputDataByFieldName.baseUnitConversion.value);
    let newBaseUnit = product1BaseUnit;
    let newReportingUnit = product1ReportingUnit;
    let hasUnitOfVolumeConversion = false;
    let hasUnitOfMassConversion = false;

    const packaging1DataToSave : Array<{ deleted : boolean, isActive : boolean, packaging : Packaging}> = [];
    const packaging2DataToSave : Array<{ deleted : boolean, isActive : boolean, packaging : Packaging}> = [];
    let packagingDataToSave : Array<{ deleted : boolean, isActive : boolean, packaging : Packaging}> = [];


    product1.getPackagingsAndMappings().getPackagingData().forEach((data) => {
        if (!data.deleted) {
            packaging1DataToSave.push(data);
        }
    });
    product2.getPackagingsAndMappings().getPackagingData().forEach((data) => {
        if (!data.deleted) {
            const duplicatePackaging = getDuplicatePackaging(data.packaging, packaging1DataToSave);
            if (duplicatePackaging !== null) {
                let currentPackaging : Packaging | null = data.packaging;
                let currentDuplicatePackaging : Packaging | null = duplicatePackaging;

                while (currentPackaging !== null && currentDuplicatePackaging !== null) {
                    const currentPackagingId = currentPackaging.getPackagingId();
                    const duplicatePackagingId = currentDuplicatePackaging.getPackagingId();

                    if (currentPackagingId !== null && duplicatePackagingId !== null) {
                        oldPackagingIdToNewPackagingId.set(currentPackagingId, duplicatePackagingId);
                    }

                    currentPackaging = currentPackaging.getContent();
                    currentDuplicatePackaging = currentDuplicatePackaging.getContent();
                }
            } else {
                const newPackaging = PackagingUtils.getPackagingWithNewPackagingId(data.packaging, oldPackagingIdToNewPackagingId, generatePackagingId);
                packaging2DataToSave.push({
                    ...data,
                    packaging: newPackaging,
                });
            }
        }
    });

    if (validationInputDataByFieldName.price.value === '2') {
        packagingDataToSave = packagingDataToSave.concat(packaging2DataToSave);
        packagingDataToSave = packagingDataToSave.concat(packaging1DataToSave);
    } else {
        packagingDataToSave = packagingDataToSave.concat(packaging1DataToSave);
        packagingDataToSave = packagingDataToSave.concat(packaging2DataToSave);
    }

    if (selectedReportingUnit === '2') {
        newReportingUnit = product2ReportingUnit instanceof PackagingId ? oldPackagingIdToNewPackagingId.getRequired(product2ReportingUnit) : product2ReportingUnit;
    }

    if (selectedBaseUnit === '2') {
        newBaseUnit = product2BaseUnit instanceof PackagingId ? oldPackagingIdToNewPackagingId.getRequired(product2BaseUnit) : product2BaseUnit;
        if (product1BaseUnit instanceof PackagingId) {
            packagingIdConversions.set(product1BaseUnit, baseUnitConversionMultiplier);
        } else if (
            newBaseUnit instanceof PackagingId ||
            (!VolumeUnit.isVolumeUnit(newBaseUnit) && VolumeUnit.isVolumeUnit(product1BaseUnit)) ||
            (VolumeUnit.isVolumeUnit(newBaseUnit) && !VolumeUnit.isVolumeUnit(product1BaseUnit)) ||
            (!MassUnit.isMassUnit(newBaseUnit) && MassUnit.isMassUnit(product1BaseUnit)) ||
            (MassUnit.isMassUnit(newBaseUnit) && !MassUnit.isMassUnit(product1BaseUnit))
        ) {
            unitOfMeasureConversions.set(product1BaseUnit, baseUnitConversionMultiplier);
            if (MassUnit.isMassUnit(product1BaseUnit)) {
                hasUnitOfMassConversion = true;
            } else {
                hasUnitOfVolumeConversion = true;
            }
        }
    } else if (selectedBaseUnit === '1') {
        if (product2BaseUnit instanceof PackagingId) {
            packagingIdConversions.set(oldPackagingIdToNewPackagingId.getRequired(product2BaseUnit), baseUnitConversionMultiplier);
        } else if (
            newBaseUnit instanceof PackagingId ||
            (!VolumeUnit.isVolumeUnit(newBaseUnit) && VolumeUnit.isVolumeUnit(product2BaseUnit)) ||
            (VolumeUnit.isVolumeUnit(newBaseUnit) && !VolumeUnit.isVolumeUnit(product2BaseUnit)) ||
            (!MassUnit.isMassUnit(newBaseUnit) && MassUnit.isMassUnit(product2BaseUnit)) ||
            (MassUnit.isMassUnit(newBaseUnit) && !MassUnit.isMassUnit(product2BaseUnit))
        ) {
            unitOfMeasureConversions.set(product2BaseUnit, baseUnitConversionMultiplier);
            if (MassUnit.isMassUnit(product2BaseUnit)) {
                hasUnitOfMassConversion = true;
            } else {
                hasUnitOfVolumeConversion = true;
            }
        }
    }

    product1Conversions.getPackagingIdConversions().forEach((number, packagingId) => {
        packagingIdConversions.set(packagingId, number * (selectedBaseUnit === '1' ? 1 : baseUnitConversionMultiplier));
    });
    product2Conversions.getPackagingIdConversions().forEach((number, packagingId) => {
        packagingIdConversions.set(oldPackagingIdToNewPackagingId.getRequired(packagingId), number * (selectedBaseUnit === '2' ? 1 : baseUnitConversionMultiplier));
    });
    product1Conversions.getUnitOfMeasureConversions().forEach((number, unit) => {
        if (MassUnit.isMassUnit(unit)) {
            if (!hasUnitOfMassConversion) {
                unitOfMeasureConversions.set(unit, number * (selectedBaseUnit === '1' ? 1 : baseUnitConversionMultiplier));
                hasUnitOfMassConversion = true;
            }
        } else if (!hasUnitOfVolumeConversion) {
            unitOfMeasureConversions.set(unit, number * (selectedBaseUnit === '1' ? 1 : baseUnitConversionMultiplier));
            hasUnitOfVolumeConversion = true;
        }
    });
    product2Conversions.getUnitOfMeasureConversions().forEach((number, unit) => {
        if ((MassUnit.isMassUnit(unit) && !hasUnitOfMassConversion) || (VolumeUnit.isVolumeUnit(unit) && !hasUnitOfVolumeConversion)) {
            unitOfMeasureConversions.set(unit, number * (selectedBaseUnit === '2' ? 1 : baseUnitConversionMultiplier));
        }
    });

    const packagingsAndMappings = new PackagingsAndMappings(
        packagingDataToSave,
        new Mappings(
            new StringValueMap(),
            new Map(),
        ),
        new Conversions(
            newBaseUnit,
            packagingIdConversions,
            unitOfMeasureConversions,
        ),
    );

    const weightsByPackagingId = new StringValueMap<PackagingId, PackagingWeight>();
    product1.getWeightsByPackagingId().forEach((packagingWeight, packagingId) => {
        // TODO Think about not having to resolve these
        const resolvedProductQuantityUnit = PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit<ProductQuantityUnit>(1, packagingId), product2.getPackagingsAndMappings().getMappings());
        const newPackagingUnit = resolvedProductQuantityUnit.getUnit();
        if (!(newPackagingUnit instanceof PackagingId)) {
            throw new RuntimeException(`unexpected newPackagingUnit is not a PackagingId ${newPackagingUnit}`);
        }
        weightsByPackagingId.set(newPackagingUnit, packagingWeight);
    });
    product2.getWeightsByPackagingId().forEach((packagingWeight, packagingId) => {
        const resolvedProductQuantityUnit = PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit<ProductQuantityUnit>(1, packagingId), product2.getPackagingsAndMappings().getMappings());
        const newPackagingUnit = resolvedProductQuantityUnit.getUnit();
        if (!(newPackagingUnit instanceof PackagingId)) {
            throw new RuntimeException(`unexpected newPackagingUnit is not a PackagingId ${newPackagingUnit}`);
        }
        const newPackagingId = oldPackagingIdToNewPackagingId.getRequired(newPackagingUnit);
        weightsByPackagingId.set(newPackagingId ? newPackagingId : packagingId, packagingWeight);
    });
    const multiVendorFeatureIsOn = window.GLOBAL_FEATURE_ACCESS.multi_vendor;
    let unitPrice = validationInputDataByFieldName.price.value === '2' ? product2.getUnitPrice() : product1.getUnitPrice();
    if (multiVendorFeatureIsOn) {
        unitPrice = new Price(
            unitPrice.getDollarValue(),
            oldPackagingUtils.getOldPackagingFromPackaging(packagingsAndMappings.getPackaging()).getUnit(),
        );
    }


    return {
        mergedProduct: new Product(
            validationInputDataByFieldName.brand.value === '2' ? product2.getBrand() : product1.getBrand(),
            validationInputDataByFieldName.name.value === '2' ? product2.getName() : product1.getName(),
            packagingsAndMappings,
            newReportingUnit,
            weightsByPackagingId,
            validationInputDataByFieldName.categoryId.value === '2' ? product2.getProductCategoryId() : product1.getProductCategoryId(),
            validationInputDataByFieldName.categoryId.value === '2' ? product2.getNewProductCategoryId() : product1.getNewProductCategoryId(),
            validationInputDataByFieldName.type.value === '2' ? product2.getProductType() : product1.getProductType(),
            unitPrice,
            validationInputDataByFieldName.unitDeposit.value === '2' ? product2.getDepositInDollars() : product1.getDepositInDollars(),
            validationInputDataByFieldName.sku.value === '2' ? product2.getSku() : product1.getSku(),
            validationInputDataByFieldName.glCode.value === '2' ? product2.getGLCode() : product1.getGLCode(),
            validationInputDataByFieldName.note.value === '2' ? product2.getNote() : product1.getNote(),
            new UserAccountIdAndTimestamp(new UserAccountId(window.GLOBAL_USER_ID), DateTime.now().toTimestampWithMillisecondPrecision()),
        ),
        oldPackagingIdToNewPackagingId
    };
};

const getMergedProductDistributorAssociations = (
    productId1 : ProductId,
    productId2 : ProductId,
    mergedProductId : ProductId,
    productsById : StringValueMap<ProductId, Product>,
    productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>,
    oldPackagingIdToNewPackagingId : StringValueMap<PackagingId, PackagingId>,
) : Array<ProductDistributorAssociation> => {
    const mergedProductDistributorAssociations : Array<ProductDistributorAssociation> = [];

    const product1 = productsById.getRequired(productId1);
    const product2 = productsById.getRequired(productId2);

    // TODO Cheezy what do we want to do with the quantities from resolving?
    const product1DistributorAssociations = productDistributorAssociationsByProductId.get(productId1) || new StringValueMap();
    product1DistributorAssociations.forEach((productDistributorAssociation) => {
        const productDistributorAssociationPrice = productDistributorAssociation.getPrice();
        const productDistributorAssociationDeposit = productDistributorAssociation.getDeposit();

        let resolvedProductDistributorAssociationPrice : ProductCost | null = null;
        if (productDistributorAssociationPrice) {
            resolvedProductDistributorAssociationPrice = new ProductCost(
                productDistributorAssociationPrice.getCost(),
                PackagingUtils.resolveProductQuantityUnit(
                    new QuantityInUnit(1, productDistributorAssociationPrice.getUnit()), product1.getPackagingsAndMappings().getMappings()
                ).getUnit(),
            );
        }

        let resolvedProductDistributorAssociationDeposit : ProductCost | null = null;
        if (productDistributorAssociationDeposit) {
            resolvedProductDistributorAssociationDeposit = new ProductCost(
                productDistributorAssociationDeposit.getCost(),
                PackagingUtils.resolveProductQuantityUnit(
                    new QuantityInUnit(1, productDistributorAssociationDeposit.getUnit()), product1.getPackagingsAndMappings().getMappings()
                ).getUnit(),
            );
        }

        mergedProductDistributorAssociations.push(
            new ProductDistributorAssociation(
                mergedProductId,
                PackagingUtils.resolveProductQuantityUnit(
                    new QuantityInUnit(1, productDistributorAssociation.getProductQuantityUnit()), product1.getPackagingsAndMappings().getMappings()
                ).getUnit(),
                productDistributorAssociation.getDistributorId(),
                productDistributorAssociation.getSku(),
                resolvedProductDistributorAssociationPrice,
                resolvedProductDistributorAssociationDeposit
            )
        );
    });

    const product2DistributorAssociations = productDistributorAssociationsByProductId.get(productId2) || new StringValueMap();
    product2DistributorAssociations.forEach((productDistributorAssociation) => {
        const productDistributorAssociationPrice = productDistributorAssociation.getPrice();
        const productDistributorAssociationDeposit = productDistributorAssociation.getDeposit();

        let resolvedProductDistributorAssociationPrice : ProductCost | null = null;
        if (productDistributorAssociationPrice) {
            const resolvedOldPriceUnit = PackagingUtils.resolveProductQuantityUnit(
                new QuantityInUnit(1, productDistributorAssociationPrice.getUnit()), product2.getPackagingsAndMappings().getMappings()
            ).getUnit();

            resolvedProductDistributorAssociationPrice = new ProductCost(
                productDistributorAssociationPrice.getCost(),
                (resolvedOldPriceUnit instanceof PackagingId) ? oldPackagingIdToNewPackagingId.getRequired(resolvedOldPriceUnit) : resolvedOldPriceUnit,
            );
        }

        let resolvedProductDistributorAssociationDeposit : ProductCost | null = null;
        if (productDistributorAssociationDeposit) {
            const resolvedOldDepositUnit = PackagingUtils.resolveProductQuantityUnit(
                new QuantityInUnit(1, productDistributorAssociationDeposit.getUnit()), product2.getPackagingsAndMappings().getMappings()
            ).getUnit();

            resolvedProductDistributorAssociationDeposit = new ProductCost(
                productDistributorAssociationDeposit.getCost(),
                (resolvedOldDepositUnit instanceof PackagingId) ? oldPackagingIdToNewPackagingId.getRequired(resolvedOldDepositUnit) : resolvedOldDepositUnit,
            );
        }

        const resolvedOldUnit = PackagingUtils.resolveProductQuantityUnit(
            new QuantityInUnit(1, productDistributorAssociation.getProductQuantityUnit()), product2.getPackagingsAndMappings().getMappings()
        ).getUnit();

        mergedProductDistributorAssociations.push(
            new ProductDistributorAssociation(
                mergedProductId,
                (resolvedOldUnit instanceof PackagingId) ? oldPackagingIdToNewPackagingId.getRequired(resolvedOldUnit) : resolvedOldUnit,
                productDistributorAssociation.getDistributorId(),
                productDistributorAssociation.getSku(),
                resolvedProductDistributorAssociationPrice,
                resolvedProductDistributorAssociationDeposit
            )
        );
    });

    return mergedProductDistributorAssociations;
};

const getSalesItemWithConflictsResolved = (
    productId1 : ProductId,
    productId2 : ProductId,
    mergedProductId : ProductId,
    oldPackagingIdToNewPackagingId : StringValueMap<PackagingId, PackagingId>,
    oldSalesItem: SalesItem,
    salesItemConflictResolution : QuantityInUnit<ProductQuantityUnit> | undefined | null,
    productsById : StringValueMap<ProductId, Product>,
) : SalesItem => {
    const componentQuantityByProductId = oldSalesItem.getComponentQuantityOfProductByProductId();
    const newComponentQuantityByProductId = new StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>();
    if (salesItemConflictResolution) {
        componentQuantityByProductId.forEach((quantityInUnit, productId) => {
            if (!productId.equals(productId1) && !productId.equals(productId2)) {
                newComponentQuantityByProductId.set(productId, quantityInUnit);
            }
        });
        newComponentQuantityByProductId.set(mergedProductId, salesItemConflictResolution);
    } else {
        componentQuantityByProductId.forEach((quantityInUnit, productId) => {
            if (productId.equals(productId1)) {
                const product = productsById.getRequired(productId1);
                newComponentQuantityByProductId.set(mergedProductId, PackagingUtils.resolveProductQuantityUnit(quantityInUnit, product.getPackagingsAndMappings().getMappings()));
            } else if (productId.equals(productId2)) {
                const product = productsById.getRequired(productId2);
                const resolvedQuantityInUnit = PackagingUtils.resolveProductQuantityUnit(quantityInUnit, product.getPackagingsAndMappings().getMappings());
                const oldProductQuantityUnit = resolvedQuantityInUnit.getUnit();
                const newProductQuantityUnit = oldProductQuantityUnit instanceof PackagingId ? oldPackagingIdToNewPackagingId.getRequired(oldProductQuantityUnit) : oldProductQuantityUnit;
                newComponentQuantityByProductId.set(
                    mergedProductId,
                    new QuantityInUnit<ProductQuantityUnit>(
                        resolvedQuantityInUnit.getQuantity(),
                        newProductQuantityUnit,
                    )
                );
            } else {
                newComponentQuantityByProductId.set(productId, quantityInUnit);
            }
        });
    }
    return new SalesItem(
        oldSalesItem.getName(),
        oldSalesItem.getLocationId(),
        oldSalesItem.getMenuGroup(),
        oldSalesItem.getPOSId(),
        oldSalesItem.getNote(),
        oldSalesItem.getNeedsAttentionCategory(),
        oldSalesItem.getSalesPrice(),
        oldSalesItem.getMiscellaneousCost(),
        newComponentQuantityByProductId,
        oldSalesItem.getComponentServingsBySalesItemId(),
        oldSalesItem.getItemYield(),
        oldSalesItem.getServingSize(),
        oldSalesItem.getSalesItemCustomUnitName(),
    );
};

const getProductMergeEventsByProductId = (
    productMergeEvents : Array<IMergeEventJsonObject>,
    productJSONToObjectSerializer : ProductJSONToObjectSerializer
) : StringValueMap<ProductId, Array<ProductMergeEvent>> => {
    const mergeEventsByProductId : StringValueMap<ProductId, Array<ProductMergeEvent>> = new StringValueMap();
    productMergeEvents.forEach((eventJson: IMergeEventJsonObject) => {
        const mergeEvent = productJSONToObjectSerializer.getMergeEvent(eventJson);
        if (!mergeEvent) {
            throw new RuntimeException('unexpected mergeEvent is null');
        }
        const mergedProducts : StringValueSet<ProductId> = new StringValueSet();
        mergedProducts.add(mergeEvent.getMergedToProductId());
        mergeEvent.getMergedFromProductIds().forEach((productId) => mergedProducts.add(productId));

        mergedProducts.forEach((productId) => {
            const mergeEventsForProductId = mergeEventsByProductId.get(productId) || [];
            mergeEventsForProductId.push(mergeEvent);
            mergeEventsByProductId.set(productId, mergeEventsForProductId);
        });
    });
    return mergeEventsByProductId;
};

const getAffectedSalesItemsById = (
    product1Id : ProductId,
    product2Id : ProductId,
    mergedProductId : ProductId,
    oldPackagingIdToNewPackagingIds : StringValueMap<PackagingId, PackagingId>,
    salesItemsWithMetadataById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    salesItemResolutionById : StringValueMap<SalesItemId, QuantityInUnit<ProductQuantityUnit> | null>,
    productsById : StringValueMap<ProductId, Product>,
) : StringValueMap<SalesItemId, SalesItem> => {
    const newSalesItemsById = new StringValueMap<SalesItemId, SalesItem>();
    salesItemsWithMetadataById.forEach((salesItemWithMetadata, salesItemId) => {
        const components = salesItemWithMetadata.getSalesItem().getComponentQuantityOfProductByProductId();
        if (components.has(product1Id) || components.has(product2Id)) {
            newSalesItemsById.set(
                salesItemId,
                getSalesItemWithConflictsResolved(
                    product1Id,
                    product2Id,
                    mergedProductId,
                    oldPackagingIdToNewPackagingIds,
                    salesItemWithMetadata.getSalesItem(),
                    salesItemResolutionById.get(salesItemId),
                    productsById
                )
            );
        }
    });
    return newSalesItemsById;
};

const getAffectedStorageAreaProductsById = (
    product1Id : ProductId,
    product2Id : ProductId,
    sortedProductIdListsByStorageAreaId : StringValueMap<StorageAreaId, Array<ProductId>>
) : StringValueMap<StorageAreaId, Array<ProductId>> => {
    const affectedStorageAreaProductsById = new StringValueMap<StorageAreaId, Array<ProductId>>();
    sortedProductIdListsByStorageAreaId.forEach((productIdList, storageAreaId) => {
        const affectedProducts : Array<ProductId> = [];
        if (productIdList.some((productIdInList) => product1Id.equals(productIdInList))) {
            affectedProducts.push(product1Id);
        }
        if (productIdList.some((productIdInList) => product2Id.equals(productIdInList))) {
            affectedProducts.push(product2Id);
        }
        if (affectedProducts.length > 0) {
            affectedStorageAreaProductsById.set(storageAreaId, affectedProducts);
        }
    });
    return affectedStorageAreaProductsById;
};

const getCartConflicts = (
    cart : Cart,
    productId1 : ProductId,
    productId2 : ProductId
) : Map<CartItem, CartItem> => {
    const product1CartItems = CartUtils.getCartItemsForProductId(cart, productId1);
    const product2CartItems = CartUtils.getCartItemsForProductId(cart, productId2);
    const conflictingCartItems : Map<CartItem, CartItem> = new Map();
    if (product1CartItems.length === 0 || product2CartItems.length === 0) {
        return conflictingCartItems;
    }

    if (window.GLOBAL_FEATURE_ACCESS.multi_vendor) {
        const conflictingProduct2CartItems : Array<CartItem> = [];
        product1CartItems.forEach((cartItem1) => {
            const cartItem1Distributor = cartItem1.getDistributorId();
            product2CartItems.forEach((cartItem2) => {
                const cartItem2Distributor = cartItem2.getDistributorId();
                if (
                    !conflictingProduct2CartItems.includes(cartItem2) &&
                    (cartItem1Distributor && cartItem2Distributor && cartItem1Distributor.equals(cartItem2Distributor)) ||
                    (cartItem1Distributor == null && cartItem2Distributor == null)
                ) {
                    conflictingCartItems.set(cartItem1, cartItem2);
                    conflictingProduct2CartItems.push(cartItem2);
                }
            });
        });
    } else {
        // multi-vendor is off, only one of each product can be in the cart
        conflictingCartItems.set(product1CartItems[0], product2CartItems[0]);
    }
    return conflictingCartItems;
};

const getDuplicatePackaging = (
    packaging : Packaging,
    packagingDataArray : Array<{ deleted : boolean, isActive : boolean, packaging : Packaging}>
) : Packaging | null => {
    for (const packagingData of packagingDataArray) {
        if (!packagingData.deleted && packagingData.isActive && PackagingUtils.arePackagingsEqualWithName(packagingData.packaging, packaging)) {
            return packagingData.packaging;
        }
    }
    return null;
};

export const MergeItemUtils = {
    getMergedProductAndPackageMappings,
    getMergedProductDistributorAssociations,
    getSalesItemWithConflictsResolved,
    getQuantityInUnitResolutionOptionsOnMergedProduct,
    getProductMergeEventsByProductId,
    getAffectedSalesItemsById,
    getAffectedStorageAreaProductsById,
    getCartConflicts,
};
