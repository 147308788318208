import { StringValueMap } from 'api/Core/StringValueMap';
import { ItemCardHistoryMergeFrom } from 'apps/ItemCard/components/ItemCardHistoryMergeFrom';
import { ItemCardHistoryMergeTo } from 'apps/ItemCard/components/ItemCardHistoryMergeTo';
import React from 'react';

import { BreakageId } from 'api/Breakage/model/BreakageId';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { TransferId } from 'api/Transfer/model/TransferId';
import { PrepEventDisplayUtils } from 'apps/CreateOrEditPrepEvent/utils/PrepEventDisplayUtils';

import { IItemCardData, IProductHistoryData } from '../reducers/ItemCardReducers';
import { getActivityDataForProduct, ProductActivityByDayByMonth, ProductActivityOnDay } from '../utils/ItemCardHistoryUtils';
import { ItemCardHistoryBreakage } from './ItemCardHistoryBreakage';
import { ItemCardHistoryInventory } from './ItemCardHistoryInventory';
import { ItemCardHistoryOrder } from './ItemCardHistoryOrder';
import { ItemCardHistoryPrepEvent } from './ItemCardHistoryPrepEvent';
import { ItemCardHistoryPrice } from './ItemCardHistoryPrice';
import { ItemCardHistoryTransfer } from './ItemCardHistoryTransfer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import '../css/ItemCardHistory.scss';

export interface IItemCardHistory {
    productId : ProductId;
    product : Product;
    itemCardData : IItemCardData;
    productHistoryData : IProductHistoryData;
    productsById : StringValueMap<ProductId, Product>;
}

export class ItemCardHistory extends React.Component<IItemCardHistory, object> {
    public render() {
        const {
            productId,
            product,
            productHistoryData,
        } = this.props;

        const productActivityByDayByMonth = getActivityDataForProduct(productHistoryData, productId);
        const sortedMonthDateStrings = Array.from(productActivityByDayByMonth.keys()).sort((dateStr1 : string, dateStr2 : string) : number => {
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            if (date1.getTime() < date2.getTime()) {
                return 1;
            } else if (date1.getTime() === date2.getTime()) {
                return 0;
            } else {
                return -1;
            }
        });

        let hasAtLeastOneEntry = false;
        let hasMonthWithNoEntries = false;
        Array.from(productActivityByDayByMonth.values()).forEach((activityByDay : Map<number, ProductActivityOnDay>) => {
            if (activityByDay.size > 0) {
                hasAtLeastOneEntry = true;
            }
            if (activityByDay.size === 0) {
                hasMonthWithNoEntries = true;
            }
        });

        return (
            <div className="item-card-history">
                <ItemCardHistoryPrice
                    productHistoryData={ productHistoryData }
                    productId={ productId }
                    product={ product }
                />
                { /* NOTE: 011619 MOU - removed sticky header component because of buggy behavior coexisting with the other header, also in IE */ }
                <div className="sticky-header-wrapper sticky-header">
                    <div className="item-activity-header">
                        <div className="item-activity-label">Item Activity</div>
                        { !hasMonthWithNoEntries &&
                            <div className="showing-activity-label">
                                <span className="bevicon bevico-history"/>
                                Showing last { productHistoryData.numMonthsOfData } month{ productHistoryData.numMonthsOfData > 1 ? 's' : '' } of activity
                            </div>
                        }
                    </div>
                </div>
                { product && hasAtLeastOneEntry && sortedMonthDateStrings.map((dateString) => {
                    return this.renderMonth(dateString, productActivityByDayByMonth);
                }) }
                { !hasAtLeastOneEntry &&
                    <div className="no-activity">
                        <div className="icons">
                            <span className="bevicon bevico-shopping-cart"/>
                            <span className="bevicon bevico-assignment"/>
                            <span className="bevicon bevico-transfer-in"/>
                            <span className="bevicon bevico-breakage"/>
                        </div>
                        Any time you inventory, order, transfer or report a spill for this item, it will appear here.
                    </div>
                }
                { hasMonthWithNoEntries && hasAtLeastOneEntry &&
                    <div className="entry-with-no-months">
                        <div className="showing-activity-label">
                            <span className="bevicon bevico-history"/>
                            Showing last { productHistoryData.numMonthsOfData } month{ productHistoryData.numMonthsOfData > 1 ? 's' : '' } of activity
                        </div>
                    </div>
                }
            </div>
        );
    }

    private renderMonth(monthString : string, productActivityByDayByMonth : ProductActivityByDayByMonth) {
        const activityInMonth = productActivityByDayByMonth.get(monthString);
        if (typeof activityInMonth === 'undefined') {
            throw new RuntimeException(`unexpected no activity in month for ${monthString}`);
        }

        const sortedDays = Array.from(activityInMonth.keys()).sort((day1 : number, day2 : number) => {
            if (day1 < day2) {
                return 1;
            } else if (day1 === day2) {
                return 0;
            } else {
                return -1;
            }
        });

        const monthDate = new Date(monthString);
        const monthTitleText = monthDate.toLocaleString('en-US', { month: 'long', year: 'numeric' });

        return (
            <div className="item-history-month" key={ monthString }>
                { /* NOTE: 011619 MOU - removed sticky header component because of buggy behavior coexisting with the other header, also in IE */ }
                <div className="sticky-header-wrapper sticky-item">
                    <div className="month-header">
                        <div className="month-title">{ monthTitleText }</div>
                    </div>
                </div>
                { sortedDays.length > 0 && sortedDays.map((dayNumber : number) => {
                    return this.renderDay(dayNumber, monthString, productActivityByDayByMonth);
                }) }
                { sortedDays.length === 0 &&
                    <div className="no-days-container">
                        <div className="no-days">No activity for this month</div>
                    </div>
                }
            </div>
        );
    }

    private renderDay(dayNumber : number, monthString : string, productActivityByDayByMonth : ProductActivityByDayByMonth) {
        const {
            itemCardData,
            productId,
            product,
            productHistoryData,
            productsById,
        } = this.props;

        const activityByDay = productActivityByDayByMonth.get(monthString);
        if (typeof activityByDay === 'undefined') {
            throw new RuntimeException(`unexpected no activity for month ${monthString}`);
        }
        const activityOnDay = activityByDay.get(dayNumber);
        if (typeof activityOnDay === 'undefined') {
            throw new RuntimeException(`unexpected no activity for day ${dayNumber} in month ${monthString}`);
        }

        if (!itemCardData) {
            throw new RuntimeException('unexpected itemCardData in null');
        }
        if (!product) {
            throw new RuntimeException('unexpected product is null');
        }
        if (!productId) {
            throw new RuntimeException('unexpected productId is null');
        }

        const monthDate = new Date(monthString);
        const monthShortForm = monthDate.toLocaleString('en-US', { month: 'short' });

        const prepEventLabelsById = PrepEventDisplayUtils.getLabelsForPrepEventIds(activityOnDay.prepEventIds, productHistoryData.prepEventsByPrepEventId, itemCardData.productsById);

        return (
            <div className="item-history-day" key={ dayNumber }>
                <div className="day-label">
                    <div className="month">{ monthShortForm }</div>
                    <div className="day-number">{ dayNumber }</div>
                </div>
                <div className="activity-container">
                    { Array.from(activityOnDay.deliveryIds.values()).map((deliveryId : DeliveryId) => {
                        const delivery = productHistoryData.deliveriesByDeliveryId.getRequired(deliveryId);
                        const distributorId = delivery.getDistributorId();
                        let distributorName : string | null = null;
                        if (distributorId) {
                            const distributor = itemCardData.distributorsByDistributorId.get(distributorId);
                            if (typeof distributor !== 'undefined') {
                                distributorName = distributor.getName();
                            }
                        }
                        return (
                            <ItemCardHistoryOrder
                                key={ deliveryId.getValue() }
                                productId={ productId }
                                product={ product }
                                deliveryId={ deliveryId }
                                delivery={ delivery }
                                distributorName={ distributorName }
                            />
                        );
                    }) }
                    { Array.from(activityOnDay.inventoryCountIds.values()).map((inventoryCountId : InventoryCountId) => {
                        const inventoryCount = productHistoryData.inventoryCountsByInventoryId.getRequired(inventoryCountId);
                        const inventoryCountMetadata = productHistoryData.inventoryMetadataByInventoryId.getRequired(inventoryCountId);
                        return (
                            <ItemCardHistoryInventory
                                key={ inventoryCountId.getValue() }
                                productId={ productId }
                                product={ product }
                                inventoryCountId={ inventoryCountId }
                                inventoryCount={ inventoryCount }
                                inventoryCountMetadata={ inventoryCountMetadata }
                            />
                        );
                    }) }
                    { Array.from(activityOnDay.prepEventIds.values()).map((prepEventId : PrepEventId) => {
                        const prepEvent = productHistoryData.prepEventsByPrepEventId.getRequired(prepEventId);
                        const prepEventLabel = prepEventLabelsById.getRequired(prepEventId);

                        return (
                            <ItemCardHistoryPrepEvent
                                key={ prepEventId.getValue() }
                                productId={ productId }
                                product={ product }
                                prepEventId={ prepEventId }
                                prepEvent={ prepEvent }
                                prepEventLabel={ prepEventLabel.preparedItems }
                            />
                        );
                    }) }
                    { Array.from(activityOnDay.transferIds.values()).map((transferId : TransferId) => {
                        const transfer = productHistoryData.transferReportsByTransferId.getRequired(transferId);
                        return (
                            <ItemCardHistoryTransfer
                                key={ transferId.getValue() }
                                productId={ productId }
                                product={ product }
                                transferId={ transferId }
                                transfer={ transfer }
                                locationNamesByLocationId={ productHistoryData.locationNamesByLocationId }
                            />
                        );
                    }) }
                    { Array.from(activityOnDay.breakageIds.values()).map((breakageId : BreakageId) => {
                        const breakage = productHistoryData.breakageReportsByBreakageId.getRequired(breakageId);
                        return (
                            <ItemCardHistoryBreakage
                                key={ breakageId.getValue() }
                                productId={ productId }
                                product={ product }
                                breakageId={ breakageId }
                                breakage={ breakage }
                            />
                        );
                    }) }

                    {
                        activityOnDay.mergeTo.size > 0 &&
                        <ItemCardHistoryMergeTo
                            product={product}
                            productId={productId}
                            mergedToProductId={activityOnDay.mergeTo}
                            productsById={productsById}
                        />
                    }

                    {
                        activityOnDay.mergeFrom.size > 0 &&
                        <ItemCardHistoryMergeFrom
                            product={product}
                            productId={productId}
                            mergedFromProductIds={activityOnDay.mergeFrom}
                            productsById={productsById}
                        />
                    }
                </div>
            </div>
        );
    }
}
