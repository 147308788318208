export type IncidentBannerLevel = 'info' | 'warning' | 'error';

export class IncidentBannerData {

    constructor (
        private readonly message : string | null,
        private readonly isShown : boolean,
        private readonly level : IncidentBannerLevel = 'info'
    ) {
    }

    public getMessage() : string | null {
        return this.message;
    }

    public getIsShown() : boolean {
        return this.isShown;
    }

    public getLevel() : IncidentBannerLevel {
        return this.level;
    }
}
