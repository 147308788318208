import { IStringValue } from 'api/Core/IStringValue';

export class UserActionLogCommentId implements IStringValue {
    constructor(readonly value : string) {}

    public equals(other : IStringValue) : boolean {
        return this.value === other.getValue();
    }

    public getValue() : string {
        return this.value;
    }

    public toString() : string {
        return `{UserActionLogCommentId: ${this.value}}`;
    }
}
