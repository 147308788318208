import { IOfflineQueueManager } from 'api/Core/OfflineQueueManager/interfaces/IOfflineQueueManager';
import { StringValueMap } from 'api/Core/StringValueMap';
import {
    IInventoryCountRetailerViewPreferences,
    IInventoryCountViewPreferencesService
} from 'api/InventoryCount/interfaces/IInventoryCountViewPreferencesService';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { LocationId } from 'api/Location/model/LocationId';

import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { GroupByOption } from 'shared/models/GroupByOption';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

interface IGetRetailerInventoryViewPreferencesResponse {
    success : boolean;
    view_preferences : { [storageAreaId : string] : {
        activeColumnSort : IColumnSorting;
        activeGroupByOption : string;
    } };
}

export class InventoryCountViewPreferencesServiceImpl implements IInventoryCountViewPreferencesService {

    constructor (
        private offlineQueueManager : IOfflineQueueManager,
    ) {}

    public setRetailerInventoryViewPreferencesForStorageAreaForLocationId(
        locationId : LocationId,
        storageAreaId : StorageAreaId,
        viewPreferences : IInventoryCountRetailerViewPreferences
    ) : Promise<void> {
        // yeah we prefer rest params for readability but I'm not refactoring this as part of linting updates. If you touch this code, please fix.
        // eslint-disable-next-line prefer-rest-params
        return this.offlineQueueManager.enqueueServiceCall(this, 'setRetailerInventoryViewPreferencesForStorageAreaForLocationId', arguments, () => {
            return new Promise<void>((resolve, reject) => {
                const urlValue = url('inventory:set_retailer_view_preferences_for_storage_area', null, window.GLOBAL_RETAILER_ID, null);
                const formData : FormData = new FormData();
                formData.append('storage_area_id', storageAreaId.getValue());
                formData.append('view_preferences', JSON.stringify(viewPreferences));
                return AjaxUtils.ajaxPostForm(urlValue, formData)
                .then((response) => {
                    resolve();
                })
                .catch((reason) => {
                    reject(reason);
                });
            });
        });
    }

    public getRetailerInventoryViewPreferencesForLocationId(
        locationId : LocationId
    ) : Promise<StringValueMap<StorageAreaId, IInventoryCountRetailerViewPreferences>> {
        return AjaxUtils.ajaxGet(url('inventory:get_retailer_view_preferences_for_storage_area', null, locationId.getValue(), null))
        .then((response : IGetRetailerInventoryViewPreferencesResponse) => {
            const preferencesByStorageAreaId = new StringValueMap<StorageAreaId, IInventoryCountRetailerViewPreferences>();

            const viewPreferences = response.view_preferences;
            if (viewPreferences) {
                for (const storageAreaIdStr of Object.keys(viewPreferences)) {
                    const viewPreferencesForStorageArea = viewPreferences[storageAreaIdStr];

                    let activeGroupByOption : GroupByOption;
                    const activeGroupByOptionValue = viewPreferencesForStorageArea.activeGroupByOption;
                    switch (activeGroupByOptionValue) {
                        case 'ALL_ITEMS':
                            activeGroupByOption = GroupByOption.ALL_ITEMS;
                            break;
                        case 'DISTRIBUTOR':
                            activeGroupByOption = GroupByOption.DISTRIBUTOR;
                            break;
                        case 'CATEGORY':
                            activeGroupByOption = GroupByOption.CATEGORY;
                            break;
                        case 'ITEM_TYPE':
                            activeGroupByOption = GroupByOption.ITEM_TYPE;
                            break;
                        case 'LAST_EDITED':
                            activeGroupByOption = GroupByOption.LAST_EDITED;
                            break;
                        default:
                            throw new RuntimeException(`unexpected activeGroupByOptionValue: ${ activeGroupByOptionValue }`);
                    }

                    preferencesByStorageAreaId.set(new StorageAreaId(storageAreaIdStr), {
                        activeColumnSort: viewPreferencesForStorageArea.activeColumnSort,
                        activeGroupByOption,
                    });
                }
            }

            return Promise.resolve(preferencesByStorageAreaId);
        });
    }
}
