import React from 'react';
import { connect } from 'react-redux';

import LocationModel from 'gen-thrift/location_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { CatalogItemId } from 'api/Search/model/CatalogItemId';
import { ICatalogItemOption } from 'api/Search/model/ICatalogItemOption';
import { AddItemModalComponent, CatalogItemComponent, ICatalogItemOptionRowId, IPricedProductPackageRowId } from 'shared/components/AddItem/reducers/addItemReducers';

import { AddItemActions, IAddItemResult, AddItemDispatch } from './actions/addItemActions';
import { AddItemModalActions, AddItemModalDispatch, IAddItemModalProps } from './actions/addItemModalActions';

import { Modal } from 'shared/components/Modal';
import { AddItemModalHeader } from './components/AddItemModalHeader';
import { AddItemView } from './components/AddItemView';

import './css/AddItemModal.scss';

interface IConnectedAddItemModalAppProps {
    setCreateItemModalShown : (isShown : boolean) => void;
    onAddItemsToEntity : (addItemResult : IAddItemResult) => void;
    onCloseModal : () => void;
    onGetInitialDataFromContext? : () => StringValueMap<ProductId, Product>;
}

interface IAddItemModalAppProps extends IConnectedAddItemModalAppProps, IAddItemModalProps {

}

interface IBoundAddItemModalAppProps extends IAddItemModalAppProps {
    dispatch : AddItemModalDispatch & AddItemDispatch;
}

export class AddItemModalApp extends React.Component<IBoundAddItemModalAppProps, object> {

    private static resetTableScrollPosition() {
        const addItemMainElement = document.getElementsByClassName('add-item-main')[0];
        if (typeof addItemMainElement !== 'undefined') {
            addItemMainElement.scrollTop = 0;
        }
    }

    private readonly RESULTS_PER_PAGE = 30;
    private readonly retailerId : LocationModel.LocationIdentifier;

    constructor(props : IBoundAddItemModalAppProps) {
        super(props);

        this.retailerId = new LocationModel.LocationIdentifier({ value: window.GLOBAL_RETAILER_ID });

        this.setProductRowExpanded = this.setProductRowExpanded.bind(this);
        this.setProductPackageRowSelected = this.setProductPackageRowSelected.bind(this);
        this.setItemsSelectedMenuIsOpen = this.setItemsSelectedMenuIsOpen.bind(this);
        this.handleAddItemsToEntity = this.handleAddItemsToEntity.bind(this);
        this.handleSetSearchTerm = this.handleSetSearchTerm.bind(this);
        this.handleUpdateSearchResults = this.handleUpdateSearchResults.bind(this);
        this.handleCloseAddItemModal = this.handleCloseAddItemModal.bind(this);
        this.handleOpenCreateItemModal = this.handleOpenCreateItemModal.bind(this);
        this.handleSetPageOffset = this.handleSetPageOffset.bind(this);
        this.resetModal = this.resetModal.bind(this);
    }

    public UNSAFE_componentWillMount() {
        this.props.dispatch(AddItemActions.setResultsPerPage(this.RESULTS_PER_PAGE));
        this.props.dispatch(AddItemActions.setOnGetInitialDataFromContextCreator(this.props.onGetInitialDataFromContext));
        this.props.dispatch(AddItemActions.getInitialData());
    }

    public render() {
        const {
            addItemState,
            addItemModalState,
        } = this.props;

        const searchIsActive = (addItemState.activeSearchTerm !== null && addItemState.activeSearchTerm !== '');
        const emptyState = addItemState.isShownByComponentName.emptyState;
        const catalogAccess = window.GLOBAL_FEATURE_ACCESS.catalog;
        const showLargeSearchBar = !searchIsActive && (catalogAccess || emptyState);

        return (
            <div>
                { addItemModalState.isShown &&
                    <Modal className="add-item-modal">
                        <AddItemModalHeader
                            showCreateItemModal={ this.handleOpenCreateItemModal }
                            closeAddItemModal={ this.handleCloseAddItemModal }
                        />
                        <AddItemView
                            addItemState={ addItemState }
                            dismissSnackBar={ this.handleDismissSnackBar }
                            addItemsToEntity={ this.handleAddItemsToEntity }
                            setItemSelectedMenuIsOpen={ this.setItemsSelectedMenuIsOpen }
                            setProductRowExpanded={ this.setProductRowExpanded }
                            setProductPackageRowSelected={ this.setProductPackageRowSelected }
                            setCatalogRowSelected={ this.setCatalogRowSelected }
                            setPageOffset={ this.handleSetPageOffset }
                            setComponentShown={ this.setComponentShown }
                            setCatalogItemComponentIsShown={ this.setCatalogItemComponentIsShown }
                            openCreateItemModal={ this.handleOpenCreateItemModal }
                            addCustomCatalogItemOption={ this.addCustomCatalogItemOption }
                            onExitWithoutSaving={ this.handleExitModal }
                            onSaveChangesAndExit={ this.handleSaveChangesAndCloseModal }

                            isSearchBarShown={ showLargeSearchBar }

                            searchTerm={ addItemState.searchTerm }
                            isSearchBarFocused={ addItemState.isSearchBarFocused }
                            setSearchTerm={ this.handleSetSearchTerm }

                            updateSearchResults={ this.handleUpdateSearchResults }
                            searchSuggestionListIsShown={ addItemState.isShownByComponentName.searchSuggestionList }
                            searchSuggestions={ addItemState.searchSuggestions }
                            highlightedSearchSuggestion={ addItemState.highlightedSearchSuggestion }
                            setHighlightedSearchSuggestion={ this.setHighlightedSearchSuggestion }
                            updateSearchSuggestions={ this.updateSearchSuggestions }
                            setSearchSuggestionListIsShown={ this.setSearchSuggestionListIsShown }
                        />
                    </Modal>
                }
            </div>
        );
    }

    private setProductRowExpanded(productId : ProductId, isExpanded : boolean) {
        this.props.dispatch(AddItemActions.setProductRowExpanded(productId, isExpanded));
    }

    private setProductPackageRowSelected(rowId : IPricedProductPackageRowId, isSelected : boolean) {
        this.props.dispatch(AddItemActions.setProductRowSelected(rowId, isSelected));
    }

    private readonly setCatalogRowSelected = (rowId : ICatalogItemOptionRowId, isSelected : boolean) => this.props.dispatch(AddItemActions.setCatalogRowSelected(rowId, isSelected));

    private setItemsSelectedMenuIsOpen(isOpen : boolean) {
        if (this.props.addItemState.selections.length > 0) {
            this.props.dispatch(AddItemActions.setItemsSelectedMenuIsOpen(isOpen));
        }
    }

    private readonly handleDismissSnackBar = () => {
        this.props.dispatch(AddItemActions.setAddedProductsNotificationShown(false, 0));
    }

    private handleAddItemsToEntity() {
        this.props.dispatch(AddItemActions.addItemsToEntity(this.retailerId))
        .then((result) => {
            this.props.onAddItemsToEntity(result);
        });
    }

    private handleSetSearchTerm(searchTerm : string | null) {
        this.props.dispatch(AddItemActions.setSearchTerm(searchTerm));
    }

    private handleUpdateSearchResults(searchTerm : string) {
        if (searchTerm === '') {
            this.props.dispatch(AddItemActions.setSearchTerm(null));
        }
        return this.props.dispatch(AddItemActions.setPageOffsetAndFetchProducts(this.retailerId, 0))
        .then(() => {
            AddItemModalApp.resetTableScrollPosition();
        });
    }

    private handleCloseAddItemModal() {
        const {
            dispatch,
            addItemState
        } = this.props;

        if (addItemState.selections.length > 0) {
            dispatch(AddItemActions.setComponentIsShown('unsavedChangesDialog', true));
        } else {
            this.handleExitModal();
        }

    }

    private readonly handleExitModal = () => {
        this.props.dispatch(AddItemModalActions.setIsShown(false));
        this.props.dispatch(AddItemActions.setComponentIsShown('unsavedChangesDialog', false));
        this.props.onCloseModal();
        this.resetModal();
    }

    private readonly handleSaveChangesAndCloseModal = () => {
        // this doesn't do the dropdown closing logic that handleAddItemsToEntity does, but once the dialog is open probably don't need to do that...
        this.props.dispatch(AddItemActions.setComponentIsShown('unsavedChangesDialog', false)); // do we need a loading state here?
        this.props.dispatch(AddItemActions.addItemsToEntity(this.retailerId))
        .then((result) => {
            this.props.onAddItemsToEntity(result);
            this.handleExitModal();
        });
    }

    private handleOpenCreateItemModal() {
        this.props.setCreateItemModalShown(true);
    }

    private handleSetPageOffset(page : number) {
        this.props.dispatch(AddItemActions.setPageOffsetAndFetchProducts(this.retailerId, page - 1))
        .then(() => {
            AddItemModalApp.resetTableScrollPosition();
        });
    }

    private resetModal() {
        this.props.dispatch(AddItemActions.setActiveSearchTerm(null));
        this.props.dispatch(AddItemActions.setPageOffset(0));
        this.props.dispatch(AddItemActions.setSearchTerm(null));
        this.props.dispatch(AddItemActions.setComponentIsShown('mobileFilterPanel', false));
        this.props.dispatch(AddItemActions.setProductIdsLastAddedToEntity([]));
        this.props.dispatch(AddItemActions.setComponentIsShown('searchSuggestionList', false));
        this.props.dispatch(AddItemActions.setSearchSuggestions([]));
        this.props.dispatch(AddItemActions.clearAllSelections());
        this.props.dispatch(AddItemActions.setItemsSelectedMenuIsOpen(false));
    }

    private readonly setHighlightedSearchSuggestion = (searchSuggestion : string | null) => this.props.dispatch(AddItemActions.setHighlightedSearchSuggestion(searchSuggestion));
    private readonly updateSearchSuggestions = () => this.props.dispatch(AddItemActions.updateSearchSuggestions());
    private readonly setSearchSuggestionListIsShown = (isShown : boolean) => this.props.dispatch(AddItemActions.setComponentIsShown('searchSuggestionList', isShown));
    private readonly setComponentShown = (componentName : AddItemModalComponent, isShown : boolean) => this.props.dispatch(AddItemActions.setComponentIsShown(componentName, isShown));
    private readonly setCatalogItemComponentIsShown = (catalogItemId : CatalogItemId, component : CatalogItemComponent, isShown : boolean) => {
        return this.props.dispatch(AddItemActions.setCatalogItemOptionDropdownIsOpen(catalogItemId, component, isShown));
    }
    private readonly addCustomCatalogItemOption = (option : ICatalogItemOption, catalogItemId : CatalogItemId) => this.props.dispatch(AddItemActions.addCustomCatalogItemOption(option, catalogItemId));
}

const mapStateToProps = (state : IAddItemModalProps, ownProps : IConnectedAddItemModalAppProps) : IAddItemModalAppProps => {
    return {
        ...ownProps,
        addItemState: state.addItemState,
        addItemModalState: state.addItemModalState,
    };
};

export const ConnectedAddItemModalApp = connect<IAddItemModalAppProps, object, IConnectedAddItemModalAppProps, IAddItemModalProps>(mapStateToProps)(AddItemModalApp);
