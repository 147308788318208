import { Action } from '@reduxjs/toolkit';
import { ActionTypes, ToastIntegrationActionInterfaces } from './ToastIntegrationBlockActions';

import { ToastIntegrationState } from 'api/Integration/model/ToastIntegrationState';

export interface IToastIntegrationBlockState {
    isLoading : boolean;
    existingIntegration : null | ToastIntegrationState;
    modalIsShown : boolean;
    modalState: IToastIntegrationModalState | null;
}

export interface IToastIntegrationModalState {
    accessToken: string | null;
}

const initialState : IToastIntegrationBlockState = {
    isLoading : false,
    existingIntegration : null,
    modalIsShown : false,
    modalState: null,
};

const reduceSetIsLoading = (
    state : IToastIntegrationBlockState,
    action : ToastIntegrationActionInterfaces.ISetIsLoading
) : IToastIntegrationBlockState => {
    return {
        ...state,
        isLoading : action.payload.isLoading,
    };
};

const reduceSetModalShown = (
    state : IToastIntegrationBlockState,
    action : ToastIntegrationActionInterfaces.ISetModalShown
) : IToastIntegrationBlockState => {
    return {
        ...state,
        modalIsShown : action.payload.isShown,
    };
};

const reduceSetModalAccessToken = (
    state : IToastIntegrationBlockState,
    action : ToastIntegrationActionInterfaces.ISetModalAccessToken
) : IToastIntegrationBlockState => {
    return {
        ...state,
        modalState: {
            ...state.modalState,
            accessToken: action.payload.accessToken
        }
    };
};

const reduceSetExistingIntegrationState = (
    state : IToastIntegrationBlockState,
    action : ToastIntegrationActionInterfaces.ISetExistingIntegrationState
) : IToastIntegrationBlockState => {
    return {
        ...state,
        existingIntegration : action.payload.toastIntegrationState,
    };
};

export const ToastIntegrationBlockReducers = (
    state : IToastIntegrationBlockState = initialState,
    action : Action,
) : IToastIntegrationBlockState => {
    switch (action.type) {
        case ActionTypes.SET_IS_LOADING:
            return reduceSetIsLoading(state, action as ToastIntegrationActionInterfaces.ISetIsLoading);
        case ActionTypes.SET_EXISTING_INTEGRATION_STATE:
            return reduceSetExistingIntegrationState(state, action as ToastIntegrationActionInterfaces.ISetExistingIntegrationState);
        case ActionTypes.SET_MODAL_SHOWN:
            return reduceSetModalShown(state, action as ToastIntegrationActionInterfaces.ISetModalShown);
        case ActionTypes.SET_MODAL_ACCESS_TOKEN:
            return reduceSetModalAccessToken(state, action as ToastIntegrationActionInterfaces.ISetModalAccessToken);
        default:
            return state;
    }
};
