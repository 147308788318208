import { StringValueMap } from 'api/Core/StringValueMap';
import { DefaultPour } from 'api/Location/interfaces/ILocationSettingsService';
import { MassUnit } from 'api/Product/model/MassUnit';
import { PackagingId } from 'api/Product/model/PackagingId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { ThunkAction } from 'redux-thunk';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { IExtraArguments } from 'shared/components/Provider';
import { createOptionsAndLabelNameTuplesFromValueArray, OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { productJSONToObjectSerializer } from 'shared/lib/manager';
import { IAction } from 'shared/models/IAction';
import { ISortedFilteredAndGroupedResult } from 'shared/utils/sortingFilteringAndGroupingUtils';
import { Validation } from 'shared/validators/validators';
import { DefaultPourFormFieldName, DefaultPourInfoByFormFieldName, IEditDefaultPourFormState } from './reducers';
import { defaultPourCategoriesSortingGroupingFilteringUtil } from './utils/defaultPourSizeSortingUtil';

export interface IEditDefaultPourFormStore {
    editDefaultPourFormState : IEditDefaultPourFormState;
}

export const ActionTypes = {
    SET_FORM: 'EDIT_DEFAULT_POUR/SET_FORM',
    SET_DEFAULT_POUR_FORM_FOR_INDEX: 'EDIT_DEFAULT_POUR/SET_DEFAULT_POUR_FORM_FOR_INDEX',
    REMOVE_DEFAULT_POUR_CATEGORY: 'EDIT_DEFAULT_POUR/REMOVE_DEFAULT_POUR_CATEGORY',
    ADD_DEFAULT_POUR_CATEGORY: 'EDIT_DEFAULT_POUR/ADD_DEFAULT_POUR_CATEGORY',
    SET_CATEGORY_OPTIONS: 'EDIT_DEFAULT_POUR/SET_CATEGORY_OPTIONS',
};

export namespace ActionInterfaces {
    export interface ISetForm extends IAction {
        payload : {
            defaultPourForm : Array<DefaultPourInfoByFormFieldName>;
        };
    }

    export interface ISetDefaultPourFormForIndex extends IAction {
        payload : {
            index : number,
            field : DefaultPourFormFieldName,
            validationInput : IValidationInputData
        };
    }

    export interface IRemoveDefaultPourCategoryByIndex extends IAction { // not used right now because need to fully backend test this with legacy nonsense
        payload : {
            index : number
        };
    }

    export interface ISetCategoryOptions extends IAction {
        payload : {
            categoryOptions : OptionsAndLabelNameTuples;
        };
    }

    export interface IAddDefaultPourCategory extends IAction { // not used right now because need to fully backend test this with legacy nonsense
        payload : { defaultPourForm : Array<DefaultPourInfoByFormFieldName> };
    }

    export type IServices = object;

    export interface IEditDefaultPourFormExtraArguments extends IExtraArguments {
        services : IServices;
    }
}

// These are unique, default categories that are given specific quantity/unit amounts
const DEFAULT_CATEGORY_INFO : DefaultPour = {
    Beer: {
        quantity: 1,
        unit: 'unit'
    },
    'Draught Beer': {
        quantity: 16,
        unit: VolumeUnit.OUNCE
    },
    Spirit: {
        quantity: 1.5,
        unit: VolumeUnit.OUNCE
    },
    Wine: {
        quantity: 6,
        unit: VolumeUnit.OUNCE
    },
    Other: {
        quantity: 1,
        unit: VolumeUnit.OUNCE
    }
};

const setDefaultPourFormForIndex = (
    index : number,
    field : DefaultPourFormFieldName,
    validationInput : IValidationInputData
) : ActionInterfaces.ISetDefaultPourFormForIndex => ({
    payload: {
        index,
        field,
        validationInput
    },
    type: ActionTypes.SET_DEFAULT_POUR_FORM_FOR_INDEX
});

const setForm = (
    defaultPourForm : Array<DefaultPourInfoByFormFieldName>
) : ActionInterfaces.ISetForm => ({
    payload: {
        defaultPourForm
    },
    type: ActionTypes.SET_FORM
});

const setCategoryOptions = (
    categoryOptions : OptionsAndLabelNameTuples
) : ActionInterfaces.ISetCategoryOptions => ({
    payload: {
        categoryOptions
    },
    type: ActionTypes.SET_CATEGORY_OPTIONS
});

const addCategory = (defaultPourForm : Array<DefaultPourInfoByFormFieldName>) : ActionInterfaces.IAddDefaultPourCategory =>
    ({ payload: { defaultPourForm }, type: ActionTypes.ADD_DEFAULT_POUR_CATEGORY });

const removeCategoryOption = (index : number) : ActionInterfaces.IRemoveDefaultPourCategoryByIndex =>
    ({ payload: { index }, type: ActionTypes.REMOVE_DEFAULT_POUR_CATEGORY });

const onFormFieldChange = (
    index : number,
    field : DefaultPourFormFieldName,
    value : string,
) : ThunkAction<void, IEditDefaultPourFormStore, ActionInterfaces.IEditDefaultPourFormExtraArguments> => {
    return (dispatch, getState, extraArguments) : void => {
        const validationResult = validateValueByFieldName(field, value);
        dispatch(setDefaultPourFormForIndex(index, field, { value, isValid: validationResult.isValid, errorMessage: validationResult.errorMessage }));
    };
};

// this should probably be in a util file
const validateValueByFieldName = (fieldName : DefaultPourFormFieldName, value : string) => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    switch (fieldName) {
        case 'quantity':
            isValid = Validation.validateNonNegativeNumber(value) || value === '';
            if (!isValid) {
                errorMessage = 'Value must be a non-negative number';
            }
            break;
        case 'unit':
            isValid = true; // trusting that dropdown is correct
            break;
        case 'category':
            isValid = Validation.validateRequired(value);
            if (!isValid) {
                errorMessage = 'Value is required';
            }
            break;
        default:
            break;
    }

    if (isValid === null) {
        throw new RuntimeException('unhandled field');
    }

    return {
        isValid,
        errorMessage,
    };
};

const getDefaultPourByCategoryFromForm = () : ThunkAction<DefaultPour | null, IEditDefaultPourFormStore, ActionInterfaces.IEditDefaultPourFormExtraArguments> => {
    return (dispatch, getState, extraArguments) : DefaultPour | null => {
        const defaultPour : DefaultPour = {};
        let allFieldsAreValid : boolean = true;

        const form = getState().editDefaultPourFormState.defaultPourForm;
        form.forEach((rowInfo, index) => {
            let rowIsValid : boolean = true;
            Object.keys(rowInfo).forEach((formFieldNameValue) => {
                const field = formFieldNameValue as DefaultPourFormFieldName;
                const fieldValue = rowInfo[field].value;
                const validationResult = validateValueByFieldName(field, fieldValue);
                allFieldsAreValid = allFieldsAreValid && validationResult.isValid;

                rowIsValid = rowIsValid && validationResult.isValid;

                if (!validationResult.isValid) {
                    dispatch(setDefaultPourFormForIndex(index, field, { value: fieldValue, isValid: validationResult.isValid, errorMessage: validationResult.errorMessage }));
                }
            });
            if (rowIsValid) {
                let unit : VolumeUnit | MassUnit | 'EA' | 'unit';
                if (rowInfo.unit.value === 'unit' || rowInfo.unit.value === 'EA') {
                    unit = rowInfo.unit.value;
                } else {
                    const productQuantityUnit = productJSONToObjectSerializer.getProductQuantityUnit(rowInfo.unit.value);
                    if (productQuantityUnit instanceof PackagingId) {
                        throw new RuntimeException('unexpected');
                    }
                    unit = productQuantityUnit;
                }
                // Only save the form if the value is populated (this is due to blank values being technically "valid" without error)
                const isQuantityNotBlank = rowInfo.quantity.value !== '';
                if (isQuantityNotBlank) {
                    defaultPour[rowInfo.category.value] = {
                        quantity: parseFloat(rowInfo.quantity.value),
                        unit,
                    };
                }
            }
        });

        if (allFieldsAreValid) {
            return defaultPour;
        } else {
            return null;
        }
    };
};

const addCategoryOption = (categoryName : string) :
    ThunkAction<void, IEditDefaultPourFormStore, ActionInterfaces.IEditDefaultPourFormExtraArguments> => {
    return async (dispatch, getState, extraArguments) => {
        const newForm = [...getState().editDefaultPourFormState.defaultPourForm];
        // When adding a category form, check if they are one of the few unique categories with specific defaults (DEFAULT_CATEGORY_INFO object)
        newForm.push({
            category: {
                value: categoryName,
                isValid: true,
                errorMessage: ''
            },
            quantity: {
                value: DEFAULT_CATEGORY_INFO[categoryName] ? DEFAULT_CATEGORY_INFO[categoryName].quantity.toString() : '',
                isValid: true,
                errorMessage: ''
            },
            unit: {
                value: DEFAULT_CATEGORY_INFO[categoryName] ? DEFAULT_CATEGORY_INFO[categoryName].unit : VolumeUnit.OUNCE,
                isValid: true,
                errorMessage: ''
            },
        });

        const columnSorting = { sortedBy: '', direction: 1 };
        const sortedProductCategoryOptions : ISortedFilteredAndGroupedResult<DefaultPourInfoByFormFieldName> =
            await defaultPourCategoriesSortingGroupingFilteringUtil.getSortedFilteredAndGroupedResult(newForm, columnSorting, '', '', null);

        dispatch(addCategory(sortedProductCategoryOptions.sortedRowIdsToDisplayByGroupName.sortedDefaultPourCategories));
    };
};

const initializeForm = (initialDefaultPour : DefaultPour | null, productsById : StringValueMap<ProductId, Product>) :
    ThunkAction<void, IEditDefaultPourFormStore, ActionInterfaces.IEditDefaultPourFormExtraArguments> => {
    return async (dispatch, getState, extraArguments) => {
        const form : Array<DefaultPourInfoByFormFieldName> = [];

        const productCategoryOptions = createOptionsAndLabelNameTuplesFromValueArray(CategoryUtils.getCategoriesFromProductsAndDefaults(productsById.values()), null);

        // convert Array<IOption> to Array<DefaultPourInfoByFormFieldName> for sorting
        const flattenedProductCategoryOptions : Array<DefaultPourInfoByFormFieldName> = productCategoryOptions[0][1].map((category : IOption) => ({
            category: {
                value: category.value,
                isValid: true,
                errorMessage: ''
            },
            quantity: {
                value: '',
                isValid: true,
                errorMessage: ''
            },
            unit: {
                value: '',
                isValid: true,
                errorMessage: ''
            },
        }));

        const columnSorting = { sortedBy: '', direction: 1 };

        const sortedProductCategoryOptions : ISortedFilteredAndGroupedResult<DefaultPourInfoByFormFieldName> =
            await defaultPourCategoriesSortingGroupingFilteringUtil.getSortedFilteredAndGroupedResult(flattenedProductCategoryOptions, columnSorting, '', '', null);

        const { sortedDefaultPourCategories } = sortedProductCategoryOptions.sortedRowIdsToDisplayByGroupName;

        // convert sorted Array<DefaultPourInfoByFormFieldName> back to Array<IOption>
        const dropdownSortedDefaultPourCategories : Array<IOption> =  sortedDefaultPourCategories.map((category : DefaultPourInfoByFormFieldName) => ({
            icon: null,
            label: category.category.value,
            value: category.category.value
        }));

        const sortedCategoryOptions : OptionsAndLabelNameTuples = [[null, dropdownSortedDefaultPourCategories]];
        if (initialDefaultPour === null) {
            // Remove specific "non-beverage" default categories from the initial form load for first time users
            const cleanedProductCategoryOptions : Array<IOption> = dropdownSortedDefaultPourCategories.filter((category : IOption) => {
                return category.value !== 'Accessory' && category.value !== 'Kitchen' && category.value !== 'Bar Goods' && category.value !== 'Food';
            });
            cleanedProductCategoryOptions.map((category) => {
                form.push({
                    category: {
                        value: category.value,
                        isValid: true,
                        errorMessage: ''
                    },
                    unit: {
                        value: DEFAULT_CATEGORY_INFO[category.value] ? DEFAULT_CATEGORY_INFO[category.value].unit : VolumeUnit.OUNCE,
                        isValid: true,
                        errorMessage: ''
                    },
                    quantity: {
                        value: DEFAULT_CATEGORY_INFO[category.value] ? DEFAULT_CATEGORY_INFO[category.value].quantity.toString() : '',
                        isValid: true,
                        errorMessage: ''
                    },
                });
            });

        } else {
            Object.keys(initialDefaultPour).forEach((category) => {
                form.push({
                    category: {
                        value: category,
                        isValid: true,
                        errorMessage: ''
                    },
                    unit: {
                        value: initialDefaultPour[category].unit,
                        isValid: true,
                        errorMessage: ''
                    },
                    quantity: {
                        value: initialDefaultPour[category].quantity.toString(),
                        isValid: true,
                        errorMessage: ''
                    },
                });
            });

        }
        dispatch(setForm(form));
        dispatch(setCategoryOptions(sortedCategoryOptions));
    };
};

export const EditDefaultPourFormActions = {
    onFormFieldChange,
    initializeForm,
    getDefaultPourByCategoryFromForm,
    removeCategoryOption,
    addCategoryOption
};
