import { IStringValue } from 'api/Core/IStringValue';

export class LocationId implements IStringValue {
    constructor (private readonly value : string) {}

    public getValue() : string {
        return this.value;
    }

    public equals(other : LocationId) : boolean {
        return this.getValue() === other.getValue();
    }

    public toString() : string {
        return `{LocationId: ${this.value}}`;
    }
}
