import React from 'react';
import { connect } from 'react-redux';

import { ProductId } from 'api/Product/model/ProductId';
import { Unit } from 'api/Product/model/Unit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';

import { ProductFormUtils } from 'apps/ItemCard/utils/ProductFormUtils';
import { IProductSlimCreateFormStore, TypedDispatch, ProductSlimCreateFormActions } from './ProductSlimCreateFormActions';
import {
    IProductSlimCreateFormState,
    ProductSlimCreateFormFieldName
} from './ProductSlimCreateFormReducers';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { PackagingForm, PackagingFormValidationInputDataByFieldName } from 'shared/components/Product/PackagingForm';
import {
    OptionsAndLabelNameTuples,
    Select2DropdownMenu
} from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { SlimCreate } from 'shared/components/SlimCreate/SlimCreate';
import { IValidationInputData, ValidationInput } from 'shared/components/ValidationInput';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import './ProductSlimCreateForm.scss';

// note on shouldShowPriceField -- this is not really a desired pattern if we are constantly hiding/showing parts
// of the form based on the context this is in. potentially this should take a list of components or isShownByComponentName
// and styling should handle any combination of elements shown. however until we need to do this, just using this one variable
export interface IConnectedProductSlimCreateFormProps {
    formState : IProductSlimCreateFormState;
    dispatch : TypedDispatch;
    shouldShowPriceField : boolean;
    shouldShowDistributorAndSkuFields : boolean;
    hasMoreOptionsButton : boolean;
    onCancel : () => void;
    onMoreOptions : (
        packagingValidationInputByFieldName : PackagingFormValidationInputDataByFieldName,
        slimCreateValidationInputDataByFieldName : { [fieldName in ProductSlimCreateFormFieldName] : IValidationInputData }
    ) => void;
    onSave : (productId : ProductId) => void;
}

export class ProductSlimCreateForm extends React.Component<IConnectedProductSlimCreateFormProps, object> {
    private packagingForm : PackagingForm | null = null;

    public UNSAFE_componentWillMount() {
        const {
            dispatch,
        } = this.props;

        if (this.props.formState.formData === null) {
            dispatch(ProductSlimCreateFormActions.fetchProductSlimCreateFormData());
        }
    }

    public render() {
        const {
            formState,
            hasMoreOptionsButton,
            shouldShowPriceField,
            shouldShowDistributorAndSkuFields,
        } = this.props;

        const formData = formState.formData;

        const validatedItemName = formState.validationInputDataByFieldName.name;

        const itemCategoryEmptyState : JSX.Element = (
            <div className="item-category-dropdown-no-options">No Available Categories</div>
        );

        let categories : OptionsAndLabelNameTuples = [];
        let categorySelectedIOption : IOption | null = null;
        if (formData) {
            categories = formData.categorySortedOptionsAndLabelNames;
            categories.forEach(([labelName, iOptions]) => {
                iOptions.forEach((iOption) => {
                    if (iOption.value === formState.validationInputDataByFieldName.categoryId.value) {
                        categorySelectedIOption = iOption;
                    }
                });
            });
        }

        // Price -- copied from ProductForm.tsx
        let priceUnitSortedOptionsAndLabelName : OptionsAndLabelNameTuples = [];
        if (this.packagingForm) {
            const packagingFormValidationInputDataByFieldName = this.packagingForm.getValidationInputDataByFieldName();
            priceUnitSortedOptionsAndLabelName = ProductFormUtils.getPriceUnitIOptions(packagingFormValidationInputDataByFieldName);
        }

        const priceUnitSelectedIOption = formState.validationInputDataByFieldName.priceUnit.value ? {
            value: formState.validationInputDataByFieldName.priceUnit.value,
            label: formState.validationInputDataByFieldName.priceUnit.value ? oldPackagingUtils.getDisplayTextForUnit(formState.validationInputDataByFieldName.priceUnit.value as Unit) : '',
            icon: null,
        } : null;

        let distributorSelectedIOption : IOption | null = null;
        if (formData) {
            formData.distributorSortedOptionsAndLabelNames.forEach(([labelName, iOptions]) => {
                iOptions.forEach((iOption) => {
                    if (iOption.value === formState.validationInputDataByFieldName.distributorId.value) {
                        distributorSelectedIOption = iOption;
                    }
                });
            });
        }

        return (
            <SlimCreate
                header="Creating New Item..."
                content={ (
                    <div className="slim-create-form">
                        <div className="slim-create-form-section">
                            <div className="item-name-input">
                                <ValidationInput
                                    inputClassName="item-name reset-dark-styles"
                                    type="text"
                                    autoFocus={ false }
                                    errorMessage={ validatedItemName.errorMessage }
                                    isValid={ validatedItemName.isValid }
                                    handleBlur={ this.onItemNameBlur }
                                    handleFocus={ null }
                                    handleChange={ this.onItemNameChange }
                                    handleEnterClick={ null }
                                    label="Item name"
                                    hintText={ null }
                                    value={ validatedItemName.value }
                                    isDisabled={ false }
                                    autoComplete={ null }
                                />
                            </div>
                            { !shouldShowPriceField &&
                                <div className="item-category-input">
                                    <label className="input-label static reset-dark-styles">Category</label>
                                    <Select2DropdownMenu
                                        isSearchable={ true }
                                        selectedOptionHeaderShown={ true }
                                        onOptionSelect={ this.onItemCategorySelect }
                                        sortedOptionsAndLabelName={ categories }
                                        selectedOption={ categorySelectedIOption }
                                        placeholderText="-"
                                        hasCreateCustomOption={ true }
                                        createCustomOption={ this.onCreateCustomCategory }
                                        customOptionGroupLabel={ null }
                                        buttonShouldDisplaySelectedLabel={ true }
                                        emptyState={ itemCategoryEmptyState }
                                        isTitleCase={ true }
                                        createCustomOptionButtonLabel={ null }
                                    />

                                    { !formState.validationInputDataByFieldName.categoryId.isValid &&
                                        <div className="slim-create-form-category-validation-error">
                                            { formState.validationInputDataByFieldName.categoryId.errorMessage }
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        <div className="slim-create-form-section">
                            <PackagingForm
                                ref={ this.packagingFormRef }
                                initialPackaging={ null }
                                onFormFieldChange={ this.onPackagingChange }
                            />
                        </div>

                        { shouldShowPriceField &&
                            <div className="slim-create-form-section">
                                <div className="item-category-input">
                                    <label className="input-label static reset-dark-styles">Category</label>
                                    <Select2DropdownMenu
                                        isSearchable={ true }
                                        selectedOptionHeaderShown={ true }
                                        onOptionSelect={ this.onItemCategorySelect }
                                        sortedOptionsAndLabelName={ categories }
                                        selectedOption={ categorySelectedIOption }
                                        placeholderText="Other"
                                        hasCreateCustomOption={ true }
                                        createCustomOption={ this.onCreateCustomCategory }
                                        customOptionGroupLabel={ null }
                                        buttonShouldDisplaySelectedLabel={ true }
                                        emptyState={ itemCategoryEmptyState }
                                        isTitleCase={ true }
                                        createCustomOptionButtonLabel={ null }
                                    />
                                </div>
                                <div className="item-price-input">
                                    <label className="input-label static reset-dark-styles">Cost</label>
                                    <div className="item-price-input-group">
                                        <ValidationInput
                                            key="priceAmount"
                                            inputClassName="reset-dark-styles no-label"
                                            type="text"
                                            autoFocus={ false }
                                            autoComplete={ null }
                                            errorMessage={ formState.validationInputDataByFieldName.priceAmount.errorMessage }
                                            isValid={ formState.validationInputDataByFieldName.priceAmount.isValid }
                                            handleBlur={ this.onPriceAmountBlur }
                                            handleFocus={ null }
                                            handleChange={ this.onPriceAmountChange }
                                            handleEnterClick={ this.doNothingFunction }
                                            label={ null }
                                            hintText={ null }
                                            value={ formState.validationInputDataByFieldName.priceAmount.value }
                                            isDisabled={ false }
                                            isCurrencyInput={ true }
                                        />
                                        <span>&nbsp;/&nbsp;</span>
                                        <div className="floating-dropdown-menu">
                                            <Select2DropdownMenu
                                                isSearchable={ false }
                                                buttonShouldDisplaySelectedLabel={ true }
                                                selectedOptionHeaderShown={ false }
                                                sortedOptionsAndLabelName={ priceUnitSortedOptionsAndLabelName }
                                                selectedOption={ priceUnitSelectedIOption }
                                                onOptionSelect={ this.onPriceUnitSelect }
                                                placeholderText="Select Unit"
                                                emptyState={ null }
                                                hasCreateCustomOption={ false }
                                                createCustomOption={ this.doNothingFunction }
                                                customOptionGroupLabel={ null }
                                                createCustomOptionButtonLabel={ null }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        { shouldShowDistributorAndSkuFields && formState.formData &&
                            <div className="slim-create-form-section">
                                <div className="item-distributor-input">
                                    <label className="input-label static reset-dark-styles">Vendor</label>
                                    <Select2DropdownMenu
                                        isSearchable={ true }
                                        buttonShouldDisplaySelectedLabel={ true }
                                        selectedOptionHeaderShown={ true }
                                        sortedOptionsAndLabelName={ formState.formData.distributorSortedOptionsAndLabelNames }
                                        selectedOption={ distributorSelectedIOption }
                                        onOptionSelect={ this.onDistributorSelect }
                                        placeholderText="Select Vendor"
                                        emptyState={ null }
                                        hasCreateCustomOption={ false }
                                        createCustomOption={ this.doNothingFunction }
                                        customOptionGroupLabel="My Vendors"
                                        createCustomOptionButtonLabel={ null }
                                    />
                                </div>
                                <div className="item-sku-input">
                                    <ValidationInput
                                        key="sku"
                                        inputClassName="reset-dark-styles"
                                        type="text"
                                        autoFocus={ false }
                                        autoComplete={ null }
                                        value={ formState.validationInputDataByFieldName.sku.value }
                                        errorMessage={ formState.validationInputDataByFieldName.sku.errorMessage }
                                        isValid={ formState.validationInputDataByFieldName.sku.isValid }
                                        handleBlur={ this.onSkuBlur }
                                        handleFocus={ null }
                                        handleChange={ this.onSkuChange }
                                        handleEnterClick={ this.doNothingFunction }
                                        label="Vendor SKU"
                                        hintText="from invoice"
                                        isDisabled={ false }
                                    />
                                </div>
                            </div>
                        }

                        { !formState.isValid &&
                            <div className="slim-create-form-validation-error">
                                { formState.errorMessage }
                            </div>
                        }
                    </div>
                ) }
                handleSaveButton={ this.onSlimCreateSaveButtonClick }
                handleCancelButton={ this.onCancel }
                handleMoreOptionsButton={ this.onMoreOptionsClick }
                hasMoreOptionsButton={ hasMoreOptionsButton }
                saveInProgress={ formState.isSubmitting }
            />
        );
    }

    private readonly onDistributorSelect = (optionValue : string) => {
        return this.onFormFieldChange('distributorId', optionValue);
    }

    private readonly onPriceUnitSelect = (optionValue : string) => {
        return this.onFormFieldChange('priceUnit', optionValue);
    }

    private readonly onPriceAmountBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        return this.onFormFieldBlur('priceAmount', event.currentTarget.value);
    }

    private readonly onPriceAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        return this.onFormFieldChange('priceAmount', event.currentTarget.value);
    }

    private readonly onItemCategorySelect = (optionValue : string) => {
        return this.onFormFieldChange('categoryId', optionValue);
    }

    private readonly onCreateCustomCategory = (optionValue : string) => {
        this.props.dispatch(ProductSlimCreateFormActions.createCustomCategory(optionValue));
        return this.onFormFieldChange('categoryId', optionValue);
    }

    private readonly onItemNameBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        return this.onFormFieldBlur('name', event.currentTarget.value);
    }

    private readonly onItemNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        return this.onFormFieldChange('name', event.currentTarget.value);
    }

    private readonly onSkuBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        return this.onFormFieldBlur('sku', event.currentTarget.value);
    }

    private readonly onSkuChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        return this.onFormFieldChange('sku', event.currentTarget.value);
    }

    private readonly onPackagingChange = (packagingFormValidationInputDataByFieldName : PackagingFormValidationInputDataByFieldName) => {
        this.props.dispatch(ProductSlimCreateFormActions.onPackagingChange(packagingFormValidationInputDataByFieldName));
    }

    private readonly packagingFormRef = (packagingForm : PackagingForm) => {
        this.packagingForm = packagingForm;
    }

    private readonly onSlimCreateSaveButtonClick = () => {
        const {
            dispatch,
            onSave,
            shouldShowPriceField,
            shouldShowDistributorAndSkuFields,
        } = this.props;

        if (this.packagingForm) {
            const packaging = this.packagingForm.getPackaging(true);
            dispatch(ProductSlimCreateFormActions.onSubmit(packaging, shouldShowPriceField, shouldShowDistributorAndSkuFields, onSave));
        } else {
            throw new RuntimeException('Unexpected: packagingForm is null');
        }
    }

    private readonly onCancel = () => {
        this.props.dispatch(ProductSlimCreateFormActions.resetForm());
        this.props.onCancel();
    }

    private readonly onMoreOptionsClick = () => {
        if (this.packagingForm) {
            this.props.onMoreOptions(this.packagingForm.getValidationInputDataByFieldName(), this.props.formState.validationInputDataByFieldName);
        } else {
            throw new RuntimeException('Unexpected: packagingForm is null');
        }
    }

    private readonly doNothingFunction = () => undefined;

    private readonly onFormFieldChange = (field : ProductSlimCreateFormFieldName, value : string) => {
        return this.props.dispatch(ProductSlimCreateFormActions.onFormFieldChange(field, value));
    }

    private readonly onFormFieldBlur = (field : ProductSlimCreateFormFieldName, value : string) => {
        return this.props.dispatch(ProductSlimCreateFormActions.onFormFieldBlur(field, value));
    }
}

export interface IOwnProps {
    hasMoreOptionsButton : boolean;
    shouldShowPriceField : boolean;
    shouldShowDistributorAndSkuFields : boolean;
    onCancel : () => void;
    onMoreOptions : (
        packagingValidationInputByFieldName : PackagingFormValidationInputDataByFieldName,
        slimCreateValidationInputDataByFieldName : { [fieldName in ProductSlimCreateFormFieldName] : IValidationInputData }
    ) => void;
    onSave : (productId : ProductId) => void;
}

interface IDispatchProps {
    dispatch : TypedDispatch;
}

const mapStateToProps = (state : IProductSlimCreateFormStore) : IProductSlimCreateFormStore => {
    return state;
};

const mapDispatchToProps = (dispatch : TypedDispatch) : { dispatch : TypedDispatch } => {
    return {
        dispatch
    };
};

const mergeProps = (state : IProductSlimCreateFormStore, dispatchProps : IDispatchProps, ownProps : IOwnProps) : IConnectedProductSlimCreateFormProps => {
    return {
        formState: state.productSlimCreateFormState,
        dispatch: dispatchProps.dispatch,
        onCancel: ownProps.onCancel,
        onMoreOptions: ownProps.onMoreOptions,
        onSave: ownProps.onSave,
        hasMoreOptionsButton: ownProps.hasMoreOptionsButton,
        shouldShowPriceField: ownProps.shouldShowPriceField,
        shouldShowDistributorAndSkuFields : ownProps.shouldShowDistributorAndSkuFields,
    };
};

export const ConnectedProductSlimCreateForm =
    connect<IProductSlimCreateFormStore, IDispatchProps, IOwnProps, IConnectedProductSlimCreateFormProps, IProductSlimCreateFormStore>(mapStateToProps, mapDispatchToProps, mergeProps)(ProductSlimCreateForm);
