import { Action } from '@reduxjs/toolkit';

import { ProductId } from 'api/Product/model/ProductId';

import { ActionInterfaces, ActionTypes } from '../actions/unmatchedProductModalActions';

import { transferReportProductDataUtils } from 'apps/InventoryTransfer/utils/transferReportProductDataUtils';

import { IAction, IEmptyAction, IFailureAction } from 'shared/models/IAction';
import { ISearchBarState } from 'shared/models/ISearchBarState';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { InterLocationProductMapping } from 'api/Transfer/model/InterLocationProductMapping';

const itemNumberLongerThanUnmapedProductIdsArrayError =
    new Error('reduceSetModalStepState itemNumber longer than unmappedProductIds');
const itemNumberLessThanOneError =
    new Error('reduceSetModalStepState itemNumber less than 0');
const addProductMappingWithoutSelectedProductError =
    new Error('reduceAddProductMapping selectedProduct is null');
const unexpectedSourceProductIdInMappingError = new Error('SourceProductId has an unexpected value');

export interface IProductMappingComponentForm {
    productId : ProductId | null;
    quantity : {
        value : string;
        isValid : boolean;
        errorMessage : string;
    };
    productQuantityUnit : ProductQuantityUnit;
}

export interface IUnmappedProductState {
    unmappedProductIds : Array<ProductId>;
    itemNumber : number;
    currentUnmappedProductForm : {
        productId : ProductId | null;
        productQuantityUnit : ProductQuantityUnit;
    };
    searchBar : ISearchBarState;
    searchResults : Array<ProductId>;
    displayedSearchResults : Array<ProductId>;
    selectedProductForm : {
        productId : ProductId | null;
        quantity : {
            value : string;
            isValid : boolean;
            errorMessage : string;
        };
        productQuantityUnit : ProductQuantityUnit;
    };
    existingMappings : Array<InterLocationProductMapping>;
    addedMappings : Array<InterLocationProductMapping>;
    isLoading : boolean;
    wasProductAddedToLocationByStep : Array<boolean>;
}

const initialState : IUnmappedProductState = {
    isLoading: false,
    itemNumber: 0,
    unmappedProductIds: [],
    searchBar: {
        searchTerm: null,
        isDisabled: false,
        isFocused: false,
    },
    searchResults: [],
    displayedSearchResults: [],
    selectedProductForm: {
        productId: null,
        quantity: {
            value: '',
            isValid: true,
            errorMessage: '',
        },
        productQuantityUnit: VolumeUnit.MILLILITER,
    },
    existingMappings: [],
    currentUnmappedProductForm: {
        productId: null,
        productQuantityUnit: VolumeUnit.MILLILITER,
    },
    addedMappings: [],
    wasProductAddedToLocationByStep: [],
};

const reduceSetUnmappedProductIds = (
    state : IUnmappedProductState,
    action : ActionInterfaces.ISetProductIdsAction
) : IUnmappedProductState => {
    return {
        ...state,
        unmappedProductIds: action.payload.productIds
    };
};

const reduceSetSelectedProductForm = (
    state : IUnmappedProductState,
    action : ActionInterfaces.ISetSelectedProductFormAction
) : IUnmappedProductState => {
    return {
        ...state,
        selectedProductForm: {
            productId: action.payload.productId,
            quantity: action.payload.quantity,
            productQuantityUnit: action.payload.productQuantityUnit,
        },
    };
};

const reduceSetExistingMappings = (
    state : IUnmappedProductState,
    action : ActionInterfaces.ISetExistingMappingsAction
) : IUnmappedProductState => {
    return {
        ...state,
        existingMappings: action.payload.productMappings
    };
};

const reduceSetModalStepState = (
    state : IUnmappedProductState,
    action : ActionInterfaces.ISetModalStepStateAction
) : IUnmappedProductState => {
    const itemNumber = action.payload.itemNumber;
    const productsById = action.payload.productsById;

    if (itemNumber < 0) {
        throw itemNumberLessThanOneError;
    }

    const currentUnmappedProductId = itemNumber > 0 ? state.unmappedProductIds[itemNumber - 1] : null;

    let addedMapping = itemNumber > 0 ? state.addedMappings[itemNumber - 1] : null;
    addedMapping = addedMapping ? addedMapping : null;

    let currentUnmappedProductForm : { productId : ProductId | null; productQuantityUnit : ProductQuantityUnit } = {
        productId: currentUnmappedProductId,
        productQuantityUnit: VolumeUnit.MILLILITER,
    };

    if (currentUnmappedProductId) {
        const currentUnmappedProduct = productsById.getRequired(currentUnmappedProductId);

        currentUnmappedProductForm = {
            productId: currentUnmappedProductId,
            productQuantityUnit: currentUnmappedProduct.getPackagingsAndMappings().getConversions().getBaseUnit(),
        };
    }

    let selectedProductForm : IProductMappingComponentForm = {
        productId: null,
        quantity: {
            value: '',
            isValid: true,
            errorMessage: '',
        },
        productQuantityUnit: VolumeUnit.MILLILITER,
    };

    if (addedMapping) {
        if (!addedMapping.getSourceProductId().equals(currentUnmappedProductId)) {
            throw unexpectedSourceProductIdInMappingError;
        }

        currentUnmappedProductForm = {
            productId: addedMapping.getSourceProductId(),
            productQuantityUnit: addedMapping.getSourceQuantityOfProduct().getUnit(),
        };
    
        selectedProductForm = {
            productId: addedMapping.getTargetProductId(),
            quantity: {
                value: addedMapping.getTargetQuantityOfProduct().getQuantity().toString(),
                isValid: true,
                errorMessage: '',
            },
            productQuantityUnit: addedMapping.getTargetQuantityOfProduct().getUnit(),
        };
    }

    if (typeof currentUnmappedProductId === 'undefined') {
        throw itemNumberLongerThanUnmapedProductIdsArrayError;
    }

    const searchTerm = '';

    // TODO alb: have the component re-filter. do not do the filtering and search bar update here.
    return {
        ...state,
        searchBar: {
            ...state.searchBar,
            searchTerm,
        },
        displayedSearchResults: transferReportProductDataUtils.filterProducts(searchTerm, state.searchResults, productsById, selectedProductForm.productId), // TODO alb: don't want to have to pass products by id, why are we re-filtering
        itemNumber,
        currentUnmappedProductForm,
        selectedProductForm,
    };
};

const reduceClearWasProductAddedToLocationByStep = (
    state : IUnmappedProductState,
    action : IEmptyAction
) : IUnmappedProductState => {
    return {
        ...state,
        wasProductAddedToLocationByStep: [],
    };
};

const reduceSetAddedMappings = (
    state : IUnmappedProductState,
    action : ActionInterfaces.ISetAddedMappingsAction
) : IUnmappedProductState => {
    return {
        ...state,
        addedMappings: action.payload.addedMappings,
    };
};

const reduceAddProductMapping = (
    state : IUnmappedProductState,
    action : ActionInterfaces.IAddProductMappingAction
) : IUnmappedProductState => {
    if (state.currentUnmappedProductForm.productId === null) {
        throw addProductMappingWithoutSelectedProductError;
    }

    const newAddedMappings = [ ...state.addedMappings ];
    newAddedMappings[state.itemNumber - 1] = action.payload.productMapping;

    return {
        ...state,
        addedMappings: newAddedMappings,
    };
};

const reduceSetSearchResults = (
    state : IUnmappedProductState,
    action : ActionInterfaces.ISetSearchResults
) : IUnmappedProductState => {
    return {
        ...state,
        searchResults: action.payload.products,
    };
};

const reduceUpdateSearchTerm = (
    state : IUnmappedProductState,
    action : ActionInterfaces.IUpdateSearchTermAction
) : IUnmappedProductState => {
    return {
        ...state,
        searchBar: {
            ...state.searchBar,
            searchTerm: action.payload.searchTerm,
        },
    };
};

const reduceUpdateDisplayedSearchResults = (
    state : IUnmappedProductState,
    action : ActionInterfaces.IUpdateDisplayedSearchResultsAction
) : IUnmappedProductState => {
    return {
        ...state,
        displayedSearchResults: action.payload.products,
    };
};

const reduceAddProductToLocationSuccess = (
    state : IUnmappedProductState,
    action : ActionInterfaces.IAddProductToLocationSuccessAction
) : IUnmappedProductState => {
    const searchResults = [...state.searchResults];
    searchResults.push(action.payload.productId);

    const wasProductAddedToLocationByStep = state.wasProductAddedToLocationByStep.slice(0);
    wasProductAddedToLocationByStep[state.itemNumber - 1] = true;

    return {
        ...state,
        isLoading: false,
        searchResults,
        selectedProductForm: {
            productId: action.payload.productId,
            quantity: {
                value : '1',
                isValid : true,
                errorMessage : ''
            },
            productQuantityUnit: action.payload.product.getPackagingsAndMappings().getConversions().getBaseUnit()
        },
        wasProductAddedToLocationByStep,
    };
};

const reduceRequest = (
    state : IUnmappedProductState,
    action : IAction
) : IUnmappedProductState => {
    return {
        ...state,
        isLoading: true,
    };
};

const reduceFailure = (
    state : IUnmappedProductState,
    action : IFailureAction
) : IUnmappedProductState => {
    return {
        ...state,
        isLoading: false,
    };
};

const reduceFetchLocationProductsRequest = (
    state : IUnmappedProductState,
    action : ActionInterfaces.IFetchLocationProductsRequestAction
) : IUnmappedProductState => {
    return {
        ...state,
        isLoading: true
    };
};

const reduceFetchLocationProductsSuccess = (
    state : IUnmappedProductState,
    action : ActionInterfaces.IFetchLocationProductsSuccessAction
) : IUnmappedProductState => {
    return {
        ...state,
        isLoading: false,
        searchResults: Array.from(action.payload.productsById.keys()),
    };
};

const reduceFetchLocationProductsFailure = (
    state : IUnmappedProductState,
    action : IFailureAction
) : IUnmappedProductState => {
    return {
        ...state,
        isLoading: false
    };
};

export const UnmappedProductModalReducers = (
    state : IUnmappedProductState = initialState,
    action : Action
) : IUnmappedProductState => {
    switch (action.type) {
        case ActionTypes.SET_EXISTING_MAPPINGS:
            return reduceSetExistingMappings(state, action as ActionInterfaces.ISetExistingMappingsAction);
        case ActionTypes.SET_SELECTED_PRODUCT_FORM:
            return reduceSetSelectedProductForm(state, action as ActionInterfaces.ISetSelectedProductFormAction);
        case ActionTypes.SET_UNMAPPED_PRODUCT_IDS:
            return reduceSetUnmappedProductIds(state, action as ActionInterfaces.ISetProductIdsAction);
        case ActionTypes.SET_MODAL_STEP_STATE:
            return reduceSetModalStepState(state, action as ActionInterfaces.ISetModalStepStateAction);
        case ActionTypes.CLEAR_WAS_PRODUCT_ADDED_TO_LOCATION_BY_STEP:
            return reduceClearWasProductAddedToLocationByStep(state, action as IEmptyAction);
        case ActionTypes.ADD_PRODUCT_MAPPING:
            return reduceAddProductMapping(state, action as ActionInterfaces.IAddProductMappingAction);
        case ActionTypes.SET_ADDED_MAPPINGS:
            return reduceSetAddedMappings(state, action as ActionInterfaces.ISetAddedMappingsAction);
        case ActionTypes.SET_SEARCH_RESULTS:
            return reduceSetSearchResults(state, action as ActionInterfaces.ISetSearchResults);
        case ActionTypes.UPDATE_SEARCH_TERM:
            return reduceUpdateSearchTerm(state, action as ActionInterfaces.IUpdateSearchTermAction);
        case ActionTypes.UPDATE_DISPLAYED_SEARCH_RESULTS:
            return reduceUpdateDisplayedSearchResults(state, action as ActionInterfaces.IUpdateDisplayedSearchResultsAction);
        case ActionTypes.ADD_PRODUCT_TO_LOCATION_REQUEST:
            return reduceRequest(
                state,
                action as IAction
            );
        case ActionTypes.ADD_PRODUCT_TO_LOCATION_SUCCESS:
            return reduceAddProductToLocationSuccess(state, action as ActionInterfaces.IAddProductToLocationSuccessAction);
        case ActionTypes.ADD_PRODUCT_TO_LOCATION_FAILURE:
            reduceFailure(
                state,
                action as IFailureAction
            );
        case ActionTypes.FETCH_LOCATION_PRODUCTS_REQUEST:
            return reduceFetchLocationProductsRequest(state, action as ActionInterfaces.IFetchLocationProductsRequestAction);
        case ActionTypes.FETCH_LOCATION_PRODUCTS_SUCCESS:
            return reduceFetchLocationProductsSuccess(state, action as ActionInterfaces.IFetchLocationProductsSuccessAction);
        case ActionTypes.FETCH_LOCATION_PRODUCTS_FAILURE:
            return reduceFetchLocationProductsFailure(
                state,
                action as IFailureAction
            );
        default:
            return state;
    }
};
