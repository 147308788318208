import React from 'react';
import MediaQuery from 'react-responsive';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';

import { Button } from 'shared/components/Button';
import { PreventPageScrolling } from 'shared/components/PreventPageScrolling';
import { IValidationInputData, ValidationInput } from 'shared/components/ValidationInput';
import { ManageStorageAreaListItem } from './ManageStorageAreaListItem';

import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';

import 'apps/InventoryCount/css/ManageStorageAreasModal.scss';

type PAGE_CONTEXT_TYPE = 'storage_area_manager' | 'inventory_count';
export interface IManageStorageAreasModalProps {
    readonly sortedStorageAreaIds : ReadonlyArray<StorageAreaId>;
    readonly storageAreaNamesById : StringValueMap<StorageAreaId, string>;
    readonly highlightedStorageAreaId : StorageAreaId | null;
    readonly newStorageAreaName : IValidationInputData;
    readonly pageContext : PAGE_CONTEXT_TYPE;
    readonly addStorageAreasButtonIsDisabled : boolean;
    readonly handleCloseClick : () => void;
    readonly handleAddAreaClick : () => void;
    readonly handleNameChangeForNewStorageArea : (name : string) => void; // TODO or can combine with following function
    readonly handleNameChangeForStorageAreaId : (storageAreaId : StorageAreaId, name : string) => void;
    readonly handleRemoveButtonClickForStorageAreaId : (storageAreaId : StorageAreaId) => void;
    readonly onDragStorageArea : (storageAreaId : StorageAreaId, storageAreaIdPreceeding : StorageAreaId | null) => void;
    readonly onDropStorageArea : (storageAreaId : StorageAreaId, storageAreaIdPreceeding : StorageAreaId | null) => void;
}

export class ManageStorageAreasModal extends React.Component<IManageStorageAreasModalProps, object> {

    public render() {
        const {
            sortedStorageAreaIds,
            storageAreaNamesById,
            highlightedStorageAreaId,
            newStorageAreaName,
            handleCloseClick,
            handleAddAreaClick,
            handleNameChangeForStorageAreaId,
            handleRemoveButtonClickForStorageAreaId,
            pageContext,
            addStorageAreasButtonIsDisabled,
        } = this.props;

        const listItems : ReadonlyArray<JSX.Element> = sortedStorageAreaIds.map(
            (storageAreaId : StorageAreaId, index : number) => {
                const storageAreaName : string = storageAreaNamesById.getRequired(storageAreaId);
                const isFocusedRow = (highlightedStorageAreaId !== null) && (highlightedStorageAreaId.equals(storageAreaId));
                const handleNameChange = (name : string) => handleNameChangeForStorageAreaId(storageAreaId, name);
                const handleRemoveButtonClick = () => handleRemoveButtonClickForStorageAreaId(storageAreaId);

                const onDragCancel = () => {
                    if (index === 0) {
                        this.props.onDragStorageArea(storageAreaId, null);
                    } else {
                        this.props.onDragStorageArea(storageAreaId, sortedStorageAreaIds[index - 1]);
                    }
                };

                return (
                    <ManageStorageAreaListItem
                        key={ storageAreaId.getValue() }
                        storageAreaId={ storageAreaId }
                        storageAreaName={ storageAreaName }
                        isFocusedRow={ isFocusedRow }
                        handleNameChange={ handleNameChange }
                        handleRemoveButtonClick={ handleRemoveButtonClick }
                        onDragHover={ this.onListItemDragHover }
                        onDrop={ this.onListItemDrop }
                        onDragCancel={ onDragCancel }
                    />
                );
            }
        );

        const doneButton = (
            <Button
                buttonClassName="flat primary"
                isDisabled={ false }
                isLoading={ false }
                onClick={ handleCloseClick }
            >
                Done
            </Button>
        );

        const editStorageAreasListElement = (
            <div className="manage-storage-areas-modal-body">
                <div className="manage-storage-areas-modal-header">
                    <h5 className="manage-storage-areas-modal-title">Edit Storage Areas</h5>
                </div>
                <div className="manage-storage-areas-modal-list-items">
                    { listItems }
                </div>
                <div className="manage-storage-areas-modal-edit-list-items">
                    <div className="manage-storage-areas-modal-add-area-button">
                        <ValidationInput
                            type="text"
                            label={ null }
                            hintText="New Area"
                            value={ newStorageAreaName.value }
                            autoFocus={ false }
                            autoComplete={ null }
                            isValid={ newStorageAreaName.isValid }
                            isDisabled={ false }
                            errorMessage={ newStorageAreaName.errorMessage }
                            inputClassName=""
                            handleEnterClick={ this.doNothing }
                            handleChange={ this.handleInputChange }
                            handleBlur={ this.doNothing }
                            handleFocus={ null }
                        />
                        <Button
                            buttonClassName="flat primary"
                            isDisabled={ !newStorageAreaName.isValid || newStorageAreaName.value === '' || addStorageAreasButtonIsDisabled }
                            isLoading={ false }
                            onClick={ handleAddAreaClick }
                        >
                            + Add
                        </Button>
                    </div>
                </div>
            </div>
        );

        // TODO design: remove the mobile-expanded-count-summary classes and remove the scss file
        return (
            <div className={ `manage-storage-areas-modal ${ pageContext === 'inventory_count' ? 'inventory-count-view' : 'storage-area-manager-view' }` }>
                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                    <div className="manage-storage-areas-modal-container left-sidebar">
                        { editStorageAreasListElement }
                        <div className="manage-storage-areas-modal-footer">
                            { doneButton }
                        </div>
                    </div>
                    <PreventPageScrolling
                        preventWindowTouchMove={ false }
                        addModalOpenClass={ true }
                    />
                    <div className="manage-storage-areas-dismiss-underlay" onClick={ handleCloseClick }/>
                </MediaQuery>
                <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                    <div className="mobile-expanded-count-summary">
                        <div className="mobile-expanded-count-summary-container manage-storage-areas-modal-body" >
                            <div className="summary-header">
                                <div className="summary-header-start">
                                    <Button
                                        buttonClassName="flat icon bevicon bevico-keyboard-arrow-down"
                                        isDisabled={ false }
                                        isLoading={ false }
                                        onClick={ handleCloseClick }
                                    />
                                </div>
                                <div className="summary-header-middle"/>
                                <div className="summary-header-end">
                                    { doneButton }
                                </div>
                            </div>
                            <div className="mobile-expanded-count-summary-body">
                                { editStorageAreasListElement }
                            </div>
                        </div>
                        <div className="mobile-expanded-count-summary-close-overlay" onClick={ handleCloseClick } />
                    </div>
                </MediaQuery>
            </div>
        );
    }

    private readonly onListItemDragHover = (dragStorageAreaId : StorageAreaId, hoverStorageAreaId : StorageAreaId) => {
        const {
            sortedStorageAreaIds,
            onDragStorageArea,
        } = this.props;

        const hoverStorageAreaIdIndex = sortedStorageAreaIds.findIndex((s) => {
            return s.equals(hoverStorageAreaId);
        });
        const storageAreaIdPreceedingIndex = hoverStorageAreaIdIndex - 1;

        let storageAreaIdPreceeding : StorageAreaId | null;
        if (storageAreaIdPreceedingIndex === -1) {
            storageAreaIdPreceeding = null;
        } else {
            storageAreaIdPreceeding = sortedStorageAreaIds[storageAreaIdPreceedingIndex];
            if (dragStorageAreaId.equals(storageAreaIdPreceeding)) {
                storageAreaIdPreceeding = hoverStorageAreaId;
            }
        }

        onDragStorageArea(dragStorageAreaId, storageAreaIdPreceeding);
    }

    private readonly onListItemDrop = (dropStorageAreaId : StorageAreaId) => {
        const {
            sortedStorageAreaIds,
            onDropStorageArea,
        } = this.props;

        const dropStorageAreaIdIndex = sortedStorageAreaIds.findIndex((s) => {
            return s.equals(dropStorageAreaId);
        });
        const storageAreaIdDropIndex = dropStorageAreaIdIndex - 1;

        let storageAreaIdPreceeding : StorageAreaId | null;
        if (storageAreaIdDropIndex === -1) {
            storageAreaIdPreceeding = null;
        } else {
            storageAreaIdPreceeding = sortedStorageAreaIds[storageAreaIdDropIndex];
        }

        onDropStorageArea(dropStorageAreaId, storageAreaIdPreceeding);
    }

    private readonly handleInputChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const {
            handleNameChangeForNewStorageArea,
        } = this.props;

        const inputElement : HTMLInputElement = event.currentTarget;
        handleNameChangeForNewStorageArea(inputElement.value);
    }

    private readonly doNothing = () => null;
}
