import { OmnivoreIntegrationState } from 'api/Integration/model/OmnivoreIntegrationState';
import { Action } from '@reduxjs/toolkit';
import {
    ActionTypes,
    OmnivoreIntegrationActionInterfaces
} from 'shared/components/PosIntegration/OmnivoreIntegrationActions';

export interface IOmnivoreIntegrationState {
    existingIntegration : OmnivoreIntegrationState | null;
    modal: IOmnivoreIntegrationModalState | null;
}

export interface IOmnivoreIntegrationModalState {
    accessToken: string | null;
}

const initialState : IOmnivoreIntegrationState = {
    existingIntegration : null,
    modal: null
};

const reduceSetIntegrationState = (
    state : IOmnivoreIntegrationState,
    action : OmnivoreIntegrationActionInterfaces.ISetIntegrationState
) : IOmnivoreIntegrationState => {
    return {
        ...state,
        existingIntegration : action.payload.integrationState
    };
};

const reduceSetModalAccessToken = (
    state : IOmnivoreIntegrationState,
    action : OmnivoreIntegrationActionInterfaces.ISetModalAccessToken
) : IOmnivoreIntegrationState => {
    return {
        ...state,
        modal: {
            ...state.modal,
            accessToken: action.payload.accessToken
        }
    };
};

export const OmnivoreIntegrationReducers = (
    state : IOmnivoreIntegrationState = initialState,
    action : Action,
) : IOmnivoreIntegrationState  => {
    switch (action.type) {
        case ActionTypes.SET_INTEGRATION_STATE:
            return reduceSetIntegrationState(state, (action as OmnivoreIntegrationActionInterfaces.ISetIntegrationState));
        case ActionTypes.SET_MODAL_ACCESS_TOKEN:
            return reduceSetModalAccessToken(state, (action as OmnivoreIntegrationActionInterfaces.ISetModalAccessToken));
        default:
            return state;
    }
};
